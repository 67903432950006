define("coachlogix/models/form-question", ["exports", "@ember-data/model", "ember-data-copyable", "coachlogix/models/base-model"], function (_exports, _model, _emberDataCopyable, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_emberDataCopyable.default, {
    questionText: (0, _model.attr)('string'),
    questionType: (0, _model.attr)('string'),
    answerExplanation: (0, _model.attr)('string'),
    moreInfoLink: (0, _model.attr)('string'),
    sortOrder: (0, _model.attr)('number'),
    isRequired: (0, _model.attr)('boolean'),
    otherFlag: (0, _model.attr)('boolean'),
    resource: (0, _model.belongsTo)('resource', {
      inverse: 'formQuestions'
    }),
    formSection: (0, _model.belongsTo)('form-section', {
      inverse: 'formQuestions'
    }),
    formQuestionOptions: (0, _model.hasMany)('form-question-option', {
      inverse: 'formQuestion',
      cascadeDelete: true
    }),

    addOption(props) {
      // order must be the last one
      let sortOrder = 0;
      this.get('formQuestionOptions').forEach(o => {
        if (o.get('sortOrder') > sortOrder) {
          sortOrder = o.get('sortOrder');
        }
      });
      sortOrder = sortOrder + 1;
      let store = Ember.getOwner(this).lookup('service:store');
      let option = store.createRecord('form-question-option', { ...props,
        sortOrder,
        formQuestion: this
      });
      this.get('formQuestionOptions').addObject(option);
      return option;
    },

    removeOption(option) {
      this.get('formQuestionOptions').removeObject(option);
    },

    deepRollback() {
      this.rollbackAttributes();
      this.hasMany('formQuestionOptions').reload();
      this.get('formQuestionOptions').invoke('rollbackAttributes');
      return this;
    }

  });

  _exports.default = _default;
});