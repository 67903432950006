define("coachlogix/models/engagement", ["exports", "@ember-data/model", "ember-concurrency", "moment", "coachlogix/models/base-model", "coachlogix/models/goalable", "coachlogix/models/programmable", "coachlogix/models/resourceable"], function (_exports, _model, _emberConcurrency, _moment, _baseModel, _goalable, _programmable, _resourceable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_goalable.default, _programmable.default, _resourceable.default, {
    /* Attributes */
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    welcomeMessage: (0, _model.attr)('string'),
    welcomeMessageTitle: (0, _model.attr)('string'),
    role: (0, _model.attr)('string'),
    // possible values: coachee, admin, manager, coache, coachee and viewer
    startDate: (0, _model.attr)('date'),
    status: (0, _model.attr)('string'),
    // draft, gray // active, green // inactive, yellow // completed, blue // archived, not seen
    billingCode: (0, _model.attr)('string'),
    endDate: (0, _model.attr)('date'),
    engagementType: (0, _model.attr)('string'),
    percentageComplete: (0, _model.attr)('number'),
    readOnlyMatching: (0, _model.attr)('boolean'),
    creator: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    ownerAccount: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    client: (0, _model.belongsTo)('client', {
      async: true,
      inverse: null
    }),
    contractorClient: (0, _model.belongsTo)('client', {
      async: true,
      inverse: null
    }),
    coach: (0, _model.belongsTo)('employee', {
      inverse: null
    }),
    coachView: (0, _model.belongsTo)('employee-view', {
      inverse: null
    }),
    coachAccount: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    coachee: (0, _model.belongsTo)('employee', {
      inverse: null
    }),
    coacheeView: (0, _model.belongsTo)('employee-view', {
      inverse: null
    }),
    coacheeAccount: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    competencyLibrary: (0, _model.belongsTo)('resource', {
      async: true,
      inverse: null
    }),
    library: (0, _model.belongsTo)('library-resource', {
      async: true,
      inverse: null
    }),
    engagementTemplate: (0, _model.belongsTo)('engagement-template', {
      async: true,
      inverse: null
    }),
    engagementSummary: (0, _model.belongsTo)('engagement-summary', {
      async: true,
      inverse: 'engagement'
    }),
    contract: (0, _model.belongsTo)('contract', {
      async: true,
      inverse: null
    }),
    contractorContract: (0, _model.belongsTo)('contract', {
      async: true,
      inverse: null
    }),
    goals: (0, _model.hasMany)('goal', {
      async: true,
      inverse: null
    }),
    stakeholders: (0, _model.hasMany)('employee', {
      async: true,
      inverse: null
    }),
    admins: (0, _model.hasMany)('role-user', {
      async: true,
      inverse: null
    }),
    adminUsers: Ember.computed.mapBy('admins', 'user'),
    engagementSessions: (0, _model.hasMany)('engagement-session', {
      async: true,
      inverse: 'engagement'
    }),
    invoiceableSessions: (0, _model.hasMany)('engagement-session', {
      async: true,
      inverse: null
    }),
    nextScheduledSession: (0, _model.belongsTo)('engagement-session', {
      async: true,
      inverse: null
    }),
    nextSessionToScheduleCurrentUser: (0, _model.belongsTo)('engagement-session', {
      async: true,
      inverse: null
    }),
    managers: Ember.computed.filterBy('admins', 'roleName', 'engagement_manager'),
    viewers: Ember.computed.filterBy('admins', 'roleName', 'engagement_viewer'),
    meetingLocation: (0, _model.belongsTo)('meeting-location', {
      inverse: null
    }),
    invoices: (0, _model.hasMany)('invoice', {
      async: true,
      inverse: 'engagement'
    }),
    lastClosedSessionDate: (0, _model.attr)('datetime'),
    coachPools: (0, _model.hasMany)('coach-pool', {
      async: true,
      inverse: null
    }),
    coachingRequests: (0, _model.hasMany)('coaching-request', {
      inverse: null
    }),
    coachMatchingStartDate: (0, _model.attr)('date'),
    coachMatchingEndDate: (0, _model.attr)('date'),
    hasInAppLocationSession: (0, _model.attr)('boolean'),
    resourceGroups: (0, _model.hasMany)('resource-group', {
      async: true,
      inverse: 'engagements'
    }),
    resources: (0, _model.hasMany)('resource', {
      async: true,
      inverse: 'engagements'
    }),
    adminResources: (0, _model.hasMany)('resource', {
      async: true,
      inverse: 'adminEngagements'
    }),
    matchingSettings: (0, _model.belongsTo)('matching-setting', {
      async: true,
      inverse: null
    }),
    //twilioIdentity: belongsTo('twilio-identity', { polymorphic: true, inverse: null }),

    /* Multi Line */
    isTrackedBySessions: Ember.computed.equal('engagementType', 'fixed_sessions'),
    isScheduled: Ember.computed.and('startDate', 'endDate'),
    label: Ember.computed('coachee.name', 'coach.name', function () {
      let coacheeName = this.get('coachee.name');
      let coachName = this.get('coach.name');
      return `${coacheeName} with ${coachName}`;
    }),
    myRole: Ember.computed('role', 'visibilityPreview', function () {
      if (this.get('role') === 'admin' && this.get('visibilityPreview')) {
        return this.get('visibilityPreview');
      }

      return this.get('role');
    }),
    isCoachee: Ember.computed.equal('role', 'coachee'),
    sessionProgramItems: Ember.computed.filter('programItems', function (item) {
      return item.get('isSession');
    }),
    engagementGoalReviews: Ember.computed('goals.[]', function () {
      let reviews = [];
      this.goals.forEach(goal => {
        if (goal.get('reviews').length) {
          goal.get('reviews').forEach(review => {
            reviews.push(review);
          });
        }
      });
      return reviews;
    }),
    sessions: Ember.computed.mapBy('sessionProgramItems', 'item'),
    tasksProgramItems: Ember.computed.filter('programItems', function (item) {
      if (item.get('isTask')) {
        return item.get('itemable');
      }
    }),
    tasks: Ember.computed.mapBy('tasksProgramItems', 'item'),
    completedSessions: Ember.computed.filterBy('sessions', 'status', 'completed'),
    progress: Ember.computed('completedSessions.{[]],length}', 'sessions.[]', function () {
      let total = this.get('sessions.length');
      let completed = this.get('completedSessions.length');

      if (total && completed) {
        return (completed / total).toFixed(2);
      } else {
        return 0;
      }
    }),
    progressReversed: Ember.computed('percentageComplete', function () {
      return 100 - this.get('percentageComplete');
    }),
    isDraft: Ember.computed.equal('status', 'draft'),
    isActive: Ember.computed.equal('status', 'active'),
    isInactive: Ember.computed.equal('status', 'inactive'),
    isMatching: Ember.computed.equal('status', 'coach_selection'),
    isDraftMatching: Ember.computed.equal('status', 'draft_coach_selection'),
    isCompleted: Ember.computed.equal('status', 'completed'),

    /* Lifecycle */
    visibilityPreview: 'admin',
    accountService: Ember.inject.service('account'),
    ajax: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    intl: Ember.inject.service(),

    /* API */
    activate: (0, _emberConcurrency.task)(function* () {
      if (!this.get('coach.id')) {
        this.clToaster.info('You need to assign a coach to start an engagement.');
      } else {
        yield this.get('updateStatus').linked().perform('active');
      }
    }),
    archive: (0, _emberConcurrency.task)(function* () {
      yield this.get('updateStatus').perform('archived');
    }),
    complete: (0, _emberConcurrency.task)(function* () {
      yield this.get('updateStatus').perform('completed');
    }),
    deactivate: (0, _emberConcurrency.task)(function* () {
      yield this.get('updateStatus').perform('inactive');
    }),
    updateStatus: (0, _emberConcurrency.task)(function* (status) {
      if (this.get('status') === status) {
        return;
      }

      const currentDate = (0, _moment.default)().utc().toDate();

      if (status === 'active' && !this.get('startDate')) {
        this.set('startDate', currentDate);
      } else if (status === 'completed' && !this.get('endDate')) {
        this.set('endDate', currentDate);
      }

      try {
        this.set('status', status);
        yield this.save();
        let readeableStatus = this.intl.t(`engagements.status.${status}`);
        const statusUpdatedMessage = status === 'archived' ? this.get('intl').t('engagement.settings.engagement-deleted') : this.get('intl').t('engagement.settings.engagement-status-updated', {
          status: readeableStatus
        });
        this.clToaster.success(statusUpdatedMessage);
      } catch (e) {
        this.rollbackAttributes();
        throw e;
      }
    })
  });

  _exports.default = _default;
});