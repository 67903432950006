define("coachlogix/services/twilio", ["exports", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * This service helps us to interact with Twilio and keep a global singleton
   * state across the app. We can't delegate this to the component scope
   * because we need to keep Twilio connected even when we're outside of
   * the messaging routes (to listen for new incoming messages, for example).
   *
   * However, this service will not contain the state of the conversations, messages,
   * channels, etc. That is deliberately delegated to the components or controllers that
   * use this service. This service should provide all the needed tools for components to
   * manage their state easily.
   *
   * The reason for this is that we don't want to keep around (potentially) many messages.
   */
  let TwilioService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = (_temp = class TwilioService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _defineProperty(this, "messages", []);

      _defineProperty(this, "users", {});

      _defineProperty(this, "channels", []);

      _defineProperty(this, "dockChannels", []);

      _defineProperty(this, "isTokenExpired", false);

      _defineProperty(this, "hiddenChannel", false);
    }

    /**
     * Initializes the twilio client.
     * Gets the token from the server and creates the twilio client afterwards,
     * using the Twilio js sdk.
     *
     * Will return a previous TwilioClient instance if previously initialized,
     * so it can be safely called multiple times and it will only initialize once.
     *
     * @returns Promise<TwilioClient>
     */
    *initTwilio() {
      if (this.client && !this.isTokenExpired) {
        return this.client;
      }

      try {
        let [Chat, token] = yield (0, _emberConcurrency.all)([emberAutoImportDynamic("twilio-chat"), this.getToken.perform()]);
        let client = yield Chat.Client.create(token);
        this.isTokenExpired = false;
        this.client = client;
        yield this.updateChannels();
        yield this.updateUnreadCount.perform();
        yield this.setupClientListeners();
        return client;
      } catch (e) {
        this.clToaster.error('Failed to initialize twilio client with error:' + e);
      }
    }
    /**
     * Gets a twilio token from the server.
     *
     * @returns Promise<string>
     */


    *getToken() {
      let response = yield this.ajax.request('helpers/twilio-token');
      return response.token;
    }

    *refreshToken() {
      let token = yield this.getToken.perform();
      yield this.client.updateToken(token);
      this.isTokenExpired = false;
    }
    /**
     * Sets up client listeners to keep track of added/removed channels and unread count updates.
     */


    async setupClientListeners() {
      this.client.on('channelJoined', c => {
        this.channels.addObject(c);
        this.updateUnreadCount.perform();
      });
      this.client.on('channelRemoved', c => {
        this.channels.removeObject(c);
        this.updateUnreadCount.perform();
      }); // Fires 3 mins before token expiry.

      this.client.on('tokenAboutToExpire', () => {
        this.refreshToken.perform();
      }); // Triggers at the time of token expiration; our tokens expire in 60 mins.  (Set in /cl-api/app/Services/TwilioService.php)
      // Note that Twilio recommends listening for only one of "tokenAboutToExpire" or "tokenExpired".
      // REF: https://www.twilio.com/docs/chat/access-token-lifecycle
      // this.client.on('tokenExpired', (e) => {
      //   this.refreshToken.perform();
      // });

      for (let c of this.channels) {
        c.on('messageAdded', m => {
          if (m.author !== this.account.activeUser.twilioIdentity) {
            this.updateUnreadCount.perform();
          }
        });
      }
    }

    async updateChannels() {
      let page = await this.client.getSubscribedChannels();
      let items = page.items;

      while (page.hasNextPage) {
        page = await page.nextPage();
        items.push(...page.items);
      }

      for (let channel of items) {
        if (channel.status === 'invited') {
          await channel.join();
        }
      } // for some reason, twilio sometimes returns channels in the "known" status
      // here we make sure that we only consider joined channels that the user can interact with


      let joinedChannels = items.filter(c => c.status === 'joined');
      this.set('channels', joinedChannels);
    }

    *updateUnreadCount() {
      yield (0, _emberConcurrency.timeout)(1500);
      let count = 0;

      for (let c of this.channels) {
        let n = 0;
        /*
         * if `lastConsumedMessageIndex` is null it means that no consumption
         * report was sent yet for this user on this channel.
         * This means that no messages were seen yet, so we use the total messages
         * count as the unread count. Later the component that shows the messages
         * is responsible for advancing the unconsumed message index.
         */

        if (c.lastConsumedMessageIndex === null) {
          n = yield c.getMessagesCount();
        } else {
          n = yield c.getUnconsumedMessagesCount();
        }

        count = count + n;
      }

      this.set('unreadCount', count);
    }

    *goToUserChat(user) {
      if (!user) {
        throw 'The user argument is mandatory';
      }

      if (!this.client) {
        yield this.initTwilio.perform();
      }

      let channel = yield this.createPersonalChannel.perform(user);
      this.dockChannels.addObject(channel);
    }
    /**
     * creates a new channel with the given users
     * channel name format cl-users:{lowest_user_id}-{highest_user_id}
     */


    *createPersonalChannel(users) {
      if (!Ember.isArray(users)) {
        users = [users];
      }

      if (!this.client) {
        throw 'Twilio has not been initialized yet.';
      }

      let userIds = users.mapBy('id');

      if (userIds.includes(this.account.activeUser.id)) {
        throw "You can't create a chat with yourself";
      }

      userIds.push(this.account.activeUser.id);
      userIds = userIds.map(id => parseInt(id, 10));
      userIds = userIds.sort((a, b) => a - b);
      let uniqueName = `cl-users:${userIds.join('-')}`;
      let channel;

      try {
        channel = yield this.client.getChannelByUniqueName(uniqueName);
      } catch (e) {// ignore error
        // console.log('Failed to find personal channel with error:' + e);
      }

      if (!channel) {
        try {
          channel = yield this.client.createChannel({
            uniqueName,
            friendlyName: `${users.mapBy('name').join(', ')} with ${this.account.activeUser.name}`,
            isPrivate: true
          });

          for (let user of users) {
            yield channel.add(`cl-user:${user.get('id')}`);
          }

          yield channel.join();
        } catch (e) {
          this.clToaster.error('Failed to create personal channel with error:' + e);
        }
      }

      return channel;
    }

    *createGroupChannel(topic, users) {
      if (!Ember.isArray(users)) {
        users = [users];
      }

      if (!this.client) {
        throw 'Twilio has not been initialized yet.';
      }

      let userIds = users.mapBy('id');

      if (userIds.includes(this.account.activeUser.id)) {
        throw "You can't create a chat with yourself";
      }

      try {
        let uniqueName = `cl-group:${this.account.activeUser.id}-${Date.now()}`;
        let channel = yield this.client.createChannel({
          uniqueName,
          friendlyName: topic,
          isPrivate: true
        });

        for (let user of users) {
          yield channel.add(`cl-user:${user.get('id')}`);
        }

        yield channel.join();
        return channel;
      } catch (e) {
        this.clToaster.error('Failed to create group channel with error:' + e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initTwilio", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "initTwilio"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getToken", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshToken", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "refreshToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateUnreadCount", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateUnreadCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToUserChat", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "goToUserChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createPersonalChannel", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createPersonalChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createGroupChannel", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createGroupChannel"), _class.prototype)), _class));
  _exports.default = TwilioService;
});