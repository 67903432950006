define("coachlogix/pods/outside/invoice-payment/pay-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KOi19a1T",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[8,\"cl-button\",[[16,\"disabled\",[30,[36,3],[[35,2],[35,1,[\"isRunning\"]]],null]],[17,1]],[[\"@raised\",\"@primary\",\"@onClick\"],[true,true,[30,[36,4],[[32,0,[\"openCheckout\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[32,0,[\"openCheckout\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"ui.loading\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"invoice.pay_with_card\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"openCheckout\",\"disabled\",\"or\",\"perform\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/outside/invoice-payment/pay-button/template.hbs"
    }
  });

  _exports.default = _default;
});