define("coachlogix/validators/presence-if", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-validators"], function (_exports, _validationErrors, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatePresence;

  function validatePresence(options, condition = () => {}) {
    return (key, value, _oldValue, changes, content) => {
      let model = Ember.assign({}, content, changes);

      if (!condition(model)) {
        return true;
      }

      let result = (0, _emberValidators.validate)('presence', value, options, null, key);

      if (typeof result === 'boolean' || typeof result === 'string') {
        return result;
      } else {
        // We flipped the meaning behind `present` and `blank` so switch the two
        if (result.type === 'present') {
          result.type = 'blank';
        } else if (result.type === 'blank') {
          result.type = 'present';
        }

        return (0, _validationErrors.default)(key, result);
      }
    };
  }
});