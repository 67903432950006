define("coachlogix/pods/components/v2/engagement/create-dialog/select-coach-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mF7aiKAz",
    "block": "{\"symbols\":[\"@coachesForClient\",\"@coachesForVendor\",\"@onSelect\",\"@onClose\",\"&attrs\"],\"statements\":[[8,\"cl-employee-selection-dialog\",[[24,0,\"cl-w-32\"],[17,5]],[[\"@coachesForClient\",\"@coachesForVendor\",\"@required\",\"@title\",\"@noOptionsText\",\"@coaches\",\"@onChange\",\"@onClose\",\"@displayEmployeeEngagementAvailability\",\"@closeOnSelect\",\"@auxButtonClick\",\"@auxButtonLabel\"],[[32,1],[32,2],true,[30,[36,0],[\"engagements.create-dialog.select-coach\"],null],[30,[36,0],[\"engagements.create-dialog.no-coaches\"],null],[30,[36,2],[[30,[36,1],[[32,1],[32,2]],null]],null],[32,3],[32,4],true,false,[30,[36,3],[[32,3],null],null],[30,[36,0],[\"employees.select-employee-dialog.assign-later\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"or\",\"not\",\"fn\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement/create-dialog/select-coach-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});