define("coachlogix/pods/personal-settings/general/controller", ["exports", "ember-changeset", "ember-changeset-validations", "ember-concurrency", "ember-concurrency-decorators", "coachlogix/validations/employee", "coachlogix/validations/user"], function (_exports, _emberChangeset, _emberChangesetValidations, _emberConcurrency, _emberConcurrencyDecorators, _employee, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PersonalSettingsGeneralController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.reads('account.activeEmployee'), _dec7 = Ember.computed.reads('account.activeUser'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember.computed('employeeChangeset.isCoach', 'employee.isInternal'), (_class = (_temp = class PersonalSettingsGeneralController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "intercom", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "employee", _descriptor6, this);

      _initializerDefineProperty(this, "user", _descriptor7, this);

      _initializerDefineProperty(this, "editState", _descriptor8, this);

      _initializerDefineProperty(this, "employeeChangeset", _descriptor9, this);

      _initializerDefineProperty(this, "userChangeset", _descriptor10, this);

      _initializerDefineProperty(this, "profileChangeset", _descriptor11, this);
    }

    async enterEditState() {
      this.employeeChangeset = new _emberChangeset.Changeset(this.employee, (0, _emberChangesetValidations.default)(_employee.default), _employee.default);
      this.userChangeset = new _emberChangeset.Changeset(this.user, (0, _emberChangesetValidations.default)(_user.default), _user.default);
      this.userChangeset.validate();
      let profile = await this.user.get('profile');

      if (!profile) {
        profile = this.store.createRecord('profile', {
          user: this.user
        });
        this.user.set('profile', profile);
      }

      this.profileChangeset = new _emberChangeset.Changeset(profile);
      this.editState = true;
    }

    *saveChanges() {
      try {
        yield (0, _emberConcurrency.all)([this.employeeChangeset.save(), this.profileChangeset.save(), this.userChangeset.save()]);
        this.intercom.set('user.name', `${this.user.firstName} ${this.user.lastName}`);
        let timezone = yield this.user.get('timezone');
        let timezoneName = timezone ? timezone.name : null;
        yield this.account.setTimeZone.perform(timezoneName);
        let language = this.user.language ? this.user.language : 'en-us';
        yield this.account.setLocale.perform(language);
        this.clToaster.success(this.intl.t('personal-settings.general.settings-saved'));
        this.editState = false;
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    get canToggleEngagementApproval() {
      return this.employee.isInternal && this.employeeChangeset.isCoach;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intercom", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "employee", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "editState", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "employeeChangeset", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "userChangeset", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "profileChangeset", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "enterEditState", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "enterEditState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canToggleEngagementApproval", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "canToggleEngagementApproval"), _class.prototype)), _class));
  _exports.default = PersonalSettingsGeneralController;
});