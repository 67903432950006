define("coachlogix/pods/components/v2/resources/resources-view/resource-actions/share-dialog/component", ["exports", "@ember-decorators/component", "ember-changeset", "ember-concurrency", "ember-concurrency-decorators", "coachlogix/models/shared-item"], function (_exports, _component, _emberChangeset, _emberConcurrency, _emberConcurrencyDecorators, _sharedItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShareDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('resourceable.modelName'), _dec7 = Ember.computed('account.activeAccount.isEnterprise', 'groups.@each.name', 'searchText', 'sharedItemsChangesets.group'), _dec8 = (0, _emberConcurrencyDecorators.lastValue)('getShares'), _dec9 = (0, _emberConcurrencyDecorators.lastValue)('getEmployees'), _dec10 = Ember.computed('sharedItems.@each.isDeleted'), _dec11 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec(_class = (_class2 = (_temp = class ShareDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "account", _descriptor4, this);

      _defineProperty(this, "searchText", '');

      _initializerDefineProperty(this, "sharedItems", _descriptor5, this);

      _initializerDefineProperty(this, "employees", _descriptor6, this);

      _defineProperty(this, "deletedRecords", []);
    }

    get groups() {
      return _sharedItem.GROUPS.filter(g => {
        if (this.resourceable) {
          let types = g.resourceableTypes || [];
          return types.includes(this.resourceable.modelName);
        } else {
          return !g.resourceableTypes;
        }
      }).map(g => {
        return {
          value: g.value,
          name: this.intl.t(`ui.share-dialog.groups.${g.value}.name`),
          description: this.intl.t(`ui.share-dialog.groups.${g.value}.description`),
          enterpriseOnly: g.enterpriseOnly
        };
      });
    }

    get filteredGroups() {
      return this.groups.filter(g => {
        return g.name.toLowerCase().includes(this.searchText.toLowerCase()) && !this.sharedItemsChangesets.find(s => s.group === g.value) && (g.enterpriseOnly && this.account.activeAccount.isEnterprise || !g.enterpriseOnly);
      });
    }

    get sharedItemsChangesets() {
      let sharedItems = this.sharedItems || [];
      return sharedItems.filter(s => !s.isDeleted).map(s => new _emberChangeset.Changeset(s));
    }

    *getShares() {
      let resource = yield this.store.findRecord(this.resource.modelName, this.resource.id, {
        reload: true,
        include: 'shared-with.employee.user,owner'
      });
      return yield resource.get('sharedWith');
    }

    *getEmployees() {
      yield (0, _emberConcurrency.timeout)(600);
      let sort = 'user.first_name';
      let filter = {
        search: this.searchText
      };

      if (this.engagementCoachee) {
        filter.engagement_coachee = this.engagementCoachee.id;
      }

      if (this.resourceable && this.resourceable.modelName === 'engagement') {
        filter.for_engagement = this.resourceable.id;
      }

      if (this.resourceable && this.resourceable.modelName === 'engagement-session') {
        filter.for_engagement = this.resourceable.get('engagement.id');
      }

      let page = {
        size: 10,
        number: 1
      };
      let include = ['user', 'account'].join(',');
      let employees = yield this.store.query('employee', {
        sort,
        page,
        include,
        filter
      });
      return employees.toArray().filter(e => {
        // filter out users that this resource is already shred with,
        // the current user and the resource owner
        return !this.sharedItemsChangesets.find(s => s.employee !== null && s.employee.id === e.id) && e.id !== this.account.activeEmployee.id && e.id !== this.resource.belongsTo('owner').id();
      });
    }

    *loadSearch(term) {
      this.set('searchText', term);

      if (term.length <= 1) {
        return;
      }

      yield this.getEmployees.perform();
    }

    createSharedItem(attrs) {
      let sharedItem = this.store.createRecord('shared-item', {
        shareable: this.resource,
        sharedByEmployee: this.account.activeEmployee,
        sharedBy: this.account.activeUser,
        ...attrs
      });

      if (this.resourceable && this.resourceable.modelName === 'engagement') {
        sharedItem.set('contextable', this.resourceable);
      }

      if (this.resourceable && this.resourceable.modelName === 'engagement-session') {
        sharedItem.set('contextable', this.resourceable.get('engagement'));
      }

      if (!this.resourceable) {
        if (attrs.employee) {
          sharedItem.set('contextable', attrs.employee.get('account'));
        } else {
          sharedItem.set('contextable', this.account.activeAccount);
        }
      }

      return sharedItem;
    }

    createNewEmployeeShare(employee) {
      this.createSharedItem({
        employee,
        user: employee.get('user')
      });
      this.set('searchText', '');
    }

    createNewGroupShare(group) {
      this.createSharedItem({
        group: group.value
      });
      this.set('searchText', '');
    }

    deleteShare(shareChangeset) {
      let share = this.sharedItems.find(s => s.id === shareChangeset.id);
      share.deleteRecord();
      this.deletedRecords.push(share);
    }

    *saveChanges() {
      try {
        for (let s of this.sharedItemsChangesets) {
          let isSharedEmployee = this.account.activeEmployee.id === s.belongsTo('sharedByEmployee').id();
          let isSharedUser = this.account.activeUser.id === s.belongsTo('sharedBy').id();
          let isOwner = this.account.activeUser.id === this.resource.belongsTo('owner').id();

          if ((isSharedEmployee || isSharedUser || isOwner) && (s.isDirty || s.isNew)) {
            yield s.save();
          }
        }

        let sharedItems = this.sharedItems ? this.sharedItems.toArray() : [];

        for (let s of sharedItems) {
          if (s.isDeleted) {
            yield s.save();
          }
        }

        this.resource.reload(); // reload resource to get back the correct isShare, permissions, etc

        this.clToaster.success(this.intl.t('resources.sharing-permission-saved'));
        this.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    discardChanges() {
      let sharedItems = this.sharedItems ? this.sharedItems.toArray() : [];

      for (let s of sharedItems) {
        if (s.isDeleted) {
          s.rollbackAttributes();
        }
      }

      this.onClose?.();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "groups", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "groups"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "filteredGroups", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "filteredGroups"), _class2.prototype), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "sharedItems", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "employees", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "sharedItemsChangesets", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "sharedItemsChangesets"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getShares", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "getShares"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getEmployees", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "getEmployees"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadSearch", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "loadSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createNewEmployeeShare", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "createNewEmployeeShare"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createNewGroupShare", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "createNewGroupShare"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteShare", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteShare"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "discardChanges", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "discardChanges"), _class2.prototype)), _class2)) || _class);
  var _default = ShareDialog;
  _exports.default = _default;
});