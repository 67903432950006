define("coachlogix/pods/my-marketplace/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MyMarketIdxPlaceRoute extends Ember.Route {
    redirect() {
      this.replaceWith('my-marketplace.orders');
    }

  }

  _exports.default = MyMarketIdxPlaceRoute;
});