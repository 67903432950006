define("coachlogix/models/goal", ["exports", "@ember-data/model", "ember-concurrency", "coachlogix/models/base-model", "coachlogix/models/commentable", "coachlogix/models/competenciable", "coachlogix/models/reviewable", "coachlogix/models/stakeholderable", "coachlogix/models/taskable"], function (_exports, _model, _emberConcurrency, _baseModel, _commentable, _competenciable, _reviewable, _stakeholderable, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_competenciable.default, _commentable.default, _reviewable.default, _stakeholderable.default, _taskable.default, {
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    dueDate: (0, _model.attr)('date'),
    requiresSignoff: (0, _model.attr)('boolean'),
    requiresReview: (0, _model.attr)('boolean'),
    completionDate: (0, _model.attr)('date'),
    assignedDate: (0, _model.attr)('date'),
    startDate: (0, _model.attr)('string'),
    isPrivate: (0, _model.attr)('boolean'),
    user: (0, _model.belongsTo)('user', {
      async: true,
      inverse: null
    }),
    creator: (0, _model.belongsTo)('employee', {
      async: true,
      inverse: null
    }),
    goalable: (0, _model.belongsTo)('base-model', {
      polymorphic: true
    }),
    parent: (0, _model.belongsTo)('goal', {
      async: true,
      inverse: 'subGoals'
    }),
    goalApproach: (0, _model.belongsTo)('goal-approach', {
      async: true,
      inverse: null
    }),
    subGoals: (0, _model.hasMany)('goal', {
      async: true,
      inverse: 'parent'
    }),
    engagement: Ember.computed('goalable.modelName', function () {
      if (this.get('goalable.modelName') === 'engagement') {
        return this.get('goalable');
      }
    }),
    availableStakeholders: Ember.computed('engagement.potentialStakeholders.[]', 'stakeholderEmployees.[]', function () {
      let stakeholders = this.get('stakeholderEmployees');
      return this.get('engagement.potentialStakeholders').filter(stakeholder => {
        if (stakeholders && stakeholders.includes(stakeholder)) {
          return false;
        }

        return true;
      });
    }),
    stakeholderEmployeesPromises: Ember.computed.mapBy('stakeholders', 'employee'),
    stakeholderEmployees: Ember.computed.mapBy('stakeholderEmployeesPromises', 'content'),
    isCompleted: Ember.computed.equal('status', 'completed'),
    subTaskAssignmentArrays: Ember.computed.mapBy('tasks', 'taskAssignments'),
    subTaskAssignments: Ember.computed('subTaskAssignmentArrays.[]', function () {
      return Ember.ArrayProxy.create({
        content: this.get('subTaskAssignmentArrays').map(subTaskAssignmentArray => {
          return subTaskAssignmentArray.get('firstObject');
        })
      });
    }),
    completedSubTaskAssignments: Ember.computed('subTaskAssignments.@each.isLoaded', function () {
      return this.get('subTaskAssignments').filter(assignment => {
        return assignment && assignment.get('isLoaded') && assignment.get('completionDate');
      });
    }),
    subTaskProgress: Ember.computed('subTaskAssignments.[]', 'completedSubTaskAssignments.[]', function () {
      let total = this.get('subTaskAssignments.length');
      let completed = this.get('completedSubTaskAssignments.length');
      return Math.round(completed / total * 1e2);
    }),
    toggleCompletion: (0, _emberConcurrency.task)(function* () {
      if (!this.get('isCompleted')) {
        this.set('completionDate', new Date());
        this.set('status', 'completed');
      } else {
        this.set('completionDate', null);
        this.set('status', 'published');
      }

      yield this.save();
      this.clToaster.success(this.intl.t('notifications.goal.status-updated'));
    }).drop(),
    updateTitle: (0, _emberConcurrency.task)(function* (title) {
      this.set('title', title);
      yield this.get('saveTask').perform();
    }),
    updateDescription: (0, _emberConcurrency.task)(function* (description) {
      this.set('description', description);
      yield this.get('saveTask').perform();
    }),
    updateDueDate: (0, _emberConcurrency.task)(function* (dueDate) {
      this.set('dueDate', dueDate);
      yield this.save();
    }),
    requestFeedback: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.store.createRecord('task', {
          title: this.get('title'),
          taskType: 'feedback_request',
          taskable: this
        }).save();
        this.clToaster.success('Request sent.');
      } catch (e) {
        this.clToaster.error(e);
      }
    }).drop(),
    delete: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.destroyRecord();
        const deleteMessage = this.intl.t('ui.goal.deleted');
        this.clToaster.success(deleteMessage);
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    intl: Ember.inject.service()
  });

  _exports.default = _default;
});