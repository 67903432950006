define("coachlogix/pods/engagement/matching-process/shared-coaches/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let MatchingProcessSharedCoaches = (_dec = (0, _emberConcurrencyDecorators.restartableTask)({
    cancelOn: 'deactivate'
  }), _dec2 = Ember._action, (_class = (_temp = class MatchingProcessSharedCoaches extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        filter: {
          refreshModel: true
        }
      });

      _defineProperty(this, "pageNumber", 1);

      _defineProperty(this, "pageSize", 9);

      _defineProperty(this, "didRequest", false);
    }

    model({
      filter
    }) {
      this.pageNumber = 1;
      filter = Ember.isPresent(filter) ? JSON.parse(filter) : {};
      return {
        engagement: this.modelFor('engagement.matching-process').engagement,
        coachPoolCoachesTask: this.loadCoachPoolCoaches.perform(filter),
        filter
      };
    }

    *loadCoachPoolCoaches(filters) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      let controller = this.controllerFor('engagement.matching-process.shared-coaches');
      controller.isLoading = true;
      let sort = filters?.sort;
      let search = filters?.search;
      delete filters?.sort;
      delete filters?.search;
      let engagement = yield this.modelFor('engagement.matching-process').engagement;
      let coachPools = yield engagement.get('coachPools');
      let coachPoolId = coachPools.firstObject.id;
      let include = ['employee.user.profile.coaching-categorizations', 'employee.account', 'employee.department', 'employee.client-coaches', 'employee.user.timezone', 'employee.user.availability-preferences.default-meeting-location', 'employee.user.profile', 'employee.coaching-requests', 'employee.coaching-requests.coach.user', 'employee.coaching-requests.coachee.user'].join(',');
      let coachPoolCoaches = yield this.store.query('coach-pool-coach', {
        filter: {
          coach_pool_id: coachPoolId,
          include_filter_params: this.didRequest ? 0 : 1,
          filters,
          search,
          employee_status: 'connected' // requests only connected coaches

        },
        page: {
          size: this.pageSize,
          number: this.pageNumber
        },
        sort,
        include
      });
      this.didRequest = true;

      if (coachPoolCoaches.meta) {
        controller.filters = coachPoolCoaches.meta.filters;
      }

      if (coachPoolCoaches.length < this.pageSize) {
        controller.moreCoaches = false;
      }

      controller.isLoading = false;
      this.pageNumber++;
      return coachPoolCoaches.toArray();
    }

    async loadNext() {
      let newCoachPoolCoaches = await this.loadCoachPoolCoaches.perform();
      let {
        coachPoolCoachesTask
      } = this.modelFor('engagement.matching-process.shared-coaches');
      coachPoolCoachesTask.value?.addObjects(newCoachPoolCoaches);
    }

    resetController(controller) {
      controller.moreCoaches = true;
      controller.isLoading = false;
      controller.filter = '{}';
      this.pageNumber = 1;
      this.didRequest = false;
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "loadCoachPoolCoaches", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loadCoachPoolCoaches"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadNext", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "loadNext"), _class.prototype)), _class));
  _exports.default = MatchingProcessSharedCoaches;
});