define("coachlogix/abilities/contact", ["exports", "ember-can", "ember-can/computed", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _computed, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    clientAbility: (0, _computed.ability)('client', 'model.client'),
    modelEmployee: Ember.computed.reads('model.employee'),
    modelClient: Ember.computed.reads('model.client'),
    canApprove: Ember.computed('clientAbility.canEdit', 'model.{modelName,contactStatus}', 'permissions.[]', function () {
      return (this.get('clientAbility.canEdit') || this.hasPermission('manage_account_clients')) && this.get('model.contactStatus') === 'your_approval_needed';
    }),
    canSendInvite: Ember.computed('clientAbility.canEdit', 'modelEmployee.status', 'model.{modelName,contactStatus,originatedBy}', 'modelClient.{isClient,isInternalRecord,isInvited}', function () {
      if (this.get('clientAbility.canEdit') || this.hasPermission('manage_account_clients')) {
        let client = this.get('model.client');
        let orgByClientOrVendorAndIsVendorOrVendor = this.get('model.originatedBy') === 'vendor' && client.get('isClient') || this.get('model.originatedBy') === 'client' && client.get('isVendor');
        let isInvitable = this.get('model.employee.status') === 'internal_record' || this.get('model.employee.status') === 'invited';

        if (orgByClientOrVendorAndIsVendorOrVendor && isInvitable && (client.get('isInternalRecord') || client.get('isInvited') || client.get('theOtherAccount.isFree'))) {
          return true;
        }
      }

      return false;
    }),
    // if contact is a coachee and client isClient and client isInternalRecord or isInvited
    // or
    // if contact is a coach and client isVendor and client isInternalRecord or isInvited
    canRemove: Ember.computed('clientAbility.canEdit', 'model.modelName', 'model.client.{isClient,isVendor,isInternalRecord,isInvited}', function () {
      let client = this.get('model.client');

      if (this.get('clientAbility.canEdit') || this.hasPermission('manage_account_clients')) {
        if (this.get('model.modelName') == 'client-coachee' && client.get('isClient') || this.get('model.modelName') == 'client-coach' && client.get('isVendor')) {
          return true;
        }
      }

      return false;
    }),
    // if contact is a coach and client isClient
    // or
    // if contact is a coachee and client isVendor
    canStopSharing: Ember.computed('clientAbility.canEdit', 'model.modelName', 'model.client.{isClient,isVendor,isActive}', function () {
      let client = this.get('model.client');

      if (this.get('clientAbility.canEdit') || this.hasPermission('manage_account_clients')) {
        if (this.get('model.modelName') == 'client-coach' && client.get('isClient') || this.get('model.modelName') == 'client-coachee' && client.get('isVendor') && client.get('isActive')) {
          return true;
        }
      }

      return false;
    })
  });

  _exports.default = _default;
});