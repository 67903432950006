define("coachlogix/models/subscription", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    status: (0, _model.attr)('string'),
    price: (0, _model.attr)('string'),
    trialEndsAt: (0, _model.attr)('date'),
    endsAt: (0, _model.attr)('date'),
    quantity: (0, _model.attr)('number'),
    seatQuantity: (0, _model.attr)('number'),
    basePrice: (0, _model.attr)('number'),
    seatPrice: (0, _model.attr)('number'),
    discountAmount: (0, _model.attr)('number'),
    account: (0, _model.belongsTo)('account', {
      inverse: 'subscription'
    }),
    plan: (0, _model.belongsTo)('plan', {
      inverse: null
    }),
    stripeCard: (0, _model.belongsTo)('stripe-card', {
      inverse: null
    }),
    isDelinquent: Ember.computed.equal('status', 'delinquent')
  });

  _exports.default = _default;
});