define("coachlogix/pods/components/v2/stripe-card-input/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StripeInputComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service('stripe-old'), _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = (_class2 = (_temp = class StripeInputComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stripe", _descriptor, this);
    }

    mountStripeElement(element) {
      let elements = this.stripe.stripe.elements(); // Create an instance of the card Element

      let card = elements.create('card', {
        style: {
          base: {
            iconColor: 'rgba(0, 0, 0, 0.38)',
            fontSize: '12px',
            fontFamily: '"Inter", "Roboto", "Helvetica Neue", sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              opacity: 0.5
            },
            color: '#757c88'
          }
        }
      });

      if (typeof this.cardCreated === 'function') {
        this.cardCreated(card);
      } // Add an instance of the card Element into the `card-element` <div>


      card.mount(element);
      card.addEventListener('change', Ember.run.bind(this, this.onChange));
      this.cardElement = card;
    }

    unmountStripeElement() {
      this.cardElement.unmount();
    }

    onChange({
      complete,
      error
    }) {
      if (typeof this.onComplete === 'function') {
        this.onComplete(complete);
      }

      if (error) {
        this.set('error', error.message);
      } else {
        this.set('error', null);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "stripe", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "mountStripeElement", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "mountStripeElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unmountStripeElement", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "unmountStripeElement"), _class2.prototype)), _class2)) || _class);
  var _default = StripeInputComponent;
  _exports.default = _default;
});