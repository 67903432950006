define("coachlogix/validations/user", ["exports", "ember-changeset-validations/validators", "coachlogix/utils/email-regex"], function (_exports, _validators, _emailRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }),
    lastName: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }),
    email: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }), (0, _validators.validateFormat)({
      regex: _emailRegex.default,
      allowBlank: true
    })],
    latitude: (0, _validators.validateNumber)({
      allowBlank: true
    }),
    longitude: (0, _validators.validateNumber)({
      allowBlank: true
    })
  };
  _exports.default = _default;
});