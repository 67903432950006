define("coachlogix/models/programmable", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /* Attributes */
    programItems: (0, _model.hasMany)('program-item', {
      async: true,
      inverse: null
    }),

    /* Single Line */
    createProgramItem: (0, _emberConcurrency.taskGroup)().enqueue(),
    sortedProgramItems: Ember.computed.sort('programItems', 'programItemSortDefinition'),

    /* Lifecycle */
    clToaster: Ember.inject.service(),
    router: Ember.inject.service(),

    /* API */
    getNextProgramItemPosition: (0, _emberConcurrency.task)(function* () {
      try {
        let programItems = yield this.get('programItems');
        let positions = programItems.mapBy('position');

        if (positions.length) {
          return Math.max(...positions) + 1;
        }

        return 1;
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    programItemSortDefinition: ['isCompleted', 'position'],

    /* Lifecycle */
    intl: Ember.inject.service(),
    addSession: (0, _emberConcurrency.task)(function* () {
      yield this.get('createSession').perform();
    }),
    addTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('createTask').perform();
    }),
    addForm: (0, _emberConcurrency.task)(function* (form) {
      yield this.get('createForm').perform(form);
    }),
    addFile: (0, _emberConcurrency.task)(function* (file) {
      yield this.get('createFile').perform(file);
    }),
    addLink: (0, _emberConcurrency.task)(function* (link) {
      yield this.createLink.perform(link);
    }),
    createFile: (0, _emberConcurrency.task)(function* (file) {
      file = yield file;

      try {
        let position = yield this.get('getNextProgramItemPosition').perform();
        let fileTask = this.get('store').createRecord('task', {
          title: file.get('name'),
          resource: file,
          taskType: 'file'
        });

        if (this.get('modelName') === 'engagement') {
          fileTask.set('engagement', this);
        } else if (this.get('modelName') === 'engagement-template') {
          fileTask.set('taskable', this);
        }

        yield fileTask.save();
        let item = this.get('store').createRecord('program-item', {
          program: this,
          item: fileTask,
          position
        });
        yield item.save();
        this.get('programItems').pushObject(item);
        this.clToaster.success(this.intl.t('engagement-task.file-saved'));
        return item;
      } catch (e) {
        this.clToaster.error(e);
      }
    }).group('createProgramItem'),
    createForm: (0, _emberConcurrency.task)(function* (form) {
      try {
        let position = yield this.get('getNextProgramItemPosition').perform();
        let formTask = this.get('store').createRecord('task', {
          title: form.get('name'),
          resource: form,
          taskType: 'form'
        });

        if (this.get('modelName') === 'engagement') {
          formTask.set('engagement', this);
        } else if (this.get('modelName') === 'engagement-template') {
          formTask.set('taskable', this);
        }

        yield formTask.save();
        let item = this.get('store').createRecord('program-item', {
          program: this,
          item: formTask,
          position
        });
        yield item.save();
        this.get('programItems').pushObject(item);
        this.clToaster.success(this.intl.t('engagement-task.form-saved'));
        return item;
      } catch (e) {
        this.clToaster.error(e);
      }
    }).group('createProgramItem'),
    createSession: (0, _emberConcurrency.task)(function* (attrs = {}) {
      let position = yield this.get('getNextProgramItemPosition').perform();
      attrs.name = this.get('intl').t('engagement-session.default-title');

      if (this.get('modelName') === 'engagement') {
        attrs.engagement = this;
      } else if (this.get('modelName') === 'engagement-template') {
        attrs.sessionable = this;
        attrs.templateDefaultCoach = true;
        attrs.templateDefaultCoachee = true;
      }

      let meetingLocation = yield this.get('meetingLocation');

      if (meetingLocation) {
        attrs.meetingLocation = meetingLocation;
      }

      let session = this.get('store').createRecord('engagement-session', attrs);
      let item = this.get('store').createRecord('program-item', {
        program: this,
        item: session,
        position
      });

      try {
        let participants = [];
        let [coach, coachee] = yield (0, _emberConcurrency.all)([this.get('coach'), this.get('coachee')]);

        if (coach) {
          participants.push(coach);
        }

        if (coachee) {
          participants.push(coachee);
        }

        session.set('participants', participants);
        yield session.save();
        yield item.save();
        this.clToaster.success(this.intl.t('engagement-session.session-saved'));
        this.get('programItems').pushObject(item);
        return item;
      } catch (e) {
        yield session.destroyRecord();
        yield item.destroyRecord();
        this.clToaster.error(e);
      }
    }).group('createProgramItem'),
    createTask: (0, _emberConcurrency.task)(function* ()
    /* programItemPosition */
    {
      let position = yield this.get('getNextProgramItemPosition').perform();
      let engagementTask = this.get('store').createRecord('task', {
        status: 'draft',
        taskType: 'todo'
      });

      if (this.get('modelName') === 'engagement') {
        engagementTask.set('engagement', this);
      } else if (this.get('modelName') === 'engagement-template') {
        engagementTask.set('taskable', this);
      }

      let item = this.get('store').createRecord('program-item', {
        program: this,
        item: engagementTask,
        position
      });

      try {
        yield engagementTask.save();
        yield item.save();
        this.get('programItems').pushObject(item);
        this.clToaster.success(this.intl.t('engagement-task.task-saved'));
        return item;
      } catch (e) {
        this.clToaster.error(e);
      }
    }).group('createProgramItem'),
    createLink: (0, _emberConcurrency.task)(function* (link) {
      try {
        let position = yield this.getNextProgramItemPosition.perform();
        let linkTask = this.store.createRecord('task', {
          title: link.get('name'),
          description: link.get('description'),
          resource: link,
          taskType: 'link'
        });

        if (this.modelName === 'engagement') {
          linkTask.set('engagement', this);
        } else if (this.get('modelName') === 'engagement-template') {
          linkTask.set('taskable', this);
        }

        yield linkTask.save();
        let item = this.store.createRecord('program-item', {
          program: this,
          item: linkTask,
          position
        });
        yield item.save();
        this.get('programItems').pushObject(item);
        this.clToaster.success(this.intl.t('engagement-task.link-saved'));
        return item;
      } catch (e) {
        this.clToaster.error(e);
      }
    }).group('createProgramItem'),
    deleteItemable: (0, _emberConcurrency.task)(function* (itemable) {
      try {
        let programItem = yield itemable.get('programItem');
        yield itemable.deleteTask.perform(); // When we delete an itemable (a session, task, etc), the backend cascade deletes its program-item as well,
        // so we can unload it from memory to get rid of it on the frontend without any DELETE request.
        // However, because of an ember-data bug: https://github.com/emberjs/data/issues/7113
        // unloading a record is not enough to remove it from every async relationship
        // so we have to manually remove it from the engagement/program-template programItems.

        let programItems = yield this.get('programItems');
        programItems.removeObject(programItem);
        programItem.unloadRecord();
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});