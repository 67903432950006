define("coachlogix/pods/components/ui/empty-state/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'flex md-padding layout-column layout-align-center-center empty-state',
    iconSize: 64
  });

  _exports.default = _default;
});