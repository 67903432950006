define("coachlogix/services/auth0", ["exports", "ember-simple-auth-auth0/services/auth0", "lodash.merge"], function (_exports, _auth, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _auth.default.extend(Ember.Evented, {
    config: Ember.computed({
      get() {
        let emberSimpleAuthConfig = Ember.get(this, '_environmentConfig')['ember-simple-auth'];
        (true && !(emberSimpleAuthConfig) && Ember.assert('ember-simple-auth config must be defined', emberSimpleAuthConfig));
        (true && !(emberSimpleAuthConfig.auth0) && Ember.assert('ember-simple-auth.auth0 config must be defined', emberSimpleAuthConfig.auth0));
        let useUSTenant = window.location.search.includes('auth0_tenant=us');

        if (useUSTenant) {
          (true && !(emberSimpleAuthConfig.auth0US) && Ember.assert('ember-simple-auth.auth0US config must be defined (app was booted with auth0_tenant=us)', emberSimpleAuthConfig.auth0US));
          emberSimpleAuthConfig.auth0 = (0, _lodash.default)({}, emberSimpleAuthConfig.auth0, emberSimpleAuthConfig.auth0US);
        }

        if (window.location.hash.includes('id_token=')) {
          emberSimpleAuthConfig.auth0.domain = useUSTenant ? 'coachlogix.auth0.com' : 'coachlogix.eu.auth0.com';
          delete emberSimpleAuthConfig.auth0.lockOptions.configurationBaseUrl;
          delete emberSimpleAuthConfig.auth0.lockOptions.overrides;
        }

        return emberSimpleAuthConfig.auth0;
      }

    }),
    isSigninEnabled: Ember.computed.equal('currentScreen', 'signin'),
    isSignupEnabled: Ember.computed.equal('currentScreen', 'signup'),

    _setupLock(lock) {
      this._super(...arguments);

      this._lock = lock;
      lock.on('signin ready', () => {
        if (this.isDestroyed) {
          return;
        }

        this.set('currentScreen', 'signin');
        this.trigger('screenChange', 'signin');
      });
      lock.on('signup ready', () => {
        if (this.isDestroyed) {
          return;
        }

        this.set('currentScreen', 'signup');
        this.trigger('screenChange', 'signup');
      });
    },

    hide() {
      if (this._lock) {
        this._lock.hide();
      }
    }

  });

  _exports.default = _default;
});