define("coachlogix/pods/program-template/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProgramTemplateRoute extends Ember.Route {
    redirect() {
      this.transitionTo('program-template.program');
    }

  }

  _exports.default = ProgramTemplateRoute;
});