define("coachlogix/transforms/boolean", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize(serialized, options) {
      if (Ember.isNone(serialized) && options.allowNull === true) {
        return null;
      }

      let type = typeof serialized;

      if (type === 'boolean') {
        return serialized;
      } else if (type === 'string') {
        return /^(true|t|1)$/i.test(serialized);
      } else if (type === 'number') {
        return serialized === 1;
      } else {
        return false;
      }
    },

    serialize(deserialized, options) {
      if (Ember.isNone(deserialized) && options.allowNull === true) {
        return deserialized;
      }

      return deserialized ? 1 : 0;
    }

  });

  _exports.default = _default;
});