define("coachlogix/templates/components/code-snippet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BTpEQ9UY",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"source\"]}",
    "meta": {
      "moduleName": "coachlogix/templates/components/code-snippet.hbs"
    }
  });

  _exports.default = _default;
});