define("coachlogix/utils/intl/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;

  function missingMessage(key, locales) {
    if (Ember.isEmpty(locales)) {
      (true && Ember.warn(`[ember-intl] no locale has been set. Documentation: https://github.com/jasonmit/ember-intl#setting-runtime-locale`, false, {
        id: 'ember-intl-no-locale-set'
      }));
      return `No locale defined.  Unable to resolve translation: "${key}"`;
    }

    let localeNames = locales.join(', ');
    (true && Ember.warn(`[ember-intl] translation: "${key}" on locale: "${localeNames}" was not found.`, false, {
      id: 'ember-intl-missing-translation'
    }));
    return key.split('.').reverse()[0];
  }
});