define("coachlogix/models/engagement-comment", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    comment: (0, _model.attr)('string'),
    isPublic: (0, _model.attr)('boolean'),
    // engagement: belongsTo('engagement', { inverse: null }),
    // engagementSession: belongsTo('engagement-session', { inverse: null }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    })
  });

  _exports.default = _default;
});