define("coachlogix/models/assignable", ["exports", "@ember-data/model", "ember-data-copyable", "ember-concurrency", "qs-stringify"], function (_exports, _model, _emberDataCopyable, _emberConcurrency, _qsStringify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_emberDataCopyable.default, {
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    clToaster: Ember.inject.service(),
    assignments: (0, _model.hasMany)('assignment', {
      inverse: null
    }),
    assignees: (0, _model.hasMany)('employee', {
      async: true,
      inverse: null
    }),
    // this will get all the form submissions from any assignments
    formSubmissions: Ember.computed('assignments.@each.formSubmissions', function () {
      return this.get('assignments').reduce((prev, a) => {
        let submissions = a.get('formSubmissions') || [];
        return prev.concat(submissions.toArray());
      }, []);
    }),
    ownAssignment: Ember.computed('accountService.activeEmployee.id', 'assignments.@each.assignee', function () {
      let activeEmployeeId = this.accountService.activeEmployee.id;
      return this.get('assignments').find(a => {
        return a.belongsTo('assignee').id() === activeEmployeeId;
      });
    }),
    createAssignment: (0, _emberConcurrency.task)(function* (assignee) {
      let intl = this.get('intl');
      assignee = yield assignee;
      let assignments = yield this.get('assignments');
      let assignees = yield (0, _emberConcurrency.all)(assignments.mapBy('assignee'));

      if (assignees && assignees.includes(assignee)) {
        const toastMessage = this.get('intl').t('engagement-task.assignee-already-added');
        return this.clToaster.info(toastMessage);
      }

      let assignment = this.store.createRecord('assignment', {
        assignable: this,
        assignee
      });

      try {
        yield assignment.save();
        this.get('assignments').pushObject(assignment);
        const employee = assignee.get('name');
        let toastMessage = '';

        switch (this.get('taskType')) {
          case 'todo':
            toastMessage = intl.t('engagement-task.todo-assignee-added');
            break;

          case 'form':
            toastMessage = intl.t('engagement-task.recipient-added');
            break;

          case 'file':
            toastMessage = intl.t('engagement-task.recipient-added', {
              recipient: employee
            });
            break;

          default:
            toastMessage = intl.t('engagement-task.user-added', {
              user: assignee.get('user.name')
            });
        }

        this.clToaster.success(toastMessage);
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    removeAssignment: (0, _emberConcurrency.task)(function* (assignment) {
      try {
        this.get('assignments').removeObject(assignment);
        yield assignment.destroyRecord();
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    progress: Ember.computed('assignments.@each.status', function () {
      let assignments = this.get('assignments') || [];
      let total = assignments.get('length');
      let completedCount = assignments.filterBy('status', 'completed').get('length');

      if (total === 0) {
        return 0;
      }

      return Math.round(completedCount / total * 100);
    }),
    resend: (0, _emberConcurrency.task)(function* () {
      const intl = this.get('intl');

      try {
        let assignments = yield this.get('assignments');
        let assignmentIds = assignments.mapBy('id');
        let typeKey = this.get('notificationType');
        let queryString = (0, _qsStringify.default)({
          notifiable_type: 'assignments',
          notifiable_id: assignmentIds,
          type_key: typeKey
        });
        yield this.get('ajax').post(`/helpers/resend-notification?${queryString}`);
        yield assignments.get('firstObject').reload();
        let taskType = this.get('taskType');

        if (taskType === 'todo') {
          taskType = 'Task';
        }

        if (taskType) {
          const toastMessage = intl.t('engagement-task.send-task-again', {
            taskType: taskType.capitalize()
          });
          this.clToaster.success(toastMessage);
        } else {
          const toastMessage = intl.t('engagement-task.resend-notifications');
          this.clToaster.success(toastMessage);
        }
      } catch (error) {
        const errorMsg = error.payload && error.payload.errors ? error.payload.errors[0] : error;

        if (errorMsg.startsWith('Reminder sent')) {
          const toastMessage = intl.t('engagement-task.can-send-once-every-24-hours');
          this.clToaster.error(toastMessage);
        } else {
          this.clToaster.error(errorMsg);
        }
      }
    })
  });

  _exports.default = _default;
});