define("coachlogix/models/user-notification-setting", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    language: (0, _model.belongsTo)('language'),
    messagingType: (0, _model.belongsTo)('messaging-type'),
    email: (0, _model.belongsTo)('email'),
    sms: (0, _model.belongsTo)('sms'),
    user: (0, _model.belongsTo)('user')
  });

  _exports.default = _default;
});