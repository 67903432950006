define("coachlogix/pods/video/settings/volume-meter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+RNjxdzB",
    "block": "{\"symbols\":[\"@audioTrack\",\"&attrs\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"handleAudioTrackUpdate\"]],[32,1]],null]],[2,\"\\n\"],[1,[30,[36,1],[[32,0,[\"handleAudioTrackUpdate\"]],[32,1]],null]],[2,\"\\n\"],[1,[30,[36,2],[[32,0,[\"handleAudioTrackUpdate\"]],false],null]],[2,\"\\n\\n\"],[11,\"svg\"],[24,\"viewBox\",\"0 0 300 17\"],[24,\"preserveAspectRatio\",\"xMinYMid meet\"],[24,0,\"cl-w-full\"],[24,\"height\",\"17\"],[17,2],[12],[2,\"\\n  \"],[10,\"defs\"],[12],[2,\"\\n    \"],[10,\"pattern\"],[14,1,\"Pattern\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[14,\"width\",\"12\"],[14,\"height\",\"17\"],[14,\"patternUnits\",\"userSpaceOnUse\"],[12],[2,\"\\n      \"],[10,\"rect\"],[14,\"x\",\"8\"],[14,\"y\",\"0\"],[14,\"width\",\"4\"],[14,\"height\",\"17\"],[14,\"fill\",\"black\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"mask\"],[14,1,\"myMask\"],[12],[2,\"\\n    \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[14,\"width\",\"100%\"],[14,\"height\",\"17\"],[14,\"fill\",\"white\"],[12],[13],[2,\"\\n    \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[14,\"width\",\"100%\"],[14,\"height\",\"17\"],[14,\"fill\",\"url(#Pattern)\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[14,\"width\",\"100%\"],[14,\"height\",\"17\"],[14,\"fill\",\"red\"],[14,\"mask\",\"url(#myMask)\"],[14,0,\"cl-fill-current cl-text-pale-grey\"],[12],[13],[2,\"\\n  \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[15,\"width\",[31,[[32,0,[\"currentVolume\"]],\"%\"]]],[14,\"height\",\"17\"],[14,\"fill\",\"red\"],[14,\"mask\",\"url(#myMask)\"],[14,0,\"cl-fill-current cl-text-steel-grey cl-transition-all cl-ease-out-exp cl-duration-200\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/video/settings/volume-meter/template.hbs"
    }
  });

  _exports.default = _default;
});