define("coachlogix/models/account-stripe-card", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    isDefault: (0, _model.attr)('boolean'),
    creditNumberPattern: (0, _model.attr)('string'),
    stripeCard: (0, _model.belongsTo)('stripe-card', {
      inverse: null
    }),
    account: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    companyLocation: (0, _model.belongsTo)('company-location', {
      inverse: null
    })
  });

  _exports.default = _default;
});