define("coachlogix/pods/components/v2/engagement/create-dialog/select-contract-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RZGX4Gci",
    "block": "{\"symbols\":[\"contract\",\"@onClose\",\"@selectedContract\",\"@onSelect\",\"@client\",\"@optional\",\"&attrs\"],\"statements\":[[8,\"cl-selection-dialog\",[[24,0,\"cl-w-32\"],[17,7]],[[\"@title\",\"@onClose\",\"@closeOnSelect\",\"@options\",\"@selected\",\"@required\",\"@search\",\"@searchField\",\"@onChange\",\"@noOptionsText\",\"@auxButtonClick\",\"@auxButtonLabel\"],[[31,[[30,[36,0],[\"engagements.create-dialog.select-contract\"],null],\" \",[30,[36,2],[[32,0,[\"accountName\"]],[30,[36,1],[[30,[36,0],[\"engagements.create-dialog.for\"],null],\" \",[32,0,[\"accountName\"]]],null]],null]]],[32,2],false,[32,0,[\"contracts\"]],[32,3],true,true,[30,[36,3],[\"name\",\"description\"],null],[32,4],[30,[36,0],[\"engagements.create-dialog.no-contracts\"],null],[30,[36,2],[[32,6],[30,[36,5],[[32,4],null],null],[30,[36,2],[[32,5],[30,[36,4],[[32,0,[\"redirectToClient\"]]],null]],null]],null],[30,[36,2],[[32,6],[30,[36,0],[\"ui.skip\"],null],[30,[36,0],[\"engagements.create-dialog.create-contract\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,2],[[32,1,[\"description\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"if\",\"array\",\"perform\",\"fn\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement/create-dialog/select-contract-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});