define("coachlogix/services/account", ["exports", "@sentry/browser", "ember-concurrency", "moment", "coachlogix/config/environment"], function (_exports, Sentry, _emberConcurrency, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // maps ember-intl locales to moment locales
  const MOMENT_LOCALES = {
    'en-us': 'en',
    'fr-fr': 'fr'
  };
  /**
   * @public
   * Account service per ember-simple-auth docs:
   * https://github.com/simplabs/ember-simple-auth/blob/master/guides/managing-current-user.md
   */

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    paperTheme: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    intercom: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    orders: Ember.inject.service(),
    twilio: Ember.inject.service(),
    moment: Ember.inject.service(),
    powerCalendar: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    clTheme: Ember.inject.service(),
    activeAccount: null,
    activeUser: null,
    activeEmployee: null,
    activeRoleUsers: null,
    activeRoles: null,
    activePermissions: null,
    accountSummary: null,
    otherAccounts: null,
    data: null,
    engagementsNumber: Ember.computed.reads('account.activeAccount.engagements.length'),
    firstEngagementId: Ember.computed.reads('account.activeAccount.engagements.firstObject'),

    hasOnlyRoles(...values) {
      let roles = this.get('activeRoles') || [];
      return values.length === roles.length && values.every(v => {
        return roles.includes(v);
      });
    },

    isCoachee: Ember.computed('activeEmployee.isCoach', 'activeRoles.{firstObject,length}', function () {
      const isCoach = this.get('activeEmployee.isCoach');
      const hasCoacheeRoles = this.hasOnlyRoles('engagement_viewer') || this.hasOnlyRoles('engagement_editor');
      const hasNoRoles = this.get('activeRoles.length') === 0 || this.get('activeRoles.length') === 1 && this.get('activeRoles.firstObject') === 'member_account_owner';
      return (hasNoRoles || hasCoacheeRoles) && !isCoach;
    }),
    isDirectCoachee: Ember.computed('activeEmployee.isCoach', 'activeRoles.{firstObject,length}', function () {
      return this.get('activeRoles.length') === 1 && this.get('activeRoles.firstObject') === 'member_account_owner' && !this.get('activeEmployee.isCoach');
    }),
    roles: Ember.computed(function () {
      return this.get('store').findAll('roles');
    }),
    engagementRoles: Ember.computed(function () {
      return this.get('store').findAll('role', {
        filter: {
          content_type: 'engagements'
        }
      });
    }),

    reset() {
      this.setProperties({
        activeAccount: null,
        activeUser: null,
        data: null
      });
    },

    loadInitialData: (0, _emberConcurrency.task)(function* () {
      let meRequest;

      try {
        meRequest = yield this.get('ajax').request('me');
      } catch (e) {
        if (e.status === 403 && e.payload && e.payload.errors && e.payload.errors.code === 'needs-verification') {
          this.router.transitionTo('outside.email-verification');
        }

        return;
      }

      if (_environment.default.environment === 'development') {
        // don't switch url on dev environments
        delete meRequest.data.api_url;
      }

      this.set('meRequest', meRequest.data);

      if (!meRequest.success) {
        if (meRequest.code === 402) {
          if (meRequest.redirectUrl) {
            window.location.href = meRequest.redirectUrl;
          } else {
            this.transitionToRoute('outside.sso-unauthorized');
          }
        } else {
          this.transitionToRoute('outside.authentication-error');
        }

        return;
      }

      let {
        id,
        current_employee,
        current_account_id,
        other_accounts
      } = meRequest.data;

      if (current_account_id) {
        yield this.get('setActiveAccount').perform(current_account_id);
      }

      if (current_employee && current_employee.id) {
        yield this.get('setActiveEmployee').perform(current_employee.id);
      }

      yield this.installTheme();

      if (id) {
        yield this.get('setActiveUser').perform(id, current_account_id);
      }

      this.set('otherAccounts', other_accounts);
    }).drop(),
    reloadSummary: (0, _emberConcurrency.task)(function* () {
      let summary = yield this.activeAccount.belongsTo('accountSummary').reload();
      this.set('accountSummary', summary);
    }),
    setActiveAccount: (0, _emberConcurrency.task)(function* (accountId) {
      let account = yield this.get('store').findRecord('account', accountId, {
        include: 'subscription.plan,subscription.stripe-card,billing-settings.currency,primary-contact,company-locations,owner-account'
      });
      yield account.get('subscription.stripeCard');
      this.set('activeAccount', account);

      if (this.activeAccount.get('accountType') === 'independent_coach') {
        let coach = yield this.activeAccount.belongsTo('coach').load();
        this.activeAccount.set('coach', coach);
      }

      if (this.activeAccount.get('accountType') === 'member' || this.activeAccount.get('status') !== 'active') {
        let ownerAccount = yield this.activeAccount.belongsTo('ownerAccount').load();
        this.activeAccount.set('ownerAccount', ownerAccount);
      }

      return account;
    }),
    setActiveEmployee: (0, _emberConcurrency.task)(function* (employeeId) {
      try {
        let employee = yield this.get('store').findRecord('employee', employeeId, {
          reload: true,
          include: 'role-users.permissions,role-users.role,matching-process,tenant.owner-account,employee-settings'
        });
        this.set('activeEmployee', employee);
        let roleUsers = yield employee.get('roleUsers');
        this.set('activeRoleUsers', roleUsers);
        let roles = [];
        let permissions = [];

        for (let roleUser of roleUsers.toArray()) {
          let role = yield roleUser.get('role');

          if (!roles.includes(role.get('name'))) {
            roles.pushObject(role.get('name'));
          }

          let rolePermissions = roleUser.get('permissions');

          if (rolePermissions) {
            rolePermissions.forEach(permission => {
              if (!permissions.includes(permission.get('name'))) {
                permissions.pushObject(permission.get('name'));
              }
            });
          }
        }

        this.set('activeRoles', roles);
        this.set('activePermissions', permissions);
      } catch (e) {
        this.clToaster.error(e);
      }
    }),

    /*
    setActiveRoles: task(function* (roleUsers) {
      let employee = yield this.get('store').findRecord('employee', employeeId, { include: 'role-users.permissions,role-users.roles'});
       let roles = yield employee.get('roleUsers');
      let permissions = yield employee.get('permissions');
      this.set('activeRoles', roles);
      this.set('activePermissions', roles.get('permissions'));
       this.set('activeEmployee', employee);
    }),
     setActivePermissions: task(function* () {
      let employee = yield this.get('store').findRecord('employee', employeeId, { include: 'role-users.permissions,role-users.roles'});
       let roles = yield employee.get('roleUsers');
      let permissions = yield employee.get('permissions');
      this.set('activeRoles', roles);
      this.set('activePermissions', roles.get('permissions'));
       this.set('activeEmployee', employee);
    }),
    */
    setActiveUser: (0, _emberConcurrency.task)(function* (userId, currentAccountId) {
      let user = yield this.get('store').findRecord('user', userId, {
        include: 'account,cronofy-connected-account,employees.account,employees.tenant.owner-account,profile',
        reload: true
      });
      this.set('activeUser', user);
      let timezone = yield user.get('timezone');
      let autoUpdateTimezone = user.autoUpdateTimezone; // If the user doesn't have a timezone set or should update, try to get that from the
      // browser and update the user to default to that one.

      let tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (autoUpdateTimezone && timezone?.name !== tzName) {
        try {
          let timezones = yield this.store.query('timezone', {
            filter: {
              name: tzName
            }
          });
          timezone = timezones.get('firstObject');
          user.set('timezone', timezone);
          let account = this.get('activeAccount');
          user.set('account', account);
          user.set('currentAccountId', currentAccountId); // Fix new accounts not having account id

          user.save();
        } catch (e) {
          this.clToaster.error(e);
        }
      }

      let language = user.get('language') || this.get('activeAccount.language');

      if (timezone) {
        this.get('setTimeZone').perform(timezone.get('name'));
      }

      if (language) {
        this.get('setLocale').perform(language);
      } else {
        this.get('setLocale').perform('en-us');
      }

      Sentry.setUser({
        id: user.id,
        email: user.email
      });

      if (!this.get('session.data.authenticated.mimicSession') && this.activeAccount) {
        // set up intercom
        let idPrefix = _environment.default.intercom.idPrefix || '';
        this.get('intercom').set('user.name', `${user.get('firstName')} ${user.get('lastName')}`);
        this.get('intercom').set('user.email', user.get('email'));
        this.get('intercom').set('user.createdAt', (0, _moment.default)(user.get('createdAt')).unix());
        this.get('intercom').set('user.id', `${idPrefix}${user.get('id')}`);
        this.get('intercom').set('user.company', this.get('activeAccount.name'));
        this.get('intercom').set('user.job_title', this.get('activeEmployee.employeeTitle'));
        this.get('intercom').set('user.is_coach', this.get('activeEmployee.isCoach'));
        this.get('intercom').set('user.stripe_plan', this.get('activeAccount.currentBillingPlan'));
        this.get('intercom').set('user.profile_published', user.get('profile.isPublished'));

        if (this.activeRoleUsers && this.activeRoleUsers.length) {
          let primaryRole = this.activeRoleUsers.mapBy('role.displayName').join(', ');
          this.get('intercom').set('user.primary_role', primaryRole);
        }

        this.intercom.start({
          hide_default_launcher: true
        });
      }

      this.get('notifications').initPusher(user);
      this.get('twilio.initTwilio').perform();
      this.get('notifications.all').perform();
      this.get('orders.all').perform();
    }),
    setLocale: (0, _emberConcurrency.task)(function* (locale) {
      this.get('intl').setLocale(locale);
      this.get('moment').setLocale(MOMENT_LOCALES[locale]);
      this.powerCalendar.set('locale', MOMENT_LOCALES[locale]);
      yield _moment.default.locale(MOMENT_LOCALES[locale]);
    }),
    setTimeZone: (0, _emberConcurrency.task)(function* (timezone) {
      yield this.get('moment').setTimeZone(timezone);
    }),

    async installTheme() {
      let activeAccount = this.get('activeAccount');

      if (this.activeEmployee && this.activeEmployee.belongsTo('tenant').id()) {
        let tenant = await this.activeEmployee.get('tenant');
        activeAccount = await tenant.get('ownerAccount');
      } else if (activeAccount && (activeAccount.get('isMember') || activeAccount.isUnclaimed)) {
        let ownerAccount = await activeAccount.get('ownerAccount');

        if (ownerAccount) {
          activeAccount = ownerAccount;
        }
      }

      if (activeAccount) {
        let theme = activeAccount.generateTheme();
        this.paperTheme.installTheme('account', theme);
        this.clTheme.setTheme({
          primary: activeAccount.primaryColor,
          secondary: activeAccount.accentColor
        });
      }
    },

    formatCurrency(value = 0, currency) {
      let code = currency || this.get('activeAccount.billingSettings.currency.code') || 'USD';
      return this.get('intl').formatNumber(value, {
        style: 'currency',
        currency: code
      });
    },

    switchAccount: (0, _emberConcurrency.task)(function* (accountId) {
      try {
        if (accountId === this.activeAccount.id) {
          return;
        }

        let user = this.activeUser;
        let newAccount = yield this.store.findRecord('account', accountId);
        user.set('account', newAccount);
        yield user.save();
        yield this.loadInitialData.perform();
        this.router.transitionTo('dashboard');
      } catch (e) {
        this.clToaster.error('Something went wrong');
      }
    })
  });

  _exports.default = _default;
});