define("coachlogix/pods/form/form-editor/form-element/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FormElementComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('question.questionType'), _dec3 = Ember.computed('question.questionType'), _dec4 = Ember.computed('activeQuestion', 'question'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = (_class2 = (_temp = class FormElementComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "activeQuestion", null);
    }

    get questionModifier() {
      return this.question.questionType;
    }

    get componentName() {
      return `form/form-editor/form-element/${this.question.questionType}`;
    }

    get isActive() {
      return this.activeQuestion === this.question;
    }

    handleClick(el) {
      this.onClick(this.question, el);
    }

    handleDeleteQuestion() {
      this.deleteQuestion(this.question);
    }

    handleDuplicateQuestion(e) {
      e.stopPropagation();
      this.duplicateQuestion(this.question);
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "questionModifier", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "questionModifier"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "componentName", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "componentName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isActive", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isActive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleDeleteQuestion", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDeleteQuestion"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleDuplicateQuestion", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDuplicateQuestion"), _class2.prototype)), _class2)) || _class);
  var _default = FormElementComponent;
  _exports.default = _default;
});