define("coachlogix/pods/components/v2/resources/create-link-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-decorators", "image-promise", "coachlogix/utils/url-regex"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators, _imagePromise, _urlRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CreateLinkDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrencyDecorators.lastValue)('createPreview'), _dec4 = Ember._action, _dec5 = (0, _emberConcurrencyDecorators.restartableTask)({
    on: 'init'
  }), _dec6 = Ember.computed('url', 'name'), _dec(_class = (_class2 = (_temp = class CreateLinkDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "filestack", _descriptor, this);

      _defineProperty(this, "name", '');

      _defineProperty(this, "description", '');

      _defineProperty(this, "url", '');

      _defineProperty(this, "linkIsValid", false);

      _initializerDefineProperty(this, "previewImage", _descriptor2, this);

      _defineProperty(this, "urlRegex", _urlRegex.default);
    }

    updateUrl(url) {
      this.set('url', url);
      this.createPreview.perform(url);
    }

    *createPreview() {
      let url = this.url;

      if (!_urlRegex.default.test(url)) {
        this.set('linkIsValid', false);
        return;
      }

      this.set('linkIsValid', true);
      yield (0, _emberConcurrency.timeout)(300); // initialize filestack client in case it wasn't before

      yield this.filestack.initClient();
      let previewUrl = this.filestack.getUrl(url, {
        urlscreenshot: true,
        resize: {
          width: 520,
          height: 360,
          align: 'top',
          fit: 'crop'
        }
      });
      return yield (0, _imagePromise.default)(previewUrl, {
        class: 'cl-block cl-w-full'
      });
    }

    get isValid() {
      return Ember.isPresent(this.url) && Ember.isPresent(this.name) && _urlRegex.default.test(this.url);
    }

    *createLink() {
      yield this.onCreate({
        name: this.name,
        description: this.description,
        url: this.url
      });
      this.onClose();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "filestack", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "previewImage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "updateUrl", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "updateUrl"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createPreview", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "createPreview"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isValid", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isValid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createLink", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createLink"), _class2.prototype)), _class2)) || _class);
  var _default = CreateLinkDialog;
  _exports.default = _default;
});