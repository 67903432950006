define("coachlogix/pods/components/v2/engagement-template/engagement-task/master-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QwT3sbdr",
    "block": "{\"symbols\":[\"@item\",\"@href\",\"@isActive\",\"@onClick\",\"&attrs\"],\"statements\":[[8,\"cl-list/item\",[[17,5]],[[\"@dense\",\"@href\",\"@isActive\",\"@onClick\"],[true,[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"itemable\",\"isForm\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[[24,0,\"cl-icon-bordered--form\"]],[[\"@icon\",\"@size\",\"@bordered\"],[\"assignment_turned_in\",22,true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"itemable\",\"isFile\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[[24,0,\"cl-icon-bordered--file\"]],[[\"@icon\",\"@size\",\"@bordered\"],[\"insert_drive_file\",22,true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"itemable\",\"isLink\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[[24,0,\"cl-icon-bordered--link\"]],[[\"@icon\",\"@size\",\"@bordered\"],[\"link\",22,true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[[24,0,\"cl-icon-bordered--task\"]],[[\"@icon\",\"@size\",\"@bordered\"],[\"offline_pin\",22,true]],null],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"cl-title-6 cl-truncate\"],[12],[1,[32,1,[\"itemable\",\"displayTitle\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement-template/engagement-task/master-item/template.hbs"
    }
  });

  _exports.default = _default;
});