define("coachlogix/pods/video/settings/volume-meter/component", ["exports", "@glimmer/component", "volume-meter"], function (_exports, _component, _volumeMeter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const AudioContext = window.AudioContext || // Default
  window.webkitAudioContext || // Safari and old versions of Chrome
  false;
  /**
   * This component renders a volume meter of the given `@audioTrack` argument
   * This is an svg based meter that fills according to the volume percentage.
   */

  let VolumeMeterComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class VolumeMeterComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentVolume", _descriptor, this);

      _defineProperty(this, "audioTrack", null);
    }

    handleAudioTrackUpdate([audioTrack]) {
      if (this.audioTrack) {
        this.destroyVolumeMeter();
      }

      this.audioTrack = audioTrack;

      if (audioTrack) {
        this.setupVolumeMeter();
      }
    }

    setupVolumeMeter() {
      if (AudioContext) {
        this.ctx = new AudioContext();
        this.meter = (0, _volumeMeter.default)(this.ctx, {
          tweenIn: 1,
          tweenOut: 1
        }, volume => {
          Ember.run.throttle(this, this.onVolumeChange, volume, 120);
        });
        let stream = new MediaStream([this.audioTrack.mediaStreamTrack]);
        let src = this.ctx.createMediaStreamSource(stream);
        src.connect(this.meter);
        this.audioTrack.on('stopped', this.onTrackStopped);
      }
    }

    destroyVolumeMeter() {
      this.audioTrack.off('stopped', this.onTrackStopped);
    }
    /**
     * invoked when the volume of the audio input changes. passed in to the volume meter.
     * This invokation is throttled by the caller
     * @param {number} volume
     */


    onVolumeChange(volume) {
      this.currentVolume = volume;
    }

    onTrackStopped() {
      this.meter.stop();
      this.ctx.close();
      this.currentVolume = 0;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentVolume", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleAudioTrackUpdate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleAudioTrackUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupVolumeMeter", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setupVolumeMeter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onVolumeChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onVolumeChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTrackStopped", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onTrackStopped"), _class.prototype)), _class));
  _exports.default = VolumeMeterComponent;
});