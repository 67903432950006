define("coachlogix/pods/settings/invoicing-payments/invoice-settings/component", ["exports", "@ember-decorators/component", "ember-changeset", "ember-concurrency-decorators"], function (_exports, _component, _emberChangeset, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompanyInvoiceSettingsCardComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('account.activeAccount.billingSettings', 'changeset', 'editState'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember.computed, _dec10 = Ember.computed('billingSettings.country', 'countries.[]'), _dec11 = Ember._action, _dec(_class = (_class2 = (_temp = class CompanyInvoiceSettingsCardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "clToaster", _descriptor4, this);

      _defineProperty(this, "editState", false);

      _defineProperty(this, "changeset", null);
    }

    get billingSettings() {
      return this.editState ? this.changeset : this.account.activeAccount.billingSettings;
    }

    async enterEditState() {
      let billingSettings = await this.account.activeAccount.get('billingSettings');
      this.set('changeset', new _emberChangeset.Changeset(billingSettings));
      this.set('editState', true);
    }

    *saveChanges() {
      try {
        yield this.changeset.save();
        this.set('editState', false);
        this.clToaster.success(this.intl.t('settings.invoicing-payments.save-successful'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    discardChanges() {
      this.changeset.rollback();
      this.set('editState', false);
    }

    get countries() {
      return this.store.findAll('country');
    }

    get selectedCountry() {
      return this.countries.findBy('iso31662', this.billingSettings.get('country'));
    }

    selectCountry(country) {
      let value = country ? country.iso31662 : null;
      this.billingSettings.set('country', value);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "billingSettings", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "billingSettings"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "enterEditState", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "enterEditState"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "discardChanges", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "discardChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "countries", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "countries"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectedCountry", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "selectedCountry"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectCountry", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "selectCountry"), _class2.prototype)), _class2)) || _class);
  var _default = CompanyInvoiceSettingsCardComponent;
  _exports.default = _default;
});