define("coachlogix/pods/marketplace/index/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MarketplaceIndexRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class MarketplaceIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "queryParams", {
        filter: {
          refreshModel: true
        }
      });

      _defineProperty(this, "pageNumber", 1);

      _defineProperty(this, "pageSize", 10);

      _defineProperty(this, "didRequestFilters", false);

      _defineProperty(this, "sortSeed", Math.random().toString(36).substr(2, 9));
    }

    model({
      filter
    }) {
      this.pageNumber = 1;
      filter = Ember.isPresent(filter) ? JSON.parse(filter) : {};
      return {
        profilesTask: this.loadProfiles.perform(filter)
      };
    }

    resetController(controller) {
      controller.moreProfiles = true;
      controller.isLoading = false;
      controller.filter = '{}';
      this.pageNumber = 1;
      this.didRequestFilters = false;
    }

    async loadNext() {
      let newProfiles = await this.loadProfiles.perform();
      let {
        profilesTask
      } = this.modelFor('marketplace.index');
      profilesTask.value?.addObjects(newProfiles);
    }

    *loadProfiles(filter) {
      let controller = this.controllerFor('marketplace.index');
      controller.isLoading = true;
      let profiles = yield this.store.query('profile', {
        include: 'owner,coaching-categorizations',
        filter: {
          sort_seed: this.sortSeed,
          include_filter_params: this.didRequestFilters ? 0 : 1,
          ...filter
        },
        page: {
          size: this.pageSize,
          number: this.pageNumber
        }
      });
      this.didRequestFilters = true;

      if (profiles.meta.filters) {
        controller.filters = profiles.meta.filters;
      }

      if (profiles.length < this.pageSize) {
        controller.moreProfiles = false;
      }

      controller.isLoading = false;
      this.pageNumber++;
      return profiles.toArray();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadNext", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "loadNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadProfiles", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadProfiles"), _class.prototype)), _class));
  _exports.default = MarketplaceIndexRoute;
});