define("coachlogix/models/email-customization", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    emailDesignId: (0, _model.attr)('string'),
    enableEmailMarkup: (0, _model.attr)('string'),
    replyToEmail: (0, _model.attr)('string'),
    bccEmail: (0, _model.attr)('string'),
    emailHeader: (0, _model.attr)('string'),
    emailFooter: (0, _model.attr)('string'),
    emailSubjectInvoice: (0, _model.attr)('string'),
    emailTemplateInvoice: (0, _model.attr)('string'),
    emailSubjectQuote: (0, _model.attr)('string'),
    emailTemplateQuote: (0, _model.attr)('string'),
    emailSubjectPayment: (0, _model.attr)('string'),
    emailTemplatePayment: (0, _model.attr)('string'),
    emailSubjectReminder1: (0, _model.attr)('string'),
    emailTemplateReminder1: (0, _model.attr)('string'),
    emailSubjectReminder2: (0, _model.attr)('string'),
    emailTemplateReminder2: (0, _model.attr)('string'),
    emailSubjectReminder3: (0, _model.attr)('string'),
    emailTemplateReminder3: (0, _model.attr)('string'),
    emailSignature: (0, _model.attr)('string'),
    pdfEmailAttachment: (0, _model.attr)('string'),
    customContactLabel1: (0, _model.attr)('string'),
    customContactLabel2: (0, _model.attr)('string'),
    account: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    companyLocation: (0, _model.belongsTo)('company-location', {
      inverse: null
    })
  });

  _exports.default = _default;
});