define("coachlogix/pods/components/v2/summary-cards/admin/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AdminCardComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('client.{clientPocs.@each.account,theOtherAccount.id}'), _dec5 = Ember._action, _dec(_class = (_class2 = (_temp = class AdminCardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clToaster", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }

    get filteredAdmins() {
      let admins = this.client.get('clientPocs') || [];
      let otherAccountId = this.client.get('theOtherAccount.id');
      return admins.filter(admin => {
        return admin.get('account.id') === otherAccountId;
      });
    }

    async editPoc(poc) {
      this.set('pocUserToEdit', await poc.get('employee.user'));
    }

    *savePoc(changeset) {
      try {
        yield changeset.save();
        this.set('pocUserToEdit', null);
        this.clToaster.success(this.intl.t('ui.account-managers.account-manager-updated'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "filteredAdmins", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "filteredAdmins"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editPoc", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "editPoc"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "savePoc", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "savePoc"), _class2.prototype)), _class2)) || _class);
  var _default = AdminCardComponent;
  _exports.default = _default;
});