define("coachlogix/pods/components/v2/messaging/channel-pane/messages/coaching-request/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CoachingRequestMessage = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('message.dateCreated'), _dec6 = (0, _emberConcurrencyDecorators.lastValue)('loadCoachingRequest'), _dec7 = Ember.computed('account.activeUser.id', 'coachingRequest.coach.user'), _dec8 = Ember.computed('account.activeUser.id', 'coachingRequest.coachee.user'), _dec9 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec(_class = (_class2 = (_temp = class CoachingRequestMessage extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "coachingRequest", _descriptor4, this);
    }

    get isToday() {
      return (0, _moment.default)().isSame(this.message.dateCreated, 'day');
    }

    get isCoach() {
      return this.coachingRequest && this.coachingRequest.get('coach.user.id') === this.account.activeUser.id;
    }

    get isCoachee() {
      return this.coachingRequest && this.coachingRequest.get('coachee.user.id') === this.account.activeUser.id;
    }

    *loadCoachingRequest() {
      let coachingRequestId = this.message.attributes.coachingRequestId;
      let include = ['coach.user', 'coachee.user', 'engagement', 'meeting-location', 'time-suggestions'].join(','); // not using findRecord because ember-data coalesces multiple findRecord requests
      // that happen in the same runloop and doesn't pass the includes parameter when that happens

      let array = yield this.store.query('coaching-request', {
        ids: [coachingRequestId],
        filter: {
          id: coachingRequestId
        },
        include
      });

      if (array.length === 0) {
        throw new Error();
      }

      return array.objectAt(0);
    }

    *cancelInterview() {
      try {
        this.coachingRequest.set('status', 'interview_canceled');
        yield this.coachingRequest.save();
        this.set('showCancelInterview', false);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    get timeSuggestions() {
      let timeSuggestions = this.coachingRequest.timeSuggestions?.toArray() || [];
      return timeSuggestions.sort((a, b) => a.startTime - b.startTime);
    }

    *requestNewTimes() {
      try {
        this.coachingRequest.status = 'interview_times_requested';
        yield this.coachingRequest.save();
      } catch (e) {
        this.coachingRequest.rollbackAttributes();
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isToday", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isToday"), _class2.prototype), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "coachingRequest", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isCoach", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "isCoach"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isCoachee", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "isCoachee"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadCoachingRequest", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "loadCoachingRequest"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancelInterview", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelInterview"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "requestNewTimes", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "requestNewTimes"), _class2.prototype)), _class2)) || _class);
  var _default = CoachingRequestMessage;
  _exports.default = _default;
});