define("coachlogix/helpers/byte-size", ["exports", "byte-size"], function (_exports, _byteSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.byteSize = byteSize;
  _exports.default = void 0;

  function byteSize([value]) {
    if (value) {
      return (0, _byteSize.default)(value).toString();
    } else {
      return value;
    }
  }

  var _default = Ember.Helper.helper(byteSize);

  _exports.default = _default;
});