define("coachlogix/models/billable-activity", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    amount: (0, _model.attr)('string'),
    account: (0, _model.belongsTo)('account'),
    client: (0, _model.belongsTo)('client'),
    billableActivityType: (0, _model.attr)('string'),
    billableActivityId: (0, _model.attr)('string'),
    invoiceable: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      inverse: null
    }),
    engagement: (0, _model.belongsTo)('engagement', {
      inverse: null
    }),
    isSession: Ember.computed.equal('billableActivityType', 'engagement-sessions')
  });

  _exports.default = _default;
});