define("coachlogix/abilities/account", ["exports", "ember-can", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    role: Ember.computed.alias('account.activeEmployee.role'),

    roleIsAny(...roles) {
      const role = this.get('role');
      const activeRoles = this.get('account.activeRoles');

      if (activeRoles) {
        return activeRoles.some(r => roles.includes(r));
      }

      return roles.includes(role);
    },

    accountTypeIsAny(...accounts) {
      return accounts.includes(this.get('account.activeAccount.accountType'));
    },

    canOnlySeeProfiles: Ember.computed('account.activeAccount.activeRoles', function () {
      return this.hasOnlyRoles('account_employee', 'account_coach_viewer');
    }),
    canAddAdmins: Ember.computed('account.activeAccount.accountType', function () {
      return this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.hasPermission('manage_account_admins');
    }),
    canAddGlobalAdmins: Ember.computed('account.activeAccount.accountType', function () {
      return this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.hasAccountRole('account_global_admin');
    }),
    canEditEmployeeRoles: Ember.computed('account.activeAccount.accountType', function () {
      return this.roleIsAny('account_global_admin');
    }),
    canManageCoachPools: Ember.computed('permissions.[]', function () {
      return this.roleIsAny('account_global_admin');
    }),
    canReports: Ember.computed('account.activeAccount.accountType', function () {
      return this.hasPermission('view_account_settings');
    }),
    canEmployees: Ember.computed('role', function () {
      return this.roleIsAny('account_global_admin', 'location_global_admin');
    }),
    canSeeCompanyResources: Ember.computed('account.account.isIndependentCoach', 'account.activeAccount.{accountType,activeRoles}', 'account.activeEmployee.{isCoach,isIndependent,isInternal}', 'permissions.[]', function () {
      let isBusinessOrEnterprise = this.accountTypeIsAny('business', 'enterprise');
      let canManageAccountResources = this.hasPermission('manage_account_resources');
      let isAdmin = this.roleIsAny('account_global_admin', 'location_global_admin');
      let isInternalCoach = this.get('account.activeEmployee.isCoach') && this.get('account.activeEmployee.isInternal');
      let isIndieCoach = this.account.activeEmployee.isIndependent; // It's critical to make sure internal coaches don't have access to account
      // resources unless they are admins.
      // This is a defensive quickfix for this problem but the main issue comes
      // from internal coaches having the `manage_account_resources` permission
      // which needs to be fixed on the backend side (tracked on BH-1050)

      if (isInternalCoach && !isAdmin) {
        return false;
      }

      if (isBusinessOrEnterprise && canManageAccountResources || isIndieCoach) {
        return true;
      }

      return false;
    }),
    canSeeCompetencyLibraries: Ember.computed('permissions.[]', function () {
      return this.hasPermission('view_account_resources');
    }),
    canManageCompetencyLibraries: Ember.computed('permissions.[]', function () {
      return this.hasPermission('manage_account_resources');
    }),
    canCreateEmployee: Ember.computed('account.activeAccount.accountType', function () {
      return this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.hasPermission('manage_account_employees');
    }),
    canUpdateEmployee: Ember.computed('account.activeAccount.accountType', function () {
      return this.hasPermission('manage_account_employees') || this.hasPermission('manage_location_employees') || this.hasPermission('manage_department_employees');
    }),
    canViewEmployee: Ember.computed('account.activeAccount', function () {
      return this.hasPermission('view_employees');
    }),
    canManageClients: Ember.computed(function () {
      return this.hasPermission('manage_account_clients');
    }),
    canCreateClients: Ember.computed('account.activeEmployee.{tenant.ownerAccount.id,account.id}', function () {
      let ownerAccountId = this.account.activeEmployee.get('tenant.ownerAccount.id');

      if (ownerAccountId) {
        let employeeAccountId = this.account.activeEmployee.get('account.id');
        return this.hasPermission('manage_account_clients') && ownerAccountId === employeeAccountId;
      } else {
        return this.hasPermission('manage_account_clients');
      }
    }),
    canSeeVendors: Ember.computed(function () {
      return !this.hasAccountRole('account_coach_viewer');
    }),
    canManageEngagements: Ember.computed('account.activeEmployee.{tenant.ownerAccount.id,account.id}', function () {
      let ownerAccountId = this.account.activeEmployee.get('tenant.ownerAccount.id');

      if (ownerAccountId) {
        let employeeAccountId = this.account.activeEmployee.get('account.id');
        return this.hasPermission('manage_engagements') && ownerAccountId === employeeAccountId;
      } else {
        return this.hasPermission('manage_engagements');
      }
    }),
    canViewEngagements: Ember.computed('canManageEngagements', 'account.{activeEmployee.isCoach,activeAccount.activeRoles}', function () {
      return this.canManageEngagements || this.account.activeEmployee.isCoach || this.hasAccountRole('engagement_viewer', 'engagement_manager');
    }),
    canDashboard: false,
    canManageResources: false,
    canManageContracts: Ember.computed(function () {
      return this.hasPermission('manage_contracts');
    }),
    canManageInvoices: Ember.computed(function () {
      return this.hasPermission('manage_invoices');
    }),
    canManagePeople: false,
    canManageCompanies: false,
    canManageReports: false,
    canManageProgramTemplates: Ember.computed(function () {
      return this.hasPermission('manage_engagements');
    }),
    canManageSettings: Ember.computed(function () {
      return this.hasAccountRole('account_global_admin');
    }),
    canManageSubscriptions: Ember.computed('account.activeEmployee.tenant.id', function () {
      return this.hasAccountRole('account_global_admin') && this.account.activeEmployee.belongsTo('tenant').id() === null;
    }),
    canManageBranding: Ember.computed('account.activeEmployee.{tenant.ownerAccount.id,account.id}', function () {
      let ownerAccountId = this.account.activeEmployee.get('tenant.ownerAccount.id');

      if (ownerAccountId) {
        let employeeAccountId = this.account.activeEmployee.get('account.id');
        return this.hasPermission('manage_account_clients') && ownerAccountId === employeeAccountId;
      } else {
        return this.hasPermission('manage_account_clients');
      }
    }),
    canManageOrgStructure: Ember.computed('account.activeAccount.isEnterprise', function () {
      let account = this.get('account');
      let activeAccount = account.get('activeAccount');
      return activeAccount.get('isEnterprise') && this.hasAccountRole('account_global_admin');
    }),
    canViewOrgStructure: Ember.computed('account.activeAccount.isEnterprise', function () {
      let account = this.get('account');
      let activeAccount = account.get('activeAccount');
      return activeAccount.get('isEnterprise') && this.hasAccountRole('account_global_admin', 'location_global_admin');
    }),
    canAddInternalCoaches: Ember.computed.reads('account.activeAccount.internalCoaching'),
    canBuyCoaching: Ember.computed.reads('account.activeAccount.buysCoaching'),
    canSellCoaching: Ember.computed.reads('account.activeAccount.providesCoaching'),
    canBuyIndependentCoach: Ember.computed.and('account.activeAccount.buysCoaching', 'account.activeAccount.buysIndependentCoaching'),
    canBuyVendorCoach: Ember.computed.and('account.activeAccount.buysCoaching', 'account.activeAccount.buysVendorCoaching'),
    canFeatureBrandColors: Ember.computed('account.activeAccount.currentBillingPlan', function () {
      // return ['business', 'business_plus', 'independent_coach', 'independent_coach_plus', 'enterprise'].includes(this.get('account.activeAccount.currentBillingPlan'));
      return true;
    }),
    canFeatureSquareLogo: Ember.computed('account.activeAccount.currentBillingPlan', function () {
      // return ['business', 'business_plus', 'independent_coach', 'independent_coach_plus', 'enterprise'].includes(this.get('account.activeAccount.currentBillingPlan'));
      return true;
    }),
    canFeatureFullLogo: Ember.computed('account.activeAccount.{currentBillingPlan,isPro}', function () {
      if (this.get('account.activeAccount.isPro')) {
        return true;
      }

      return ['business_plus', 'independent_coach_plus', 'enterprise'].includes(this.get('account.activeAccount.currentBillingPlan'));
    }),
    canFeatureCustomEmail: Ember.computed('account.activeAccount.currentBillingPlan', function () {
      return ['business_plus', 'independent_coach_plus', 'enterprise'].includes(this.get('account.activeAccount.currentBillingPlan'));
    }),
    canFeatureEngagementLevelReporting: Ember.computed('account.activeAccount.currentBillingPlan', function () {
      return ['business', 'business_plus', 'independent_coach_plus', 'enterprise'].includes(this.get('account.activeAccount.currentBillingPlan'));
    }),
    canViewTypeOfCoachFilter: Ember.computed('account.activeAccount.activeRoles', function () {
      return this.hasAccountRole('account_global_admin');
    }),
    canSendForm: Ember.computed.reads('account.activeAccount.isPro'),
    canCreateMatchingSettings: Ember.computed.reads('account.activeAccount.isPro'),
    canBulkUpload: Ember.computed.reads('account.activeAccount.isPro'),
    canChangeAccountInfo: Ember.computed.or('account.activeAccount.{isBusiness,isEnterprise}')
  });

  _exports.default = _default;
});