define("coachlogix/pods/components/v2/notifications-dropdown/notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N4GDZlMF",
    "block": "{\"symbols\":[\"@activity\"],\"statements\":[[8,\"cl-icon\",[[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[30,[36,1],[[30,[36,0],[[32,1,[\"notification\",\"messageType\",\"subCategory\"]],[32,1,[\"notification\",\"messageType\",\"category\"]]],null]],null],\"-icon\"]]]],[[\"@icon\",\"@solid\",\"@size\"],[[32,0,[\"icon\"]],true,14]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text cl-py-1\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-items-center\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-5 cl-flex-1\"],[12],[1,[30,[36,2],[[30,[36,0],[[32,1,[\"notification\",\"messageType\",\"subCategory\"]],[32,1,[\"notification\",\"messageType\",\"category\"]]],null]],null]],[13],[2,\"\\n    \"],[10,\"small\"],[14,0,\"cl-title-7\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[32,1,[\"createdAt\"]]],[[\"interval\"],[5000]]]],[2,\"\\n\"],[6,[37,4],[[32,1,[\"read\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"cl-status-indicator\",[[24,0,\"cl-ml-1\"]],[[\"@success\"],[true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"cl-whitespace-normal cl-body-1\"],[12],[1,[30,[36,5],[[32,1,[\"activityBody\"]]],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"or\",\"dasherize\",\"humanize\",\"moment-from-now\",\"unless\",\"html-safe\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/notifications-dropdown/notification/template.hbs"
    }
  });

  _exports.default = _default;
});