define("coachlogix/models/invoice-item", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    notes: (0, _model.attr)('string'),
    taxName1: (0, _model.attr)('string'),
    taxRate1: (0, _model.attr)('number'),
    billingMethod: (0, _model.attr)('string'),
    // 'hourly','flat_fee','unit_price'
    units: (0, _model.attr)('number'),
    unitPrice: (0, _model.attr)('number'),
    // taxTotal: attr('number'),
    // itemTotal: attr('number'),
    invoiceableType: (0, _model.attr)('string'),
    isAdhocSession: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    invoiceable: (0, _model.belongsTo)('engagement-session', {
      async: true,
      inverse: null
    }),
    invoice: (0, _model.belongsTo)('invoice', {
      async: true,
      inverse: 'invoiceItems'
    }),
    isTaxable: Ember.computed.bool('taxRate1'),
    subTotal: Ember.computed('billingMethod', 'units', 'unitPrice', function () {
      let cost = this.get('unitPrice') || 0;
      let billingQuantity = this.get('units') || 0;
      let billingMethod = this.get('billingMethod');

      if (billingMethod === 'billable_hours') {
        return parseFloat(cost) * (parseFloat(billingQuantity) / 60);
      } else if (billingMethod === 'unit_cost') {
        return parseFloat(cost) * parseFloat(billingQuantity);
      }
    }),
    taxTotalComputed: Ember.computed('subTotal', 'taxRate1', function () {
      let subTotal = this.get('subTotal') || 0;
      let taxRate1 = this.get('taxRate1') || 0;
      return parseFloat(subTotal) * parseFloat(taxRate1);
    }),
    itemTotalComputed: Ember.computed('taxTotalComputed', 'subTotal', function () {
      let subTotal = this.get('subTotal') || 0;
      let taxTotal = this.get('taxTotalComputed') || 0;
      return parseFloat(subTotal) + parseFloat(taxTotal);
    })
  });

  _exports.default = _default;
});