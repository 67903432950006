define("coachlogix/models/engagement-session", ["exports", "@ember-data/model", "ember-concurrency", "coachlogix/utils/popup-window", "coachlogix/models/base-model", "coachlogix/models/commentable", "coachlogix/models/custom-notifiable", "coachlogix/models/resourceable", "coachlogix/models/taskable"], function (_exports, _model, _emberConcurrency, _popupWindow, _baseModel, _commentable, _customNotifiable, _resourceable, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_commentable.default, _customNotifiable.default, _taskable.default, _resourceable.default, {
    actualDuration: (0, _model.attr)('number'),
    cancelReason: (0, _model.attr)('string'),
    completedDate: (0, _model.attr)('datetime'),
    description: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    duration: (0, _model.attr)('number', {
      defaultValue: 60
      /* minutes */

    }),
    meetingInstructions: (0, _model.attr)('string'),
    scheduledDate: (0, _model.attr)('datetime'),
    status: (0, _model.attr)('string'),
    // scheduled, completed, late_cancelled
    title: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    defaultTimeUnit: (0, _model.attr)('string', {
      defaultValue: 'minutes'
    }),
    // minutes, hours
    appVideoFlag: (0, _model.attr)('number'),
    templateDefaultCoach: (0, _model.attr)('boolean'),
    templateDefaultCoachee: (0, _model.attr)('boolean'),
    engagement: (0, _model.belongsTo)('engagement', {
      inverse: 'engagementSessions'
    }),
    programItem: (0, _model.belongsTo)('program-item', {
      async: true,
      inverse: null
    }),
    sessionable: (0, _model.belongsTo)('base-model', {
      async: true,
      inverse: null
    }),
    meetingLocation: (0, _model.belongsTo)('meeting-location', {
      async: true,
      inverse: null
    }),
    //invoiceable: belongsTo('base-model', { async: true, inverse: 'invoiceable' }),

    /* Single Line */
    availableParticipants: Ember.computed.setDiff('engagement.potentialStakeholders', 'participants'),
    belongsToEngagement: Ember.computed.alias('engagement.id'),
    belongsToTemplate: Ember.computed.equal('sessionable.modelName', 'engagement-template'),
    canBeMovedOnFrom: Ember.computed.or('isCompleted', 'isLateCancelled'),
    isCompleted: Ember.computed.equal('status', 'completed'),
    isLateCancelled: Ember.computed.equal('status', 'late_cancelled'),
    isScheduled: Ember.computed.equal('status', 'scheduled'),
    location: Ember.computed.alias('meetingInstructions'),
    // ??
    participants: (0, _model.hasMany)('employee', {
      async: true,
      inverse: null
    }),
    resourceGroups: (0, _model.hasMany)('resource-group', {
      async: true,
      inverse: 'engagementSessions'
    }),
    resources: (0, _model.hasMany)('resource', {
      async: true,
      inverse: 'engagementSessions'
    }),

    /* Multi Line */
    displayTitle: Ember.computed('title', function () {
      if (this.get('title')) {
        return this.get('title');
      }

      return this.get('intl').t('engagement-session.default-title');
    }),
    statusIsFinal: Ember.computed('status', function () {
      if (this.get('status') === 'completed') {
        return true;
      }

      if (this.get('status') === 'late_cancelled') {
        return true;
      }
    }),

    /* Lifecycle */
    accountService: Ember.inject.service('account'),
    intl: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    router: Ember.inject.service(),

    /* API */
    duplicate: (0, _emberConcurrency.task)(function* () {
      this.clToaster.info(this.intl.t('models.task.duplicating-session'));

      try {
        // Fetch all the data.
        yield this.reload();
        let programItem = yield this.get('programItem');
        let program = yield programItem.get('program'); // Create duplicate.

        let duplicatedItem = this.store.createRecord('program-item', {
          clonedFrom: programItem
        });
        let lastPosition = programItem.get('programable.programItems').sortBy('position').get('lastObject.position') + 1;
        duplicatedItem.set('position', lastPosition);
        yield duplicatedItem.save(); // Add duplicated item to the local item list.

        program.get('programItems').pushObject(duplicatedItem);
        this.clToaster.success(this.intl.t('engagement-session.session-duplicated'));
        return duplicatedItem;
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    lateCancel: (0, _emberConcurrency.task)(function* () {
      this.set('status', 'late_cancelled');
      yield this.get('saveTask').perform();
    }),
    updateDescription: (0, _emberConcurrency.task)(function* (description) {
      this.set('description', description);
      yield this.get('saveTask').perform();
    }),
    updateTimeUnit: (0, _emberConcurrency.task)(function* (newTimeUnit) {
      let defaultTimeUnit = this.get('defaultTimeUnit');

      if (newTimeUnit === defaultTimeUnit) {
        return;
      }

      try {
        this.set('defaultTimeUnit', newTimeUnit);
        yield this.save();
      } catch (e) {
        this.set('defaultTimeUnit', defaultTimeUnit);
        this.clToaster.error(this.intl.t('engagement-session.time-unit-error'));
      }
    }),
    updateDuration: (0, _emberConcurrency.task)(function* (proposedDuration) {
      const intl = this.get('intl');
      proposedDuration = parseInt(proposedDuration, 10);
      let currentDuration = parseInt(this.get('duration'), 10);
      const durationKey = this.get('status') === 'completed' && this.get('actualDuration') ? 'actualDuration' : 'duration';

      if (proposedDuration === currentDuration) {
        return;
      }

      if (proposedDuration < 1) {
        this.set(durationKey, currentDuration);
        return this.clToaster.info(intl.t('engagement-session.duration-required'));
      } else {
        this.set(durationKey, proposedDuration);

        if (durationKey === 'duration') {
          this.set('actualDuration', proposedDuration);
        }
      }

      try {
        yield this.save();
        this.clToaster.success(intl.t('engagement-session.duration-updated'));
      } catch (e) {
        this.set(durationKey, currentDuration);

        if (durationKey === 'duration') {
          this.set('actualDuration', currentDuration);
        }

        this.clToaster.error(e);
      }
    }),
    updateLocation: (0, _emberConcurrency.task)(function* (location) {
      if (this.get('location') === location) {
        return;
      }

      try {
        this.set('location', location);
        yield this.save();
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }),
    updateMeetingLocation: (0, _emberConcurrency.task)(function* (location) {
      const intl = this.get('intl');

      if (this.get('location') === location) {
        return;
      }

      try {
        this.set('meetingLocation', location);
        yield this.save();
        this.clToaster.success(intl.t('engagement-session.meeting-location-updated'));
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }),
    updateTitle: (0, _emberConcurrency.task)(function* (title) {
      this.set('title', title);
      yield this.get('saveTask').perform();
    }),
    complete: (0, _emberConcurrency.task)(function* (completedDate, actualDuration, defaultTimeUnit) {
      try {
        this.setProperties({
          actualDuration,
          completedDate,
          defaultTimeUnit,
          status: 'completed'
        });
        yield this.save();
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }),
    schedule: (0, _emberConcurrency.task)(function* (scheduledDate) {
      let currentScheduledDate = this.get('scheduledDate');
      let currentStatus = this.get('status');
      let intl = this.get('intl');

      try {
        this.setProperties({
          scheduledDate
        });

        if (!this.get('statusIsFinal')) {
          this.set('status', 'scheduled');
        }

        yield this.save();
        const toastMessage = currentScheduledDate ? intl.t('engagement-session.session-rescheduled') : intl.t('engagement-session.session-scheduled');
        this.clToaster.success(toastMessage);
      } catch (e) {
        this.setProperties({
          scheduledDate: currentScheduledDate,
          status: currentStatus
        });
        this.clToaster.error(e);
      }
    }),
    sendScheduleRequest: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'schedule_request');
        yield this.save();
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    sendRescheduleRequest: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'request_reschedule');
        yield this.save();
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    addToInvoice: (0, _emberConcurrency.task)(function* (invoice) {
      let invoiceItem = this.store.createRecord('invoice-item', {
        invoiceable: this,
        invoiceableType: 'engagement-sessions',
        invoice: invoice,
        unitPrice: 0,
        units: this.get('actualDuration'),
        billingMethod: 'billable_hours'
      });
      yield invoiceItem.save();
      invoice.get('invoiceItems').addObject(invoiceItem);
      return invoiceItem;
    }),
    openVideoSession: (0, _emberConcurrency.task)(function* () {
      let videoUrl = this.router.urlFor('video', this.id);
      yield (0, _popupWindow.default)(videoUrl, this.displayTitle, 400, 565);
    })
  });

  _exports.default = _default;
});