define("coachlogix/models/link", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    url: (0, _model.attr)('string'),
    isLink: Ember.computed.equal('resourceType', 'link')
  });

  _exports.default = _default;
});