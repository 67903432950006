define("coachlogix/pods/form/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_SORT_ORDER = ['sortOrder:asc'];

  var _default = Ember.Controller.extend({
    clToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    active: 'title',
    sortOrder: DEFAULT_SORT_ORDER,
    form: Ember.computed.reads('model.form.value'),
    formQuestions: Ember.computed.reads('form.formQuestions'),
    formSections: Ember.computed.reads('form.formSections'),
    sortedSections: Ember.computed.sort('form.formSections', 'sortOrder'),
    shouldDisable: Ember.computed.or('save.isRunning', 'rollback.isRunning'),

    getErrorItem() {
      const formSections = this.get('sortedSections');

      for (let idx = 0; idx < formSections.get('length'); idx++) {
        // Check section title
        const section = formSections.objectAt(idx);
        if (!section.get('name')) return section; // Check section's questions

        const formQuestions = section.get('formQuestions').sortBy('sortOrder');

        for (let idx = 0; idx < formQuestions.get('length'); idx++) {
          const question = formQuestions.objectAt(idx);

          if (!question.get('questionText')) {
            return question;
          }

          if ((question.get('questionType') === 'radio' || question.get('questionType') === 'checkbox') && question.get('formQuestionOptions.length') === 0) {
            return question;
          }
        }
      }
    },

    showErrorToast(errorItem) {
      if (errorItem.get('modelName') === 'form-section') {
        this.clToaster.error(this.intl.t('form.empty-section-error'));
        return;
      }

      if (!errorItem.get('questionText')) {
        this.clToaster.error(this.intl.t('form.empty-question-error'));
        return;
      }

      const questionType = errorItem.get('questionType');

      if (questionType === 'radio' || questionType === 'checkbox') {
        this.clToaster.error(this.intl.t('form.no-options-error'));
      }
    },

    rollback: (0, _emberConcurrency.task)(function* () {
      let form = this.get('model.form.value');
      yield form.get('deepRollback').perform();
      this.set('active', 'title');
    }).restartable(),
    save: (0, _emberConcurrency.task)(function* () {
      let form = this.get('model.form.value');
      let wasNew = form.get('isNew');
      let errorItem = this.getErrorItem();

      if (errorItem) {
        this.set('active', errorItem);
        this.showErrorToast(errorItem);
        return;
      }

      this.set('active', 'active');

      try {
        yield (0, _emberConcurrency.all)(form.get('formSections').invoke('save'));
        yield (0, _emberConcurrency.all)(form.get('formQuestions').invoke('save'));
        let options = yield (0, _emberConcurrency.all)(form.get('formQuestions').map(q => q.get('formQuestionOptions')));
        options = options.reduce((prev, opts) => {
          return prev.concat(opts.toArray());
        }, []);
        yield (0, _emberConcurrency.all)(options.invoke('save'));
        yield form.save();

        if (wasNew) {
          this.replaceRoute('form', {
            form: {
              value: form
            }
          });
        }

        this.clToaster.success(this.intl.t('form.saved'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }).restartable()
  });

  _exports.default = _default;
});