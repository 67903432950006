define("coachlogix/pods/components/v2/avatar-for/client/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n78Uh1UV",
    "block": "{\"symbols\":[\"@model\",\"@size\",\"&attrs\"],\"statements\":[[6,[37,0],[[32,1,[\"isCompany\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"v2/avatar-for/account\",[[17,3]],[[\"@model\",\"@size\"],[[32,1,[\"theOtherAccount\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"isIndependent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"v2/avatar-for/user\",[[17,3]],[[\"@model\",\"@size\"],[[32,1,[\"owner\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/avatar-for/client/template.hbs"
    }
  });

  _exports.default = _default;
});