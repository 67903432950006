define("coachlogix/models/resourceable", ["exports", "@ember-data/model", "ember-data-storefront/mixins/loadable-model", "ember-concurrency"], function (_exports, _model, _loadableModel, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-mixins
  var _default = Ember.Mixin.create(_loadableModel.default, {
    clToaster: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    permission: (0, _model.attr)('string'),
    resourceGroups: (0, _model.hasMany)('resource-group', {
      async: true,
      inverse: null
    }),
    resources: (0, _model.hasMany)('resource', {
      async: true,
      inverse: null
    }),

    /*
     * Adds one or more existing resources or resource-groups
     */
    addResource: (0, _emberConcurrency.task)(function* (resources, {
      save = true
    } = {}) {
      try {
        resources = Ember.isArray(resources) ? resources : [resources];
        resources.forEach(r => {
          // adding a resource group to an object
          if (r.get('modelName') === 'resource-group') {
            this.get('resourceGroups').addObject(r);
          } else {
            // adding a normal resource to an object
            this.get('resources').addObject(r);
          }
        });

        if (save) {
          yield this.save();
          this.clToaster.success('Resource was added successfully.');
        }
      } catch (e) {
        this.clToaster.error(e);
      }
    }),

    /*
     * Removes one or more existing resources or resource-groups
     */
    removeResource: (0, _emberConcurrency.task)(function* (resources) {
      try {
        resources = Ember.isArray(resources) ? resources : [resources];
        resources.forEach(r => {
          // deleting a resource group to an engagement
          if (r.get('modelName') === 'resource-group') {
            this.get('resourceGroups').removeObject(r);
          } else {
            // deleting a normal resource to an engagement
            this.get('resources').removeObject(r);
          }
        });
        yield this.save();
        this.clToaster.success('Deleted successfully.');
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});