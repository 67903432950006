define("coachlogix/models/base-model", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_TIME = 500;

  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    meta: (0, _model.attr)(),
    account: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    user: (0, _model.belongsTo)('user', {
      async: true,
      inverse: null
    }),
    isNotNew: Ember.computed.not('isNew'),
    modelName: Ember.computed('_internalModel.modelName', function () {
      if (this.get('_internalModel.modelName')) {
        return this._internalModel.modelName;
      }
    }),
    capitalizedModelName: Ember.computed('modelName', function () {
      return Ember.String.capitalize(this.get('modelName') || '').replace('-', ' ');
    }),
    clToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    saveTask: (0, _emberConcurrency.task)(function* (opts = {}) {
      let debouncedTask = this.get('debouncedSaveTask');
      let {
        showToast
      } = opts;
      showToast = showToast === undefined ? true : showToast;
      let modelName = this.get('capitalizedModelName');
      debouncedTask.cancelAll();

      try {
        yield this.save();

        if (showToast) {
          let toastMessage = this.get('intl').t('models.model-saved', {
            model: modelName
          });
          this.get('clToaster').success(toastMessage);
        }
      } catch (e) {
        this.get('clToaster').error(e);
      }
    }).keepLatest(),
    deleteTask: (0, _emberConcurrency.task)(function* () {
      let modelName = this.get('capitalizedModelName');
      let toastMessage = this.get('intl').t('models.model-deleted', {
        model: modelName
      });

      try {
        yield this.destroyRecord();
        this.get('clToaster').success(toastMessage);
      } catch (e) {
        this.get('clToaster').error(e);
      }
    }).keepLatest(),
    reloadTask: (0, _emberConcurrency.task)(function* () {
      yield this.reload();
    }),
    debouncedSaveTask: (0, _emberConcurrency.task)(function* (opts = {}) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_TIME);
      this.get('saveTask').perform(opts);
    }).restartable(),
    updateProperty: (0, _emberConcurrency.task)(function* (prop, value, displayToast = true) {
      try {
        if (this.get('isDeleted') || this.get('isSaving') || !this.get('hasDirtyAttributes') && this.get(prop) === value) {
          // noop if the model is deleted or is saved and no changes
          return;
        }

        this.set(prop, value);
        yield this.save();

        if (displayToast) {
          let successMessage = this.intl.t('models.save-success');
          this.clToaster.success(successMessage);
        }
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }).restartable()
  });

  _exports.default = _default;
});