define("coachlogix/pods/invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1dxRNy8S",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,3],[\"page-title\"],null]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h1\"],[14,0,\"cl-title-1\"],[12],[1,[30,[36,2],[\"invoices.title\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"cl-container cl-mx-auto cl-p-4 cl-flex cl-flex-col\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,6],[\"sell coaching in account\"],null],[30,[36,6],[\"buy coaching in account\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"cl-card\",[[24,0,\"cl-mb-3\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-tabs\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"tab\"]],[],[[\"@href\",\"@isActive\"],[[30,[36,0],[\"invoices.sent\"],null],[30,[36,1],[\"invoices.sent\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,2],[\"invoices.tabs.sent\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,1,[\"tab\"]],[],[[\"@href\",\"@isActive\"],[[30,[36,0],[\"invoices.received\"],null],[30,[36,1],[\"invoices.received\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,2],[\"invoices.tabs.received\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[30,[36,10],[[30,[36,9],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"href-to\",\"is-active\",\"t\",\"get-element-by-id\",\"-in-el-null\",\"in-element\",\"can\",\"and\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/invoices/template.hbs"
    }
  });

  _exports.default = _default;
});