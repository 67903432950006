define("coachlogix/models/payment-method", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    sourceReference: (0, _model.attr)('string'),
    routingNumber: (0, _model.attr)('string'),
    last4: (0, _model.attr)('string'),
    expiration: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    account: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    paymentGatewayToken: (0, _model.belongsTo)('payment-gateway-token', {
      inverse: null
    }),
    paymentType: (0, _model.belongsTo)('payment-type', {
      inverse: null
    }),
    currency: (0, _model.belongsTo)('currency', {
      inverse: null
    })
  });

  _exports.default = _default;
});