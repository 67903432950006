define("coachlogix/models/stakeholderable", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    accountService: Ember.inject.service('account'),
    clToaster: Ember.inject.service(),
    stakeholders: (0, _model.hasMany)('employee', {
      async: true,
      inverse: null
    }),
    createStakeholder: (0, _emberConcurrency.task)(function* (employee) {
      let stakeholderable = yield this;
      let stakeholder = this.store.createRecord('stakeholder', {
        employee,
        stakeholderable
      });

      try {
        yield stakeholder.save();
        this.get('stakeholders').pushObject(stakeholder);
      } catch (e) {
        yield stakeholder.destroyRecord();
        this.clToaster.error(e);
      }
    }),
    removeStakeholder: (0, _emberConcurrency.task)(function* (stakeholder) {
      try {
        this.get('stakeholders').removeObject(stakeholder);
        yield stakeholder.destroyRecord();
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});