define("coachlogix/pods/components/settings/subscription/cancel-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Axge4ORG",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],null,[[\"fullscreen\",\"clickOutsideToClose\",\"onClose\"],[true,[30,[36,7],[[35,1]],null],[35,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h2\"],[14,0,\"md-title\"],[12],[1,[30,[36,0],[\"settings.subscription.cancel-dialog.title\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"settings.subscription.cancel-dialog.prompt\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],null,[[\"class\"],[\"layout-row\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"flex\"],[12],[13],[2,\"\\n    \"],[6,[37,3],null,[[\"onClick\",\"disabled\"],[[35,2],[35,1]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"ui.cancel\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[6,[37,3],null,[[\"primary\",\"onClick\",\"disabled\"],[true,[35,4],[35,1]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"ui.confirm\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"disabled\",\"onClose\",\"paper-button\",\"onCancel\",\"paper-dialog-content\",\"paper-dialog-actions\",\"not\",\"paper-dialog\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/settings/subscription/cancel-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});