define("coachlogix/pods/form/form-submission/question/number/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FormQuestionNumberComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('answer.answerJson.[]'), _dec3 = Ember._action, _dec(_class = (_class2 = class FormQuestionNumberComponent extends Ember.Component {
    get value() {
      let [value] = this.answer.answerJson || [];
      return value;
    }

    setValue(v) {
      if (Ember.isBlank(v)) {
        this.set('answer.answerJson', []);
      } else {
        this.set('answer.answerJson', [v]);
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "value", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "value"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setValue", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "setValue"), _class2.prototype)), _class2)) || _class);
  var _default = FormQuestionNumberComponent;
  _exports.default = _default;
});