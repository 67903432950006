define("coachlogix/models/goalable", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    accountService: Ember.inject.service('account'),
    clToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    goals: (0, _model.hasMany)('goal', {
      async: true,
      inverse: 'goalable'
    }),
    createGoal: (0, _emberConcurrency.task)(function* (attrs) {
      let goal = this.store.createRecord('goal', attrs);
      goal.setProperties({
        goalable: this
      });

      try {
        yield goal.save();
        this.clToaster.success(this.intl.t('notifications.goal.created'));
        this.get('goals').pushObject(goal);
        return goal;
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});