define("coachlogix/pods/components/v2/form-controls/timezone-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jlvfokFY",
    "block": "{\"symbols\":[\"timezone\",\"&attrs\",\"@disabled\",\"@autoUpdateTimezone\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex\"],[17,2],[12],[2,\"\\n  \"],[8,\"cl-select\",[[24,0,\"cl-flex-1\"]],[[\"@disabled\",\"@label\",\"@placeholder\",\"@options\",\"@solid\",\"@selected\",\"@onChange\",\"@searchEnabled\",\"@searchField\"],[[32,3],[30,[36,0],[\"ui.timezone\"],null],[30,[36,0],[\"ui.timezone\"],null],[32,0,[\"timezones\"]],true,[32,0,[\"selectedTimezone\"]],[32,0,[\"onTimezoneChange\"]],true,\"name\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[6,[37,1],[[32,1,[\"formattedOffset\"]]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"ui.timezone-offset\"],[[\"offset\"],[[32,1,[\"formattedOffset\"]]]]]]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[32,1,[\"formattedName\"]]],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[32,0,[\"browserTimezone\"]],[30,[36,3],[[32,1],[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      (\"],[1,[32,0,[\"browserTimezone\",\"name\"]]],[2,\")\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[6,[37,1],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[[24,0,\"cl-mt-3 cl-ml-1 cl-cursor-default\"]],[[\"@icon\",\"@primary\"],[\"info\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-tooltip\",[],[[\"@position\"],[\"left\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"ui.current-timezone-helper\"],[[\"name\",\"htmlSafe\"],[[32,0,[\"browserTimezone\",\"name\"]],true]]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"defaultTimezone\",\"eq\",\"and\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/form-controls/timezone-select/template.hbs"
    }
  });

  _exports.default = _default;
});