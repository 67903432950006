define("coachlogix/models/response", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    created: (0, _model.attr)('date'),
    updated: (0, _model.attr)('date'),

    /*user: belongsTo('user', {
      async: true,
      inverse: null
    }),*/
    answers: (0, _model.hasMany)('answer', {
      async: true,
      inverse: null
    }),
    survey: (0, _model.belongsTo)('survey', {
      async: true,
      inverse: null
    }),
    assignment: (0, _model.belongsTo)('assignment', {
      async: true,
      inverse: null
    })
  });

  _exports.default = _default;
});