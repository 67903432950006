define("coachlogix/pods/components/profile/publications/edit-dialog/component", ["exports", "@glimmer/component", "ember-changeset", "ember-changeset-validations", "ember-concurrency-decorators", "coachlogix/validations/publication"], function (_exports, _component, _emberChangeset, _emberChangesetValidations, _emberConcurrencyDecorators, _publication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProfilePublicationsEditComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrencyDecorators.task)({
    group: 'saveChanges'
  }), _dec5 = (0, _emberConcurrencyDecorators.task)({
    group: 'saveChanges'
  }), (_class = (_temp = class ProfilePublicationsEditComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "clToaster", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _defineProperty(this, "changeset", null);

      _initializerDefineProperty(this, "saveChanges", _descriptor4, this);

      let publication = this.args.publication;

      if (!publication) {
        publication = this.store.createRecord('publication', {
          profile: this.args.profile,
          date: new Date()
        });
      }

      this.changeset = new _emberChangeset.Changeset(publication, (0, _emberChangesetValidations.default)(_publication.default), _publication.default);
      this.changeset.validate();
    }

    willDestroy() {
      super.willDestroy(...arguments);

      if (this.changeset.data.isNew) {
        this.changeset.data.destroyRecord();
      }
    }

    *save() {
      try {
        yield this.changeset.save();
        this.clToaster.success(this.intl.t('v2.employee.profile.publications-updated'));
        this.args.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *delete() {
      try {
        yield this.args.publication.destroyRecord();
        this.clToaster.success(this.intl.t('v2.employee.profile.publications-updated'));
        this.args.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveChanges", [_emberConcurrencyDecorators.taskGroup], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  _exports.default = ProfilePublicationsEditComponent;
});