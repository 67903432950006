define("coachlogix/pods/components/v2/upload-dialog/component", ["exports", "@glimmer/component", "ember-ajax/errors", "ember-concurrency", "ember-concurrency-decorators", "ember-group-by", "coachlogix/pods/components/v2/upload-dialog/csv-schemas"], function (_exports, _component, _errors, _emberConcurrency, _emberConcurrencyDecorators, _emberGroupBy, _csvSchemas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UploadDialogComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberGroupBy.default)('parsedData.errors', 'type'), _dec12 = Ember._tracked, _dec13 = Ember._action, (_class = (_temp = class UploadDialogComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "can", _descriptor2, this);

      _initializerDefineProperty(this, "ajax", _descriptor3, this);

      _initializerDefineProperty(this, "clToaster", _descriptor4, this);

      _defineProperty(this, "uploadTypes", [// clients
      {
        value: 'directly-contracted-coachee',
        icon: 'person',
        title: this.intl.t('ui.upload-dialog.upload-types.directly-contracted-coachee.title'),
        description: this.intl.t('ui.upload-dialog.upload-types.directly-contracted-coachee.description'),
        can: c => c.can('sell coaching in account'),
        templateLinkId: 'direct_coachees',
        buildSchema: _csvSchemas.default['directly-contracted-coachee']
      }, {
        value: 'client-company',
        icon: 'business',
        title: this.intl.t('ui.upload-dialog.upload-types.client-company.title'),
        description: this.intl.t('ui.upload-dialog.upload-types.client-company.description'),
        can: c => c.can('sell coaching in account'),
        templateLinkId: 'clients',
        buildSchema: _csvSchemas.default['client-company']
      }, // vendors
      {
        value: 'independent-coach',
        icon: 'person',
        title: this.intl.t('ui.upload-dialog.upload-types.independent-coach.title'),
        description: this.intl.t('ui.upload-dialog.upload-types.independent-coach.description'),
        can: c => c.can('buy coaching in account'),
        templateLinkId: 'independent_coaches',
        buildSchema: _csvSchemas.default['independent-coach']
      }, {
        value: 'external-vendor',
        icon: 'business',
        title: this.intl.t('ui.upload-dialog.upload-types.external-vendor.title'),
        description: this.intl.t('ui.upload-dialog.upload-types.external-vendor.description'),
        can: c => c.can('buy coaching in account'),
        templateLinkId: 'vendors',
        buildSchema: _csvSchemas.default['external-vendor']
      }, // employees
      {
        value: 'employee',
        icon: 'person',
        title: this.intl.t('ui.upload-dialog.upload-types.employee.title'),
        description: this.intl.t('ui.upload-dialog.upload-types.employee.description'),
        can: () => true,
        templateLinkId: 'employees',
        buildSchema: _csvSchemas.default['employee']
      }, // engagements
      {
        value: 'create-engagements',
        icon: 'add',
        title: this.intl.t('ui.upload-dialog.upload-types.create-engagements.title'),
        description: this.intl.t('ui.upload-dialog.upload-types.create-engagements.description'),
        can: () => true,
        templateLinkId: 'engagements',
        buildSchema: _csvSchemas.default['create-engagements']
      }, {
        value: 'update-engagements',
        icon: 'sync',
        title: this.intl.t('ui.upload-dialog.upload-types.update-engagements.title'),
        description: this.intl.t('ui.upload-dialog.upload-types.update-engagements.description'),
        can: () => true,
        templateLinkId: 'update_engagements',
        buildSchema: _csvSchemas.default['update-engagements']
      }]);

      _defineProperty(this, "selectedTypes", this.uploadTypes.filter(t => {
        return this.args.types.includes(Ember.String.dasherize(t.value)) && t.can(this.can);
      }));

      _initializerDefineProperty(this, "selectedType", _descriptor5, this);

      _initializerDefineProperty(this, "parsedData", _descriptor6, this);

      _initializerDefineProperty(this, "percentUploaded", _descriptor7, this);

      _initializerDefineProperty(this, "file", _descriptor8, this);

      _initializerDefineProperty(this, "didUploadSucceed", _descriptor9, this);

      _initializerDefineProperty(this, "didUpload", _descriptor10, this);

      _initializerDefineProperty(this, "groupedErrors", _descriptor11, this);

      _initializerDefineProperty(this, "errors", _descriptor12, this);
    }

    get step() {
      if (!this.selectedType) {
        return 1;
      }

      if (!this.parsedData) {
        return 2;
      }

      if (this.didUpload) {
        return 4;
      } else {
        return 3;
      }
    }

    *parseFile([file]) {
      if (!file) {
        return;
      }

      const Papa = yield emberAutoImportDynamic("papaparse");

      const parseFileP = rawFile => {
        return new Promise(resolve => {
          Papa.parse(rawFile, {
            worker: true,
            header: true,
            skipEmptyLines: 'greedy',
            complete: results => resolve(results)
          });
        });
      };

      let parsedData = yield parseFileP(file);
      let errors = [];

      if (parsedData.errors && parsedData.errors.length) {
        for (let e of parsedData.errors) {
          errors.push({
            type: 'csv-error',
            message: this.intl.t(`ui.upload-dialog.errors.${e.code}`, e)
          });
        }
      }

      try {
        let [Joi, JoiDate] = yield (0, _emberConcurrency.all)([emberAutoImportDynamic("joi"), emberAutoImportDynamic("@hapi/joi-date")]);
        Joi = Joi.extend(JoiDate.default);
        yield this.selectedType.buildSchema(Joi).validateAsync(parsedData, {
          abortEarly: false,
          errors: {
            render: true
          }
        });
      } catch (err) {
        if (err.details) {
          for (let e of err.details) {
            // trying to group errors in a way that makes sense in the ui
            // the @groupBy macro will group errors by this key
            let type = e.type;

            if (type === 'any.required') {
              type = 'string.empty';
            }

            if (type === 'number.positive') {
              type = 'alternatives.types';
            }

            errors.push({
              message: this.intl.t(`ui.upload-dialog.errors.${e.type}`, e.context),
              type
            });
          }
        }
      }

      this.file = file;
      this.parsedData = {
        data: parsedData.data,
        fields: parsedData.meta.fields,
        errors
      };
    }

    *upload() {
      this.errors = [];
      this.didUpload = true;

      try {
        if (this.selectedType.value === 'update-engagements') {
          yield this.uploadUpdateEngagements.perform();
        } else {
          yield this.uploadFile.perform();
        }

        this.didUploadSucceed = true;
      } catch (e) {
        if ((0, _errors.isBadRequestError)(e) || (0, _errors.isConflictError)(e)) {
          let errors = e.payload.errors || [];
          this.errors = errors;
        } else {
          this.clToaster.error(e);
        }
      }
    }

    *uploadFile() {
      this.percentUploaded = 0;
      let type = this.selectedType.templateLinkId;
      let url = `/import/${type}`;
      let formData = new FormData();
      formData.append('upload_file', this.file);
      yield this.ajax.request(url, {
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        xhr: () => {
          let xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener('progress', evt => {
            if (evt.lengthComputable) {
              let percentComplete = evt.loaded / evt.total;
              this.percentUploaded = Math.trunc(percentComplete * 100);
            }
          }, false);
          return xhr;
        }
      });
    }

    *uploadUpdateEngagements() {
      let rows = this.parsedData.data;
      let engagements = [];
      let engagement = null;

      for (let i = 0; i < rows.length; i++) {
        let row = rows[i]; // rows with no ids should be skipped

        if (Ember.isEmpty(row['Id'])) {
          continue;
        }

        if (row['Type'] === 'Engagement') {
          if (!engagement) {
            engagement = {};
          } else if (engagement.id !== row['Id']) {
            // this is a new engagement. we need to add it to the list
            engagements.push(engagement); // and reset

            engagement = {};
          } // first and additional engagement lines


          engagement.id = row['Id'];

          if (Ember.isPresent(row['Field'])) {
            engagement[row['Field']] = row['Value'];
          }
        }

        if (['Engagement Session', 'Form', 'Todo', 'Goal', 'File', 'Link'].includes(row['Type'])) {
          let arrayKey;

          switch (row['Type']) {
            case 'Engagement Session':
              arrayKey = 'engagement-sessions';
              break;

            case 'Goal':
              arrayKey = 'goals';
              break;

            case 'Form':
            case 'File':
            case 'Todo':
            case 'Link':
              arrayKey = 'tasks';
              break;

            default:
              break;
          }

          if (!arrayKey) {
            continue;
          }

          if (!engagement[arrayKey]) {
            engagement[arrayKey] = [];
          }

          let obj = engagement[arrayKey].find(o => o.id === row['Id']);

          if (!obj) {
            obj = {};
            engagement[arrayKey].push(obj);
          }

          obj.id = row['Id'];

          if (Ember.isPresent(row['Field'])) {
            obj[row['Field']] = row['Value'];
          }

          if (row['Type'] === 'Engagement Session' && row['Field'] === 'status' && row['Value'] === 'completed') {
            obj.completed_date = row['Completion_date'];
            obj.duration = row['Duration'];
          }
        }
      } // push last engagement


      engagements.push(engagement);
      yield this.ajax.request('/bulk/engagements', {
        method: 'POST',
        data: engagements,
        xhr: () => {
          let xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener('progress', evt => {
            if (evt.lengthComputable) {
              let percentComplete = evt.loaded / evt.total;
              this.percentUploaded = Math.trunc(percentComplete * 100);
            }
          }, false);
          return xhr;
        }
      });
    }

    handleClose() {
      if (this.didUploadSucceed) {
        this.args.onFinish?.();
      }

      this.args.onClose?.();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "can", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedType", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "parsedData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "percentUploaded", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "file", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "didUploadSucceed", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "didUpload", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "parseFile", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "parseFile"), _class.prototype), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "groupedErrors", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "upload", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "upload"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadUpdateEngagements", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "uploadUpdateEngagements"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleClose", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "handleClose"), _class.prototype)), _class));
  _exports.default = UploadDialogComponent;
});