define("coachlogix/services/ember-oauth2", ["exports", "ember-oauth2/services/ember-oauth2"], function (_exports, _emberOauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberOauth.default.extend({
    authUri() {
      let uri = this._super(...arguments);

      if (this.get('linkToken')) {
        uri += `&link_token=${encodeURIComponent(this.get('linkToken'))}`;
      }

      if (this.get('avoidLinking')) {
        uri += `&avoid_linking=true`;
      }

      return uri;
    }

  });

  _exports.default = _default;
});