define("coachlogix/pods/components/cl-ui/toolbar-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['toolbar-input', 'layout-row', 'layout-align-center-center'],
    classNameBindings: ['focused:toolbar-input--focused']
  });

  _exports.default = _default;
});