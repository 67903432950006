define("coachlogix/models/sessionable", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    accountService: Ember.inject.service('account'),
    clToaster: Ember.inject.service(),
    sessions: (0, _model.hasMany)('session', {
      async: true,
      inverse: null
    }),
    createSession: (0, _emberConcurrency.task)(function* (attrs) {
      let session = this.store.createRecord('session', { ...attrs,
        sessionable: this,
        user: this.get('accountService.activeUser')
      });

      try {
        yield session.save();
        this.get('session').pushObject(session);
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});