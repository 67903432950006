define("coachlogix/models/form-section", ["exports", "@ember-data/model", "ember-data-copyable", "coachlogix/models/base-model"], function (_exports, _model, _emberDataCopyable, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_emberDataCopyable.default, {
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    sortOrder: (0, _model.attr)('number'),
    resource: (0, _model.belongsTo)('resource', {
      inverse: 'formSections'
    }),
    formQuestions: (0, _model.hasMany)('form-question', {
      inverse: 'formSection',
      cascadeDelete: true
    }),

    addQuestion(props) {
      let store = Ember.getOwner(this).lookup('service:store');
      let questions = this.get('formQuestions'); // order is length or the specified order

      let sortOrder = questions.get('length');

      if (props && props.sortOrder !== undefined) {
        sortOrder = props.sortOrder;
      }

      let question = store.createRecord('form-question', { ...props,
        sortOrder,
        resource: this.get('resource')
      }); // for each question with a higher order, shift it by one
      // to make room for the new question

      questions.forEach(q => {
        if (q.get('sortOrder') >= sortOrder) {
          q.set('sortOrder', q.get('sortOrder') + 1);
        }
      });
      questions.addObject(question);
      this.normalizeQuestionsSortOrder();
      return question;
    },

    removeQuestion(question) {
      this.get('formQuestions').removeObject(question);
      this.normalizeQuestionsSortOrder();
    },

    normalizeQuestionsSortOrder() {
      this.get('formQuestions').sortBy('sortOrder').forEach((q, i) => {
        q.set('sortOrder', i + 1);
      });
    },

    async persistChangedQuestions() {
      let changedQuestions = this.get('formQuestions').filter(q => q.get('hasDirtyAttributes'));
      await Ember.RSVP.all(changedQuestions.map(q => q.save()));
      return changedQuestions;
    }

  });

  _exports.default = _default;
});