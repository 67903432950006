define("coachlogix/models/contact", ["exports", "@ember-data/model", "ember-concurrency", "qs-stringify", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrency, _qsStringify, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * This mixin creates a more useful status for contacts
   * on the frontend, derived from the client and client account statuses.
   * More info https://docs.google.com/spreadsheets/d/1HYi-Jbx1ibXA0r0KzhqZfZXg_KARUhbBR72uRe2pZUQ/edit?userstoinvite=pete.nystrom@coachlogix.com&ts=5a8e1398&actionButton=1#gid=637340266
   */
  var _default = _baseModel.default.extend({
    status: (0, _model.attr)('string'),
    // approval_needed, approved, declined, invited
    originatedBy: (0, _model.attr)('string'),
    employee: (0, _model.belongsTo)('employee'),
    account: (0, _model.belongsTo)('account'),
    isApprovalNeeded: Ember.computed.equal('status', 'approval_needed'),
    isInvited: Ember.computed.equal('status', 'invited'),
    isCoach: Ember.computed.equal('modelName', 'client-coach'),
    isCoachee: Ember.computed.equal('modelName', 'client-coachee'),
    contactStatus: Ember.computed('client.{clientAccount.isFree,client.theOtherAccount.status,isClient,isVendor,status}', 'isApprovalNeeded', 'originatedBy', 'status', function () {
      let client = this.get('client');

      if (this.get('isApprovalNeeded')) {
        if (this.get('originatedBy') === 'client' && client.get('isClient')) {
          return 'your_approval_needed';
        } else if (this.get('originatedBy') === 'vendor' && client.get('isVendor')) {
          if (client.get('clientAccount.isFree')) {
            return 'managed_by_vendor';
          } else {
            return 'your_approval_needed';
          }
        } else if (client.get('isVendor')) {
          return 'vendor_approval_needed';
        } else if (client.get('isClient')) {
          if (client.get('clientAccount.status') === 'inactive' || client.get('clientAccount.isFree')) {
            return 'not_applicable';
          } else {
            return 'client_approval_needed';
          }
        }
      } else {
        return this.get('status');
      }
    }),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    approveContact: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'approved');
        yield this.save();
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    declineContact: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.get('modelName') == 'client-coachee') {
          yield this.destroyRecord();
        } else {
          this.set('status', 'declined');
          yield this.save();
        }
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    resendInvitation: (0, _emberConcurrency.task)(function* () {
      try {
        let params = {};

        if (this.get('isCoach')) {
          params.client_coach_id = this.get('id');
        } else if (this.get('isCoachee')) {
          params.client_coachee_id = this.get('id');
        }

        let queryString = (0, _qsStringify.default)(params);
        let wasInvited = this.get('isInvited');
        this.set('status', 'invited');
        yield this.get('ajax').post(`/helpers/resend-invitation?${queryString}`);
        const toastMessage = wasInvited ? this.get('intl').t('ui.invite-resent-success') : this.get('intl').t('ui.invite-sent-success');
        this.clToaster.success(toastMessage);
      } catch (e) {
        this.clToaster.error('Invitation sent less than 24 hours ago.');
      }
    })
  });

  _exports.default = _default;
});