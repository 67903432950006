define("coachlogix/pods/stripe-callback/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      didTransition() {
        let code = this.controller.get('code');
        this.store.createRecord('stripe-connected-account', {
          code
        }).save().then(() => {
          window.close();
        }).catch(() => {
          window.close();
        });
      }

    }
  });

  _exports.default = _default;
});