define("coachlogix/pods/components/cl-employee-selection-dialog/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ClEmployeeSelectionDialog = (_dec = Ember.inject.service, (_class = (_temp = class ClEmployeeSelectionDialog extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    *getEmployees(searchTerm, pageNumber, pageSize) {
      let sort = 'user.first_name';
      let filter = {
        search: searchTerm
      };

      if (this.args.coachable) {
        filter.coachable = 1;
      }

      if (this.args.shareable) {
        filter.shareable = 1;
      }

      if (this.args.coaches) {
        filter.coaches = 1;
      }

      if (this.args.canParticipateEngagement) {
        filter.can_participate_engagement = 1;
      }

      if (this.args.engagementCoachee) {
        filter.engagement_coachee = this.args.engagementCoachee.id;
      }

      if (this.args.coachPool) {
        filter.exclude_from_coach_pool_id = this.args.coachPool.id;
      }

      if (this.args.coacheesForClient) {
        filter.coachees_for_client = this.args.coacheesForClient.id;
      }

      if (this.args.coacheesForVendor) {
        filter.coachees_for_vendor = this.args.coacheesForVendor.id;
      }

      if (this.args.coachesForClient) {
        filter.coaches_for_client = this.args.coachesForClient.id;
      }

      if (this.args.coachesForVendor) {
        filter.coaches_for_vendor = this.args.coachesForVendor.id;
      }

      if (this.args.forEngagement) {
        let engagement = yield this.args.forEngagement;
        filter.for_engagement = engagement.id;
      }

      if (this.args.canChat) {
        filter['can-chat'] = 1;
      }

      let page = {
        size: pageSize,
        number: pageNumber
      };
      let include = ['user', 'account'].join(',');
      let employees = yield this.store.query('employee', {
        sort,
        page,
        include,
        filter
      });
      return employees.toArray();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getEmployees", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getEmployees"), _class.prototype)), _class));
  _exports.default = ClEmployeeSelectionDialog;
});