define("coachlogix/pods/components/v2/hours-days-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B4vn34mj",
    "block": "{\"symbols\":[\"item\",\"&attrs\",\"@label\",\"@disabled\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex cl-items-end\"],[17,2],[12],[2,\"\\n  \"],[8,\"cl-input\",[[16,\"disabled\",[32,4]],[24,\"min\",\"0\"],[24,0,\"cl-mr-2\"],[24,4,\"number\"]],[[\"@label\",\"@solid\",\"@value\",\"@onChange\"],[[32,3],true,[32,0,[\"displayValue\"]],[32,0,[\"handleChange\"]]]],null],[2,\"\\n  \"],[8,\"cl-select\",[],[[\"@disabled\",\"@options\",\"@solid\",\"@selected\",\"@onChange\"],[[32,4],[32,0,[\"timeUnitOptions\"]],true,[32,0,[\"timeUnit\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"timeUnit\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/hours-days-input/template.hbs"
    }
  });

  _exports.default = _default;
});