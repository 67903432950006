define("coachlogix/pods/components/v2/engagement/create-dialog/select-custom-coach-pool-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zxrgxxu/",
    "block": "{\"symbols\":[\"@engagementCoachee\",\"@onCoachesSelect\",\"@onClose\",\"&attrs\"],\"statements\":[[8,\"cl-employee-selection-dialog\",[[24,0,\"cl-w-32\"],[17,4]],[[\"@required\",\"@title\",\"@noOptionsText\",\"@coaches\",\"@engagementCoachee\",\"@multiple\",\"@onChange\",\"@onClose\",\"@displayEmployeeEngagementAvailability\",\"@warnMessage\",\"@closeOnSelect\"],[true,[30,[36,0],[\"engagements.create-dialog.select-coaches\"],null],[30,[36,0],[\"engagements.create-dialog.no-coaches\"],null],true,[32,1],true,[32,2],[32,3],true,[30,[36,1],[[32,0,[\"account\",\"activeAccount\",\"isIndependentCoach\"]],[30,[36,0],[\"engagement.capacity-coaches-warning\"],null]],null],false]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"unless\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement/create-dialog/select-custom-coach-pool-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});