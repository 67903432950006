define("coachlogix/pods/components/v2/engagement/engagement-coaching-request/detail-view/component", ["exports", "@ember-decorators/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CoachingRequestDetailView = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('session.meetingLocation.meetingType'), _dec5 = Ember.computed('account.activeUser.timezone.name'), _dec(_class = (_class2 = (_temp = class CoachingRequestDetailView extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }

    get locationIcon() {
      let meetingType = this.get('session.meetingLocation.meetingType');

      if (meetingType === 'phone') {
        return 'phone';
      } else if (meetingType === 'in_app_video') {
        return 'smartphone';
      } else if (meetingType === 'video') {
        return 'videocam';
      } else {
        return 'location-on';
      }
    }

    get timezoneName() {
      const timezone = this.get('account.activeUser.timezone.name');

      if (timezone) {
        return _moment.default.tz(timezone).format('z');
      }

      return this.intl.t('global.general.timezone_default');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "locationIcon", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "locationIcon"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "timezoneName", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "timezoneName"), _class2.prototype)), _class2)) || _class);
  var _default = CoachingRequestDetailView;
  _exports.default = _default;
});