define("coachlogix/validations/credential", ["exports", "ember-changeset-validations/validators", "coachlogix/utils/urlish-regex", "coachlogix/validators/presence-if"], function (_exports, _validators, _urlishRegex, _presenceIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    issuingOrg: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }),
    issueDate: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }),
    issuingOrgCustomName: (0, _presenceIf.default)({
      presence: true,
      ignoreBlank: true
    }, a => a.issuingOrg?.isCustom),
    credentialUrl: (0, _validators.validateFormat)({
      regex: _urlishRegex.default,
      allowBlank: true,
      message: 'Should be a valid link'
    })
  };
  _exports.default = _default;
});