define("coachlogix/pods/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8KYfUJvN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-flex cl-items-center cl-justify-center cl-w-full cl-h-full cl-absolute cl-bg-pale-grey-three\"],[12],[2,\"\\n  \"],[8,\"cl-progress-circular\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/loading/template.hbs"
    }
  });

  _exports.default = _default;
});