define("coachlogix/pods/settings/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SettingsIndexRoute extends Ember.Route {
    redirect() {
      this.replaceWith('settings.basic-info');
    }

  }

  _exports.default = SettingsIndexRoute;
});