define("coachlogix/pods/video/video-track/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r4Nxc80G",
    "block": "{\"symbols\":[\"&attrs\",\"@videoTrack\"],\"statements\":[[11,\"video\"],[17,1],[4,[38,0],[[32,0,[\"setupVideoElement\"]],[32,2]],null],[4,[38,1],[[32,0,[\"setupVideoElement\"]],[32,2]],null],[4,[38,2],[[32,0,[\"destroyVideoElement\"]],[32,2]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/video/video-track/template.hbs"
    }
  });

  _exports.default = _default;
});