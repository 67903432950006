define("coachlogix/models/contract-adjustment-request-field", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    adjustmentField: (0, _model.attr)('string'),
    adjustmentValue: (0, _model.attr)('string'),
    originalValue: (0, _model.attr)('string'),
    contractAdjustmentRequest: (0, _model.belongsTo)('contract-adjustment-request', {
      inverse: 'contractAdjustmentRequestFields'
    })
  });

  _exports.default = _default;
});