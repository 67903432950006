define("coachlogix/pods/components/settings/subscription/update-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f7vh3xT2",
    "block": "{\"symbols\":[\"dialog\",\"&attrs\",\"@disabled\",\"@onClose\",\"@plan\",\"@onUpdate\"],\"statements\":[[8,\"cl-dialog\",[[24,0,\"cl-w-32\"],[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"cl-title-3 cl-flex-1\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"settings.subscription.update-dialog.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"cl-button\",[[16,\"disabled\",[32,3]]],[[\"@iconButton\",\"@onClick\"],[true,[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"close\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"settings.subscription.update-dialog.prompt\"],[[\"name\",\"interval\",\"htmlSafe\"],[[32,5,[\"name\"]],[32,5,[\"interval\"]],true]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-button\",[[16,\"disabled\",[32,3]]],[[\"@raised\",\"@onClick\"],[true,[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"ui.cancel\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"cl-button\",[[16,\"disabled\",[32,3]]],[[\"@raised\",\"@primary\",\"@onClick\"],[true,true,[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"ui.save\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/settings/subscription/update-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});