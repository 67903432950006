define("coachlogix/pods/components/cronofy/calendar-sync/component", ["exports", "@ember-decorators/component", "cronofy-elements", "coachlogix/config/environment"], function (_exports, _component, _cronofyElements, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CalendarSyncComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec(_class = (_class2 = (_temp = class CalendarSyncComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "uniqueId", Ember.guidFor(this));

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }

    setupElement(element, [token, linkToken]) {
      element.id = this.uniqueId;
      let locale = this.account.activeUser.iso639Language;
      (0, _cronofyElements.CalendarSync)({
        target_id: element.id,
        element_token: token,
        locale,
        // single_profile: true,
        authorization: {
          redirect_uri: `${window.location.origin}/cronofy-callback`,
          state: JSON.stringify({
            path: window.location.pathname
          }),
          client_id: _environment.default.cronofy.clientId,
          scope: 'create_calendar create_event delete_event read_free_busy change_participation_status',
          avoid_linking: true,
          ...(linkToken && {
            link_token: linkToken
          }) // this adds link_token only if linkToken is truthy

        },
        styles: {
          prefix: 'calendar-sync'
        },
        callback: ({
          notification,
          userinfo
        }) => {
          if (this.onProfileRevoke && notification.type === 'profile_revoked') {
            this.onProfileRevoke(notification, userinfo);
          }
        },
        translations: {
          en: {
            calendar_sync: {
              cancel: this.intl.t('ui.back')
            }
          },
          fr: {
            calendar_sync: {
              cancel: this.intl.t('ui.back')
            }
          }
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "setupElement", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "setupElement"), _class2.prototype)), _class2)) || _class);
  var _default = CalendarSyncComponent;
  _exports.default = _default;
});