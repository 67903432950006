define("coachlogix/pods/components/v2/avatar-for/employee/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tG8FvD+2",
    "block": "{\"symbols\":[\"@model\",\"@size\",\"&attrs\"],\"statements\":[[8,\"v2/avatar-for/user\",[[17,3]],[[\"@model\",\"@size\"],[[32,1,[\"user\"]],[32,2]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/avatar-for/employee/template.hbs"
    }
  });

  _exports.default = _default;
});