define("coachlogix/models/award", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = _baseModel.default.extend({
    associationType: (0, _model.attr)('string'),
    fresh: (0, _model.attr)('boolean'),
    pending: (0, _model.attr)('boolean'),
    sourceObject: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      async: true,
      inverse: null
    }),
    targetObject: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      async: true,
      inverse: null
    }),
    dependencies: (0, _model.hasMany)('association', {
      async: true,
      inverse: null
    }),
    businessUnit: (0, _model.belongsTo)('businessUnit', {
      inverse: null
    }),
    relatedPendingAction: (0, _model.belongsTo)('pendingAction', {
      async: true,
      inverse: null
    })
  });

  _exports.default = _default;
});