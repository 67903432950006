define("coachlogix/pods/components/ui/video-embed/component", ["exports", "@glimmer/component", "embed-video", "ember-component-css/pod-names"], function (_exports, _component, _embedVideo, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VideoEmbedComponent extends _component.default {
    get styleNamespace() {
      return _podNames.default['ui/video-embed'];
    }

    get embedCode() {
      try {
        return Ember.String.htmlSafe((0, _embedVideo.default)(this.args.videoUrl));
      } catch (e) {
        return null;
      }
    }

  }

  _exports.default = VideoEmbedComponent;
});