define("coachlogix/utils/urlish-regex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // taken from https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript/19696443#19696443
  var _default = new RegExp(/^(?:https?:\/\/)?(?:www\.)?(?:[a-zA-Z\d-_.]+(?:(?:\.|@)[a-zA-Z\d]{2,}))(?:(?:[-a-zA-Z\d:%_+.~#*$!?&//=@]*)(?:[,](?![\s]))*)*$/i);

  _exports.default = _default;
});