define("coachlogix/abilities/engagement", ["exports", "ember-can", "coachlogix/abilities/engagement-permission-helpers", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _engagementPermissionHelpers, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_engagementPermissionHelpers.default, _userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    engagement: Ember.computed.reads('model'),
    isOwnerAccount: Ember.computed('account.activeAccount', 'engagement.account', function () {
      return this.get('account.activeAccount') === this.get('engagement.account');
    }),
    isAdmin: Ember.computed.equal('model.role', 'admin'),
    isCoach: Ember.computed.equal('model.role', 'coach'),
    isViewer: Ember.computed.equal('model.role', 'viewer'),
    isManager: Ember.computed.equal('model.role', 'manager'),
    isActive: Ember.computed.equal('model.status', 'active'),
    isCompleted: Ember.computed.equal('model.status', 'completed'),
    canEdit: Ember.computed('isAdmin', 'isManager', 'canBeEdited', function () {
      return this.get('canBeEdited') && (this.get('isAdmin') || this.get('isManager'));
    }),
    canSeeHeader: Ember.computed('canBeEdited', 'isAdmin', 'isCoach', 'isManager', 'isViewer', function () {
      return this.canBeEdited && (this.isAdmin || this.isManager || this.isCoach || this.isViewer);
    }),
    // Both, coach and coachee, must exist to be able to edit.
    canBeEdited: Ember.computed('engagement.{coach.id,coachee.id,role}', function () {
      let coachId = this.get('engagement.coach.id');
      let coacheeId = this.get('engagement.coachee.id');
      let isDraft = this.engagementStatusIsAny('draft', 'coach_selection', 'draft_coach_selection');

      if (isDraft) {
        return true;
      }

      return coachId && coacheeId;
    }),
    canAssignCoach: Ember.computed('canEdit', 'roles.[]', function () {
      return this.canEdit && !this.engagementStatusIsAny('coach_selection', 'draft_coach_selection');
    }),
    canEditTemplate: Ember.computed('engagementStatus', 'roles.[]', function () {
      return this.engagementStatusIsAny('draft', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager');
    }),
    canEditDueDates: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      return this.get('canBeEdited') && this.roleIsAny('admin', 'manager', 'coach');
    }),
    canManageNotes: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      return this.get('canBeEdited') && this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach', 'coachee');
    }),
    canManageResources: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      return this.get('canBeEdited') && this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager', 'coach', 'coachee');
    }),
    canDownloadReports: Ember.computed('roles.[]', function () {
      return this.roleIsAny('admin', 'manager', 'coach');
    }),
    canPreviewVisibility: Ember.computed.reads('canEdit'),
    // check https://docs.google.com/spreadsheets/d/1zYunY8RgFFCe-odeK7k6qe7WTdn3myBFbCgb0Ws1jMc/edit#gid=613053699
    // for more details
    // "add items" was a bad name because this also applies to reordering items and
    // other general program edit permissions as well
    canEditProgram: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      if (this.get('canBeEdited') && this.roleIsAny('admin') && this.engagementStatusIsAny('draft', 'coach_selection', 'draft_coach_selection')) {
        return true;
      }

      if (this.get('canBeEdited') && this.roleIsAny('admin') && this.engagementStatusIsAny('active')) {
        return true;
      }

      return false;
    }),
    canAddProgramItem: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      if (this.get('canBeEdited') && this.roleIsAny('admin') && this.engagementStatusIsAny('draft', 'coach_selection', 'draft_coach_selection')) {
        return true;
      }

      if (this.get('canBeEdited') && this.roleIsAny('admin') && this.engagementStatusIsAny('active')) {
        return true;
      }

      return false;
    }),
    canEditProgramItem: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      if (this.get('canBeEdited') && this.roleIsAny('admin') && this.engagementStatusIsAny('draft', 'coach_selection', 'draft_coach_selection')) {
        return true;
      }

      if (this.get('canBeEdited') && this.roleIsAny('admin') && this.engagementStatusIsAny('active')) {
        return true;
      }

      return false;
    }),
    // also used for archiving
    canDelete: Ember.computed('account.activeAccount.id', 'engagement.ownerAccount.id', 'engagementStatus', 'roles.[]', function () {
      if (this.roleIsAny('admin', 'manager') && this.get('engagement.ownerAccount.id') === this.get('account.activeAccount.id')) {
        return true;
      }

      return false;
    }),
    canStartMatching: Ember.computed('account.activeAccount.id', 'engagement.isDraftMatching', 'engagementStatus', 'roles.[]', function () {
      let isAllowedToStart = this.roleIsAny('admin') || this.engagement.get('ownerAccount.id') === this.account.activeAccount.id;

      if (isAllowedToStart && this.engagement.isDraftMatching) {
        return true;
      }

      return false;
    }),
    canLinkInformation: Ember.computed('roles.[]', function () {
      return this.roleIsAny('admin', 'manager', 'coach');
    }),
    canLinkToCoachSummary: Ember.computed('roles.[]', function () {
      return this.roleIsAny('admin', 'manager', 'coach');
    }),
    canClickCoacheeName: Ember.computed('roles.[]', function () {
      return this.roleIsAny('admin', 'manager', 'coach');
    }),
    canViewSettings: Ember.computed('engagementStatus', 'roles.[]', function () {
      if (this.hasAccountRole('account_global_admin', 'location_global_admin')) {
        return true;
      }

      if (this.roleIsAny('admin', 'manager')) {
        return true;
      }

      if (this.roleIsAny('coach') && this.engagementStatusIsAny('active', 'inactive', 'complete')) {
        return true;
      }

      return false;
    }),
    canEditSettings: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      if (this.get('canBeEdited') && this.hasAccountRole('account_global_admin', 'location_global_admin')) {
        return true;
      }

      if (this.get('canBeEdited') && this.roleIsAny('admin', 'manager')) {
        return true;
      }

      return false;
    }),
    canStart: Ember.computed('canEditSettings', 'account.activeEmployee.{tenant.ownerAccount.id,account.id}', function () {
      let ownerAccountId = this.account.activeEmployee.get('tenant.ownerAccount.id');

      if (ownerAccountId) {
        let employeeAccountId = this.account.activeEmployee.get('account.id');
        return this.canEditSettings && ownerAccountId === employeeAccountId;
      } else {
        return this.canEditSettings;
      }
    }),
    canDownloadSummary: Ember.computed('engagementStatus', 'roles.[]', function () {
      return this.roleIsAny('admin', 'manager', 'coach') && this.engagementStatusIsAny('active', 'inactive', 'completed');
    }),
    canMeetingLocation: Ember.computed('canBeEdited', 'engagementStatus', 'isCoach', 'roles.[]', function () {
      return this.get('canBeEdited') && (this.roleIsAny('admin', 'coach') || this.get('isCoach'));
    }),
    canEditFiles: Ember.computed.bool('canBeEdited'),
    canViewBillingContract: Ember.computed('roles.[]', function () {
      return this.roleIsAny('admin', 'manager');
    }),
    canEditBillingContract: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      return this.get('canBeEdited') && this.roleIsAny('admin', 'manager') && this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection');
    }),
    canCreateNotes: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      if (this.get('canBeEdited') && this.roleIsAny('admin', 'manager', 'coach', 'coachee') && this.engagementStatusIsAny('active')) {
        return true;
      }

      if (this.get('canBeEdited') && this.roleIsAny('coach', 'coachee') && this.engagementStatusIsAny('active')) {
        return true;
      }

      return false;
    }),
    canAddResourcesFromGlobal: Ember.computed('canBeEdited', 'engagementStatus', 'isCoachee', 'roles.[]', function () {
      return this.roleIsAny('admin', 'manager', 'coach') || !this.isCoachee;
    }),
    canCreateResources: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      if (this.get('canBeEdited') && this.engagementStatusIsAny('draft', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager')) {
        return true;
      }

      if (this.get('canBeEdited') && this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach', 'coachee')) {
        return true;
      }

      return false;
    }),
    canEditResources: Ember.computed.reads('canCreateResources'),
    canManageGoals: Ember.computed('canBeEdited', 'engagementStatus', 'roles.[]', function () {
      if (this.get('canBeEdited')) {
        return this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach', 'coachee') || this.engagementStatusIsAny('draft', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager');
      }

      return false;
    }),
    canInvoice: Ember.computed('account.activeAccount.isIndependentCoach', 'canBeEdited', 'engagement.{client.isClient,contractorClient.isClient}', 'engagementStatus', 'roles.[]', function () {
      if (this.get('account.activeAccount.isIndependentCoach')) {
        return this.get('canBeEdited') && this.engagementStatusIsAny('active', 'complete', 'inactive') && (this.get('engagement.client.isClient') || this.get('engagement.contractorClient.isClient'));
      } else {
        return this.get('canBeEdited') && this.engagementStatusIsAny('active', 'complete', 'inactive') && this.get('engagement.client.isClient') && this.roleIsAny('admin', 'manager');
      }
    }),
    canAssignContract: Ember.computed.reads('isAdmin'),
    canSeeMatchingProcess: Ember.computed('engagement.{isDraftMatching,isMatching}', 'engagementStatus', function () {
      let isInAMatchingState = this.engagement.isMatching || this.engagement.isDraftMatching;
      return isInAMatchingState && this.roleIsAny('admin');
    })
  });

  _exports.default = _default;
});