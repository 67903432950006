define("coachlogix/pods/settings/branding/colors/component", ["exports", "@glimmer/component", "ember-changeset", "ember-concurrency-decorators"], function (_exports, _component, _emberChangeset, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BrandingColorsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class BrandingColorsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "editState", _descriptor3, this);

      _initializerDefineProperty(this, "changeset", _descriptor4, this);

      _defineProperty(this, "defaultPrimary", '#5e1de1');

      _defineProperty(this, "defaultSecondary", '#02dc94');

      _defineProperty(this, "colorThemes", [{
        name: 'Coaching.com (default)',
        primary: null,
        secondary: null
      }, {
        name: 'Quarterback',
        primary: '#0a6788',
        secondary: '#f0c809'
      }, {
        name: 'Friday Night',
        primary: '#22282d',
        secondary: '#e783a2'
      }, {
        name: 'Sunkist',
        primary: '#f6511e',
        secondary: '#ffb404'
      }, {
        name: 'Midnight',
        primary: '#344055',
        secondary: '#888098'
      }, {
        name: 'Ocean',
        primary: '#0181af',
        secondary: '#2dc7ff'
      }, {
        name: 'Sundae',
        primary: '#ffeaee',
        secondary: '#7f675b'
      }, {
        name: 'Muffins',
        primary: '#b7506f',
        secondary: '#adbdff'
      }]);
    }

    get activeAccount() {
      return this.editState ? this.changeset : this.account.activeAccount;
    }

    get selectedTheme() {
      const toLower = s => s ? s.toLowerCase() : s;

      return this.colorThemes.find(t => {
        return toLower(t.primary) === toLower(this.activeAccount.primaryColor) && toLower(t.secondary) === toLower(this.activeAccount.accentColor);
      });
    }

    get primaryPreviewStyle() {
      return Ember.String.htmlSafe(`background-color: ${this.activeAccount.primaryColor || this.defaultPrimary};`);
    }

    get secondaryPreviewStyle() {
      return Ember.String.htmlSafe(`background-color: ${this.activeAccount.accentColor || this.defaultSecondary};`);
    }

    enterEditState() {
      this.changeset = new _emberChangeset.Changeset(this.account.activeAccount);
      this.editState = true;
    }

    setSelectedTheme(theme) {
      if (!this.editState || this.saveChanges.isRunning) {
        return;
      }

      this.activeAccount.set('primaryColor', theme.primary);
      this.activeAccount.set('accentColor', theme.secondary); // all themes currently have the primary color sidenav

      this.activeAccount.set('isSidenavWhite', false);
    }

    *saveChanges() {
      yield this.changeset.save();
      this.editState = false;
      this.account.installTheme();
    }

    discardChanges() {
      this.changeset.rollback();
      this.editState = false;
      this.account.installTheme();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "editState", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "enterEditState", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "enterEditState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelectedTheme", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedTheme"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype)), _class));
  _exports.default = BrandingColorsComponent;
});