define("coachlogix/pods/components/settings/subscription/subscriptions-dialog/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: Ember.inject.service(),
    intl: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    tagName: '',
    activeSubscription: Ember.computed.reads('account.activeAccount.subscription'),
    quantity: Ember.computed.reads('activeSubscription.quantity'),
    seatQuantity: Ember.computed.reads('activeSubscription.seatQuantity'),
    seatPrice: Ember.computed.reads('account.activeAccount.subscription.plan.seatPrice'),
    newQuantity: Ember.computed.reads('seatQuantity'),
    additionalSeats: Ember.computed.reads('newQuantity'),
    price: Ember.computed('additionalSeats', 'seatPrice', function () {
      return (this.get('additionalSeats') || 0) * this.get('seatPrice');
    }),
    totalPrice: Ember.computed('price', 'activeSubscription.plan.basePrice', function () {
      return this.get('price') + this.get('activeSubscription.plan.basePrice');
    }),
    isInvalid: Ember.computed('newQuantity', 'activeSubscription.seatQuantity', function () {
      return parseInt(this.get('newQuantity')) < 0 || parseInt(this.get('newQuantity')) === this.get('activeSubscription.seatQuantity');
    }),
    updateSubscriptions: (0, _emberConcurrency.task)(function* () {
      let quantity = 1;

      if (this.get('newQuantity')) {
        quantity = quantity + (parseInt(this.get('newQuantity')) || 0);
      }

      let activeSubscription = yield this.get('activeSubscription');
      activeSubscription.set('quantity', quantity);

      try {
        yield activeSubscription.save();
        yield this.get('account.activeAccount').reload();
        this.get('clToaster').success(this.get('intl').t('settings.subscription.seats-dialog.success-message'));
      } catch (e) {
        this.get('clToaster').error(e);
      }

      if (typeof this.onClose === 'function') {
        this.onClose();
      }
    })
  });

  _exports.default = _default;
});