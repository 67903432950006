define("coachlogix/models/document-content", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    body: (0, _model.attr)('string'),
    resource: (0, _model.belongsTo)('resource', {
      async: true,
      inverse: 'documentContent'
    })
  });

  _exports.default = _default;
});