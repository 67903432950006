define("coachlogix/services/places", ["exports", "@coachlogix/cl-ui/services/places"], function (_exports, _places) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _places.default;
    }
  });
});