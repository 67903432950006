define("coachlogix/pods/index/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.computed.reads('account.activeAccount'), _dec13 = Ember.computed.or('account.activeEmployee.tenant.ownerAccount.isAccountSidenavWhite', 'account.activeAccount.isAccountSidenavWhite'), _dec14 = Ember.computed('account.activeAccount.{isMember,ownerAccount.logo,logo,isUnclaimed}', 'account.activeEmployee.tenant.ownerAccount.logo'), _dec15 = Ember._action, _dec16 = Ember._action, (_class = (_temp = class IndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "domainMasking", _descriptor2, this);

      _initializerDefineProperty(this, "auth0", _descriptor3, this);

      _initializerDefineProperty(this, "ajax", _descriptor4, this);

      _initializerDefineProperty(this, "clToaster", _descriptor5, this);

      _initializerDefineProperty(this, "topbar", _descriptor6, this);

      _initializerDefineProperty(this, "notifications", _descriptor7, this);

      _initializerDefineProperty(this, "orders", _descriptor8, this);

      _initializerDefineProperty(this, "twilio", _descriptor9, this);

      _initializerDefineProperty(this, "intercom", _descriptor10, this);

      _initializerDefineProperty(this, "storage", _descriptor11, this);

      _initializerDefineProperty(this, "activeAccount", _descriptor12, this);

      _initializerDefineProperty(this, "isSidenavWhite", _descriptor13, this);

      _defineProperty(this, "queryParams", ['orderId', 'slug', 'profileId']);
    }

    get sideNavLogo() {
      if (this.account.activeEmployee.get('tenant.ownerAccount')) {
        return this.account.activeEmployee.get('tenant.ownerAccount.logo');
      }

      if ((this.account.activeAccount.isMember || this.account.activeAccount.isUnclaimed) && this.account.activeAccount.get('ownerAccount.logo')) {
        return this.account.activeAccount.get('ownerAccount.logo');
      }

      if (this.account.activeAccount.logo) {
        return this.account.activeAccount.logo;
      }

      return '/img/logo-white.svg';
    }

    *invalidateSession() {
      let currAccount = this.account.activeAccount;
      let logoutUrl;

      if (currAccount.logoutUrl) {
        logoutUrl = currAccount.logoutUrl;
      } else {
        if (window.location.origin) {
          logoutUrl = window.location.origin;
        } else {
          // Some browsers (mainly IE) does not have this property, so we need to build it manually...
          logoutUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
        }
      }

      try {
        yield this.ajax.post('/logout', {
          dataType: 'text'
        });
      } catch (e) {// ignore error
      }

      try {
        this.auth0.logout(logoutUrl);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    handleMainSectionScroll(ev) {
      let header = document.getElementById('main-top-bar');
      let maxTopBarTranslate = Math.min((220 - header.clientHeight) * -1, 0);
      let value = Math.max(-ev.srcElement.scrollTop * 0.6, -115, maxTopBarTranslate);
      this.set('bgTopStyle', Ember.String.htmlSafe(`transform: translateY(${value}px)`));
      this.set('topNavShadow', value === -115 || value === maxTopBarTranslate);
    }

    openIntercomWidget() {
      this.intercom.show();
    }

    *switchAccount(notification) {
      let tenant = yield notification.get('tenant');
      let employee = this.account.activeUser.employees.find(e => {
        return e.belongsTo('tenant').id() === tenant.id;
      });
      let accountId = employee ? employee.belongsTo('account').id() : null;

      if (accountId) {
        yield this.account.switchAccount.perform(accountId);
        this.router.transitionTo('notifications', this.notifications.switchTenant.token);
        this.notifications.set('switchTenant', null);
      } else {
        this.clToaster.error(this.intl.t('application.context-switcher.error'));
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "domainMasking", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "auth0", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "topbar", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "orders", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "twilio", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "intercom", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "activeAccount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isSidenavWhite", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sideNavLogo", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "sideNavLogo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "invalidateSession", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "invalidateSession"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMainSectionScroll", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "handleMainSectionScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openIntercomWidget", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "openIntercomWidget"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchAccount", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "switchAccount"), _class.prototype)), _class));
  _exports.default = IndexController;
});