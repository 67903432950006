define("coachlogix/pods/outside/sso-unauthorized/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Gs5+qdi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex layout-align-center-center\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex md-padding old-browser--message text-center\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"outside.auth.whoops\"],null]],[13],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"outside.auth.sso\"],null]],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/outside/sso-unauthorized/template.hbs"
    }
  });

  _exports.default = _default;
});