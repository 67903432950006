define("coachlogix/pods/components/v2/resources/resources-view/resources-grid/resource-card/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let ResourceCard = (_dec = (0, _component.tagName)(''), _dec(_class = class ResourceCard extends Ember.Component {}) || _class);
  var _default = ResourceCard;
  _exports.default = _default;
});