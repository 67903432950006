define("coachlogix/pods/settings/marketplace/checklist/stripe/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qRmfvQqs",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[10,\"div\"],[14,0,\"cl-h-full cl-p-3 cl-flex cl-flex-col cl-space-y-3 cl-justify-center cl-items-center\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[\"powered_by_stripe\"],[[\"class\"],[\"cl-h-4\"]]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-flex-col cl-space-y-0.5 cl-text-center\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-5\"],[12],[1,[30,[36,1],[\"settings.marketplace.stripe.title\"],null]],[13],[2,\"\\n\"],[6,[37,3],[[32,1,[\"account\",\"isStripeConnected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[8,\"cl-icon\",[[24,0,\"cl-mr-1\"]],[[\"@icon\",\"@success\",\"@size\"],[\"check\",true,18]],null],[1,[30,[36,1],[\"settings.marketplace.stripe.description-connected\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[1,[30,[36,1],[\"settings.marketplace.stripe.description-disconnected\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,1,[\"account\",\"isStripeConnected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"disconnectStripe\",\"isRunning\"]]]],[[\"@primary\",\"@onClick\"],[true,[30,[36,0],[[32,0,[\"disconnectStripe\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[\"settings.invoicing.stripe-connected.disconnect-button\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"connectStripe\",\"isRunning\"]]]],[[\"@primary\",\"@raised\",\"@onClick\"],[true,true,[30,[36,0],[[32,0,[\"connectStripe\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[\"settings.invoicing.stripe.connect-button\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"t\",\"svg-jar\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/settings/marketplace/checklist/stripe/template.hbs"
    }
  });

  _exports.default = _default;
});