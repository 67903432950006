define("coachlogix/abilities/note", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    account: Ember.inject.service(),
    isOwner: Ember.computed('model.user', 'account.activeUser', function () {
      return this.get('model.user.id') === this.get('account.activeUser.id');
    }),
    canShare: Ember.computed.reads('isOwner'),
    canDelete: Ember.computed.reads('isOwner'),
    canDownload: Ember.computed.reads('isOwner'),
    canView: Ember.computed('isOwner', 'model.sharedWith.@each.user', 'account.activeUser', function () {
      if (this.get('isOwner')) {
        return true;
      }

      let sharedWith = this.get('model.sharedWith') || [];
      return sharedWith.any(s => {
        return s.belongsTo('user').id() === this.get('account.activeUser.id');
      });
    })
  });

  _exports.default = _default;
});