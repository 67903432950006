define("coachlogix/utils/popup-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = popupWindow;

  function popupWindow(url, title, w, h) {
    let y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
    let x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
    return window.open(url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
  }
});