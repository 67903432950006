define("coachlogix/pods/dashboard/top10-themes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CBl+41vC",
    "block": "{\"symbols\":[\"c\",\"&attrs\"],\"statements\":[[8,\"cl-card\",[[24,0,\"cl-flex cl-flex-col cl-py-3 cl-px-3 cl-space-y-1\"],[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h4\"],[14,0,\"cl-title-4\"],[12],[1,[30,[36,2],[\"dashboard.widget.impact\"],null]],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"getData\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-p-4 cl-flex cl-items-center cl-justify-center\"],[12],[2,\"\\n      \"],[8,\"cl-progress-circular\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"competencies\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"cl-card\",[[24,0,\"cl-p-1 cl-flex cl-flex-col\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"cl-flex cl-items-end\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-text-sm cl-font-bold cl-mb-0.5\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"cl-body-2\"],[12],[1,[32,1,[\"percentage\"]]],[2,\"%\"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[8,\"cl-progress-linear\",[],[[\"@value\"],[[32,1,[\"percentage\"]]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"      \"],[8,\"cl-empty-state\",[],[[\"@icon\",\"@size\",\"@text\"],[\"list\",88,\"There are no used themes yet.\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/dashboard/top10-themes/template.hbs"
    }
  });

  _exports.default = _default;
});