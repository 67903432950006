define("coachlogix/pods/components/cl-chip-filters/place/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PL0KaYS8",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex cl-flex-col cl-space-y-2 cl-p-3\"],[17,1],[12],[2,\"\\n  \"],[8,\"cl-places-autocomplete\",[],[[\"@solid\",\"@placeholder\",\"@selected\",\"@onChange\"],[true,[30,[36,0],[\"v2.common.search-placeholder\"],null],[32,0,[\"value\"]],[32,0,[\"setLocation\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cl-chip-filters/place/template.hbs"
    }
  });

  _exports.default = _default;
});