define("coachlogix/pods/components/v2/summary-cards/client-contracts/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SummaryClientContract = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrencyDecorators.lastValue)('getContracts'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec(_class = (_class2 = (_temp = class SummaryClientContract extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "contracts", _descriptor3, this);
    }

    contractCreated(contract, usesTemplate) {
      this.set('addContract', false); // Edit contract dialog will fetch again after complete,
      // so no need to do it yet if it uses template.

      if (usesTemplate) {
        this.set('contractToEdit', contract);
      } else {
        this.getContracts.perform();
      }
    }

    onEditorClose(contractWasSaved) {
      this.set('contractToEdit', null);
      this.set('contractIdToEdit', null);

      if (contractWasSaved && typeof contractWasSaved === 'boolean') {
        // Avoid false positives because of the click event
        this.getContracts.perform();
      }
    }

    *getContracts() {
      let contracts = yield this.store.query('contract', {
        filter: {
          client_id: this.client.id
        },
        include: 'client,client.account,client.client-account,payment-schedule'
      });
      let contractToEdit = contracts.findBy('id', this.contractIdToEdit);

      if (contractToEdit) {
        this.set('contractToEdit', contractToEdit);
      }

      return contracts;
    }

    *send(contract) {
      try {
        contract.set('status', 'sent');
        yield contract.save();
        this.clToaster.success('Contract sent successfully.');
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *setStatus(contract, status) {
      try {
        contract.set('status', status);
        yield contract.save();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "contracts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "contractCreated", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "contractCreated"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onEditorClose", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "onEditorClose"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getContracts", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "getContracts"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "send", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "send"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setStatus", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "setStatus"), _class2.prototype)), _class2)) || _class);
  var _default = SummaryClientContract;
  _exports.default = _default;
});