define("coachlogix/pods/coaches/add-to-pool-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iuM2m79V",
    "block": "{\"symbols\":[\"pool\",\"@onClose\",\"&attrs\"],\"statements\":[[8,\"cl-selection-dialog\",[[24,0,\"cl-w-40\"],[17,3]],[[\"@bordered\",\"@search\",\"@searchField\",\"@title\",\"@selectLabel\",\"@onClose\",\"@multiple\",\"@required\",\"@options\",\"@onChange\"],[true,true,[30,[36,1],[\"name\"],null],[30,[36,0],[\"coaches.add-to-pool\"],null],[30,[36,0],[\"coaches.update-pool\"],null],[32,2],true,true,[30,[36,2],[[32,0,[\"loadCoachPools\"]]],null],[30,[36,2],[[32,0,[\"saveChanges\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-flex-col cl-w-full cl-space-y-1\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-flex cl-items-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"cl-flex-1\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cl-title-4\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,3],[[32,1,[\"description\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"p\"],[14,0,\"cl-whitespace-pre-wrap\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[32,1,[\"coachPoolCoaches\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"cl-title-7\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"coaches.members\"],[[\"value\"],[[32,1,[\"coachPoolCoaches\",\"length\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"perform\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/coaches/add-to-pool-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});