define("coachlogix/adapters/application", ["exports", "@ember-data/adapter/json-api", "ember-data-cascade-delete", "ember-ajax/mixins/ajax-support", "qs-stringify", "coachlogix/config/environment"], function (_exports, _jsonApi, _emberDataCascadeDelete, _ajaxSupport, _qsStringify, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-mixins
  const TYPE_MAP = ['employee-level', 'country', 'role', 'timezone', 'language', 'currency'];
  const DID_RELOAD = {};

  var _default = _jsonApi.default.extend(_ajaxSupport.default, _emberDataCascadeDelete.default, {
    account: Ember.inject.service(),
    host: Ember.computed('account.meRequest.api_url', function () {
      return this.account.meRequest?.api_url || _environment.default.api.host;
    }),
    namespace: _environment.default.api.namespace,
    coalesceFindRequests: true,

    isInvalid(status) {
      return status === 400;
    },

    shouldReloadAll(store, snapshotArray) {
      if (TYPE_MAP.includes(snapshotArray.type.modelName)) {
        let didReload = DID_RELOAD[snapshotArray.type.modelName];
        DID_RELOAD[snapshotArray.type.modelName] = true;
        return !didReload;
      }

      return this._super(...arguments);
    },

    shouldBackgroundReloadRecord(store, snapshot) {
      return !TYPE_MAP.includes(snapshot.modelName);
    },

    shouldBackgroundReloadAll(store, snapshotArray) {
      return !TYPE_MAP.includes(snapshotArray.type.modelName);
    },

    updateRecord(store, type, snapshot) {
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      let url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');

      if (snapshot.adapterOptions && snapshot.adapterOptions.include) {
        url += '?include=' + snapshot.adapterOptions.include;
      }

      return this.ajax(url, 'PATCH', {
        data: data
      });
    },

    urlForFindRecord(id, modelName, snapshot) {
      let url = this._super(...arguments);

      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.queryParams) {
        let queryParams = snapshot.adapterOptions.queryParams || {};
        let stringifiedQueryParams = (0, _qsStringify.default)(queryParams);
        return `${url}?${stringifiedQueryParams}`;
      } else {
        return url;
      }
    },

    urlForFindAll(modelName, snapshot) {
      let url = this._super(...arguments);

      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.queryParams) {
        let queryParams = snapshot.adapterOptions.queryParams || {};
        let stringifiedQueryParams = (0, _qsStringify.default)(queryParams);
        return `${url}?${stringifiedQueryParams}`;
      } else {
        return url;
      }
    }

  });

  _exports.default = _default;
});