define("coachlogix/pods/components/v2/invite-dialog/common/search-company/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',
    newCompanyName: Ember.computed.reads('companyName'),
    searchCompanies: (0, _emberConcurrency.task)(function* (searchTerm) {
      if (!this.isValidSearch) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(400);
      let accounts = yield this.get('store').query('account', {
        filter: {
          name: searchTerm
        }
      });

      if (accounts.get('length') === 0) {
        // auto-select the no companies found option
        this.set('company', null);
      }

      return accounts;
    }).restartable(),
    isValidSearch: Ember.computed.gte('companyName.length', 2),
    isValid: Ember.computed('company', 'newCompanyName', function () {
      return this.get('company') || this.get('company') === null && this.get('newCompanyName');
    })
  });

  _exports.default = _default;
});