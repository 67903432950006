define("coachlogix/pods/dashboard/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eCqPLvF2",
    "block": "{\"symbols\":[\"Widget\",\"&attrs\",\"@widget\"],\"statements\":[[6,[37,2],[[32,3,[\"component\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,3,[\"component\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[[17,2]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"let\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/dashboard/widget/template.hbs"
    }
  });

  _exports.default = _default;
});