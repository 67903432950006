define("coachlogix/pods/components/profile/general-info/specialties-edit-dialog/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dcp2ZUgR",
    "block": "{\"symbols\":[\"@options\",\"@message\"],\"statements\":[[8,\"cl-animated-if\",[[24,0,\"cl-flex-none\"]],[[\"@value\"],[[30,[36,0],[[32,2],[32,1,[\"length\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-px-3 cl-py-1 cl-bg-info cl-text-info-contrast cl-mb-1 cl-flex cl-items-center\"],[12],[2,\"\\n    \"],[8,\"cl-icon\",[[24,0,\"cl-mr-2\"]],[[\"@icon\"],[\"info\"]],null],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"and\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/profile/general-info/specialties-edit-dialog/info/template.hbs"
    }
  });

  _exports.default = _default;
});