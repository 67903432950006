define("coachlogix/pods/components/v2/engagement/settings/contracts-card/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EngagementSettingsContractsCardComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.or('engagement.client.isVendor', 'engagement.client.isClient'), _dec6 = Ember.computed.or('engagement.contractorClient.isVendor', 'engagement.contractorClient.isClient'), _dec(_class = (_class2 = (_temp = class EngagementSettingsContractsCardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "account", _descriptor3, this);

      _initializerDefineProperty(this, "needsClientContract", _descriptor4, this);

      _initializerDefineProperty(this, "needsContractorContract", _descriptor5, this);
    }

    *removeContract() {
      if (this.engagement.isActive) {
        this.set('showContractRemoveConfirmation', true);
      } else {
        yield this.engagement.updateProperty.perform('contract', null);
      }
    }

    *removeContractorContract() {
      if (this.engagement.isActive) {
        this.set('showContractorContractRemoveConfirmation', true);
      } else {
        yield this.engagement.updateProperty.perform('contractorContract', null);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "needsClientContract", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "needsContractorContract", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "removeContract", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "removeContract"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeContractorContract", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "removeContractorContract"), _class2.prototype)), _class2)) || _class);
  var _default = EngagementSettingsContractsCardComponent;
  _exports.default = _default;
});