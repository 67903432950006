define("coachlogix/models/account-table-customization", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    user: (0, _model.belongsTo)('user'),
    account: (0, _model.belongsTo)('account'),
    tableName: (0, _model.attr)('string'),
    sortField: (0, _model.attr)('number'),
    sortOrder: (0, _model.attr)('string'),
    visibleColumns: (0, _model.attr)('array'),
    version: (0, _model.attr)('number')
  });

  _exports.default = _default;
});