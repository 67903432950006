define("coachlogix/models/taskable", ["exports", "@ember-data/model", "ember-data-copyable", "ember-concurrency"], function (_exports, _model, _emberDataCopyable, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_emberDataCopyable.default, {
    accountService: Ember.inject.service('account'),
    clToaster: Ember.inject.service(),
    tasks: (0, _model.hasMany)('tasks', {
      async: true,
      inverse: null
    }),
    todos: Ember.computed.filterBy('tasks', 'taskType', 'todo'),
    createTask: (0, _emberConcurrency.task)(function* (attrs) {
      let task = this.store.createRecord('task', { ...attrs,
        taskable: this,
        user: this.get('accountService.activeUser')
      });

      try {
        const savedTask = yield task.save();
        this.get('tasks').pushObject(task);
        return savedTask;
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    hasTasks: Ember.computed('tasks', function () {
      return this.get('tasks').get('length') > 0;
    }),
    progress: Ember.computed('tasks.@each.progress', function () {
      let tasks = this.get('tasks') || [];
      let total = tasks.get('length');
      let sum = tasks.mapBy('progress').reduce((a, i) => a + i, 0);
      return total > 0 ? Math.round(sum / total) : 0;
    })
  });

  _exports.default = _default;
});