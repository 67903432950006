define("coachlogix/models/expense", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    amount: (0, _model.attr)('number') //invoiceable: belongsTo('base-model', { async: true, inverse: 'invoiceable' }),

  });

  _exports.default = _default;
});