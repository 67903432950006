define("coachlogix/pods/components/v2/engagement/engagement-task/detail-view/actions/edit-completed-date-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EditCompletedDateDialogComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec(_class = (_class2 = (_temp = class EditCompletedDateDialogComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "moment", _descriptor3, this);
    }

    init() {
      super.init(...arguments);
      let completedDate = Ember.get(this.task, 'completedDate');

      if (completedDate) {
        this.selectedDate = (0, _moment.default)(completedDate).toDate();
      } else {
        this.selectedDate = (0, _moment.default)().toDate();
      }
    }

    *completeTask() {
      let selectedDatetime = (0, _moment.default)(this.selectedDate);
      let dateObj = {
        year: selectedDatetime.year(),
        month: selectedDatetime.month(),
        day: selectedDatetime.date()
      };
      let timezone = this.moment.timeZone;

      if (timezone) {
        selectedDatetime = _moment.default.tz(dateObj, timezone);
      } else {
        selectedDatetime = (0, _moment.default)(dateObj);
      }

      try {
        yield Ember.get(this.task, 'completeTask').perform(selectedDatetime);
        this.clToaster.success(this.intl.t('engagement-task.updated-task'));
        this.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    momentTz(m) {
      let timezone = this.moment.timeZone;

      if (timezone) {
        return m.tz(timezone);
      } else {
        return m;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "moment", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "completeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "completeTask"), _class2.prototype)), _class2)) || _class);
  var _default = EditCompletedDateDialogComponent;
  _exports.default = _default;
});