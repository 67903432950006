define("coachlogix/pods/components/v2/engagement-template/engagement-session/master-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mHvdqUym",
    "block": "{\"symbols\":[\"@href\",\"@isActive\",\"@onClick\",\"&attrs\",\"@item\"],\"statements\":[[8,\"cl-list/item\",[[17,4]],[[\"@dense\",\"@href\",\"@isActive\",\"@onClick\"],[true,[32,1],[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"cl-icon\",[[24,0,\"cl-icon-bordered--session\"]],[[\"@icon\",\"@bordered\",\"@size\"],[\"people\",true,22]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"cl-title-6 cl-truncate\"],[12],[1,[32,5,[\"itemable\",\"displayTitle\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement-template/engagement-session/master-item/template.hbs"
    }
  });

  _exports.default = _default;
});