define("coachlogix/pods/components/v2/avatar-for/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bOTqU3iK",
    "block": "{\"symbols\":[\"AvatarComponent\",\"@model\",\"&attrs\"],\"statements\":[[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,0],[\"v2/avatar-for/\",[32,2,[\"modelName\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[[17,3]],[[\"@model\",\"@size\"],[[32,2],[32,0,[\"size\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"component\",\"let\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/avatar-for/template.hbs"
    }
  });

  _exports.default = _default;
});