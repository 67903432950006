define("coachlogix/pods/form/form-editor/form-questions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zRsnU8qW",
    "block": "{\"symbols\":[\"group\",\"question\",\"isActive\",\"item\",\"@disabled\",\"@active\",\"@updateActive\",\"@maybeDeleteQuestion\",\"@duplicateQuestion\",\"@reorderItems\",\"&attrs\"],\"statements\":[[8,\"sortable-group\",[[24,0,\"cl-list-none cl-p-0 cl-m-0\"],[17,11]],[[\"@model\",\"@onChange\"],[[32,0,[\"sortedQuestions\"]],[32,10]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,\"cl-animated-each\",[[24,0,\"cl-overflow-visible\"]],[[\"@value\"],[[32,1,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,6],[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"item\"]],[[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-flex-1\"]]]],[[\"@model\",\"@isDraggingDisabled\"],[[32,2],[30,[36,1],[[30,[36,0],[[32,3]],null],[32,5]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"form/form-editor/form-element\",[[24,0,\"card\"]],[[\"@question\",\"@activeQuestion\",\"@onClick\",\"@deleteQuestion\",\"@duplicateQuestion\",\"@disabled\"],[[32,2],[32,6],[32,7],[32,8],[32,9],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,4,[\"handle\"]],[[24,0,\"cl-flex cl-justify-center cl-absolute cl-top-0 cl-right-0 cl-left-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[8,\"cl-icon\",[[24,0,\"cl-text-cloudy-blue\"]],[[\"@icon\"],[\"drag-handle\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[4]}]]],[2,\"\\n\\n\"]],\"parameters\":[3]}]]],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"or\",\"if\",\"eq\",\"let\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/form/form-editor/form-questions/template.hbs"
    }
  });

  _exports.default = _default;
});