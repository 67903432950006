define("coachlogix/models/form", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CHECKBOX_TYPE = _exports.DROPDOWN_TYPE = _exports.RADIO_TYPE = _exports.NUMBER_TYPE = _exports.TEXT_TYPE = void 0;
  const TEXT_TYPE = 'text';
  _exports.TEXT_TYPE = TEXT_TYPE;
  const NUMBER_TYPE = 'number';
  _exports.NUMBER_TYPE = NUMBER_TYPE;
  const RADIO_TYPE = 'radio';
  _exports.RADIO_TYPE = RADIO_TYPE;
  const DROPDOWN_TYPE = 'dropdown';
  _exports.DROPDOWN_TYPE = DROPDOWN_TYPE;
  const CHECKBOX_TYPE = 'checkbox';
  _exports.CHECKBOX_TYPE = CHECKBOX_TYPE;

  var _default = Ember.Mixin.create({
    isConfidential: (0, _model.attr)('boolean', {
      allowNull: true
    }),
    blockGlobalResponses: (0, _model.attr)('boolean'),
    formLink: (0, _model.attr)('string'),
    formQuestions: (0, _model.hasMany)('form-question', {
      inverse: 'resource',
      cascadeDelete: true
    }),
    formSections: (0, _model.hasMany)('form-section', {
      inverse: 'resource',
      cascadeDelete: true
    }),
    formSubmissions: (0, _model.hasMany)('form-submission', {
      inverse: 'resource'
    }),
    // Computed properties
    isForm: Ember.computed.equal('resourceType', 'form'),

    addQuestion(props) {
      let store = Ember.getOwner(this).lookup('service:store');
      let questions = this.get('formQuestions'); // order is length or the specified order

      let sortOrder = questions.get('length');

      if (props && props.sortOrder !== undefined) {
        sortOrder = props.sortOrder;
      }

      let question = store.createRecord('form-question', { ...props,
        sortOrder,
        resource: this.get('resource')
      }); // for each question with a higher order, shift it by one
      // to make room for the new question

      questions.forEach(q => {
        if (q.get('sortOrder') >= sortOrder) {
          q.set('sortOrder', q.get('sortOrder') + 1);
        }
      });
      questions.addObject(question);
      this.normalizeQuestionsSortOrder();
      return question;
    },

    removeQuestion(question) {
      this.get('formQuestions').removeObject(question);
      this.normalizeQuestionsSortOrder();
    },

    // used for the undo functionality of the form editor
    undeleteQuestion(question) {
      this.get('formQuestions').addObject(question);
    },

    addSection(props) {
      let store = Ember.getOwner(this).lookup('service:store'); // order is 0 or the specified order

      let sortOrder = 0;

      if (props && props.sortOrder !== undefined) {
        sortOrder = props.sortOrder;
      }

      let section = store.createRecord('form-section', { ...props,
        sortOrder
      }); // for each section with a higher order, shift it by one
      // to make room for the new section

      this.get('formSections').forEach(s => {
        if (s.get('sortOrder') >= sortOrder) {
          Ember.set(s, 'sortOrder', s.get('sortOrder') + 1);
        }
      });
      this.get('formSections').addObject(section);
      this.normalizeSectionsSortOrder();
      return section;
    },

    removeSection(section) {
      this.get('formSections').removeObject(section);
      this.normalizeSectionsSortOrder();
    },

    deepRollback: (0, _emberConcurrency.task)(function* () {
      if (!this.get('isNew')) {
        yield this.reload();
      }

      this.rollbackAttributes();
      this.hasMany('formQuestions').reload();
      this.get('formQuestions').invoke('deepRollback');
      this.hasMany('formSections').reload();
      this.get('formSections').invoke('rollbackAttributes');
      return this;
    }),
    updateConfidential: (0, _emberConcurrency.task)(function* (value) {
      let previousConfidential = this.get('isConfidential');

      try {
        this.set('isConfidential', value);
        yield this.save();

        if (value) {
          this.clToaster.success('Form is confidential');
        } else {
          this.clToaster.success('Form is not confidential');
        }
      } catch (e) {
        this.set('isConfidential', previousConfidential);
        this.clToaster.error(e);
      }
    }),

    normalizeQuestionsSortOrder() {
      this.get('formQuestions').sortBy('sortOrder').forEach((q, i) => {
        q.set('sortOrder', i + 1);
      });
    },

    async persistChangedQuestions() {
      let changedQuestions = this.get('formQuestions').filter(q => q.get('hasDirtyAttributes'));
      await Ember.RSVP.all(changedQuestions.map(q => q.save()));
      return changedQuestions;
    },

    normalizeSectionsSortOrder() {
      this.get('formSections').sortBy('sortOrder').forEach((s, i) => {
        s.set('sortOrder', i + 1);
      });
    },

    async persistChangedSections() {
      let changedSections = this.get('formSections').filter(s => s.get('hasDirtyAttributes'));
      await Ember.RSVP.all(changedSections.map(s => s.save()));
      return changedSections;
    }

  });

  _exports.default = _default;
});