define("coachlogix/models/custom-notification", ["exports", "@ember-data/model", "coachlogix/models/base-model", "coachlogix/models/resourceable"], function (_exports, _model, _baseModel, _resourceable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_resourceable.default, {
    typeKey: (0, _model.attr)('string'),
    customNotifiable: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      inverse: null
    }),
    recipients: (0, _model.attr)('json'),
    // coach || coachee || all others
    via: (0, _model.attr)('string', {
      defaultValue: 'all'
    }),
    // all || email || sms
    message: (0, _model.attr)('string'),
    subject: (0, _model.attr)('string'),
    attachment: (0, _model.attr)('string'),
    timeUnit: (0, _model.attr)('string'),
    timeValue: (0, _model.attr)('string'),
    sendAutomatically: (0, _model.attr)('boolean'),
    scheduledRelation: (0, _model.attr)('string'),
    user: (0, _model.belongsTo)('user'),
    notificationReminders: (0, _model.hasMany)('notification-reminder', {
      inverse: 'customNotification',
      cascadeDelete: true
    })
  });

  _exports.default = _default;
});