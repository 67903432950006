define("coachlogix/pods/components/v2/notifications-dropdown/notification/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ICONS_MAP = {
    //sub categories
    forms: 'assignment-turned-in',
    tasks: 'offline_pin',
    files: 'insert_drive_file',
    resources: 'insert_drive_file',
    links: 'link',
    notes: 'subject',
    // categories
    engagements: 'flag',
    goals: 'gps_fixed',
    accounts: 'account_circle',
    sessions: 'today',
    invoices: 'attach_money',
    payments: 'credit_card',
    network: 'share',
    users: 'person',
    messaging: 'forum',
    coach_matching: 'people',
    announcements: 'announcement',
    default: 'settings'
  };
  let NotificationComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed('activity.notification.messageType.{category,subCategory}'), _dec(_class = (_class2 = (_temp = class NotificationComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    get icon() {
      let key = this.activity.notification.get('messageType.subCategory') || this.activity.notification.get('messageType.category') || 'default';
      return ICONS_MAP[key];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "icon", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "icon"), _class2.prototype)), _class2)) || _class);
  var _default = NotificationComponent;
  _exports.default = _default;
});