define("coachlogix/pods/components/v2/messaging/channel-pane/group-avatar/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelPaneGroupAvatar = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrencyDecorators.lastValue)('getUser'), _dec4 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec(_class = (_class2 = (_temp = class ChannelPaneGroupAvatar extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "twilio", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);
    }

    *getUser() {
      if (!this.identity) {
        return null;
      } else if (this.users[this.identity]) {
        return this.users[this.identity];
      } else {
        // user might not existing in the channel pane users hash.
        // this can happen if we have messages from removed users.
        // so we need to explicitly find this user using the twilio client.
        return yield this.twilio.client.getUserDescriptor(this.identity);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "twilio", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "user", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "getUser", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "getUser"), _class2.prototype)), _class2)) || _class);
  var _default = ChannelPaneGroupAvatar;
  _exports.default = _default;
});