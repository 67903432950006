define("coachlogix/models/coaching-request-message", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    coachingRequest: (0, _model.belongsTo)('coaching-request', {
      inverse: 'coachingRequestMessages'
    }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    status: (0, _model.attr)('string'),
    content: (0, _model.attr)('string')
  });

  _exports.default = _default;
});