define("coachlogix/models/coaching-request", ["exports", "@ember-data/model", "ember-ajax/errors", "ember-concurrency", "coachlogix/models/base-model"], function (_exports, _model, _errors, _emberConcurrency, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    status: (0, _model.attr)('string'),
    // sent, interview_approved, interview_declined, interview_scheduled, interview_completed, coach_selected, coach_approved, coach_declined
    coach: (0, _model.belongsTo)('employee', {
      inverse: 'coachingRequests'
    }),
    coachee: (0, _model.belongsTo)('employee', {
      async: true,
      inverse: null
    }),
    coachUser: (0, _model.belongsTo)('user', {
      async: true,
      inverse: null
    }),
    coacheeUser: (0, _model.belongsTo)('user', {
      async: true,
      inverse: null
    }),
    engagement: (0, _model.belongsTo)('engagement', {
      inverse: null
    }),
    coachingRequestMessages: (0, _model.hasMany)('coaching-request-message', {
      inverse: 'coachingRequest'
    }),
    interviewTitle: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    description: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    duration: (0, _model.attr)('number', {
      defaultValue: 60
      /* minutes */

    }),
    actualDuration: (0, _model.attr)('number'),
    defaultTimeUnit: (0, _model.attr)('string', {
      defaultValue: 'minutes'
    }),
    // minutes, hours
    meetingInstructions: (0, _model.attr)('string'),
    scheduledDate: (0, _model.attr)('datetime'),
    completedDate: (0, _model.attr)('datetime'),
    cancelReason: (0, _model.attr)('string'),
    channelId: (0, _model.attr)('string'),
    programItem: (0, _model.belongsTo)('program-item', {
      async: true,
      inverse: null
    }),
    meetingLocation: (0, _model.belongsTo)('meeting-location', {
      async: true,
      inverse: null
    }),
    declinedBy: (0, _model.belongsTo)('employee', {
      async: true,
      inverse: null
    }),
    timeSuggestions: (0, _model.hasMany)('time-suggestion', {
      inverse: 'coachingRequest',
      async: false
    }),
    timeSuggestionsComment: (0, _model.attr)('string'),
    isNotRequested: Ember.computed.equal('status', 'no_request'),
    isInterviewTimesRequested: Ember.computed.equal('status', 'interview_times_requested'),
    isInterviewTimesSent: Ember.computed.equal('status', 'interview_times_sent'),
    isInterviewApproved: Ember.computed.equal('status', 'interview_approved'),
    isInterviewDeclined: Ember.computed.equal('status', 'interview_declined'),
    isInterviewScheduled: Ember.computed.equal('status', 'interview_scheduled'),
    isScheduled: Ember.computed.equal('status', 'interview_scheduled'),
    isInterviewCanceled: Ember.computed.equal('status', 'interview_canceled'),
    isInterviewCompleted: Ember.computed.equal('status', 'interview_completed'),
    isSelected: Ember.computed.equal('status', 'coach_selected'),
    isCoachApproved: Ember.computed.equal('status', 'coach_approved'),
    isCoachDeclined: Ember.computed.equal('status', 'coach_declined'),
    isCoachPending: Ember.computed.equal('status', 'coach_pending'),
    isMarketplace: (0, _model.attr)('boolean'),
    canBeMovedOnFrom: Ember.computed.or('isInterviewCompleted', 'isSelected', 'isCoachApproved'),
    isCompleted: Ember.computed.reads('canBeMovedOnFrom'),
    intl: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    participants: Ember.computed('coach', 'coachee', function () {
      return [this.coach, this.coachee];
    }),
    displayTitle: Ember.computed('interviewTitle', function () {
      if (this.interviewTitle) {
        return this.interviewTitle;
      }

      return this.intl.t('engagement-coaching-request.default-title');
    }),

    /* API */
    updateTitle: (0, _emberConcurrency.task)(function* (title) {
      this.set('interviewTitle', title);
      yield this.get('saveTask').perform();
    }),
    updateMeetingLocation: (0, _emberConcurrency.task)(function* (location) {
      if (this.get('location') === location) {
        return;
      }

      try {
        this.set('meetingLocation', location);
        yield this.save();
        this.clToaster.success(this.intl.t('engagement-session.meeting-location-updated'));
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }),
    updateDuration: (0, _emberConcurrency.task)(function* (proposedDuration) {
      proposedDuration = parseInt(proposedDuration, 10);
      let currentDuration = parseInt(this.get('duration'), 10);
      const durationKey = (this.get('isInterviewCompleted') || this.get('isCoachApproved')) && this.get('actualDuration') ? 'actualDuration' : 'duration';

      if (proposedDuration === currentDuration) {
        return;
      }

      if (proposedDuration < 1) {
        this.set(durationKey, currentDuration);
        return this.clToaster.warn(this.intl.t('engagement-session.duration-required'));
      } else {
        this.set(durationKey, proposedDuration);

        if (durationKey === 'duration') {
          this.set('actualDuration', proposedDuration);
        }
      }

      try {
        yield this.save();
        this.clToaster.success(this.intl.t('engagement-session.duration-updated'));
      } catch (e) {
        this.set(durationKey, currentDuration);

        if (durationKey === 'duration') {
          this.set('actualDuration', currentDuration);
        }

        this.clToaster.error(e);
      }
    }),
    updateTimeUnit: (0, _emberConcurrency.task)(function* (newTimeUnit) {
      let defaultTimeUnit = this.get('defaultTimeUnit');

      if (newTimeUnit === defaultTimeUnit) {
        return;
      }

      try {
        this.set('defaultTimeUnit', newTimeUnit);
        yield this.save();
      } catch (e) {
        this.set('defaultTimeUnit', defaultTimeUnit);
        this.clToaster.error(this.intl.t('engagement-session.time-unit-error'));
      }
    }),
    lateCancel: (0, _emberConcurrency.task)(function* () {
      this.set('status', 'interview_canceled');
      yield this.get('saveTask').perform();
    }),
    complete: (0, _emberConcurrency.task)(function* (completedDate, actualDuration, defaultTimeUnit) {
      try {
        this.setProperties({
          actualDuration,
          completedDate,
          defaultTimeUnit,
          status: 'interview_completed'
        });
        yield this.save();
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }),
    schedule: (0, _emberConcurrency.task)(function* (scheduledDate) {
      let currentScheduledDate = this.get('scheduledDate');
      let currentStatus = this.get('status');
      let intl = this.get('intl');

      try {
        this.set('scheduledDate', scheduledDate);
        this.set('status', 'interview_scheduled');
        yield this.save();
        let toastMessage = currentScheduledDate ? intl.t('engagement-session.session-rescheduled') : intl.t('engagement-session.session-scheduled');
        this.clToaster.success(toastMessage);
      } catch (e) {
        this.setProperties({
          scheduledDate: currentScheduledDate,
          status: currentStatus
        });

        if ((0, _errors.isBadRequestError)(e) || (0, _errors.isConflictError)(e)) {
          let errors = e.payload.errors || [];

          if (errors.length > 0) {
            let error = errors[0];
            this.clToaster.error(this.intl.t(`engagement-coaching-request.errors.${error.code}`));
          }
        } else {
          this.clToaster.error(e);
        }
      }
    })
  });

  _exports.default = _default;
});