define("coachlogix/pods/form/form-answers/answer/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FormAnswersAnswerComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('answers.answerJson'), _dec(_class = (_class2 = (_temp = class FormAnswersAnswerComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "colors", ['#0081ff', '#02dc94', '#ff1050', '#ffe050', '#994ff7']);
    }

    get groupedAnswers() {
      let groups = [];

      for (let a of this.answers) {
        let answers = a.answerJson || [];

        for (let value of answers) {
          let g = groups.find(g => g.value === value);

          if (g) {
            g.count++;
          } else {
            groups.push({
              value,
              count: 1
            });
          }
        }
      }

      return groups;
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "groupedAnswers", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "groupedAnswers"), _class2.prototype)), _class2)) || _class);
  var _default = FormAnswersAnswerComponent;
  _exports.default = _default;
});