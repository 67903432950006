define("coachlogix/services/ajax", ["exports", "ember-ajax/services/ajax", "coachlogix/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    account: Ember.inject.service(),
    contentType: 'application/vnd.api+json',
    trustedHosts: Object.freeze(['localhost', 'http://localhost', 'http://locahost:4200', 'https://webdev.coachlogix.com']),
    host: Ember.computed('account.meRequest.api_url', function () {
      return this.account.meRequest?.api_url || _environment.default.api.host;
    }),
    namespace: _environment.default.api.namespace,
    headers: Ember.computed('session.{isAuthenticated,session.authenticated.idToken}', 'tempToken', function () {
      let headers = {};

      if (this.get('session.isAuthenticated')) {
        headers['Authorization'] = `Bearer ${this.get('session.session.authenticated.idToken')}`;
      } else if (this.get('tempToken')) {
        headers['Authorization'] = `Bearer ${this.get('tempToken')}`;
      }

      return headers;
    }),

    isUnauthorizedError(status) {
      if (status === 401) {// this.get('session').invalidate();
      }
    }

  });

  _exports.default = _default;
});