define("coachlogix/models/activity", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    activitySubject: (0, _model.attr)('string'),
    activityBody: (0, _model.attr)('string'),
    actor: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    // object: belongsTo('base-model', { polymorphic: true, inverse: null }),
    notification: (0, _model.belongsTo)('notification', {
      inverse: null
    }),
    read: Ember.computed.reads('notification.read'),
    isInvitation: Ember.computed.reads('notification.isInvitation')
  });

  _exports.default = _default;
});