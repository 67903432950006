define("coachlogix/pods/marketplace/profile/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProfileRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, (_class = (_temp = class ProfileRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "account", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);
    }

    async beforeModel() {
      if (this.session.isAuthenticated) {
        await this.account.loadInitialData.perform();
      }
    }

    async setupController(controller, model) {
      super.setupController(...arguments);
      let {
        initInterview
      } = this.paramsFor('marketplace.profile');

      if (initInterview) {
        let coach = await model.owner.get('employee');

        if (!this.account.activeAccount.isMember) {
          controller.displayUnableTo = true;
          return;
        }

        let interviews = await this.store.query('coaching-request', {
          filter: {
            marketplace: 1,
            participants: 'mine',
            status: 'interview_scheduled',
            employee_id: model.owner.employee.get('id')
          }
        });

        if (interviews.toArray()[0]) {
          this.clToaster.error(this.intl.t('marketplace.interview-already-scheduled'));
          return;
        }

        let meetingLocations = await this.store.query('meeting-location', {
          filter: {
            'meeting-type': 'in_app_video'
          }
        });
        let meetingLocation = meetingLocations.toArray()[0];
        let coachingRequest = this.store.createRecord('coaching-request', {
          status: 'interview_scheduled',
          coach,
          coachee: this.account.activeEmployee,
          interviewTitle: this.intl.t('settings.marketplace.interview.interview-name'),
          description: this.intl.t('settings.marketplace.interview.desc-val'),
          duration: 15,
          meetingLocation
        });
        controller.openInterviewScheduler = true;
        controller.coachingRequest = coachingRequest;
      }
    }

    async model({
      path
    }) {
      let include = ['owner.packages', 'owner.employee', 'coaching-categorizations', 'languages.language', 'languages.fluency', 'credentials.issuing-org', 'assessments.certified-assessment', 'education-degrees'].join(',');
      let profiles;

      try {
        profiles = await this.store.query('profile', {
          filter: {
            url: decodeURI(path)
          },
          include
        });
        profiles = profiles.toArray();
      } catch (e) {
        console.log(e);
      }

      if (profiles && profiles[0]) {
        return profiles[0];
      }

      this.router.replaceWith('not-found');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProfileRoute;
});