define("coachlogix/pods/employee/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c0Gt7fCZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-flex cl-space-y-4 sm:cl-space-y-0 sm:cl-space-x-4 cl-flex-col sm:cl-flex-row\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-flex cl-flex-col cl-space-y-4 sm:cl-w-1/2\"],[12],[2,\"\\n    \"],[8,\"v2/summary-cards/contact\",[],[[\"@user\"],[[32,0,[\"employee\",\"user\"]]]],null],[2,\"\\n\\n    \"],[8,\"v2/summary-cards/hours-info\",[],[[\"@hoursScheduled\",\"@hoursUnscheduled\",\"@hoursCompleted\"],[[32,0,[\"employee\",\"meta\",\"hours\",\"scheduled\"]],[32,0,[\"employee\",\"meta\",\"hours\",\"unscheduled\"]],[32,0,[\"employee\",\"meta\",\"hours\",\"completed\"]]]],null],[2,\"\\n\\n    \"],[8,\"v2/summary-cards/sessions-info\",[],[[\"@sessionsScheduled\",\"@sessionsUnscheduled\",\"@sessionsCompleted\"],[[32,0,[\"employee\",\"meta\",\"sessions\",\"scheduled\"]],[32,0,[\"employee\",\"meta\",\"sessions\",\"unscheduled\"]],[32,0,[\"employee\",\"meta\",\"sessions\",\"completed\"]]]],null],[2,\"\\n\\n    \"],[8,\"v2/summary-cards/sessions\",[],[[\"@client\",\"@employee\"],[[32,0,[\"client\"]],[32,0,[\"employee\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-flex cl-flex-col cl-space-y-4 sm:cl-w-1/2\"],[12],[2,\"\\n    \"],[8,\"v2/summary-cards/employee-info\",[],[[\"@employee\"],[[32,0,[\"employee\"]]]],null],[2,\"\\n\\n    \"],[8,\"v2/summary-cards/themes\",[],[[\"@client\",\"@employee\"],[[32,0,[\"client\"]],[32,0,[\"employee\"]]]],null],[2,\"\\n\\n    \"],[8,\"v2/summary-cards/interviews\",[],[[\"@client\",\"@employee\"],[[32,0,[\"client\"]],[32,0,[\"employee\"]]]],null],[2,\"\\n\\n    \"],[8,\"v2/summary-cards/stakeholder-feedback\",[],[[\"@client\",\"@employee\"],[[32,0,[\"client\"]],[32,0,[\"employee\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/employee/index/template.hbs"
    }
  });

  _exports.default = _default;
});