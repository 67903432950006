define("coachlogix/models/payment-schedule", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.percentageProperties = _exports.SCHEDULE_TYPES = void 0;
  const SCHEDULE_TYPES = ['duration', 'fixed_contract_amount_percentages'];
  _exports.SCHEDULE_TYPES = SCHEDULE_TYPES;
  const percentageProperties = ['upfront', 'firstQuarter', 'firstThird', 'middle', 'secondThird', 'thirdQuarter', 'end'];
  _exports.percentageProperties = percentageProperties;

  var _default = _baseModel.default.extend({
    intl: Ember.inject.service(),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    scheduleType: (0, _model.attr)('string'),
    upfront: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    firstQuarter: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    firstThird: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    middle: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    secondThird: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    thirdQuarter: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    end: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    upfrontPercentage: Ember.computed('upfront', function () {
      return Math.round(this.get('upfront') * 100 * 100) / 100;
    }),
    firstQuarterPercentage: Ember.computed('firstQuarter', function () {
      return Math.round(this.get('firstQuarter') * 100 * 100) / 100;
    }),
    firstThirdPercentage: Ember.computed('firstThird', function () {
      return Math.round(this.get('firstThird') * 100 * 100) / 100;
    }),
    middlePercentage: Ember.computed('middle', function () {
      return Math.round(this.get('middle') * 100 * 100) / 100;
    }),
    secondThirdPercentage: Ember.computed('secondThird', function () {
      return Math.round(this.get('secondThird') * 100 * 100) / 100;
    }),
    thirdQuarterPercentage: Ember.computed('thirdQuarter', function () {
      return Math.round(this.get('thirdQuarter') * 100 * 100) / 100;
    }),
    endPercentage: Ember.computed('end', function () {
      return Math.round(this.get('end') * 100 * 100) / 100;
    }),
    total: Ember.computed('upfront', 'firstQuarter', 'firstThird', 'middle', 'secondThird', 'thirdQuarter', 'end', function () {
      let values = this.getProperties('upfront', 'firstQuarter', 'firstThird', 'middle', 'secondThird', 'thirdQuarter', 'end');
      return Object.keys(values).reduce((prev, key) => {
        return prev + parseFloat(values[key] || 0);
      }, 0);
    }),
    totalPercentage: Ember.computed('total', function () {
      return Math.round(this.get('total') * 100 * 100) / 100;
    }),
    percentagesDescription: Ember.computed(...percentageProperties, function () {
      let percentages = this.getProperties(percentageProperties);
      return Object.keys(percentages).map(k => {
        return {
          key: k,
          value: percentages[k]
        };
      }).filter(({
        value
      }) => value > 0).map(({
        key,
        value
      }) => {
        value = Math.round(value * 100 * 100) / 100;
        let text = this.get('intl').t(`settings.billing.periods.${key}`);
        return `${value}% ${text}`;
      }).join(', ');
    })
  });

  _exports.default = _default;
});