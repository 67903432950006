define("coachlogix/models/custom-notifiable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    customNotifications: (0, _model.hasMany)('custom-notification', {
      async: true
    }),
    reminderNotification: Ember.computed('customNotifications.@each.typeKey', function () {
      if (this.get('customNotifications')) {
        return this.get('customNotifications').findBy('typeKey', 'reminder_session');
      }
    }),
    scheduledNotification: Ember.computed('customNotifications.@each.typeKey', function () {
      if (this.get('customNotifications')) {
        return this.get('customNotifications').findBy('typeKey', 'schedule_session');
      }
    }),
    allNotifications: Ember.computed('scheduledNotification.enabled', 'schedulingRequestNotification.enabled', 'reminderNotification.enabled', function () {
      let notifications = [];

      if (this.get('scheduledNotification')) {
        notifications.pushObject(this.get('scheduledNotification'));
      }

      if (this.get('schedulingRequestNotification')) {
        notifications.pushObject(this.get('schedulingRequestNotification'));
      }

      if (this.get('reminderNotification')) {
        notifications.pushObject(this.get('reminderNotification'));
      }

      return notifications;
    }),
    notificationsCount: Ember.computed('customNotifications.[]', function () {
      return this.get('customNotifications').get('length');
    }),
    hasNotifications: Ember.computed.bool('notificationsCount')
  });

  _exports.default = _default;
});