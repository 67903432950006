define("coachlogix/pods/outside/email-verification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+PTLnhrR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-flex cl-relative cl-justify-center cl-items-center cl-bg-overlay cl-h-full cl-w-full cl-opacity-100 cl-bg-cover\"],[12],[2,\"\\n  \"],[8,\"cl-card\",[[24,0,\"cl-text-center cl-py-4 cl-px-3\"]],[[\"@raised\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h1\"],[14,0,\"cl-title-1\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"outside.auth.verify-email.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"cl-my-3 cl-x-0\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"outside.auth.verify-email.message\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-flex cl-justify-center\"],[12],[2,\"\\n      \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"resendEmail\",\"isRunning\"]]]],[[\"@onClick\",\"@primary\",\"@raised\"],[[30,[36,1],[[32,0,[\"resendEmail\"]]],null],true,true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"outside.auth.verify-email.resend\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-mt-2\"],[12],[2,\"\\n      \"],[10,\"a\"],[14,0,\"cl-no-underline cl-color-inherit cl-title-6\"],[15,6,[31,[[30,[36,2],[\"logout\"],null]]]],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"outside.auth.verify-email.return-login\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"perform\",\"href-to\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/outside/email-verification/template.hbs"
    }
  });

  _exports.default = _default;
});