define("coachlogix/pods/components/v2/engagement/dashboard/program-card/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProgramCardComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed.reads('engagement.programItems'), _dec3 = Ember.computed.filterBy('items', 'isPublishedOrScheduled'), _dec4 = Ember.computed.sort('sentOrScheduledItems', 'sentSort'), _dec5 = Ember.computed.filterBy('items', 'canBeMovedOnFrom', true), _dec6 = Ember.computed.sort('completedItems', 'completedSort'), _dec7 = Ember.computed('items.@each.{canBeMovedOnFrom,isPublished,isScheduled}'), _dec8 = Ember.computed.sort('pendingItems', 'pendingItemsSort'), _dec9 = Ember.computed.or('pendingItemsSorted.length', 'completedItemsSorted.length', 'sentItemsSorted.length'), _dec10 = Ember.computed.not('isNotEmpty'), _dec(_class = (_class2 = (_temp = class ProgramCardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "displayCompleted", true);

      _initializerDefineProperty(this, "items", _descriptor, this);

      _initializerDefineProperty(this, "sentOrScheduledItems", _descriptor2, this);

      _defineProperty(this, "sentSort", ['publishedOrScheduledDate:asc']);

      _initializerDefineProperty(this, "sentItemsSorted", _descriptor3, this);

      _initializerDefineProperty(this, "completedItems", _descriptor4, this);

      _defineProperty(this, "completedSort", ['completedDate:asc']);

      _initializerDefineProperty(this, "completedItemsSorted", _descriptor5, this);

      _defineProperty(this, "pendingItemsSort", ['position:asc']);

      _initializerDefineProperty(this, "pendingItemsSorted", _descriptor6, this);

      _initializerDefineProperty(this, "isNotEmpty", _descriptor7, this);

      _initializerDefineProperty(this, "isEmpty", _descriptor8, this);
    }

    // Pending items. These are items that aren't completed, published or scheduled. They appear last
    // in the list and are sorted by position
    get pendingItems() {
      return this.items.filter(p => {
        return !p.get('item.canBeMovedOnFrom') && !p.get('item.isPublished') && !p.get('item.isScheduled');
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "items", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "sentOrScheduledItems", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "sentItemsSorted", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "completedItems", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "completedItemsSorted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "pendingItems", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "pendingItems"), _class2.prototype), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "pendingItemsSorted", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "isNotEmpty", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "isEmpty", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  var _default = ProgramCardComponent;
  _exports.default = _default;
});