define("coachlogix/pods/components/v2/tasks-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dUEsbdSE",
    "block": "{\"symbols\":[\"task\",\"@placeholder\",\"@canEdit\",\"&attrs\"],\"statements\":[[11,\"ul\"],[24,0,\"cl-flex cl-flex-col cl-p-0 cl-m-0 cl-list cl-list-none\"],[17,4],[12],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,0,[\"tasks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"v2/tasks-list/item\",[],[[\"@task\",\"@canEdit\"],[[32,1],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,5],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"cl-flex cl-mt-1\"],[12],[2,\"\\n      \"],[8,\"cl-button\",[],[[\"@iconButton\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"add\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"cl-input\",[[16,\"placeholder\",[32,2]],[16,\"disabled\",[32,0,[\"createNewTask\",\"isRunning\"]]]],[[\"@containerClass\",\"@line\",\"@value\",\"@onChange\"],[\"cl-flex-1 cl-pl-2 cl-pr-3\",true,[32,0,[\"taskText\"]],[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"taskText\"]]],null]],null]]],null],[2,\"\\n      \"],[8,\"cl-button\",[],[[\"@raised\",\"@primary\",\"@disabled\",\"@onClick\"],[true,true,[30,[36,3],[[32,0,[\"createNewTask\",\"isRunning\"]],[30,[36,2],[[32,0,[\"taskText\"]]],null]],null],[30,[36,4],[[32,0,[\"createNewTask\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[32,0,[\"createNewTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          Saving\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          Add item\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"is-empty\",\"or\",\"perform\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/tasks-list/template.hbs"
    }
  });

  _exports.default = _default;
});