define("coachlogix/pods/settings/basic-info/coaching-capacity-card/component", ["exports", "@glimmer/component", "ember-changeset", "ember-changeset-validations", "ember-concurrency-decorators", "coachlogix/validations/account"], function (_exports, _component, _emberChangeset, _emberChangesetValidations, _emberConcurrencyDecorators, _account) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CoachingCapacityCardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class CoachingCapacityCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "editState", _descriptor4, this);

      _initializerDefineProperty(this, "changeset", _descriptor5, this);
    }

    get activeAccount() {
      return this.editState ? this.changeset : this.account.activeAccount;
    }

    enterEditState() {
      this.changeset = new _emberChangeset.Changeset(this.account.activeAccount, (0, _emberChangesetValidations.default)(_account.default), _account.default);
      this.editState = true;
    }

    *saveChanges() {
      try {
        yield this.changeset.save();
        this.editState = false;
        this.clToaster.success(this.intl.t('settings.basic-info.coaching.coach-capacity-updated'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    discardChanges() {
      this.changeset.rollback();
      this.editState = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "editState", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "enterEditState", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "enterEditState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype)), _class));
  _exports.default = CoachingCapacityCardComponent;
});