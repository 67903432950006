define("coachlogix/pods/components/ui/stripe-card-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Kfy0nqv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[15,\"for\",[31,[\"input-\",[34,1]]]],[14,0,\"md-required\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[\"ui.credit-or-debit\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"md-input\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,1,[31,[\"input-\",[34,1]]]],[14,0,\"md-input\"],[14,5,\"border: none\"],[12],[2,\"\\n\"],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"md-errors-spacer\"],[12],[13],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"md-input-messages-animation md-auto-hide\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,5,\"opacity: 1; margin-top: 0\"],[14,0,\"paper-input-error ng-enter ng-enter-active md-input-message-animation\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"error\",\"elementId\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/ui/stripe-card-input/template.hbs"
    }
  });

  _exports.default = _default;
});