define("coachlogix/pods/video/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UD/jbnQI",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[32,0,[\"model\",\"displayTitle\"]]],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"cl-w-full cl-h-full cl-flex cl-items-center cl-justify-center cl-bg-pale-grey-three cl-p-0 sm:cl-p-4\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isSessionStarted\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"video/video-ui\",[],[[\"@session\",\"@onStopSession\"],[[32,0,[\"model\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"isSessionStarted\"]]],null],false],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"video/video-setup\",[],[[\"@session\",\"@onStartSession\"],[[32,0,[\"model\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"isSessionStarted\"]]],null],true],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"page-title\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/video/template.hbs"
    }
  });

  _exports.default = _default;
});