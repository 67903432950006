define("coachlogix/pods/vendor/coachees/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VendorCoacheesRoute extends Ember.Route {
    model() {
      return {
        vendorTask: this.modelFor('vendor').vendorTask
      };
    }

  }

  _exports.default = VendorCoacheesRoute;
});