define("coachlogix/pods/components/v2/avatar-for/user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pFFxx7Zb",
    "block": "{\"symbols\":[\"@model\",\"@size\",\"&attrs\"],\"statements\":[[8,\"cl-user-avatar\",[[17,3]],[[\"@imageUrl\",\"@firstName\",\"@lastName\",\"@email\",\"@size\"],[[32,1,[\"avatar\"]],[32,1,[\"firstName\"]],[32,1,[\"lastName\"]],[32,1,[\"email\"]],[32,2]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/avatar-for/user/template.hbs"
    }
  });

  _exports.default = _default;
});