define("coachlogix/pods/components/v2/summary-cards/sessions/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SummarySessionsWidgetComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = (_temp = class SummarySessionsWidgetComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _defineProperty(this, "statuses", ['scheduled', 'completed', 'late_cancelled', 'draft']);

      _defineProperty(this, "statusFilters", ['scheduled']);

      _defineProperty(this, "participantsFilter", 'myself');
    }

    toggleTypeFilter(value) {
      if (this.typeFilters.includes(value)) {
        this.typeFilters.removeObject(value);
      } else {
        this.typeFilters.addObject(value);
      }

      this.paginationApi.reset();
    }

    toggleStatusFilter(value, event = null) {
      event?.stopPropagation?.();

      if (this.statusFilters.includes(value)) {
        this.statusFilters.removeObject(value);
      } else {
        this.statusFilters.addObject(value);
      }

      this.paginationApi.reset();
    }

    setParticipantsFilter(value) {
      this.set('participantsFilter', value);
      this.paginationApi.reset();
    }

    *loadData({
      pagination: {
        pageSize,
        pageNumber
      }
    }) {
      let include = ['engagement.coach.user', 'engagement.coach.user.timezone', 'engagement.coach.user.availability-preferences', 'engagement.coachee.user', 'participants.user', 'meeting-location'].join(',');
      let filter = {};

      if (this.dashboard) {
        filter.dashboard = 1;

        if (this.participantsFilter) {
          filter.participants = this.participantsFilter;
        }
      }

      if (this.statusFilters && this.statusFilters.length) {
        filter.status = this.statusFilters;
      }

      if (this.employee) {
        filter.employee_id = this.employee.id;
      }

      if (this.client) {
        filter.client_id = this.client.id;
      }

      let page = {
        size: pageSize,
        number: pageNumber
      };
      let sessions = yield this.store.query('engagement-session', {
        filter,
        page,
        include
      });
      return sessions.toArray();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleTypeFilter", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleTypeFilter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleStatusFilter", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleStatusFilter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setParticipantsFilter", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "setParticipantsFilter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadData", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "loadData"), _class2.prototype)), _class2)) || _class);
  var _default = SummarySessionsWidgetComponent;
  _exports.default = _default;
});