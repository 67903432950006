define("coachlogix/models/file", ["exports", "@ember-data/model", "byte-size", "coachlogix/utils/resource-category"], function (_exports, _model, _byteSize, _resourceCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PREVIEWABLE_CATEGORIES = void 0;
  const PREVIEWABLE_CATEGORIES = ['text', 'image', 'presentation', 'spreadsheet'];
  _exports.PREVIEWABLE_CATEGORIES = PREVIEWABLE_CATEGORIES;

  var _default = Ember.Mixin.create({
    resourceInfo: (0, _model.attr)('object'),
    isFile: Ember.computed.equal('resourceType', 'file'),
    isPreviewable: Ember.computed('isFile', 'resourceInfo.{handle,mimetype}', function () {
      return this.isFile && this.resourceInfo.handle && PREVIEWABLE_CATEGORIES.includes((0, _resourceCategory.resourceCategoryName)(this)) && !this.resourceInfo.mimetype.includes('csv');
    }),
    byteSize: Ember.computed('resourceInfo.size', function () {
      if (this.resourceInfo.size) {
        return (0, _byteSize.default)(this.resourceInfo.size).toString();
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});