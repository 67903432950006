define("coachlogix/models/interaction", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    modelReference: Ember.computed.reads('name'),
    calendarTitle: (0, _model.attr)('string'),
    // resolved title for calendar
    cancelReason: (0, _model.attr)('string'),
    completedDate: (0, _model.attr)('date'),
    createdDate: (0, _model.attr)('date'),
    description: (0, _model.attr)('string'),
    meetingInstructions: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    actualDuration: (0, _model.attr)('number', {
      defaultValue: 15
    }),
    duration: (0, _model.attr)('number'),
    scheduledDate: (0, _model.attr)('date'),
    showSchedule: (0, _model.attr)('boolean'),
    // coach has a shared calendar
    status: (0, _model.attr)('string', {
      defaultValue: 'unscheduled'
    }),
    engagement: (0, _model.belongsTo)('engagement', {
      key: 'engagement',
      async: true,
      inverse: null
    }),
    roadmapItem: (0, _model.belongsTo)('roadmapItem', {
      inverse: null
    }),

    /* comments: hasMany('comment', {
      key: 'comments',
      async: true,
      inverse: null
    }),*/
    participants: (0, _model.hasMany)('user', {
      key: 'participants',
      async: true,
      inverse: null
    }),

    /* reminders: hasMany('interaction-reminder', {
      async: true,
      inverse: null
    }), */
    // computed properties
    actualDate: Ember.computed.alias('completedDate'),
    scheduledDuration: Ember.computed.alias('duration'),
    engagementIsActive: Ember.computed.alias('engagement.isActive'),
    isComplete: Ember.computed.notEmpty('completedDate'),
    isSent: Ember.computed.notEmpty('publishedDate'),
    isPublished: Ember.computed.notEmpty('publishedDate'),
    wasConducted: Ember.computed.equal('status', 'conducted'),
    wasCanceled: Ember.computed.equal('status', 'late cancel'),
    hasCoachSchedule: Ember.computed.alias('showSchedule'),
    isScheduled: Ember.computed('completedDate', 'scheduledDate', function () {
      if (this.get('completedDate') !== null) {
        return false;
      } else if (this.get('scheduledDate')) {
        return true;
      }

      return false;
    }),
    isUnscheduled: Ember.computed.equal('scheduledDate', null)
  });

  _exports.default = _default;
});