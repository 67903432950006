define("coachlogix/mixins/filterable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  const { observer, computed: { filter }, defineProperty } = Ember;
  */
  const FilterCategory = Ember.Object.extend({
    appliedFilters: Ember.computed.filterBy('options', 'enabled', true),

    filter(items) {
      if (this.get('appliedFilters.length')) {
        items = items.filter(item => {
          return this.get('appliedFilters').some(option => {
            return option.filter(item) ? true : false;
          });
        });
      }

      return items;
    }

  });

  var _default = Ember.Mixin.create({
    /*init() {
      this._super(...arguments);
      this.setupFilters();
    },*/
    setupFilters() {
      let filters = this.get('filters');
      let filterCategories = Object.keys(filters);
      filterCategories = filterCategories.map(key => {
        let {
          label,
          options
        } = filters[key];
        return FilterCategory.create({
          label,
          enabled: true,
          options: options.map(({
            label,
            filter
          }) => {
            return {
              enabled: false,
              filter,
              label
            };
          })
        });
      });
      this.set('filterCategories', filterCategories);
    },

    enabledFilterCategories: Ember.computed.filterBy('filterCategories', 'enabled', true),
    enabledFilterCategoryFilters: Ember.computed('enabledFilterCategories.[]', function () {
      return Ember.ArrayProxy.create({
        content: [].concat(...this.get('enabledFilterCategories').mapBy('options'))
      });
    }),
    enabledFilters: Ember.computed.filterBy('enabledFilterCategoryFilters', 'enabled'),
    hasFilters: Ember.computed.alias('enabledFilters.length'),
    filteredResults: Ember.computed('results.@each', 'enabledFilters.@each', function () {
      let categories = this.get('enabledFilterCategories');
      let results = this.get('results');
      categories.forEach(category => {
        results = category.filter(results);
      });
      return results;
    }),

    clearFilters() {
      this.get('enabledFilters').forEach(filter => {
        filter.set('enabled', false);
      });
    }

  });

  _exports.default = _default;
});