define("coachlogix/pods/components/v2/contracts/add-new-contract-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3q5TIkoa",
    "block": "{\"symbols\":[\"@client\",\"@onClose\"],\"statements\":[[6,[37,2],[[32,0,[\"getContractTemplates\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,5],[\"cl-wormhole\"],null]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-z-20 cl-absolute cl-inset-0 cl-flex cl-items-center cl-justify-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"cl-absolute cl-inset-0 cl-bg-strong-grey cl-opacity-50\"],[12],[13],[2,\"\\n      \"],[8,\"cl-progress-circular\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,4],[[32,0,[\"contractTemplates\",\"length\"]],[32,0,[\"showContractTemplatesDialog\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[8,\"v2/contracts/select-contract-template-dialog\",[],[[\"@contractTemplates\",\"@onClose\",\"@onSelect\",\"@onContractCreate\"],[[32,0,[\"contractTemplates\"]],[32,2],[30,[36,3],[[32,0,[\"createContract\"]]],null],[30,[36,1],[[30,[36,0],[[32,0,[\"showContractTemplatesDialog\"]]],null],false],null]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n    \"],[8,\"v2/contracts/contract-dialog\",[],[[\"@modelName\",\"@client\",\"@onCreated\",\"@onClose\"],[\"contract\",[32,1],[32,0,[\"onCreated\"]],[30,[36,2],[[32,0,[\"contractTemplates\",\"length\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"showContractTemplatesDialog\"]]],null],true],null],[32,2]],null]]],null],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"if\",\"perform\",\"and\",\"get-element-by-id\",\"-in-el-null\",\"in-element\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/contracts/add-new-contract-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});