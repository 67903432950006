define("coachlogix/models/commentable", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    accountService: Ember.inject.service('account'),
    clToaster: Ember.inject.service(),
    comments: (0, _model.hasMany)('comment', {
      async: true,
      inverse: 'commentable'
    }),
    createComment: (0, _emberConcurrency.task)(function* (body) {
      let user = this.accountService.activeUser;
      let comment = this.store.createRecord('comment', {
        user,
        commentable: this,
        body
      });

      try {
        yield comment.save();
        this.comments.pushObject(comment);
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});