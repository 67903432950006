define("coachlogix/pods/client/route", ["exports", "ember-can/computed", "ember-concurrency-decorators"], function (_exports, _computed, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ClientRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _computed.ability)('account'), _dec4 = Ember.computed.reads('accountAbility.canOnlySeeProfiles'), _dec5 = Ember._action, _dec6 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class ClientRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "topbar", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "accountAbility", _descriptor3, this);

      _initializerDefineProperty(this, "canOnlySeeProfiles", _descriptor4, this);
    }

    activate() {
      super.activate(...arguments);
      this.topbar.setTransparent();
    }

    model({
      client_id
    }) {
      return {
        clientTask: this.loadClient.perform(client_id)
      };
    }

    reloadModel() {
      this.refresh();
    }

    *loadClient(id) {
      return yield this.store.findRecord('client', id, {
        reload: true,
        adapterOptions: {
          queryParams: {
            include_metadata: 1
          }
        },
        include: ['client-pocs.employee.user', 'client-coachees.employee', 'client-coaches.employee', 'client-pocs.employee.invitation', 'client-locations', 'primary-contact.timezone', 'lead.timezone', 'client-account', 'account', 'requestor-account', 'client-summary', 'primary-contact.profile'].join(',')
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "topbar", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "accountAbility", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "canOnlySeeProfiles", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "reloadModel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "reloadModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadClient", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadClient"), _class.prototype)), _class));
  _exports.default = ClientRoute;
});