define("coachlogix/abilities/form", ["exports", "ember-can", "ember-can/computed", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _computed, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    resourceAbility: (0, _computed.ability)('resource', 'model'),
    canToggleConfidential: Ember.computed('resourceAbility.canEdit', 'activeRoles.[]', function () {
      return this.resourceAbility.canEdit && this.hasAccountRole('account_global_admin', 'account_engagement_manager', 'location_global_admin');
    }),
    canSeeResponses: Ember.computed('activeRoles.[]', 'model.isConfidential', function () {
      if (this.model.isConfidential) {
        return this.hasAccountRole('account_global_admin');
      } else {
        return true;
      }
    }),
    canSend: Ember.computed('account.activeUser.id', 'model.owner.id', function () {
      return this.account.activeUser.id === this.model.belongsTo('owner').id();
    })
  });

  _exports.default = _default;
});