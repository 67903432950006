define("coachlogix/models/tax-rate", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    rate: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    isInclusive: (0, _model.attr)('boolean'),
    ratePercentage: Ember.computed('rate', {
      get() {
        let rate = this.get('rate');

        if (rate) {
          // workaround floating point arithmetic imprecision
          return Math.round(rate * 100 * 100) / 100;
        }
      },

      set(key, value) {
        this.set('rate', parseFloat(value) / 100);
        return value;
      }

    })
  });

  _exports.default = _default;
});