define("coachlogix/models/employee", ["exports", "@ember-data/model", "ember-concurrency", "qs-stringify", "coachlogix/models/base-model", "coachlogix/models/commentable"], function (_exports, _model, _emberConcurrency, _qsStringify, _baseModel, _commentable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_commentable.default, {
    intl: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    deletedAt: (0, _model.attr)('date'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    employeeTitle: (0, _model.attr)('string'),
    deskNumber: (0, _model.attr)('string'),
    internalIdentificationNumber: (0, _model.attr)('string'),
    isCoach: (0, _model.attr)('boolean'),
    workEmail: (0, _model.attr)('string'),
    workPhone: (0, _model.attr)('string'),
    coachAccount: (0, _model.attr)('string'),
    coachCapacity: (0, _model.attr)('number'),
    activeEngagements: (0, _model.attr)('number'),
    acceptingClients: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    requestExternalEngagementApproval: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    coachingRequests: (0, _model.hasMany)('coaching-request', {
      inverse: 'coach'
    }),
    companyLocation: (0, _model.belongsTo)('company-location', {
      inverse: null
    }),
    department: (0, _model.belongsTo)('department', {
      inverse: null
    }),
    subDepartment: (0, _model.belongsTo)('department', {
      inverse: null
    }),
    employeeLevel: (0, _model.belongsTo)('employee-level', {
      inverse: null
    }),
    ownerAccount: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    matchingProcess: (0, _model.belongsTo)('engagement', {
      inverse: null
    }),
    tenant: (0, _model.belongsTo)('tenant', {
      inverse: null
    }),
    employeeSettings: (0, _model.belongsTo)('employee-setting', {
      inverse: 'employee'
    }),
    clientCoaches: (0, _model.hasMany)('client-coach', {
      async: true,
      inverse: 'employee'
    }),
    clientCoachees: (0, _model.hasMany)('client-coachee', {
      async: true,
      inverse: 'employee'
    }),
    roleUsers: (0, _model.hasMany)('role-user', {
      async: true,
      inverse: null
    }),
    //invitations: hasMany('invitation', { async: true, inverse: null }),
    coachPoolMatchingProcess: (0, _model.hasMany)('engagement', {
      inverse: null
    }),
    roleNames: Ember.computed('roleUsers.@each.role', function () {
      let roles = [];
      this.get('roleUsers').forEach(roleUser => {
        let role = roleUser.get('role');

        if (!roles.includes(role.get('displayName'))) {
          roles.pushObject(role.get('displayName'));
        }
      });
      return roles;
    }),
    employeeIsGlobal: Ember.computed('roleUsers', function () {
      let roleUsers = this.get('roleUsers');
      roleUsers.forEach(roleUser => {
        let role = roleUser.get('role');

        if (role.get('name') === 'account_global_admin') {
          return true;
        }
      });
      return false;
    }),
    notDeletedRoleUsers: Ember.computed.filterBy('roleUsers', 'isDeleted', false),
    notEmployeeRole: Ember.computed.filterBy('roleUsers.roleName', 'account_employee', false),
    accountRoles: Ember.computed.filterBy('notDeletedRoleUsers', 'objectType', 'accounts'),
    locationRoles: Ember.computed.filterBy('notDeletedRoleUsers', 'objectType', 'company-locations'),
    departmentRoles: Ember.computed.filterBy('notDeletedRoleUsers', 'objectType', 'departments'),
    permissions: (0, _model.hasMany)('permission', {
      async: true,
      inverse: null
    }),
    role: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    //'invited','connected','internal_record'
    sendInvitation: (0, _model.attr)('boolean'),
    account: (0, _model.belongsTo)('account', {
      async: true,
      inverse: 'employees'
    }),
    user: (0, _model.belongsTo)('user', {
      async: true,
      inverse: null
    }),
    isInvited: Ember.computed.equal('status', 'invited'),
    isInternalRecord: Ember.computed.equal('status', 'internal_record'),
    isActive: Ember.computed.equal('status', 'connected'),
    userRoles: Ember.computed.mapBy('roleUsers', 'role'),
    userRolesNames: Ember.computed.mapBy('userRoles', 'name'),
    isInternal: Ember.computed('account.id', 'accountService.activeAccount.id', function () {
      return this.belongsTo('account').id() === this.get('accountService.activeAccount.id');
    }),
    isIndependent: Ember.computed.reads('account.isIndependentCoach'),
    isExternal: Ember.computed.not('isInternal'),
    isOwner: Ember.computed.equal('account.owner_id', 'user.id'),
    //isGlobalAdmin: computed.equal('role', 'account_global_admin'),
    isGlobalAdmin: Ember.computed('accountService.activeRoles', function () {
      return this.get('accountService.activeRoles').includes('account_global_admin');
    }),
    roleUsersRoles: Ember.computed.mapBy('roleUsers', 'role'),
    isAdmin: Ember.computed('roleUsers', 'roleUsersRoles.@each.name', function () {
      let roles = (this.get('roleUsers') || []).mapBy('role.name');
      return roles.any(roleName => {
        return roleName === 'account_global_admin' || roleName === 'location_global_admin';
      });
    }),
    isEngagementManager: Ember.computed('accountService.activeRoles', function () {
      return this.get('accountService.activeRoles').includes('account_engagement_manager');
    }),
    isLocationManager: Ember.computed('accountService.activeRoles', function () {
      return this.get('accountService.activeRoles').includes('location_global_admin');
    }),
    isDepartmentManager: Ember.computed('accountService.activeRoles', function () {
      return this.get('accountService.activeRoles').includes('department_admin');
    }),
    avatar: Ember.computed.alias('user.avatar'),
    name: Ember.computed.alias('user.name'),
    email: Ember.computed.alias('user.email'),
    coverPhoto: Ember.computed.or('user.coverPhoto', 'account.coverPhoto'),
    displayEmail: Ember.computed.or('workEmail', 'user.email'),
    displayPhone: Ember.computed.or('workPhone', 'user.phone'),
    displayTitle: Ember.computed('employeeTitle', 'isInternal', 'account.name', function () {
      if (this.get('isInternal')) {
        return this.get('employeeTitle');
      } else {
        return this.get('account.name');
      }
    }),
    displayLabel: Ember.computed('isInternal', 'displayTitle', 'account.name', function () {
      /*if (this.get('isInternal')) {
        return this.get('displayTitle')
      } else {
        return this.get('account.name')
      }*/
      return false;
    }),
    engagementsAsCoach: Ember.computed('id', 'store', function () {
      return this.store.query('engagement', {
        filter: {
          coach_id: this.id
        },
        include: 'engagement-summary,coachee'
      });
    }),
    engagementsAsCoachee: Ember.computed('id', 'store', function () {
      return this.store.query('engagement', {
        filter: {
          coachee_id: this.id
        },
        include: 'engagement-summary,coach'
      });
    }),
    activeEngagementsAsCoachee: Ember.computed.filterBy('engagementsAsCoachee', 'status', 'active'),
    activeGoals: Ember.computed('activeEngagementsAsCoachee.@each.goals', function () {
      let goals = [];
      this.get('activeEngagementsAsCoachee').forEach(engagement => {
        engagement.get('goals').forEach(goal => {
          goals.pushObject(goal);
        });
      });
      return goals;
    }),
    engagementsAsCoachAndCoachee: Ember.computed.union('engagementsAsCoach', 'engagementsAsCoachee'),
    engagements: Ember.computed.uniq('engagementsAsCoachAndCoachee'),
    completedSessions: Ember.computed.filterBy('sessions', 'status', 'completed'),

    /*
    hoursScheduled: computed('upcomingSessions', function() {
      let hours = 0;
      let minutes = 0;
      this.get('upcomingSessions').forEach((session) => {
        let sessionDuration = session.get('actualDuration');
        console.log(session.get('id') + " upcoming: " +sessionDuration);
        if(sessionDuration) {
          minutes += sessionDuration;
        }
      });
      if(minutes) {
        hours = Math.ceil((minutes/60)*4)/4;
      }
       return hours;
    }),
     sessionHours: task( function(sessions) {
      let hours = 0;
      let minutes = 0;
      sessions.forEach((session) => {
        let sessionDuration = session.get('actualDuration');
        if(sessionDuration) {
          minutes += sessionDuration;
        }
      });
      if(minutes) {
        hours = Math.ceil((minutes/60)*4)/4;
      }
       return hours;
       //return DS.PromiseArray.create({
      //  promise: this.get('loadHoursCompleted').perform()
      //})
    }),
    */
    hoursCompleted: Ember.computed('completedSessions', function () {
      let hours = 0;
      let minutes = 0;
      this.get('completedSessions').forEach(session => {
        let sessionDuration = session.get('actualDuration');

        if (sessionDuration) {
          minutes += sessionDuration;
        }
      });

      if (minutes) {
        hours = Math.ceil(minutes / 60 * 4) / 4;
      }

      return hours; //return DS.PromiseArray.create({
      //  promise: this.get('loadHoursCompleted').perform()
      //})
    }),
    //participatingSessions: computed.mapBy('participatingSessionProxies', 'content'),
    completedEngagements: Ember.computed.filterBy('engagements', 'status', 'completed'),
    draftEngagements: Ember.computed.filterBy('engagements', 'status', 'draft'),
    loadUpcomingSessions: (0, _emberConcurrency.task)(function* () {
      let sessions = yield this.store.query('engagement-session', {
        filter: {
          employee_id: this.id,
          upcoming: true
        }
      });
      return sessions;
    }),
    hoursRemainingTask: (0, _emberConcurrency.task)(function* () {
      return yield this.get('ajax').request(`/helpers/session-hours-remaining?employee_id=${this.id}`);
    }),
    nextSession: Ember.computed.alias('upcomingSessions.firstObject'),

    /*
    upcomingSessions: computed(function() {
      let sessions = this.store.query('engagement-session', { filter: { employee_id: this.id, upcoming: true } });
      console.log(sessions);
      return sessions;
    }),
     upcomingSessions: computed('loadUpcomingSessions', function() {
      return DS.PromiseArray.create({
        promise: this.get('loadUpcomingSessions').perform()
      });
    }),
    */

    /* Actions */
    updateTitle: (0, _emberConcurrency.task)(function* (value) {
      this.set('employeeTitle', value);
      yield this.save();
    }),
    updateID: (0, _emberConcurrency.task)(function* (value) {
      this.set('internalIdentificationNumber', value);
      yield this.save();
    }),
    updateDeskNumber: (0, _emberConcurrency.task)(function* (value) {
      this.set('deskNumber', value);
      yield this.save();
    }),
    updateWorkPhone: (0, _emberConcurrency.task)(function* (value) {
      this.set('workPhone', value);
      yield this.save();
    }),
    updateWorkEmail: (0, _emberConcurrency.task)(function* (value) {
      this.set('workEmail', value);
      yield this.save();
    }),
    updateBusinessUnit: (0, _emberConcurrency.task)(function* (value) {
      this.set('workPhone', value);
      yield this.save();
    }),
    updateEmployeeTitle: (0, _emberConcurrency.task)(function* (title) {
      try {
        this.set('employeeTitle', title);
        yield this.save();
        this.clToaster.success('Title updated');
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }),
    removeEmployee: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'disconnected');
        yield this.save();
        this.unloadRecord();
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    removeCoach: (0, _emberConcurrency.task)(function* () {
      if (this.get('isInternal')) {
        yield this.removeEmployee.perform();
      } else {
        if (this.get('isIndependent')) {
          try {
            let client_account_id = this.get('accountService.activeAccount.id');
            let account = this.get('account');
            let clients = yield this.store.query('client', {
              filter: {
                client_account_id: client_account_id,
                account_id: account.get('id')
              }
            });
            let client = clients.get('firstObject');

            if (client) {
              yield client.destroyRecord();
            }

            this.unloadRecord();
          } catch (e) {
            this.clToaster.error(e);
          }
        } else {
          try {
            let client_account_id = this.get('accountService.activeAccount.id');
            let account = this.get('account');
            let clients = yield this.store.query('client', {
              filter: {
                client_account_id: client_account_id,
                account_id: account.get('id')
              },
              include: 'client-coaches'
            });
            let client = clients.get('firstObject');
            let clientCoaches = yield client.get('clientCoaches');
            let [clientCoach] = clientCoaches.filter(c => {
              return c.get('employee.id') === this.get('id');
            });

            if (clientCoach) {
              yield clientCoach.destroyRecord();
            }

            this.unloadRecord();
          } catch (e) {
            this.clToaster.error(e);
          }
        }
      }
    }),
    resendInvitation: (0, _emberConcurrency.task)(function* () {
      try {
        let wasInvited = this.get('isInvited');
        let queryString = (0, _qsStringify.default)({
          employee_id: this.get('id')
        });
        yield this.get('ajax').post(`/helpers/resend-invitation?${queryString}`);
        this.set('status', 'invited');
        const toastMessage = wasInvited ? this.get('intl').t('ui.invite-resent-success') : this.get('intl').t('ui.invite-sent-success');
        this.clToaster.success(toastMessage);
      } catch (e) {
        this.clToaster.error('Invitation was sent less than 24 hours ago.');
      }
    }),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    accountService: Ember.inject.service('account')
  });

  _exports.default = _default;
});