define("coachlogix/pods/components/v2/invite-dialog/common/search-email/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    formSubmitted: false,
    email: '',
    validEmail: Ember.computed.match('email', /^.+@.+\..+$/),
    emailAccepted: Ember.computed('email', 'acceptedEmails', 'emailIsEmployee', function () {
      const acceptedEmails = this.get('acceptedEmails');
      const currentEmail = this.get('email');

      if (!acceptedEmails || acceptedEmails.length === 0) {
        return true;
      }

      return acceptedEmails.includes(currentEmail);
    }),
    allowedEmail: Ember.computed('email', 'validEmail', 'emailAccepted', 'emailIsEmployee', 'connectedEmployee.isRunning', 'excludeEmployees', function () {
      const excludeEmployees = this.get('excludeEmployees');
      let isAllowdToBeAdded = true;

      if (excludeEmployees) {
        isAllowdToBeAdded = !this.get('emailIsEmployee') && !this.get('connectedEmployee.isRunning');
      }

      return this.get('email') && this.get('validEmail') && this.get('emailAccepted') && isAllowdToBeAdded;
    }),
    errors: Ember.computed('allowedEmail', 'connectedEmployee.isRunning', 'email', 'emailAccepted', 'emailIsEmployee', 'emailNotAcceptedError', 'excludeEmployees', 'validEmail', function () {
      const errors = [];
      const intl = this.get('intl');
      const excludeEmployees = this.get('excludeEmployees');

      if (!this.get('validEmail')) {
        errors.push(intl.t('ui.invite-dialog.invalid-email'));
        return errors;
      }

      if (excludeEmployees && this.get('connectedEmployee.isRunning')) {
        errors.push(intl.t('ui.invite-dialog.verifying'));
        return errors;
      }

      if (excludeEmployees && this.get('emailIsEmployee')) {
        errors.push(intl.t('ui.invite-dialog.already-connected-poc-error'));
        return errors;
      }

      if (!this.get('emailAccepted')) {
        errors.push(this.get('emailNotAcceptedError'));
        return errors;
      }

      return errors;
    }),
    emailIsEmployee: Ember.computed('connectedEmployee.{value,isRunning}', function () {
      const employee = this.get('connectedEmployee.value');
      const isRunning = this.get('connectedEmployee.isRunning');
      return employee && !isRunning;
    }),
    connectedEmployee: Ember.computed('connectedEmployeeSearch', 'email', function () {
      return this.get('connectedEmployeeSearch').perform();
    }),
    connectedEmployeeSearch: (0, _emberConcurrency.task)(function* () {
      if (!this.get('email')) return;
      const employees = yield this.get('store').query('employee', {
        filter: {
          search: this.get('email')
        }
      });
      const match = employees.find(employee => employee.get('workEmail') === this.get('email'));
      return match;
    }).restartable(),
    searchEmail: (0, _emberConcurrency.task)(function* () {
      if (!this.email || !this.validEmail) {
        this.set('formSubmitted', true);
        return;
      }

      let email = this.email.trim();

      try {
        let response = yield this.ajax.request(`/email-check/${email}`); // Found user

        let users = yield this.store.query('user', {
          filter: {
            uuid: response.data.uuid
          },
          include: ['employee', 'employee.role-users'].join(',')
        });
        let user = users.firstObject;
        let employee = yield user?.get('employee');

        if (employee) {
          this.get('onNext')(employee);
        } else {
          this.get('onNext')(Ember.Object.create({
            user,
            workEmail: email
          }));
        }
      } catch (e) {
        this.get('onNext')(Ember.Object.create({
          workEmail: email
        }));
      }
    })
  });

  _exports.default = _default;
});