define("coachlogix/services/twilio-sync", ["exports", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StripeService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class StripeService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clToaster", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _defineProperty(this, "client", null);

      _defineProperty(this, "isTokenExpired", false);
    }

    /**
     * Initializes the twilio client.
     * Gets the token from the server and creates the twilio client afterwards,
     * using the Twilio js sdk.
     *
     * Will return a previous SyncClient instance if previously initialized,
     * so it can be safely called multiple times and it will only initialize once.
     *
     * @returns Promise<SyncClient>
     */
    *initTwilio() {
      if (this.client && !this.isTokenExpired) {
        return this.client;
      }

      try {
        let [Client, token] = yield (0, _emberConcurrency.all)([emberAutoImportDynamic("twilio-sync"), this.getToken.perform()]);
        let client = new Client.SyncClient(token);
        this.isTokenExpired = false;
        this.client = client;
        this.client.on('tokenAboutToExpire', () => {
          this.refreshToken.perform();
        });
        return client;
      } catch (e) {
        this.clToaster.error('Failed to initialize twilio sync client with error:' + e);
      }
    }
    /**
     * Gets a twilio token from the server.
     *
     * @returns Promise<string>
     */


    *getToken() {
      let response = yield this.ajax.request('helpers/twilio-sync-token');
      return response.token;
    }

    *refreshToken() {
      let token = yield this.getToken.perform();
      yield this.client.updateToken(token);
      this.isTokenExpired = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initTwilio", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "initTwilio"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getToken", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshToken", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "refreshToken"), _class.prototype)), _class));
  _exports.default = StripeService;
});