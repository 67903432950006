define("coachlogix/pods/form/form-answers/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    loadData: (0, _emberConcurrency.task)(function* () {
      let form = yield this.get('form');
      let submissions = yield form.get('formSubmissions');
      let answers = yield (0, _emberConcurrency.all)(submissions.mapBy('answers'));
      yield (0, _emberConcurrency.all)(answers.map(as => {
        return (0, _emberConcurrency.all)(as.mapBy('question'));
      }));
    }).on('init'),
    allAnswers: Ember.computed('form.formSubmissions.@each.answers', function () {
      return this.get('form.formSubmissions').mapBy('answers').reduce((a, b) => a.concat(b.toArray()), []).filter(a => !a.get('isNew'));
    }),
    hasSections: Ember.computed.bool('form.formSections.length'),
    answersBySection: Ember.computed('form.{formSections.@each.sortOrder,formQuestions.@each.sortOrder}', 'allAnswers.@each.question', function () {
      let allAnswers = this.get('allAnswers');
      return this.get('form.formSections').sortBy('sortOrder').map(section => {
        let questions = section.get('formQuestions').sortBy('sortOrder').map(question => {
          return {
            question,
            answers: allAnswers.filterBy('question.content', question)
          };
        });
        return {
          section,
          questions
        };
      });
    }),
    answersByQuestion: Ember.computed('form.formQuestions.@each.sortOrder', 'allAnswers.@each.question', function () {
      let allAnswers = this.get('allAnswers');
      return this.get('form.formQuestions').sortBy('sortOrder').map(question => {
        return {
          question,
          answers: allAnswers.filterBy('question.content', question)
        };
      });
    })
  });

  _exports.default = _default;
});