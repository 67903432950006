define("coachlogix/abilities/payment", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    canDelete: Ember.computed('model.{invoice.isSender,isPending}', function () {
      let payment = this.model;

      if (payment.get('invoice.isSender')) {
        return true;
      } else {
        return payment.isPending;
      }
    }),
    canApprove: Ember.computed('model.{invoice.isSender,isPending}', function () {
      let payment = this.model;
      return payment.get('invoice.isSender') && payment.isPending;
    }),
    canDecline: Ember.computed.reads('canApprove')
  });

  _exports.default = _default;
});