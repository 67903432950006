define("coachlogix/pods/components/profile/summary/edit-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QQhjG1lo",
    "block": "{\"symbols\":[\"dialog\",\"&attrs\",\"@onClose\"],\"statements\":[[8,\"cl-dialog\",[[24,0,\"cl-w-40\"],[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"cl-title-3 cl-flex-1\"],[12],[1,[30,[36,0],[\"v2.common.summary\"],null]],[13],[2,\"\\n    \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"saveChanges\",\"isRunning\"]]]],[[\"@iconButton\",\"@onClick\"],[true,[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"close\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"content\"]],[],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-markdown-editor\",[[24,0,\"cl-h-40\"]],[[\"@value\",\"@onChange\",\"@image\"],[[30,[36,1],[[32,0,[\"changeset\"]],\"summary\"],null],[30,[36,2],[[32,0,[\"changeset\"]],\"summary\"],null],false]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"saveChanges\",\"isRunning\"]]]],[[\"@raised\",\"@onClick\"],[true,[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"ui.cancel\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"saveChanges\",\"isRunning\"]]]],[[\"@raised\",\"@primary\",\"@onClick\"],[true,true,[30,[36,3],[[32,0,[\"saveChanges\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"ui.save\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"changeset-get\",\"changeset-set\",\"perform\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/profile/summary/edit-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});