define("coachlogix/pods/engagement/matching-process/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class V2EngagementMatchingIndexRoute extends Ember.Route {
    redirect() {
      this.replaceWith('engagement.matching-process.shared-coaches');
    }

  }

  _exports.default = V2EngagementMatchingIndexRoute;
});