define("coachlogix/abilities/employee", ["exports", "ember-can", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    // you can edit an employee if:
    //   - if employee from your account
    //     - you have manage_account_employees permission or global admin
    //     - it's your own employee
    //   - if from another account
    //     - if you:
    //       - have manage_account_employees permission or global admin
    //       - the account and employee aren't active
    //       - your account is the ownerAccount of this employee
    canEdit: Ember.computed('account.{activeAccount.id,activeEmployee.id,activeUser.id}', 'model.account.{id,isActive}', 'model.{user.id,isActive,ownerAccount.id}', function () {
      let currentAccountId = this.get('account.activeAccount.id');
      let employeeAccountId = this.get('model.account.id');
      let isAdmin = this.hasAccountRole('account_global_admin', 'location_global_admin');
      let hasManagePermissions = this.hasPermission('manage_account_employees');
      let currentUserId = this.get('account.activeUser.id');
      let employeeUserId = this.get('model.user.id');
      let employeeIsActive = this.get('model.isActive');
      let employeeAccountIsActive = this.get('model.account.isActive');
      let employeeOwnerAccountId = this.get('model.ownerAccount.id');

      if (currentAccountId === employeeAccountId) {
        // same account
        // you have permissions to edit an employee of your account
        if (isAdmin || hasManagePermissions) {
          return true;
        } // the employee is yourself


        if (currentUserId === employeeUserId) {
          return true;
        }
      } else {
        // different account
        // you can edit basically because there's no one else on the other side to do it
        // and you "own" this record (you created it first)
        if (isAdmin && !employeeIsActive && !employeeAccountIsActive && employeeOwnerAccountId === currentAccountId) {
          return true;
        }
      }

      return false;
    }),
    canCreateEmployee: Ember.computed('account.activeAccount.accountType', function () {
      return this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.hasPermission('manage_account_employees');
    }),
    canUpdateEmployee: Ember.computed('account.activeAccount.accountType', function () {
      return this.hasPermission('manage_account_employees');
    }),
    canViewEmployee: Ember.computed('account.activeAccount', function () {
      return this.hasPermission('view_employees');
    }),
    canViewSummary: Ember.computed('account.activeEmployee.{id,isInternal,isCoach}', 'model.id', function () {
      let activeEmployee = this.account.activeEmployee; // Internal coaches should not see their own summary

      if (this.model.id === activeEmployee.id && activeEmployee.isInternal && activeEmployee.isCoach) {
        return false;
      }

      return true;
    }),
    canManageEngagements: false,
    canViewEngagements: false,
    canManageResources: false,
    canManageInvoices: false,
    canManagePeople: false,
    canManageCompanies: false,
    canManageReports: false,
    canManageSettings: false,
    canManageProgramTemplates: Ember.computed.alias('account.activeEmployee.role', function () {}),
    canManageOrgStructure: Ember.computed.reads('account.activeAccount.isEnterprise'),
    canAddInternalCoaches: Ember.computed.reads('account.activeAccount.internalCoaching'),
    canBuyCoaching: Ember.computed.reads('account.activeAccount.buysCoaching'),
    canSellCoaching: Ember.computed.reads('account.activeAccount.providesCoaching'),
    canUseMarketplace: Ember.computed('model.{isIndependent,id}', 'account.activeEmployee.id', function () {
      return this.model.id === this.account.activeEmployee.id && this.model.isIndependent;
    })
  });

  _exports.default = _default;
});