define("coachlogix/services/native", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service abstracts the specificities of communicating with
   * the native mobile application (currently iOS and Android).
   */
  var _default = Ember.Service.extend({
    isAndroid() {
      return window.Mobile && typeof window.Mobile === 'object';
    },

    isIOS() {
      return window.webkit && window.webkit.messageHandlers && typeof window.webkit.messageHandlers === 'object';
    },

    /**
     * Runs a method `method` on native with the provided arguments, according to the detected platform
     * @param {string} method
     * @param  {...any} args
     */
    runMethod(method, ...args) {
      if (this.isAndroid() && typeof window.Mobile[method] === 'function') {
        return window.Mobile[method](...args);
      } else if (this.isIOS() && typeof window.webkit.messageHandlers[method].postMessage === 'function') {
        // workaround: postMessage needs to have at least one argument or it won't be called
        if (args && args.length === 0) {
          args = [''];
        }

        return window.webkit.messageHandlers[method].postMessage(...args);
      }
    },

    getToken() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (this.isAndroid()) {
          resolve(this.runMethod('getToken'));
        } else if (this.isIOS()) {
          // WKWebView doesn't support native returning of values to javascript,
          // so any methods that need returning of a value are wrapped in a promise for
          // better ergonomics. The native counterpart is responsible for calling the
          // `receiveToken` function.
          window.receiveToken = function () {
            resolve(...arguments);
            delete window.receiveToken;
          };

          this.runMethod('getToken');
        } else {
          reject();
        }
      });
    },

    submissionFinished(submissionId) {
      return this.runMethod('submissionFinished', submissionId);
    },

    submissionError(message) {
      return this.runMethod('submissionError', message);
    },

    reviewFinished(reviewId) {
      return this.runMethod('reviewFinished', reviewId);
    },

    reviewError(message) {
      return this.runMethod('reviewError', message);
    }

  });

  _exports.default = _default;
});