define("coachlogix/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    account: Ember.inject.service(),
    intl: Ember.inject.service(),

    compute([value = 0], {
      currency
    }) {
      let code = currency || this.get('account.activeAccount.billingSettings.currency.code') || 'USD';
      return this.get('intl').formatNumber(value, {
        style: 'currency',
        currency: code
      });
    }

  });

  _exports.default = _default;
});