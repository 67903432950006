define("coachlogix/abilities/goal", ["exports", "ember-can", "coachlogix/abilities/engagement-permission-helpers"], function (_exports, _emberCan, _engagementPermissionHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_engagementPermissionHelpers.default, {
    engagement: Ember.computed.reads('model.engagement'),
    isOwner: Ember.computed('account.activeEmployee.id', 'model.creator.id', function () {
      return this.get('account.activeEmployee.id') === this.get('model.creator.id');
    }),
    canEdit: Ember.computed('isOwner', 'engagementStatus', 'isCoach', 'roles.[]', function () {
      return this.isOwner || this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach') || this.get('isCoach');
    }),
    canInteract: Ember.computed('engagementStatus', 'isCoach', 'isCoachee', 'roles.[]', function () {
      return this.engagementStatusIsAny('active') && (this.roleIsAny('admin', 'manager', 'coach', 'coachee') || this.get('isCoachee') || this.get('isCoach'));
    }),
    canProvideFeedback: Ember.computed('engagementStatus', 'isCoach', 'isCoachee', 'roles.[]', function () {
      return this.roleIsAny('admin', 'manager', 'coach', 'coachee') || this.get('isCoachee') || this.get('isCoach');
    }),
    canEditPrivacy: Ember.computed('account.activeEmployee.id', 'engagementStatus', 'isOwner', 'model', function () {
      let isOwner = this.model.get('creator').get('id') === this.account.activeEmployee.id;

      if (this.engagementStatusIsAny('active') && isOwner) {
        return true;
      }
    }),
    canManage: Ember.computed.reads('canEdit'),
    canEditTitle: Ember.computed.reads('canEdit'),
    canEditDescription: Ember.computed.reads('canEdit'),
    canComment: Ember.computed.reads('canInteract'),
    canViewComments: Ember.computed.reads('canInteract'),
    canAddTheme: Ember.computed.reads('canEdit'),
    canComplete: Ember.computed.reads('canInteract'),
    canDelete: Ember.computed.reads('canEdit'),
    canManageActions: Ember.computed.reads('canEdit')
  });

  _exports.default = _default;
});