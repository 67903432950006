define("coachlogix/pods/engagement/goals/index/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let GoalsIndexRoute = (_class = class GoalsIndexRoute extends Ember.Route {
    redirect(model) {
      this.redirectToGoal.perform(model.goalsTask);
    }

    *redirectToGoal(task) {
      let goals = yield task;
      goals = goals.filterBy('isNew', false).filterBy('isLoaded', true).filterBy('isDeleted', false).sortBy('createdAt').reverse();

      if (goals.get('length') > 0) {
        this.replaceWith('engagement.goals.goal', goals.get('firstObject.id'));
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "redirectToGoal", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToGoal"), _class.prototype)), _class);
  _exports.default = GoalsIndexRoute;
});