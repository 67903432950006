define("coachlogix/pods/components/cl-session-scheduler-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ClSessionSchedulerDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.or('user.isCronofyConnected', 'session.engagement.coach.user.isCronofyConnected'), _dec5 = Ember.computed('session.meetingLocation.meetingType'), _dec6 = Ember._action, _dec7 = Ember.computed('moment.timeZone', 'selectedDate', 'selectedTime'), _dec8 = Ember.computed('selectedDatetime'), _dec9 = Ember.computed('account.activeUser.id', 'session.engagement.coach.user.id', 'user.id'), _dec(_class = (_class2 = (_temp = class ClSessionSchedulerDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "moment", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "isCronofyConnected", _descriptor3, this);
    }

    init() {
      super.init(...arguments);
      let scheduledDate = Ember.get(this.session, 'scheduledDate');
      Ember.set(this, 'selectedDate', scheduledDate ? (0, _moment.default)(scheduledDate).toDate() : (0, _moment.default)().toDate());
      Ember.set(this, 'selectedTime', scheduledDate ? this.momentTz((0, _moment.default)(scheduledDate)).format('HH:mm') : this.momentTz((0, _moment.default)()).add(1, 'hour').startOf('hour').format('HH:mm'));
    }

    get locationIcon() {
      let meetingType = Ember.get(this.session, 'meetingLocation.meetingType');

      if (meetingType === 'phone') {
        return 'phone';
      } else if (meetingType === 'in_app_video') {
        return 'smartphone';
      } else if (meetingType === 'video') {
        return 'videocam';
      } else {
        return 'location-on';
      }
    }

    goBack() {
      this.set('showConfirmation', false);
      this.set('selectedSlot', null);
    }

    get dateToSchedule() {
      return this.isCronofyConnected ? this.selectedSlot.start : this.selectedDatetime;
    }

    *scheduleSession() {
      yield Ember.get(this.session, 'schedule').perform(this.dateToSchedule);
      yield this.onSchedule?.();
      this.onClose?.();
    }

    get selectedDatetime() {
      let selectedDatetime = (0, _moment.default)(this.selectedDate);
      let selectedTime = (0, _moment.default)(this.selectedTime, 'HH:mm');
      let dateObj = {
        year: selectedDatetime.year(),
        month: selectedDatetime.month(),
        day: selectedDatetime.date(),
        hour: selectedTime.hour(),
        minute: selectedTime.minute()
      };
      let timezone = this.moment.timeZone;

      if (timezone) {
        selectedDatetime = _moment.default.tz(dateObj, timezone);
      } else {
        selectedDatetime = (0, _moment.default)(dateObj);
      }

      return selectedDatetime;
    }

    get isInvalidSelectedDatetime() {
      return (0, _moment.default)(this.selectedDatetime).isBefore((0, _moment.default)());
    }

    momentTz(m) {
      let timezone = this.moment.timeZone;

      if (timezone) {
        return m.tz(timezone);
      } else {
        return m;
      }
    }

    get canForceSchedule() {
      let userId = this.user && Ember.get(this.user, 'id') || this.session.get('engagement.coach.user.id');
      return this.account.activeUser.id === userId;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "moment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isCronofyConnected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "locationIcon", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "locationIcon"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goBack", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "goBack"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "scheduleSession", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class2.prototype, "scheduleSession"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectedDatetime", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "selectedDatetime"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isInvalidSelectedDatetime", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "isInvalidSelectedDatetime"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canForceSchedule", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "canForceSchedule"), _class2.prototype)), _class2)) || _class);
  var _default = ClSessionSchedulerDialog;
  _exports.default = _default;
});