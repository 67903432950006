define("coachlogix/pods/settings/marketplace/publish-success-dialog/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let EmployeeMarketplacePublishSuccessDialogComponent = (_dec = Ember._action, (_class = (_temp = class EmployeeMarketplacePublishSuccessDialogComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "origin", window.location.origin);
    }

    async showConfetti(element) {
      try {
        element = element.target || element;
        const confetti = await emberAutoImportDynamic("canvas-confetti");
        let {
          x,
          y,
          width,
          height
        } = element.getBoundingClientRect();
        confetti.default({
          origin: {
            x: (x + width / 2) / window.innerWidth,
            // since they fall down, start a bit higher than random
            y: (y + height / 2) / window.innerHeight
          },
          zIndex: 1000
        });
      } catch (e) {// ignore any possible confetti related error as this is purely visual
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "showConfetti", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showConfetti"), _class.prototype)), _class));
  _exports.default = EmployeeMarketplacePublishSuccessDialogComponent;
});