define("coachlogix/pods/components/v2/invite-dialog/types/client-poc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0PUlduGo",
    "block": "{\"symbols\":[\"currentStep\",\"@disabled\",\"@onClose\",\"@isSendForm\",\"@createText\",\"@title\",\"@acceptedEmails\",\"@emailNotAcceptedError\",\"@onBack\"],\"statements\":[[8,\"cl-animated-value\",[],[[\"@value\"],[[32,0,[\"step\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[8,\"v2/invite-dialog/common/search-email\",[],[[\"@disabled\",\"@title\",\"@description\",\"@acceptedEmails\",\"@excludeEmployees\",\"@emailNotAcceptedError\",\"@onClose\",\"@onBack\",\"@onNext\"],[[32,2],[30,[36,4],[[32,6],[30,[36,0],[\"ui.invite-dialog.contact-email\"],null]],null],[30,[36,0],[\"ui.invite-dialog.enter-email\"],null],[32,7],true,[32,8],[32,3],[32,9],[30,[36,6],[[30,[36,1],[[30,[36,5],[[32,0,[\"employee\"]]],null]],null],[32,0,[\"next\"]]],null]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],2],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"v2/invite-dialog/common/user-details\",[],[[\"@disabled\",\"@employee\",\"@userType\",\"@onBack\",\"@onClose\",\"@onNext\",\"@nextText\",\"@isSendForm\",\"@createText\",\"@invalidPoc\"],[[32,2],[32,0,[\"employee\"]],[30,[36,0],[\"ui.invite-dialog.user-types.poc\"],null],[32,0,[\"previous\"]],[32,3],[30,[36,1],[[32,0,[\"onSave\"]],[32,0,[\"employee\"]]],null],[30,[36,0],[\"ui.add\"],null],[32,4],[32,5],[32,0,[\"invalidPoc\"]]]],null],[2,\"\\n\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"eq\",\"if\",\"or\",\"mut\",\"queue\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/invite-dialog/types/client-poc/template.hbs"
    }
  });

  _exports.default = _default;
});