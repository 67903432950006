define("coachlogix/pods/dashboard/coaches-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HWA8YzgV",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[8,\"cl-card\",[[24,0,\"cl-flex cl-flex-col cl-p-3\"],[17,1]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h5\"],[14,0,\"cl-title-5 cl-mb-2 cl-text-center\"],[12],[2,\"Coaches\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"getData\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-p-4 cl-flex cl-items-center cl-justify-center\"],[12],[2,\"\\n      \"],[8,\"cl-progress-circular\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-flex\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-text-center cl-border-solid cl-border-0 cl-border-pale-grey cl-border-r\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cl-title-1\"],[12],[1,[30,[36,0],[[32,0,[\"summary\",\"activeCoaches\"]],0],null]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cl-title-7 cl-text-light-grey-blue\"],[12],[2,\"Engaged\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-text-center cl-border-solid cl-border-0 cl-border-pale-grey cl-border-r\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cl-title-1\"],[12],[1,[30,[36,0],[[32,0,[\"summary\",\"internalCoaches\"]],0],null]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cl-title-7 cl-text-light-grey-blue\"],[12],[2,\"Internal\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-text-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cl-title-1\"],[12],[1,[30,[36,0],[[32,0,[\"summary\",\"externalCoaches\"]],0],null]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cl-title-7 cl-text-light-grey-blue\"],[12],[2,\"External\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/dashboard/coaches-info/template.hbs"
    }
  });

  _exports.default = _default;
});