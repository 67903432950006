define("coachlogix/models/file-resource", ["exports", "@ember-data/model", "coachlogix/models/base-resource"], function (_exports, _model, _baseResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseResource.default.extend({
    description: (0, _model.attr)('string'),
    parent: (0, _model.attr)(),
    file: (0, _model.belongsTo)('file', {
      async: true,
      inverse: null
    }),
    isPreviewable: true,
    isDocument: true,
    resourceSlug: Ember.computed('resourceType', 'file', 'file.fileType', function () {
      if (this.get('file.fileType')) {
        switch (this.get('file.fileType')) {
          case 'doc':
          case 'docx':
            return 'doc';

          case 'pdf':
            return 'pdf';

          case 'xlsx':
          case 'xls':
            return 'xls';

          case 'ppt':
          case 'pptx':
            return 'ppt';

          case 'jpg':
          case 'jpeg':
          case 'bmp':
          case 'png':
            return 'image';
        }
      } else {
        return this.get('resourceType').replace(' ', '_').replace('.', '').toLowerCase();
      }
    }),
    isPDF: Ember.computed.equal('file.fileType', 'pdf'),
    isXLSX: Ember.computed.equal('file.fileType', 'xlsx'),
    isDOCX: Ember.computed.equal('file.fileType', 'docx')
  });

  _exports.default = _default;
});