define("coachlogix/pods/components/v2/engagement-template/master-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0BVdaL+9",
    "block": "{\"symbols\":[\"@item\",\"@href\",\"@isActive\",\"&attrs\"],\"statements\":[[6,[37,0],[[32,1,[\"isTask\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"v2/engagement-template/engagement-task/master-item\",[[16,0,[32,0,[\"styleNamespace\"]]],[17,4]],[[\"@item\",\"@href\",\"@isActive\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"isSession\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"v2/engagement-template/engagement-session/master-item\",[[16,0,[32,0,[\"styleNamespace\"]]],[17,4]],[[\"@item\",\"@href\",\"@isActive\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement-template/master-item/template.hbs"
    }
  });

  _exports.default = _default;
});