define("coachlogix/models/business-unit", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    childUnits: (0, _model.hasMany)('businessUnit', {
      async: true,
      inverse: null
    }),
    parent: (0, _model.belongsTo)('businessUnit', {
      async: true,
      inverse: null
    }),
    grandParent: (0, _model.belongsTo)('businessUnit', {
      async: true,
      inverse: null
    }),
    fullPath: Ember.computed('name', 'parent.{isFulfilled,name}', function () {
      let parent = this.get('parent');

      if (parent.get('id')) {
        let parentPath = parent.get('fullPath');

        if (parentPath) {
          return `${parent.get('fullPath')} : ${this.get('name')}`;
        }
      }

      return this.get('name');
    }),
    fullName: Ember.computed('grandParent.name', 'name', 'parent.name', function () {
      let fullName = this.get('name');

      if (this.get('parent.name')) {
        fullName = `${this.get('parent.name')} ${fullName}`;
      }

      if (this.get('grandParent.name')) {
        fullName = `${this.get('grandParent.name')} ${fullName}`;
      }

      return fullName;
    }),
    sortedChildren: Ember.computed('childUnits.@each.name', function () {
      return this.get('childUnits').sortBy('name');
    })
  });

  _exports.default = _default;
});