define("coachlogix/abilities/nav", ["exports", "ember-can", "ember-can/computed", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _computed, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    accountAbility: (0, _computed.ability)('account'),
    activeAccount: Ember.computed.alias('account.activeAccount'),
    accountType: Ember.computed.alias('activeAccount.accountType'),
    role: Ember.computed.alias('account.activeEmployee.role'),

    roleIsAny(...roles) {
      const role = this.get('role');
      const activeRoles = this.get('account.activeRoles') || [];
      const accountRoles = role ? [role, ...activeRoles] : activeRoles;
      return accountRoles.some(activeRole => {
        return roles.includes(activeRole);
      });
    },

    accountTypeIsAny(...accounts) {
      return accounts.includes(this.get('accountType'));
    },

    canMyProfile: Ember.computed('account.{activeAccount.accountType,activeEmployee.isCoach}', function () {
      if (this.get('account.activeAccount.accountType') === 'Independent Coach') {
        return true;
      }

      if (this.get('account.activeEmployee.isCoach')) {
        return true;
      }

      return false;
    }),
    canDashboard: Ember.computed.not('account.meRequest.redirect'),
    canOnlyOneEngagement: Ember.computed('role', 'account.activeRoles', function () {
      return !this.roleIsAny('account_global_admin');
    }),
    canEngagements: Ember.computed.not('account.isCoachee'),
    canAccountResources: Ember.computed('account.{activeAccount.accountType,activeEmployee.isCoach,isCoachee}', function () {
      if (this.get('account.isCoachee')) {
        return false;
      }

      if (this.hasObjectTypePermission('view_account_resources', 'accounts') || this.get('account.activeEmployee.isCoach')) {
        return true;
      }

      return false;
    }),
    canReports: Ember.computed('accountType', 'account.activeAccount.accountType', function () {
      if (this.accountTypeIsAny('member')) {
        return false;
      }

      if (this.hasPermission('view_account_settings')) {
        return true;
      }

      return false;
    }),

    /*
    canEngagements: computed('role', 'accountType', function() {
      if (this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.roleIsAny('account_owner', 'account_global_admin', 'location_global_admin', 'account_engagement_manager', 'coach', 'engagement_manager', 'engagement_viewer', 'account_employee') ) {
        return true;
      }
    }),
     canCompanyResources: computed('role', 'accountType', function() {
      if (this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.roleIsAny('account_owner', 'account_global_admin', 'location_global_admin', 'account_engagement_manager', 'coach', 'engagement_manager', 'engagement_viewer', 'account_employee') ) {
        return true;
      }
    }),
    */
    canEmployees: Ember.computed('role', 'accountType', function () {
      return this.accountTypeIsAny('enterprise') && this.roleIsAny('account_global_admin', 'location_global_admin');
    }),
    canVendors: Ember.computed('activeAccount.buysCoaching', 'role', 'accountType', function () {
      if (this.accountTypeIsAny('independent_coach', 'member') || !this.hasPermission('view_clients')) {
        return false;
      }

      if (this.get('activeAccount.buysCoaching')) {
        return true;
      }

      return false;
    }),
    canCoaches: Ember.computed('role', 'accountType', 'accountAbility.{canAddInternalCoaches,canBuyCoaching}', function () {
      return this.accountTypeIsAny('business', 'enterprise') && this.hasPermission('view_coaches') && (this.get('accountAbility.canAddInternalCoaches') || this.get('accountAbility.canBuyCoaching'));
    }),
    canClients: Ember.computed('role', 'accountType', 'activeAccount.providesCoaching', function () {
      return this.get('activeAccount.providesCoaching') && this.hasPermission('view_clients');
    }),
    canInvoices: Ember.computed('permissions.[]', 'activeAccount.{buysCoaching,providesCoaching}', 'account.isDirectCoachee', function () {
      if (this.get('account.isDirectCoachee')) {
        return false;
      }

      if (this.get('activeAccount.buysCoaching') && this.hasPermission('view_invoices')) {
        return true;
      }

      if (this.get('activeAccount.providesCoaching') && this.hasPermission('view_invoices')) {
        return true;
      }
    }),
    canResources: false,
    canSettings: Ember.computed('role', 'accountType', function () {
      return this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.hasPermission('view_account_settings');
    }),
    canProgramTemplates: Ember.computed('role', 'accountType', function () {
      return this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.hasObjectTypePermission('manage_program_templates', 'accounts');
    }),
    canSupport: true
  });

  _exports.default = _default;
});