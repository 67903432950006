define("coachlogix/pods/dashboard/billable-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vzbocMxp",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[8,\"v2/summary-cards/billable-activity\",[[17,1]],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/dashboard/billable-activity/template.hbs"
    }
  });

  _exports.default = _default;
});