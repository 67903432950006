define("coachlogix/pods/reports/report/filter/component", ["exports", "@ember-decorators/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ReportFilterComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = (_class2 = (_temp = class ReportFilterComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "statusFilter", 'all');
    }

    updateStringFilterSelection(filter, value) {
      if (!value) this.selectedFilters.set(filter.name, null);
      this.selectedFilters.set(filter.name, {
        operation: 'contains',
        value
      });
    }

    selectRange(range) {
      this.set('selectedRange', range);
      let {
        start,
        end
      } = range;

      if (start && end) {
        this.selectedFilters.set('start_date', {
          operation: 'greater',
          value: (0, _moment.default)(start).format('YYYY-MM-DD')
        });
        this.selectedFilters.set('end_date', {
          operation: 'lesser',
          value: (0, _moment.default)(end).format('YYYY-MM-DD')
        });
      }
    }

    updateEnumFilterSelection(filter, option, value) {
      if (!value) {
        this.selectedFilters.set(filter.name, null);
      } else {
        this.selectedFilters.set(filter.name, {
          operation: 'equals',
          value: option.value
        });
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "updateStringFilterSelection", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "updateStringFilterSelection"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectRange", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "selectRange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateEnumFilterSelection", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "updateEnumFilterSelection"), _class2.prototype)), _class2)) || _class);
  var _default = ReportFilterComponent;
  _exports.default = _default;
});