define("coachlogix/pods/settings/basic-info/company-info-card/component", ["exports", "@ember-decorators/component", "ember-changeset", "ember-changeset-validations", "ember-concurrency", "ember-concurrency-decorators", "tracked-toolbox", "coachlogix/validations/account"], function (_exports, _component, _emberChangeset, _emberChangesetValidations, _emberConcurrency, _emberConcurrencyDecorators, _trackedToolbox, _account) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompanyInfoCardComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('editState', 'account.activeAccount', 'changeset'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec(_class = (_class2 = (_temp = class CompanyInfoCardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "editState", false);

      _defineProperty(this, "changeset", null);
    }

    get activeAccount() {
      return this.editState ? this.changeset : this.account.activeAccount;
    }

    enterEditState() {
      this.set('changeset', new _emberChangeset.Changeset(this.account.activeAccount, (0, _emberChangesetValidations.default)(_account.default), _account.default));
      this.changeset.validate();
      this.set('editState', true);
    }

    *saveChanges() {
      yield this.changeset.save();
      this.set('editState', false);
    }

    discardChanges() {
      this.changeset.rollback();
      this.set('editState', false);
    }

    *searchUsers(term) {
      yield (0, _emberConcurrency.timeout)(500);
      let employees = yield this.store.query('employee', {
        include: 'user',
        filter: {
          account_id: this.account.activeAccount.id,
          search: term
        }
      });
      return employees.mapBy('user');
    }

    get countries() {
      return this.store.findAll('country');
    }

    get selectedCountry() {
      return this.countries.find(c => c.iso31662 === this.activeAccount.country);
    } // This needs to be placed here because changesets don't support computed properties


    get fullLocation() {
      let properties = ['city', 'state'];
      return properties.map(p => this.activeAccount[p]).filter(Boolean).join(', ');
    } // This needs to be placed here because changesets don't proxy method calls to their wrapping object correctly


    setLocation(place) {
      if (Ember.isPresent(place)) {
        // a place was deselected
        for (let a of place.address_components) {
          if (a.types.includes('country')) {
            this.changeset.set('country', a.short_name);
          } else if (a.types.includes('locality')) {
            this.changeset.set('city', a.long_name);
          } else if (a.types.includes('administrative_area_level_1')) {
            this.changeset.set('state', a.long_name);
          } else if (a.types.includes('postal_code')) {
            this.changeset.set('postalCode', a.long_name);
          }
        }
      } else {
        // place was deselected
        this.changeset.set('city', null);
        this.changeset.set('state', null);
        this.changeset.set('postalCode', null);
      }
    }

    setCountry(country) {
      this.changeset.set('country', country ? country.iso31662 : null);
      this.changeset.set('locationInfo', null);
      this.changeset.set('city', null);
      this.changeset.set('state', null);
      this.changeset.set('postalCode', null);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "activeAccount", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "activeAccount"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "enterEditState", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "enterEditState"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "discardChanges", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "discardChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "searchUsers", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "searchUsers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "countries", [_trackedToolbox.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "countries"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setLocation", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "setLocation"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setCountry", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "setCountry"), _class2.prototype)), _class2)) || _class);
  var _default = CompanyInfoCardComponent;
  _exports.default = _default;
});