define("coachlogix/serializers/coaching-request", ["exports", "coachlogix/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CoachingRequestSerializer extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", { // eslint-disable-next-line ember/no-attrs-in-components
        ...this.attrs,
        // the backend team asked to not send this relationship
        // because it was causing an error. Since it is read-only relationship
        // for the frontend, it doesn't affect anything.
        programItem: {
          serialize: false
        }
      });
    }

  }

  _exports.default = CoachingRequestSerializer;
});