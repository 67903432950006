define("coachlogix/abilities/settings", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    account: Ember.inject.service(),
    canInvoicing: Ember.computed.reads('account.activeAccount.providesCoaching'),
    canPayments: Ember.computed.reads('account.activeAccount.buysCoaching'),
    canBilling: Ember.computed.or('account.activeAccount.{buysCoaching,providesCoaching}')
  });

  _exports.default = _default;
});