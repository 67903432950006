define("coachlogix/pods/components/v2/engagement/engagement-session/master-item/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let SessionMasterItem = (_dec = (0, _component.tagName)(''), _dec(_class = class SessionMasterItem extends Ember.Component {}) || _class);
  var _default = SessionMasterItem;
  _exports.default = _default;
});