define("coachlogix/abilities/invoice", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    account: Ember.inject.service(),
    canEdit: Ember.computed('account.activeAccount.id', 'model.{isDraft,isSender}', function () {
      let invoice = this.get('model');

      if (invoice.belongsTo('contract').id()) {
        let contract = invoice.get('contract.content') || invoice.get('contract');
        let isCreator = contract.belongsTo('account').id() === this.get('account.activeAccount.id');
        return invoice.get('isDraft') && invoice.get('isSender') && isCreator;
      } else {
        return invoice.get('isDraft') && invoice.get('isSender');
      }
    }),
    canSend: Ember.computed('model.{isDraft,isSender}', function () {
      let invoice = this.get('model');
      return invoice.get('isDraft') && invoice.get('isSender');
    }),
    canResend: Ember.computed('model.{isDraft,isPaid,isSender}', function () {
      let invoice = this.get('model');
      return !invoice.get('isDraft') && !invoice.get('isPaid') && invoice.get('isSender');
    }),
    canPay: Ember.computed('model.{isClient,payViaStripe,total,isPaid,clientIsPaid}', function () {
      let invoice = this.get('model');
      return invoice.get('isClient') && invoice.get('payViaStripe') && invoice.get('total') > 0 && !invoice.get('isPaid') && !invoice.get('clientIsPaid');
    }),
    // this is a special feature
    canMarkAsPaid: Ember.computed('account.isEnterprise', 'model.{isSender,isClient,isSent,isPartialPaid,isClientMarkedPartialPaid}', function () {
      let invoice = this.model;

      if (invoice.isSent || invoice.isPartialPaid || invoice.isClientMarkedPartialPaid) {
        if (invoice.isSender) {
          return true;
        }

        if (this.account.isEnterprise && invoice.isClient) {
          return true;
        }
      }

      return false;
    }),
    canAddPayment: Ember.computed('model.{isSent,isPartialPaid,total}', function () {
      let invoice = this.model;
      return (invoice.isSent || invoice.isPartialPaid) && invoice.total > 0;
    }),
    canDelete: Ember.computed('model.isSender', function () {
      let invoice = this.get('model');
      return invoice.get('isSender');
    })
  });

  _exports.default = _default;
});