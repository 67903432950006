define("coachlogix/pods/outside/form/responses/route", ["exports", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormResponsesRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec7 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class FormResponsesRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clTheme", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "account", _descriptor3, this);

      _initializerDefineProperty(this, "native", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);
    }

    async beforeModel() {
      // try to load a token from the mobile app
      try {
        let token = await this.native.getToken();
        this.ajax.set('tempToken', token);
      } catch (e) {
        // ignore error
        return;
      } finally {
        await this.account.loadInitialData.perform();
      }
    }

    model({
      task_id
    }) {
      let taskTask = this.getTask.perform(task_id);
      return {
        task: taskTask,
        submissions: this.getSubmissions.perform(taskTask)
      };
    }

    *getTask(taskId) {
      let include = ['assignments.assignee.user', 'assignments.form-submissions', 'resource.form-questions.form-question-options', 'resource.form-sections', 'engagement.owner-account', 'engagement.coachee.user', 'engagement.coach.user', 'engagement'].join(',');
      return yield this.store.findRecord('task', taskId, {
        include,
        reload: true
      });
    }

    *getSubmissions(task) {
      task = yield task;
      let assignments = yield task.get('assignments');
      let submissions = yield (0, _emberConcurrency.all)(assignments.mapBy('formSubmissions'));
      return submissions.reduce((prev, submissions) => {
        return prev.concat(submissions.toArray());
      }, []).reject(s => !s);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clTheme", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "native", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getTask", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "getTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getSubmissions", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "getSubmissions"), _class.prototype)), _class));
  _exports.default = FormResponsesRoute;
});