define("coachlogix/models/form-answer", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    answerJson: (0, _model.attr)('array', {
      defaultValue: () => []
    }),
    submission: (0, _model.belongsTo)('form-submission', {
      inverse: 'answers'
    }),
    question: (0, _model.belongsTo)('form-question'),
    sorOrder: Ember.computed.reads('question.sortOrder'),
    isRequired: Ember.computed.reads('question.isRequired'),
    hasAnswer: Ember.computed.bool('answerJson.length'),
    answerAsText: Ember.computed('answerJson.[]', function () {
      return this.get('answerJson').join(', ');
    })
  });

  _exports.default = _default;
});