define("coachlogix/pods/components/v2/resources/create-folder-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SJPpmkGt",
    "block": "{\"symbols\":[\"dialog\",\"&attrs\",\"@onClose\"],\"statements\":[[8,\"cl-dialog\",[[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"cl-title-3 cl-flex-1\"],[12],[1,[30,[36,0],[\"resources.new-folder\"],null]],[13],[2,\"\\n    \"],[8,\"cl-button\",[],[[\"@iconButton\",\"@disabled\",\"@onClick\"],[true,[32,0,[\"createFolder\",\"isRunning\"]],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"close\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,\"cl-input\",[[16,\"placeholder\",[30,[36,0],[\"ui.name\"],null]],[16,\"disabled\",[32,0,[\"createFolder\",\"isRunning\"]]]],[[\"@solid\",\"@value\",\"@onChange\"],[true,[32,0,[\"name\"]],[30,[36,2],[[30,[36,1],[[32,0,[\"name\"]]],null]],null]]],null],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-button\",[],[[\"@raised\",\"@disabled\",\"@onClick\"],[true,[32,0,[\"createFolder\",\"isRunning\"]],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"ui.cancel\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"cl-button\",[],[[\"@raised\",\"@primary\",\"@disabled\",\"@onClick\"],[true,true,[30,[36,4],[[30,[36,3],[[32,0,[\"name\"]]],null],[32,0,[\"createFolder\",\"isRunning\"]]],null],[30,[36,5],[[32,0,[\"createFolder\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"ui.create\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"fn\",\"not\",\"or\",\"perform\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/resources/create-folder-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});