define("coachlogix/pods/form/form-submission/question/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IPw/P8hb",
    "block": "{\"symbols\":[\"&attrs\",\"@answer\",\"@disabled\",\"@viewMode\"],\"statements\":[[11,\"div\"],[24,0,\"cl-p-4\"],[17,1],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"cl-title-4 cl-mb-3\"],[12],[2,\"\\n\"],[2,\"    \"],[1,[32,2,[\"question\",\"questionText\"]]],[2,\"\\n\"],[6,[37,0],[[32,2,[\"question\",\"isRequired\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"cl-text-danger\"],[12],[2,\"*\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[8,\"cl-input\",[[24,\"rows\",\"1\"],[24,\"placeholder\",\"Your answer\"],[24,0,\"cl-font-body cl-text-lg\"],[16,\"disabled\",[32,3]]],[[\"@line\",\"@textarea\",\"@value\",\"@onChange\",\"@editable\"],[true,true,[32,0,[\"value\"]],[32,0,[\"setValue\"]],[30,[36,1],[[32,4]],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"not\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/form/form-submission/question/text/template.hbs"
    }
  });

  _exports.default = _default;
});