define("coachlogix/pods/invoices/components/create-dialog/after-options/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let InvoicesCreateDialogAfterOptions = (_dec = (0, _component.tagName)(''), _dec(_class = class InvoicesCreateDialogAfterOptions extends Ember.Component {}) || _class);
  var _default = InvoicesCreateDialogAfterOptions;
  _exports.default = _default;
});