define("coachlogix/pods/engagements/controller", ["exports", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EngagementsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = (_temp = class EngagementsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _defineProperty(this, "statusFilter", null);

      _defineProperty(this, "filters", {});
    }

    filterByStatus(status) {
      this.statusFilter = status;
      this.tableApi.reloadData();
    }

    *loadData({
      paginationData,
      sortData,
      filterData
    }) {
      yield (0, _emberConcurrency.timeout)(600);
      let sort;

      if (sortData.length) {
        let sortName = sortData[0].prop;
        let sortPrefix = sortData[0].direction === 'desc' ? '-' : '';
        sort = `${sortPrefix}${sortName}`;
      }

      let filter = {};

      if (filterData.filter) {
        filter.search = filterData.filter;
      }

      if (this.statusFilter && this.statusFilter !== 'all') {
        if (this.statusFilter === 'draft') {
          filter.status = ['draft', 'draft_matching'];
        } else {
          filter.status = this.statusFilter;
        }
      }

      let include = ['coachee-view', 'coach-view', 'engagement-summary', 'engagement-template'].join(',');
      let page = {
        size: paginationData.pageSize,
        number: paginationData.pageNumber
      };
      let engagements = yield this.store.query('engagement', {
        page,
        include,
        sort,
        filter,
        include_metadata: 1
      });
      this.set('totalRows', engagements.meta.page.total);
      this.set('meta', engagements.meta);
      this.set('filters', filter);
      return engagements;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filterByStatus", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "filterByStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadData", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadData"), _class.prototype)), _class));
  _exports.default = EngagementsController;
});