define("coachlogix/pods/components/cl-chip-filters/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ir5z+mts",
    "block": "{\"symbols\":[\"o\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex cl-flex-col cl-space-y-2\"],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-flex-col cl-max-h-24 cl-overflow-auto cl-p-3\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"cl-checkbox\",[],[[\"@value\",\"@onChange\"],[[32,1,[\"isSelected\"]],[30,[36,2],[[30,[36,1],[[30,[36,0],[[32,1,[\"isSelected\"]]],null]],null],[32,0,[\"handleApply\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"queue\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cl-chip-filters/select/template.hbs"
    }
  });

  _exports.default = _default;
});