define("coachlogix/transforms/object", ["exports", "@ember-data/serializer/transform", "jquery"], function (_exports, _transform, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize(value) {
      if (value && typeof value === 'string') {
        try {
          return JSON.parse(value);
        } catch (e) {
          return null;
        }
      } else {
        return value;
      }
    },

    serialize(value) {
      if (!_jquery.default.isPlainObject(value)) {
        return undefined;
      } else {
        return value;
      }
    }

  });

  _exports.default = _default;
});