define("coachlogix/pods/components/v2/engagement/create-dialog/select-matching-settings-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "erCG/K3P",
    "block": "{\"symbols\":[\"matchingSetting\",\"@onClose\",\"@onSelect\",\"@optional\",\"&attrs\"],\"statements\":[[8,\"cl-selection-dialog\",[[24,0,\"cl-w-32\"],[17,5]],[[\"@title\",\"@onClose\",\"@closeOnSelect\",\"@options\",\"@required\",\"@search\",\"@searchField\",\"@onChange\",\"@noOptionsText\",\"@auxButtonClick\",\"@auxButtonLabel\"],[[30,[36,0],[\"engagements.create-workflow.matching-settings-step-title\"],null],[32,2],false,[32,0,[\"matchingSettings\"]],true,true,[30,[36,1],[\"name\",\"description\"],null],[32,3],[30,[36,0],[\"engagements.create-workflow.no-matching-settings-found\"],null],[30,[36,3],[[32,4],[30,[36,2],[[32,3],null],null]],null],[30,[36,0],[\"ui.skip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"engagement.settings.last-modified-at\"],[[\"date\"],[[30,[36,4],[[32,1,[\"updatedAt\"]],\"ll\"],null]]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"fn\",\"if\",\"moment-format\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement/create-dialog/select-matching-settings-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});