define("coachlogix/pods/engagement/matching-process/shared-coaches/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MatchingProcessSharedCoachesController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.reads('model.engagement.value'), _dec4 = Ember.computed.reads('model.coachPoolCoachesTask.value'), _dec5 = Ember.computed.reads('engagement.matchingSettings.coachPoolsWelcomeMessage'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, (_class = (_temp = class MatchingProcessSharedCoachesController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "twilio", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _defineProperty(this, "queryParams", ['filter']);

      _initializerDefineProperty(this, "engagement", _descriptor3, this);

      _initializerDefineProperty(this, "coachPoolCoaches", _descriptor4, this);

      _initializerDefineProperty(this, "welcomeMessage", _descriptor5, this);

      _initializerDefineProperty(this, "filter", _descriptor6, this);

      _initializerDefineProperty(this, "filters", _descriptor7, this);

      _defineProperty(this, "selectedCoach", void 0);

      _initializerDefineProperty(this, "filterOptions", _descriptor8, this);

      _initializerDefineProperty(this, "moreCoaches", _descriptor9, this);

      _initializerDefineProperty(this, "isLoading", _descriptor10, this);
    }

    get appliedFilters() {
      return Ember.isPresent(this.filter) ? JSON.parse(this.filter) : {};
    }

    applyFilters(filtersObj) {
      this.filter = JSON.stringify(filtersObj);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "twilio", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "engagement", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "coachPoolCoaches", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "welcomeMessage", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '{}';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filterOptions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "moreCoaches", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "applyFilters", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "applyFilters"), _class.prototype)), _class));
  _exports.default = MatchingProcessSharedCoachesController;
});