define("coachlogix/pods/components/v2/employee/inactivate-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u0Bpy5vx",
    "block": "{\"symbols\":[\"@title\",\"@onClose\"],\"statements\":[[8,\"cl-confirmation-dialog\",[],[[\"@title\",\"@onClose\",\"@onConfirm\"],[[32,1],[32,2],[30,[36,0],[[32,0,[\"handleDisconnect\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[12],[1,[30,[36,1],[\"employee.inactivate-dialog.inactivate-confirmation\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"cl-checkbox\",[],[[\"@value\",\"@onChange\"],[[32,0,[\"removeEngagements\"]],[30,[36,0],[[30,[36,2],[[32,0,[\"removeEngagements\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[\"employee.inactivate-dialog.remove-engagements\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"t\",\"mut\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/employee/inactivate-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});