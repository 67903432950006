define("coachlogix/pods/outside/login/route", ["exports", "ember-paper/utils/generate-palette", "ember-paper/utils/palettes", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _generatePalette, _palettes, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-mixins
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    auth0: Ember.inject.service(),
    ajax: Ember.inject.service(),
    paperTheme: Ember.inject.service(),
    domainMasking: Ember.inject.service(),

    beforeModel(transition) {
      // if we are authenticated and have a mimic token, we invalidade the session
      // this will refresh the browser, but that's ok because mimicAuthenticate
      // will still run and ember-simple-auth will pick the session when the app boots
      // back up
      if (this.get('session.isAuthenticated') && transition.to.queryParams.mimic_token) {
        this.get('session').invalidate();
        return;
      }

      this._super(...arguments);
    },

    installTheme({
      primary = '#5e1de1',
      accent = '#02dc94'
    }) {
      let theme = {
        primary: (0, _generatePalette.default)(primary),
        accent: (0, _generatePalette.default)(accent),
        warn: _palettes.default.red
      };
      this.get('paperTheme').installTheme('account', theme);
    },

    async model({
      mimic_token,
      sso_token,
      expires_in,
      issued_at
    }) {
      if (mimic_token) {
        this.mimicAuthenticate(mimic_token);
      }

      if (sso_token) {
        this.ssoAuthenticate(sso_token, expires_in, issued_at);
      }

      let data = {};

      try {
        data = await this.domainMasking.getData();
        this.installTheme({
          primary: data.primary_color,
          accent: data.accent_color
        });
      } catch (e) {// ignore if not found
      }
    },

    mimicAuthenticate(token, expiresIn = 86400, issuedAt = Math.ceil(Date.now() / 1000)) {
      this.get('session').authenticate('authenticator:test', {
        idToken: token,
        expiresIn,
        issuedAt,
        mimicSession: true
      });
    },

    ssoAuthenticate(token, expiresIn = 86400, issuedAt = Math.ceil(Date.now() / 1000)) {
      this.get('session').authenticate('authenticator:test', {
        idToken: token,
        expiresIn,
        issuedAt
      });
    },

    _getUrlHashData() {
      const auth0 = this.get('auth0')._getAuth0Instance();

      const enableImpersonation = !!this.get('auth0.config.enableImpersonation');
      return new Ember.RSVP.Promise((resolve, reject) => {
        auth0.parseHash({
          __enableImpersonation: enableImpersonation
        }, (err, parsedPayload) => {
          if (err) {
            return reject(new Error(err));
          }

          resolve(parsedPayload);
        });
      });
    },

    openLogin() {
      let controller = this.controller;
      let {
        open
      } = this.paramsFor('outside.login');
      open = open || 'login'; // url should already be updated on the afterRender queue.
      // auth0 login modal needs to be opened only when the url
      // is /login as per defined on auth0 dashboard.

      Ember.run.scheduleOnce('afterRender', () => {
        controller.send(open);
      });
    },

    deactivate() {
      this.didOpen = false;
      this.auth0.hide();
    },

    actions: {
      didTransition() {
        if (this.didOpen) {
          return;
        } // only open login window if there's no auth0 hash
        // this has is used on SSO


        this._getUrlHashData().then(urlHashData => {
          if (Ember.isEmpty(urlHashData)) {
            this.openLogin();
          }
        }).catch(() => {
          this.openLogin();
        }).finally(() => {
          this.didOpen = true;
        });
      }

    }
  });

  _exports.default = _default;
});