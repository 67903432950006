define("coachlogix/pods/components/v2/resources/resource-selection-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourceSelectionDialogComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed('accept'), _dec(_class = (_class2 = (_temp = class ResourceSelectionDialogComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "accept", 'form file');
    }

    get acceptedTypes() {
      return this.accept.split(' ').map(s => s.trim());
    }

    *getResources(searchTerm, pageNumber, pageSize) {
      let page = {
        size: pageSize,
        number: pageNumber
      };
      let filter = {
        resource_type: this.acceptedTypes.join(','),
        search: searchTerm
      };
      let options = {
        filter,
        page
      };

      if (this.sharableResourcesOnly) {
        filter['is_root'] = true;
        options['include'] = 'owner';
      }

      let fetchResourcesPromise = this.store.query('resource', options);

      if (this.acceptedTypes.includes('resource-group')) {
        let fetchResourceGroupsPromise = this.store.query('resource-group', {});
        let [resourceGroups, resources] = yield (0, _emberConcurrency.all)([fetchResourceGroupsPromise, fetchResourcesPromise]);
        return resourceGroups.toArray().concat(resources.toArray());
      } else {
        let resources = yield fetchResourcesPromise;
        return resources.toArray();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "acceptedTypes", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "acceptedTypes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getResources", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "getResources"), _class2.prototype)), _class2)) || _class);
  var _default = ResourceSelectionDialogComponent;
  _exports.default = _default;
});