define("coachlogix/models/twilio-identity", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    twilioIdentity: (0, _model.attr)('string'),
    twilioSid: (0, _model.attr)('string'),
    twilioable: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      inverse: null
    })
  });

  _exports.default = _default;
});