define("coachlogix/pods/engagement/route", ["exports", "ember-ajax/errors", "ember-concurrency-decorators"], function (_exports, _errors, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EngagementRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec6 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class EngagementRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "topbar", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "account", _descriptor4, this);
    }

    activate() {
      super.activate(...arguments);
      this.topbar.setBackground();
    }

    model({
      engagement_id
    }) {
      let engagementsTask = this.loadEngagements.perform();
      return {
        engagementsTask,
        engagementTask: this.loadEngagement.perform(engagement_id, engagementsTask)
      };
    }

    *loadEngagements() {
      if (this.account.isCoachee) {
        let engagements = yield this.store.query('engagement', {
          include: 'coach.user,coach.employee,coachee.user,coachee-view,coach-view,engagement-summary,engagement-template,owner-account'
        });
        return engagements.sortBy('isMatching').reverse();
      } else {
        return [];
      }
    }

    *loadEngagement(id, engagementsTask) {
      yield engagementsTask;

      try {
        let include = ['coach.user', 'coach.user.availability-preferences', 'coach.user.cronofy-connected-account', 'coach.user.timezone', 'coachee.user', 'coach.account', 'admin-resources', 'engagement-summary', 'coaching-requests', 'coaching-requests.coachee', 'coaching-requests.coachee.user', 'coaching-requests.coach', 'coaching-requests.coach.user', 'comments', 'engagement-template', 'program-items', 'goals', 'goals.reviews', 'program-items.itemable', 'program-items.itemable.participants.user', 'next-scheduled-session.meeting-location', 'next-scheduled-session.participants.user', 'next-session-to-schedule.meeting-location', 'next-session-to-schedule.participants.user', 'client', 'matching-settings', 'owner-account'].join(',');
        let engagement = yield this.store.findRecord('engagement', id, {
          reload: true,
          include
        });
        yield this.maybeRedirect(engagement);
        return engagement;
      } catch (e) {
        if ((0, _errors.isForbiddenError)(e)) {
          this.router.replaceWith('engagements');
        }

        if ((0, _errors.isNotFoundError)(e)) {
          this.transitionTo('not-found');
        }
      }
    }

    onMatchingProcessCompleted() {
      this.refresh();
    }

    async maybeRedirect(engagement) {
      if (engagement && (engagement.isMatching || engagement.isDraftMatching)) {
        if (engagement.belongsTo('coachee').id() === this.account.activeEmployee.id) {
          let coachingRequests = await engagement.get('coachingRequests');
          let selectedRequest = coachingRequests.find(c => c.isSelected || c.isCoachApproved || c.isCoachPending); // only transition to the routes if the current route isn't already correct, otherwise we're transitioning
          // twice, requesting two times the same model

          if (selectedRequest && !this.router.currentRouteName.includes('engagement.matching-process-selection')) {
            this.router.replaceWith('engagement.matching-process-selection', engagement.id, selectedRequest.id);
          } else if (!this.router.currentRouteName.includes('engagement.matching-process')) {
            this.router.transitionTo('engagement.matching-process', engagement.id);
          }
        } else if (this.router.currentRouteName === 'engagement.index') {
          this.router.transitionTo('engagement.matching-process-coaches', engagement.id);
        }
      } // if not matching, let the normal transition to index (engagement dashboard) take place

    } // we need this hacky workaround, because in ember transitions to routes *with same model*
    // do not trigger the model hook. So the maybeRedirect was never triggered in that case.
    // Coachees in a matching-selection route can click on it's own engagement,
    // and transition to the engagement index instead


    async didTransition() {
      if (this.router.currentRouteName === 'engagement.matching-process-selection') {
        let engagement = await this.modelFor('engagement').engagementTask;
        this.maybeRedirect(engagement);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "topbar", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadEngagements", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loadEngagements"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadEngagement", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadEngagement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMatchingProcessCompleted", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onMatchingProcessCompleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class));
  _exports.default = EngagementRoute;
});