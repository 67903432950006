define("coachlogix/abilities/engagement-session", ["exports", "ember-can", "moment", "coachlogix/abilities/engagement-permission-helpers"], function (_exports, _emberCan, _moment, _engagementPermissionHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_engagementPermissionHelpers.default, {
    account: Ember.inject.service(),
    clock: Ember.inject.service(),
    engagement: Ember.computed.reads('model.engagement'),
    session: Ember.computed.reads('model'),
    isComplete: Ember.computed.reads('model.status', 'completed'),
    coachSharedAvailability: Ember.computed.reads('model.engagement.coach.user.isCronofyConnected'),
    canCoacheeSchedule: Ember.computed('isCoachee', 'engagementStatus', 'coachSharedAvailability', function () {
      let accountIsCoachee = this.isCoachee;
      let engagementIsActive = this.engagementStatus === 'active';
      let coachSharedCalendar = this.coachSharedAvailability;
      return accountIsCoachee && engagementIsActive && coachSharedCalendar;
    }),
    canSeeCoacheeScheduleWarning: Ember.computed('isCoachee', 'engagementStatus', 'coachSharedAvailability', function () {
      let accountIsCoachee = this.isCoachee;
      let engagementIsActive = this.engagementStatus === 'active';
      let coachSharedCalendar = this.coachSharedAvailability;
      return accountIsCoachee && engagementIsActive && !coachSharedCalendar;
    }),
    canEdit: Ember.computed('roles.[]', 'engagementStatus', 'status', function () {
      return this.engagementStatusIsAny('active', 'draft', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager');
    }),
    canManageAssignees: Ember.computed('engagementStatus', 'isFromTemplate', 'model', 'roles.[]', 'status', function () {
      return this.engagementStatusIsAny('active', 'draft', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager', 'coach');
    }),
    canEditTitle: Ember.computed.reads('canEdit'),
    canEditDescription: Ember.computed.reads('canEdit'),
    canDuplicate: Ember.computed.reads('canEdit'),
    canDelete: Ember.computed.reads('canEdit'),
    canEditActionItems: Ember.computed('roles.[]', 'engagementStatus', function () {
      return this.engagementStatusIsAny('active', 'draft', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager', 'coach', 'coachee');
    }),
    canEditCompletedDate: Ember.computed('roles.[]', 'engagementStatus', 'status', function () {
      return this.engagementStatusIsAny('active') && this.statusIsAny('completed') && this.roleIsAny('admin', 'manager', 'coach');
    }),
    canEditScheduledDuration: Ember.computed('roles.[]', 'engagementStatus', 'status', function () {
      return this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager');
    }),
    canEditCompletedDuration: Ember.computed.reads('canEditScheduledDuration'),
    canEditLocation: Ember.computed('roles.[]', 'engagementStatus', function () {
      if (this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager')) {
        return true;
      }

      if (this.engagementStatusIsAny('active') && this.roleIsAny('coach')) {
        return true;
      }

      return false;
    }),
    canEditNotifications: Ember.computed('engagementStatus', 'isCoach', 'roles.[]', 'status', function () {
      return this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && this.statusIsAny('draft', 'scheduled', 'schedule_request', 'reschedule_request', 'request_reschedule', 'unscheduled') && (this.roleIsAny('admin', 'manager', 'coach') || this.get('isCoach'));
    }),
    canManagePeople: Ember.computed('roles.[]', 'engagementStatus', function () {
      if (this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager')) {
        return true;
      }

      if (this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'coach')) {
        return true;
      }

      return false;
    }),
    canSchedule: Ember.computed('roles.[]', 'engagementStatus', 'status', 'coachSharedAvailability', function () {
      if (this.statusIsAny('draft', 'schedule_request', 'reschedule_request', 'request_reschedule', 'unscheduled') && this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach')) {
        return true;
      }

      if (this.roleIsAny('coachee') && this.engagementStatusIsAny('active') && this.get('coachSharedAvailability') && this.statusIsAny('draft', 'schedule_request', 'reschedule_request', 'request_reschedule', 'unscheduled')) {
        return true;
      }

      return false;
    }),
    canSendScheduleRequest: Ember.computed('roles.[]', 'engagementStatus', 'status', 'coachSharedAvailability', function () {
      return this.statusIsAny('draft') && this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach') && this.get('coachSharedAvailability');
    }),
    canSendRescheduleRequest: Ember.computed('roles.[]', 'engagementStatus', 'status', 'coachSharedAvailability', function () {
      return this.statusIsAny('draft', 'schedule_request', 'reschedule_request', 'request_reschedule') && this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach') && this.get('coachSharedAvailability');
    }),
    canReschedule: Ember.computed('coachSharedAvailability', 'engagement.{coach.user.availabilityPreferences.cancellationHours,isCoachee}', 'engagementStatus', 'roles.[]', 'session.scheduledDate', 'status', function () {
      const cancellationHours = this.get('engagement.coach.user.availabilityPreferences.cancellationHours');
      const scheduledDate = (0, _moment.default)(this.get('session.scheduledDate'));
      const cancellationDeadline = scheduledDate.subtract(cancellationHours, 'hours');
      const isCoachee = this.get('engagement.isCoachee');

      if ((0, _moment.default)().isAfter(cancellationDeadline) && isCoachee) {
        return false;
      }

      if (this.statusIsAny('scheduled', 'interview_scheduled') && this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach')) {
        return true;
      }

      if (this.roleIsAny('coachee') && this.engagementStatusIsAny('active') && this.get('coachSharedAvailability') && this.statusIsAny('scheduled')) {
        return true;
      }

      return false;
    }),
    canComplete: Ember.computed('accountIsCoach', 'engagementStatus', 'role', 'roles.[]', 'status', function () {
      return this.engagementStatusIsAny('active') && this.role !== 'viewer' && (this.roleIsAny('admin', 'manager', 'coach') || this.get('accountIsCoach')) && this.statusIsAny('draft', 'scheduled', 'schedule_request', 'reschedule_request', 'request_reschedule', 'unscheduled', 'completed');
    }),
    canRecomplete: Ember.computed('accountIsCoach', 'engagementStatus', 'role', 'roles.[]', 'status', function () {
      return this.engagementStatusIsAny('active') && this.role !== 'viewer' && (this.roleIsAny('admin', 'manager', 'coach') || this.get('accountIsCoach')) && this.statusIsAny('completed');
    }),
    canLateCancel: Ember.computed.reads('canComplete'),
    canManageTasks: Ember.computed('roles.[]', 'engagementStatus', function () {
      return this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager', 'coach') && this.statusIsAny('draft', 'scheduled', 'completed', 'schedule_request', 'reschedule_request', 'request_reschedule', 'unscheduled');
    }),
    canManageNotes: Ember.computed('roles.[]', 'engagementStatus', function () {
      return this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach', 'coachee');
    }),
    canComment: Ember.computed('roles.[]', 'engagementStatus', function () {
      return this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach', 'coachee', 'viewer');
    }),
    canViewComments: Ember.computed('engagementStatus', function () {
      return this.engagementStatusIsAny('active', 'inactive', 'completed');
    }),
    canUseVideo: Ember.computed('clock.minute', 'model.{meetingLocation.isInAppVideo,isScheduled,scheduledDate,defaultTimeUnit,duration,isMarketplace}', 'engagementStatus', function () {
      let scheduledDate = (0, _moment.default)(this.model.scheduledDate);
      let startTime = scheduledDate.clone().subtract(15, 'minutes');
      let endTime = scheduledDate.clone().add(this.model.duration, this.model.defaultTimeUnit);
      let isBetween = (0, _moment.default)().isBetween(startTime, endTime);
      return this.model.isScheduled && this.model.get('meetingLocation.isInAppVideo') && (this.engagementStatusIsAny('active', 'coach_selection') || this.model.isMarketplace) && isBetween;
    }),
    canStartVideo: Ember.computed.and('canUseVideo', 'isCoach'),
    canJoinVideo: Ember.computed('canUseVideo', 'model.{modelName,participants}', 'account.activeEmployee.id', 'isCoach', function () {
      let participantIds; // the coaching-request model is *practically* identical to an engagement-session
      // it doesn't have a participants relationship, but it has a computed property for it.
      // with this little condition, we can account for that little difference.

      if (this.model.modelName === 'coaching-request') {
        participantIds = this.model.participants.map(p => p.get('id')) || [];
      } else {
        participantIds = this.model.hasMany('participants').ids() || [];
      }

      let isParticipant = participantIds.includes(this.account.activeEmployee.id);
      return this.canUseVideo && isParticipant && !this.isCoach;
    })
  });

  _exports.default = _default;
});