define("coachlogix/pods/vendors/controller", ["exports", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VendorsController = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class VendorsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "vendorTypeFilter", null);

      _defineProperty(this, "filters", {});
    }

    filterByType(type) {
      this.vendorTypeFilter = type;
      this.tableApi.reloadData();
    }

    *loadData({
      paginationData,
      sortData,
      filterData
    }) {
      yield (0, _emberConcurrency.timeout)(600);
      let params = {};

      if (sortData.length) {
        let sortName = sortData[0].prop;
        let sortPrefix = sortData[0].direction === 'desc' ? '-' : '';
        params.sort = `${sortPrefix}${sortName}`;
      }

      let filters = {
        vendors: 1
      };

      if (filterData.filter) {
        filters.search = filterData.filter;
      }

      if (this.vendorTypeFilter && this.vendorTypeFilter !== 'all') {
        filters['vendor-type'] = this.vendorTypeFilter;
      }

      params.filter = filters;
      params.include = ['client-account', 'account', 'primary-contact', 'lead', 'client-locations', 'requestor-account'].join(',');
      params.page = {
        size: paginationData.pageSize,
        number: paginationData.pageNumber
      };
      params.include_metadata = 1;
      let vendors = yield this.store.query('client', params);
      this.set('totalRows', vendors.meta.page.total);
      this.set('meta', vendors.meta);
      this.set('filters', params.filter);
      return vendors;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filterByType", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "filterByType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadData", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadData"), _class.prototype)), _class));
  _exports.default = VendorsController;
});