define("coachlogix/services/notifications", ["exports", "ember-concurrency-decorators", "push.js", "coachlogix/config/environment"], function (_exports, _emberConcurrencyDecorators, _push, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /* global Pusher */
  // Pusher.logToConsole = true;
  let NotificationsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.filterBy('all.lastSuccessful.value', 'read', false), _dec7 = Ember.computed.reads('unread.length'), _dec8 = Ember.computed.filterBy('all.lastSuccessful.value', 'isInvitation', true), _dec9 = Ember.computed.filterBy('all.lastSuccessful.value', 'isInvitation', false), _dec10 = Ember.computed.sort('invitations', function (a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    } else if (a.createdAt > b.createdAt) {
      return -1;
    }

    return 0;
  }), _dec11 = Ember.computed.sort('alerts', function (a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    } else if (a.createdAt > b.createdAt) {
      return -1;
    }

    return 0;
  }), _dec12 = Ember.computed.alias('storage.notificationsEnabled'), (_class = (_temp = class NotificationsService extends Ember.Service {
    *all() {
      return yield this.store.findAll('activity', {
        include: 'notification.message-type,actor',
        reload: true
      });
    }

    constructor() {
      super(...arguments); // defaults to true if not yet set

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "account", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "storage", _descriptor5, this);

      _initializerDefineProperty(this, "unread", _descriptor6, this);

      _initializerDefineProperty(this, "unreadCount", _descriptor7, this);

      _initializerDefineProperty(this, "invitations", _descriptor8, this);

      _initializerDefineProperty(this, "alerts", _descriptor9, this);

      _initializerDefineProperty(this, "sortedInvitations", _descriptor10, this);

      _initializerDefineProperty(this, "sortedAlerts", _descriptor11, this);

      _initializerDefineProperty(this, "isEnabled", _descriptor12, this);

      if (this.isEnabled === undefined) {
        this.set('isEnabled', true);
      } // check of permissions have been manually revoked meanwhile
      // and update local storage preference


      if (!_push.default.Permission.has() && this.isEnabled) {
        this.set('isEnabled', false);
      }
    }

    initPusher(user) {
      let authEndpoint = '';

      if (_environment.default.api.host === '/') {
        authEndpoint = window.location.origin;
      } else {
        authEndpoint = this.account.meRequest?.api_url || _environment.default.api.host;
      }

      authEndpoint += `/${_environment.default.api.namespace}/pusher/auth`;
      let socket = new Pusher(_environment.default.pusher.appKey, {
        cluster: _environment.default.pusher.cluster,
        authEndpoint,
        disableStats: true,
        auth: {
          headers: {
            Authorization: `Bearer ${this.session.session.get('authenticated.idToken')}`
          }
        }
      });
      let announcementChannel = socket.subscribe('coachlogix-announcement');
      announcementChannel.bind('App\\Events\\CriticalDeployment', Ember.run.bind(this, this.onAnnouncementChannel));
      let channel = socket.subscribe(`private-cl.user.${user.get('id')}`);
      channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', Ember.run.bind(this, this.onNewNotification));
    }

    onAnnouncementChannel(message) {
      return _push.default.create(message.message, {
        body: message.message,
        icon: '/img/logo.png',
        timeout: 0,

        onClick() {
          window.location.reload(true);
        }

      });
    }

    async onNewNotification({
      activity_id
    }) {
      if (!this.isEnabled) {
        return;
      }

      let activity = await this.store.findRecord('activity', activity_id, {
        include: 'notification'
      });
      this.showNotification(activity);
    }

    async request() {
      await _push.default.create('Thank you!', {
        body: 'Notifications are now enabled.',
        icon: '/img/logo.png',
        timeout: 4000
      });
      this.set('isEnabled', true);
    }

    revoke() {
      this.set('isEnabled', false);
    }

    showNotification(activity) {
      let router = this.router;
      let notification = activity.get('notification');
      return _push.default.create(activity.activitySubject, {
        body: activity.activityBody,
        icon: '/img/logo.png',
        timeout: 4000,

        onClick() {
          router.transitionTo('notifications', notification);
          window.focus();
          this.close();
        }

      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "unread", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "unreadCount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "invitations", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "alerts", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "sortedInvitations", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "sortedAlerts", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "all", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "all"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isEnabled", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NotificationsService;
});