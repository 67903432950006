define("coachlogix/models/client-coach", ["exports", "@ember-data/model", "coachlogix/models/contact"], function (_exports, _model, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _contact.default.extend({
    approvalDate: (0, _model.attr)('date'),
    additionalInfo: (0, _model.attr)('string'),
    declineReason: (0, _model.attr)('string'),
    isContracted: (0, _model.attr)('boolean'),
    deletedAt: (0, _model.attr)('date'),
    account: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    approver: (0, _model.belongsTo)('employee', {
      inverse: null
    }),
    client: (0, _model.belongsTo)('client', {
      inverse: 'clientCoaches'
    }),
    employee: (0, _model.belongsTo)('employee', {
      inverse: 'clientCoaches'
    })
  });

  _exports.default = _default;
});