define("coachlogix/models/role-user", ["exports", "@ember-data/model", "ember-concurrency", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrency, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    clToaster: Ember.inject.service(),
    adminableType: (0, _model.attr)('string'),
    adminable: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      async: true,
      inverse: null
    }),
    user: (0, _model.belongsTo)('user', {
      async: true,
      inverse: 'roleUsers'
    }),
    role: (0, _model.belongsTo)('role', {
      inverse: null
    }),
    permissions: (0, _model.hasMany)('permission', {
      inverse: null
    }),
    roleName: Ember.computed.reads('role.name'),
    objectType: Ember.computed.reads('role.objectType'),
    addAccountAdmin: (0, _emberConcurrency.task)(function* (user, role, account) {
      user = yield user;
      role = yield role;
      account = yield account;
      let admin = this.store.createRecord('role-user', {
        user,
        role,
        account
      });

      try {
        yield admin.save();
        this.get('admins').pushObject(admin);
        this.clToaster.success(`${user.get('name')} was added as a ${role.get('displayName')}`);
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    addAccountObjectAdmin: (0, _emberConcurrency.task)(function* (user, role, account, adminable) {
      user = yield user;
      role = yield role;
      account = yield account;
      adminable = yield adminable;
      let admin = this.store.createRecord('role-user', {
        user,
        role,
        account,
        adminable
      });

      try {
        yield admin.save();
        this.get('admins').pushObject(admin);
        this.clToaster.success(`${user.get('name')} was added as a ${role.get('display_name')}`);
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});