define("coachlogix/pods/form/form-editor/focusable-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kEsUmcx8",
    "block": "{\"symbols\":[\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[8,\"cl-card\",[[16,\"tabindex\",[30,[36,0],[[32,1],\"-1\",\"0\"],null]],[16,0,[31,[\"cl-outline-none cl-flex cl-flex-col cl-relative \",[30,[36,0],[[32,0,[\"isActive\"]],\"cl-z-10 form-element--active\"],null]]]],[17,2],[4,[38,1],[\"click\",[32,0,[\"handleClick\"]]],null],[4,[38,2],[[32,0,[\"registerCardEl\"]]],null]],[[\"@raised\",\"@bordered\"],[[32,0,[\"isActive\"]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,3,[[32,0,[\"isActive\"]]]],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"cl-absolute cl-left-0 cl-top-0 cl-bottom-0 cl-transition-all cl-duration-500 cl-ease-out-exp cl-bg-primary \",[30,[36,0],[[32,0,[\"isActive\"]],\"cl-w-0.5\",\"cl-w-0\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"did-insert\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/form/form-editor/focusable-card/template.hbs"
    }
  });

  _exports.default = _default;
});