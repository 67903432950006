define("coachlogix/models/stakeholder", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    roleType: (0, _model.attr)('string', {
      defaultValue: 'other'
    }),
    engagement: (0, _model.belongsTo)('engagement', {
      inverse: null
    }),
    employee: (0, _model.belongsTo)('employee', {
      inverse: null
    }),
    stakeholderable: (0, _model.belongsTo)('base-model', {
      polymorphic: true
    })
  });

  _exports.default = _default;
});