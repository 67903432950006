define("coachlogix/pods/components/v2/engagement/assign-coach-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lG0Zgkkm",
    "block": "{\"symbols\":[\"@onClose\",\"&attrs\"],\"statements\":[[6,[37,5],[[32,0,[\"showCoachesDialog\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"cl-employee-selection-dialog\",[[24,0,\"cl-w-32\"],[17,2]],[[\"@required\",\"@title\",\"@noOptionsText\",\"@coaches\",\"@onChange\",\"@onClose\",\"@closeOnSelect\",\"@auxButtonClick\",\"@auxButtonLabel\"],[true,[30,[36,0],[\"engagements.create-dialog.select-coach\"],null],[30,[36,0],[\"engagements.create-dialog.no-coaches\"],null],true,[30,[36,4],[[32,0,[\"assignCoach\"]]],null],[32,1],false,[30,[36,3],[[30,[36,2],[[32,0,[\"showCoachesDialog\"]]],null],false],null],[30,[36,0],[\"engagements.create-dialog.create-coach\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"v2/invite-dialog\",[],[[\"@title\",\"@types\",\"@onClose\",\"@onSave\",\"@disabled\"],[[30,[36,0],[\"coaches.add-coach\"],null],[30,[36,1],[\"internal-coach\",\"independent-coach\",\"vendor-provided-coach\"],null],[30,[36,3],[[30,[36,2],[[32,0,[\"showCoachesDialog\"]]],null],true],null],[30,[36,4],[[32,0,[\"assignCoach\"]]],null],[32,0,[\"assignCoach\",\"isRunning\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"mut\",\"fn\",\"perform\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement/assign-coach-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});