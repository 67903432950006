define("coachlogix/pods/competency-library/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompetencyLibraryController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.reads('model.competencyLibrary.value'), _dec6 = Ember.computed('model.competencies.value.@each.{name,description}', 'search'), _dec7 = Ember.computed.sort('competencies', 'competenciesSorting'), (_class = (_temp = class CompetencyLibraryController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _defineProperty(this, "search", '');

      _initializerDefineProperty(this, "competencyLibrary", _descriptor5, this);

      _initializerDefineProperty(this, "sortedCompetencies", _descriptor6, this);

      _defineProperty(this, "competenciesSorting", ['name']);
    }

    get competencies() {
      let search = this.search.toLowerCase();
      return this.model.competencies.value.filter(c => {
        let name = c.name ? c.name.toLowerCase() : '';
        let description = c.description ? c.description.toLowerCase() : '';
        return name.includes(search) || description.includes(search);
      });
    }

    *updateProperty(prop, value) {
      yield this.competencyLibrary.updateProperty.perform(prop, value, false);
      this.clToaster.success(this.intl.t('competency-library.theme-library-updated'));
    }

    *saveNewCompetency(newCompetency) {
      try {
        let competencies = yield this.competencyLibrary.get('competencies');
        competencies.pushObject(newCompetency);
        yield this.competencyLibrary.save();
        this.clToaster.success(this.intl.t('competency-library.theme-added-to-library'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *removeCompetency(competency) {
      try {
        this.competencyLibrary.get('competencies').removeObject(competency);
        yield this.competencyLibrary.save();
        this.clToaster.success(this.intl.t('competency-library.competency-removed'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *toggleCompetency(competency) {
      try {
        let selectedCompetencies = yield this.competencyLibrary.get('competencies');

        if (selectedCompetencies.includes(competency)) {
          yield this.removeCompetency.perform(competency);
        } else {
          yield this.saveNewCompetency.perform(competency);
        }
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *deleteThemeLibrary() {
      yield this.competencyLibrary.deleteTask.perform();
      this.router.transitionTo('resources');
    }

    *duplicateThemeLibrary() {
      this.clToaster.info(this.intl.t('models.resource.duplicating-resource'));

      try {
        let duplicateThemeLibrary = this.store.createRecord('resource', {
          name: `${this.competencyLibrary.get('name')} - Copy`,
          clonedFrom: this.competencyLibrary
        });
        yield duplicateThemeLibrary.save();
        this.router.transitionTo('competency-library', duplicateThemeLibrary.id);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "competencyLibrary", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "competencies", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "competencies"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sortedCompetencies", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateProperty", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateProperty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveNewCompetency", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveNewCompetency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeCompetency", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeCompetency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCompetency", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCompetency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteThemeLibrary", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteThemeLibrary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "duplicateThemeLibrary", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "duplicateThemeLibrary"), _class.prototype)), _class));
  _exports.default = CompetencyLibraryController;
});