define("coachlogix/initializers/smoothscroll-polyfill", ["exports", "smoothscroll-polyfill"], function (_exports, _smoothscrollPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * We force the polyfill to run because Chrome's native implementation
   * doesn't seem to work consistently when creating new program items.
   */
  function initialize() {
    window.__forceSmoothScrollPolyfill__ = true;

    _smoothscrollPolyfill.default.polyfill();
  }

  var _default = {
    name: 'smoothscroll-polyfill',
    initialize
  };
  _exports.default = _default;
});