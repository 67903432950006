define("coachlogix/pods/components/v2/client/share-coachee-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dFD67FIf",
    "block": "{\"symbols\":[\"@onClose\",\"&attrs\"],\"statements\":[[8,\"cl-employee-selection-dialog\",[[24,0,\"cl-w-32\"],[17,2]],[[\"@required\",\"@title\",\"@noOptionsText\",\"@shareable\",\"@onChange\",\"@onClose\",\"@closeOnSelect\"],[true,[30,[36,0],[\"engagements.create-dialog.select-coachee\"],null],[30,[36,0],[\"engagements.create-dialog.no-coachees\"],null],true,[30,[36,1],[[32,0,[\"createCoachee\"]]],null],[32,1],false]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"perform\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/client/share-coachee-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});