define("coachlogix/abilities/subscription", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    account: Ember.inject.service(),
    activeAccount: Ember.computed.reads('account.activeAccount'),
    activePlan: Ember.computed.reads('activeAccount.subscription.plan'),
    canViewPlans: Ember.computed('activeAccount.{isFree,isEnterprise}', 'activePlan.provider', function () {
      return (this.get('activePlan.provider') === 'stripe' || this.get('activeAccount.isFree')) && !this.get('activeAccount.isEnterprise');
    }),
    canSeeSeatsInfo: Ember.computed('activeAccount.{isFree,isBusiness,isEnterprise}', function () {
      return !this.get('activeAccount.isFree') && (this.get('activeAccount.isBusiness') || this.get('activeAccount.isEnterprise'));
    }),
    canManageSeats: Ember.computed('canSeeSeatsInfo', 'activePlan.provider', function () {
      return this.get('canSeeSeatsInfo') && this.get('activePlan.provider') === 'stripe';
    }),
    canManageCreditCards: Ember.computed('activePlan.provider', function () {
      return this.get('activePlan') && this.get('activePlan.provider') === 'stripe';
    })
  });

  _exports.default = _default;
});