define("coachlogix/pods/components/v2/goal/competency-tags/competency-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FpronFM/",
    "block": "{\"symbols\":[\"competency\",\"@onClose\",\"@goal\",\"&attrs\"],\"statements\":[[8,\"cl-selection-dialog\",[[24,0,\"cl-w-40\"],[17,4]],[[\"@title\",\"@onClose\",\"@options\",\"@multiple\",\"@search\",\"@searchField\",\"@selectLabel\",\"@selected\",\"@onChange\"],[[30,[36,0],[\"engagement.goals.themes\"],null],[32,2],[32,0,[\"options\"]],true,true,[30,[36,1],[\"name\",\"description\"],null],[30,[36,0],[\"ui.save\"],null],[32,3,[\"competencies\"]],[30,[36,2],[[32,3,[\"updateCompetencies\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"cl-whitespace-normal\"],[12],[1,[32,1,[\"defaultedDescription\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"perform\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/goal/competency-tags/competency-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});