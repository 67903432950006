define("coachlogix/pods/components/v2/resources/resources-view/resource-actions/duplicate-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourceDuplicateDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec(_class = (_class2 = (_temp = class ResourceDuplicateDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _defineProperty(this, "name", void 0);
    }

    init() {
      super.init(...arguments);
      this.set('name', `${this.resource.name} - Copy`);
    }

    *duplicate() {
      let selectedGroup = this.selectedGroup;
      let isEngagement = this.resourceable?.modelName === 'engagement';
      let isEngagementTemplate = this.resourceable?.modelName === 'engagement-template';
      let isSession = this.resourceable?.modelName === 'engagement-session';
      this.clToaster.info(this.intl.t('models.resource.duplicating-resource'));

      try {
        let duplicatedResource = this.store.createRecord(this.resource.modelName, {
          name: this.name,
          clonedFrom: this.resource,
          engagements: isEngagement && !selectedGroup && !this.showAdminResources ? [this.resourceable] : [],
          engagementTemplates: isEngagementTemplate && !selectedGroup && !this.showAdminResources ? [this.resourceable] : [],
          engagementSessions: isSession && !selectedGroup ? [this.resourceable] : [],
          adminEngagements: this.showAdminResources && isEngagement ? [this.resourceable] : [],
          adminEngagementTemplates: this.showAdminResources && isEngagementTemplate ? [this.resourceable] : [],
          resourceGroups: selectedGroup ? [selectedGroup] : []
        });
        yield duplicatedResource.save();

        if (!this.selectedGroup) {
          this.onDuplicate?.(duplicatedResource);
        }

        this.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "duplicate", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "duplicate"), _class2.prototype)), _class2)) || _class);
  var _default = ResourceDuplicateDialog;
  _exports.default = _default;
});