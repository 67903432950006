define("coachlogix/pods/components/v2/messaging/channel-dock/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VStklTQ4",
    "block": "{\"symbols\":[\"channel\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"cl-absolute cl-bottom-0 cl-right-0 cl-flex cl-items-end cl-z-30\"],[17,2],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"twilio\",\"dockChannels\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"v2/messaging/channel-dock/channel-window\",[],[[\"@channel\",\"@isHidden\",\"@onClose\"],[[32,1],[30,[36,0],[[32,0,[\"twilio\",\"hiddenChannel\"]],[32,1,[\"sid\"]]],null],[30,[36,1],[[32,0,[\"removeFromDock\"]],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/messaging/channel-dock/template.hbs"
    }
  });

  _exports.default = _default;
});