define("coachlogix/pods/components/v2/engagement/create-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EngagementsCreateDialogComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember.computed.or('selectedCoachee.isExternal', 'selectedCoach.isExternal'), _dec13 = Ember.computed.and('selectedCoach.isExternal', 'selectedCoachee.isExternal'), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec(_class = (_class2 = (_temp = class EngagementsCreateDialogComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "clToaster", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _defineProperty(this, "step", 'selectingCoachee');

      _defineProperty(this, "showCoacheesDialog", true);

      _initializerDefineProperty(this, "needsClientContract", _descriptor6, this);

      _initializerDefineProperty(this, "needsContractorContract", _descriptor7, this);
    }

    async coacheeSelected(coachee) {
      let isIndependentCoachAccount = this.account.activeAccount.isIndependentCoach; // workaround for loading the matching process
      // the backend returns them as ids, so we don't know the value until we load it
      // the next step requires this

      await coachee.get('matchingProcess');
      this.set('selectedCoachee', coachee);

      if (isIndependentCoachAccount) {
        this.set('selectedCoach', this.account.activeAccount.get('coach'));
        this.set('step', 'selectingTemplate');
      } else {
        this.set('step', 'selectingMatchingProcess');
      }
    }

    matchingProcessSelected(selected) {
      if (selected === 'coach') {
        this.set('step', 'selectingCoach');
        return;
      }

      if (selected === 'coach-pools') {
        this.set('step', 'selectingCoachPool');
        return;
      }

      if (selected === 'custom-coach-pool') {
        this.set('step', 'selectingCustomCoachPool');
        return;
      }

      if (selected === 'skip') {
        this.set('step', 'selectingTemplate');
        return;
      }

      if (selected === 'new-coach') {
        this.set('step', 'creatingCoach');
        return;
      }
    }

    coachSelected(coach) {
      this.set('selectedCoach', coach);
      this.set('step', 'selectingTemplate');
    }

    coachPoolsSelected(coachPools) {
      this.set('selectedCoachPools', coachPools);
      this.set('engagementStatus', 'draft_coach_selection');
      this.set('step', 'selectingMatchingSettings');
    }

    coachesSelected(coaches) {
      this.set('selectedCoaches', coaches);
      this.set('engagementStatus', 'draft_coach_selection');
      this.set('step', 'selectingMatchingSettings');
    }

    matchingSettingsSelected(matchingSetting) {
      this.set('selectedMatchingSetting', matchingSetting);
      this.set('step', 'selectingTemplate');
    }

    async templateSelected(template) {
      this.set('selectedTemplate', template);

      if (this.needsClientContract) {
        await this.determineNextClientContractStep();
      } else {
        await this.createEngagement.perform();
      }
    }

    async determineNextClientContractStep() {
      let filter = {
        assignable: true
      };

      if (this.selectedCoachee.isInternal && this.selectedCoach.isExternal) {
        let account = await this.selectedCoach.get('account');
        filter.account_id = account.id;
      } else {
        let clientAccount = await this.selectedCoachee.get('account');
        filter.client_account_id = clientAccount.id;
      }

      let contracts = await this.store.query('contract', {
        filter
      });

      if (contracts.length === 0) {
        await this.clientContractSelected(null);
      } else {
        this.set('contracts', contracts);
        this.set('step', 'selectingClientContract');
      }
    }

    async clientContractSelected(contract) {
      this.set('selectedClientContract', contract);

      if (this.needsContractorContract) {
        await this.determineNextContractorContractStep();
      } else {
        await this.createEngagement.perform();
      }
    }

    async determineNextContractorContractStep() {
      let filter = {
        assignable: true
      };
      let account = await this.selectedCoach.get('account');
      filter.account_id = account.id;
      let contracts = await this.store.query('contract', {
        filter
      });

      if (contracts.length === 0) {
        await this.contractorContractSelected(null);
      } else {
        this.set('contracts', contracts);
        this.set('step', 'selectingContractorContract');
      }
    }

    async contractorContractSelected(contract) {
      this.set('selectedContractorContract', contract);
      await this.createEngagement.perform();
    }

    *createEngagement() {
      let user = this.account.activeUser;
      let account = this.account.activeAccount;
      let coachee = this.selectedCoachee;
      let coacheeAccount = yield coachee.get('account');
      let coach = this.selectedCoach;
      let coachAccount;

      if (coach) {
        coachAccount = yield coach.get('account');
      }

      let status = this.engagementStatus || 'draft';
      let coachPools = this.selectedCoachPools || [];
      let matchingSettings = this.selectedMatchingSetting;
      let engagementTemplate = this.selectedTemplate;
      let contract = this.selectedClientContract;
      let contractorContract = this.selectedContractorContract;
      let selectedCoaches = this.selectedCoaches;

      try {
        if (selectedCoaches) {
          // Create engagement pool
          let pool = this.store.createRecord('coach-pool', {
            name: '',
            description: '',
            isEngagement: true,
            account,
            user,
            coaches: []
          });
          yield pool.save(); // Save coach pool

          let coachSavePromises = selectedCoaches.map(coach => {
            let cpc = this.store.createRecord('coach-pool-coach', {
              notes: '',
              employee: coach,
              coachPool: pool
            });
            return cpc.save();
          });
          yield (0, _emberConcurrency.all)(coachSavePromises);
          coachPools = [pool];
        }

        let engagement = yield this.store.createRecord('engagement', {
          user,
          account,
          coachee,
          coacheeAccount,
          coach,
          coachAccount,
          engagementTemplate,
          contract,
          contractorContract,
          coachPools,
          status,
          matchingSettings
        }).save();
        this.router.transitionTo('engagement', engagement.id);
        this.onClose();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "coacheeSelected", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "coacheeSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "matchingProcessSelected", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "matchingProcessSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "coachSelected", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "coachSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "coachPoolsSelected", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "coachPoolsSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "coachesSelected", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "coachesSelected"), _class2.prototype), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "needsClientContract", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "needsContractorContract", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "matchingSettingsSelected", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "matchingSettingsSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "templateSelected", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "templateSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clientContractSelected", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "clientContractSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "contractorContractSelected", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "contractorContractSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createEngagement", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class2.prototype, "createEngagement"), _class2.prototype)), _class2)) || _class);
  var _default = EngagementsCreateDialogComponent;
  _exports.default = _default;
});