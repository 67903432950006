define("coachlogix/models/resource-group-item", ["exports", "@ember-data/model", "ember-data-copyable", "coachlogix/models/base-model"], function (_exports, _model, _emberDataCopyable, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_emberDataCopyable.default, {
    resource: (0, _model.belongsTo)('resource', {
      async: true,
      inverse: null
    }),
    resourceGroup: (0, _model.belongsTo)('resource-group', {
      async: true,
      inverse: 'resourceItems'
    })
  });

  _exports.default = _default;
});