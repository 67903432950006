define("coachlogix/models/invoice", ["exports", "@ember-data/model", "ember-concurrency", "moment", "qs-stringify", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrency, _moment, _qsStringify, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    invoiceNumber: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    // 'draft','sent','viewed','approved','partial_paid','paid','client_marked_paid','client_marked_partial_paid'
    poNumber: (0, _model.attr)('string'),
    invoiceDate: (0, _model.attr)('date'),
    dueDate: (0, _model.attr)('date'),
    terms: (0, _model.attr)('string'),
    privateNotes: (0, _model.attr)('string'),
    publicNotes: (0, _model.attr)('string'),
    amount: (0, _model.attr)('number'),
    balance: (0, _model.attr)('number'),
    clientBalance: (0, _model.attr)('number'),
    vatPercent: (0, _model.attr)('number'),
    vatAmount: (0, _model.attr)('number'),
    taxName1: (0, _model.attr)('string'),
    taxRate1: (0, _model.attr)('number'),
    payViaStripe: (0, _model.attr)('boolean'),
    address: (0, _model.attr)('string'),
    address2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    postalCode: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    vendorAddress: (0, _model.attr)('string'),
    vendorAddress2: (0, _model.attr)('string'),
    vendorCity: (0, _model.attr)('string'),
    vendorState: (0, _model.attr)('string'),
    vendorPostalCode: (0, _model.attr)('string'),
    vendorCountry: (0, _model.attr)('string'),
    account: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    client: (0, _model.belongsTo)('client', {
      inverse: 'invoices'
    }),
    clientAccount: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    isClientDeleted: (0, _model.attr)('boolean'),
    contract: (0, _model.belongsTo)('contract', {
      inverse: null
    }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    invoiceDesign: (0, _model.belongsTo)('invoice-design', {
      inverse: null
    }),
    paymentTerm: (0, _model.belongsTo)('payment-term', {
      inverse: null
    }),
    engagement: (0, _model.belongsTo)('engagement', {
      async: true,
      inverse: 'invoices'
    }),
    currency: (0, _model.belongsTo)('currency', {
      inverse: null
    }),
    invoiceItems: (0, _model.hasMany)('invoice-item', {
      async: true,
      inverse: 'invoice'
    }),
    payments: (0, _model.hasMany)('payment', {
      async: true,
      inverse: 'invoice'
    }),
    isDraft: Ember.computed.equal('status', 'draft'),
    isNotDraft: Ember.computed.not('isDraft'),
    isSent: Ember.computed.equal('status', 'sent'),
    isPaid: Ember.computed.equal('status', 'paid'),
    isPartialPaid: Ember.computed.equal('status', 'partial_paid'),
    isClientMarkedPaid: Ember.computed.equal('status', 'client_marked_paid'),
    isClientMarkedPartialPaid: Ember.computed.equal('status', 'client_marked_partial_paid'),
    // we want to extend the normal statuses with additional ones
    derivedStatus: Ember.computed('isPastDue', 'status', function () {
      return this.isPastDue ? 'past_due' : this.status;
    }),
    isSender: Ember.computed('account', 'accountService.activeAccount.id', function () {
      return this.get('accountService.activeAccount.id') === this.belongsTo('account').id();
    }),
    isClient: Ember.computed.not('isSender'),
    isPastDue: Ember.computed('dueDate', 'isSent', 'isPartialPaid', function () {
      let dueDate = (0, _moment.default)(this.get('dueDate'));
      let now = (0, _moment.default)();
      return this.get('dueDate') && this.get('isSent') && now.isAfter(dueDate);
    }),
    taxRatePercentage: Ember.computed('taxRate1', function () {
      let rate = this.get('taxRate1');

      if (rate) {
        // workaround floating point arithmetic imprecision
        return Math.round(rate * 100 * 100) / 100;
      }
    }),
    subTotal: Ember.computed('invoiceItems.@each.subTotal', function () {
      return this.get('invoiceItems').reduce((previous, item) => {
        return previous + (parseFloat(item.get('subTotal')) || 0);
      }, 0);
    }),
    taxableAmount: Ember.computed('invoiceItems.@each.{subTotal,isTaxable}', function () {
      return this.get('invoiceItems').filterBy('isTaxable').reduce((previous, item) => {
        return previous + (parseFloat(item.get('subTotal')) || 0);
      }, 0);
    }),
    taxesApplied: Ember.computed('taxableAmount', 'taxRate1', function () {
      return this.get('taxableAmount') * (this.get('taxRate1') || 0);
    }),
    vatApplied: Ember.computed('subTotal', 'vatPercent', function () {
      return this.get('subTotal') * (this.get('vatPercent') / 100 || 0);
    }),
    total: Ember.computed('subTotal', 'taxesApplied', 'vatApplied', function () {
      return this.get('subTotal') + this.get('taxesApplied') + this.get('vatApplied');
    }),
    resend: (0, _emberConcurrency.task)(function* () {
      try {
        let invoiceId = this.get('id');
        let typeKey = 'receive_invoice';

        if (this.get('payViaStripe')) {
          typeKey = 'receive_stripe_invoice';
        }

        let queryString = (0, _qsStringify.default)({
          notifiable_type: 'invoices',
          notifiable_id: invoiceId,
          type_key: typeKey
        });
        yield this.get('ajax').post(`/helpers/resend-notification?${queryString}`);

        if (this.isClientDeleted) {
          this.set('isClientDeleted', false);
          yield this.save();
        }

        this.clToaster.success(`Invoice sent again.`);
      } catch (e) {
        this.clToaster.error('You can only send once every 24 hours');
      }
    }),
    markAsPaid: (0, _emberConcurrency.task)(function* () {
      if (this.isSender) {
        this.set('status', 'paid');
      } else {
        this.set('status', 'client_marked_paid');
      }

      yield this.save();
    }),
    markAsUnpaid: (0, _emberConcurrency.task)(function* () {
      this.set('status', 'sent');
      yield this.save();
    }),
    markAsPartialPaid: (0, _emberConcurrency.task)(function* () {
      if (this.isSender) {
        this.set('status', 'partial_paid');
      } else {
        this.set('status', 'client_marked_partial_paid');
      }

      yield this.save();
    }),
    send: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'sent');
        yield this.save();
        this.clToaster.success(this.intl.t('invoice.invoice_sent_successfully'));
      } catch (e) {
        this.clToaster.error(this.intl.t('invoice.invoice_sent_error'));
      }
    }),
    deleteTask: (0, _emberConcurrency.task)(function* () {
      if (this.isSender) {
        yield this.destroyRecord();
      } else {
        this.set('isClientDeleted', true);
        yield this.save();
        this.unloadRecord();
      }
    })
  });

  _exports.default = _default;
});