define("coachlogix/models/coach-pool", ["exports", "@ember-data/model", "ember-concurrency", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrency, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    isEngagement: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    account: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    coachPoolCoaches: (0, _model.hasMany)('coach-pool-coach', {
      async: true,
      inverse: 'coachPool'
    }),
    coaches: (0, _model.hasMany)('employee', {
      inverse: null
    }),
    engagements: (0, _model.hasMany)('engagement', {
      inverse: null
    }),
    // TODO: need to figure out how to update this as a relationship
    updatePoolCoaches: (0, _emberConcurrency.task)(function* (employees) {
      this.set('employees', employees);
      yield this.get('saveTask').perform();
    })
  });

  _exports.default = _default;
});