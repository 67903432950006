define("coachlogix/abilities/client", ["exports", "ember-can", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),

    accountTypeIsAny(...accounts) {
      return accounts.includes(this.get('account.activeAccount.accountType'));
    },

    canEdit: Ember.computed('account.activeAccount.id', 'model.theOtherAccount.{isActive,ownerAccountId}', 'model.{clientLocations,isActive}', function () {
      let currentAccountId = this.get('account.activeAccount.id');
      let hasManagePermissions = this.hasPermission('manage_account_clients');
      let clientIsActive = this.get('model.theOtherAccount.isActive');
      let clientOwnerAccountId = false;

      if (this.get('model.theOtherAccount.ownerAccountId')) {
        clientOwnerAccountId = this.get('model.theOtherAccount.ownerAccountId');
      }

      let companyLocations = this.get('model.clientLocations').mapBy('companyLocation.content');
      let isAdmin = this.hasAccountRole('account_global_admin', 'location_global_admin');

      if (clientOwnerAccountId == currentAccountId) {
        return true;
      }

      if (clientIsActive) {
        return false;
      }

      if (isAdmin) {
        return true;
      }

      if (hasManagePermissions) {
        return true;
      }

      if (companyLocations && this.hasObjectPermission('manage_location_clients', companyLocations)) {
        return true;
      }

      return false;
    }),
    canManageClient: Ember.computed.reads('canEdit'),
    canApprove: Ember.computed('account.activeAccount.id', 'model.{approvalStatus,clientLocations,isActive,theOtherAccount.ownerAccountId}', function () {
      let currentAccountId = this.get('account.activeAccount.id');
      let hasManagePermissions = this.hasPermission('manage_account_clients');
      let clientOwnerAccountId = false;

      if (this.get('model.theOtherAccount.ownerAccountId')) {
        clientOwnerAccountId = this.get('model.theOtherAccount.ownerAccountId');
      }

      let companyLocations = this.get('model.clientLocations').mapBy('companyLocation.content');
      let isAdmin = this.hasAccountRole('account_global_admin', 'location_global_admin');

      if (this.get('model.approvalStatus') === 'your_approval_needed') {
        if (clientOwnerAccountId == currentAccountId) {
          return true;
        }

        if (isAdmin || hasManagePermissions) {
          return true;
        }

        if (companyLocations && this.hasObjectPermission('manage_location_clients', companyLocations)) {
          return true;
        }
      }

      return false;
    }),
    canSendInvite: Ember.computed('account.activeAccount.id', 'model.{approvalStatus,clientLocations,isActive,status,theOtherAccount.ownerAccount.id}', function () {
      let client = this.get('model');
      let isAlreadyApproved = client.approvalStatus === 'approved';
      let approvalRequiredByMe = client.approvalStatus === 'your_approval_needed';
      let isIndieCoacheeOrCompany = client.get('isIndependentCoachee') || client.get('isCompany') || client.get('isVendor');
      let isIndieCoachOrCompany = client.get('isIndependentCoach') || client.get('isCompany');
      let clientRequiresApproval = ['vendor_approval_needed', 'client_approval_needed'].includes(client.get('approvalStatus'));
      let clientAccountIsInactive = client.get('isInternalRecord') || client.get('isInvited') || client.get('account.isPending') || !client.get('isActive') && !client.get('theOtherAccount.isActive');
      let hasManagePermissions = this.hasPermission('manage_account_clients');
      let companyLocations = this.get('model.clientLocations').mapBy('companyLocation.content');
      let isAdmin = this.hasAccountRole('account_global_admin', 'location_global_admin');

      if (hasManagePermissions || isAdmin || companyLocations && this.hasObjectPermission('manage_location_clients', companyLocations)) {
        if (!this.model.get('primaryContact.email') || isAlreadyApproved || approvalRequiredByMe) {
          return false;
        }

        if ((clientRequiresApproval || clientAccountIsInactive) && (isIndieCoacheeOrCompany || isIndieCoachOrCompany)) {
          return true;
        }
      }

      return false;
    }),
    canAddAdmins: Ember.computed.reads('canEdit'),
    canAddContact: Ember.computed('account.activeAccount.accountType', 'canEdit', function () {
      return this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.canEdit;
    }),
    canApproveContact: Ember.computed('account.activeAccount.accountType', 'canEdit', function () {
      return this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.canEdit;
    }),
    canDeleteContact: Ember.computed('account.activeAccount.accountType', 'canEdit', function () {
      return this.accountTypeIsAny('independent_coach', 'business', 'enterprise') && this.canEdit;
    }),
    canManageLocations: Ember.computed('account.activeAccount.{accountType,companyLocations.length}', function () {
      return this.accountTypeIsAny('enterprise') && this.get('account.activeAccount.companyLocations.length') > 0;
    }),
    canAddPoc: Ember.computed.not('model.theOtherAccount.isActive'),
    canManageEngagements: false,
    canViewEngagements: false,
    canManageResources: false,
    canManageInvoices: false,
    canManagePeople: false,
    canManageCompanies: false,
    canManageReports: false,
    canManageSettings: false
  });

  _exports.default = _default;
});