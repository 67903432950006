define("coachlogix/pods/components/v2/resources/resources-view/resource-actions/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ResourceActions = (_dec = (0, _component.tagName)(''), _dec(_class = (_class2 = class ResourceActions extends Ember.Component {
    /*
     * If resource was added on an engagement-template, unlink it from the template
     * otherwise, hard delete it
     */
    *delete() {
      if (this.resourceable && this.resourceable.modelName === 'engagement-template') {
        if (this.resource.isEngagement) {
          // hard delete here. admin resources on templates can only be uploaded.
          yield this.resource.deleteTask.linked().perform();
        } else {
          // unlink
          if (this.selectedGroup) {
            let resourceGroups = yield this.resource.get('resourceGroups');
            resourceGroups.removeObject(this.selectedGroup);
            yield this.resource.save();
          } else {
            let engagementTemplates = yield this.resource.get('engagementTemplates');
            engagementTemplates.removeObject(this.resourceable);
            yield this.resource.save();
          }
        }
      } else {
        // hard delete
        yield this.resource.deleteTask.linked().perform();
      }

      this.onDelete?.(this.resource);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "delete", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype)), _class2)) || _class);
  var _default = ResourceActions;
  _exports.default = _default;
});