define("coachlogix/pods/components/v2/invite-dialog/types/vendor-provided-coach/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rcIrpCKi",
    "block": "{\"symbols\":[\"currentStep\",\"@onSave\",\"@onBack\"],\"statements\":[[8,\"cl-animated-value\",[],[[\"@value\"],[[32,0,[\"step\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,1],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[8,\"v2/invite-dialog/types/external-vendor\",[],[[\"@onBack\",\"@onSave\"],[[32,3],[30,[36,3],[[30,[36,1],[[30,[36,2],[[32,0,[\"vendorData\"]]],null]],null],[32,0,[\"next\"]]],null]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1],2],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"v2/invite-dialog/types/request-coach\",[],[[\"@onBack\",\"@onSave\"],[[32,0,[\"previous\"]],[30,[36,3],[[30,[36,1],[[30,[36,2],[[32,0,[\"coachData\"]]],null]],null],[30,[36,1],[[32,2],[30,[36,0],null,[[\"vendorData\",\"coachData\"],[[32,0,[\"vendorData\"]],[32,0,[\"coachData\"]]]]]],null]],null]]],null],[2,\"\\n\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"fn\",\"mut\",\"queue\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/invite-dialog/types/vendor-provided-coach/template.hbs"
    }
  });

  _exports.default = _default;
});