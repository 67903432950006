define("coachlogix/pods/marketplace/profile/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProfileController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, (_class = (_temp = class ProfileController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "session", _descriptor6, this);

      _initializerDefineProperty(this, "coachingRequest", _descriptor7, this);

      _initializerDefineProperty(this, "openInterviewScheduler", _descriptor8, this);

      _initializerDefineProperty(this, "displayUnableTo", _descriptor9, this);

      _defineProperty(this, "queryParams", ['initInterview']);
    }

    *scheduleInterview() {
      try {
        if (this.session.isAuthenticated) {
          let coach = yield this.model.owner.get('employee');

          if (!this.account.activeAccount.isMember) {
            this.displayUnableTo = true;
            return;
          }

          let interviews = yield this.store.query('coaching-request', {
            filter: {
              marketplace: 1,
              participants: 'mine',
              status: 'interview_scheduled',
              employee_id: this.model.owner.employee.get('id')
            }
          });

          if (interviews.toArray()[0]) {
            this.clToaster.error(this.intl.t('marketplace.interview-already-scheduled'));
          }

          let meetingLocations = yield this.store.query('meeting-location', {
            filter: {
              'meeting-type': 'in_app_video'
            }
          });
          let meetingLocation = meetingLocations.toArray()[0];
          this.coachingRequest = this.store.createRecord('coaching-request', {
            status: 'interview_scheduled',
            coach,
            coachee: this.account.activeEmployee,
            interviewTitle: this.intl.t('settings.marketplace.interview.interview-name'),
            description: this.intl.t('settings.marketplace.interview.desc-val'),
            duration: 15,
            meetingLocation
          });
          this.openInterviewScheduler = true;
        } else {
          this.router.transitionTo('outside.login', {
            queryParams: {
              profileId: this.model.id,
              open: 'signup'
            }
          });
        }
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "coachingRequest", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "openInterviewScheduler", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "displayUnableTo", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "scheduleInterview", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "scheduleInterview"), _class.prototype)), _class));
  _exports.default = ProfileController;
});