define("coachlogix/models/client-coach-share", ["exports", "@ember-data/model", "ember-concurrency", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrency, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    clToaster: Ember.inject.service(),
    status: (0, _model.attr)('string'),
    employee: (0, _model.belongsTo)('employee', {
      inverse: null
    }),
    client: (0, _model.belongsTo)('client', {
      inverse: 'coachShares'
    }),
    shareWith: (0, _emberConcurrency.task)(function* (attrs) {
      let shareable = yield this;
      attrs['shareable'] = shareable;
      let sharedItem = this.store.createRecord('shared-item', attrs);

      try {
        yield sharedItem.save();
        this.get('sharedWith').pushObject(sharedItem);
      } catch (e) {
        yield sharedItem.destroyRecord();
        this.clToaster.error(e);
      }
    }),
    approve: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'approved');
        yield this.save();
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    decline: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'declined');
        yield this.save();
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});