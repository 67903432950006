define("coachlogix/models/coaching-theme-info", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    infoType: (0, _model.attr)('string'),
    category: (0, _model.attr)('string'),
    content: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    resource: (0, _model.belongsTo)('resource', {
      async: true,
      inverse: null
    }),
    account: (0, _model.belongsTo)('account'),
    user: (0, _model.belongsTo)('user')
  });

  _exports.default = _default;
});