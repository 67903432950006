define("coachlogix/helpers/download-url", ["exports", "qs-stringify", "coachlogix/config/environment"], function (_exports, _qsStringify, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PARAM_NAME = 'cl_token';
  let DownloadUrl = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class DownloadUrl extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);
    }

    get host() {
      return this.account.meRequest?.api_url || _environment.default.api.host;
    }

    sendViaForm([url, ...params], {
      token = false
    }) {
      let baseUrl = `${this.host || ''}/${_environment.default.api.namespace}/${url}`;
      let tokenString = this.session.data.authenticated.idToken;
      let queryString = (0, _qsStringify.default)(Object.assign({}, ...params));
      let targetUrl;

      if (queryString) {
        if (baseUrl.includes('?')) {
          targetUrl = `${baseUrl}&${queryString}`;
        } else {
          targetUrl = `${baseUrl}?${queryString}`;
        }
      } else {
        targetUrl = baseUrl;
      } // Problem: we need to download a file while also sending
      // the jwt token of the current session. We can't do this in a GET
      // request because urls we would have to use a query param, which belongs to
      // the url itself. Urls are often logged in servers, leading to potential security
      // issues.
      // Solution: create a form with the token as a hidden input, and send it in a POST
      // Link: https://stackoverflow.com/a/59363326


      let form = document.createElement('form');
      form.method = 'post';
      form.target = '_blank';
      form.action = targetUrl;
      form.classList.add('cl-hidden');

      if (token) {
        let tokenInput = document.createElement('input');
        tokenInput.type = 'hidden';
        tokenInput.name = PARAM_NAME;
        tokenInput.value = tokenString;
        form.appendChild(tokenInput);
      }

      document.body.appendChild(form);
      form.submit();
      form.remove();
    }

    compute(positional, named) {
      return (...args) => {
        this.sendViaForm([...positional, ...args], named);
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DownloadUrl;
});