define("coachlogix/pods/settings/org-structure/departments-card/department-edit-dialog/component", ["exports", "@ember-decorators/component", "ember-changeset", "ember-concurrency-decorators"], function (_exports, _component, _emberChangeset, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DepartmentEditDialogComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec(_class = (_class2 = (_temp = class DepartmentEditDialogComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    init() {
      super.init(...arguments);
      let department = this.department;

      if (!department) {
        department = this.store.createRecord('department');
        this.department = department;
      }

      this.changeset = new _emberChangeset.Changeset(department);
    }

    *saveChanges() {
      if (this.parent) {
        this.changeset.set('parent', this.parent);
      }

      yield this.changeset.save();
      this.onClose();
    }

    handleClose() {
      this.changeset.rollback();

      if (this.department.isNew) {
        this.department.destroyRecord();
      }

      this.onClose();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClose", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClose"), _class2.prototype)), _class2)) || _class);
  var _default = DepartmentEditDialogComponent;
  _exports.default = _default;
});