define("coachlogix/models/chat-message", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    channelId: (0, _model.attr)('string'),
    body: (0, _model.attr)('string'),
    recipient: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    userId: (0, _model.belongsTo)('user', {
      inverse: null
    })
  });

  _exports.default = _default;
});