define("coachlogix/pods/components/ui/file-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yfp+OmHI",
    "block": "{\"symbols\":[\"@accept\",\"@maxFiles\",\"@onUploadDone\",\"@onClose\"],\"statements\":[[8,\"filestack-picker\",[],[[\"@accept\",\"@maxFiles\",\"@onUploadDone\",\"@onClose\",\"@lang\",\"@onFileSelected\"],[[30,[36,0],[[32,1],[32,1],[32,0,[\"defaultAccepts\"]]],null],[32,2],[32,3],[32,4],[32,0,[\"account\",\"activeUser\",\"iso639Language\"]],[30,[36,1],[[32,0],\"onFileSelected\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/ui/file-picker/template.hbs"
    }
  });

  _exports.default = _default;
});