define("coachlogix/models/site-feature", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    measureType: (0, _model.attr)('string'),
    // checkbox, up_to_integer, integer, percent
    measureUnit: (0, _model.attr)('string'),
    category: (0, _model.attr)('string'),
    sortOrder: (0, _model.attr)('number'),
    planFeature: (0, _model.belongsTo)('plan-feature', {
      inverse: null
    })
  });

  _exports.default = _default;
});