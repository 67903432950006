define("coachlogix/pods/dashboard/card-scroller/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JeB7EGqp",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"cl-px-3 cl-flex cl-overflow-x-hidden cl-relative\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,0],null,[[\"registerElement\",\"updateTotalPages\"],[[32,0,[\"registerWidgetsScroller\"]],[32,0,[\"updateTotalPages\"]]]]]]],[2,\"\\n\\n  \"],[1,[30,[36,1],[\"resize\",[32,0,[\"updateTotalPages\"]]],null]],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"page\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-absolute cl-left-0 cl-top-0 cl-bottom-0 cl-flex cl-items-center fade-background-left cl-pl-4 cl-w-20 cl-justify-start cl-pointer-events-none\"],[12],[2,\"\\n      \"],[8,\"cl-button\",[[24,0,\"cl-pointer-events-auto\"]],[[\"@bordered\",\"@iconButton\",\"@onClick\"],[true,true,[32,0,[\"previousPage\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"chevron-left\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[30,[36,4],[[32,0,[\"page\"]],[32,0,[\"totalPages\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-absolute cl-right-0 cl-top-0 cl-bottom-0 cl-flex cl-items-center fade-background-right cl-pr-4 cl-w-20 cl-justify-end cl-pointer-events-none\"],[12],[2,\"\\n      \"],[8,\"cl-button\",[[24,0,\"cl-pointer-events-auto\"]],[[\"@bordered\",\"@iconButton\",\"@onClick\"],[true,true,[32,0,[\"nextPage\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"chevron-right\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"on-window\",\"gt\",\"if\",\"not-eq\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/dashboard/card-scroller/template.hbs"
    }
  });

  _exports.default = _default;
});