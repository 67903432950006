define("coachlogix/macros/equals-ignore-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(propKey, string) {
    return Ember.computed(propKey, function () {
      if (this.get(propKey) && string) {
        return this.get(propKey).toLowerCase() === string.toLowerCase();
      } else {
        return false;
      }
    });
  }
});