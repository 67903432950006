define("coachlogix/pods/components/v2/summary-cards/sessions-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5wvOuggK",
    "block": "{\"symbols\":[\"chart\",\"&attrs\",\"@sessionsScheduled\",\"@sessionsUnscheduled\",\"@sessionsCompleted\"],\"statements\":[[8,\"cl-card\",[[24,0,\"cl-flex cl-flex-col cl-p-3\"],[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h4\"],[14,0,\"cl-title-4 cl-mb-2\"],[12],[2,\"Sessions Info\"],[13],[2,\"\\n\\n  \"],[8,\"cl-chart-stacked\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"bar\"]],[],[[\"@value\",\"@success\"],[[32,3],true]],null],[2,\"\\n    \"],[8,[32,1,[\"bar\"]],[],[[\"@value\"],[[32,4]]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-mt-2\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-flex\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"cl-status-indicator\",[],[[\"@success\"],[true]],null],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"cl-ml-1\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[10,\"span\"],[14,0,\"cl-font-semibold cl-mr-1\"],[12],[2,\"Scheduled\"],[13],[13],[2,\"\\n        \"],[10,\"div\"],[12],[1,[30,[36,0],[[32,3]],[[\"allowEmpty\"],[true]]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"cl-flex cl-ml-5\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"cl-status-indicator\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"cl-ml-1\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[10,\"span\"],[14,0,\"cl-font-semibold cl-mr-1\"],[12],[2,\"Unscheduled\"],[13],[13],[2,\"\\n        \"],[10,\"div\"],[12],[1,[30,[36,0],[[32,4]],[[\"allowEmpty\"],[true]]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-flex cl-items-end cl-justify-end\"],[12],[1,[30,[36,0],[[32,5]],[[\"allowEmpty\"],[true]]]],[2,\" sessions completed\"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"format-number\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/summary-cards/sessions-info/template.hbs"
    }
  });

  _exports.default = _default;
});