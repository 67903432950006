define("coachlogix/models/department", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    account: (0, _model.belongsTo)('account', {
      inverse: 'departments'
    }),
    name: (0, _model.attr)('string'),
    parent: (0, _model.belongsTo)('department', {
      inverse: 'subDepartments'
    }),
    subDepartments: (0, _model.hasMany)('department', {
      inverse: 'parent'
    }),
    fullName: Ember.computed('parent.fullName', 'name', function () {
      if (this.belongsTo('parent').id() !== null) {
        return `${this.get('parent.fullName')} > ${this.get('name')}`;
      } else {
        return this.get('name');
      }
    })
  });

  _exports.default = _default;
});