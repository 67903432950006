define("coachlogix/models/competency-description", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    description: (0, _model.attr)('string'),
    competency: (0, _model.belongsTo)('competency', {
      inverse: 'competencyDescription'
    })
  });

  _exports.default = _default;
});