define("coachlogix/pods/components/v2/upload-dialog/csv-schemas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // this files exports *functions* that return Joi schemas.
  // the reason is that the Joi library itself is dynamically loaded
  // only when needed, so we can't assume its presence. Instead, the caller will
  // pass in the Joi dependency that the function can then use to build and return the schema.
  const directlyContractedCoachee = Joi => Joi.object({
    meta: Joi.object({
      fields: Joi.array().items(Joi.string().valid('first_name').required().label('first_name'), Joi.string().valid('last_name').required().label('last_name'), Joi.string().valid('email').required().label('email'), Joi.string().valid('send_invite').optional(), Joi.string().allow(''))
    }).unknown(),
    data: Joi.array().items(Joi.object({
      first_name: Joi.string().max(64).required(),
      last_name: Joi.string().max(64).required(),
      email: Joi.string().email({
        tlds: false
      }).required(),
      send_invite: Joi.string().valid('0', '1', '').optional()
    }).unknown()).min(1).required()
  }).unknown();

  const clientCompanySchema = Joi => Joi.object({
    meta: Joi.object({
      fields: Joi.array().items(Joi.string().valid('company_name').required().label('company_name'), Joi.string().valid('first_name').required().label('first_name'), Joi.string().valid('last_name').required().label('last_name'), Joi.string().valid('contact_email').required().label('contact_email'), Joi.string().valid('send_invite').optional(), Joi.string().allow(''))
    }).unknown(),
    data: Joi.array().items(Joi.object({
      company_name: Joi.string().max(100).required(),
      first_name: Joi.string().max(64).required(),
      last_name: Joi.string().max(64).required(),
      contact_email: Joi.string().email({
        tlds: false
      }).required(),
      send_invite: Joi.string().valid('0', '1', '').optional()
    }).unknown()).min(1).required()
  }).unknown(); // the schema is the same as a directly contracted coachee, so no need to recreate it


  const independentCoachSchema = directlyContractedCoachee; // the schema is the same as a client company, so no need to recreate it

  const externalVendorSchema = clientCompanySchema;

  const employeeSchema = Joi => Joi.object({
    meta: Joi.object({
      fields: Joi.array().items(Joi.string().valid('first_name').required().label('first_name'), Joi.string().valid('last_name').required().label('last_name'), Joi.string().valid('email').required().label('email'), Joi.string().valid('is_coach').optional(), Joi.string().valid('send_invite').optional(), Joi.string().allow(''))
    }).unknown(),
    data: Joi.array().items(Joi.object({
      first_name: Joi.string().max(64).required(),
      last_name: Joi.string().max(64).required(),
      email: Joi.string().email({
        tlds: false
      }).required(),
      is_coach: Joi.string().valid('0', '1', '').optional(),
      send_invite: Joi.string().valid('0', '1', '').optional(),
      role: Joi.string().valid('account_global_admin', 'account_employee', '').optional()
    }).unknown()).min(1).required()
  }).unknown();

  const createEngagementSchema = Joi => Joi.object({
    meta: Joi.object({
      fields: Joi.array().items(Joi.string().valid('coach_email').required().label('coach_email'), Joi.string().valid('coachee_email').required().label('coachee_email'), Joi.string().allow(''))
    }).unknown(),
    data: Joi.array().items(Joi.object({
      coach_email: Joi.string().email({
        tlds: false
      }).required(),
      coachee_email: Joi.string().email({
        tlds: false
      }).required(),
      editor: Joi.string().email({
        tlds: false,
        multiple: true
      }).allow('').optional(),
      viewer: Joi.string().email({
        tlds: false,
        multiple: true
      }).allow('').optional(),
      status: Joi.string().valid('active', 'completed', 'draft', '').optional(),
      start_date: Joi.alternatives().try(Joi.date().format(['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', 'D/M/YY']), Joi.string().valid('')).optional(),
      end_date: Joi.alternatives().try(Joi.date().format(['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', 'D/M/YY']), Joi.string().valid('')).optional()
    }).unknown()).min(1).required()
  }).unknown();

  const updateEngagementSchema = Joi => Joi.object({
    meta: Joi.object({
      fields: Joi.array().items(Joi.string().valid('Type').required().label('Type'), Joi.string().valid('Id').required().label('Id'), Joi.string().valid('Field').required().label('Field'), Joi.string().valid('Value').required().label('Value'), Joi.string().allow(''))
    }).unknown(),
    data: Joi.array().items(Joi.object({
      Type: Joi.string().valid('Engagement', 'Engagement Session', 'Form', 'Todo', 'Goal', 'File', 'Link').required(),
      Field: Joi.string().when('Type', {
        switch: [{
          is: 'Engagement',
          then: Joi.string().valid('description', 'welcomeMessage', 'welcomeMessageTitle', 'startDate', 'status', 'endDate')
        }, {
          is: 'Engagement Session',
          then: Joi.string().valid('cancelReason', 'completedDate', 'description', 'duration', 'meetingInstructions', 'scheduledDate', 'status', 'title')
        }, {
          is: Joi.string().valid('Form', 'File', 'Todo', 'Link'),
          then: Joi.string().valid('description', 'publishedDate', 'completedDate', 'status', 'targetDate', 'title')
        }]
      }).allow('').optional(),
      Completion_date: Joi.alternatives().try(Joi.date().format(['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss', 'D/M/YY']), Joi.string().valid('')).optional(),
      Duration: Joi.alternatives().try(Joi.number().positive(), Joi.string().valid('')).optional()
    }).unknown()).min(1).required()
  }).unknown();

  var _default = {
    // clients
    'directly-contracted-coachee': directlyContractedCoachee,
    'client-company': clientCompanySchema,
    // vendors
    'independent-coach': independentCoachSchema,
    'external-vendor': externalVendorSchema,
    // employees
    employee: employeeSchema,
    'create-engagements': createEngagementSchema,
    'update-engagements': updateEngagementSchema
  };
  _exports.default = _default;
});