define("coachlogix/pods/client/coaches/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ClientCoachesRoute extends Ember.Route {
    model() {
      return {
        clientTask: this.modelFor('client').clientTask
      };
    }

  }

  _exports.default = ClientCoachesRoute;
});