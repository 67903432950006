define("coachlogix/abilities/contract", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    account: Ember.inject.service(),
    isCreator: Ember.computed('model.account.id', 'account.activeAccount.id', function () {
      return this.get('model').belongsTo('account').id() === this.get('account.activeAccount.id');
    }),
    recipientIsNotIndieCoachee: Ember.computed('model.client.isIndependentCoachee', function () {
      return !this.model.get('client.isIndependentCoachee');
    }),
    canSend: Ember.computed.and('model.client.theOtherAccount.isActive', 'model.isDraft', 'isCreator', 'recipientIsNotIndieCoachee'),
    canDelete: Ember.computed.reads('isCreator'),
    canApprove: Ember.computed('model.isSent', 'isCreator', function () {
      return this.get('model.isSent') && !this.get('isCreator');
    }),
    canEdit: Ember.computed.reads('isCreator')
  });

  _exports.default = _default;
});