define("coachlogix/pods/form/form-editor/form-element/radio/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators", "ember-paper/utils/clamp"], function (_exports, _component, _emberConcurrencyDecorators, _clamp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormElementRadioComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.sort('question.formQuestionOptions', 'sortOrder'), _dec5 = Ember.computed('isActive', 'sortedOptions.length'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec(_class = (_class2 = (_temp = class FormElementRadioComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _defineProperty(this, "sortOrder", ['sortOrder:asc']);

      _initializerDefineProperty(this, "sortedOptions", _descriptor3, this);
    }

    get shouldShowClose() {
      return this.isActive && this.sortedOptions.length > 1;
    }

    registerElement(element) {
      this.questionElement = element;
    }

    reorderItems(options) {
      let changedOptions = [];
      options.forEach((o, i) => {
        let previous = Ember.get(o, 'sortOrder');
        let newOrder = i;
        Ember.set(o, 'sortOrder', newOrder);

        if (previous !== newOrder) {
          changedOptions.push(o);
        }
      });
      changedOptions.map(o => o.save());
    } // this action selects input text on focus


    selectAll(ev) {
      ev.target.select();
    }

    *addOption() {
      let optionText = this.intl.t('form.questions.single-choice.new-option');
      let option = this.question.addOption({
        value: `${optionText} ${this.get('sortedOptions.length') + 1}`
      });
      Ember.run.scheduleOnce('afterRender', () => {
        let input = this.questionElement.querySelectorAll('.option-input')[this.question.get('formQuestionOptions.length') - 1];

        if (input) {
          input.focus();
        }
      });
      yield option.save();
      this.clToaster.success(this.intl.t('form.questions.added-option-toast'));
    }

    *removeOption(option) {
      let options = this.question.get('formQuestionOptions');
      let index = (0, _clamp.default)(options.indexOf(option), 0, options.get('length') - 2);
      this.question.removeOption(option);
      yield option.destroyRecord();
      this.clToaster.success(this.intl.t('form.questions.deleted-option-toast')); // wait for ember to render the removal before focusing new input

      Ember.run.scheduleOnce('afterRender', () => {
        let input = this.questionElement.querySelectorAll('.option-input')[index];

        if (input) {
          input.focus();
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "sortedOptions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "shouldShowClose", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldShowClose"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "registerElement", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "registerElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reorderItems", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "reorderItems"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectAll", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "selectAll"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addOption", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "addOption"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeOption", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "removeOption"), _class2.prototype)), _class2)) || _class);
  var _default = FormElementRadioComponent;
  _exports.default = _default;
});