define("coachlogix/pods/components/v2/resources/link-selection-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TKZtGcbW",
    "block": "{\"symbols\":[\"@onChange\",\"@onClose\"],\"statements\":[[6,[37,5],[[32,0,[\"createResource\",\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[32,0,[\"createResource\",\"performCount\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[32,0,[\"list\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n      \"],[8,\"v2/resources/resource-selection-dialog\",[],[[\"@title\",\"@accept\",\"@required\",\"@onChange\",\"@onClose\",\"@auxButtonClick\",\"@auxButtonLabel\"],[[30,[36,3],[\"global.general.link\"],null],\"link\",true,[32,1],[32,2],[30,[36,1],[[30,[36,0],[[32,0,[\"list\"]]],null],false],null],[30,[36,3],[\"company-resources.create-link\"],null]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n      \"],[8,\"v2/resources/create-link-dialog\",[],[[\"@onClose\",\"@onCreate\"],[[30,[36,1],[[30,[36,0],[[32,0,[\"list\"]]],null],true],null],[30,[36,2],[[32,0,[\"createLink\"]]],null]]],null],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"perform\",\"t\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/resources/link-selection-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});