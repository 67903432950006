define("coachlogix/pods/components/v2/user-hover-card/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserHoverCardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, (_class = (_temp = class UserHoverCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "twilio", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    handleClick() {
      if (this.args.linkToProfile) {
        this.transitionToProfile.perform(this.args.user);
      }
    }

    *transitionToProfile(user) {
      let coach = yield user.get('employee');

      if (coach.get('isInternal') || this.account.isCoachee) {
        this.router.transitionTo('employee.profile', coach.id);
      } else {
        let account = yield coach.get('account');
        let clients = yield this.store.query('client', {
          filter: {
            account_id: account.get('id'),
            client_account_id: this.account.activeAccount.id
          }
        });
        let client = clients.get('firstObject');

        if (client) {
          if (client.get('isIndependentCoach')) {
            this.router.transitionTo('vendor.coach-profile', client.get('id'));
          } else {
            yield client.get('clientCoaches');
            let contact = yield this.store.peekAll('client-coach').filter(c => {
              return c.belongsTo('employee').id() === coach.get('id');
            });
            this.router.transitionTo('employee.profile', coach.id, {
              queryParams: {
                clientId: client.get('id'),
                contactId: contact.get('firstObject').get('id'),
                contactType: 'coaches'
              }
            });
          }
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "twilio", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToProfile", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToProfile"), _class.prototype)), _class));
  _exports.default = UserHoverCardComponent;
});