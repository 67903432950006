define("coachlogix/services/stripe-old", ["exports", "coachlogix/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Stripe, StripeCheckout */
  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.stripe = Stripe(_environment.default.stripe.publishableKey);
    },

    openCheckout(options = {}) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.getCheckoutHandler({ ...options,
          opened: () => {
            resolve();
          }
        }).then(handler => {
          handler.open();
        }).catch(() => {
          reject();
        });
      });
    },

    getCheckoutHandler(options = {}) {
      return this.loadStripeCheckoutJs().then(() => {
        return StripeCheckout.configure({
          key: _environment.default.stripe.publishableKey,
          image: 'https://cdn.filestackcontent.com/WufObfueRN6hcscpTs0y',
          locale: 'auto',
          currency: 'usd',
          ...options
        });
      });
    },

    loadStripeCheckoutJs() {
      if (this._scriptLoaded || this._scriptLoading) {
        return this._stripeScriptPromise;
      }

      this._scriptLoading = true;
      let script = document.createElement('script');
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.async = true;
      script.type = 'text/javascript';
      this._stripeScriptPromise = new Ember.RSVP.Promise((resolve, reject) => {
        script.onload = () => {
          this._scriptLoaded = true;
          this._scriptLoading = false;
          resolve();
        };

        script.onerror = () => {
          this._scriptLoading = false;
          reject();
        };

        document.body.appendChild(script);
      });
      return this._stripeScriptPromise;
    }

  });

  _exports.default = _default;
});