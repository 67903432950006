define("coachlogix/pods/outside/login/controller", ["exports", "coachlogix/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    auth0: Ember.inject.service(),
    domainMasking: Ember.inject.service(),
    queryParams: ['mimic_token', 'sso_token', 'expires_in', 'issued_at', 'open', 'packageId', 'orderId', 'profileId'],

    init() {
      this._super(...arguments);

      this.auth0.on('screenChange', () => {
        let tabsElement = document.querySelector('.auth0-lock-tabs-container');

        if (tabsElement) {
          let termsElement = document.createElement('div');
          tabsElement.parentNode.insertBefore(termsElement, tabsElement.nextSibling);
          this.set('termsElement', termsElement);
          this.updateButtons();
        }
      });
    },

    setDomainMaskingOptions(lockOptions) {
      let options = this.get('domainMasking.data');

      if (Ember.isPresent(options.full_logo)) {
        lockOptions.theme = lockOptions.theme || {};
        lockOptions.theme.logo = options.full_logo;
      }

      if (Ember.isPresent(options.primary_color)) {
        lockOptions.theme = lockOptions.theme || {};
        lockOptions.theme.primaryColor = options.primary_color;
      }

      if (this.get('domainMasking.terms').length) {
        lockOptions.showTerms = false;
      }
    },

    updateButtons() {
      let submitButtons = document.querySelectorAll('button.auth0-lock-submit,button.auth0-lock-social-button');

      if (!submitButtons.length) {
        return;
      }

      if (this.get('auth0.isSignupEnabled')) {
        let areAllTermsChecked = this.get('domainMasking.terms').every(i => i.checked);

        if (areAllTermsChecked) {
          submitButtons.forEach(e => e.removeAttribute('disabled'));
        } else {
          submitButtons.forEach(e => e.setAttribute('disabled', ''));
        }
      } else {
        submitButtons.forEach(e => e.removeAttribute('disabled'));
      }
    },

    actions: {
      login() {
        let {
          lockOptions
        } = _environment.default['ember-simple-auth'].auth0;
        lockOptions.allowLogin = true;
        lockOptions.allowSignUp = lockOptions.allowSignUp === undefined ? true : lockOptions.allowSignUp;
        lockOptions.autoclose = true;
        lockOptions.initialScreen = 'login';
        this.setDomainMaskingOptions(lockOptions);
        this.get('session').authenticate('authenticator:auth0-lock', lockOptions);
        let termsElement = document.querySelector('.auth0-lock-content-wrapper');
        this.set('termsElement', termsElement);
        this.updateButtons();
      },

      signup() {
        let {
          lockOptions
        } = _environment.default['ember-simple-auth'].auth0;
        lockOptions.allowLogin = true;
        lockOptions.allowSignUp = lockOptions.allowSignUp === undefined ? true : lockOptions.allowSignUp;
        lockOptions.autoclose = true;
        lockOptions.initialScreen = 'signUp';
        this.setDomainMaskingOptions(lockOptions);

        if (Ember.isPresent(this.packageId) || Ember.isPresent(this.profileId)) {
          lockOptions.additionalSignUpFields = [{
            name: 'given_name',
            placeholder: 'Enter your first name',
            storage: 'root',
            // this stores the information in the root of the user object and not on its metadata
            validator: v => ({
              valid: Ember.isPresent(v),
              hint: "Can't be blank"
            })
          }, {
            name: 'family_name',
            placeholder: 'Enter your last name',
            storage: 'root',
            // this stores the information in the root of the user object and not on its metadata
            validator: v => ({
              valid: Ember.isPresent(v),
              hint: "Can't be blank"
            })
          }, {
            type: 'hidden',
            name: 'redirect_url',
            value: `${window.location.origin}/login`
          }];
          let specialParam;

          if (this.packageId) {
            specialParam = {
              type: 'hidden',
              name: 'package_id',
              value: this.packageId
            };
          } else if (this.profileId) {
            specialParam = {
              type: 'hidden',
              name: 'profile_id',
              value: this.profileId
            };
          }

          lockOptions.additionalSignUpFields.push(specialParam);
        }

        this.session.authenticate('authenticator:auth0-lock', lockOptions);
        let termsElement = document.querySelector('.auth0-lock-content-wrapper');
        this.set('termsElement', termsElement);
        this.updateButtons();
      },

      reset() {
        let {
          lockOptions
        } = _environment.default['ember-simple-auth'].auth0;
        lockOptions.allowLogin = false;
        lockOptions.allowSignUp = false;
        lockOptions.autoclose = false;
        lockOptions.initialScreen = 'forgotPassword';
        this.setDomainMaskingOptions(lockOptions);
        this.get('session').authenticate('authenticator:auth0-lock', lockOptions);
        let termsElement = document.querySelector('.auth0-lock-content-wrapper');
        this.set('termsElement', termsElement);
        this.updateButtons();
      },

      onTermsCheck(item, ev) {
        Ember.set(item, 'checked', ev.target.checked);
        this.updateButtons();
      }

    }
  });

  _exports.default = _default;
});