define("coachlogix/models/messaging-type", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    category: (0, _model.attr)('string'),
    subCategory: (0, _model.attr)('string'),
    isEmail: (0, _model.attr)('number'),
    isSms: (0, _model.attr)('number'),
    isPushNotification: (0, _model.attr)('number'),
    isSiteActivity: (0, _model.attr)('number'),
    typeKey: (0, _model.attr)('string'),
    customizable: (0, _model.attr)('number'),
    accountMessagings: (0, _model.hasMany)('account-messaging', {
      async: true
    }),
    onlySendIfCustom: (0, _model.attr)('number'),
    alwaysEnabled: Ember.computed.not('onlySendIfCustom') // default time unit
    // default time value

  });

  _exports.default = _default;
});