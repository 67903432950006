define("coachlogix/pods/components/cl-chip-filters/select-lookup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ofOLlW6",
    "block": "{\"symbols\":[\"o\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex cl-flex-col cl-space-y-2 cl-p-3\"],[17,2],[12],[2,\"\\n  \"],[8,\"cl-input\",[[24,\"placeholder\",\"Search\"]],[[\"@line\",\"@icon\",\"@value\",\"@onChange\"],[true,\"search\",[32,0,[\"searchText\"]],[30,[36,3],[[32,0,[\"loadOptions\"]]],null]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[15,1,[32,0,[\"uniqueId\"]]],[14,0,\"cl-flex cl-flex-col cl-max-h-24 cl-overflow-auto\"],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"loadOptions\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"cl-flex cl-justify-center cl-items-center cl-h-16\"],[12],[2,\"\\n        \"],[8,\"cl-progress-circular\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"vertical-collection\",[],[[\"@tagName\",\"@containerSelector\",\"@items\",\"@estimateHeight\",\"@staticHeight\",\"@bufferSize\"],[\"ul\",[31,[\"#\",[32,0,[\"uniqueId\"]]]],[32,0,[\"options\"]],32,true,5]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"li\"],[12],[2,\"\\n          \"],[8,\"cl-checkbox\",[],[[\"@value\",\"@onChange\"],[[32,1,[\"isSelected\"]],[30,[36,2],[[30,[36,1],[[30,[36,0],[[32,1,[\"isSelected\"]]],null]],null],[32,0,[\"handleApply\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"queue\",\"perform\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cl-chip-filters/select-lookup/template.hbs"
    }
  });

  _exports.default = _default;
});