define("coachlogix/pods/engagement/goals/goal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cpPXdrFS",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"goalTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cl-w-full cl-h-full cl-flex cl-justify-center cl-items-center\"],[12],[2,\"\\n    \"],[8,\"cl-progress-circular\",[],[[\"@size\"],[48]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"v2/goal/detail-view\",[],[[\"@goal\"],[[32,0,[\"model\",\"goalTask\",\"value\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/engagement/goals/goal/template.hbs"
    }
  });

  _exports.default = _default;
});