define("coachlogix/pods/competency-library/edit-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EditCompetencyDialogComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec(_class = (_class2 = (_temp = class EditCompetencyDialogComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clToaster", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _defineProperty(this, "competencyName", void 0);

      _defineProperty(this, "competencyDescription", void 0);
    }

    init() {
      super.init(...arguments);
      this.competencyName = this.competency.name;
      this.competencyDescription = this.competency.get('competencyDescription.description') || this.competency.description;
    }

    *onSave() {
      try {
        if (this.competency.isCoachlogix) {
          let competencyDescription = yield this.competency.get('competencyDescription'); // tried to change description in a CoachLogix global competency
          // need to create/update a competency description

          if (!competencyDescription && this.competencyDescription !== this.competency.description) {
            competencyDescription = this.store.createRecord('competency-description', {
              competency: this.competency
            });
            competencyDescription.set('description', this.competencyDescription);
            yield competencyDescription.save();
          }
        } else {
          this.competency.set('name', this.competencyName);
          this.competency.set('description', this.competencyDescription);
          yield this.competency.save();
        }

        let message = this.intl.t('competency-library.competency-updated');
        this.clToaster.success(message);
      } catch (e) {
        this.clToaster.error(e);
      }

      if (this.onClose) {
        this.onClose();
      }
    }

    *resetCustomDescription(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      let competencyDescription = yield this.competency.get('competencyDescription');

      if (competencyDescription) {
        yield competencyDescription.destroyRecord();
      }

      this.set('competencyDescription', this.competency.description);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onSave", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "onSave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetCustomDescription", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "resetCustomDescription"), _class2.prototype)), _class2)) || _class);
  var _default = EditCompetencyDialogComponent;
  _exports.default = _default;
});