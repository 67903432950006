define("coachlogix/models/client", ["exports", "@ember-data/model", "ember-concurrency", "qs-stringify", "coachlogix/models/base-model", "coachlogix/models/commentable"], function (_exports, _model, _emberConcurrency, _qsStringify, _baseModel, _commentable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_commentable.default, {
    contractEndsAt: (0, _model.attr)('string'),
    balance: (0, _model.attr)('string'),
    receivedToDate: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    // active, suspended, deleted, internal_record, invited
    clientType: (0, _model.attr)('string'),
    vendorType: (0, _model.attr)('string'),
    deletedAt: (0, _model.attr)('date'),
    sendInvite: (0, _model.attr)('boolean'),
    account: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    clientAccount: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    requestorAccount: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    lead: (0, _model.belongsTo)('user'),
    user: (0, _model.belongsTo)('user'),
    primaryContact: (0, _model.belongsTo)('user'),
    invoices: (0, _model.hasMany)('invoice'),
    payments: (0, _model.hasMany)('payment'),
    contracts: (0, _model.hasMany)('contract', {
      inverse: 'client'
    }),
    engagements: (0, _model.hasMany)('engagement', {
      async: true,
      inverse: null
    }),
    clientCoachees: (0, _model.hasMany)('client-coachee', {
      inverse: 'client'
    }),
    clientCoaches: (0, _model.hasMany)('client-coach', {
      inverse: 'client'
    }),
    clientLocations: (0, _model.hasMany)('company-location', {
      async: true,
      inverse: null
    }),
    clientPocs: (0, _model.hasMany)('client-poc', {
      inverse: 'client'
    }),
    clientSummary: (0, _model.belongsTo)('client-summary', {
      async: true,
      inverse: 'client'
    }),
    companyLocations: (0, _model.hasMany)('company-location'),
    billableActivities: (0, _model.hasMany)('billable-activity', {
      inverse: 'client'
    }),
    // computed properties
    // this is a client if we are their vendor
    isClient: Ember.computed('account', 'accountService.activeAccount.id', function () {
      return this.get('accountService.activeAccount.id') === this.belongsTo('account').id();
    }),
    // this is a vendor if we are their client
    isVendor: Ember.computed('accountService.activeAccount.id', 'clientAccount', function () {
      return this.get('accountService.activeAccount.id') === this.belongsTo('clientAccount').id();
    }),
    // returns the correct account
    theOtherAccount: Ember.computed('isVendor', 'isClient', 'clientAccount', 'account', function () {
      if (this.get('isVendor')) {
        // this is a vendor
        return this.get('account');
      } else if (this.get('isClient')) {
        // this is a client
        return this.get('clientAccount');
      }
    }),
    // returns the correct owner
    owner: Ember.computed('isVendor', 'isClient', 'lead', 'primaryContact', function () {
      if (this.get('isVendor')) {
        return this.get('lead');
      } else {
        return this.get('primaryContact');
      }
    }),
    // returns the correct type
    relType: Ember.computed('isVendor', 'isClient', 'clientType', 'vendorType', function () {
      if (this.get('isVendor')) {
        return this.get('vendorType');
      } else if (this.get('isClient')) {
        return this.get('clientType');
      }
    }),
    isCompany: Ember.computed('relType', function () {
      return this.get('relType') === 'company' || this.get('relType') === 'provider';
    }),
    isIndependentCoachee: Ember.computed.equal('relType', 'direct_coachee'),
    isIndependentCoach: Ember.computed.equal('relType', 'independent_coach'),
    isIndependent: Ember.computed.or('isIndependentCoach', 'isIndependentCoachee'),
    isActive: Ember.computed.equal('status', 'active'),
    isInternalRecord: Ember.computed.equal('status', 'internal_record'),
    isInvited: Ember.computed.equal('status', 'invited'),
    isPending: Ember.computed.equal('status', 'pending'),
    approvalStatus: Ember.computed('accountService.activeAccount.id', 'isActive', 'isClient', 'isIndependentCoachee', 'isInvited', 'isVendor', 'requestorAccount', 'status', function () {
      if (this.get('isInvited')) {
        if (this.belongsTo('requestorAccount').id() != this.get('accountService.activeAccount.id')) {
          return 'your_approval_needed';
        } else if (this.get('isVendor') || this.get('isIndependentCoachee')) {
          return 'request_sent';
        } else if (this.get('isClient')) {
          return 'client_approval_needed';
        }
      } else if (this.get('isActive')) {
        return 'approved';
      } else {
        return this.get('status');
      }
    }),
    admins: Ember.computed.filterBy('contacts', 'isAdmin'),
    coaches: Ember.computed.filterBy('sharedContacts', 'isCoach'),
    coachees: Ember.computed.filterBy('contacts', 'isCoachee'),
    sessions: Ember.computed('loadSessions', function () {
      return this.get('loadSessions').perform();
    }),
    loadSessions: (0, _emberConcurrency.task)(function* () {
      let sessions = yield this.store.query('engagement-session', {
        filter: {
          client_id: this.id,
          upcoming: true
        }
      });
      this.set('sessions', sessions);
    }),
    loadEngagements: (0, _emberConcurrency.task)(function* () {
      let engagements = yield this.store.query('engagement', {
        filter: {
          client_id: this.id
        },
        include: 'coachee-view,coach-view,engagement-summary,engagement-template'
      });
      this.set('engagements', engagements);
    }),
    // tasks
    disconnect: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.destroyRecord();
        this.clToaster.success(this.intl.t('coaches.client-disconnect'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    declineInvitation: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'internal_record');
        yield this.save();
        this.clToaster.success(this.intl.t('coaches.client-disconnect'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    approve: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'active');
        yield this.save();
        this.clToaster.success('Connection accepted.');
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    sendInviteInternalClientRecord: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'invited');
        yield this.save();
        this.clToaster.success('Invite sent.');
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    resendInvitation: (0, _emberConcurrency.task)(function* (accountId) {
      try {
        let wasInvited = this.get('isInvited');
        let otherAccount = yield this.get('theOtherAccount');
        let pocs = null;
        let contact = null;
        let queryString = null;

        if (otherAccount.get('accountType') == 'independent_coach') {
          pocs = yield this.get('clientCoaches');
          yield pocs.forEach(clientPoc => {
            if (accountId != clientPoc.get('account').get('id')) {
              contact = clientPoc;
            }
          });
          queryString = (0, _qsStringify.default)({
            client_coach_id: contact.get('id')
          });
        } else {
          pocs = yield this.get('clientPocs');
          yield pocs.forEach(clientPoc => {
            if (accountId != clientPoc.get('account').get('id')) {
              contact = clientPoc;
            }
          });
          queryString = (0, _qsStringify.default)({
            client_poc_id: contact.get('id')
          });
        }

        yield this.get('ajax').post(`/helpers/resend-invitation?${queryString}`);
        this.set('status', 'invited');
        const toastMessage = wasInvited ? this.get('intl').t('ui.invite-resent-success') : this.get('intl').t('ui.invite-sent-success');
        this.clToaster.success(toastMessage);
      } catch (e) {
        this.clToaster.error('Invitation was sent less than 24 hours ago.');
      }
    }),
    getOtherAccount: (0, _emberConcurrency.task)(function* () {
      return yield this.get('theOtherAccount');
    }),
    accountService: Ember.inject.service('account'),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    clToaster: Ember.inject.service()
  });

  _exports.default = _default;
});