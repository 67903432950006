define("coachlogix/pods/invoices/components/create-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InvoicesCreateDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec(_class = (_class2 = (_temp = class InvoicesCreateDialog extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _defineProperty(this, "showUninvoiced", false);

      _defineProperty(this, "showOnlyExternal", true);

      this.options = this.getEngagements.perform();
    }

    *getEngagements() {
      let engagements;
      let filter = {};

      if (this.showUninvoiced) {
        filter.invoicing = 1;
      }

      if (this.showOnlyExternal) {
        filter.external = 1;
      }

      if (this.client) {
        filter.client_id = this.client.id;
      }

      if (this.showUninvoiced || this.showOnlyExternal) {
        engagements = yield this.store.query('engagement', {
          filter,
          include: 'coach.user,coachee.user'
        });
      } else {
        engagements = yield this.store.findAll('engagement', {
          include: 'coach.user,coachee.user,invoiceable-sessions',
          reload: true
        });
      }

      engagements = engagements.filter(e => !e.isDraft && !e.isMatching && !e.isDraftMatching);
      return engagements;
    }

    *createInvoice(engagement) {
      let account = this.account.activeAccount;
      let billingSettings = yield account.get('billingSettings');
      let vatAmount = billingSettings.vatPercent;
      let currency = yield billingSettings.get('currency');
      let invoice = this.store.createRecord('invoice', {
        engagement,
        vatAmount,
        account,
        currency
      });
      yield invoice.save();
      this.onClose();
      this.router.transitionTo('invoice', invoice.id);
    }

    onToggleUninvoiced(value) {
      this.set('showUninvoiced', value);
      this.set('options', this.getEngagements.perform());
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "getEngagements", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "getEngagements"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createInvoice", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createInvoice"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onToggleUninvoiced", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "onToggleUninvoiced"), _class2.prototype)), _class2)) || _class);
  var _default = InvoicesCreateDialog;
  _exports.default = _default;
});