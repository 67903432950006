define("coachlogix/models/form-submission", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    // status ['started', 'submitted', 'reviewed', 'archived']
    status: (0, _model.attr)('string'),
    start: (0, _model.attr)('date'),
    end: (0, _model.attr)('date'),
    resource: (0, _model.belongsTo)('resource'),
    answers: (0, _model.hasMany)('form-answer', {
      inverse: 'submission'
    }),
    user: (0, _model.belongsTo)('user'),
    assignment: (0, _model.belongsTo)('assignment', {
      inverse: 'formSubmissions'
    }),
    isSubmitted: Ember.computed.equal('status', 'submitted'),
    // these are auxiliary properties to allow updating the
    // user's name when submitting an external form
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string')
  });

  _exports.default = _default;
});