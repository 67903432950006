define("coachlogix/abilities/engagement-permission-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    account: Ember.inject.service(),
    role: Ember.computed.reads('engagement.myRole'),
    status: Ember.computed.reads('model.status'),
    engagementStatus: Ember.computed.reads('engagement.status'),
    isCoachee: Ember.computed('engagement.coachee.id', 'account.activeEmployee.id', function () {
      return this.get('engagement.coachee.id') === this.get('account.activeEmployee.id');
    }),
    isCoach: Ember.computed('account.activeEmployee.id', 'coach.id', 'engagement.coach.id', 'model.{coach.id,modelName}', function () {
      if (this.model.modelName === 'coaching-request') {
        return this.get('model.coach.id') === this.get('account.activeEmployee.id');
      } else {
        return this.get('engagement.coach.id') === this.get('account.activeEmployee.id');
      }
    }),
    accountIsCoach: Ember.computed.reads('account.activeEmployee.isCoach'),

    isOrContains(target, value) {
      if (Ember.isArray(value)) {
        return value.includes(target);
      } else if (value) {
        return value === target;
      }
    },

    engagementStatusIsAny(...values) {
      return values.any(v => {
        return this.isOrContains(this.get('engagementStatus'), v);
      });
    },

    statusIsAny(...values) {
      return values.any(v => {
        return this.isOrContains(this.get('status'), v);
      });
    },

    roleIsAny(...values) {
      const role = this.get('role');
      const activeRoles = this.get('account.activeRoles');
      const accountRoles = role ? [role, ...activeRoles] : activeRoles;
      return accountRoles.some(activeRole => {
        return values.includes(activeRole);
      });
    },

    roles: Ember.computed('role', function () {
      return [this.get('role')];
    })
  });

  _exports.default = _default;
});