define("coachlogix/pods/components/v2/engagement-template/settings/stakeholders-card/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EngagementSettingsStakeholdersCardComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec(_class = (_class2 = (_temp = class EngagementSettingsStakeholdersCardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);
    }

    *createStakeholders(employee) {
      let user = yield employee.get('user'); // Check for duplicates

      let admins = this.engagement.get('admins');
      let adminAlreadyExists = admins.any(admin => admin.get('user.id') === user.id);

      if (adminAlreadyExists) {
        let tKey = this.roleName === 'engagement_manager' ? 'engagement.settings.manager-already-added' : 'engagement.settings.viewer-already-added';
        this.clToaster.info(this.intl.t(tKey));
        this.set('addStakeholder', false);
        this.set('roleName', null);
        return;
      } // Create new role user if it hasn't been added


      let roles = yield this.store.query('role', {
        filter: {
          name: this.roleName
        }
      });
      let role = roles.firstObject;
      let roleUser = this.store.createRecord('role-user', {
        user: user,
        role: role,
        adminable: this.engagement
      });

      try {
        let savedUser = yield roleUser.save();
        admins.addObject(savedUser);
        this.set('addStakeholder', false);
        this.set('roleName', null);

        if (role.get('displayName') === 'Engagement Manager') {
          this.clToaster.success('Engagement editor added successfully.');
        } else {
          this.clToaster.success(`${role.get('displayName').toLowerCase().capitalize()}` + ' added successfully.');
        }
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "createStakeholders", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createStakeholders"), _class2.prototype)), _class2)) || _class);
  var _default = EngagementSettingsStakeholdersCardComponent;
  _exports.default = _default;
});