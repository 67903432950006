define("coachlogix/pods/components/v2/summary-cards/contact/component", ["exports", "@ember-decorators/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SummaryContactComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed('user', 'properties'), _dec(_class = (_class2 = (_temp = class SummaryContactComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "properties", [{
        name: 'phone',
        label: this.intl.t('ui.phone'),
        icon: 'phone'
      }, {
        name: 'displayAddress',
        label: this.intl.t('settings.basic-info.address'),
        icon: 'location-on'
      }, {
        name: 'timezone.name',
        label: this.intl.t('ui.timezone'),
        icon: 'public'
      }, {
        name: 'gender',
        label: this.intl.t('ui.gender-label'),
        icon: 'wc',
        valueFunc: v => this.intl.t(`ui.genders.${v}`)
      }, {
        name: 'dateOfBirth',
        label: this.intl.t('ui.date-of-birth'),
        icon: 'cake',
        valueFunc: v => (0, _moment.default)(v).format('L')
      }]);
    }

    get values() {
      return this.properties.map(p => {
        let value = Ember.get(this.user, p.name);

        if (value && p.valueFunc) {
          value = p.valueFunc(value);
        }

        return { ...p,
          value
        };
      }).filter(p => Boolean(p.value));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "values", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "values"), _class2.prototype)), _class2)) || _class);
  var _default = SummaryContactComponent;
  _exports.default = _default;
});