define("coachlogix/helpers/map-get", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mapGet = mapGet;
  _exports.default = void 0;

  function mapGet([map, key]) {
    (true && !(map) && Ember.assert('map must be passed in', map));
    (true && !(key) && Ember.assert('key must be passed in', key));
    return map.get(key);
  }

  var _default = Ember.Helper.helper(mapGet);

  _exports.default = _default;
});