define("coachlogix/pods/components/v2/avatar-for/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let AvatarFor = (_dec = (0, _component.tagName)(''), _dec(_class = (_temp = class AvatarFor extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "size", 24);
    }

  }, _temp)) || _class);
  var _default = AvatarFor;
  _exports.default = _default;
});