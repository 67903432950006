define("coachlogix/pods/index/route", ["exports", "ember-concurrency-decorators", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberConcurrencyDecorators, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class IndexRoute extends Ember.Route.extend(_authenticatedRouteMixin.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "domainMasking", _descriptor2, this);

      _initializerDefineProperty(this, "routeTracker", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "clToaster", _descriptor6, this);
    }

    async beforeModel(transition) {
      super.beforeModel(...arguments);

      if (!transition.isAborted) {
        this.domainMasking.getData();
        await this.account.loadInitialData.perform();
        this.routeTracker.startTracking();

        if (this.account.meRequest.redirect_info && !this.account.didRedirect) {
          let meRequest = this.account.meRequest;
          let redirectInfo = meRequest.redirect_info;
          let redirectRoute = redirectInfo.redirect_route;
          let redirectParams = redirectInfo.redirect_params;
          this.account.didRedirect = true;
          this.transitionTo(redirectRoute, ...redirectParams);
        }
      }
    }

    *redirectToMarketPlaceProfile(profileId) {
      // need profile slug
      let profile = yield this.store.findRecord('profile', profileId);
      this.router.transitionTo('marketplace.profile', profile.currentUrl, {
        queryParams: {
          initInterview: true
        }
      });
    }

    redirect() {
      if (!this.account.activeAccount || (this.account.activeAccount.isUnclaimed || this.account.activeAccount.isPending || this.account.activeAccount.isInvited) && this.account.activeEmployee.isGlobalAdmin) {
        this.replaceWith('account-setup');
        return;
      }

      let {
        orderId,
        slug,
        profileId
      } = this.paramsFor('index');

      if (orderId) {
        if (!this.account.activeAccount.isMember) {
          this.router.transitionTo('dashboard', {
            queryParams: {
              marketplaceError: true
            }
          });
        } else {
          this.router.transitionTo('my-marketplace.orders.order', orderId);
        }
      }

      if (slug) {
        if (!this.account.activeAccount.isMember) {
          this.router.transitionTo('dashboard', {
            queryParams: {
              marketplaceError: true
            }
          });
        } else {
          this.router.transitionTo('marketplace.profile', slug, {
            queryParams: {
              initInterview: true
            }
          });
        }
      }

      if (profileId) {
        if (!this.account.activeAccount.isMember) {
          this.router.transitionTo('dashboard', {
            queryParams: {
              marketplaceError: true
            }
          });
        } else {
          this.redirectToMarketPlaceProfile.perform(profileId);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "domainMasking", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "routeTracker", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "redirectToMarketPlaceProfile", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToMarketPlaceProfile"), _class.prototype)), _class));
  _exports.default = IndexRoute;
});