define("coachlogix/services/cl-custom-properties", ["exports", "@coachlogix/cl-ui/services/cl-custom-properties"], function (_exports, _clCustomProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _clCustomProperties.default;
    }
  });
});