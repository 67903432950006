define("coachlogix/pods/engagement/program/program-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lq3PY0TB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"model\",\"programItem\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cl-w-full cl-h-full cl-flex cl-justify-center cl-items-center\"],[12],[2,\"\\n    \"],[8,\"cl-progress-circular\",[],[[\"@size\"],[48]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"v2/engagement/detail-view\",[],[[\"@item\"],[[32,0,[\"model\",\"programItem\",\"value\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/engagement/program/program-item/template.hbs"
    }
  });

  _exports.default = _default;
});