define("coachlogix/models/country", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    capital: (0, _model.attr)('string'),
    citizenship: (0, _model.attr)('string'),
    countryCode: (0, _model.attr)('string'),
    currency: (0, _model.attr)('string'),
    currencyCode: (0, _model.attr)('string'),
    currencySubUnit: (0, _model.attr)('string'),
    fullName: (0, _model.attr)('string'),
    iso31662: (0, _model.attr)('string'),
    iso31663: (0, _model.attr)('string'),
    regionCode: (0, _model.attr)('string'),
    subRegionCode: (0, _model.attr)('string'),
    eea: (0, _model.attr)('boolean'),
    swapPostalCode: (0, _model.attr)('boolean'),
    swapCurrencySymbol: (0, _model.attr)('boolean'),
    thousandSeparator: (0, _model.attr)('string'),
    decimalSeparator: (0, _model.attr)('string')
  });

  _exports.default = _default;
});