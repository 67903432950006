define("coachlogix/pods/dashboard/controller", ["exports", "ember-concurrency-decorators", "lodash.sortedindexby"], function (_exports, _emberConcurrencyDecorators, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.reads('model.availableWidgets'), _dec6 = Ember.computed.reads('model.widgetColumns'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class DashboardController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "clToaster", _descriptor4, this);

      _defineProperty(this, "marketplaceError", false);

      _defineProperty(this, "queryParams", ['approveContractId', 'marketplaceError', 'coachingRequestId']);

      _defineProperty(this, "coachingRequestId", null);

      _defineProperty(this, "approveContractId", null);

      _initializerDefineProperty(this, "availableWidgets", _descriptor5, this);

      _initializerDefineProperty(this, "widgetColumns", _descriptor6, this);
    }

    buildConfigString() {
      let config = this.widgetColumns.map(c => c.map(w => ({
        id: w.id
      })));
      return JSON.stringify(config);
    }

    *saveChanges() {
      let employeeSettings = yield this.account.activeEmployee.get('employeeSettings');

      if (!employeeSettings) {
        employeeSettings = this.store.createRecord('employee-setting', {
          employee: this.account.activeEmployee
        });
      }

      employeeSettings.set('customDashboard', this.buildConfigString());
      yield employeeSettings.save();
      this.set('isEditing', false);
    }

    discardChanges() {
      this.set('widgetColumns', this.widgetColumnsCopy);
      this.set('availableWidgets', this.availableWidgetsCopy);
      this.widgetColumnsCopy = null;
      this.availableWidgetsCopy = null;
      this.set('isEditing', false);
    }

    startEditing() {
      let widgetColumnsCopy = JSON.parse(JSON.stringify(this.widgetColumns));
      let availableWidgetsCopy = JSON.parse(JSON.stringify(this.availableWidgets)); // save copy for potential restore later

      this.widgetColumnsCopy = widgetColumnsCopy;
      this.availableWidgetsCopy = availableWidgetsCopy;
      this.set('isEditing', true);
    }

    onDragEnd({
      sourceList,
      sourceIndex,
      targetList,
      targetIndex
    }) {
      if (sourceList === targetList && sourceIndex === targetIndex) return;
      let item = sourceList.objectAt(sourceIndex);
      sourceList.removeAt(sourceIndex);
      targetList.insertAt(targetIndex, item);
    }

    determineForeignPosition({
      draggedItem,
      items
    }) {
      return (0, _lodash.default)(items, draggedItem, 'position');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "availableWidgets", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "widgetColumns", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startEditing", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "startEditing"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragEnd", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onDragEnd"), _class.prototype)), _class));
  _exports.default = DashboardController;
});