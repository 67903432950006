define("coachlogix/models/account", ["exports", "@ember-data/model", "ember-concurrency", "ember-paper/utils/generate-palette", "ember-paper/utils/palettes", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrency, _generatePalette, _palettes, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    address2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    postalCode: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    altPhone: (0, _model.attr)('string'),
    poCode: (0, _model.attr)('string'),
    language: (0, _model.attr)('string'),
    tagline: (0, _model.attr)('string'),
    accountType: (0, _model.attr)('string'),
    // independent_coach, business, enterprise
    seatsUsed: (0, _model.attr)('number'),
    seatsPurchased: (0, _model.attr)('number'),
    seatsAllocated: (0, _model.attr)('number'),
    seatsAllowed: Ember.computed('seatsPurchased', 'seatsAllocated', function () {
      return (this.get('seatsPurchased') || 0) + (this.get('seatsAllocated') || 0);
    }),
    currentBillingPlan: (0, _model.attr)('string'),
    trialEndsAt: (0, _model.attr)('date'),
    planEndsAt: (0, _model.attr)('date'),
    engagementsAllowed: (0, _model.attr)('number'),
    engagementsUsed: (0, _model.attr)('number'),
    freeEngagementUsed: (0, _model.attr)('boolean'),
    remainingEngagements: Ember.computed('currentBillingPlan', 'engagementsAllowed', 'engagementsUsed', function () {
      let plan = this.get('currentBillingPlan') || '';

      if (plan.includes('_plus') || plan.includes('_pro')) {
        return 1;
      }

      return (this.get('engagementsAllowed') || 0) - (this.get('engagementsUsed') || 0);
    }),
    logo: (0, _model.attr)('string'),
    fullLogo: (0, _model.attr)('string'),
    coverPhoto: (0, _model.attr)('string'),
    primaryColor: (0, _model.attr)('string'),
    accentColor: (0, _model.attr)('string'),
    isSidenavWhite: (0, _model.attr)('boolean'),
    website: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    facebook: (0, _model.attr)('string'),
    linkedin: (0, _model.attr)('string'),
    twitter: (0, _model.attr)('string'),
    emailSignature: (0, _model.attr)('string'),
    defaultCoachCapacity: (0, _model.attr)('number'),
    // this attribute is just used during account setup
    employeeTitle: (0, _model.attr)('string'),
    awards: (0, _model.hasMany)('award', {
      async: true,
      inverse: null
    }),
    // title description month year awardDate
    internalCoaching: (0, _model.attr)('boolean'),
    providesCoaching: (0, _model.attr)('boolean'),
    buysCoaching: (0, _model.attr)('boolean'),
    buysIndependentCoaching: (0, _model.attr)('boolean'),
    buysVendorCoaching: (0, _model.attr)('boolean'),
    status: (0, _model.attr)('string'),
    // pending, trial, active, suspended, deleted, unclaimed
    isStripeConnected: (0, _model.attr)('boolean'),
    logoutUrl: (0, _model.attr)('string'),
    intl: Ember.inject.service(),
    ownerAccount: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    accountSummary: (0, _model.belongsTo)('account-summary', {
      async: true,
      inverse: 'account'
    }),
    stripeConnectedAccount: (0, _model.belongsTo)('stripe-connected-account', {
      inverse: null
    }),
    owner: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    primaryContact: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    coach: (0, _model.belongsTo)('employee', {
      inverse: null
    }),
    emailCustomization: (0, _model.belongsTo)('email-customization', {
      inverse: null
    }),
    billingSettings: (0, _model.belongsTo)('billing-setting', {
      inverse: 'account'
    }),
    employees: (0, _model.hasMany)('employee', {
      inverse: 'account'
    }),
    freePlan: (0, _model.belongsTo)('plan', {
      inverse: null
    }),
    subscription: (0, _model.belongsTo)('subscription', {
      inverse: 'account'
    }),
    companyLocations: (0, _model.hasMany)('company-location', {
      inverse: 'account',
      async: true
    }),
    departments: (0, _model.hasMany)('department', {
      inverse: 'account'
    }),
    billableActivities: (0, _model.hasMany)('billable-activity', {
      inverse: 'account'
    }),
    engagements: (0, _model.hasMany)('engagement', {
      inverse: null,
      async: true
    }),
    resourceGroups: (0, _model.hasMany)('resource-group', {
      async: true,
      inverse: 'accounts'
    }),
    resources: (0, _model.hasMany)('resource', {
      async: true,
      inverse: 'accounts'
    }),
    isBusiness: Ember.computed.equal('accountType', 'business'),
    isEnterprise: Ember.computed.equal('accountType', 'enterprise'),
    isIndependentCoach: Ember.computed.equal('accountType', 'independent_coach'),
    isMember: Ember.computed.equal('accountType', 'member'),
    isActive: Ember.computed.equal('status', 'active'),
    isPending: Ember.computed.equal('status', 'pending'),
    isUnclaimed: Ember.computed.equal('status', 'unclaimed'),
    isInvited: Ember.computed.equal('status', 'invited'),
    isFrozen: Ember.computed.equal('status', 'frozen'),
    isPro: Ember.computed('currentBillingPlan', function () {
      let currentPlan = this.get('currentBillingPlan') || '';
      return currentPlan.toUpperCase().includes('ENTERPRISE') || currentPlan.toUpperCase().includes('PRO') || currentPlan.toUpperCase().includes('PLUS');
    }),
    isFree: Ember.computed('currentBillingPlan', function () {
      let currentPlan = this.get('currentBillingPlan') || '';
      return currentPlan.toUpperCase().includes('FREE');
    }),
    marketPlaceCommission: Ember.computed('currentBillingPlan', 'isFree', 'isPro', function () {
      return this.isPro ? '15' : this.currentBillingPlan === 'independent_coach' ? '20' : this.isFree ? '25' : null;
    }),
    coverPhotoStyle: Ember.computed('coverPhoto', function () {
      let coverPhoto = this.get('coverPhoto');

      if (coverPhoto) {
        return Ember.String.htmlSafe(`background-image: url(${this.get('coverPhoto')}); background-size: cover;`);
      }
    }),
    isAccountSidenavWhite: Ember.computed('isMember', 'isSidenavWhite', 'isUnclaimed', 'ownerAccount.isSidenavWhite', function () {
      let isSidenavWhite = this.isSidenavWhite;

      if (this.isMember || this.isUnclaimed) {
        isSidenavWhite = this.get('ownerAccount.isSidenavWhite');
      }

      return isSidenavWhite;
    }),
    updateLogo: (0, _emberConcurrency.task)(function* ({
      filesUploaded = []
    }) {
      try {
        this.set('logo', filesUploaded[0].url);
        yield this.save();
        this.clToaster.success(this.intl.t('models.account.logo-updated'));
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }),
    updateName: (0, _emberConcurrency.task)(function* (name) {
      try {
        this.set('name', name);
        yield this.save();
        this.clToaster.success('Company updated.');
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }),
    invite: (0, _emberConcurrency.task)(function* () {// this.clToaster.info('to do');
    }),

    generateTheme() {
      let primary = this.get('primaryColor') || '#5e1de1';
      let accent = this.get('accentColor') || '#02dc94';
      return {
        primary: (0, _generatePalette.default)(primary),
        accent: (0, _generatePalette.default)(accent),
        warn: _palettes.default.red
      };
    }

  });

  _exports.default = _default;
});