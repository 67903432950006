define("coachlogix/pods/personal-settings/calendar/route", ["exports", "ember-can/computed", "ember-concurrency-decorators"], function (_exports, _computed, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PersonalSettingsCalendarRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _computed.ability)('personal-setting'), _dec5 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec6 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec7 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec8 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class PersonalSettingsCalendarRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "ajax", _descriptor3, this);

      _initializerDefineProperty(this, "personalSettingsAbility", _descriptor4, this);
    }

    redirect() {
      if (!this.personalSettingsAbility.canSyncCalendar) {
        this.replaceWith('personal-settings.general');
      }
    }

    model() {
      return {
        cronofyConnectedAccount: this.loadCronofyAccount.perform(),
        meetingLocations: this.loadMeetingLocations.perform(),
        availabilityPreferences: this.loadAvailabilityPreferences.perform(),
        calendars: this.loadCalendars.perform()
      };
    }

    *loadCronofyAccount() {
      let cronofyConnectedAccount = yield this.account.activeUser.get('cronofyConnectedAccount');

      if (!cronofyConnectedAccount) {
        return;
      }

      return yield this.store.findRecord('cronofy-connected-account', cronofyConnectedAccount.id, {
        include: 'writeable-calendars',
        reload: true
      });
    }

    *loadMeetingLocations() {
      return yield this.store.findAll('meeting-location');
    }

    *loadAvailabilityPreferences() {
      let r = yield this.store.query('availability-preference', {
        filter: {
          user_id: this.account.activeUser.id
        }
      });
      let availabilityPreferences = r.get('firstObject');

      if (!availabilityPreferences) {
        availabilityPreferences = this.store.createRecord('availability-preference', {
          user: this.account.activeUser
        });
        yield availabilityPreferences.save();
      }

      return availabilityPreferences;
    }

    *loadCalendars() {
      if (!this.account.activeUser.belongsTo('cronofyConnectedAccount').id()) {
        return;
      }

      let response = yield this.ajax.request('helpers/calendars');
      return response.calendars.filter(c => !c.calendar_readonly && !c.calendar_deleted);
    }

    resetController(controller) {
      super.resetController(...arguments);
      controller.calendarWasEdited = false;
      controller.showUnsavedDataConfirmation = false;
    }

    willTransition(transition) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      let controller = this.controllerFor('personal-settings.calendar');

      if (controller.calendarWasEdited && !this.abortedTransition) {
        controller.showUnsavedDataConfirmation = true;
        transition.abort();
        this.abortedTransition = transition;
      }
    }

    discardChanges() {
      this.abortedTransition?.retry();
      this.abortedTransition = null;
    }

    stay() {
      this.abortedTransition = null; // eslint-disable-next-line ember/no-controller-access-in-routes

      let controller = this.controllerFor('personal-settings.calendar');
      controller.showUnsavedDataConfirmation = false;
      document.querySelector('.availability-rules__submit')?.scrollIntoView({
        behavior: 'smooth'
      });
      controller.shouldHighlightRules = true;
      Ember.run.later(function () {
        controller.shouldHighlightRules = false;
      }, 2500);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "personalSettingsAbility", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadCronofyAccount", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loadCronofyAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMeetingLocations", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadMeetingLocations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAvailabilityPreferences", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "loadAvailabilityPreferences"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadCalendars", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "loadCalendars"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stay", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "stay"), _class.prototype)), _class));
  var _default = PersonalSettingsCalendarRoute;
  _exports.default = _default;
});