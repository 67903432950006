define("coachlogix/pods/components/v2/engagement/detail-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yLF0HOcW",
    "block": "{\"symbols\":[\"@item\"],\"statements\":[[6,[37,0],[[32,0,[\"itemable\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"isTask\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"v2/engagement/engagement-task/detail-view\",[],[[\"@task\"],[[32,0,[\"itemable\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"isSession\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"v2/engagement/engagement-session/detail-view\",[],[[\"@session\"],[[32,0,[\"itemable\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"isInterview\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"v2/engagement/engagement-coaching-request/detail-view\",[],[[\"@request\"],[[32,0,[\"itemable\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement/detail-view/template.hbs"
    }
  });

  _exports.default = _default;
});