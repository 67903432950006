define("coachlogix/models/engagement-template", ["exports", "@ember-data/model", "ember-concurrency", "coachlogix/models/base-model", "coachlogix/models/goalable", "coachlogix/models/programmable", "coachlogix/models/resourceable"], function (_exports, _model, _emberConcurrency, _baseModel, _goalable, _programmable, _resourceable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_goalable.default, _programmable.default, _resourceable.default, {
    /* Attributes */
    engagementType: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    welcomeMessage: (0, _model.attr)('string'),
    welcomeMessageTitle: (0, _model.attr)('string'),
    totalHours: (0, _model.attr)('number'),
    totalSessions: (0, _model.attr)('number'),
    account: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    clonedFrom: (0, _model.belongsTo)('engagement-template', {
      inverse: null
    }),
    meetingLocation: (0, _model.belongsTo)('meeting-location', {
      inverse: null
    }),
    competencyLibrary: (0, _model.belongsTo)('resource', {
      async: true,
      inverse: null
    }),
    programItems: (0, _model.hasMany)('program-item', {
      async: true,
      inverse: null
    }),
    sessions: (0, _model.hasMany)('engagement-session', {
      async: true,
      inverse: null
    }),
    resourceGroups: (0, _model.hasMany)('resource-group', {
      async: true,
      inverse: 'engagementTemplates'
    }),
    resources: (0, _model.hasMany)('resource', {
      async: true,
      inverse: 'engagementTemplates'
    }),
    adminResources: (0, _model.hasMany)('resource', {
      async: true,
      inverse: 'adminEngagementTemplates'
    }),
    admins: (0, _model.hasMany)('role-user', {
      async: true,
      inverse: null
    }),
    managers: Ember.computed.filterBy('admins', 'roleName', 'engagement_manager'),
    viewers: Ember.computed.filterBy('admins', 'roleName', 'engagement_viewer'),
    intl: Ember.inject.service(),

    /* API */
    updateName: (0, _emberConcurrency.task)(function* (name) {
      this.set('name', name);

      try {
        yield this.save();
        const toastMessage = this.intl.t('program-templates.general.template-updated');
        this.clToaster.success(toastMessage);
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    deleteTemplate: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.destroyRecord();
        this.clToaster.success(this.intl.t('program-templates.settings.delete-success'));
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});