define("coachlogix/validations/account", ["exports", "ember-changeset-validations/validators", "coachlogix/utils/email-regex", "coachlogix/utils/urlish-regex"], function (_exports, _validators, _emailRegex, _urlishRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }),
    email: (0, _validators.validateFormat)({
      regex: _emailRegex.default,
      allowBlank: true
    }),
    defaultCoachCapacity: (0, _validators.validateNumber)({
      allowBlank: true,
      positive: true
    }),
    website: (0, _validators.validateFormat)({
      regex: _urlishRegex.default,
      allowBlank: true,
      message: 'Should be a valid link'
    }),
    facebook: [(0, _validators.validateFormat)({
      regex: _urlishRegex.default,
      allowBlank: true,
      message: 'Should be a valid link'
    }), (0, _validators.validateFormat)({
      regex: /facebook/,
      allowBlank: true,
      message: 'Should be a Facebook link'
    })],
    twitter: [(0, _validators.validateFormat)({
      regex: _urlishRegex.default,
      allowBlank: true,
      message: 'Should be a valid link'
    }), (0, _validators.validateFormat)({
      regex: /twitter/,
      allowBlank: true,
      message: 'Should be a Twitter link'
    })],
    linkedin: [(0, _validators.validateFormat)({
      regex: _urlishRegex.default,
      allowBlank: true,
      message: 'Should be a valid link'
    }), (0, _validators.validateFormat)({
      regex: /linkedin/,
      allowBlank: true,
      message: 'Should be a Linkedin link'
    })]
  };
  _exports.default = _default;
});