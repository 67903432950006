define("coachlogix/pods/components/v2/invite-dialog/types/internal-coach/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "andZzwV5",
    "block": "{\"symbols\":[\"currentStep\",\"@disabled\",\"@onClose\",\"@isSendForm\",\"@createText\",\"@onBack\"],\"statements\":[[8,\"cl-animated-value\",[],[[\"@value\"],[[32,0,[\"step\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[8,\"v2/invite-dialog/common/search-email\",[],[[\"@title\",\"@description\",\"@onBack\",\"@onClose\",\"@onNext\"],[\"Coach email\",\"Enter the coach's email\",[32,6],[32,3],[30,[36,5],[[30,[36,0],[[30,[36,4],[[32,0,[\"employee\"]]],null]],null],[32,0,[\"next\"]]],null]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],2],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"v2/invite-dialog/common/user-details\",[],[[\"@disabled\",\"@employee\",\"@hideDescriptionText\",\"@invalidCoach\",\"@userType\",\"@onBack\",\"@onClose\",\"@onNext\",\"@nextText\",\"@isInternal\",\"@isSendForm\",\"@createText\",\"@showEmployeeDetails\",\"@showMoreDetails\",\"@showLocation\"],[[32,2],[32,0,[\"employee\"]],true,[32,0,[\"invalidCoach\"]],\"coach\",[32,0,[\"previous\"]],[32,3],[30,[36,0],[[32,0,[\"onSave\"]],[32,0,[\"employee\"]]],null],[30,[36,1],[\"ui.add\"],null],true,[32,4],[32,5],true,true,true]],null],[2,\"\\n\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"t\",\"eq\",\"if\",\"mut\",\"queue\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/invite-dialog/types/internal-coach/template.hbs"
    }
  });

  _exports.default = _default;
});