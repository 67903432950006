define("coachlogix/pods/components/v2/invite-dialog/component", ["exports", "@ember-data/model", "ember-ajax/errors", "ember-animated/transitions/move-over", "ember-can/computed", "ember-concurrency"], function (_exports, _model, _errors, _moveOver, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LINKS = [{
    value: 'new-client-company',
    icon: 'business',
    title: 'Sponsored coachee',
    description: 'A coachee sponsored by their company. Click here to find or add the client company and their coachees.',
    route: 'clients',
    can: a => a.get('canSellCoaching')
  }, {
    value: 'new-vendor-company',
    icon: 'business',
    title: 'Vendor contracted coach',
    description: 'A coach who works with a coaching vendor or group of coaches. Click here to find or add the coaching vendor and their coaches.',
    route: 'vendors',
    can: a => a.get('canBuyCoaching')
  }];

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    account: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    tagName: '',
    closeOnSave: true,
    accountAbility: (0, _computed.ability)('account'),
    selectedTypes: Ember.computed('accountAbility.{canAddInternalCoaches,canBuyCoaching,canSellCoaching}', 'inviteTypes', 'types', function () {
      let types = this.get('types') || [];
      let accountAbility = this.get('accountAbility');
      return this.get('inviteTypes').filter(t => {
        return types.includes(Ember.String.dasherize(t.value)) && t.can(accountAbility);
      });
    }),
    selectedLinks: Ember.computed('links', 'accountAbility.{canAddInternalCoaches,canBuyCoaching,canSellCoaching}', function () {
      let links = this.get('links') || [];
      let accountAbility = this.get('accountAbility');
      return LINKS.filter(l => {
        return links.includes(Ember.String.dasherize(l.value)) && l.can(accountAbility);
      });
    }),
    componentName: Ember.computed('selectedType', function () {
      return `v2/invite-dialog/types/${this.selectedType}`;
    }),

    init() {
      this._super(...arguments);

      const intl = this.get('intl');
      this.inviteTypes = [{
        value: 'internal-coachee',
        icon: 'person',
        title: 'Internal coachee',
        description: 'A coachee that is an employee.',
        can: () => true
      }, {
        value: 'directly-contracted-coachee',
        icon: 'person',
        title: intl.t('ui.invite-dialog.invite-types.directly-contracted-coachee.title'),
        description: intl.t('ui.invite-dialog.invite-types.directly-contracted-coachee.description'),
        can: a => a.get('canSellCoaching')
      }, {
        value: 'sponsored-coachee',
        icon: 'business',
        title: intl.t('ui.invite-dialog.invite-types.sponsored-coachee.title'),
        description: intl.t('ui.invite-dialog.invite-types.sponsored-coachee.description'),
        can: a => a.get('canSellCoaching')
      }, {
        value: 'internal-coach',
        icon: 'person',
        title: intl.t('ui.invite-dialog.invite-types.internal-coach.title'),
        description: intl.t('ui.invite-dialog.invite-types.internal-coach.description'),
        can: a => a.get('canAddInternalCoaches') || a.get('canSellCoaching')
      }, {
        value: 'independent-coach',
        icon: 'person',
        title: intl.t('ui.invite-dialog.invite-types.independent-coach.title'),
        description: intl.t('ui.invite-dialog.invite-types.independent-coach.description'),
        can: a => a.get('canBuyCoaching')
      }, {
        value: 'vendor-provided-coach',
        icon: 'business',
        title: intl.t('ui.invite-dialog.invite-types.vendor-provided-coach.title'),
        description: intl.t('ui.invite-dialog.invite-types.vendor-provided-coach.description'),
        can: a => a.get('canBuyCoaching')
      }, {
        value: 'client-company',
        icon: 'business',
        title: intl.t('ui.invite-dialog.invite-types.client-company.title'),
        description: intl.t('ui.invite-dialog.invite-types.client-company.description'),
        can: a => a.get('canSellCoaching')
      }, {
        value: 'external-vendor',
        icon: 'business',
        title: intl.t('ui.invite-dialog.invite-types.external-vendor.title'),
        description: intl.t('ui.invite-dialog.invite-types.external-vendor.description'),
        can: a => a.get('canBuyCoaching')
      }, {
        value: 'employee',
        icon: 'person',
        title: intl.t('ui.invite-dialog.invite-types.employee.title'),
        description: intl.t('ui.invite-dialog.invite-types.employee.description'),
        can: () => true
      }, {
        value: 'request-coach',
        icon: 'person',
        title: intl.t('ui.invite-dialog.invite-types.request-coach.title'),
        description: intl.t('ui.invite-dialog.invite-types.request-coach.description'),
        can: a => a.get('canBuyCoaching')
      }, {
        value: 'request-coachee',
        icon: 'person',
        title: intl.t('ui.invite-dialog.invite-types.request-coachee.title'),
        description: intl.t('ui.invite-dialog.invite-types.request-coachee.description'),
        can: a => a.get('canSellCoaching')
      }, {
        value: 'client-poc',
        icon: 'person',
        title: intl.t('ui.invite-dialog.invite-types.client-poc.title'),
        description: intl.t('ui.invite-dialog.invite-types.client-poc.description'),
        can: () => true
      }, {
        value: 'external-participant',
        icon: 'person',
        title: intl.t('ui.invite-dialog.invite-types.external-participant.title'),
        description: intl.t('ui.invite-dialog.invite-types.external-participant.description'),
        can: () => true
      }];
      let selectedTypes = this.get('selectedTypes');

      if (selectedTypes.length === 1) {
        this.set('selectedType', selectedTypes.get('firstObject.value'));
        this.set('noTypeSelection', true);
      }
    },

    save: (0, _emberConcurrency.task)(function* () {
      let selectedType = this.get('selectedType');

      if (['internal-coachee', 'internal-coach'].includes(selectedType)) {
        yield this.get('saveInternal').perform(...arguments);
      } else if (['directly-contracted-coachee', 'client-company'].includes(selectedType)) {
        yield this.get('saveClient').perform(...arguments);
      } else if (['independent-coach', 'external-vendor'].includes(selectedType)) {
        yield this.get('saveVendor').perform(...arguments);
      } else if (selectedType === 'employee') {
        yield this.get('saveEmployee').perform(...arguments);
      } else if (selectedType === 'request-coach') {
        yield this.get('saveCoachContact').perform(...arguments);
      } else if (selectedType === 'request-coachee') {
        yield this.get('saveCoacheeContact').perform(...arguments);
      } else if (selectedType === 'client-poc') {
        yield this.get('savePocContact').perform(...arguments);
      } else if (selectedType === 'external-participant') {
        yield this.get('saveExternalParticipant').perform(...arguments);
      } else if (selectedType === 'sponsored-coachee') {
        yield this.get('saveSponsoredCoachee').perform(...arguments);
      } else if (selectedType === 'vendor-provided-coach') {
        yield this.get('saveVendorProvidedCoach').perform(...arguments);
      }

      if (this.onClose && this.closeOnSave) {
        this.onClose();
      }
    }),
    saveVendorProvidedCoach: (0, _emberConcurrency.task)(function* ({
      vendorData,
      coachData
    }) {
      let client;

      if (vendorData.company.id) {
        try {
          let clients = yield this.store.query('client', {
            filter: {
              client_account_id: this.account.activeAccount.id,
              account_id: vendorData.company.id
            }
          });

          if (clients.get('firstObject')) {
            client = clients.get('firstObject');
          }
        } catch (e) {// ignore
        }
      }

      if (!client) {
        client = yield this.saveVendor.perform(vendorData, {
          ignoreOnSave: true
        });
      }

      this.set('client', client);
      let clientCoach = yield this.saveCoachContact.perform(coachData, {
        ignoreOnSave: true
      });
      let employee = yield clientCoach.get('employee');

      if (this.get('onSave')) {
        this.get('onSave')(employee);
      }
    }),
    saveSponsoredCoachee: (0, _emberConcurrency.task)(function* ({
      clientData,
      coacheeData
    }) {
      let client;

      if (clientData.company.id) {
        try {
          let clients = yield this.store.query('client', {
            filter: {
              client_account_id: clientData.company.id,
              account_id: this.account.activeAccount.id
            }
          });

          if (clients.get('firstObject')) {
            client = clients.get('firstObject');
          }
        } catch (e) {// ignore
        }
      }

      if (!client) {
        client = yield this.saveClient.perform(clientData, {
          ignoreOnSave: true
        });
      }

      this.set('client', client);
      let clientCoachee = yield this.saveCoacheeContact.perform(coacheeData, {
        ignoreOnSave: true
      });
      let employee = yield clientCoachee.get('employee');

      if (this.get('onSave')) {
        this.get('onSave')(employee);
      }
    }),
    saveInternal: (0, _emberConcurrency.task)(function* (data) {
      let selectedType = this.get('selectedType');

      try {
        let employeeProps = data.getProperties('companyLocation', 'employeeLevel', 'isAdmin', 'employeeTitle', 'department', 'subDepartment', 'workEmail', 'isCoach', 'sendInvitation');

        if (data.get('user')) {
          employeeProps.user = data.get('user');
        } else {
          employeeProps.firstName = data.get('firstName');
          employeeProps.lastName = data.get('lastName');
        }

        if (employeeProps.subDepartment) {
          employeeProps.department = yield employeeProps.subDepartment.get('parent');
        }

        if (employeeProps.isAdmin) {
          employeeProps.role = 'account_global_admin';
        }

        if (selectedType === 'internal-coach') {
          employeeProps.isCoach = true;
        }

        let employee = this.get('store').createRecord('employee', employeeProps);
        yield employee.save();

        if (this.get('onSave')) {
          this.get('onSave')(employee);
        }

        this.clToaster.success(this.selectedType.includes('coachee') ? 'Internal coachee added.' : 'Internal coach added.');
      } catch (e) {
        this.handleError(e);
      }
    }),
    saveEmployee: (0, _emberConcurrency.task)(function* (data) {
      try {
        let employeeProps = data.getProperties('companyLocation', 'employeeLevel', 'isAdmin', 'employeeTitle', 'department', 'subDepartment', 'workEmail', 'isCoach', 'sendInvitation');

        if (data.get('user')) {
          employeeProps.user = data.get('user');
        } else {
          employeeProps.firstName = data.get('firstName');
          employeeProps.lastName = data.get('lastName');
        }

        if (employeeProps.subDepartment) {
          employeeProps.department = yield employeeProps.subDepartment.get('parent');
        }

        if (employeeProps.isAdmin) {
          employeeProps.role = 'account_global_admin';
        }

        let employee = this.get('store').createRecord('employee', employeeProps);
        yield employee.save();

        if (this.get('onSave')) {
          this.get('onSave')(employee);
        }

        this.clToaster.success('Employee added.');
      } catch (e) {
        this.handleError(e);
      }
    }),
    saveClient: (0, _emberConcurrency.task)(function* ({
      employee,
      poc,
      company
    }, opts) {
      let data = {
        send_invitation: false
      };

      if (employee) {
        let user = employee.get('user');
        data.send_invitation = employee.get('sendInvitation');

        if (user) {
          data.coachee_user_id = user.get('id');
        } else {
          data.coachee = {
            first_name: employee.get('firstName'),
            last_name: employee.get('lastName'),
            email: employee.get('workEmail'),
            send_invitation: employee.get('sendInvitation')
          };
        }
      }

      if (poc) {
        let pocUser = poc.get('user');
        data.send_invitation = poc.get('sendInvitation');

        if (pocUser) {
          data.primary_contact_id = pocUser.get('id');
        } else {
          data.primary_contact = {
            first_name: poc.get('firstName'),
            last_name: poc.get('lastName'),
            email: poc.get('workEmail'),
            send_invitation: poc.get('sendInvitation')
          };
        }
      }

      if (company) {
        if (typeof company === 'string') {
          data.client_account = {
            name: company
          };
        } else {
          data.client_account_id = company.get('id'); // if company is active, we force an invitation send

          if (company.get('isActive') && data.primary_contact) {
            data.primary_contact.send_invitation = true;
            data.send_invitation = true;
          }
        }
      }

      if (employee && !company) {
        data.client_type = 'direct_coachee';
      } else {
        data.client_type = 'company';
      }

      try {
        let response = yield this.get('ajax').post('/clients?include=client-coachees.employee,account,client-account', {
          data: {
            data: {
              attributes: data,
              type: 'clients'
            }
          }
        });
        this.get('store').pushPayload(response);
        let createdEmployee = null;

        if (employee) {
          let employees = response.included.filterBy('type', 'employee');
          let email = employee.get('workEmail') || employee.get('user.email');
          let employeeId = employees.findBy('attributes.workEmail', email).id;
          createdEmployee = this.get('store').peekRecord('employee', employeeId);
        }

        let client = this.get('store').peekRecord('client', response.data.id);

        if (this.get('onSave') && !opts.ignoreOnSave) {
          this.get('onSave')(createdEmployee, client);
        }

        this.clToaster.success('Client was added.');
        return client;
      } catch (e) {
        this.handleError(e);
      }
    }),
    saveVendor: (0, _emberConcurrency.task)(function* ({
      employee,
      poc,
      company
    }, opts) {
      let data = {};

      if (employee) {
        let user = employee.get('user');

        if (user) {
          data.coach_user_id = user.get('id');
        } else {
          data.coach = {
            first_name: employee.get('firstName'),
            last_name: employee.get('lastName'),
            email: employee.get('workEmail'),
            send_invitation: true
          };
        }
      }

      if (poc) {
        let pocUser = poc.get('user');

        if (pocUser) {
          data.lead_id = pocUser.get('id');
          data.lead = {
            send_invitation: true
          };
        } else {
          data.lead = {
            first_name: poc.get('firstName'),
            last_name: poc.get('lastName'),
            email: poc.get('workEmail'),
            send_invitation: true
          };
        }
      }

      if (company) {
        if (typeof company === 'string') {
          data.account = {
            name: company
          };
        } else {
          data.account_id = company.get('id'); // if company is active, we force an invitation send

          if (company.get('isActive') && data.lead) {
            data.lead.send_invitation = true;
          }
        }
      }

      if (employee && company) {
        data.vendor_type = 'provider';
      } else if (employee && !company) {
        data.vendor_type = 'independent_coach';
        data.send_invitation = true;
      } else if (!employee && company) {
        data.vendor_type = 'provider';
      }

      data.vendor = true;

      try {
        let response = yield this.get('ajax').post('/clients?include=client-pocs.employee,account,client-account', {
          data: {
            data: {
              attributes: data,
              type: 'clients'
            }
          }
        });
        this.get('store').pushPayload(response);
        let createdEmployee = null;

        if (employee) {
          let employees = response.included.filterBy('type', 'employee');
          let email = employee.get('workEmail') || employee.get('user.email');
          let employeeId = employees.findBy('attributes.workEmail', email).id;
          createdEmployee = this.get('store').peekRecord('employee', employeeId);
        }

        let vendor = this.get('store').peekRecord('client', response.data.id);

        if (this.get('onSave') && !opts.ignoreOnSave) {
          this.get('onSave')(createdEmployee, vendor);
        }

        if (data.vendor_type === 'independent_coach') {
          this.clToaster.success('Independent coach was added.');
        } else {
          this.clToaster.success('Vendor was added.');
        }

        return vendor;
      } catch (e) {
        this.handleError(e);
      }
    }),
    saveCoachContact: (0, _emberConcurrency.task)(function* (data, opts) {
      try {
        let client = yield this.get('client');
        let employee;

        if (data instanceof _model.default && data.get('isLoaded')) {
          employee = data;
        } else {
          let account = yield client.get('getOtherAccount').perform();
          let employeeProps = {};
          employeeProps.isCoach = true;
          employeeProps.account = account;
          employeeProps.workEmail = data.get('workEmail');
          employeeProps.sendInvitation = data.get('sendInvitation');

          if (data.get('user')) {
            employeeProps.user = data.get('user');
          } else {
            employeeProps.firstName = data.get('firstName');
            employeeProps.lastName = data.get('lastName');
          }

          employee = this.get('store').createRecord('employee', { ...employeeProps
          });
          yield employee.save();
        }

        let clientCoach = this.get('store').createRecord('client-coach', {
          client,
          employee
        });
        yield clientCoach.save();

        if (this.get('onSave') && !opts.ignoreOnSave) {
          this.get('onSave')();
        }

        if (this.get('requestingCoach')) {
          this.clToaster.success(this.get('intl').t('ui.invite-dialog.coach-requested'));
        } else {
          this.clToaster.success(this.get('intl').t('ui.invite-dialog.coach-added'));
        }

        return clientCoach;
      } catch (e) {
        this.handleError(e);
      }
    }),
    saveCoacheeContact: (0, _emberConcurrency.task)(function* (data, opts) {
      try {
        let client = yield this.get('client');
        let employee;

        if (data instanceof _model.default && data.get('isLoaded')) {
          employee = data;
        } else {
          let account = yield client.get('getOtherAccount').perform();
          let employeeProps = {};
          employeeProps.account = account;
          employeeProps.workEmail = data.get('workEmail');
          employeeProps.sendInvitation = data.get('sendInvitation');

          if (data.get('user')) {
            employeeProps.user = data.get('user');
          } else {
            employeeProps.firstName = data.get('firstName');
            employeeProps.lastName = data.get('lastName');
          }

          employee = this.get('store').createRecord('employee', { ...employeeProps
          });
          yield employee.save();
        }

        let clientCoachee = this.get('store').createRecord('client-coachee', {
          client,
          employee
        });
        yield clientCoachee.save();

        if (this.get('onSave') && !opts.ignoreOnSave) {
          this.get('onSave')();
        }

        if (this.get('requestingCoachee')) {
          this.clToaster.success(this.get('intl').t('ui.invite-dialog.coachee-requested'));
        } else {
          this.clToaster.success(this.get('intl').t('ui.invite-dialog.coachee-added'));
        }

        return clientCoachee;
      } catch (e) {
        this.handleError(e);
      }
    }),
    savePocContact: (0, _emberConcurrency.task)(function* (data) {
      try {
        let client = yield this.get('client');
        let employee;

        if (data instanceof _model.default && data.get('isLoaded')) {
          employee = data;
        } else {
          let account = yield client.get('getOtherAccount').perform();
          let employeeProps = {};
          employeeProps.account = account;
          employeeProps.workEmail = data.get('workEmail');
          employeeProps.sendInvitation = data.get('sendInvitation');

          if (data.get('user')) {
            employeeProps.user = data.get('user');
          } else {
            employeeProps.firstName = data.get('firstName');
            employeeProps.lastName = data.get('lastName');
          }

          employee = this.get('store').createRecord('employee', { ...employeeProps
          });
          yield employee.save();
        }

        let clientPOC = this.get('store').createRecord('client-poc', {
          client,
          employee
        });
        yield clientPOC.save();

        if (this.get('onSave')) {
          this.get('onSave')();
        }

        this.clToaster.success(this.get('intl').t('ui.invite-dialog.poc-added'));
      } catch (e) {
        this.handleError(e);
      }
    }),
    saveExternalParticipant: (0, _emberConcurrency.task)(function* (data) {
      try {
        let existingEmployee = yield data.get('user.employee'); // If the user already exists, no need to create a new one.

        if (existingEmployee) {
          this.get('onSave')(existingEmployee);
          return;
        }

        let user = this.get('store').createRecord('user', {
          firstName: data.get('firstName'),
          lastName: data.get('lastName'),
          email: data.get('workEmail')
        });
        yield user.save();
        let employee = yield user.get('employee');

        if (this.get('onSave')) {
          this.get('onSave')(employee);
        }
      } catch (e) {
        this.clToaster.error(e);
      }
    }),

    handleError(e) {
      if ((0, _errors.isBadRequestError)(e) || (0, _errors.isConflictError)(e)) {
        let errors = e.payload.errors || [];

        if (errors.length > 0) {
          let error = errors[0];
          this.clToaster.error(this.intl.t(`ui.invite-dialog.errors.${error.code}`));
        }
      } else {
        this.clToaster.error(e);
      }
    },

    rules({
      oldItems,
      newItems
    }) {
      if (newItems[0] && !oldItems[0]) {
        return _moveOver.toLeft;
      } else {
        return _moveOver.toRight;
      }
    }

  });

  _exports.default = _default;
});