define("coachlogix/models/contract-adjustment-request", ["exports", "@ember-data/model", "coachlogix/models/base-model", "coachlogix/models/commentable"], function (_exports, _model, _baseModel, _commentable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CONTRACT_ADJUSTMENT_REQUEST_STATUSES = void 0;
  const CONTRACT_ADJUSTMENT_REQUEST_STATUSES = ['approved', 'draft', 'pending', 'rejected'];
  _exports.CONTRACT_ADJUSTMENT_REQUEST_STATUSES = CONTRACT_ADJUSTMENT_REQUEST_STATUSES;

  var _default = _baseModel.default.extend(_commentable.default, {
    status: (0, _model.attr)('string'),
    adjustmentReason: (0, _model.attr)('string'),
    approverNotes: (0, _model.attr)('string'),
    contract: (0, _model.belongsTo)('contract', {
      inverse: 'contractAdjustmentRequests'
    }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    approver: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    contractAdjustmentRequestFields: (0, _model.hasMany)('contract-adjustment-request-field', {
      inverse: 'contractAdjustmentRequest'
    })
  });

  _exports.default = _default;
});