define("coachlogix/pods/settings/marketplace/publish-success-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ApdB1aOl",
    "block": "{\"symbols\":[\"dialog\",\"&attrs\",\"@onClose\",\"@user\"],\"statements\":[[8,\"cl-dialog\",[[24,0,\"cl-w-40\"],[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[[24,0,\"cl-justify-end\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"close\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"content\"]],[[24,0,\"cl-pb-4 cl-flex cl-flex-col cl-items-center\"]],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"v2/avatar-for\",[[4,[38,0],[[32,0,[\"showConfetti\"]]],null],[4,[38,1],[\"click\",[32,0,[\"showConfetti\"]]],null]],[[\"@model\",\"@size\"],[[32,4],70]],null],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"cl-title-3 cl-my-3 cl-text-center cl-px-2\"],[12],[1,[30,[36,2],[\"settings.marketplace.publish-success-dialog.title\"],null]],[13],[2,\"\\n    \"],[8,\"cl-button\",[[24,\"target\",\"_blank\"]],[[\"@primary\",\"@href\"],[true,[31,[[32,0,[\"origin\"]],\"/\",[32,4,[\"profile\",\"currentUrl\"]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,2],[\"settings.marketplace.publish-success-dialog.view-profile\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"on\",\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/settings/marketplace/publish-success-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});