define("coachlogix/models/notification", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    typeKey: (0, _model.attr)('string'),
    notificationType: (0, _model.attr)('string'),
    // 'other' or 'invitation'
    token: (0, _model.attr)('string'),
    read: (0, _model.attr)('boolean'),
    createdBy: (0, _model.attr)('string'),
    notificationSubject: (0, _model.attr)('string'),
    notificationBody: (0, _model.attr)('string'),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    receiver: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    notifiable: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      inverse: null
    }),
    targetable: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      inverse: null
    }),
    tenant: (0, _model.belongsTo)('tenant', {
      inverse: null
    }),
    messageType: (0, _model.belongsTo)('messaging-type', {
      inverse: null
    }),
    isInvitation: Ember.computed.equal('notificationType', 'invitation')
  });

  _exports.default = _default;
});