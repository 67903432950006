define("coachlogix/pods/client/coachees/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ClientCoacheesRoute extends Ember.Route {
    model() {
      return {
        clientTask: this.modelFor('client').clientTask
      };
    }

  }

  _exports.default = ClientCoacheesRoute;
});