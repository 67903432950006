define("coachlogix/pods/form/form-submission/question/dropdown/component", ["exports", "coachlogix/pods/form/form-submission/question/radio/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FormQuestionDropdownComponent extends _component.default {}

  _exports.default = FormQuestionDropdownComponent;
});