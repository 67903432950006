define("coachlogix/pods/employees/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TJfzKj+7",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,1],[\"page-title\"],null]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"cl-title-1\"],[12],[2,\" \"],[1,[30,[36,0],[\"settings.company-members.title.default\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-body-2\"],[12],[1,[30,[36,0],[\"settings.company-members.subtitle.default\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"cl-container cl-mx-auto cl-p-4 cl-flex\"],[12],[2,\"\\n  \"],[8,\"employees/employees-table\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"get-element-by-id\",\"-in-el-null\",\"in-element\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/employees/template.hbs"
    }
  });

  _exports.default = _default;
});