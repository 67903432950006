define("coachlogix/models/milestone", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    description: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    createdDate: (0, _model.attr)('date'),
    dueDate: (0, _model.attr)('date'),
    completedDate: (0, _model.attr)('date'),
    status: (0, _model.attr)('string', {
      defaultValue: 'incomplete'
    }),
    engagement: (0, _model.belongsTo)('engagement', {
      async: true,
      inverse: null
    }),
    roadmapItem: (0, _model.belongsTo)('roadmapItem', {
      inverse: null
    }),
    assignee: (0, _model.belongsTo)('user', {
      async: true,
      inverse: null
    }),

    /* computed */
    wasConducted: Ember.computed.equal('status', 'conducted'),
    engagementIsActive: Ember.computed.alias('engagement.isActive'),
    isComplete: Ember.computed.notEmpty('completedDate'),
    isPublished: Ember.computed.notEmpty('publishedDate'),
    isSent: Ember.computed.notEmpty('publishedDate')
  });

  _exports.default = _default;
});