define("coachlogix/pods/outside/feedback/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FeedbackRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class FeedbackRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clTheme", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "native", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    async beforeModel() {
      // try to load a token from the mobile app
      try {
        let token = await this.native.getToken();
        this.ajax.set('tempToken', token);
      } catch (e) {
        // ignore error
        return;
      }
    }

    model({
      assignment_id
    }) {
      return {
        modelTask: this.loadGoalAndAssignment.perform(assignment_id)
      };
    }

    *loadGoalAndAssignment(assignment_id) {
      let assignment = yield this.store.findRecord('assignment', assignment_id, {
        reload: true,
        include: 'assignable'
      });
      let feedbackTaskId = assignment.belongsTo('assignable').id();
      let include = ['taskable.assignable', 'user.account', 'taskable.goalable.coach.user.account', 'taskable.goalable.coachee.user.account', 'taskable.reviews'].join(',');
      let feedbackTask = yield this.store.findRecord('task', feedbackTaskId, {
        reload: true,
        include
      });
      let goalAssignment = yield feedbackTask.get('taskable');
      let user = yield feedbackTask.get('user');
      let account = yield user.get('account');
      let engagement = yield goalAssignment.get('goalable');
      let reviews = yield goalAssignment.get('reviews');
      let didSubmit = reviews.any(r => {
        let userId = r.belongsTo('user').id();
        return user.id === userId;
      });
      this.clTheme.setTheme({
        primary: account.primaryColor,
        secondary: account.accentColor
      });
      yield engagement.get('coach');
      yield engagement.get('coachee');
      return {
        didSubmit,
        goal: goalAssignment,
        assignment: goalAssignment,
        account,
        engagement
      };
    }

    onReviewSaved(reviewId) {
      // inform mobile app that the review has finished
      this.native.reviewFinished(reviewId);
    }

    onReviewError(e) {
      // inform mobile app that the review has errored
      this.native.reviewError(e.toString());
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clTheme", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "native", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadGoalAndAssignment", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loadGoalAndAssignment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onReviewSaved", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onReviewSaved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onReviewError", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onReviewError"), _class.prototype)), _class));
  _exports.default = FeedbackRoute;
});