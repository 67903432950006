define("coachlogix/models/competenciable", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    accountService: Ember.inject.service('account'),
    clToaster: Ember.inject.service(),
    isCompetencyLibrary: Ember.computed.equal('resourceType', 'competency-library'),
    competencies: (0, _model.hasMany)('competency', {
      async: true,
      inverse: null
    }),
    createCompetency: (0, _emberConcurrency.task)(function* () {// alert('stub');
    }),
    updateCompetencies: (0, _emberConcurrency.task)(function* (competencies) {
      this.set('competencies', competencies);
      yield this.get('saveTask').perform();
    })
  });

  _exports.default = _default;
});