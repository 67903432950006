define("coachlogix/pods/vendors/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+1OGpVGs",
    "block": "{\"symbols\":[\"menu\",\"content\",\"type\",\"@onStatusChange\",\"&attrs\"],\"statements\":[[8,\"cl-menu\",[[17,5]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"trigger\"]],[[16,0,[31,[\"cl-absolute cl-top-0 cl-right-0 cl-h-full cl-flex cl-items-center cl-px-1 cl-border-solid cl-border-0 cl-border-l cl-border-pale-grey \",[30,[36,5],[[32,1,[\"isOpen\"]],\"cl-bg-strong-grey cl-text-strong-grey-contrast\"],null],\" cl-rounded-r-sm cl-transition-colors cl-duration-500 cl-ease-out-exp\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-font-semibold\"],[12],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,3],[\"vendors.type.\",[32,0,[\"filter\"]]],null]],null]],[2,\"\\n      \"],[8,\"cl-icon\",[],[[\"@icon\"],[[30,[36,5],[[32,1,[\"isOpen\"]],\"arrow_drop_up\",\"arrow_drop_down\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[[24,0,\"cl--space-y-1\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[30,[36,6],[\"all\",\"independent_coach\",\"provider\"],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,2,[\"item\"]],[],[[\"@onClick\"],[[30,[36,2],[[30,[36,0],[[30,[36,1],[[32,0,[\"filter\"]]],null],[32,3]],null],[30,[36,0],[[32,4],[32,3]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"cl-radio\",[],[[\"@value\",\"@groupValue\"],[[32,3],[32,0,[\"filter\"]]]],null],[2,\"\\n        \"],[1,[30,[36,4],[[30,[36,3],[\"vendors.type.\",[32,3]],null]],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"mut\",\"queue\",\"concat\",\"t\",\"if\",\"array\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/vendors/filter/template.hbs"
    }
  });

  _exports.default = _default;
});