define("coachlogix/models/resource", ["exports", "@ember-data/model", "ember-data-copyable", "coachlogix/utils/resource-category", "coachlogix/models/competenciable", "coachlogix/models/document", "coachlogix/models/file", "coachlogix/models/form", "coachlogix/models/link", "coachlogix/models/shareable"], function (_exports, _model, _emberDataCopyable, _resourceCategory, _competenciable, _document, _file, _form, _link, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shareable.default.extend(_file.default, _form.default, _link.default, _document.default, _competenciable.default, _emberDataCopyable.default, {
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    resourceType: (0, _model.attr)('string'),
    isEngagement: (0, _model.attr)('boolean', {
      allowNull: true
    }),
    owner: (0, _model.belongsTo)('user', {
      async: true,
      inverse: 'resources'
    }),
    account: (0, _model.belongsTo)('account', {
      async: true,
      inverse: null
    }),
    clonedFrom: (0, _model.belongsTo)('resource', {
      inverse: null
    }),
    // resourceables
    engagements: (0, _model.hasMany)('engagement', {
      async: true,
      inverse: 'resources'
    }),
    engagementSessions: (0, _model.hasMany)('engagement-session', {
      async: true,
      inverse: 'resources'
    }),
    engagementTemplates: (0, _model.hasMany)('engagement-template', {
      async: true,
      inverse: 'resources'
    }),
    adminEngagements: (0, _model.hasMany)('engagement', {
      async: true,
      inverse: 'adminResources'
    }),
    adminEngagementTemplates: (0, _model.hasMany)('engagement-template', {
      async: true,
      inverse: 'adminResources'
    }),
    accounts: (0, _model.hasMany)('account', {
      async: true,
      inverse: 'resources'
    }),
    resourceGroups: (0, _model.hasMany)('resource-group', {
      async: true,
      inverse: 'resources'
    }),
    coachingThemeInfo: (0, _model.hasMany)('coaching-theme-info', {
      async: true,
      inverse: null
    }),
    categoryName: Ember.computed('resourceType', 'resourceInfo.mimetype', function () {
      return (0, _resourceCategory.resourceCategoryName)(this);
    })
  });

  _exports.default = _default;
});