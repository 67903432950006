define("coachlogix/models/engagement-milestone", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    position: (0, _model.attr)('number'),
    additionalInfo: (0, _model.attr)('string'),
    scheduledDate: (0, _model.attr)('string'),
    completedDate: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    engagement: (0, _model.belongsTo)('engagement', {
      inverse: null
    })
  });

  _exports.default = _default;
});