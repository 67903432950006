define("coachlogix/abilities/user-roles-permissions-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    account: Ember.inject.service(),
    activeRoleUsers: Ember.computed.reads('account.activeRoleUsers'),
    activeRoles: Ember.computed.reads('account.activeRoles'),
    permissions: Ember.computed.reads('account.activePermissions'),

    hasAccountRole(...values) {
      let roles = this.get('activeRoles') || [];
      return values.any(v => {
        return roles.includes(v);
      });
    },

    hasOnlyRoles(...values) {
      let roles = this.get('activeRoles') || [];
      return values.length === roles.length && values.every(v => {
        return roles.includes(v);
      });
    },

    hasObjectPermission(permission, object) {
      let roleUsers = this.get('activeRoleUsers') || [];
      return roleUsers.any(ur => {
        let permissions = ur.get('permissions').mapBy('name');
        let adminable = ur.get('adminable.content') || ur.get('adminable');

        if (Ember.isArray(object)) {
          return permissions.includes(permission) && object.includes(adminable);
        } else {
          return permissions.includes(permission) && object === adminable;
        }
      });
    },

    hasObjectTypePermission(permission, type) {
      let roleUsers = this.activeRoleUsers || [];
      return roleUsers.any(ur => {
        if (ur.adminableType === type) {
          let permissions = ur.get('permissions').mapBy('name');

          if (Ember.isArray(type)) {
            return permissions.includes(permission);
          } else {
            return permissions.includes(permission);
          }
        } else {
          return false;
        }
      });
    },

    hasPermission(permissionCheck) {
      let permissions = this.get('permissions');

      if (permissions) {
        if (Ember.isArray(permissionCheck)) {
          permissionCheck.forEach(permission => {
            if (permissions.includes(permission)) {
              return true;
            }
          });
        } else if (permissionCheck) {
          return permissions.includes(permissionCheck);
        }
      }
    }

  });

  _exports.default = _default;
});