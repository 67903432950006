define("coachlogix/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute('form.index'), this.toRoute('form.answers'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.hasClass('reports-transition'), this.use('fade', {
      duration: 100
    }));
    this.transition(this.hasClass('tabs-transition'), this.toValue((toValue, fromValue) => toValue > fromValue), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.hasClass('toggle-by-value'), this.toValue((toValue, fromValue) => toValue && !fromValue), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.hasClass('week-transition'), this.toValue((toValue, fromValue) => toValue.startDate.isBefore(fromValue.startDate)), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.hasClass('left-right-if'), this.toValue(toValue => !!toValue), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.hasClass('right-left-if'), this.toValue(toValue => !!toValue), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.hasClass('scale'), this.toValue(true), this.use('scale', {
      duration: 100
    }), this.reverse('scale', {
      duration: 100
    }));
  }
});