define("coachlogix/pods/components/v2/resources/resource-selection-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JHHol2+U",
    "block": "{\"symbols\":[\"resource\",\"@title\",\"@required\",\"@onClose\",\"@selected\",\"@onChange\",\"@auxButtonClick\",\"@auxButtonLabel\"],\"statements\":[[8,\"cl-selection-dialog\",[[24,0,\"cl-w-32\"]],[[\"@title\",\"@search\",\"@required\",\"@searchField\",\"@onClose\",\"@pagination\",\"@getOptions\",\"@selected\",\"@onChange\",\"@auxButtonClick\",\"@auxButtonLabel\"],[[30,[36,1],[[32,2],[30,[36,0],[\"ui.resources\"],null]],null],true,[32,3],\"name\",[32,4],true,[30,[36,2],[[32,0,[\"getResources\"]]],null],[32,5],[32,6],[32,7],[32,8]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,\"cl-resource-icon\",[],[[\"@resource\"],[[32,1]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"cl-flex\"],[12],[2,\"\\n\"],[6,[37,3],[[32,1,[\"isShared\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"cl-icon\",[[24,0,\"cl-mr-0.5\"]],[[\"@icon\",\"@size\"],[\"group\",14]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[1,[30,[36,0],[\"resources.last-modified\"],[[\"date\"],[[30,[36,4],[[32,1,[\"updatedAt\"]],\"ll\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"or\",\"perform\",\"if\",\"moment-format\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/resources/resource-selection-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});