define("coachlogix/pods/components/v2/goal/master-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sSJ7j7zK",
    "block": "{\"symbols\":[\"@href\",\"@isActive\",\"@onClick\",\"&attrs\",\"@goal\"],\"statements\":[[8,\"cl-list/item\",[[17,4]],[[\"@dense\",\"@href\",\"@isActive\",\"@onClick\"],[true,[32,1],[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,5,[\"completionDate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[],[[\"@icon\",\"@solid\",\"@success\",\"@size\"],[\"check\",true,true,22]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[],[[\"@icon\",\"@bordered\",\"@success\",\"@size\"],[\"gps_fixed\",true,true,22]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"cl-body-1\"],[12],[1,[32,5,[\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/goal/master-item/template.hbs"
    }
  });

  _exports.default = _default;
});