define("coachlogix/pods/components/ui/alert-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    icon: 'info',
    iconSize: 36
  });

  _exports.default = _default;
});