define("coachlogix/pods/components/v2/summary-cards/billable-activity/component", ["exports", "@ember-decorators/component", "ember-ajax/errors", "ember-can/computed", "ember-concurrency-decorators"], function (_exports, _component, _errors, _computed, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SummaryBillableWidgetComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = (0, _computed.ability)('account'), _dec7 = Ember.computed('accountAbility.canSellCoaching', 'client.isClient'), _dec8 = Ember.computed('accountAbility.canBuyCoaching', 'client.isVendor'), _dec(_class = (_class2 = (_temp = class SummaryBillableWidgetComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "clToaster", _descriptor4, this);

      _initializerDefineProperty(this, "accountAbility", _descriptor5, this);

      _defineProperty(this, "selectedTab", void 0);
    }

    // 0 is billable activity, 1 is invoices
    get canSeeBillableActivity() {
      return (this.client ? this.client.isClient : true) && this.accountAbility.canSellCoaching;
    }

    get canSeeInvoices() {
      return (this.client ? this.client.isVendor : true) && this.accountAbility.canBuyCoaching;
    }

    init() {
      super.init(...arguments);

      if (!this.canSeeBillableActivity && this.canSeeInvoices) {
        // the only time we want to show invoices by default
        // is what that is the only tab we can see
        this.selectedTab = 1;
      } else {
        this.selectedTab = 0;
      }
    }

    *loadActivities({
      pagination: {
        pageSize,
        pageNumber
      }
    }) {
      let include = ['invoiceable.participants.user', 'engagement', 'engagement.coach.user', 'engagement.coachee.user', 'engagement.contract', 'client.account', 'client.client-account'].join(',');
      let filter = {};

      if (this.client) {
        filter.client_id = this.client.id;
      }

      let page = {
        size: pageSize,
        number: pageNumber
      };
      let billableActivities = yield this.store.query('billable-activity', {
        filter,
        page,
        include
      });
      return billableActivities.toArray();
    }

    *loadInvoices({
      pagination: {
        pageSize,
        pageNumber
      }
    }) {
      let include = ['engagement', 'engagement.coach.user', 'engagement.coachee.user', 'engagement.coachee.account', 'contract', 'client.account', 'currency', 'invoice-items'].join(',');
      let filter = {
        received: 1,
        status: ['sent', 'partial_paid'].join(',')
      };

      if (this.client) {
        filter.client_id = this.client.id;
      }

      let page = {
        size: pageSize,
        number: pageNumber
      };
      let invoices = yield this.store.query('invoice', {
        filter,
        page,
        include
      });
      return invoices.toArray();
    }

    *createInvoice(activity) {
      let account = this.account.activeAccount;
      let user = this.account.activeUser;
      let invoiceable = yield activity.get('invoiceable');
      let engagement = yield activity.get('engagement');
      let amount = activity.amount;
      let contract = yield engagement.get('contract');
      let hasContract = contract && contract.id;
      let invoice; // Search existing invoices by contract if it has one

      if (hasContract) {
        let invoices = yield this.store.query('invoice', {
          filter: {
            contract_id: contract.id,
            status: 'draft'
          }
        });
        invoice = invoices.get('firstObject');
      } else {
        // If no contract, check using the engagement.
        let invoices = yield this.store.query('invoice', {
          filter: {
            engagement_id: engagement.id,
            status: 'draft'
          }
        });
        invoice = invoices.get('firstObject');
      } // If it doesnt have a match, then create a new invoice.


      if (!invoice) {
        invoice = this.store.createRecord('invoice', {
          engagement,
          user,
          account,
          dueDate: new Date(),
          contract: hasContract ? contract : undefined
        });
        yield invoice.save();
      } else {
        try {
          // Add the item to the invoice.
          let invoiceItem = this.store.createRecord('invoice-item', {
            invoice,
            invoiceable,
            unitPrice: amount
          });
          yield invoiceItem.save();
        } catch (e) {
          this.handleError(e);
        }
      } // Transition to the invoice page.


      yield this.router.transitionTo('invoice', invoice.id);
    }

    handleError(e) {
      if ((0, _errors.isBadRequestError)(e) || (0, _errors.isConflictError)(e)) {
        let errors = e.payload.errors || [];

        if (errors.length > 0) {
          let error = errors[0];
          this.clToaster.error(this.intl.t(`dashboard.billable-activity.errors.${error.code}`));
        }
      } else {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "accountAbility", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "canSeeBillableActivity", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "canSeeBillableActivity"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canSeeInvoices", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "canSeeInvoices"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadActivities", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "loadActivities"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadInvoices", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "loadInvoices"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createInvoice", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createInvoice"), _class2.prototype)), _class2)) || _class);
  var _default = SummaryBillableWidgetComponent;
  _exports.default = _default;
});