define("coachlogix/pods/components/profile/credentials/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProfileCredentialsComponent extends _component.default {
    get credentials() {
      return this.args.profile.get('credentials').filter(e => !e.isNew);
    }

  }

  _exports.default = ProfileCredentialsComponent;
});