define("coachlogix/pods/invoice/index/sessions-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InvoiceSessionsDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed, _dec4 = Ember._action, _dec(_class = (_class2 = (_temp = class InvoiceSessionsDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    get sessions() {
      return this.getSessions.perform();
    }

    *getSessions() {
      let contract = yield this.contract;
      let engagement = yield this.engagement;
      let filter = {
        invoiceable: true
      };

      if (contract) {
        filter.contract_id = contract.id;
      } else {
        filter.engagement_id = engagement.id;
      }

      let invoiceableSessions = yield this.store.query('engagement-session', {
        filter
      });
      let selectedSessions = this.selectedSessions || [];
      return invoiceableSessions.filter(i => !selectedSessions.includes(i));
    }

    addNewSession() {
      this.onNewSession();
      this.onClose();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "sessions", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "sessions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getSessions", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "getSessions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addNewSession", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "addNewSession"), _class2.prototype)), _class2)) || _class);
  var _default = InvoiceSessionsDialog;
  _exports.default = _default;
});