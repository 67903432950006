define("coachlogix/pods/outside/login/terms-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wXlPpkfp",
    "block": "{\"symbols\":[\"dialog\",\"l\",\"&attrs\",\"@onClose\"],\"statements\":[[8,\"cl-dialog\",[[24,0,\"cl-w-40\"],[17,3]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"cl-title-3 cl-flex-1\"],[12],[2,\"\\n      \"],[1,[32,0,[\"currentTerm\",\"title\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"cl-select\",[[24,0,\"cl-mr-1\"]],[[\"@options\",\"@selected\",\"@onChange\"],[[32,0,[\"languages\"]],[32,0,[\"currentLanguage\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"currentLanguage\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[8,\"cl-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"close\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,3],[[35,2,[\"content\"]]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-button\",[],[[\"@raised\",\"@onClick\"],[true,[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,4],[\"ui.close\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"currentTerm\",\"html-safe\",\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/outside/login/terms-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});