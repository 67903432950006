define("coachlogix/pods/components/settings/subscription/subscribe-dialog/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    stripe: Ember.inject.service('stripe-old'),
    store: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    account: Ember.inject.service(),

    /* Step 1 (only for business accounts) */
    seats: 0,
    planPrice: Ember.computed.reads('plan.basePrice'),
    perSeatPrice: Ember.computed.reads('plan.seatPrice'),
    seatsPrice: Ember.computed('seats', 'perSeatPrice', function () {
      return this.get('seats') * this.get('perSeatPrice');
    }),
    totalPrice: Ember.computed('seatsPrice', 'planPrice', function () {
      return this.get('planPrice') + this.get('seatsPrice');
    }),
    step1Invalid: Ember.computed.lt('seats', 0),

    /* Step 2 */
    cards: Ember.computed(function () {
      return this.get('store').findAll('stripe-card');
    }),
    isInvalid: Ember.computed('selectedCard', 'creatingNewCard', 'cardValid', 'name', function () {
      if (this.get('creatingNewCard')) {
        return !this.get('cardValid') || !this.get('name');
      } else {
        return !this.get('selectedCard');
      }
    }),
    creatingNewCard: Ember.computed.equal('cards.length', 0),
    actions: {
      toggleSelectedCard(card) {
        let selectedCard = this.get('selectedCard');

        if (selectedCard === card) {
          this.set('selectedCard', null);
        } else {
          this.set('selectedCard', card);
        }

        this.set('creatingNewCard', false);
      },

      enterCreateCardMode() {
        this.set('selectedCard', null);
        this.set('creatingNewCard', true);
      }

    },

    /* Step 3 */
    createCard: (0, _emberConcurrency.task)(function* (nextStep) {
      let stripeCard = this.get('selectedCard');
      let plan = this.get('plan'); // create stripe card, if user asked for that

      if (!stripeCard && this.get('creatingNewCard')) {
        let stripe = this.get('stripe').stripe;
        let cardElement = this.get('cardElement');

        try {
          let {
            source
          } = yield stripe.createSource(cardElement, {
            owner: {
              name: this.get('name'),
              address: {
                city: this.get('city'),
                country: this.get('country.iso31662'),
                line1: this.get('address'),
                line2: this.get('address2'),
                postal_code: this.get('zip'),
                state: this.get('state')
              } // TODO add more information about owner

            }
          });
          stripeCard = yield this.get('store').createRecord('stripe-card', {
            cardBrand: source.card.brand,
            expMonth: source.card.exp_month,
            expYear: source.card.exp_year,
            cardId: 'N/A',
            // workaround for now
            cardLastFour: source.card.last4,
            cardToken: source.id
          }).save();
        } catch (e) {
          this.get('clToaster').error(e.error.message);
          return;
        }
      } // create subscription


      try {
        let quantity = 1;

        if (this.get('seats')) {
          quantity = quantity + (parseInt(this.get('seats')) || 0);
        }

        yield this.get('store').createRecord('subscription', {
          plan,
          stripeCard,
          quantity,
          account: this.account.activeAccount
        }).save();
        this.get('account.activeAccount').reload();
        nextStep();
      } catch (e) {
        this.get('clToaster').error('An error has occurred while creating your subscription.');
      }
    })
  });

  _exports.default = _default;
});