define("coachlogix/pods/components/v2/resources/resources-view/resources-grid/folder-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZAvugp1O",
    "block": "{\"symbols\":[\"@resource\",\"@resourceable\",\"@selectedGroup\",\"@showAdminResources\",\"@onDuplicate\",\"@onDelete\",\"@onClick\",\"&attrs\"],\"statements\":[[8,\"cl-card\",[[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-flex cl-p-2 \",[30,[36,0],[[32,7],\"cl-cursor-pointer hover:cl-bg-pale-grey\"],null],\"  cl-transition-colors cl-duration-500 cl-ease-out-exp\"]]],[17,8],[4,[38,1],[\"click\",[32,7]],null]],[[\"@raised\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"cl-icon\",[[24,0,\"cl-mr-1\"]],[[\"@icon\"],[[30,[36,0],[[32,1,[\"isNote\"]],\"snippet_folder\",\"folder\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-flex cl-flex-col cl-truncate\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-body-1 cl-font-semibold cl-truncate\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-body-2\"],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,3],[[32,1,[\"isShared\"]],[30,[36,2],[[32,1,[\"isNote\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"cl-icon\",[[24,0,\"cl-mr-0.5\"]],[[\"@icon\",\"@size\"],[\"group\",14]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[1,[30,[36,5],[\"resources.last-modified\"],[[\"date\"],[[30,[36,4],[[32,1,[\"updatedAt\"]],\"ll\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,6],[[32,1,[\"isNote\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cl-pl-2\"],[12],[2,\"\\n      \"],[8,\"v2/resources/resources-view/resource-actions\",[],[[\"@resource\",\"@resourceable\",\"@selectedGroup\",\"@showAdminResources\",\"@onDuplicate\",\"@onDelete\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"not\",\"and\",\"moment-format\",\"t\",\"unless\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/resources/resources-view/resources-grid/folder-card/template.hbs"
    }
  });

  _exports.default = _default;
});