define("coachlogix/pods/invoice/index/taxes-dialog/component", ["exports", "@ember-decorators/component", "ember-changeset", "ember-concurrency-decorators"], function (_exports, _component, _emberChangeset, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InvoiceTaxesDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _emberConcurrencyDecorators.lastValue)('getTaxes'), _dec6 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember.computed('editingTax.{name,ratePercentage}'), _dec(_class = (_class2 = (_temp = class InvoiceTaxesDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "taxes", _descriptor4, this);
    }

    *getTaxes() {
      let taxes = yield this.store.findAll('tax-rate');
      let selected = taxes.find(t => {
        return t.name === this.taxName && t.ratePercentage === this.taxRatePercentage;
      });
      this.set('selectedTax', selected);
      return taxes;
    }

    toggleEditingTax(tax) {
      this.set('editingTax', new _emberChangeset.Changeset(tax));
      this.set('selectedTax', tax);
    }

    *saveTax() {
      if (this.editingTax.isNew) {
        let name = this.editingTax.name;
        let rate = this.editingTax.ratePercentage / 100;

        if (this.saveForLater) {
          let tax = this.store.createRecord('tax-rate', {
            name,
            rate
          });
          yield tax.save();
          this.clToaster.success(this.intl.t('settings.invoicing.add-tax-dialog.success'));
        }

        this.set('selectedTax', {
          name,
          ratePercentage: this.editingTax.ratePercentage
        });
        yield this.selectTax.perform();
      } else {
        yield this.editingTax.save();
        this.set('editingTax', null);
        this.clToaster.success(this.intl.t('Tax updated'));
      }
    }

    *selectTax() {
      let name = this.selectedTax.name;
      let rate = this.selectedTax.ratePercentage / 100;

      if (this.onChange) {
        yield this.onChange({
          name,
          rate
        });
      }

      if (this.onClose) {
        this.onClose();
      }
    }

    *deleteTax(tax) {
      if (this.selectedTax === tax) {
        this.set('selectedTax', null);
      }

      yield tax.deleteTask.perform();
    }

    cancelEdits() {
      if (!this.editingTax.isNew) {
        this.editingTax.rollback();
      }

      this.set('editingTax', null);
    }

    addNewTax() {
      let tax = {
        isNew: true
      };
      this.set('selectedTax', tax);
      this.set('editingTax', tax);
    }

    get isValid() {
      return this.editingTax && this.editingTax.name && this.editingTax.ratePercentage && this.editingTax.ratePercentage > 0 && this.editingTax.ratePercentage < 100;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "taxes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "getTaxes", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "getTaxes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleEditingTax", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleEditingTax"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveTax", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveTax"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectTax", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "selectTax"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteTax", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteTax"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancelEdits", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelEdits"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addNewTax", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "addNewTax"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isValid", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "isValid"), _class2.prototype)), _class2)) || _class);
  var _default = InvoiceTaxesDialog;
  _exports.default = _default;
});