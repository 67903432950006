define("coachlogix/pods/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I+cwusH/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-flex-1 cl-flex\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-background cl-relative cl-hidden md:cl-flex cl-flex-col cl-p-5 cl-shadow-md\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg cl-absolute cl-inset-0\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"overlay cl-absolute cl-inset-0\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"cl-flex-1\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"logo-long\"],[[\"class\"],[\"cl-h-5\"]]]],[2,\"\\n    \"],[13],[2,\"\\n     \"],[10,\"div\"],[14,0,\"cl-title-2 cl-italic cl-text-primary-contrast\"],[12],[2,\"\\n       \"],[1,[30,[36,1],[\"outside.login.tagline\"],null]],[2,\"\\n     \"],[13],[2,\"\\n   \"],[13],[2,\"\\n\\n   \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-flex cl-flex-col\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-p-5 cl-flex cl-items-center cl-justify-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"login-form\"],[12],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"cl-title-1\"],[12],[1,[30,[36,1],[\"not-found.title\"],null]],[13],[2,\"\\n\\n        \"],[10,\"p\"],[14,0,\"cl-my-4\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"not-found.description\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"cl-flex cl-justify-end\"],[12],[2,\"\\n          \"],[8,\"cl-button\",[],[[\"@primary\",\"@href\"],[true,[30,[36,2],[\"dashboard\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"keyboard_arrow_right\"]],null],[2,\"\\n            \"],[1,[30,[36,1],[\"not-found.goToLogin\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\",\"href-to\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});