define("coachlogix/pods/settings/marketplace/checklist/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmployeeMarketplaceChecklistComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class EmployeeMarketplaceChecklistComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "activeStep", 0);

      _defineProperty(this, "steps", [{
        name: this.intl.t('settings.marketplace.contact-info.name'),
        component: 'settings/marketplace/checklist/contact-info',
        user: this.args.user,

        get isCompleted() {
          return Ember.isPresent(this.user.firstName) && Ember.isPresent(this.user.lastName) && Ember.isPresent(this.user.email);
        }

      }, {
        name: this.intl.t('settings.marketplace.summary.name'),
        component: 'settings/marketplace/checklist/summary',
        profile: this.args.user.profile,

        get isCompleted() {
          let summary = this.profile.plainTextSummary;
          return Ember.isPresent(summary) && summary.length >= 350;
        }

      }, {
        name: this.intl.t('settings.marketplace.profile-picture.name'),
        component: 'settings/marketplace/checklist/profile-picture',
        user: this.args.user,

        get isCompleted() {
          return Ember.isPresent(this.user.avatar);
        }

      }, {
        name: this.intl.t('settings.marketplace.calendar-sync.name'),
        component: 'settings/marketplace/checklist/calendar-sync',
        user: this.args.user,

        get isCompleted() {
          return this.user.isCronofyConnected;
        }

      }, {
        name: this.intl.t('settings.marketplace.interview.tab-title'),
        component: 'settings/marketplace/checklist/interview',
        profile: this.args.user.profile,

        get isCompleted() {
          return Ember.isPresent(this.profile.allowIntroductoryInterviews);
        }

      }, {
        name: this.intl.t('settings.marketplace.stripe.name'),
        component: 'settings/marketplace/checklist/stripe',
        user: this.args.user,

        get isCompleted() {
          return this.user.get('account.isStripeConnected');
        }

      }]);
    }

    get isCompleted() {
      return this.steps.every(s => s.isCompleted);
    }

    handleCompletedChange([value]) {
      Ember.run.next(() => {
        this.args.onCompletedChange?.(value);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleCompletedChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleCompletedChange"), _class.prototype)), _class));
  _exports.default = EmployeeMarketplaceChecklistComponent;
});