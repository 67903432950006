define("coachlogix/pods/components/cl-employee-selection-dialog/capacity/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmployeeSelectionDialogEmployeeCapacityComponent = (_dec = Ember.inject.service, (_class = (_temp = class EmployeeSelectionDialogEmployeeCapacityComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    get isEmployeeAtCoachCapacity() {
      let defaultCoachCapacity = this.args.employee.get('account.defaultCoachCapacity');
      let employeeCapacity = this.args.employee.coachCapacity;
      let activeEngagements = this.args.employee.activeEngagements;

      if (employeeCapacity) {
        return activeEngagements > 0 ? activeEngagements >= employeeCapacity : false;
      }

      if (defaultCoachCapacity) {
        return activeEngagements > 0 ? activeEngagements >= defaultCoachCapacity : false;
      }

      return false;
    }

    get label() {
      if (this.isEmployeeAtCoachCapacity && !this.args.employee.acceptingClients || !this.args.employee.acceptingClients) {
        return this.intl.t('ui.unavailable-for-new-clients');
      }

      if (this.isEmployeeAtCoachCapacity) {
        return this.intl.t('ui.at-capacity');
      }

      return null;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EmployeeSelectionDialogEmployeeCapacityComponent;
});