define("coachlogix/pods/components/v2/form-controls/language-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QcGyj9mj",
    "block": "{\"symbols\":[\"locale\",\"@disabled\",\"@selected\",\"@onChange\"],\"statements\":[[8,\"cl-select\",[],[[\"@disabled\",\"@label\",\"@placeholder\",\"@options\",\"@selected\",\"@solid\",\"@onChange\"],[[32,2],[30,[36,0],[\"v2.common.platform-langugage\"],null],[30,[36,0],[\"v2.common.platform-langugage\"],null],[32,0,[\"intl\",\"locales\"]],[32,3],true,[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,0],[[30,[36,1],[\"ui.language.\",[32,1]],null]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/form-controls/language-select/template.hbs"
    }
  });

  _exports.default = _default;
});