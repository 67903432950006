define("coachlogix/pods/components/v2/summary-cards/interviews/actions/time-suggestions-dialog/suggestion/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TimeSuggestionsDialogSuggestion = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class TimeSuggestionsDialogSuggestion extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "moment", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);
    }

    // the datepicker expects a date object in the browser's local timezone
    // but we always work with utc
    get dateValue() {
      if (this.args.dateTime) {
        let date = this.momentTz((0, _moment.default)(this.args.dateTime));
        return (0, _moment.default)({
          year: date.year(),
          month: date.month(),
          day: date.date()
        }).toDate();
      } else {
        return null;
      }
    }

    get timeValue() {
      return this.args.dateTime ? this.momentTz((0, _moment.default)(this.args.dateTime)).format('HH:mm') : '';
    }

    get wasPreviouslyDeclined() {
      let date = (0, _moment.default)(this.args.dateTime);
      return this.args.declinedSuggestions.some(s => date.isSame(s.startTime, 'minute'));
    } // we get a date object in the local timezone
    // but user is inputting in its own timezone, possible different
    // from the browser's, so we have to convert


    setDate(value) {
      if (!value) {
        this.args.onChange(null);
      } else {
        let date = _moment.default.tz({
          year: value.getFullYear(),
          month: value.getMonth(),
          day: value.getDate(),
          hours: 0,
          minutes: 0,
          seconds: 0
        }, this.moment.timeZone);

        if (this.args.dateTime) {
          let time = this.momentTz((0, _moment.default)(this.args.dateTime));
          date.hours(time.hours());
          date.minutes(time.minutes());
        }

        this.args.onChange(date.toDate());
      }
    }

    setTime(value) {
      let time = (0, _moment.default)(value, 'HH:mm');
      let date = this.momentTz((0, _moment.default)(this.args.dateTime));
      date.hours(time.hours());
      date.minutes(time.minutes());
      console.log(date.format());
      this.args.onChange(date.toDate());
    }

    momentTz(m) {
      let timezone = this.moment.timeZone;

      if (timezone) {
        return m.tz(timezone);
      } else {
        return m;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "moment", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setDate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTime", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setTime"), _class.prototype)), _class));
  _exports.default = TimeSuggestionsDialogSuggestion;
});