define("coachlogix/pods/components/v2/resources/document-dialog/component", ["exports", "@ember-decorators/component", "ember-changeset", "ember-concurrency-decorators", "coachlogix/config/environment"], function (_exports, _component, _emberChangeset, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec(_class = (_class2 = (_temp = class DocumentDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "filestack", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "hooks", {
        addImageBlobHook: this.addImageBlobHook
      });
    }

    async addImageBlobHook(file, callback) {
      let client = await this.filestack.initClient();
      let res = await client.upload(file, {}, _environment.default['ember-filestack'].pickerOptions.storeTo);
      callback(res.url, res.filename);
    }

    *getDocument() {
      let content;

      if (this.document.isNew) {
        content = this.store.createRecord('document-content', {
          resource: this.document
        });
      } else {
        this.document = yield this.store.findRecord('resource', this.document.id, {
          include: 'document-content',
          reload: true
        });
        content = yield this.document.get('documentContent');
      }

      this.set('documentChangeset', new _emberChangeset.Changeset(this.document));
      this.set('contentChangeset', new _emberChangeset.Changeset(content));
    }

    *saveChanges() {
      // let isNew = this.document.isNew;
      yield this.contentChangeset.save();
      yield this.documentChangeset.save();
      /*
      if (isNew && this.resourceable) {
        yield this.resourceable.addResource.linked().perform(this.document);
      }
      */

      this.onClose?.();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "filestack", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "addImageBlobHook", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "addImageBlobHook"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getDocument", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "getDocument"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveChanges"), _class2.prototype)), _class2)) || _class);
  var _default = DocumentDialog;
  _exports.default = _default;
});