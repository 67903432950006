define("coachlogix/adapters/task", ["exports", "coachlogix/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: () => 'tasks'
  });

  _exports.default = _default;
});