define("coachlogix/services/drag-sort", ["exports", "ember-drag-sort/services/drag-sort"], function (_exports, _dragSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dragSort.default;
    }
  });
});