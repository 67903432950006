define("coachlogix/pods/dashboard/approve-contract-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "llzevCnk",
    "block": "{\"symbols\":[\"@onClose\"],\"statements\":[[6,[37,0],[[32,0,[\"contract\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"v2/contracts/contract-dialog\",[],[[\"@contract\",\"@onClose\"],[[32,0,[\"contract\"]],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/dashboard/approve-contract-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});