define("coachlogix/models/client-coachee", ["exports", "@ember-data/model", "coachlogix/models/contact"], function (_exports, _model, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _contact.default.extend({
    client: (0, _model.belongsTo)('client', {
      inverse: 'clientCoachees'
    }),
    engagements: (0, _model.hasMany)('engagement'),
    employee: (0, _model.belongsTo)('employee', {
      inverse: 'clientCoachees'
    })
  });

  _exports.default = _default;
});