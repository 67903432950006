define("coachlogix/models/form-question-option", ["exports", "@ember-data/model", "ember-data-copyable", "coachlogix/models/base-model"], function (_exports, _model, _emberDataCopyable, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_emberDataCopyable.default, {
    value: (0, _model.attr)('string'),
    sortOrder: (0, _model.attr)('number'),
    weight: (0, _model.attr)('number'),
    formQuestion: (0, _model.belongsTo)('form-question', {
      inverse: 'formQuestionOptions'
    })
  });

  _exports.default = _default;
});