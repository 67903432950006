define("coachlogix/models/comment", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    body: (0, _model.attr)('string'),
    value: Ember.computed.alias('body'),
    date: Ember.computed.alias('createdAt'),
    commentable: (0, _model.belongsTo)('base-model', {
      async: true
    }),
    user: (0, _model.belongsTo)('user', {
      async: true,
      inverse: null
    })
  });

  _exports.default = _default;
});