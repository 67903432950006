define("coachlogix/pods/components/cl-user-card-interview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KmkvRA7k",
    "block": "{\"symbols\":[\"@interview\",\"&attrs\",\"@href\",\"@employee\",\"&default\"],\"statements\":[[8,\"cl-card\",[[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"a\"],[15,6,[32,3]],[15,0,[31,[\"cl-flex cl-no-underline cl-flex-row cl-items-center cl-px-4 cl-py-3 \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"v2/avatar-for\",[],[[\"@model\",\"@size\"],[[32,4],72]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-flex cl-flex-col cl-ml-2 cl-flex-1\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"cl-mt-1 cl-title-3\"],[12],[1,[32,4,[\"user\",\"name\"]]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"cl-title-7\"],[12],[1,[32,4,[\"account\",\"name\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"cl-mt-2\"],[12],[2,\"\\n          \"],[8,\"cl-chip\",[[24,0,\"cl-flex cl-items-center cl-justify-between\"]],[[\"@solid\",\"@success\",\"@danger\"],[true,[32,1,[\"isInterviewCompleted\"]],[32,1,[\"isInterviewCanceled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"cl-icon\",[[24,0,\"cl-mr-1\"]],[[\"@icon\",\"@size\"],[[30,[36,0],[[32,1,[\"isInterviewCompleted\"]],\"check\",[30,[36,0],[[32,1,[\"isInterviewCanceled\"]],\"cancel\",\"help\"],null]],null],12]],null],[2,\"\\n            \"],[1,[30,[36,3],[[30,[36,2],[\"coachlogix-terms.statuses.coaching-request.\",[30,[36,1],[[32,1,[\"status\"]],\"no_request\"],null]],null]],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[18,5,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"or\",\"concat\",\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cl-user-card-interview/template.hbs"
    }
  });

  _exports.default = _default;
});