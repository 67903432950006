define("coachlogix/pods/reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3P4RHKYw",
    "block": "{\"symbols\":[\"report\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,4],[\"page-title\"],null]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h1\"],[14,0,\"cl-title-1\"],[12],[1,[30,[36,3],[\"reports.title\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[32,0,[\"model\",\"reportTypesTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cl-h-screen cl-w-full cl-flex cl-justify-center cl-items-center\"],[12],[2,\"\\n    \"],[8,\"cl-progress-circular\",[],[[\"@size\"],[48]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cl-container cl-mx-auto cl-p-4 cl-flex cl-flex-col\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-flex\"],[12],[2,\"\\n      \"],[8,\"cl-select\",[[24,0,\"cl-w-32\"]],[[\"@label\",\"@placeholder\",\"@options\",\"@selected\",\"@onChange\"],[\"Report type\",\"Please select a report\",[32,0,[\"reportTypes\"]],[32,0,[\"selectedReport\"]],[32,0,[\"selectReport\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"name\"]]],null]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"titleize\",\"-outlet\",\"component\",\"t\",\"get-element-by-id\",\"-in-el-null\",\"in-element\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/reports/template.hbs"
    }
  });

  _exports.default = _default;
});