define("coachlogix/pods/engagement/program/program-item/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProgramItemRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec4 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class ProgramItemRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "twilioSync", _descriptor2, this);
    }

    model({
      program_item_id
    }) {
      return {
        programItem: this.loadProgramItem.perform(program_item_id)
      };
    }

    resetController() {
      super.resetController(...arguments);

      if (this.document) {
        this.document?.close();
        this.document = null;
      }
    }

    *loadProgramItem(programItemId) {
      let include = [// needed data for sessions
      'itemable.participants.user', 'itemable.meeting-location', // also for coaching-requests
      'itemable.tasks.assignments.assignee.user', 'itemable.resources', // needed data for tasks
      'itemable.resource.form-submissions', // for forms
      'itemable.assignees.user', 'itemable.assignments.form-submissions', 'itemable.assignments.assignee.user', // needed for both
      'itemable.comments', 'itemable.custom-notifications', 'itemable.custom-notifications.resources', 'itemable.custom-notifications.notification-reminders', // needed for coaching requests
      'itemable.coach.user', 'itemable.coachee.user'].join(',');
      let programItem = yield this.store.findRecord('program-item', programItemId, {
        reload: true,
        include
      });

      if (programItem.isSession) {
        let session = yield programItem.get('itemable');

        if (session.get('meetingLocation.isGoogleMeet') && !session.get('meetingLocation.additionalInfo')) {
          yield this.listenToUpdates.perform(programItem.belongsTo('itemable').id());
        }
      }

      return programItem;
    }

    *listenToUpdates(id) {
      let client = yield this.twilioSync.initTwilio.perform();
      this.document = yield client.document(`session_${id}`);
      this.document.on('updated', event => {
        let session = this.store.peekRecord('engagement-session', id);
        let newMeetingLocation = this.store.createRecord('meeting-location', {
          meetingType: 'google_meet',
          oneTimeUse: true,
          additionalInfo: event.data.meeting_location_link
        });
        session.set('meetingLocation', newMeetingLocation);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "twilioSync", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadProgramItem", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadProgramItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listenToUpdates", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "listenToUpdates"), _class.prototype)), _class));
  _exports.default = ProgramItemRoute;
});