define("coachlogix/pods/components/settings/subscription/account-support-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZVhn8Xxg",
    "block": "{\"symbols\":[\"dialog\",\"alert\",\"@message\",\"&attrs\",\"@onClose\"],\"statements\":[[8,\"cl-dialog\",[[24,0,\"cl-w-32\"],[17,4]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"cl-title-3 cl-flex-1\"],[12],[1,[30,[36,0],[\"settings.subscription.account-support-dialog.title\"],null]],[13],[2,\"\\n    \"],[8,\"cl-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"close\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"cl-alert\",[],[[\"@warn\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2,[\"icon\"]],[],[[\"@icon\"],[\"feedback\"]],null],[2,\"\\n        \"],[8,[32,2,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,3]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"settings.subscription.account-support-dialog.info\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-button\",[],[[\"@raised\",\"@onClick\"],[true,[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"ui.close\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/settings/subscription/account-support-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});