define("coachlogix/pods/reports/report/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OILZr/QI",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"model\",\"reportTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cl-min-h-32 cl-flex cl-items-center cl-justify-center\"],[12],[2,\"\\n    \"],[8,\"cl-progress-circular\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"reports/report/report-table\",[],[[\"@report\"],[[32,0,[\"model\",\"reportTask\",\"value\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/reports/report/template.hbs"
    }
  });

  _exports.default = _default;
});