define("coachlogix/models/user-context", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    primaryRole: (0, _model.attr)('string')
    /*user: belongsTo('user', {
      async: true,
      inverse: null
    })*/

  });

  _exports.default = _default;
});