define("coachlogix/pods/components/settings/subscription/plan-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['plan-card', 'layout-column'],
    classNameBindings: ['hovered:md-whiteframe-5dp', 'hovered:plan-card--hovered', 'isSameAccountType:plan-card--other-account-type', 'showCurrentPlanRibbon:md-whiteframe-3dp', 'showCurrentPlanRibbon:plan-card--hovered'],
    hasActivePlan: Ember.computed.bool('_activePlan'),
    isActivePlan: Ember.computed('plan', '_activePlan', function () {
      return this.get('plan') === this.get('_activePlan');
    }),
    isSameAccountType: Ember.computed('accountType', 'plan.accountType', function () {
      return this.get('plan.accountType') && this.get('plan.accountType').includes(this.get('accountType'));
    }),
    showCurrentPlanRibbon: Ember.computed('isActivePlan', 'plan.interval', 'hasActivePlan', 'isSameAccountType', function () {
      return this.get('isActivePlan') || this.get('plan.interval') === 'Free' && this.get('isSameAccountType') && !this.get('hasActivePlan');
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      let activePlan = this.get('activePlan');

      if (activePlan && activePlan.then) {
        activePlan.then(p => {
          this.set('_activePlan', p);
        });
      } else {
        this.set('_activePlan', activePlan);
      }
    },

    mouseEnter() {
      this.set('hovered', true);
    },

    mouseLeave() {
      this.set('hovered', false);
    }

  });

  _exports.default = _default;
});