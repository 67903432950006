define("coachlogix/models/client-poc", ["exports", "@ember-data/model", "ember-concurrency", "qs-stringify", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrency, _qsStringify, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    accountService: Ember.inject.service('account'),
    ajax: Ember.inject.service(),
    status: (0, _model.attr)('string'),
    // active, invited
    isPrimary: (0, _model.attr)('boolean'),
    employee: (0, _model.belongsTo)('employee'),
    client: (0, _model.belongsTo)('client', {
      inverse: 'clientPocs'
    }),
    account: (0, _model.belongsTo)('account'),
    isInvited: Ember.computed.equal('status', 'invited'),
    isActive: Ember.computed.equal('status', 'active'),
    employeeEmail: Ember.computed.reads('employee.email'),
    isSameAccount: Ember.computed('employee.account.id', 'accountService.activeAccount.id', function () {
      return this.get('employee.account.id') === this.get('accountService.activeAccount.id');
    }),
    disconnect: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.destroyRecord();
        this.clToaster.success('Disconnected from account manager.');
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    resendInvitation: (0, _emberConcurrency.task)(function* () {
      try {
        let queryString = (0, _qsStringify.default)({
          client_poc_id: this.id
        });
        let wasInvited = this.isInvited;
        yield this.ajax.post(`/helpers/resend-invitation?${queryString}`);
        this.set('employee.status', 'invited');

        if (this.get('client.isVendor')) {
          this.set('client.account.status', 'pending');
        } else {
          this.set('client.clientAccount.status', 'pending');
        }

        let toastMessage = wasInvited ? this.intl.t('ui.invite-resent-success') : this.intl.t('ui.invite-sent-success');
        this.clToaster.success(toastMessage);
      } catch (e) {
        this.clToaster.error('Invitation sent less than 24 hours ago.');
      }
    })
  });

  _exports.default = _default;
});