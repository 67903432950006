define("coachlogix/pods/messaging/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MessagingController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = (_temp = class MessagingController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "twilio", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _defineProperty(this, "search", '');
    }

    *createChannel(selectedUsers) {
      try {
        let channel = yield this.twilio.createPersonalChannel.perform(selectedUsers);
        this.router.transitionTo('messaging.channel', channel.sid);
      } catch (e) {
        this.clToaster.error(e);
      }

      this.set('showUsersDialog', false);
    }

    *createGroupChannel(topic) {
      try {
        let selectedUsers = this.selectedUsers;
        let channel = yield this.twilio.createGroupChannel.perform(topic, selectedUsers);
        this.router.transitionTo('messaging.channel', channel.sid);
      } catch (e) {
        this.clToaster.error(e);
      }

      this.set('askForTopic', false);
      this.set('selectedUsers', []);
    }

    async usersSelected(users) {
      if (users.length === 1) {
        await this.createChannel.perform(users);
      } else if (users.length >= 1) {
        this.set('selectedUsers', users);
        this.set('showUsersDialog', false);
        this.set('askForTopic', true);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "twilio", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createChannel", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createGroupChannel", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createGroupChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "usersSelected", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "usersSelected"), _class.prototype)), _class));
  _exports.default = MessagingController;
});