define("coachlogix/pods/settings/marketplace/controller", ["exports", "ember-ajax/errors", "ember-concurrency-decorators"], function (_exports, _errors, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SettingsMarketplaceController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.reads('model.userTask.value'), _dec5 = Ember._tracked, _dec6 = Ember.computed.filterBy('model.packagesTask.value', 'isNew', false), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, (_class = (_temp = class SettingsMarketplaceController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clToaster", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "ajax", _descriptor3, this);

      _initializerDefineProperty(this, "user", _descriptor4, this);

      _defineProperty(this, "origin", window.location.origin);

      _defineProperty(this, "host", window.location.host);

      _initializerDefineProperty(this, "showTerms", _descriptor5, this);

      _initializerDefineProperty(this, "packages", _descriptor6, this);

      _initializerDefineProperty(this, "editPackage", _descriptor7, this);

      _initializerDefineProperty(this, "isCompleted", _descriptor8, this);
    }

    *toggle(value) {
      if (value) {
        this.showTerms = true;
      } else {
        yield this.user.profile.unpublish.perform();
      }
    }

    *search() {
      try {
        yield this.ajax.request(`helpers/profile-check/${this.url}`);
        return true;
      } catch (e) {
        if ((0, _errors.isNotFoundError)(e)) {
          return false;
        } else {
          this.clToaster.error(e);
          throw e;
        }
      }
    }

    createPackage() {
      this.editPackage = this.store.createRecord('package', {
        status: 'inactive',
        owner: this.user
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showTerms", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "packages", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "editPackage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isCompleted", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createPackage", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "createPackage"), _class.prototype)), _class));
  _exports.default = SettingsMarketplaceController;
});