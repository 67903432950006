define("coachlogix/pods/video/video-ui/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment", "moment-duration-format", "tracked-maps-and-sets", "tracked-toolbox", "twilio-video"], function (_exports, _component, _emberConcurrencyDecorators, _moment, _momentDurationFormat, _trackedMapsAndSets, _trackedToolbox, _twilioVideo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  (0, _momentDurationFormat.default)(_moment.default);
  const IDENTITY_REGEX = /cl-user:(\d+)/;
  let VideoUiComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._tracked, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = (_temp = class VideoUiComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "account", _descriptor3, this);

      _initializerDefineProperty(this, "twilioVideo", _descriptor4, this);

      _initializerDefineProperty(this, "clToaster", _descriptor5, this);

      _initializerDefineProperty(this, "dominantSpeaker", _descriptor6, this);

      _initializerDefineProperty(this, "screenShareParticipant", _descriptor7, this);

      _initializerDefineProperty(this, "participants", _descriptor8, this);

      _initializerDefineProperty(this, "users", _descriptor9, this);

      _initializerDefineProperty(this, "hasCoachStarted", _descriptor10, this);

      _initializerDefineProperty(this, "isOpen", _descriptor11, this);

      _initializerDefineProperty(this, "room", _descriptor12, this);

      _initializerDefineProperty(this, "showSettings", _descriptor13, this);

      _initializerDefineProperty(this, "startedOn", _descriptor14, this);

      _initializerDefineProperty(this, "timeElapsed", _descriptor15, this);

      _initializerDefineProperty(this, "mainParticipant", _descriptor16, this);

      this.connectToSessionRoom.perform();
    }

    *connectToSessionRoom() {
      let session = yield this.args.session;
      let type = session.modelName;
      let {
        token
      } = yield this.ajax.request(`/twilio/video-token/${type}/${session.id}`);
      let tracks = [];

      if (this.twilioVideo.localVideoTrack) {
        tracks.push(this.twilioVideo.localVideoTrack);
      }

      if (this.twilioVideo.localAudioTrack) {
        tracks.push(this.twilioVideo.localAudioTrack);
      }

      this.room = yield (0, _twilioVideo.connect)(token, {
        tracks,
        dominantSpeaker: true
      }); // add active user

      this.users.set(this.room.localParticipant.sid, this.account.activeUser);

      for (let participant of this.room.participants.values()) {
        this.onParticipantConnected(participant, false);
      }

      this.updateHasCoachStarted();
      window.addEventListener('beforeunload', this.onBeforeUnload);
      this.room.on('participantConnected', this.onParticipantConnected);
      this.room.on('participantDisconnected', this.onParticipantDisconnected);
      this.room.on('dominantSpeakerChanged', this.onDominantSpeakerChanged);
      this.room.on('trackSubscribed', this.onTrackSubscribed);
      this.room.on('trackUnsubscribed', this.onTrackUnsubscribed);
      this.room.on('disconnected', this.onDisconnected);
    }

    willDestroy() {
      super.willDestroy(...arguments);

      if (this.room) {
        this.room.off('participantConnected', this.onParticipantConnected);
        this.room.off('participantDisconnected', this.onParticipantDisconnected);
        this.room.off('dominantSpeakerChanged', this.onDominantSpeakerChanged);
        this.room.off('trackSubscribed', this.onTrackSubscribed);
        this.room.off('trackUnsubscribed', this.onTrackUnsubscribed);
      }

      window.removeEventListener('beforeunload', this.onBeforeUnload);
    }
    /**
     * tests if any participant is the session coach. The presence of the coach is
     * used to determine if the video session has started.
     */


    updateHasCoachStarted() {
      let hasCoachStarted = false;
      let coachUserId = this.args.session.modelName === 'coaching-request' ? this.args.session.get('coach.user.id') : this.args.session.get('engagement.coach.user.id');

      if (this.room) {
        let [, localId] = this.room.localParticipant.identity.match(IDENTITY_REGEX);

        if (localId === coachUserId) {
          hasCoachStarted = true;
        }
      }

      for (let [, p] of this.participants) {
        if (p.identity) {
          let [, id] = p.identity.match(IDENTITY_REGEX);

          if (id === coachUserId) {
            hasCoachStarted = true;
          }
        }
      }

      let newHasCoachStarted = hasCoachStarted && this.participants.size > 0; // this case is where the actual call starts, so we start the timer

      if (newHasCoachStarted && !this.hasCoachStarted) {
        this.startedOn = new Date();
        this.updateTimeElapsed();
      }

      this.hasCoachStarted = newHasCoachStarted;
    }

    updateTimeElapsed() {
      if (this.isDestroyed) {
        return;
      }

      this.timeElapsed = _moment.default.duration((0, _moment.default)().diff(this.startedOn)).format('hh:mm:ss', {
        trim: false
      });
      Ember.run.later(() => {
        this.updateTimeElapsed();
      }, 1000);
    }

    async onParticipantConnected(p, showToaster = true) {
      console.log('onParticipantConnected', ...arguments);
      let [, id] = p.identity.match(IDENTITY_REGEX);
      let user = await this.store.findRecord('user', id);
      this.participants.set(p.sid, p);
      this.users.set(p.sid, user);
      this.updateHasCoachStarted();
      this.updateMainParticipant();

      if (showToaster && this.hasCoachStarted) {
        this.clToaster.info(`<strong>${user.name}</strong> has entered the room.`);
      }
    }

    onParticipantDisconnected(p) {
      console.log('onParticipantDisconnected', p);
      let user = this.users.get(p.sid);
      this.participants.delete(p.sid);
      this.users.delete(p.sid);
      this.updateMainParticipant();
      this.clToaster.info(`<strong>${user.name}</strong> has left the room.`);
    }

    onDominantSpeakerChanged(participant) {
      console.log('The new dominant speaker in the Room is:', participant);
      this.dominantSpeaker = participant;
      this.updateMainParticipant();
    }

    onTrackSubscribed(track, publication, participant) {
      console.log('[Room] onTrackSubscribed', ...arguments);

      if (track.name === 'screenshare') {
        this.screenShareParticipant = participant; // disable your own screen sharing if it is on
        // when a new screen share has been done

        if (this.twilioVideo.screenShareOn) {
          this.toggleScreenShare.perform();
        }
      }

      this.updateMainParticipant();
    }

    onTrackUnsubscribed(track, publication, participant) {
      console.log('[Room] onTrackUnsubscribed', ...arguments); // only reset screensharing if there isn't already another
      // participant that has screen shared meanwhile

      if (track.name === 'screenshare' && participant === this.screenShareParticipant) {
        this.screenShareParticipant = null;
      }

      this.updateMainParticipant();
    }
    /**
     * returns the main video track to show on the main panel. The algorithm to calculate
     * the main track is the following:
     *
     * - if there is some screen sharing track, return it
     * - if there is a dominant speaker, return it
     * - return the first participant that has a video track
     * - return the first participant that has an audio track
     * - return the first participant
     *
     * TODO implement pinned participants
     */


    updateMainParticipant() {
      // TODO: check for a screenshare video track, which takes
      // precendence over a dominant speaker video track
      // return dominant speaker if exists
      if (this.screenShareParticipant) {
        this.mainParticipant = this.screenShareParticipant;
        return;
      } // return dominant speaker if exists


      if (this.dominantSpeaker) {
        this.mainParticipant = this.dominantSpeaker;
        return;
      } // return the first participant that has a video track


      for (let participant of this.participants.values()) {
        let hasTrack = false;

        for (let track of participant.videoTracks.values()) {
          if (track.isSubscribed) {
            hasTrack = true;
          }
        }

        if (hasTrack) {
          this.mainParticipant = participant;
          return;
        }
      } // return the first participant that has an audio track


      for (let participant of this.participants.values()) {
        let hasTrack = false;

        for (let track of participant.audioTracks.values()) {
          if (track.isSubscribed) {
            hasTrack = true;
          }
        }

        if (hasTrack) {
          this.mainParticipant = participant;
          return;
        }
      } // return the first participant


      if (this.participants.size > 0) {
        this.mainParticipant = this.participants.values().next().value;
        return;
      }

      this.mainParticipant = null;
    }

    onBeforeUnload() {
      if (this.room) {
        this.room.disconnect();
        this.room = null;
      }
    }

    onDisconnected() {
      this.args.onStopSession?.();
    }

    async disconnectRoom() {
      this.room.disconnect();
      await this.twilioVideo.stopVideo.perform();
      await this.twilioVideo.stopVideo.perform();
      await this.twilioVideo.stopScreenShare.perform();
      this.args.onStopSession?.();
    }

    *toggleVideo() {
      if (this.twilioVideo.localVideoTrack) {
        yield this.room.localParticipant.unpublishTrack(this.twilioVideo.localVideoTrack);
      }

      yield this.twilioVideo.toggleVideo.perform();

      if (this.twilioVideo.localVideoTrack) {
        this.room.localParticipant.publishTrack(this.twilioVideo.localVideoTrack);
      }
    }

    *toggleAudio() {
      if (this.twilioVideo.localAudioTrack) {
        yield this.room.localParticipant.unpublishTrack(this.twilioVideo.localAudioTrack);
      }

      yield this.twilioVideo.toggleAudio.perform();

      if (this.twilioVideo.localAudioTrack) {
        this.room.localParticipant.publishTrack(this.twilioVideo.localAudioTrack);
      }
    }

    *toggleScreenShare() {
      if (this.twilioVideo.localScreenTrack) {
        yield this.room.localParticipant.unpublishTrack(this.twilioVideo.localScreenTrack);
      }

      yield this.twilioVideo.toggleScreenShare.perform();

      if (this.twilioVideo.localScreenTrack) {
        this.room.localParticipant.publishTrack(this.twilioVideo.localScreenTrack);
      }

      if (this.twilioVideo.screenShareOn) {
        this.screenShareParticipant = this.room.localParticipant;
      } else if (this.room.localParticipant === this.screenShareParticipant) {
        this.screenShareParticipant = null;
      }

      this.updateMainParticipant();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "twilioVideo", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dominantSpeaker", [_trackedToolbox.dedupeTracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "screenShareParticipant", [_trackedToolbox.dedupeTracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "participants", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedMapsAndSets.TrackedMap();
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedMapsAndSets.TrackedMap();
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasCoachStarted", [_trackedToolbox.dedupeTracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "room", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showSettings", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "startedOn", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "timeElapsed", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "connectToSessionRoom", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "connectToSessionRoom"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onParticipantConnected", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onParticipantConnected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onParticipantDisconnected", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onParticipantDisconnected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDominantSpeakerChanged", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onDominantSpeakerChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTrackSubscribed", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onTrackSubscribed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTrackUnsubscribed", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onTrackUnsubscribed"), _class.prototype), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "mainParticipant", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onBeforeUnload", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "onBeforeUnload"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDisconnected", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "onDisconnected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disconnectRoom", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "disconnectRoom"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleVideo", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "toggleVideo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAudio", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAudio"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleScreenShare", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "toggleScreenShare"), _class.prototype)), _class));
  _exports.default = VideoUiComponent;
});