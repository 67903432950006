define("coachlogix/mixins/app-bar-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    appbar: Ember.inject.service(),
    appBarDefaults: {
      heirarchy: 'parent',
      color: 'light',
      height: 'default',
      disabled: false
    },

    activate() {
      this._super(...arguments);

      let attrs = Ember.assign({}, this.get('appBarDefaults'), this.get('appBar'));
      this.get('appbar').setProperties(attrs);
    },

    deactivate() {
      this._super(...arguments);

      this.get('appbar').setProperties(this.get('appBarDefaults'));
    }

  });

  _exports.default = _default;
});