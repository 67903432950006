define("coachlogix/pods/components/v2/engagement-template/engagement-session/detail-view/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let SessionDetailView = (_dec = (0, _component.tagName)(''), _dec(_class = class SessionDetailView extends Ember.Component {}) || _class);
  var _default = SessionDetailView;
  _exports.default = _default;
});