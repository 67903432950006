define("coachlogix/validations/publication", ["exports", "ember-changeset-validations/validators", "coachlogix/utils/urlish-regex"], function (_exports, _validators, _urlishRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }),
    authors: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }),
    linkUrl: (0, _validators.validateFormat)({
      regex: _urlishRegex.default,
      allowBlank: true,
      message: 'Should be a valid link'
    })
  };
  _exports.default = _default;
});