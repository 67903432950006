define("coachlogix/helpers/yeti-table-eq", ["exports", "ember-yeti-table/-private/helpers/yeti-table-eq"], function (_exports, _yetiTableEq) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _yetiTableEq.default;
    }
  });
  Object.defineProperty(_exports, "yetiTableEq", {
    enumerable: true,
    get: function () {
      return _yetiTableEq.yetiTableEq;
    }
  });
});