define("coachlogix/components/time-control", ["exports", "ember-animated-tools/components/time-control"], function (_exports, _timeControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _timeControl.default;
    }
  });
});