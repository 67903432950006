define("coachlogix/models/location", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    description: (0, _model.attr)('string'),
    lat: (0, _model.attr)('number'),
    long: (0, _model.attr)('number'),
    slug: (0, _model.attr)('string'),
    owner: (0, _model.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});