define("coachlogix/pods/components/v2/summary-cards/stakeholder-feedback/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vv826AHZ",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"cl-title-4 cl-mb-3\"],[12],[1,[30,[36,1],[\"dashboard.widget.feedback\"],null]],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"getData\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"cl-card\",[[24,0,\"cl-p-4 cl-flex cl-items-center cl-justify-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-progress-circular\",[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"reviews\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"v2/engagement/dashboard/feedback-card-slider\",[],[[\"@reviews\"],[[32,0,[\"reviews\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"cl-card\",[[24,0,\"cl-p-4 cl-flex cl-items-center cl-justify-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-empty-state\",[],[[\"@icon\",\"@size\",\"@text\"],[\"list\",88,\"No feedback was provided yet.\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/summary-cards/stakeholder-feedback/template.hbs"
    }
  });

  _exports.default = _default;
});