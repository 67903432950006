define("coachlogix/pods/components/cl-employee-selection-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+tnuUOVH",
    "block": "{\"symbols\":[\"employee\",\"@title\",\"@required\",\"@multiple\",\"@onClose\",\"@selected\",\"@onChange\",\"@auxButtonClick\",\"@auxButtonLabel\",\"@closeOnSelect\",\"@selectLabel\",\"@warnMessage\",\"@beforeOptionsComponent\",\"&attrs\",\"@displayEmployeeEngagementAvailability\"],\"statements\":[[8,\"cl-selection-dialog\",[[17,14]],[[\"@title\",\"@search\",\"@pagination\",\"@required\",\"@multiple\",\"@onClose\",\"@getOptions\",\"@selected\",\"@onChange\",\"@auxButtonClick\",\"@auxButtonLabel\",\"@closeOnSelect\",\"@selectLabel\",\"@beforeOptionsComponent\"],[[30,[36,0],[[32,2],\"Select users\"],null],true,true,[32,3],[32,4],[32,5],[30,[36,1],[[32,0,[\"getEmployees\"]]],null],[32,6],[32,7],[32,8],[32,9],[32,10],[32,11],[30,[36,0],[[32,13],[30,[36,2],[\"cl-employee-selection-dialog/info\"],[[\"message\"],[[32,12]]]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,\"cl-user-avatar\",[],[[\"@size\",\"@imageUrl\",\"@firstName\",\"@email\",\"@lastName\"],[28,[32,1,[\"user\",\"avatar\"]],[32,1,[\"user\",\"firstName\"]],[32,1,[\"user\",\"email\"]],[32,1,[\"user\",\"lastName\"]]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[1,[32,1,[\"user\",\"firstName\"]]],[2,\" \"],[1,[32,1,[\"user\",\"lastName\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[32,1,[\"user\",\"email\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[32,15]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"cl-employee-selection-dialog/capacity\",[],[[\"@employee\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"perform\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cl-employee-selection-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});