define("coachlogix/pods/video/volume-visualizer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wqRHVe4Y",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"cl-transition-opacity cl-duration-500 cl-ease-out-exp\"],[16,5,[32,0,[\"audioVisualizerStyle\"]]],[17,1],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/video/volume-visualizer/template.hbs"
    }
  });

  _exports.default = _default;
});