define("coachlogix/pods/coaches/coach-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/V8wLfQH",
    "block": "{\"symbols\":[\"@reloadTable\",\"@coach\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[30,[36,1],[[30,[36,4],[[32,2,[\"user\",\"id\"]],[32,0,[\"account\",\"activeUser\",\"id\"]]],null]],null],[30,[36,1],[[30,[36,3],[\"only see profiles in account\"],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2,[\"isInternal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"v2/employee/actions\",[],[[\"@showUpdateInfo\",\"@preventDisconnect\",\"@onDelete\",\"@employee\"],[false,true,[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[32,2,[\"clientCoaches\"]],[32,2,[\"isExternal\"]],[30,[36,1],[[32,2,[\"isIndependent\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"v2/contact/actions\",[],[[\"@onInvite\",\"@contact\"],[[32,1],[32,2,[\"clientCoaches\",\"firstObject\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[32,2,[\"clientCoaches\"]],[32,0,[\"clientCoachesClient\"]],[32,2,[\"isExternal\"]],[32,2,[\"isIndependent\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"v2/client/actions\",[],[[\"@onInvite\",\"@onDisconnect\",\"@client\"],[[32,1],[32,1],[32,0,[\"clientCoachesClient\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"not\",\"and\",\"can\",\"eq\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/coaches/coach-actions/template.hbs"
    }
  });

  _exports.default = _default;
});