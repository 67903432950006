define("coachlogix/pods/engagement/matching-process/interviews/interview-card/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InterviewCardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.reads('args.engagement.matchingSettings.coachPoolsNoInterviewAllowed'), (_class = (_temp = class InterviewCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clToaster", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "isInterviewAllowed", _descriptor3, this);
    }

    get timeSuggestions() {
      let timeSuggestions = this.args.interview.timeSuggestions?.toArray() || [];
      return timeSuggestions.sort((a, b) => a.startTime - b.startTime);
    }

    get canRescheduleInterview() {
      return this.args.interview && !this.args.interview.isNew && this.args.interview.isInterviewScheduled && this.isInterviewAllowed && this.args.interview.get('coach.user.isCronofyConnected');
    }

    get canRescheduleInterviewWithTimes() {
      return this.args.interview && !this.args.interview.isNew && this.args.interview.isInterviewScheduled && this.isInterviewAllowed && !this.args.interview.get('coach.user.isCronofyConnected');
    }

    *requestNewTimes() {
      try {
        this.args.interview.status = 'interview_times_requested';
        yield this.args.interview.save();
      } catch (e) {
        this.args.interview.rollbackAttributes();
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isInterviewAllowed", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "requestNewTimes", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "requestNewTimes"), _class.prototype)), _class));
  _exports.default = InterviewCardComponent;
});