define("coachlogix/models/coach", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    coachType: (0, _model.attr)('string'),
    account: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    companyLocation: (0, _model.belongsTo)('company-location', {
      inverse: null
    }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    employee: (0, _model.belongsTo)('employee', {
      inverse: null
    }),
    engagements: (0, _model.hasMany)('engagement', {
      inverse: 'coach'
    }),
    pools: (0, _model.hasMany)()
  });

  _exports.default = _default;
});