define("coachlogix/services/topbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TopBarService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "solid", true);

      _defineProperty(this, "background", false);
    }

    setTransparent() {
      this.set('solid', false);
      this.set('background', false);
    }

    setSolid() {
      this.set('solid', true);
      this.set('background', false);
    }

    setBackground() {
      this.set('solid', false);
      this.set('background', true);
    }

  }

  _exports.default = TopBarService;
});