define("coachlogix/pods/employee/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Employee = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec5 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec6 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec7 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class Employee extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "topbar", _descriptor3, this);
    }

    model({
      employee_id,
      clientId,
      contactType,
      contactId
    }) {
      return {
        employeeTask: this.loadEmployee.perform(employee_id, clientId),
        engagementTask: this.loadMatchingEngagement.perform(),
        clientTask: this.loadClient.perform(clientId),
        contactTask: this.loadContact.perform(contactType, contactId),
        clientId,
        contactType
      };
    }

    async afterModel() {
      this.topbar.setTransparent();
    }

    *loadEmployee(id, clientId) {
      let queryParams = {
        include_metadata: 1
      };

      if (clientId) {
        queryParams.metadata_client = clientId;
      }

      let employee = yield this.store.findRecord('employee', id, {
        include: ['user.timezone', 'user.work-experience', 'user.availability-preferences.default-meeting-location', 'coaching-requests', 'coaching-requests.coachee', 'coaching-requests.engagement', 'coaching-requests.user', 'role-users.role', 'user.role-users.role', 'employee-level', 'company-location', 'department', 'user.profile', 'user.profile.coaching-categorizations', 'user.profile.user-affiliations', 'user.profile.work-experiences', 'user.profile.credentials.issuing-org', 'user.profile.education-degrees', 'user.profile.assessments.certified-assessment', 'user.profile.publications', 'user.profile.languages.language', 'user.profile.languages.fluency'].join(','),
        adapterOptions: {
          queryParams
        },
        reload: true
      });
      let user = yield employee.get('user');
      let profile = yield user.get('profile');

      if (!profile) {
        profile = this.store.createRecord('profile', {
          user
        });
        user.set('profile', profile);
      }

      return employee;
    }

    *loadContact(contactType, contactId) {
      if (!contactId) return;
      let modelName;

      if (contactType === 'coaches') {
        modelName = 'client-coach';
      } else if (contactType === 'coachees') {
        modelName = 'client-coachee';
      } else if (contactType === 'pocs') {
        modelName = 'client-poc';
      }

      return yield this.store.findRecord(modelName, contactId, {
        include: 'employee.user,client'
      });
    }

    *loadMatchingEngagement() {
      let engagementId = this.account.activeEmployee.belongsTo('matchingProcess').id();

      if (engagementId) {
        return yield this.store.findRecord('engagement', engagementId, {
          reload: true,
          include: ['coach.user', 'coach.user.availability-preferences', 'coach.user.cronofy-connected-account', 'coach.user.timezone', 'coachee.user', 'coach.account', 'client', 'matching-settings'].join(',')
        });
      }
    }

    *loadClient(id) {
      if (!id) return null;
      return yield this.store.findRecord('client', id, {
        reload: true,
        adapterOptions: {
          queryParams: {
            include_metadata: 1
          }
        },
        include: ['client-pocs.employee.user', 'client-coachees.employee', 'client-coaches.employee', 'client-pocs.employee.invitation', 'client-locations', 'primary-contact', 'client-account', 'account', 'lead', 'requestor-account', 'client-summary'].join(',')
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "topbar", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadEmployee", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "loadEmployee"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadContact", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loadContact"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMatchingEngagement", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadMatchingEngagement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadClient", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "loadClient"), _class.prototype)), _class));
  _exports.default = Employee;
});