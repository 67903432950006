define("coachlogix/transforms/datetime", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize(serialized) {
      let type = typeof serialized;

      if (type === 'string') {
        let offset = serialized.indexOf('+');

        if (offset !== -1 && serialized.length - 3 === offset) {
          (true && !(false) && Ember.deprecate(`The ECMA2015 Spec for ISO 8601 dates does not allow for shorthand timezone offsets such as +00.
          Ember Data's normalization of date's allowing for this shorthand has been deprecated, please update your API to return
          UTC dates formatted with ±hh:mm timezone offsets or implement a custom UTC transform.`, false, {
            id: 'ds.attr.date.normalize-utc',
            until: '3.0.0'
          }));
          return new Date(`${serialized}:00`); // this is a phantom specific bug fix in which +0000 is not supported
        } else if (offset !== -1 && serialized.length - 5 === offset) {
          offset += 3;
          return new Date(serialized.slice(0, offset) + ':' + serialized.slice(offset));
        }

        return new Date(serialized);
      } else if (type === 'number') {
        return new Date(serialized);
      } else if (serialized === null || serialized === undefined) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return serialized;
      } else {
        return null;
      }
    },

    serialize(date) {
      if (date instanceof Date && !isNaN(date) || date && (0, _moment.default)(date).isValid()) {
        return (0, _moment.default)(date).toISOString(); // format('YYYY-MM-DD'/* HH:mm:SS*/);
      } else {
        return null;
      }
    }

  });

  _exports.default = _default;
});