define("coachlogix/pods/invoice/index/sessions-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2K6iooJv",
    "block": "{\"symbols\":[\"session\",\"@onClose\",\"@onSelect\"],\"statements\":[[8,\"cl-selection-dialog\",[],[[\"@title\",\"@required\",\"@search\",\"@onClose\",\"@options\",\"@onChange\",\"@multiple\",\"@noOptionsText\",\"@auxButtonClick\",\"@auxButtonLabel\"],[[30,[36,0],[\"invoice.select_session\"],null],true,false,[32,2],[32,0,[\"sessions\"]],[32,3],true,[30,[36,0],[\"invoice.no_session\"],null],[32,0,[\"addNewSession\"]],[30,[36,1],[[30,[36,0],[\"invoice.add_new_session\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"title\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"em\"],[12],[1,[30,[36,0],[\"invoice.untitled_session\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[6,[37,2],[[32,1,[\"duration\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[32,1,[\"duration\"]]],[2,\" \"],[1,[30,[36,0],[\"invoice.minutes\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"titleize\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/invoice/index/sessions-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});