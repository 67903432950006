define("coachlogix/abilities/user", ["exports", "ember-can", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    canEdit: Ember.computed('account.activeUser', 'model.{ownerId,id}', function () {
      if (this.get('account.activeUser.id') === this.get('model.ownerId')) {
        return true;
      }

      if (this.get('account.activeUser.id') === this.get('model.id')) {
        return true;
      }

      return false;
    }),
    canEditCoachProfile: Ember.computed('account.{activeAccount.id,activeEmployee.id,activeUser.id}', 'model.account.{id,isActive}', 'model.{id,isActive}', function () {
      let currentUserId = this.get('account.activeUser.id');
      let currentAccountId = this.get('account.activeAccount.id');
      let userId = this.get('model.id');
      let userAccountId = this.get('model.account.id');
      let hasManagePermissions = this.hasPermission('manage_account_employees');
      let userIsActive = this.get('model.isActive');
      let userAccountIsActive = this.get('model.account.isActive');

      if (currentAccountId === userAccountId) {
        // same account
        if (hasManagePermissions) {
          // current user has manage_account_employees
          return true;
        }

        if (currentUserId === userId) {
          // this employee belongs to this user's account
          return true;
        }
      } else {
        // from another account
        if (hasManagePermissions && !userAccountIsActive && !userIsActive) {
          // current user has manage_account_employees and this employee is *not* active and the other account isn't active as well
          return true;
        }
      }

      return false;
    })
  });

  _exports.default = _default;
});