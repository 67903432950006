define("coachlogix/pods/components/ui/upgrade-zone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YHYNxIVo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,7],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"icon\"],[\"block\"]]]],[2,\"\\n    \"],[10,\"p\"],[14,0,\"md-title\"],[12],[1,[30,[36,4],[\"settings.subscription.upgrade-now\"],null]],[13],[2,\"\\n\"],[6,[37,6],null,[[\"primary\",\"raised\",\"onClick\"],[true,true,[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null],true],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,4],[\"ui.upgrade\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"onClose\"],[[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null],false],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"showPlans\",\"mut\",\"action\",\"settings/subscription/plans-dialog\",\"t\",\"ui/empty-state\",\"paper-button\",\"if\",\"canUseFeature\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/ui/upgrade-zone/template.hbs"
    }
  });

  _exports.default = _default;
});