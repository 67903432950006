define("coachlogix/pods/components/v2/employee/update-dialog/component", ["exports", "@glimmer/component", "ember-changeset", "ember-changeset-validations", "ember-concurrency", "ember-concurrency-decorators", "coachlogix/helpers/unwrap-proxy", "coachlogix/validations/profile", "coachlogix/validations/user"], function (_exports, _component, _emberChangeset, _emberChangesetValidations, _emberConcurrency, _emberConcurrencyDecorators, _unwrapProxy, _profile, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmployeeUpdateDialog = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._cached, _dec9 = Ember._cached, _dec10 = Ember._cached, _dec11 = Ember._cached, _dec12 = Ember._action, _dec13 = Ember._cached, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = (_temp = class EmployeeUpdateDialog extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "account", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "employeeChangeset", _descriptor5, this);

      _initializerDefineProperty(this, "userChangeset", _descriptor6, this);

      _initializerDefineProperty(this, "profileChangeset", _descriptor7, this);

      this.employeeChangeset = new _emberChangeset.Changeset(this.args.employee);
      let user = (0, _unwrapProxy.unwrapProxy)(this.args.employee.get('user'));
      this.userChangeset = new _emberChangeset.Changeset(user, (0, _emberChangesetValidations.default)(_user.default), _user.default);
      this.userChangeset.validate();
      let profile = (0, _unwrapProxy.unwrapProxy)(user.get('profile'));
      this.profileChangeset = new _emberChangeset.Changeset(profile, (0, _emberChangesetValidations.default)(_profile.default), _profile.default);
      this.profileChangeset.validate();
    }

    get employeeLevels() {
      return this.store.findAll('employee-level');
    }

    get departments() {
      // filter only top level departments
      return this.store.findAll('department', {
        reload: true
      }).then(departments => {
        return departments.filter(d => d.belongsTo('parent').id() === null);
      });
    }

    get subDepartments() {
      if (this.employeeChangeset.get('department')) {
        let department = this.employeeChangeset.get('department');
        return department.get('subDepartments');
      } else {
        return [];
      }
    }

    get companyLocations() {
      return this.getCompanyLocations.perform();
    }

    *getCompanyLocations() {
      if (this.account.activeRoles.includes('account_global_admin')) {
        return yield this.account.activeAccount.get('companyLocations');
      } else if (this.account.activeRoles.includes('location_global_admin')) {
        let locationAdminRoleUsers = this.account.activeRoleUsers.filter(ur => {
          return ur.get('role.name') === 'location_global_admin';
        });
        return yield (0, _emberConcurrency.all)(locationAdminRoleUsers.mapBy('adminable'));
      } else {
        return [];
      }
    }

    *saveChanges() {
      try {
        let [employee] = yield (0, _emberConcurrency.all)([this.employeeChangeset.save(), this.profileChangeset.save(), this.userChangeset.save()]);
        this.args.onClose?.();
        this.args.onSave?.(employee);
        this.clToaster.success(this.intl.t('ui.coach-profile.contact.info-updated'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    changeDepartment(department) {
      this.employeeChangeset.set('department', department);
      this.employeeChangeset.set('subDepartment', null);
    }

    get countries() {
      return this.store.findAll('country');
    }

    get selectedCountry() {
      return this.countries.find(c => c.iso31662 === this.profileChangeset.country);
    } // This needs to be placed here because changesets don't support computed properties


    get fullLocation() {
      let properties = ['city', 'state'];
      return properties.map(p => this.profileChangeset[p]).filter(Boolean).join(', ');
    } // This needs to be placed here because changesets don't proxy method calls to their wrapping object correctly


    setLocation(place) {
      if (Ember.isPresent(place)) {
        // a place was deselected
        this.profileChangeset.set('locationInfo', place);

        for (let a of place.address_components) {
          if (a.types.includes('country')) {
            this.profileChangeset.set('country', a.short_name);
          } else if (a.types.includes('locality')) {
            this.profileChangeset.set('city', a.long_name);
          } else if (a.types.includes('administrative_area_level_1')) {
            this.profileChangeset.set('state', a.long_name);
          } else if (a.types.includes('postal_code')) {
            this.profileChangeset.set('postalCode', a.long_name);
          }
        }
      } else {
        // place was deselected
        this.profileChangeset.set('locationInfo', null);
        this.profileChangeset.set('city', null);
        this.profileChangeset.set('state', null);
        this.profileChangeset.set('postalCode', null);
      }
    }

    setCountry(country) {
      this.profileChangeset.set('country', country ? country.iso31662 : null);
      this.profileChangeset.set('locationInfo', null);
      this.profileChangeset.set('city', null);
      this.profileChangeset.set('state', null);
      this.profileChangeset.set('postalCode', null);
    }

    selectAvatar({
      filesUploaded
    }) {
      try {
        this.userChangeset.set('avatar', filesUploaded[0].url);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "employeeChangeset", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userChangeset", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "profileChangeset", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "employeeLevels", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "employeeLevels"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "departments", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "departments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "subDepartments", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "subDepartments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "companyLocations", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "companyLocations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCompanyLocations", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getCompanyLocations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeDepartment", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "changeDepartment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countries", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "countries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setLocation", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCountry", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAvatar", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "selectAvatar"), _class.prototype)), _class));
  _exports.default = EmployeeUpdateDialog;
});