define("coachlogix/pods/components/v2/messaging/channels-list/list-item/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsListItem = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('userDescriptors.[]', 'channelTopic'), _dec5 = Ember.computed('channel'), _dec(_class = (_class2 = (_temp = class ChannelsListItem extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "twilio", _descriptor2, this);
    }

    get channelName() {
      let users = this.userDescriptors || [];

      if (users.length >= 2) {
        return this.channelTopic;
      } else {
        let names = users.map(u => u.friendlyName);
        return names.join(', ');
      }
    }

    get isGroup() {
      return this.channel.uniqueName.startsWith('cl-group:');
    }

    init() {
      super.init(...arguments);
      this.channels = [];
      this.getUsers.perform();
      this.getUnreadCount.perform();
      this.set('channelTopic', this.channel.friendlyName);

      this._onMemberJoined = (...args) => this.onMemberJoined(...args);

      this.channel.on('memberJoined', this._onMemberJoined);

      this._onUpdated = (...args) => this.onUpdated(...args);

      this.channel.on('updated', this._onUpdated);
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.channel.removeListener('memberJoined', this._onMemberJoined);
      this.channel.removeListener('updated', this._onUpdated);
      this.removeUserEventListeners();
    }

    *getUsers() {
      let channel = this.channel;
      let userDescriptors = yield channel.getUserDescriptors();
      let ownIdentity = this.account.activeUser.twilioIdentity;
      userDescriptors = userDescriptors.items.filter(u => u.identity !== ownIdentity);
      this.set('userDescriptors', userDescriptors);

      if (!this.isGroup) {
        let users = yield (0, _emberConcurrency.all)(userDescriptors.map(u => u.subscribe()));
        this.removeUserEventListeners();
        this.set('users', users);
        this.addUserEventListeners();
      }
    }

    addUserEventListeners() {
      this._onUserUpdated = (...args) => this.onUserUpdated(...args);

      let users = this.users || [];

      for (let user of users) {
        user.on('updated', this._onUserUpdated);
        this.set('isOnline', user.online);
      }
    }

    removeUserEventListeners() {
      let users = this.users || [];

      for (let user of users) {
        user.removeListener('updated', this._onUserUpdated);
      }
    }

    *getUnreadCount() {
      yield (0, _emberConcurrency.timeout)(1000);
      let unreadCount = 0;

      if (this.channel.lastConsumedMessageIndex === null) {
        unreadCount = yield this.channel.getMessagesCount();
      } else {
        unreadCount = yield this.channel.getUnconsumedMessagesCount();
      }

      this.set('unreadCount', unreadCount);
    }

    onMemberJoined() {
      this.getUsers.perform();
    }

    onUserUpdated({
      user
    }) {
      this.set('isOnline', user.online);
    }

    onUpdated({
      updateReasons
    }) {
      if (updateReasons.includes('lastConsumedMessageIndex') || updateReasons.includes('lastMessage')) {
        this.getUnreadCount.perform();
      }

      if (updateReasons.includes('friendlyName')) {
        this.set('channelTopic', this.channel.friendlyName);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "twilio", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "channelName", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "channelName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isGroup", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isGroup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getUsers", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "getUsers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "getUnreadCount", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "getUnreadCount"), _class2.prototype)), _class2)) || _class);
  var _default = ChannelsListItem;
  _exports.default = _default;
});