define("coachlogix/pods/form/form-editor/form-element/number/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let FormElementNumberComponent = (_dec = (0, _component.tagName)(''), _dec(_class = class FormElementNumberComponent extends Ember.Component {}) || _class);
  var _default = FormElementNumberComponent;
  _exports.default = _default;
});