define("coachlogix/pods/components/v2/messaging/user-selection-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zDRkVt0K",
    "block": "{\"symbols\":[\"@title\",\"@onClose\"],\"statements\":[[8,\"cl-employee-selection-dialog\",[[24,0,\"cl-w-32\"]],[[\"@required\",\"@multiple\",\"@canChat\",\"@title\",\"@onChange\",\"@onClose\"],[true,true,true,[30,[36,1],[[32,1],[32,1],[30,[36,0],[\"messaging.new_conversation\"],null]],null],[30,[36,2],[[32,0,[\"onEmployeesSelection\"]]],null],[32,2]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"perform\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/messaging/user-selection-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});