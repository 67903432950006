define("coachlogix/pods/components/cl-chip-filters/range/component", ["exports", "@glimmer/component", "tracked-toolbox"], function (_exports, _component, _trackedToolbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ClChipFiltersRangeComponent = (_dec = (0, _trackedToolbox.localCopy)('args.filter.meta.min'), _dec2 = (0, _trackedToolbox.localCopy)('args.filter.meta.max'), _dec3 = Ember._action, (_class = (_temp = class ClChipFiltersRangeComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "min", _descriptor, this);

      _initializerDefineProperty(this, "max", _descriptor2, this);

      let min = this.args.appliedFilter?.find(o => o.operation === 'greater');
      let max = this.args.appliedFilter?.find(o => o.operation === 'lesser');
      this.minValue = min?.value || this.min;
      this.maxValue = max?.value || this.max;
    }

    handleApply() {
      this.args.applyFilter?.([{
        value: this.minValue,
        operation: 'greater'
      }, {
        value: this.maxValue,
        operation: 'lesser'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "min", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "max", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleApply", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleApply"), _class.prototype)), _class));
  _exports.default = ClChipFiltersRangeComponent;
});