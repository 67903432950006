define("coachlogix/abilities/dashboard", ["exports", "ember-can", "ember-can/computed", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _computed, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    accountAbility: (0, _computed.ability)('account'),

    roleIsAny(...roles) {
      const role = this.get('role');
      const activeRoles = this.get('account.activeRoles');

      if (activeRoles) {
        return activeRoles.some(r => roles.includes(r));
      }

      return roles.includes(role);
    },

    canSeeWelcomeMessage: Ember.computed('activeRoles.[]', function () {
      return !this.hasOnlyRoles('account_employee', 'account_coach_viewer');
    }),
    canSeeBillableActivity: Ember.computed('account.activeEmployee.{isCoach,isInternal}', 'accountAbility.{canBuyCoaching,canSellCoaching}', 'activeRoles.[]', function () {
      let isAdmin = this.roleIsAny('account_global_admin', 'location_global_admin');
      let isInternalCoach = this.get('account.activeEmployee.isCoach') && this.get('account.activeEmployee.isInternal');

      if (isInternalCoach && !isAdmin) {
        return false;
      }

      return this.accountAbility.canSellCoaching || this.accountAbility.canBuyCoaching;
    }),
    canSeeInvoices: Ember.computed.reads('canSeeBillableActivity'),
    canSeeTopThemes: Ember.computed('account.activeAccount.activePermissions.[]', function () {
      return this.hasPermission('view_account_settings');
    }),
    canSeeEngagementsInfo: Ember.computed('account.activeAccount.activePermissions.[]', function () {
      return this.hasPermission('view_account_settings');
    }),
    canSeeHoursInfo: Ember.computed('account.{activeAccount.activePermissions.[],activeEmployee.isCoach}', function () {
      return this.hasPermission('view_account_settings') || this.account.activeEmployee.isCoach;
    }),
    canSeeSessionsInfo: Ember.computed('account.{activeAccount.activePermissions.[],activeEmployee.isCoach}', function () {
      return this.hasPermission('view_account_settings') || this.account.activeEmployee.isCoach;
    }),
    canSeeInterviews: Ember.computed('account.{activeAccount.activePermissions.[],activeEmployee.isCoach}', function () {
      return this.hasPermission('view_account_settings') || this.account.activeEmployee.isCoach;
    }),
    canSeeCoaches: Ember.computed('account.activeAccount.activePermissions.[]', function () {
      return this.hasPermission('view_account_settings');
    })
  });

  _exports.default = _default;
});