define("coachlogix/pods/components/settings/subscription/payment-method-dialog/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    stripe: Ember.inject.service('stripe-old'),
    clToaster: Ember.inject.service(),
    account: Ember.inject.service(),
    tagName: '',
    isInvalid: Ember.computed('selectedCard', 'creatingNewCard', 'cardValid', 'name', function () {
      if (this.get('creatingNewCard')) {
        return !this.get('cardValid') || !this.get('name');
      } else {
        return !this.get('selectedCard');
      }
    }),
    actions: {
      toggleSelectedCard(card) {
        let selectedCard = this.get('selectedCard');

        if (selectedCard === card) {
          this.set('selectedCard', null);
        } else {
          this.set('selectedCard', card);
        }

        this.set('creatingNewCard', false);
      },

      enterCreateCardMode() {
        this.set('selectedCard', null);
        this.set('creatingNewCard', true);
      }

    },
    cards: (0, _emberConcurrency.task)(function* () {
      return yield this.get('store').findAll('stripe-card', {
        reload: true
      });
    }).on('init'),
    loadSelectedCard: (0, _emberConcurrency.task)(function* () {
      let subscription = yield this.get('account.activeAccount.subscription');
      let stripeCard = yield subscription.get('stripeCard');
      this.set('selectedCard', stripeCard);
      return stripeCard;
    }).on('init'),
    updateCard: (0, _emberConcurrency.task)(function* () {
      let stripeCard = this.get('selectedCard');
      let activeSubscription = yield this.get('account.activeAccount.subscription'); // create stripe card, if user asked for that

      if (!stripeCard && this.get('creatingNewCard')) {
        let stripe = this.get('stripe').stripe;
        let cardElement = this.get('cardElement');

        try {
          let {
            source
          } = yield stripe.createSource(cardElement, {
            owner: {
              name: this.get('name')
              /* address: {
                city: this.get('city'),
                country: this.get('country.iso31662'),
                line1: this.get('address'),
                line2: this.get('address2'),
                postal_code: this.get('zip'),
                state: this.get('state')
              } */
              // TODO add more information about owner

            }
          });
          stripeCard = yield this.get('store').createRecord('stripe-card', {
            cardBrand: source.card.brand,
            expMonth: source.card.exp_month,
            expYear: source.card.exp_year,
            cardId: 'N/A',
            // workaround for now
            cardLastFour: source.card.last4,
            cardToken: source.id
          }).save();
        } catch (e) {
          this.get('clToaster').error(e.error.message);
          return;
        }
      } // create subscription


      try {
        activeSubscription.set('stripeCard', stripeCard);
        yield activeSubscription.save();
        this.get('clToaster').success('Payment method updated.');
      } catch (e) {
        this.get('clToaster').error('An error has occurred while updating your payment method.');
      }

      if (typeof this.onClose === 'function') {
        this.onClose();
      }
    })
  });

  _exports.default = _default;
});