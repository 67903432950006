define("coachlogix/pods/components/profile/general-info/employee-levels-edit-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OUkDJHfA",
    "block": "{\"symbols\":[\"type\",\"@onClose\",\"&attrs\"],\"statements\":[[8,\"cl-selection-dialog\",[[17,3]],[[\"@title\",\"@search\",\"@searchField\",\"@multiple\",\"@onClose\",\"@options\",\"@selected\",\"@onChange\"],[[30,[36,0],[\"v2.employee.profile.select-levels\"],null],true,\"value\",true,[32,2],[30,[36,1],[[32,0,[\"getDefaultLevels\"]]],null],[30,[36,2],[[32,0,[\"changeset\"]],\"employeeLevels\"],null],[30,[36,1],[[32,0,[\"updateCoachingCategorization\"]],\"employeeLevels\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[1,[32,1,[\"value\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"perform\",\"changeset-get\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/profile/general-info/employee-levels-edit-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});