define("coachlogix/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uDwmJO15",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Coaching.com\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[[32,0,[\"routeStyleNamespaceClassSet\"]],\" cl-flex cl-h-full\"]]],[12],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"isDev\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"animated-tools\",[],[[\"@hideUntilKeys\"],[\"Ctrl-Shift-KeyA\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[8,\"ember-load-remover\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});