define("coachlogix/abilities/engagement-task", ["exports", "ember-can", "coachlogix/abilities/engagement-permission-helpers", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _engagementPermissionHelpers, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_engagementPermissionHelpers.default, _userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    engagement: Ember.computed.reads('model.engagement'),
    isFromTemplate: Ember.computed.reads('model.isFromTemplate'),
    isSent: Ember.computed.equal('model.status', 'active'),
    isComplete: Ember.computed.equal('model.status', 'completed'),
    canEdit: Ember.computed('roles.[]', 'isFromTemplate', 'engagementStatus', 'status', function () {
      if (this.get('isFromTemplate')) {
        return true;
      }

      return this.engagementStatusIsAny('active', 'draft', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager');
    }),
    canManageTask: Ember.computed('roles.[]', 'isFromTemplate', 'engagementStatus', 'status', function () {
      if (this.get('isFromTemplate')) {
        return true;
      }

      return this.engagementStatusIsAny('active', 'draft', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('admin', 'manager');
    }),
    canEditTitle: Ember.computed.reads('canManageTask'),
    canEditDescription: Ember.computed.reads('canManageTask'),
    canDuplicate: Ember.computed.reads('canManageTask'),
    canDelete: Ember.computed.reads('canManageTask'),
    canEditNotifications: Ember.computed('engagementStatus', 'isCoach', 'roles.[]', 'status', function () {
      return this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && this.statusIsAny('draft', 'published') && (this.roleIsAny('admin', 'manager') || this.get('isCoach'));
    }),
    canDownloadResources: Ember.computed('model.resource.isFile', 'roles.[]', 'status', function () {
      if (!this.model.get('resource.isFile')) return false;

      if (this.roleIsAny('admin', 'manager', 'coach')) {
        return true;
      }

      if (this.roleIsAny('coachee', 'viewer') && this.statusIsAny('published', 'completed')) {
        return true;
      }

      return false;
    }),
    canActionBar: Ember.computed('roles.[]', 'engagementStatus', 'status', function () {
      return this.statusIsAny('draft', 'published', 'unassigned') && this.roleIsAny('admin', 'manager', 'coach') || this.engagementStatusIsAny('active') && this.statusIsAny('published') && this.roleIsAny('coachee');
    }),
    canSend: Ember.computed('roles.[]', 'engagementStatus', 'status', function () {
      return this.engagementStatusIsAny('active') && this.statusIsAny('draft', 'published', 'unassigned') && this.roleIsAny('admin', 'manager', 'coach');
    }),
    canResend: Ember.computed('roles.[]', 'engagementStatus', 'status', function () {
      return this.engagementStatusIsAny('active') && this.statusIsAny('published') && this.roleIsAny('admin', 'manager', 'coach');
    }),
    canComplete: Ember.computed('account.activeEmployee.id', 'accountIsCoach', 'engagementStatus', 'isCoachee', 'model.{assignees,isForm}', 'role', 'roles.[]', 'status', function () {
      // forms are not manually completable
      if (this.model.isForm) {
        return false;
      } // Only complete on active engagements


      let engagementIsActive = this.engagementStatusIsAny('active');

      if (!engagementIsActive) {
        return false;
      } // Admin/Managers/Coaches


      let isAllowedRole = (this.roleIsAny('admin', 'manager', 'coach') || this.get('accountIsCoach')) && this.role !== 'viewer';
      let canBeCompleted = this.statusIsAny('draft', 'published');

      if (isAllowedRole && canBeCompleted) {
        return true;
      } // Coachees and task assignees


      let isTaskSent = this.statusIsAny('published');
      let activeEmployeeId = this.get('account.activeEmployee.id');
      let isTaskAssignee = (this.get('model.assignees') || []).findBy('id', activeEmployeeId);
      let isAllowedToCompleteSent = this.get('isCoachee') || isTaskAssignee;

      if (isTaskSent && isAllowedToCompleteSent) {
        return true;
      }

      return false;
    }),
    canRecomplete: Ember.computed('accountIsCoach', 'engagementStatus', 'model.isForm', 'roles.[]', 'status', function () {
      return !this.model.isForm && // forms are not manually completable
      this.engagementStatusIsAny('active') && (this.roleIsAny('admin', 'manager', 'coach') || this.get('accountIsCoach')) && this.statusIsAny('completed');
    }),
    canSeeResourceIcon: Ember.computed('roles.[]', 'engagementStatus', 'status', function () {
      return !this.roleIsAny('viewer');
    }),
    canManageAssignees: Ember.computed('engagementStatus', 'isFromTemplate', 'model', 'roles.[]', 'status', function () {
      if (this.get('isFromTemplate')) {
        return true;
      }

      let task = this.model;
      let isSingleAssignee = task.isTodo || task.isLink || task.isMilestone || task.isFile; // only allow to manage assignees after sending on forms (the only task that can have multiple assignees)

      return this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && (isSingleAssignee && this.statusIsAny('draft') || task.isForm) && this.roleIsAny('admin', 'manager', 'coach');
    }),
    canManageTasks: Ember.computed('roles.[]', 'engagementStatus', 'status', function () {
      return this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && this.statusIsAny('draft', 'published') && this.roleIsAny('admin', 'manager');
    }),
    canSeeCoacheeWarning: Ember.computed('isCoachee', 'model', 'status', function () {
      return this.isCoachee && this.statusIsAny('draft');
    }),
    canComment: Ember.computed('roles.[]', 'engagementStatus', function () {
      return this.engagementStatusIsAny('active') && this.roleIsAny('admin', 'manager', 'coach', 'coachee', 'viewer');
    }),
    canViewComments: Ember.computed('roles.[]', 'engagementStatus', function () {
      return this.engagementStatusIsAny('active', 'inactive', 'completed') && this.roleIsAny('admin', 'manager', 'coach', 'coachee', 'viewer');
    }),
    // can see confidential responses if global_admin and current account is the creator of engagement
    canSeeFormResponses: Ember.computed('activeRoles.[]', 'model.{resource.isConfidential,engagement.ownerAccount}', 'account.activeAccount.id', function () {
      if (this.get('model.resource.isConfidential')) {
        let isCreatorAccount = this.model.get('engagement.ownerAccount.id') === this.account.activeAccount.id;
        return isCreatorAccount && this.hasAccountRole('account_global_admin');
      }

      if (this.roleIsAny('viewer')) {
        return false;
      }

      return true;
    }),
    canSeeOwnFormResponses: Ember.computed('account.activeUser.id', 'model.formSubmissions.@each.user', function () {
      return this.model.get('formSubmissions').some(s => {
        return s.belongsTo('user').id() === this.account.activeUser.id;
      });
    }),
    canPreviewForm: Ember.computed('roles.[]', function () {
      return this.roleIsAny('admin', 'manager', 'coach');
    }),
    canRespondForm: Ember.computed('canSeeOwnFormResponses', 'status', function () {
      return this.statusIsAny('published') && !this.canSeeOwnFormResponses;
    }),
    canPreviewFile: Ember.computed('roles.[]', 'status', function () {
      return this.roleIsAny('admin', 'manager', 'coach') || this.roleIsAny('coachee') && this.statusIsAny('published', 'completed');
    }),
    canPreviewLink: Ember.computed('roles.[]', 'status', function () {
      return this.roleIsAny('admin', 'manager', 'coach') || this.roleIsAny('coachee') && this.statusIsAny('published', 'completed');
    })
  });

  _exports.default = _default;
});