define("coachlogix/pods/form/external-send-dialog/component", ["exports", "@glimmer/component", "email-addresses", "ember-concurrency-decorators"], function (_exports, _component, _emailAddresses, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormExternalSendDialogComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class FormExternalSendDialogComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clToaster", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "selectedTab", _descriptor4, this);

      _initializerDefineProperty(this, "showEmployeesSelection", _descriptor5, this);

      _initializerDefineProperty(this, "addressesString", _descriptor6, this);

      _initializerDefineProperty(this, "subject", _descriptor7, this);

      _initializerDefineProperty(this, "message", _descriptor8, this);

      _initializerDefineProperty(this, "invalidAddresses", _descriptor9, this);

      _initializerDefineProperty(this, "validAddresses", _descriptor10, this);
    }

    get isInvalidSend() {
      return Ember.isEmpty(this.validAddresses) || Ember.isEmpty(this.subject) || Ember.isEmpty(this.message);
    }

    handleAddressChange(value = '') {
      this.addressesString = value; // split addresses by the possible separators
      // in this case comma, semicolon and new lines
      // remove empty values if existing

      let addresses = value.split(/\r\n|\r|\n|,|;/).map(a => a.trim()).filter(a => a !== '');
      let invalidAddresses = [];
      let validAddresses = [];

      for (let a of addresses) {
        let parsed = _emailAddresses.default.parseOneAddress({
          input: a,
          strict: false,
          // turn off obsolete features in the rfc
          rejectTLD: true // domains require a "."

        });

        if (parsed === null) {
          invalidAddresses.push(a);
        } else {
          let exists = validAddresses.some(a => a.address === parsed.address);

          if (!exists) {
            validAddresses.push(parsed);
          }
        }
      }

      this.invalidAddresses = invalidAddresses;
      this.validAddresses = validAddresses;
    }

    highlightInvalid(address, event) {
      event.preventDefault();

      if (this.addressInputEl) {
        let start = this.addressesString.indexOf(address);
        let end = start + address.length;
        this.addressInputEl.focus();
        this.addressInputEl.setSelectionRange(start, end);
      }
    }

    addEmployeeEmails(employees) {
      // filter out employees that are already in the addresses list
      employees = employees.filter(e => !this.validAddresses.find(a => e.get('user.email') === a.address));

      if (employees.length === 0) {
        return;
      }

      let emailsString = employees.map(e => {
        if (Ember.isEmpty(e.get('user.firstName')) && Ember.isEmpty(e.get('user.lastName'))) {
          return e.get('user.email');
        } else {
          return `${e.get('user.name')} <${e.get('user.email')}>`;
        }
      }).join(';\n');
      this.addressesString = Ember.isEmpty(this.addressesString) ? emailsString : `${this.addressesString};\n${emailsString}`;
      this.handleAddressChange(this.addressesString);
    }

    *send() {
      try {
        yield this.ajax.post('helpers/send-form', {
          data: {
            form_id: this.args.form.id,
            subject: this.subject,
            message: this.message,
            recipients: this.validAddresses.map(a => ({
              name: a.name,
              email: a.address
            }))
          }
        });
        this.clToaster.success(this.intl.t('form.external-send-dialog.send-success'));
        this.args.onClose?.();
      } catch (e) {
        this.clToaster.error(e.payload.message || e);
      }
    }

    copy() {
      if (this.linkInputEl) {
        this.linkInputEl.select();
        document.execCommand('copy');
        this.clToaster.info(this.intl.t('form.external-send-dialog.copied'));
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedTab", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showEmployeesSelection", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "addressesString", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "subject", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "message", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "invalidAddresses", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "validAddresses", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleAddressChange", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "handleAddressChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "highlightInvalid", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "highlightInvalid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addEmployeeEmails", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "addEmployeeEmails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "send", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "send"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copy", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "copy"), _class.prototype)), _class));
  _exports.default = FormExternalSendDialogComponent;
});