define("coachlogix/pods/dashboard/route", ["exports", "ember-can/computed", "ember-concurrency-decorators"], function (_exports, _computed, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _computed.ability)('dashboard'), _dec5 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class DashboardRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "topbar", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _defineProperty(this, "queryParams", {
        coachingRequestId: {
          replace: true
        },
        approveContractId: {
          replace: true
        }
      });

      _initializerDefineProperty(this, "dashboardAbility", _descriptor4, this);

      _defineProperty(this, "allWidgets", () => [{
        id: 'top10-themes',
        name: 'Top 10 Themes',
        component: 'dashboard/top10-themes',
        image: 'img/widgets/top10-themes.png',
        position: 1,
        estimatedHeight: 647,
        can: this.dashboardAbility.canSeeTopThemes
      }, {
        id: 'engagements-info',
        name: 'Engagements',
        component: 'dashboard/engagements-info',
        image: 'img/widgets/engagements.png',
        position: 2,
        estimatedHeight: 242,
        can: this.dashboardAbility.canSeeEngagementsInfo
      }, {
        id: 'hours-info',
        name: 'Hours Info',
        component: 'dashboard/hours-info',
        image: 'img/widgets/hours-info.png',
        position: 3,
        estimatedHeight: 141,
        can: this.dashboardAbility.canSeeHoursInfo
      }, {
        id: 'sessions-info',
        name: 'Sessions Info',
        component: 'dashboard/sessions-info',
        image: 'img/widgets/sessions-info.png',
        position: 4,
        estimatedHeight: 141,
        can: this.dashboardAbility.canSeeSessionsInfo
      }, {
        id: 'billable-activity',
        name: 'Billable Activity',
        component: 'dashboard/billable-activity',
        image: 'img/widgets/billable-activity.png',
        position: 5,
        estimatedHeight: 436,
        can: this.dashboardAbility.canSeeBillableActivity
      }, {
        id: 'tasks',
        name: 'Tasks',
        component: 'dashboard/tasks',
        image: 'img/widgets/tasks.png',
        position: 6,
        estimatedHeight: 100,
        can: true
      }, {
        id: 'sessions',
        name: 'Sessions',
        component: 'dashboard/sessions',
        image: 'img/widgets/sessions.png',
        position: 7,
        estimatedHeight: 394,
        can: true
      }, {
        id: 'coaches-info',
        name: 'Coaches',
        component: 'dashboard/coaches-info',
        image: 'img/widgets/coaches.png',
        position: 8,
        estimatedHeight: 128,
        can: this.dashboardAbility.canSeeCoaches
      }, {
        id: 'interviews',
        name: 'Coach Matching Interviews',
        component: 'dashboard/interviews',
        image: 'img/widgets/matching-interviews.png',
        position: 9,
        estimatedHeight: 485,
        can: this.dashboardAbility.canSeeInterviews
      }, {
        id: 'stakeholder-feedback',
        name: 'Stakeholder Feedback',
        component: 'dashboard/stakeholder-feedback',
        image: 'img/widgets/stakeholder-feedback.png',
        position: 10,
        estimatedHeight: 283,
        can: true
      }, {
        id: 'invoices',
        name: 'Invoices Summary',
        component: 'dashboard/invoices',
        image: 'img/widgets/invoices-info.png',
        position: 11,
        estimatedHeight: 256,
        can: this.dashboardAbility.canSeeInvoices
      }].filter(w => w.can));
    }

    activate() {
      super.activate(...arguments);
      this.topbar.setSolid();
    }

    redirect() {
      if (this.account.isCoachee) {
        this.transitionTo('engagements');
      }
    }

    model() {
      return this.restoreFromConfigString.perform();
    }

    setupController(controller) {
      super.setupController(...arguments);
      let {
        marketplaceError
      } = this.paramsFor('dashboard');

      if (marketplaceError) {
        controller.set('displayUnableTo', true);
      }
    }

    *restoreFromConfigString() {
      try {
        let employeeSettings = yield this.account.activeEmployee.get('employeeSettings');
        let configString = employeeSettings?.customDashboard;

        if (!configString) {
          configString = this.computeDefaultConfig();
        }

        let config = JSON.parse(configString);
        let widgetIds = [];
        let allWidgets = this.allWidgets();
        let widgetColumns = config.map(c => {
          return c.map(({
            id
          }) => {
            let widget = allWidgets.find(w => w.id === id);
            widgetIds.push(id);
            return widget;
          });
        });
        let availableWidgets = allWidgets.filter(w => !widgetIds.includes(w.id));
        return {
          availableWidgets,
          widgetColumns
        };
      } catch (e) {
        this.clToaster.error('Some error occured while reading dashboard configuration.');
      }
    }

    computeDefaultConfig() {
      let allWidgets = this.allWidgets();
      let columns = [[], []];
      let columnHeights = [0, 0];
      let margin = 30;
      let visibleWidgets = allWidgets.filter(w => w.can);

      for (let w of visibleWidgets) {
        let columnIndex = columnHeights.indexOf(Math.min(...columnHeights));
        columns[columnIndex].push({
          id: w.id
        });
        columnHeights[columnIndex] = columnHeights[columnIndex] + w.estimatedHeight + margin;
      }

      return JSON.stringify(columns);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "topbar", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dashboardAbility", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "restoreFromConfigString", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "restoreFromConfigString"), _class.prototype)), _class));
  _exports.default = DashboardRoute;
});