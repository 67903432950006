define("coachlogix/models/contract", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PROPERTY_GROUPS = _exports.ALLOW_PAYMENT_SCHEDULES = _exports.ALLOW_EXPENSES_OPTIONS = _exports.PAYMENT_INTERVAL_TYPES = _exports.INVOICE_SCHEDULE_TYPES = _exports.BILLING_APPROACHES = _exports.CONTRACT_TYPES = _exports.CONTRACT_STATUSES = void 0;
  const CONTRACT_STATUSES = ['active', 'adjustment_requested', 'archived', 'completed', 'draft', 'sent', 'declined'];
  _exports.CONTRACT_STATUSES = CONTRACT_STATUSES;
  const CONTRACT_TYPES = ['ongoing', 'term', 'term_with_cap'];
  _exports.CONTRACT_TYPES = CONTRACT_TYPES;
  const BILLING_APPROACHES = ['flat', 'hourly', 'fixed_contract_amount', 'retainer'];
  _exports.BILLING_APPROACHES = BILLING_APPROACHES;
  const INVOICE_SCHEDULE_TYPES = ['manual', 'payment_interval', 'payment_schedule'];
  _exports.INVOICE_SCHEDULE_TYPES = INVOICE_SCHEDULE_TYPES;
  const PAYMENT_INTERVAL_TYPES = ['weeks', 'months', 'hours_billed', 'sessions_completed', 'amount_owed'];
  _exports.PAYMENT_INTERVAL_TYPES = PAYMENT_INTERVAL_TYPES;
  const ALLOW_EXPENSES_OPTIONS = ['no', 'yes', 'approval_needed'];
  _exports.ALLOW_EXPENSES_OPTIONS = ALLOW_EXPENSES_OPTIONS;
  const ALLOW_PAYMENT_SCHEDULES = ['100% Upfront', '50% Upfront, 50% Middle', '50% Upfront, 50% End', '50% Middle, 50% End', '100% End']; // changes to each of these groups should be batched together in a single contract adjustment

  _exports.ALLOW_PAYMENT_SCHEDULES = ALLOW_PAYMENT_SCHEDULES;
  const PROPERTY_GROUPS = ['name', 'description', ['billingApproach', 'contractAmount'], ['invoiceScheduleType', 'paymentIntervalType', 'paymentIntervalValue', 'dayOfMonth', 'dayOfWeek', 'paymentSchedule'], ['start', 'end'], ['allowExpenses', 'expenseCap']];
  _exports.PROPERTY_GROUPS = PROPERTY_GROUPS;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    start: (0, _model.attr)('date'),
    end: (0, _model.attr)('date'),
    contractAmount: (0, _model.attr)('number'),
    amountToDate: (0, _model.attr)('number'),
    status: (0, _model.attr)('string', {
      defaultValue: 'draft'
    }),
    contractType: (0, _model.attr)('string', {
      defaultValue: 'ongoing'
    }),
    taxName1: (0, _model.attr)('string'),
    taxRate1: (0, _model.attr)('string'),
    taxName2: (0, _model.attr)('string'),
    taxRate2: (0, _model.attr)('string'),
    paymentTermsInfo: (0, _model.attr)('string'),
    invoiceDayOfMonth: (0, _model.attr)('string'),
    allowExpenses: (0, _model.attr)('string'),
    applyVat: (0, _model.attr)('string'),
    dayOfWeek: (0, _model.attr)('number'),
    dayOfMonth: (0, _model.attr)('number'),
    billingApproach: (0, _model.attr)('string'),
    paymentScheduleMetric: (0, _model.attr)('string'),
    // if schedule type = payment_interval then get interval_type and value
    // if schedule type = payment_schedule then get the payment_schedule_id associated
    invoiceScheduleType: (0, _model.attr)('string'),
    paymentIntervalType: (0, _model.attr)('string'),
    paymentIntervalValue: (0, _model.attr)('number'),
    invoiceAutomatically: (0, _model.attr)('boolean', {
      allowNull: true
    }),
    payViaStripe: (0, _model.attr)('boolean', {
      allowNull: true
    }),
    contractAmountCap: (0, _model.attr)('string'),
    hoursCap: (0, _model.attr)('string'),
    engagementsCap: (0, _model.attr)('string'),
    engagementSessionsCap: (0, _model.attr)('string'),
    expenseCap: (0, _model.attr)('number'),
    engagements: (0, _model.hasMany)('engagement', {
      inverse: null
    }),
    contractAdjustmentRequests: (0, _model.hasMany)('contract-adjustment-request', {
      inverse: 'contract'
    }),
    contractTemplate: (0, _model.belongsTo)('contract-template', {
      inverse: null
    }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    account: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    clientAccount: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    client: (0, _model.belongsTo)('client', {
      inverse: 'contracts'
    }),
    currency: (0, _model.belongsTo)('currency', {
      inverse: null
    }),
    paymentSchedule: (0, _model.belongsTo)('payment-schedule', {
      inverse: null
    }),
    employeeLevel: (0, _model.belongsTo)('employee-level', {
      inverse: null
    }),
    isDraft: Ember.computed.equal('status', 'draft'),
    isSent: Ember.computed.equal('status', 'sent'),
    isDeclined: Ember.computed.equal('status', 'declined'),
    isActive: Ember.computed.equal('status', 'active')
  });

  _exports.default = _default;
});