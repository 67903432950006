define("coachlogix/pods/settings/invoicing-payments/connect-stripe/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompanyConnectStripeCardComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.reads('account.activeAccount'), _dec(_class = (_class2 = (_temp = class CompanyConnectStripeCardComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "emberOauth2", _descriptor3, this);

      _initializerDefineProperty(this, "activeAccount", _descriptor4, this);
    }

    *connectStripe() {
      this.emberOauth2.setProperties({
        redirectUri: `${window.location.origin}/stripe-callback`
      });
      this.emberOauth2.setProvider('stripe');
      let popup = yield this.emberOauth2.authorize();
      let promise = new Promise(resolve => {
        let interval = setInterval(async () => {
          if (popup.closed) {
            clearInterval(interval);
            resolve();
          }
        }, 1000);
      });
      yield promise;
      yield this.account.activeAccount.reload();
    }

    *disconnectStripe() {
      try {
        let stripeConnectedAccount = yield this.activeAccount.get('stripeConnectedAccount');
        yield stripeConnectedAccount.destroyRecord();
        yield this.activeAccount.reload();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "emberOauth2", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "activeAccount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "connectStripe", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "connectStripe"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "disconnectStripe", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "disconnectStripe"), _class2.prototype)), _class2)) || _class);
  var _default = CompanyConnectStripeCardComponent;
  _exports.default = _default;
});