define("coachlogix/pods/invoice/payments/pay-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PayDialogComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('stripe-old'), _dec6 = (0, _emberConcurrencyDecorators.lastValue)('getCards'), _dec7 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember.computed('selectedCard', 'creatingNewCard', 'cardValid', 'name'), _dec11 = Ember.computed('amount', 'invoice.balance'), _dec12 = Ember.computed('selectedAmountOption.id', 'invoice.balance', 'amount'), _dec(_class = (_class2 = (_temp = class PayDialogComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "stripe", _descriptor4, this);

      _initializerDefineProperty(this, "cards", _descriptor5, this);
    }

    *getCards() {
      let cards = yield this.store.findAll('stripe-card', {
        reload: true
      });
      return cards.toArray();
    }

    toggleSelectedCard(card) {
      if (this.selectedCard === card) {
        this.set('selectedCard', null);
      } else {
        this.set('selectedCard', card);
      }

      this.set('creatingNewCard', false);
    }

    enterCreateCardMode() {
      this.set('selectedCard', null);
      this.set('creatingNewCard', true);
    }

    get isValid() {
      if (this.creatingNewCard) {
        return this.cardValid && this.name;
      } else {
        return this.selectedCard;
      }
    }

    *nextStep() {
      let stripeCard = this.selectedCard; // create stripe card, if user asked for that

      if (!stripeCard && this.creatingNewCard) {
        let stripe = this.stripe.stripe;
        let cardElement = this.cardElement;

        try {
          let {
            source
          } = yield stripe.createSource(cardElement, {
            owner: {
              name: this.name
              /* address: {
                city: this.get('city'),
                country: this.get('country.iso31662'),
                line1: this.get('address'),
                line2: this.get('address2'),
                postal_code: this.get('zip'),
                state: this.get('state')
              } */
              // TODO add more information about owner

            }
          });
          stripeCard = yield this.store.createRecord('stripe-card', {
            cardBrand: source.card.brand,
            expMonth: source.card.exp_month,
            expYear: source.card.exp_year,
            cardId: 'N/A',
            // workaround for now
            cardLastFour: source.card.last4,
            cardToken: source.id
          }).save();
        } catch (e) {
          this.clToaster.error(e.error.message);
          return;
        }
      }

      this.set('selectedCard', stripeCard);
      this.set('showCreatePaymentDialog', true);
    }

    init() {
      super.init(...arguments);
      this.amountOptions = [{
        id: 'full',
        label: `Remaining amount (${this.intl.formatNumber(this.invoice.balance, {
          currency: this.invoice.get('currency.code'),
          style: 'currency'
        })})`
      }, {
        id: 'other',
        label: 'Other'
      }];
      this.selectedAmountOption = this.amountOptions[0];
    }

    get amountErrors() {
      if (this.amount <= 0 || this.amount > this.invoice.balance) {
        return [`You can only pay up to the current invoice balance (${this.intl.formatNumber(this.invoice.balance, {
          currency: this.invoice.get('currency.code'),
          style: 'currency'
        })}).`];
      }

      return [];
    }

    get isPaymentValid() {
      if (this.selectedAmountOption.id === 'full') {
        return true;
      } else if (this.selectedAmountOption.id === 'other') {
        return this.amount > 0 && this.amount <= this.invoice.balance;
      }

      return false;
    }

    *createPayment() {
      let amount;

      if (this.selectedAmountOption.id === 'full') {
        amount = this.invoice.balance;
      } else if (this.selectedAmountOption.id === 'other') {
        amount = this.amount;
      }

      let payment = this.store.createRecord('payment', {
        paymentType: this.paymentType,
        paymentDate: this.paymentDate,
        amount,
        invoice: this.invoice
      });

      try {
        yield payment.save();
        yield this.invoice.reload();

        if (typeof this.onClose === 'function') {
          this.onClose();
        }

        this.clToaster.success(this.intl.t('invoice.payment_created_successfully'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *pay() {
      let amount;

      if (this.selectedAmountOption.id === 'full') {
        amount = this.invoice.balance;
      } else if (this.selectedAmountOption.id === 'other') {
        amount = this.amount;
      }

      let payment = this.store.createRecord('payment', {
        paymentType: this.paymentType,
        paymentDate: this.paymentDate,
        amount,
        invoice: this.invoice,
        stripeCard: this.selectedCard,
        status: 'completed'
      });

      try {
        yield payment.save();
        yield this.invoice.reload();

        if (typeof this.onClose === 'function') {
          this.onClose();
        }

        this.clToaster.success(this.intl.t('invoice.payment_created_successfully'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "stripe", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "cards", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "getCards", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "getCards"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleSelectedCard", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleSelectedCard"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "enterCreateCardMode", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "enterCreateCardMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isValid", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "isValid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "nextStep", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "nextStep"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "amountErrors", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "amountErrors"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isPaymentValid", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "isPaymentValid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createPayment", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createPayment"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "pay", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "pay"), _class2.prototype)), _class2)) || _class);
  var _default = PayDialogComponent;
  _exports.default = _default;
});