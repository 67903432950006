define("coachlogix/pods/components/v2/back-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5BLrReuY",
    "block": "{\"symbols\":[\"&default\",\"@default\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"routeTracker\",\"hasPreviousRoutes\"]],[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0,[\"goBack\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/back-link/template.hbs"
    }
  });

  _exports.default = _default;
});