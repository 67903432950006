define("coachlogix/abilities/personal-setting", ["exports", "ember-can", "coachlogix/abilities/user-roles-permissions-helper"], function (_exports, _emberCan, _userRolesPermissionsHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_userRolesPermissionsHelper.default, {
    account: Ember.inject.service(),
    canSyncCalendar: Ember.computed.reads('account.activeEmployee.isCoach'),
    canToggleCoach: Ember.computed('account.activeAccount.isIndependentCoach', function () {
      return this.hasAccountRole('account_global_admin', 'location_global_admin') && !this.account.activeAccount.isIndependentCoach;
    }),
    canToggleEngagementApproval: Ember.computed('account.activeEmployee.{id,isInternal,isCoach}', 'model.{id,isInternal,isCoach}', function () {
      let activeEmployee;

      if (this.model) {
        activeEmployee = this.model;
      } else {
        activeEmployee = this.account.activeEmployee;
      }

      return !!(activeEmployee.isInternal && activeEmployee.isCoach);
    })
  });

  _exports.default = _default;
});