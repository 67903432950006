define("coachlogix/models/payment", ["exports", "@ember-data/model", "ember-concurrency", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrency, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    status: (0, _model.attr)('string'),
    amount: (0, _model.attr)('number'),
    paymentDate: (0, _model.attr)('date'),
    createdBy: (0, _model.attr)('string'),
    invoice: (0, _model.belongsTo)('invoice', {
      inverse: 'payments'
    }),
    paymentType: (0, _model.belongsTo)('payment-type', {
      inverse: null
    }),
    stripeCard: (0, _model.belongsTo)('stripe-card', {
      inverse: null
    }),
    isPending: Ember.computed.equal('status', 'pending_approval'),
    isCompleted: Ember.computed.equal('status', 'completed'),
    isFailed: Ember.computed.equal('status', 'failed'),
    isDeclined: Ember.computed.equal('status', 'vendor_declined'),
    approve: (0, _emberConcurrency.task)(function* () {
      this.set('status', 'completed');
      yield this.save(); // reload invoice because this might change its status

      this.get('invoice').reload();
    }),
    decline: (0, _emberConcurrency.task)(function* () {
      this.set('status', 'vendor_declined');
      yield this.save(); // reload invoice because this might change its status

      this.get('invoice').reload();
    })
  });

  _exports.default = _default;
});