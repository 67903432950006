define("coachlogix/pods/form/form-submission/question/checkbox/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormQuestionCheckboxComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed.sort('answer.question.formQuestionOptions', 'sortOrder'), _dec4 = Ember.computed.mapBy('sortedOptions', 'value'), _dec5 = Ember.computed.setDiff('answer.answerJson', 'allOptionValues'), _dec6 = Ember.computed.setDiff('answer.answerJson', 'otherOptions'), _dec7 = Ember.computed('sortedOptions.@each.value', 'answer.answerJson.[]'), _dec8 = Ember.computed('otherOptions.[]'), _dec9 = Ember.computed('otherValue'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec(_class = (_class2 = (_temp = class FormQuestionCheckboxComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "uniqueId", Ember.guidFor(this));

      _defineProperty(this, "sortOrder", ['sortOrder:asc']);

      _initializerDefineProperty(this, "sortedOptions", _descriptor2, this);

      _initializerDefineProperty(this, "allOptionValues", _descriptor3, this);

      _initializerDefineProperty(this, "otherOptions", _descriptor4, this);

      _initializerDefineProperty(this, "optionValues", _descriptor5, this);
    }

    get auxOptions() {
      let answerJson = this.answer.answerJson || [];
      return this.sortedOptions.map(o => {
        return {
          option: o,
          enabled: answerJson.includes(o.value)
        };
      });
    }

    get otherValue() {
      if (this.otherOptions && this.otherOptions.length > 0) {
        return this.otherOptions.objectAt(0);
      } else {
        return '';
      }
    }

    get hasOtherValue() {
      return Ember.isPresent(this.otherValue);
    }

    setValue(option, value) {
      if (value) {
        this.answer.answerJson.addObject(option.value);
      } else {
        this.answer.answerJson.removeObject(option.value);
      }
    }

    setOtherValue(value) {
      if (Ember.isPresent(value)) {
        this.set('answer.answerJson', [...this.optionValues, value]);
      } else {
        this.set('answer.answerJson', this.optionValues);
      }
    }

    otherChanged(value) {
      if (value) {
        this.set('answer.answerJson', [...this.optionValues, this.intl.t('form.questions.other-answer')]);
      } else {
        this.set('answer.answerJson', this.optionValues);
      }

      Ember.run.scheduleOnce('afterRender', () => {
        document.getElementById(`input-${this.uniqueId}-other-input`).focus();
      });
    } // this action selects input text on focus


    selectAll(ev) {
      ev.target.select();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "sortedOptions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "allOptionValues", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "otherOptions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "optionValues", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "auxOptions", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "auxOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "otherValue", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "otherValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hasOtherValue", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "hasOtherValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setValue", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "setValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setOtherValue", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "setOtherValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "otherChanged", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "otherChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectAll", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "selectAll"), _class2.prototype)), _class2)) || _class);
  var _default = FormQuestionCheckboxComponent;
  _exports.default = _default;
});