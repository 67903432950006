define("coachlogix/pods/components/cl-chip-filters/place/component", ["exports", "@glimmer/component", "tracked-toolbox"], function (_exports, _component, _trackedToolbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ClChipFiltersPlaceComponent = (_dec = (0, _trackedToolbox.localCopy)('args.appliedFilter.value'), _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class ClChipFiltersPlaceComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "value", _descriptor, this);
    }

    setLocation(place) {
      if (!place) {
        this.value = null;
        this.handleApply();
      } else {
        for (let a of place.address_components) {
          if (a.types.includes('locality')) {
            this.value = a.long_name;
            this.handleApply();
            return;
          }
        }

        this.value = place.formatted_address;
        this.handleApply();
      }
    }

    handleApply() {
      if (Ember.isPresent(this.value)) {
        this.args.applyFilter?.({
          operation: 'like',
          value: this.value
        });
      } else {
        this.args.clearFilter?.();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setLocation", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleApply", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleApply"), _class.prototype)), _class));
  _exports.default = ClChipFiltersPlaceComponent;
});