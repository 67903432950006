define("coachlogix/pods/components/cl-chip-filters/range/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tKIDppg+",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex cl-flex-col cl-space-y-2 cl-p-3\"],[17,1],[12],[2,\"\\n\\n  \"],[8,\"cl-slider\",[],[[\"@min\",\"@max\",\"@step\",\"@minValue\",\"@maxValue\",\"@onMinChange\",\"@onMaxChange\"],[[32,0,[\"min\"]],[32,0,[\"max\"]],10,[32,0,[\"minValue\"]],[32,0,[\"maxValue\"]],[30,[36,2],[[30,[36,1],[[30,[36,0],[[32,0,[\"minValue\"]]],null]],null],[32,0,[\"handleApply\"]]],null],[30,[36,2],[[30,[36,1],[[30,[36,0],[[32,0,[\"maxValue\"]]],null]],null],[32,0,[\"handleApply\"]]],null]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-justify-center\"],[12],[2,\"\\n    \"],[8,\"cl-chip\",[[24,0,\"cl-bg-deep-blue cl-text-deep-blue-contrast cl-border-none\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,3],[[32,0,[\"minValue\"]]],[[\"code\"],[\"USD\"]]]],[2,\" - \"],[1,[30,[36,3],[[32,0,[\"maxValue\"]]],[[\"code\"],[\"USD\"]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"queue\",\"format-currency\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cl-chip-filters/range/template.hbs"
    }
  });

  _exports.default = _default;
});