define("coachlogix/helpers/unwrap-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unwrapProxy = unwrapProxy;
  _exports.isProxy = isProxy;
  _exports.default = void 0;

  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy(Ember.get(o, 'content')) : o;
  }

  function isProxy(o) {
    return !!(o && (o instanceof Ember.ObjectProxy || o instanceof Ember.ArrayProxy));
  }

  var _default = Ember.Helper.helper(function ([o]) {
    return unwrapProxy(o);
  });

  _exports.default = _default;
});