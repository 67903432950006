define("coachlogix/authenticators/auth0-silent-auth", ["exports", "ember-simple-auth-auth0/authenticators/auth0-silent-auth"], function (_exports, _auth0SilentAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _auth0SilentAuth.default;
    }
  });
});