define("coachlogix/pods/employees/employees-table/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmployeesTable = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.reads('account.activeAccount'), _dec(_class = (_class2 = (_temp = class EmployeesTable extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "activeAccount", _descriptor3, this);

      _defineProperty(this, "filters", {});
    }

    get canUploadCSV() {
      return !this.account.activeEmployee.isIndependent && !this.account.activeEmployee.isLocationManager;
    }

    *loadData({
      paginationData,
      sortData,
      filterData
    }) {
      yield (0, _emberConcurrency.timeout)(600);
      let params = {};

      if (sortData.length) {
        let sortName = sortData[0].prop;
        let sortPrefix = sortData[0].direction === 'desc' ? '-' : '';
        params.sort = `${sortPrefix}${sortName}`;
      }

      let filters = {};

      if (filterData.filter) {
        filters.search = filterData.filter;
      }

      params.filter = filters;
      params.include = ['user', 'company-location', 'department', 'sub-department', 'user.role-users.role', 'role-users', 'role-users.role', 'employee-level', 'user.profile'].join(',');
      params.page = {
        size: paginationData.pageSize,
        number: paginationData.pageNumber
      };
      let employees = yield this.store.query('employee', params);
      this.set('totalRows', employees.meta.page.total);
      this.set('filters', params.filter);
      return employees;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "activeAccount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "loadData", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "loadData"), _class2.prototype)), _class2)) || _class);
  var _default = EmployeesTable;
  _exports.default = _default;
});