define("coachlogix/models/user", ["exports", "@ember-data/model", "ember-data-storefront/mixins/loadable-model", "bcp-47", "ember-concurrency-decorators", "coachlogix/models/base-model"], function (_exports, _model, _loadableModel, _bcp, _emberConcurrencyDecorators, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let User = (_dec = Ember.inject.service('account'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('boolean'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string', {
    defaultValue: 'en-us'
  }), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('date'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.belongsTo)('profile', {
    inverse: 'owner',
    async: false
  }), _dec19 = (0, _model.attr)('boolean'), _dec20 = (0, _model.hasMany)('role-user', {
    async: true,
    inverse: 'user'
  }), _dec21 = (0, _model.hasMany)('resource-group', {
    async: true,
    inverse: 'user'
  }), _dec22 = (0, _model.hasMany)('resource', {
    async: true,
    inverse: 'owner'
  }), _dec23 = (0, _model.hasMany)('employee', {
    inverse: null
  }), _dec24 = (0, _model.attr)('boolean'), _dec25 = (0, _model.belongsTo)('cronofy-connected-account', {
    async: true,
    inverse: 'user'
  }), _dec26 = (0, _model.belongsTo)('availability-preference', {
    async: true,
    inverse: 'user'
  }), _dec27 = (0, _model.belongsTo)('businessUnit', {
    async: true,
    inverse: null
  }), _dec28 = (0, _model.belongsTo)('timezone', {
    inverse: null
  }), _dec29 = (0, _model.hasMany)('account', {
    inverse: null
  }), _dec30 = (0, _model.belongsTo)('account', {
    inverse: null
  }), _dec31 = (0, _model.belongsTo)('employee', {
    inverse: null
  }), _dec32 = (0, _model.hasMany)('package', {
    inverse: 'owner',
    async: false
  }), _dec33 = Ember.computed.equal('status', 'active'), _dec34 = Ember.computed.equal('status', 'external'), (_class = (_temp = class User extends _baseModel.default.extend(_loadableModel.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "accountService", _descriptor, this);

      _initializerDefineProperty(this, "ownerId", _descriptor2, this);

      _initializerDefineProperty(this, "lastLogin", _descriptor3, this);

      _initializerDefineProperty(this, "autoUpdateTimezone", _descriptor4, this);

      _initializerDefineProperty(this, "avatar", _descriptor5, this);

      _initializerDefineProperty(this, "email", _descriptor6, this);

      _initializerDefineProperty(this, "firstName", _descriptor7, this);

      _initializerDefineProperty(this, "language", _descriptor8, this);

      _initializerDefineProperty(this, "lastName", _descriptor9, this);

      _initializerDefineProperty(this, "dateOfBirth", _descriptor10, this);

      _initializerDefineProperty(this, "location", _descriptor11, this);

      _initializerDefineProperty(this, "phone", _descriptor12, this);

      _initializerDefineProperty(this, "status", _descriptor13, this);

      _initializerDefineProperty(this, "tagline", _descriptor14, this);

      _initializerDefineProperty(this, "twilioIdentity", _descriptor15, this);

      _initializerDefineProperty(this, "emailNotifications", _descriptor16, this);

      _initializerDefineProperty(this, "currentAccountId", _descriptor17, this);

      _initializerDefineProperty(this, "profile", _descriptor18, this);

      _initializerDefineProperty(this, "isCoach", _descriptor19, this);

      _initializerDefineProperty(this, "roleUsers", _descriptor20, this);

      _initializerDefineProperty(this, "resourceGroups", _descriptor21, this);

      _initializerDefineProperty(this, "resources", _descriptor22, this);

      _initializerDefineProperty(this, "employees", _descriptor23, this);

      _initializerDefineProperty(this, "isCronofyConnected", _descriptor24, this);

      _initializerDefineProperty(this, "cronofyConnectedAccount", _descriptor25, this);

      _initializerDefineProperty(this, "availabilityPreferences", _descriptor26, this);

      _initializerDefineProperty(this, "businessUnit", _descriptor27, this);

      _initializerDefineProperty(this, "timezone", _descriptor28, this);

      _initializerDefineProperty(this, "accounts", _descriptor29, this);

      _initializerDefineProperty(this, "account", _descriptor30, this);

      _initializerDefineProperty(this, "employee", _descriptor31, this);

      _initializerDefineProperty(this, "packages", _descriptor32, this);

      _initializerDefineProperty(this, "isActive", _descriptor33, this);

      _initializerDefineProperty(this, "isExternal", _descriptor34, this);
    }

    get name() {
      let first = this.firstName;
      let last = this.lastName;

      if (Ember.isEmpty(first) && Ember.isEmpty(last)) {
        return this.email;
      } else {
        return [first, last].join(' ');
      }
    } // the `language` field is on bcp47 format,
    // but there are cases where we need iso639 code.


    get iso639Language() {
      let {
        language
      } = _bcp.default.parse(this.language); // default to 'en' if for some wild reason we can't parse this bcp47


      language = language || 'en';
      return language;
    }

    get isOwn() {
      return this.id === this.accountService.activeUser.id;
    }
    /* Methods */


    *updateAvatar({
      filesUploaded = []
    }) {
      try {
        this.set('avatar', filesUploaded[0].url);
        yield this.save();
        this.clToaster.success('Avatar updated.');
      } catch (e) {
        this.rollbackAttributes();
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "accountService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ownerId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lastLogin", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "autoUpdateTimezone", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "avatar", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dateOfBirth", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "tagline", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "twilioIdentity", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "emailNotifications", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "currentAccountId", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "profile", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isCoach", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "roleUsers", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "resourceGroups", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "resources", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "employees", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "isCronofyConnected", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "cronofyConnectedAccount", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "availabilityPreferences", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "businessUnit", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "timezone", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "accounts", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "employee", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "packages", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "isExternal", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateAvatar", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateAvatar"), _class.prototype)), _class));
  _exports.default = User;
});