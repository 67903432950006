define("coachlogix/models/program-item", ["exports", "@ember-data/model", "coachlogix/models/base-model", "coachlogix/models/taskable"], function (_exports, _model, _baseModel, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_taskable.default, {
    position: (0, _model.attr)('number', {
      defaultValue: '1'
    }),
    visibleToCoach: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    visibleToCoachee: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    visibilityLevel: (0, _model.attr)('string'),
    clonedFrom: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      inverse: null
    }),
    programable: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      inverse: null
    }),
    itemable: (0, _model.belongsTo)('base-model', {
      async: true,
      polymorphic: true,
      inverse: null,
      cascadeDelete: true
    }),
    item: Ember.computed.alias('itemable'),
    program: Ember.computed.alias('programable'),
    isSession: Ember.computed.equal('itemable.modelName', 'engagement-session'),
    isTask: Ember.computed.equal('itemable.modelName', 'task'),
    isInterview: Ember.computed.equal('itemable.modelName', 'coaching-request'),
    // we need the following computed properties because
    // dependent keys containing @each only work one level deep.
    // they're also auxiliary properties to support the engagement/program controller
    isPublished: Ember.computed.reads('itemable.isPublished'),
    isScheduled: Ember.computed.reads('itemable.isScheduled'),
    isPublishedOrScheduled: Ember.computed.or('isPublished', 'isScheduled'),
    canBeMovedOnFrom: Ember.computed.reads('itemable.canBeMovedOnFrom'),
    publishedOrScheduledDate: Ember.computed.or('itemable.scheduledDate', 'itemable.publishedDate'),
    completedDate: Ember.computed.reads('itemable.completedDate'),
    isValidItem: Ember.computed.or('isSession', 'isTask', 'isInterview')
  });

  _exports.default = _default;
});