define("coachlogix/pods/components/v2/engagement/engagement-task/detail-view/actions/notifications-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TaskNotificationsDialogComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec9 = Ember._action, _dec10 = Ember._action, _dec(_class = (_class2 = (_temp = class TaskNotificationsDialogComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "account", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "clToaster", _descriptor5, this);

      _initializerDefineProperty(this, "intl", _descriptor6, this);

      _defineProperty(this, "currentDialog", 'notification');
    }

    get notificationType() {
      let taskType = this.task.taskType;

      if (taskType === 'form') {
        return 'engagement_form_received';
      } else if (taskType === 'file') {
        return 'engagement_program_file_received';
      } else if (taskType === 'todo' || taskType === 'milestone') {
        return 'engagement_task_sent';
      } else if (taskType === 'link') {
        return 'engagement_program_link_sent';
      }

      return null;
    }

    get hasEmptyAssignees() {
      if (this.programTemplate) {
        return !this.task.templateDefaultCoach && !this.task.templateDefaultCoachee;
      } else {
        return this.task.get('assignees.length') === 0;
      }
    }

    init() {
      super.init(...arguments);
      let customNotifications = this.task.get('customNotifications');
      let notification = customNotifications.findBy('typeKey', this.notificationType);

      if (!notification) {
        notification = this.store.createRecord('custom-notification');
      }

      this.subject = notification.subject;
      this.message = notification.message;
      let reminder;

      if (notification) {
        reminder = notification.get('notificationReminders.firstObject');
      }

      this.notification = notification;
      this.hasNotification = !!notification;
      this.notificationTimeValue = notification?.timeValue || 1;
      this.notificationTimeUnit = notification?.timeUnit || 'hours';
      this.sendNotificationAutomatically = notification?.sendAutomatically || false;
      this.reminder = reminder;
      this.hasReminder = !!reminder;
      this.timeValue = reminder?.timeValue || 1;
      this.timeUnit = reminder?.timeUnit || 'hours';
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);

      if (this.notification && this.notification.isNew) {
        this.notification.destroyRecord();
      }
    }

    *fetchPreview() {
      let {
        html,
        subject
      } = yield this.ajax.request('/helpers/mail-preview', {
        data: {
          type_key: this.task.notificationType,
          notifiable_type: 'assignments',
          recipient_id: this.account.activeUser.id,
          template_values: {
            task_title: this.task.title || this.intl.t('engagement-task.default-title'),
            resource_url: this.task.get('resource.url'),
            resource_description: this.task.get('resource.description')
          }
        }
      });

      if (Ember.isEmpty(this.subject)) {
        this.set('subject', subject);
      }

      this.set('html', html);

      if (this.currentDialog === 'preview') {
        this.parsePreview();
      }
    }

    parsePreview() {
      let el = document.createRange().createContextualFragment(this.html);
      let previewEl = el.getElementById('email-preview-content');
      previewEl.style.whiteSpace = 'pre-line';
      previewEl.textContent = this.message;
      this.set('emailPreviewEl', el);
      this.set('previewEl', previewEl);
    }

    togglePreview() {
      let previewEnabled = this.currentDialog === 'preview';

      if (!previewEnabled && this.html) {
        this.parsePreview();
        this.set('currentDialog', 'preview');
      } else if (!this.html) {
        this.set('previewEl', null);
        this.set('currentDialog', 'preview');
      } else {
        this.set('previewEl', null);
        this.set('currentDialog', 'notification');
      }
    }

    updateMessage(value) {
      this.set('message', value);

      if (this.previewEl) {
        this.previewEl.textContent = value;
      }
    }

    *save() {
      let notification = this.notification; // test if custom notification is enabled or not

      if (this.sendNotificationAutomatically || this.hasNotification || this.hasReminder) {
        // test if we need to create a new one or just update an existing one
        if (!notification) {
          notification = this.store.createRecord('custom-notification');
        }

        notification.setProperties({
          customNotifiable: this.task,
          typeKey: this.notificationType,
          message: this.message,
          subject: this.subject,
          timeUnit: this.notificationTimeUnit,
          timeValue: this.notificationTimeValue,
          sendAutomatically: this.sendNotificationAutomatically,
          scheduledRelation: 'next'
        });
        yield notification.save();
        this.clToaster.success(this.intl.t('ui.custom-notifications-dialog.notification-updated'));
        this.task.get('customNotifications').pushObject(notification);
        let reminder = this.reminder; // test if reminder is enabled or not

        if (this.hasReminder) {
          // test if we need to create a new one or just update an existing one
          if (!reminder) {
            reminder = this.store.createRecord('notification-reminder');
          }

          reminder.setProperties({
            customNotification: notification,
            timeUnit: this.timeUnit,
            timeValue: this.timeValue,
            timePeriod: 'after'
          });
          yield reminder.save();
        } else {
          // if it isn't, test if there's an existing one we need to delete
          if (reminder) {
            yield reminder.destroyRecord();
          }
        }
      } else {
        // if it isn't, test if there's an existing one we need to delete
        if (notification) {
          // any reminders will be cascade deleted
          this.task.get('customNotifications').removeObject(notification);
          yield notification.destroyRecord();
        }
      }

      if (this.onClose) {
        this.onClose();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "fetchPreview", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "fetchPreview"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "togglePreview", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "togglePreview"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateMessage", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "updateMessage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2)) || _class);
  var _default = TaskNotificationsDialogComponent;
  _exports.default = _default;
});