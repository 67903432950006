define("coachlogix/pods/clients/filter/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let ClientFilterComponent = (_dec = (0, _component.tagName)(''), _dec(_class = (_temp = class ClientFilterComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "filter", 'all');
    }

  }, _temp)) || _class);
  var _default = ClientFilterComponent;
  _exports.default = _default;
});