define("coachlogix/pods/form/form-editor/form-element/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JqU5w+TU",
    "block": "{\"symbols\":[\"@question\",\"&attrs\"],\"statements\":[[8,\"cl-input\",[[16,\"placeholder\",[30,[36,0],[\"form.questions.text.answer-placeholder\"],null]],[24,\"rows\",\"1\"],[17,2]],[[\"@containerClass\",\"@line\",\"@textarea\",\"@value\",\"@editable\",\"@icon\"],[\"cl-px-5 cl-font-body cl-text-lg\",true,true,[32,1,[\"userAnswer\"]],false,\"text-fields\"]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/form/form-editor/form-element/text/template.hbs"
    }
  });

  _exports.default = _default;
});