define("coachlogix/pods/reports/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s/YMc6GU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-flex cl-items-center cl-justify-center cl-h-32\"],[12],[2,\"\\n  \"],[8,\"cl-empty-state\",[],[[\"@icon\",\"@size\",\"@text\"],[\"insert-chart\",88,\"Please select a report.\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/reports/index/template.hbs"
    }
  });

  _exports.default = _default;
});