define("coachlogix/pods/components/v2/resources/resources-view/resources-grid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isEmpty: Ember.computed('resourceGroups.length', 'resources.length', 'searchText', function () {
      let hasResourceGroups = this.get('resourceGroups.length');
      let hasResources = this.get('resources.length');
      let hasSearchText = this.get('searchText');
      return !hasResourceGroups && !hasResources && !hasSearchText;
    }),
    searchFilter: Ember.computed('searchText', function () {
      let typeRegex = /type:([^\s]+)/g;
      let result = typeRegex.exec(this.searchText);

      if (result) {
        let [, match] = result;
        return match;
      } else {
        return '';
      }
    }),
    nameFilter: Ember.computed('searchText', function () {
      let typeRegex = /type:([^\s]+)/g;
      let nameFilter = this.searchText.replace(typeRegex, '').trim();
      return nameFilter;
    }),
    actions: {
      onFolderDblClick() {
        if (typeof this.onFolderDblClick === 'function') {
          this.onFolderDblClick(...arguments);
        }
      },

      onFolderClick(item, e) {
        e.stopPropagation();
        this.set('selected', item);

        if (typeof this.onFolderClick === 'function') {
          this.onFolderClick(...arguments);
        }

        if (typeof this.onSelect === 'function') {
          this.onSelect(item);
        }
      },

      onFileDblClick() {
        if (typeof this.onFileDblClick === 'function') {
          this.onFileDblClick(...arguments);
        }
      },

      onFileClick(item, e) {
        e.stopPropagation();
        this.set('selected', item);

        if (typeof this.onFileClick === 'function') {
          this.onFileClick(...arguments);
        }

        if (typeof this.onSelect === 'function') {
          this.onSelect(item);
        }
      },

      deselect() {
        this.set('selected', null);

        if (typeof this.onSelect === 'function') {
          this.onSelect(null);
        }
      }

    }
  });

  _exports.default = _default;
});