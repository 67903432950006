define("coachlogix/pods/components/cl-slot-picker/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ClSlotPicker = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = (0, _emberConcurrencyDecorators.restartableTask)({
    on: 'init'
  }), _dec8 = Ember._action, _dec(_class = (_class2 = (_temp = class ClSlotPicker extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "moment", _descriptor2, this);

      _initializerDefineProperty(this, "account", _descriptor3, this);

      _defineProperty(this, "center", new Date());

      _defineProperty(this, "minDate", new Date());

      _defineProperty(this, "day", new Date());

      _defineProperty(this, "selectedSlot", null);

      _defineProperty(this, "duration", 60);
    }

    onDayChange(date) {
      this.set('day', date);
      this.resetManualSchedule();
      this.fetchSlots.perform();
    }

    handleSlotClick(slot) {
      if (this.selectedSlot === slot) {
        this.set('selectedSlot', null);
      } else {
        this.set('selectedSlot', slot);
        this.set('manualScheduleTime', '');
      }

      this.onSlotSelected?.(this.selectedSlot);
    }

    *fetchSlots() {
      let day = _moment.default.tz({
        year: this.day.getFullYear(),
        month: this.day.getMonth(),
        day: this.day.getDate()
      }, this.moment.timeZone);

      let start = day.clone().startOf('day');
      let end = day.clone().endOf('day');
      let now = this.moment.moment(); // cronofy doesn't let us ask for availability in the past
      // so if the given start date is in the past, we send the current time

      start = start.isBefore(now) ? now.clone() : start;
      let availabilityPreferences = yield this.user.get('availabilityPreferences');

      if (availabilityPreferences?.withinHours) {
        let afterWithinHours = now.clone().add({
          hours: availabilityPreferences.withinHours
        }); // the start of the following day could be before
        // the withinHour period, in which case the new start needs
        // to become the withinHours

        start = start.isBefore(afterWithinHours) ? afterWithinHours : start; // adding these hours might mean we've moved to the following day
        // (imagine adding 4 hours at 21 PM)

        if (start.isAfter(end)) {
          // abort if start is after end since there aren't
          // any slots possible
          return [];
        }
      }

      start = start.set({
        millisecond: 0
      });
      end = end.set({
        millisecond: 0
      });
      start = start.toISOString();
      end = end.toISOString();
      let cAccount = yield this.user.get('cronofyConnectedAccount');
      let sub = cAccount.cronofyId;
      let duration = this.duration;
      let user_id = yield this.user.get('id');
      let response = yield this.ajax.request('helpers/slots', {
        data: {
          start,
          end,
          required_duration: duration,
          subs: [sub],
          coach_user_id: user_id
        }
      });
      return response.data.available_slots.map(slot => {
        return {
          start: new Date(slot.start),
          end: new Date(slot.end)
        };
      });
    }

    onManualScheduleChange(value) {
      this.set('manualScheduleTime', value);
      let time = this.moment.moment(value, 'HH:mm');
      let date = this.moment.moment(this.day);
      date.hours(time.hours());
      date.minutes(time.minutes());
      let slot = {
        start: date.toDate()
      };
      this.onSlotSelected?.(slot);
    }

    resetManualSchedule() {
      this.set('manualScheduleTime', '');
      this.onSlotSelected?.(null);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "moment", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onDayChange", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "onDayChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleSlotClick", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSlotClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fetchSlots", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "fetchSlots"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onManualScheduleChange", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "onManualScheduleChange"), _class2.prototype)), _class2)) || _class);
  var _default = ClSlotPicker;
  _exports.default = _default;
});