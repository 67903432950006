define("coachlogix/pods/components/cronofy/calendar-sync/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2VxD7ht7",
    "block": "{\"symbols\":[\"token\",\"linkToken\",\"@singleCalendar\"],\"statements\":[[8,\"cronofy/cronofy-element\",[],[[\"@subs\"],[[32,0,[\"account\",\"activeUser\",\"cronofyConnectedAccount\",\"cronofyId\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[30,[36,0],[[32,3],\"remove-add-button\"],null]]]],[4,[38,1],[[32,0,[\"setupElement\"]],[32,1],[32,2]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cronofy/calendar-sync/template.hbs"
    }
  });

  _exports.default = _default;
});