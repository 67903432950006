define("coachlogix/models/base-program", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    programItems: (0, _model.hasMany)('program-item', {
      inverse: 'program'
    })
  });

  _exports.default = _default;
});