define("coachlogix/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "account-setup": {
      "account-step": {
        "account-type-warning": "This sign up is for businesses and independent practices only. If you are an internal employee or an invited party do not proceed. Please sign up using the invitation link sent by your inviting party.",
        "coach-description": "For independent coaches running a private practice",
        "coach-label": "Coach",
        "company-description": "For managing multiple coaches",
        "company-label": "Business",
        "question": "Are you signing up for a Coach or a Business account?",
        "title": "Welcome to"
      },
      "buy-coaching": "We contract external coaches",
      "coach-description": "Individual coach that offers learning and development services.",
      "coach-label": "Professional Coach",
      "coachlogix": "Coaching.com",
      "company-name-label": "Company Name",
      "company-step": {
        "individual-company-question": "Last step! What is the name of your individual company?",
        "org-question": "Last step! What is the name of your organization?",
        "title": "Tell us more"
      },
      "company-type-question": "Which best describes your company?",
      "contract-coaches": "Do you contract external coaches?",
      "email-label": "Email",
      "enable-features-contract-coaches": "Enable features to contract external coaches and coaching companies, receive invoices and make payments, and track coaching spend.",
      "enable-features-internal-coaching": "Enable features to manage coaching with internal coaches within your organization.",
      "enable-features-sell-coaching": "Enable features to connect with clients, create invoices and receive payments, and track coaching revenue.",
      "enterprise-description": "Company that runs internal learning and development programs.",
      "enterprise-label": "Enterprise Company",
      "first-name-label": "First name",
      "greeting": "Hi",
      "internal-coaching": "Do you employ internal coaches?",
      "last-name-label": "Last name",
      "legal": "By clicking “CONTINUE” you agree to the Coaching.com Terms of Service and Privacy Policy.",
      "options-title": "Help us tailor your experience. Select any that apply:",
      "provider-description": "Company with multiple coaches that offer learning and development services.",
      "provider-label": "Coaching Provider",
      "sell-coaching": "Do you sell coaching services?",
      "social-merge-step": {
        "continue-signup": "Continue Signup",
        "create-account": "If this is your first time here and would like to setup your account, please click the <strong>Continue Signup</strong> button below.",
        "merge-email": "If you're already a Coaching.com user and previously used a different email to login, please enter it below.",
        "title": "We did not find a user that matches the information from your social profile."
      },
      "step-one-label": "Step 1 of 2",
      "step-two-label": "Step 2 of 2",
      "user-info-step": {
        "title": "We’re excited you’re here! First tell us a little about yourself."
      },
      "verify-email-step": {
        "check-junk": "If you do not see the email in your inbox, please make sure to check your junk/spam email folders. ",
        "continue-merge": "Continue",
        "title": "We sent you an email with a verification code that you will receive shortly. Please enter the code below."
      }
    },
    "application": {
      "context-switcher": {
        "confirm-button": "Switch",
        "error": "Something went wrong while changing tenant",
        "prompt": "This notification requires you to switch to a different tenant? Do you want to proceed?",
        "title": "Switch tenant"
      }
    },
    "cl-calendar": {
      "today": "Today"
    },
    "cl-color-picker": {
      "cancel": "Cancel",
      "clear": "Clear",
      "save": "Save"
    },
    "cl-comment-submit": {
      "comment": "Comment",
      "placeholder": "Write a comment",
      "submitting": "Submitting..."
    },
    "cl-comments": {
      "cancel": "Cancel",
      "collapse": "Collapse",
      "expand": "Expand",
      "placeholder": "Edit your comment",
      "submit": "Submit",
      "submitting": "Submitting..."
    },
    "cl-confirmation-dialog": {
      "cancel": "Cancel",
      "default-title": "Are you sure?",
      "ok": "Ok"
    },
    "cl-date-picker": {
      "clear": "Clear"
    },
    "cl-dropzone": {
      "drop-files": "Drop your files here",
      "or": "or",
      "select": "Select file"
    },
    "cl-infinite-pagination": {
      "empty": "No items.",
      "loading": "Loading..."
    },
    "cl-places-autocomplete": {
      "loading-message": "Loading locations...",
      "search-message": "Type to search locations"
    },
    "cl-selection-dialog": {
      "cancel": "Cancel",
      "default-title-multiple": "Select one or more options",
      "default-title-single": "Select an option",
      "empty": "No items.",
      "loading": "Loading...",
      "select": "Select"
    },
    "cl-session-scheduler-dialog": {
      "attendees": "Attendees",
      "cancel-button": "Cancel",
      "confirm-button": "Confirm schedule",
      "details": "Details",
      "hours": "hours",
      "minutes": "minutes",
      "schedule": "Schedule"
    },
    "cl-sidenav": {
      "close": "Close",
      "open": "Open"
    },
    "cl-slot-picker": {
      "force-schedule-helper": "This will override any busy times on synced calendars and Standard Availability settings.",
      "force-schedule-label": "Choose time",
      "no-slots": "No available slots."
    },
    "cl-table": {
      "back": "Back",
      "columns": "Columns",
      "columns-dialog-title": "Customize Columns",
      "create": "Create new",
      "export": "Export",
      "export-csv": "CSV",
      "export-excel": "Excel",
      "export-pdf": "PDF",
      "next": "Next",
      "page-info": "({pageStart}-{pageEnd} of {totalRows})",
      "reset-columns": "Reset columns",
      "rows-per-page": "Rows per page",
      "search": "Search",
      "update": "Update"
    },
    "cl-truncate": {
      "read-more": "Read more",
      "show-less": "Show less"
    },
    "client": {
      "account-contact": {
        "no-admin-match": "The email doesn't match any contact email for this client."
      },
      "add-admin-dialog": {
        "admin-added": "Admin was added.",
        "admin-already-added": "This admin was already added."
      },
      "client-action": {
        "approve-coach": "Approve coach",
        "approving-coach-title": "About this coach",
        "coach-name": "{coachType}'s name.",
        "enter-the-coach-name": "Enter the {coachType}'s name.",
        "independent-coach": "Independent coach",
        "independent-coach-description": "An independent coach, contracted directly.",
        "internal-coach": "Internal coach",
        "internal-coach-description": "An employee of your company who provides coaching services.",
        "send-invitation-description": "You can add this person and invite later.",
        "send-invitation-optional": "Send invitation?"
      },
      "client-contracts": {
        "active": "Active",
        "coaching-relationship": "{coach} coaching {coachee}",
        "contracts": "Client contracts",
        "no-contracts": "No contracts found.",
        "pending": "Pending",
        "vendor-contracts": "Vendor contracts"
      },
      "client-locations": {
        "assign-location": "Assign location",
        "description": {
          "client": "Restrict location admin access to this client's data by assigning locations based on your organizational structure.",
          "vendor": "Restrict location admin access to this vendor's data by assigning locations based on your organizational structure."
        },
        "edit-locations": "Edit locations",
        "locations-updated": "Client locations updated.",
        "no-locations-added": "No locations added yet",
        "no-locations-helper": {
          "client": "The client data is visible to all locations admins if no locations are selected.",
          "vendor": "The vendor data is visible to all locations admins if no locations are selected."
        },
        "title": "Manage visibility"
      }
    },
    "clients": {
      "about": "About",
      "add-client": "Add client",
      "client-type": "Client type",
      "coaches": {
        "status": {
          "active": "Approved",
          "pending": "Awaiting approval"
        }
      },
      "companies": {
        "add-dialog-title": "Add client company",
        "description": "Client companies that you work with.",
        "empty-state": "No client companies found.",
        "name": "Company",
        "title": "Client Companies"
      },
      "create-workflow": {
        "account-name-label": "Company name",
        "email-label": "Contact email",
        "first-name-label": "Contact first name",
        "last-name-label": "Contact last name",
        "title": "Create client"
      },
      "description": "List of clients that you work with.",
      "direct": {
        "add-dialog-title": "Add direct coachee",
        "description": "Coachees that are not sponsored by a client company.",
        "empty-state": "No clients found.",
        "name": "Individual client",
        "title": "Direct Coachees"
      },
      "glance": "At a Glance",
      "individual": "Individual",
      "loading": "Loading clients...",
      "no-clients": "No clients",
      "no-coachees": "No coachees",
      "no-coaches": "No coaches",
      "search-placeholder": "Search clients",
      "sessions": {
        "no-past": "No past sessions.",
        "no-upcoming": "No upcoming sessions",
        "past": "Past",
        "upcoming": "Upcoming",
        "upcoming-sessions": "Upcoming sessions"
      },
      "stats": {
        "active-engagements": "Active engagements",
        "completed-engagements": "Completed engagements",
        "total-engagements": "Total engagements"
      },
      "table-header": {
        "account-status": "Account Status",
        "active-engagements": "Active Engagements",
        "approval-status": "Approval Status",
        "client-type": "Client Type",
        "completed-engagements": "Completed Engagements",
        "connection-status": "Connection Status",
        "contact-email": "Contact Email",
        "contact-phone": "Contact Phone",
        "date-added": "Date Added",
        "draft-engagements": "Draft Engagements",
        "email": "Email",
        "name": "Name",
        "phone": "Phone",
        "primary-contact": "Primary Contact",
        "status": "Status",
        "user-status": "User Status"
      },
      "title": "Clients",
      "type": {
        "all": "All",
        "company": "Company",
        "direct_coachee": "Individual"
      },
      "upload-clients": "Upload clients"
    },
    "coach": {
      "about": "About",
      "city-label": "City",
      "contact-label": "Contact info",
      "disconnect": "Disconnect",
      "education-degree-saved": "Education degree saved",
      "email-label": "Email",
      "engagement-log": "<strong>{coach}</strong> started an <a href=\"/engagements/{engagementId}\">engagement</a> with <a href=\"/employees/{coacheeId}\">{coachee}</a>.",
      "engagements": "Engagements",
      "engagements-tab": "Engagements",
      "internal-label": "Internal Coach",
      "invite": "Invite",
      "invoices": "Invoices",
      "invoices-tab": "Invoices",
      "new-engagement-button": "New engagement",
      "no-coaching-experience-added": "You haven't added coaching experience yet.",
      "no-external-profiles": "No external profiles",
      "overview-label": "Overview",
      "phone-label": "Phone",
      "profiles": "Profiles",
      "specialities-label": "Specialities",
      "summary": "Summary",
      "timeline-label": "Timeline",
      "years": "years"
    },
    "coach-pools": {
      "active-engagements": "Active engagements: {engagements}",
      "add-a-description": "Add a description",
      "add-coach-pool": {
        "description": "Add coach pools to the matching process.",
        "title": "Add coach pool"
      },
      "add-coachees": "Add coachees",
      "add-coaches": "Add coaches",
      "add-coaches-to-matching-process": "Add coaches to matching process",
      "add-individual-coaches": {
        "description": "Select specific coaches to add to the matching process.",
        "title": "Add individual coaches"
      },
      "add-new-coach": "Add coach",
      "add-new-coachee": "Add coachee",
      "add-new-share": "Add new share",
      "assign-a-coach": {
        "description": "Skip the matching and assign a coach directly from your directory.",
        "title": "Assign a coach"
      },
      "coach-pools": "Coach pools",
      "coach-removed": "Coach removed from pool.",
      "coach-removed-from-matching": "Coach removed from matching.",
      "coach-selection": "Coach selection",
      "coachee-removed": "Coachee removed from pool.",
      "coachees": "Coachees",
      "coachees-added": "Coachees added successfully.",
      "coaches": "Coaches",
      "coaches-added": "Coaches added successfully.",
      "confirm-coach-interview": "Click the ‘Request’ button below to initiate a request for a <strong>{title}</strong> with this coach. If you have preferred days or times please let the coach know using the text box below.",
      "create-pool": "Create pool",
      "delete-pool-warning": "Delete coach pool permanently. This affects ongoing coach matchings that involve this pool. You cannot undo this action.",
      "description": "Description",
      "error-saving-the-pool": "Error saving the pool.",
      "error-starting-matching-process": "Error starting the matching process.",
      "individuals": "Individuals",
      "members-count": "{members} members",
      "name": "Name",
      "no-coachees-found": "No coachees found",
      "no-coaches-found": "No coaches found",
      "no-coaches-selected": "No coaches selected.",
      "no-pools": "No pools were created yet.",
      "no-pools-found": "No pools found",
      "pool-deleted": "Pool deleted.",
      "pool-name": "Pool name",
      "pool-saved": "Pool saved.",
      "pool-updated": "Pool updated.",
      "pools": "Pools",
      "remove-coachees": "Remove coachees",
      "remove-coachees-dialog-warning": "This action will remove all the selected coachees from the pool.<strong>Are you sure you want to continue?</strong>",
      "request": "Request",
      "search-for-coach": "Search for coach",
      "search-for-coachee": "Search for coachee",
      "search-for-coaches": "Search for coaches",
      "search-for-contract": "Search for contract",
      "search-for-pools": "Search for coach pools",
      "search-for-shares": "Search for shares",
      "search-for-template": "Search for engagement template",
      "select-all": "Select all",
      "select-coachees": "Select coachees",
      "select-coaches": "Select coaches",
      "send-request": "Send request",
      "settings": "Settings",
      "share-matching-status": {
        "engagement-started-on": "Engagement started on {date}",
        "matching-completed-on": "Matching completed on {date}",
        "matching-ended-on": "Matching ended on {date}",
        "matching-started-on": "Matching started on {date}",
        "not-yet-started": "Matching not yet started"
      },
      "shares": "Shares",
      "shares-removed": "Shares removed",
      "start-matching-process": "Start matching process",
      "start-matching-process-dialog-warning": "This will start the matching process for <strong>{selectedCoachees}</strong> coachees.<br/>Are you sure you want to proceed?",
      "untitled-coach-pool": "Untitled coach pool"
    },
    "coaches": {
      "active-coaches": "Active Coaches",
      "add": "add",
      "add-coach": "Add coach",
      "add-coach-or-vendor": "Add coach or vendor",
      "add-coach-pool": "Add Coach Pool",
      "add-to-pool": "Add to Pool",
      "admin-approval": "An admin will be notified and asked for approval.",
      "all-coaches": "All Coaches",
      "approve-selection": "Approve selection",
      "approved-coaches": "Approved Coaches",
      "assign-coach": "Assign coach",
      "automatic": "The engagement will start after the selection.",
      "clear": "clear",
      "client-disconnect": "Disconnected.",
      "coach-pool-created": "Coach Pool Created Successfully",
      "coach-pool-settings": "Coach Pool Settings",
      "coach-pools": "Coach Pools",
      "coaches": "Coaches",
      "coaches-list": "List of Coaches",
      "coaching-vendors": "Coaching Vendors",
      "confirm-coach": "Are you sure you want to select this coach? This action cannot be undone",
      "create-new-pool": "Create new pool",
      "create-pool": "Create Pool",
      "create-pool-button": "Create Pool",
      "create-workflow": {
        "account-name-label": "Company name",
        "coach-type-step": {
          "prompt": "Which type of coach are you adding?",
          "title": "Add coach"
        },
        "create-pool-button": "Create Pool",
        "email-label": "Contact email",
        "employee-invite-button": "Invite new coach",
        "employee-invite-prompt": "Fill out this form to invite a new employee.",
        "employee-invite-title": "Adding someone new?",
        "employee-search-no-results": "We didn't find anyone matching \"{search}\"",
        "employee-search-placeholder": "Enter name or email",
        "external-coach-description": "A coach that is contracted to provide coaching services.",
        "external-coach-label": "Independent Coach",
        "first-name-label": "Contact first name",
        "internal-coach-description": "A coach that is an employee of your company.",
        "internal-coach-label": "Internal Coach",
        "last-name-label": "Contact last name"
      },
      "decline-selection": "Decline selection",
      "decline-selection-warning": "Are you sure you want to decline this coaching request?",
      "deselect-all": "Deselect All",
      "edit-pool": "Edit Coach Pool",
      "edit-pool-settings": "Settings",
      "employee-removed": "Employee removed.",
      "error-removing-employee": "Problem removing employee.",
      "error-updating-request": "Error updating the request.",
      "existing-pool": "Add to Coach Pool",
      "filter-labels": {
        "coaching-type": "Type of Coach",
        "credentials": "Credentials",
        "experience": "Experience",
        "gender": "Gender",
        "interview-status": "Interview status",
        "interview-statuses": {
          "interview-approved": "Approved",
          "interview-completed": "Completed",
          "interview-scheduled": "Scheduled",
          "sent": "Requested"
        },
        "leader-levels": "Target Audience",
        "location": "Location",
        "modalities": "Modalities",
        "specialities": "Specialties"
      },
      "filters": "Filters",
      "individuals": "Individuals",
      "internal": "Internal",
      "invite-coaches": "Invite Coaches",
      "loading": "Loading coaches...",
      "members": "{value} {value, plural, one {Coach} other {Coaches}} ",
      "new-pool": "New Coach Pool",
      "no-coach": "No coaches.",
      "no-coach-pools": "No coach pools found.",
      "no-coaches": "No coaches found.",
      "pending-approval": "Pending Approval",
      "phases": {
        "closing": "Closing Phase",
        "initial": "Initial Phase",
        "mid": "Mid Phase"
      },
      "pools": "Pools",
      "remove-coach": "Remove coach",
      "request-interview": "Request interview",
      "request-interview-confirmation": "Are you sure you want to request an interview with {coachName}?",
      "request-interview-message": "Hi {coachName},\n{coacheeName} has requested a matching interview with you. Once you coordinate a time, schedule it here.",
      "request-interview-message-html": "Hi {coachName},<br><br>\n<strong>{coacheeName}</strong> has requested a matching interview with you. Once you coordinate a time, schedule it here.",
      "request-interview-message-html-coachee": "You requested a matching interview with <strong>{coachName}</strong>.<br>\nOnce <strong>{coachName}</strong> schedules a time, it will appear here.",
      "request-interview-message-html-other": "<strong>{coacheeName}</strong> has requested a matching interview with <strong>{coachName}</strong>.\nOnce <strong>{coachName}</strong> schedules a time, it will appear here.",
      "reschedule-interview": "Reschedule interview",
      "resend-request": "Resend request",
      "schedule-interview": "Schedule interview",
      "search-by-name-or-email": "Search by name or email",
      "search-placeholder": "Search coaches",
      "select": "Select",
      "select-all": "Select All",
      "select-coach": "Select coach",
      "show-messages": "Show messages",
      "showing-page": "Showing {start} to {end} of {total} entries",
      "showing-page-coachee": "({start}-{end} of {total})",
      "start-conversation": "Send message",
      "start-engagement": "Start engagement",
      "table-header": {
        "name": "Name",
        "status": "Status"
      },
      "tabs": {
        "internal": "Internal coaches",
        "vendors": "External vendors"
      },
      "title": "Coaches",
      "update-pool": "Update pool",
      "vendor-search-placeholder": "Search vendors",
      "view-profile": "View profile"
    },
    "coachlogix-terms": {
      "account-status": {
        "internal_record": "Internal record"
      },
      "account-type": "Company Type",
      "account-types": {
        "business": "Business",
        "enterprise": "Enterprise",
        "independent-coach": "Independent coach"
      },
      "client": "client",
      "coach": "Coach",
      "coach-interview": "Coach Interview",
      "coachee": "Coachee",
      "coaching": "coaching",
      "coachlogix": "Coaching.com",
      "contract": "contract",
      "engagement-session": "Session",
      "engagements": "Engagements",
      "independent-coach": "Independent coach",
      "internal-coach": "Internal coach",
      "internal-employee": "Internal employee",
      "session-date": "Session date",
      "statuses": {
        "account": {
          "active": "Active",
          "inactive": "Inactive",
          "invited": "Invited",
          "pending": "Invited",
          "suspended": "Active",
          "trial": "Active",
          "unclaimed": "Inactive"
        },
        "client": {
          "active": "Active",
          "approval_needed": "Approval pending",
          "approved": "Connected",
          "client_approval_needed": "Client approval needed",
          "coachees": {
            "total": "Total coachees"
          },
          "coaches": {
            "active": "Active",
            "inactive": "Inactive",
            "invited": "Invited",
            "total": "Total coaches"
          },
          "internal_record": "Internal record",
          "invited": "Invite sent",
          "not_applicable": "not applicable",
          "pending": "Invite sent",
          "request_sent": "Request sent"
        },
        "coaching-request": {
          "coach_approved": "Approved",
          "coach_declined": "Unavailable",
          "coach_pending": "Selected",
          "coach_selected": "Selected",
          "interview_approved": "Interview approved",
          "interview_canceled": "Interview canceled",
          "interview_completed": "Interview completed",
          "interview_declined": "Interview declined",
          "interview_scheduled": "Interview scheduled",
          "interview_times_requested": "Interview times requested",
          "interview_times_sent": "Interview times sent",
          "no_request": "Not yet requested"
        },
        "contact": {
          "approved": "Approved",
          "client_approval_needed": "Client approval needed",
          "declined": "Declined",
          "internal_record": "Internal record",
          "invited": "Invite sent",
          "managed_by_vendor": "Managed by vendor",
          "not_applicable": "Not applicable",
          "pending": "Request sent",
          "request_sent": "Request sent",
          "vendor_approval_needed": "Vendor approval needed",
          "your_approval_needed": "Your approval needed"
        },
        "contract": {
          "active": "Active",
          "declined": "Declined",
          "draft": "Draft",
          "sent": "Sent"
        },
        "employee": {
          "active": "Active",
          "connected": "Active",
          "disconnected": "Inactive",
          "internal_record": "Inactive",
          "invited": "Invited",
          "owned": "Stakeholder",
          "pending": "Inactive",
          "suspended": "Active",
          "trial": "Inactive",
          "unclaimed": "Inactive"
        },
        "engagement": {
          "active": "Active",
          "archived": "Deleted",
          "coach_selection": "Matching",
          "completed": "Completed",
          "draft": "Draft",
          "draft_coach_selection": "Matching draft",
          "inactive": "Inactive"
        },
        "engagement-session": {
          "completed": "Completed",
          "draft": "Draft",
          "late_cancelled": "Late cancelled",
          "scheduled": "Scheduled"
        },
        "invoice": {
          "client_marked_paid": "Client marked paid",
          "client_marked_partial_paid": "Client marked partial paid",
          "draft": "Draft",
          "paid": "Paid",
          "partial_paid": "Partially paid",
          "past_due": "Late",
          "sent": "Due"
        },
        "matching-process": {
          "completed": "Matching completed",
          "started": "Matching started",
          "unstarted": "Matching unstarted"
        },
        "payment": {
          "completed": "Approved",
          "failed": "Failed",
          "pending_approval": "Awaiting approval",
          "vendor_declined": "Declined"
        },
        "user": {
          "offline": "Offline",
          "online": "Online"
        }
      },
      "statuses-helpers": {
        "coaching-request": {
          "coach_approved": "This was the selected coach for this engagement!",
          "coach_declined": "This coach is unavailable",
          "coach_pending": "Awaiting coach selection approval",
          "coach_selected": "Awaiting coach selection approval",
          "interview_approved": "Interview may now be scheduled",
          "interview_canceled": "Your scheduled interview was canceled",
          "interview_completed": "Interview was completed. You may select this coach.",
          "interview_completed_admin": "Interview was completed on {completedDate}",
          "interview_declined": "Interview request was declined",
          "interview_scheduled": "Interview was scheduled for {scheduledDate}",
          "sent": "Awaiting interview approval"
        }
      },
      "vendor": "vendor"
    },
    "companies": {
      "create-dialog": {
        "cancel": "Cancel",
        "next": "Next",
        "step1": {
          "label": "Are you adding an internal or external connection?"
        },
        "title": "Create"
      },
      "delete-tooltip": "Delete",
      "loading": "Loading companies...",
      "search": "Search Companies",
      "table": {
        "headers": {
          "actions": "Actions",
          "address": "Address",
          "name": "Name"
        },
        "status": {
          "connected": "Connected",
          "internalRecord": "Internal record",
          "invited": "Invited"
        }
      },
      "title": "Companies"
    },
    "company": {
      "coaches": "Coaches",
      "index": {
        "about": "About",
        "details": "Details",
        "no-about": "No about us information was provided by the client yet.",
        "no-details": "No details were provided by the client yet."
      },
      "people": {
        "table": {
          "headers": {
            "actions": "Actions",
            "address": "Address",
            "name": "Name"
          }
        }
      },
      "subtitle": "Company",
      "tabs": {
        "coachees": "Coachees",
        "coaches": "Coaches",
        "details": "Details",
        "engagements": "Engagements",
        "invoices": "Invoices",
        "people": "People",
        "profile": "Profile",
        "summary": "Summary"
      },
      "vendors": "Vendors"
    },
    "company-resources": {
      "account": "Company resources",
      "add": "Add resource",
      "add-from": "Add from resources",
      "admin": "Admin resources",
      "copied": "Resource copied to \"My resources\"",
      "create-competency-library": "Create theme library",
      "create-document": "Create document",
      "create-folder": "Create folder",
      "create-form": "Create form",
      "create-link": "Create link",
      "create-program-template": "Create program template",
      "delete-prompt": "Are you sure you want to delete this resource?",
      "delete-prompt-warning": "By deleting this resource you are also deleting it from any engagement where this resource currently exists.",
      "delete-tooltip": "Delete",
      "file-created-toast": "File was successfully created",
      "form-label": "Form",
      "kb-label": "KB",
      "loading": "Loading resources...",
      "me-label": "me",
      "my": "My resources",
      "shared": "Shared with me",
      "table": {
        "headers": {
          "actions": "Actions",
          "date-added": "Date added",
          "fileSize": "File size",
          "fileType": "File type",
          "lastModified": "Last modified",
          "name": "Name",
          "owner": "Owner"
        }
      },
      "title": "Resources",
      "upload-file": "Upload file"
    },
    "competency-library": {
      "add-competency": "Add theme",
      "add-description": "Add a description to this theme library",
      "cant-edit-name": "Name can't be edited because it is a Coaching.com global competency.",
      "competencies-title": "Competencies",
      "competency-removed": "Theme removed.",
      "competency-updated": "Themes updated.",
      "create-new": "Create new theme",
      "description": "Description",
      "edit-competency": "Edit theme",
      "edit-competency-tooltip": "Edit theme",
      "name": "Name",
      "no-competencies": "No themes added yet.",
      "no-competencies-found": "No themes found.",
      "remove-competency-tooltip": "Remove theme",
      "reset-description": "Reset to default description",
      "search-competency": "Search for theme",
      "theme-added-to-library": "Theme added to library.",
      "theme-library-updated": "Theme library updated."
    },
    "contact": {
      "dropdown-actions": {
        "disconnect": "Disconnect",
        "disconnect-confirm": "Are you sure you want to remove this {contactType} from <strong>{accountName}</strong>?",
        "stop-sharing": "Stop sharing",
        "stop-sharing-confirm": "Are you sure you want to stop sharing this {contactType} with <strong>{accountName}</strong>?"
      },
      "info": "Contact information",
      "phone": "Phone",
      "phone-abbr": "P:"
    },
    "contracts": {
      "allow-expenses-options": {
        "approval_needed": "Yes, but approval is needed",
        "no": "No",
        "yes": "Yes"
      },
      "billing-approaches": {
        "fixed_contract_amount": "Fixed amount",
        "flat": "Per session",
        "hourly": "Per hour",
        "retainer": "Retainer"
      },
      "billing-approaches-amounts": {
        "fixed_contract_amount": "Total cost",
        "flat": "Amount per session",
        "hourly": "Amount per hour",
        "retainer": "Amount invoiced each billable period"
      },
      "client": {
        "add-contract": "Add contract"
      },
      "contract-duration-interval": {
        "engagement_sessions": "Engagement sessions",
        "hours_billed": "Hours billed",
        "months": "Monthly",
        "sessions_completed": "Sessions",
        "weeks": "Weekly"
      },
      "contract-duration-type": {
        "days": "Days",
        "months": "Months",
        "sessions_completed": "Sessions completed",
        "weeks": "Weeks"
      },
      "create-dialog": {
        "billing-metric-choice": "Which billing metric would you like to use?",
        "contract": {
          "name": "What is this contract called?",
          "name-hint": "Give this contract a name.",
          "title": "Create contract"
        },
        "contract-duration": "Would you like this contract to terminate after a specific length of time?",
        "contract-template": {
          "name": "What is this contract template called?",
          "name-hint": "Give this contract template a name.",
          "title": "Create contract template"
        },
        "create-new": "Create new contract",
        "no-templates": "No contract templates were found.",
        "payment-schedule": "Would you like invoices generated automatically?",
        "select-template": "Select contract template",
        "summary": {
          "billing-metric": "The used billing metric will be <strong>{billingMetric}</strong>.",
          "contract-duration": "Contract will terminate after <strong>{contractDuration}</strong>.",
          "name": "This contract will be called <strong>{name}</strong>.",
          "payment-schedule": "Payments will be scheduled using <strong>\"{paymentSchedule}\"</strong>."
        }
      },
      "dialog": {
        "amount-placeholder": "Enter amount",
        "billing-metric": "Billing metric",
        "billing-metric-placeholder": "Please select a billing metric",
        "generate-automatic-invoices": "Generate invoices automatically",
        "info-separator": {
          "contract": "Contract Info",
          "contract-template": "Contract Template Info"
        },
        "payment-schedule": "Payment Schedule",
        "read-only-title": "Contract",
        "steps": "Step {n} of {total}",
        "success": {
          "contract": "Contract saved successfully.",
          "contract-template": "Contract template saved successfully."
        }
      },
      "duration-type": {
        "each-month": "Same day each month",
        "hours_billed": "After {value, plural, =1 {one hour billed} other {# hours billed}}",
        "months": "Every {value, plural, =1 {month} other {# months}}",
        "sessions_completed": "After {value, plural, =1 {one session} other {# sessions}}",
        "weeks": "Every {value, plural, =1 {week} other {# weeks}}"
      },
      "editor": {
        "after": "after",
        "approve-success": "Contract approved successfully.",
        "auto-gen-invoice": "Generate invoices automatically",
        "auto-invoicing-helper": "If not selected, you will be notified when it's time to create the invoice based on your payment schedule.",
        "auto-invoicing-prompt": "Send invoices automatically based on the above payment schedule",
        "billing-metric": "Billing metric",
        "contract": {
          "title": "Edit contract"
        },
        "contract-duration": "I would like this contract to terminate after a specific length of time",
        "contract-template": {
          "title": "Edit contract template"
        },
        "day_of_every": "day of every",
        "decline-success": "Contract declined successfully.",
        "delete-confirmation": "This contract is currently in use. Deleting it will remove it from all engagements. Are you sure you want to proceed?",
        "delete-success": "Contract deleted successfully.",
        "generate-invoices": "Generate invoices automatically",
        "hours": "hour(s)",
        "months": "month(s)",
        "name": "Name",
        "no-edit": "You don't have edit permissions for this contract.",
        "no-edit-allowed": "You don't have edit permissions for this contract.",
        "of_every": "of every",
        "on": "on",
        "on_the": "on the",
        "pay-via-stripe": "Collect payment via Stripe",
        "payment-schedule": "Payment schedule",
        "payment-schedule-input": {
          "after": "after",
          "day-every": "day every",
          "every": "every",
          "hours": "hour(s)",
          "months": "month(s)",
          "on": "on",
          "on-the": "on the",
          "sessions": "session(s)",
          "weeks": "week(s)"
        },
        "save-success": "Contract saved successfully.",
        "sent-success": "Contract sent successfully.",
        "sessions": "session(s)",
        "terms": "Terms",
        "weeks": "week(s)"
      },
      "ordinal-numbers": "{number, selectordinal, one {#st} two {#nd} few {#rd} other {#th} =-1 {Last} }",
      "summary": {
        "amount": "Amount",
        "interval": "Interval",
        "invoice_automatically": "Invoices will be sent automatically.",
        "metric": "Metric",
        "name": "Contract name",
        "payment": "A credit card payment link will be included."
      }
    },
    "ctas": {
      "add": "Add",
      "add-admin": "Add Admin",
      "add-editor": "Add Editor",
      "add-manager": "Add Manager",
      "add-poc": "Add POC",
      "add-resource": "Add Resource",
      "add-vendor-contact": "Add Vendor Contact",
      "add-viewer": "Add Viewer",
      "assign-coach": "Assign Coach",
      "assign-coachee": "Assign Coachee",
      "confirm-delete-coachee": "Are you sure you want to delete this coachee?",
      "create": "Create",
      "delete": "Delete",
      "request-coach": {
        "add": "Add coach",
        "request": "Request coach"
      },
      "request-coachee": {
        "add": "Add coachee",
        "request": "Request coachee"
      },
      "share-coach": {
        "active": "Share Coach",
        "inactive": "Add coach",
        "internal_record": "Add Coach",
        "invited": "Share Coach",
        "pending": "Add Coach"
      },
      "share-coachee": {
        "active": "Share Coachee",
        "inactive": "Add coachee",
        "internal_record": "Add Coachee",
        "invited": "Share Coachee",
        "pending": "Add Coachee"
      },
      "submit": "Submit"
    },
    "custom-notifications": {
      "label": "{\n  count, plural,\n  =0 { Manage notifications }\n  other { Manage notifications }\n}\n"
    },
    "dashboard": {
      "activity-feed": {
        "count": "{numActivities, plural, =0 {no activities} =1 {one activity} other {# activities}}",
        "no-data-available": "No data available",
        "no-more-records": "No more records",
        "title": "Activity feed"
      },
      "billable-activity": {
        "activity": "Activity",
        "assignments": "Assignment",
        "coached-by": "{coachee} coached by {coach}",
        "create-invoice": "Create invoice",
        "create-new-invoice": "Create new invoice",
        "description": "Create and send invoices for engagements with billable activity.",
        "empty-state": "No billable activity.",
        "engagement-sessions": "Session",
        "errors": {
          "billable-invoiced": "This session has been already invoiced."
        },
        "expenses": "Expenses",
        "invoices": "Invoices",
        "review-invoice": "Review and send.",
        "title": "Billable activity",
        "view-invoice": "View invoice"
      },
      "compentencies": {
        "add-skills": "Add Skills",
        "find-coach": "Find a coach that fits you",
        "first-engagement": "Create your first engagement",
        "first-goal": "Learn about, and set your first goal",
        "plan-guide": "Plan Guide",
        "replay": "Watch Replay",
        "right-arrow": "›",
        "select-plan": "Select the plan that's right for you",
        "sign-up": "Sign up for our free live webinar",
        "skillsets": "Tell us what skillsets you' re looking to improve",
        "start-sso": "Get started on your SSO implementation",
        "tutorial": "Read Tutorial"
      },
      "interviews": {
        "actions": {
          "cancel": "Cancel interview",
          "complete": "Complete interview",
          "reschedule": "Reschedule interview",
          "schedule": "Schedule interview",
          "see-suggested-times": "See suggested times",
          "suggest-times": "Suggest times"
        },
        "completed-interview": "Completed interview with {coacheeName}",
        "empty-state": "No interview requests",
        "interview-scheduled": "Interview with {coacheeName}",
        "interview-with": "New interview request from {coacheeName}",
        "no-scheduled": "{coacheeName} from {accountName} would like to interview you.",
        "requests": "Requests",
        "scheduled": "Scheduled",
        "statuses": {
          "cancelled": "Cancelled",
          "completed": "Completed",
          "requested": "Requested",
          "scheduled": "Scheduled"
        },
        "times-dialog": {
          "coachee-comment": "Coachee added a comment for your consideration",
          "declined-suggestions": "Declined suggestions",
          "declined-suggestions-helper": "There are <strong>{num}</strong> previously declined time suggestions",
          "declined-time-error": "This time was previously suggested. Please select a different one.",
          "interview-date": "Interview date",
          "select-date": "Select a date",
          "select-time": "Select a time",
          "suggestion-number": "Suggestion #{index}",
          "title": "Suggest Interview Time"
        },
        "title": "Matching Interviews"
      },
      "invoices": {
        "coach": "Coach: {coachName}",
        "currency-sign": "$",
        "description": "Invoices you've received requiring payment or status update.",
        "empty-state": "No recent invoices.",
        "general-title": "Invoices",
        "no-invoices": "No invoices.",
        "title": "Recent Invoices"
      },
      "sessions": {
        "no-past": "No past sessions.",
        "no-upcoming": "No upcoming sessions.",
        "past": "Past",
        "upcoming": "Upcoming"
      },
      "sessions-module": {
        "empty-state": "No sessions found",
        "interview-with": "Interview with {coacheeName}",
        "no-location": "No meeting location was set.",
        "title": "Scheduled sessions"
      },
      "stats": "Stats",
      "tasks": null,
      "tasks-module": {
        "all": "All",
        "all-open": "All Open",
        "dismiss": "Dismiss",
        "feedback-request": "Your feedback is requested.",
        "feedback-request-completed-on": "Feedback submitted on {date}.",
        "feedback-request-from": "Feedback requested from {username}.",
        "file-completed-on": "File task completed on {date}.",
        "file-review-request": "You have a file to review.",
        "file-review-request-from": "{username} has a file to review.",
        "fill-out-form": "Fill out the form",
        "form-completed-on": "Form submitted on {date}.",
        "form-fill-request": "You've been sent a form to fill out.",
        "form-fill-request-from": "{username} has a form to fill out.",
        "mark-completed": "Mark completed",
        "mine": "Mine",
        "open-file": "Open file",
        "resend-form": "Resend the form",
        "showing": "Showing:",
        "task-completed-on": "Task completed on {date}.",
        "task-request": "You have a task to complete.",
        "task-request-from": "{username} has a task to complete.",
        "title": "Tasks"
      },
      "title": "Dashboard",
      "welcome": "Welcome,",
      "widget": {
        "accounting-widget": "Accounting",
        "coaches": "Coaches",
        "engagements": "Engagements",
        "feedback": "Stakeholder Feedback",
        "impact": "Top 10 Themes",
        "invoices": "Invoices",
        "progress": "Progress",
        "sessions": "Sessions"
      }
    },
    "domain": {
      "account": "",
      "account-billing-info": "",
      "account-customization": "",
      "account-payment-gateway": "",
      "account-stripe-card": "",
      "activity": "",
      "assignment": "",
      "association": "",
      "base-model": "",
      "base-program": "",
      "base-resource": "",
      "billing-approach": "Billing Approach",
      "billing-settings": "",
      "business-unit": "",
      "chat": "Chat",
      "client": "",
      "coach": "",
      "coaching-experience": "",
      "coaching-inquiry": "",
      "coaching-inquiry-message": "",
      "company": "",
      "contract": "",
      "country": "",
      "credit": "",
      "cronofy-connected-account": "",
      "education-degree": "Education",
      "email-customization": "",
      "employee": "",
      "engagement": "Engagement",
      "engagement-coaching-request": "Interview",
      "engagement-comment": "",
      "engagement-milestone": "",
      "engagement-note": "Note",
      "engagement-resource": "",
      "engagement-review": "",
      "engagement-session": "Session",
      "engagement-session-participant": "",
      "engagement-task": "Task",
      "file": "",
      "file-resource": "",
      "form": "",
      "form-question": "",
      "gateway-type": "",
      "goal": "Goal",
      "goal-approach": "",
      "interaction": "",
      "invoice": "",
      "invoice-design": "",
      "invoice-item": "",
      "library-resource": "",
      "location": "",
      "me": "",
      "message": "",
      "message-history": "",
      "message-recipient": "",
      "milestone": "",
      "note": "",
      "notification": "",
      "organization": "",
      "payment": "",
      "payment-gateway": "",
      "payment-library": "",
      "payment-method": "",
      "payment-status": "",
      "payment-term": "",
      "pending-action": "",
      "permission": "",
      "permission-role": "",
      "plan": "",
      "pool": "",
      "profile": "",
      "program-item": "",
      "program-resource": "",
      "program-template": "Program template",
      "programmable": "",
      "reminder": "",
      "report": "",
      "resource": "",
      "response": "",
      "role": "",
      "role-user": "",
      "roles": {
        "account-employee": "Employee",
        "account-owner": "Account Owner",
        "coachlogix-billing": "Billing Manager"
      },
      "specialty": "",
      "status": "",
      "stripe-card": "",
      "stripe-connected-account": "",
      "subscription": "",
      "survey-resource": "",
      "task": "",
      "task-assignment": "",
      "task-grouping": "",
      "task-resource": "",
      "task-review": "",
      "task-signoff": "",
      "task-type": "",
      "theme-library": "Theme library",
      "user": "",
      "user-affiliation": "Affiliation",
      "user-assessment": "",
      "user-certification": "",
      "user-context": "",
      "user-language": "User language",
      "user-publication": "",
      "user-work": "",
      "vendor": "",
      "work-experience": "Work experience"
    },
    "employee": {
      "account-roles": "Account Roles",
      "at": "at",
      "employe-actions": {
        "coach-eng-capacity": "Coach Engagement Capacity",
        "remove-confirmation": "Are you sure you want to remove this employee?",
        "set-capacity": "Set Engagement Capacity"
      },
      "global-admin": "Global Admin",
      "global-admin-permissions": "Has permission to manage all engagements, coaches, clients, employees, account financials and company settings.",
      "id": "Employee ID",
      "inactivate-dialog": {
        "employee-inactivated": "Employee successfully inactivated.",
        "employee-inactivation-failed": "Failed to inactivate employee.",
        "inactivate-confirmation": "Are you sure you want to inactivate this employee?",
        "remove-engagements": "Remove all data, including engagements as well."
      },
      "info": "Employee information",
      "level": "Employee level",
      "location-admin": "Location Admin (requires at least 1 location)",
      "location-admin-permissions": "Has permission to manage location-specific engagements, coaches, clients, employees, account financials and company settings. Can access location-specific data and reports.",
      "name": "Name",
      "not-provided": "Not provided",
      "provides-coaching": "Provides Coaching",
      "send": "Send a message",
      "tabs": {
        "marketplace": "Marketplace"
      },
      "title": "Employee title"
    },
    "employees": {
      "add-employee": "Add employee",
      "loading": "Loading employees...",
      "select-employee-dialog": {
        "add-admin": "Add a new admin",
        "add-employee": "Add a new employee",
        "assign-later": "Assign later",
        "no-admins-found": "No admins were found.",
        "no-employees-found": "No employees were found.",
        "search": "Search...",
        "select-admin": "Select admin",
        "select-employee": "Select Employee"
      },
      "upload-employees": "Upload employees"
    },
    "engagement": {
      "active-alert": "This engagement is currently active.",
      "add-attendee": "Add attendee",
      "add-by-email": "Can't find someone? Add by email",
      "admin-tab": "Admin",
      "archive-prompt": "Are you sure you want to archive this engagement?",
      "capacity-coaches-warning": "Coaches that have reached their engagement capacity will not be shown to coachee.",
      "coach-assign-error": "An error has occured while assigning a coach.",
      "coach-assign-success": "Coach was assigned successfully.",
      "coach-capacity-met-warning": "This coach has reached their engagement capacity. Are you sure you want to set this engagement to active?",
      "coach-capacity-met-warning-title": "Coach Capacity Override",
      "coach-hasnt-started-yet": "Your coach hasn't started your engagement yet.",
      "coach-label": "Coach",
      "coachee-label": "Coachee",
      "coaches-added-to-matching": "Coaches added to matching.",
      "complete-engagement": "Complete engagement",
      "create-engagement": "Create engagement",
      "create-links": "Create links and assign them for review.",
      "create-tasks": "Create and assign tasks.",
      "dashboard-tab": "Overview",
      "delete-prompt": "Are you sure you want to delete this engagement?",
      "download-summary": "Download summary",
      "draft-alert": {
        "button": "Start engagement",
        "description": "This engagement is still a draft. Coaching will begin as soon as it is activated.",
        "title": "Start this engagement"
      },
      "empty-matching-not-started": "Can't start matching without at least one option.",
      "engagement-settings-updated": "Engagement settings updated.",
      "find-people": "Find people by name or email",
      "goals": {
        "add": "Add goal",
        "add-theme": "Add themes",
        "add-theme-warn": "Please add a theme library before adding themes to this goal.",
        "cancel": "Cancel",
        "coach-details": "Coach details",
        "coach-privacy": "This goal is only visible to you and your coach.",
        "coachee-privacy": "This goal is only visible to you and your coachee.",
        "comment-count": "{count} of {total}",
        "complete-goal": "Complete goal",
        "completed-on": "Completed on {date}",
        "confidential-goal-description": "This goal is confidential. Only the coach and coachee can see this goal.",
        "default-description": "Add a description",
        "default-title": "Untitled goal",
        "delete-goal": "Delete goal",
        "due-date": "Due date",
        "due-date-date": "Due date: {date}",
        "due-date-tooltip": "Target due date",
        "edit-themes": "Edit themes",
        "fallback-title": "Untitled goals",
        "feedback": "Feedback",
        "feedback-table": {
          "change": "Change",
          "coach": "Coach",
          "coachee": "Coachee",
          "final": "Final",
          "initial": "Initial",
          "latest": "Latest",
          "no-change": "No change",
          "percentage-decrease": "{percentage}% decrease",
          "percentage-increase": "{percentage}% increase"
        },
        "goal-description": "Goal description",
        "goal-name": "Goal name",
        "goal-progress": "Milestone completion",
        "manage-visibility": "Manage Visibility",
        "milestones": "Milestones",
        "must-add-stakeholders": "You must add stakeholders before you can request feedback.",
        "no-due-date": "No due date set",
        "personal-privacy": "This goal is only visible to you.",
        "private": "Private",
        "progress": "Progress",
        "provide-feedback": "Provide feedback",
        "request-feedback": "Request feedback",
        "save": "Save",
        "set-due-date": "Set due date",
        "stakeholders": "Stakeholders",
        "themes": "Themes",
        "title": "Create goal",
        "uncomplete-goal": "Mark as uncompleted",
        "visibility-confidential": "Confidential",
        "visibility-confidential-description": "Only the coach and coachee can see this goal.",
        "visibility-everyone": "Everyone",
        "visibility-everyone-description": "All admins and engagement participants can see this goal.",
        "visibility-warning": "If you set this goal as confidential, you will lose access to it, since you're not the coach or coachee of this engagement. Are you sure you want to proceed?"
      },
      "goals-tab": "Goals",
      "inactive-alert": {
        "button": "Resume engagement",
        "description": "This engagement is inactive. You can either restart this engagement or create a new one.",
        "title": "This engagement is inactive"
      },
      "invite-coachee-dialog": {
        "description": "Video conferences must be joined from the Coaching.com mobile app. <strong>{coachee}</strong> has not yet activated their Coaching.com account or downloaded the mobile app. Would you like to send <strong>{coachee}</strong> an invitation to create an account and download the mobile app?",
        "send-invite-confirmation": "Send invite now?",
        "send-invite-warning": "The coachee will not be able to access the chosen meeting location. We recommend changing the default meeting location or sending the invite.",
        "title": "Invite coachee"
      },
      "loading": "Loading engagement...",
      "matching-process": {
        "account-name": "Account",
        "coach-location": "Coach's location",
        "coach-name": "Coach's name",
        "email": "Email",
        "group-channel-topic": "Engagement with {coachName} and {coacheeName}",
        "inactive-coachees-warning": "Inactive and internal record coachees will be invited if a matching process gets started.",
        "inactive-coaches-warning": "Inactive and internal record coaches will be invited but won't appear as options for the coachee until they are active.",
        "interview-card": {
          "request-times": "Request new times",
          "suggested-times": "Suggested times",
          "waiting": "Waiting for suggested schedule times from <strong>{coachName}</strong>"
        },
        "request-dialog": {
          "comment-label": "Comment",
          "comment-placeholder": "Provide details on your preferred schedule (optional)"
        },
        "request-status": "Request status",
        "start-engagement-confirmation": "The coach matching settings for this engagement will start this engagement automatically once a coach has been assigned. Do you want to proceed?",
        "status": "Status"
      },
      "matching-process-tab": "Matching",
      "matching-started": "Matching started",
      "me": "me",
      "no-engagements": "No engagements were found.",
      "no-results": "No results found.",
      "notes": {
        "empty-note": "Empty note",
        "search-notes": "Search notes"
      },
      "notes-tab": "Notes",
      "notifications": {
        "add-message": "Add message",
        "after-engagement": "after engagement is started.",
        "after-file": "after the file is sent.",
        "after-form": "after the form is sent.",
        "after-link": "after the link is sent.",
        "after-previous-item": "after previous program item is completed.",
        "after-session": "before the session.",
        "after-task": "after the task is sent.",
        "after-todo": "after the task is sent.",
        "customize-content": "Customize content",
        "customize-content-description": "A scheduling confirmation email will be sent to all attendees when the session is scheduled. This email will automatically include the session date, time, attendees and an ICS attachment. Customize the subject and add a unique message below.",
        "customize-content-task-description": "You can send {taskType} notifications manually or schedule them to send automatically. Customize the subject and add a unique message below.",
        "edit-subject": "Edit subject",
        "enable-attachments": "Add attachments",
        "enable-attachments-description": "Add attachments to be sent on the notification and reminder emails.",
        "enable-reminder": "Enable reminder",
        "enable-reminder-description": "Add a session reminder to be sent before the session scheduled date.",
        "enable-task-reminder-description": "Add a reminder to complete the task. The reminder will only be sent if the recipient has yet to complete the task.",
        "loading-preview": "Loading preview...",
        "no-assignees-notice": "Add an assignee before enabling this feature.",
        "notifications": "Notifications",
        "preview-email": "Preview email",
        "send-automatically": "Send automatically",
        "send-automatically-description": "Schedule this item to be sent automatically.",
        "send-notification": "Send notification",
        "send-reminder": "Reminder will be sent"
      },
      "owner-company": "Company",
      "program": {
        "add-item": "Add Item",
        "hide-completed": "Hide Completed",
        "no-program-items": "No program items.",
        "no-search-results": "No search results.",
        "placeholders": {
          "active": "Start adding items to this program in the left column.",
          "coachee": "Your coach hasn't created your program yet.<br>Come back soon!",
          "draft": "You can assign a program template under engagement settings or build a new program here by adding items in the left column.",
          "inactive": "Resume the engagement to start adding items to this program."
        },
        "program-items-reordered": "Program changes saved.",
        "program-items-save-failed": "Program items failed to save.",
        "show-completed": "Show Completed"
      },
      "program-item": {
        "delete-dialog": "Delete item?"
      },
      "program-items": {
        "coaching-session-label": "Coaching Session",
        "file-label": "File",
        "form-label": "Form",
        "link-label": "Link",
        "session-label": "Session",
        "task-label": "Task"
      },
      "program-tab": "Program",
      "program-tab-disabled": "You are using a template, please select coach to edit program template.",
      "reports": {
        "engagement-summary": "Engagement Summary",
        "goals-summary": "Goals Summary",
        "notes-summary": "Notes Summary"
      },
      "reports-tab": "Reports",
      "resources": {
        "folders-label": "Folders",
        "note-card": {
          "untitled": "Untitled",
          "updated-on": "Updated on"
        },
        "permissions-notice": "<strong>Resources are private by default.</strong> To make a resource available to other users you must share the resource either to \"Everyone\" which would make it available to all Engagement Stakeholders or to selected stakeholders.",
        "resources-label": "Resources"
      },
      "resources-tab": "Resources",
      "return-to-engagements": "Return to engagements",
      "schedule-sessions": "Schedule coaching sessions.",
      "send-forms": "Send out forms and questionnaires.",
      "settings": {
        "add-engagement-editor": "Add engagement editor",
        "add-engagement-viewer": "Add engagement viewer",
        "add-file": "Add file",
        "add-viewer": "Add Viewer",
        "added": "Added on",
        "added-on": "Added on {date}",
        "coach": "Coach",
        "coach-disclaimer": "Coaches can see all tabs but cannot edit the program or settings.",
        "coachee": "Coachee",
        "coachee-disclaimer": "Coachees can only see the program, notes, goals and resources tabs.",
        "coachee-disclaimer-more": "Coachees can only see the program, notes, goals and resources tabs.",
        "confirm-remove-contract": "Are you sure you want to remove this contract from this engagement?",
        "contract": "contract",
        "contracts": {
          "client-contract": "Contract",
          "contractor-contract": "Coach contract",
          "title": "Contracts"
        },
        "create-invoice": "Create invoice",
        "created": "Created on",
        "created-on": "Created on",
        "creator": "Created by",
        "delete-engagement": "Delete this engagement",
        "delete-engagement-confirm": "Once you delete an engagement, it cannot be recovered.",
        "description": "Description",
        "description-placeholder": "Describe this engagement",
        "end": "End:",
        "end-unspecified": "End: Not specified.",
        "engagement-dates": "Engagement Dates",
        "engagement-deleted": "Engagement deleted.",
        "engagement-started": "Cannot be added after engagement has started.",
        "engagement-status": "Engagement Status",
        "engagement-status-updated": "Engagement set as {status}.",
        "hours-info": "Hours info",
        "invoicing-description": "Cannot be added after engagement starts.",
        "invoicing-title": "Preferred Invoicing Approach",
        "issued-on": "Issued on",
        "last-modified-at": "Last modified at {date}",
        "manager-already-added": "Editor already added.",
        "manager-disclaimer": "Can edit the program and engagement settings",
        "managers": "Engagement Editors",
        "matching": {
          "confirmation-dialog": {
            "message": "Enabling coachee Interaction is permanent you will not be able to switch back to ‘View Only’. Would you like to proceed?",
            "title": "Enable interaction"
          },
          "title": "Matching",
          "view-only": "View only matching",
          "view-only-desc": "When selected, coachees will only be able to view coach profiles. No interview scheduling or coach selection will be possible.",
          "view-only-invalid": "You cannot make this engagement view only if the matching starts as a normal engagement."
        },
        "matching-setting-label": "Matching settings",
        "meeting-location-description": "Set a default meeting location for this engagement.",
        "meeting-location-title": "Default Meeting Location",
        "meeting-locations": {
          "google_meet": "Google Meet",
          "in_app_video": "Coaching.com video",
          "link_will_generate": "A Google Meet link will be generated shortly.",
          "location": "Location",
          "phone": "Phone",
          "video": "Video"
        },
        "no-undo": "You cannot undo this action.",
        "no-viewers": "No viewers added.",
        "not-selected": "Not selected",
        "not-specified": "Not specified",
        "participants": "Engagement Participants",
        "percent-complete": "% of session hours completed",
        "percent-sessions": "% of sessions completed",
        "remove-contract": "Remove contract",
        "remove-editor": "Remove editor",
        "remove-manager": "Remove manager",
        "remove-viewer": "Remove viewer",
        "sessions-info": "Sessions info",
        "settings-permissions-notice": "Only Admins, Editors, and the Coach can see Engagement Settings.",
        "start": "Start:",
        "start-unspecified": "Start: Not specified.",
        "template-change-disclaimer": "Cannot be modified after engagement starts.",
        "template-disclaimer": "Cannot be added after engagement starts.",
        "title": "Title",
        "title-placeholder": "Type your engagement title",
        "upload-file": "Upload file",
        "viewer-already-added": "Viewer already added.",
        "viewer-disclaimer": "Can view program, notes, goals and resources tabs, but not settings",
        "viewers": "Engagement Viewers"
      },
      "settings-tab": "Settings",
      "start-chat-coach": "Start chat with coach",
      "start-chat-coachee": "Start chat with coachee",
      "start-matching": "Start matching",
      "status": "This engagement status is <strong>{status}</strong>.",
      "summary": {
        "goals-completed": "Goals completed",
        "goals-info": "Goals info",
        "goals-remaining": "Goals remaining",
        "percent-goals-complete": "% of goals completed",
        "percent-tasks-complete": "% of tasks completed",
        "task-info": "Tasks info",
        "tasks-completed": "Tasks completed",
        "tasks-remaining": "Tasks remaining",
        "total-goals": "Total goals",
        "total-tasks": "Total tasks"
      },
      "summary-tab": "Summary",
      "title": "{coachee} coached by {coach}",
      "upload-files": "Upload files and assign them for review.",
      "viewing-as": "Viewing as:",
      "visibility-updated": "Visibility updated."
    },
    "engagement-coach-pool": {
      "selection": {
        "cancel-dialog": {
          "description": "Are you sure you want to cancel this coaching request?",
          "title": "Cancel request"
        },
        "cancel-request": "Cancel request",
        "header-text": "You have selected <strong>{coachName}</strong> for this engagement.",
        "info": "You have selected <strong>{coachName}</strong> as your coach for this engagement. Your request is currently awaiting for approval. The engagement will start once your coach selection has been approved.",
        "interview-completed": "Your interview was completed on {completedDate}",
        "selected": "Awaiting coach selection approval"
      }
    },
    "engagement-coaching-request": {
      "change-completed-date": "Change completed date",
      "coaching-interview": "Coaching interview",
      "complete-interview": "Complete interview",
      "completed-on": "Completed on {date}",
      "default-title": "Matching interview",
      "details": "Interview details",
      "errors": {
        "max-interview-requests": "You have reached the maximum number of interview requests."
      },
      "location": "Location",
      "request-sent": "Request sent.",
      "scheduled-for": "Scheduled for {date}"
    },
    "engagement-note": {
      "add-note": "Add note",
      "delete-label": "Delete note",
      "note-shared": "Note shared.",
      "placeholder-content": {
        "about": "About notes",
        "attach": "Private notes can be attached to sessions or used for journaling.",
        "create": "Anyone with access to this engagement can create private notes.",
        "download": "You can download your private notes individually or download all in one file",
        "private": "Notes are private and cannot be viewed by anyone else.",
        "sharing": "Private notes cannot be shared, however you can create a document and share it with others under the engagement resources tab."
      },
      "shared-by": "Shared by {user}",
      "sharing-removed": "{user} was removed from this note.",
      "view-only": "(view only)"
    },
    "engagement-session": {
      "action-items": "Action items",
      "add-attendees-before-completing": "Add attendees before completing.",
      "add-attendees-before-scheduling": "Add attendees before scheduling.",
      "add-description": "Add a description",
      "add-note": "Add session note",
      "add-participant": "Add participant",
      "attendee": "attendee",
      "attendee-added": "Attendee added.",
      "attendees": "Attendees",
      "cancel-button": "Cancel",
      "cancelation-date-description": "Date of cancellation",
      "cancelation-date-label": "Cancelation date",
      "cancelation-reason-description": "Reason for canceling (optional)",
      "cancelation-reason-label": "Reason",
      "change-date-button": "Change date",
      "change-session-date": "Change session's date",
      "coachee-can-schedule": "You can schedule this session now.",
      "coachee-schedule-warning": "You can only schedule sessions if your coach has shared availability.",
      "comments": "Comments",
      "complete-interview-label": "Complete interview",
      "complete-label": "Complete session",
      "complete-session-button": "Complete",
      "completed-on": "Completed on {date}",
      "confirm-delete": "Are you sure you want to delete this session?",
      "default-title": "Untitled session",
      "delete-label": "Delete session",
      "delete-todo": "Delete item",
      "duplicate-label": "Duplicate session",
      "duration": "Duration",
      "duration-default": "15 minutes",
      "duration-minutes": "Duration (Minutes)",
      "duration-required": "Duration is required.",
      "duration-updated": "Duration updated.",
      "edit-cancelation": "Edit cancelation",
      "edit-scheduled-confirmation": "Edit scheduling confirmation",
      "enable-reminder": "Enable reminder",
      "fullscreen": "Open fullscreen",
      "google-meet-unscheduled": "Google Meet's video link will become available once the session is scheduled.",
      "late-cancel-description": "This session will appear as billable activity on invoices and reports.",
      "late-cancel-label": "Late cancel",
      "late-canceled": "This session was late canceled on {date}",
      "late-cancelled-on": "Late canceled on {date}",
      "location": "Location",
      "manage-attendees-label": "Manage attendees",
      "marked-as-late-cancellation": "Session marked as late cancelation.",
      "meeting-location-updated": "Meeting location updated.",
      "minutes": "Minutes",
      "minutes-label": "minute",
      "named-attendee-added": "{attendee} added.",
      "no-coach-availability": "This coach has not shared their availability yet. You can schedule this session, but this may cause scheduling conflicts for the coach.",
      "no-coach-availability-interview": "This coach has not shared their availability yet. You can schedule this interview, but this may cause scheduling conflicts for the coach.",
      "no-description": "No description added",
      "no-location": "No location added",
      "no-participants": "No participants added.",
      "no-past": "You can't schedule to a past date.",
      "participants-needed": "You must add at least one attendee.",
      "person-already-added": "This person has already been added.",
      "reschedule": "Reschedule",
      "reschedule-label": "Reschedule",
      "schedule-label": "Schedule session",
      "scheduled-for": "Scheduled for {date}",
      "send-reschedule-request": "Send Reschedule Request",
      "send-schedule-request": "Send Schedule Request",
      "session-date": "Session date",
      "session-details": "Details",
      "session-duplicated": "Session duplicated",
      "session-rescheduled": "Session rescheduled.",
      "session-saved": "Session saved.",
      "session-scheduled": "Session scheduled.",
      "session-updated": "Session updated.",
      "set-meeting-location": "Set meeting location",
      "start-time": "Start time",
      "time-unit-error": "Error setting the time unit.",
      "updated-session": "Session updated",
      "video-attendees": "Only engagement stakeholders who have active accounts will be able to access Coaching.com Video link."
    },
    "engagement-task": {
      "add-action-item": "Add new action item",
      "add-assignee": "Add assignee",
      "add-description": "Add a description",
      "add-recipient": "Add recipient",
      "assignee": "assignee",
      "assignee-already-added": "This person has already been added.",
      "assignee-needed": "You must assign at least one person.",
      "assignees": "Assignees",
      "can-send-once-every-24-hours": "You can only send it once every 24 hours.",
      "change-date": "Change date",
      "coachee-warning": {
        "file": "You cannot see this file or mark it complete until it is sent to you.",
        "form": "You cannot see this form or respond until it is sent to you.",
        "link": "You cannot see this link or mark it complete until it is sent to you.",
        "milestone": "You cannot mark this task complete until it is sent to you.",
        "todo": "You cannot mark this task complete until it is sent to you."
      },
      "comment": "Comment",
      "comments": "Comments",
      "complete-dialog": {
        "body": "Are you sure you want to complete this task?",
        "title": "Complete task"
      },
      "complete-label": "Complete task",
      "completed-date-set": "Completion date was set.",
      "completed-on": "Completed on",
      "confidential-form-description": "This form is confidential. Only account admins and form recipients can see responses.",
      "confirm-delete": "Are you sure you want to delete this task?",
      "default-name": "Untitled task",
      "delete-label": "Delete task",
      "download-resource": "Download resource",
      "duplicate-label": "Duplicate task",
      "file-saved": "File saved.",
      "form-saved": "Form saved.",
      "fullscreen": "Open fullscreen",
      "link-saved": "Link saved.",
      "manage-assignees-label": "Manage assignees",
      "mark-link-complete-warning": "You cannot see this task content or mark it complete until it is sent to you.",
      "no-assignees": "No participant added.",
      "no-description": "No description added",
      "no-responses-collected": "This form has no responses yet.",
      "one-response-collected": "1 response collected.",
      "own-answer": "You answered this form.",
      "preview": "Preview form",
      "preview-own-response": "See response",
      "preview-responses": "View responses",
      "recipient": "recipient",
      "recipient-added": "Recipient added.",
      "recipient-added-form": "Form sent to recipient.",
      "recipients": "Recipients",
      "resend": "Resend",
      "resend-error": "You need to add at least one recipient",
      "resend-file": "Resend file",
      "resend-form": "Resend form",
      "resend-link": "Resend link",
      "resend-notifications": "Resent notifications to assignees.",
      "resend-task": "Resend task",
      "resend-todo": "Resend task",
      "respond": "Respond",
      "responses-collected": "responses collected.",
      "send-automatically-confirmation": "This task is set to send a notification automatically, but you're trying to remove all of its assignees. Do you wish to proceed and disable automatic sending?",
      "send-file": "Send file",
      "send-form": "Send form",
      "send-link": "Send link",
      "send-milestone": "Send task",
      "send-task": "Send task",
      "send-task-again": "{taskType} sent again.",
      "send-todo": "Send task",
      "sent": "Sent",
      "sent-on": "Sent on {date}.",
      "set-target-date": "Set target date",
      "target-date-set": "Target date was set.",
      "task-completed-date": "Task completed date",
      "task-resent": "Notification resent",
      "task-saved": "Task saved.",
      "task-sent": "Task sent",
      "todo-assignee-added": "Assignee added.",
      "updated-task": "Updated task",
      "user-added": "{user} added.",
      "write-comment": "Write a comment"
    },
    "engagements": {
      "actions": {
        "complete": "Complete engagement",
        "delete": "Delete engagement",
        "resume": "Resume engagement",
        "start": "Start engagement",
        "start-matching": "Start matching"
      },
      "add-participant-by-email": "Can't find someone? Add by email",
      "column-selection": {
        "cancel": "Cancel",
        "save": "Save",
        "title": "Customize Columns"
      },
      "create-dialog": {
        "already-matching": "<strong>{coacheeName}</strong> is currently involved in coach matching.\nGo to their engagement to select their coach, schedule a matching interview, and more.",
        "assign-later": "Assign later",
        "choose-coach": "Choose the coach from your coach directory.",
        "create-coach": "Create new coach",
        "create-coach-description": "Create a new coach for this engagement",
        "create-coachee": "Create new coachee",
        "create-contract": "Create a contract now",
        "create-engagement": "Create Engagement",
        "for": "for",
        "go-to-engagement": "Go to engagement",
        "no-coachees": "No coachees were found.",
        "no-coaches": "No coaches were found.",
        "no-contracts": "No contracts were found.",
        "no-pools": "No coach pools were found.",
        "no-templates": "No templates were found.",
        "only-complete-uninvoiced": "Only show engagements with completed and uninvoiced sessions",
        "select-coach": "Select coach",
        "select-coach-pools": "Select coach pools",
        "select-coachee": "Select coachee",
        "select-coaches": "Select coaches",
        "select-contract": "Select contract",
        "select-template": "Select engagement template",
        "selecting": "Select coach",
        "selecting-options": "Suggest specific coaches",
        "selecting-pool": "Share coach pools",
        "share-options": "Provide specific suggestions for the coachee to choose from.",
        "share-pools": "Let the coachee choose their coach from coach pools.",
        "vendor-coaches-warning": "Vendor coaches can only have engagements with internal coaches"
      },
      "create-workflow": {
        "coachee-step-title": "Select Coachee",
        "company-filter-title": "Companies",
        "matching-settings-step-title": "Select matching settings",
        "no-matching-settings-found": "No matching settings were found.",
        "no-templates-found": "No templates were found.",
        "search-coachees-placeholder": "Search all coachees",
        "search-templates-placeholder": "Search for program template",
        "step-one-label": "Step 1 of 1",
        "step-two-label": "Step 2 of 2",
        "template-step-title": "Select program template"
      },
      "delete-confirmation": {
        "text": "You're about to delete this engagement. This action cannot be undone.",
        "title": "Delete confirmation"
      },
      "delete-tooltip": "Delete",
      "engagement-status": "Engagement status",
      "invite-coachee": {
        "description": "Would you like to send {coachee} an invitation to activate his/her account?",
        "is-inactive": "is Inactive"
      },
      "loading": "Loading engagements...",
      "na": "N/A",
      "no-coach-assigned": "No coach has been assigned.",
      "progress": {
        "coach": "Coach:",
        "completed": "Completed",
        "no-completed": "No completed engagements.",
        "no-started": "No started engagements.",
        "soon": "Finishing soon",
        "started": "Started"
      },
      "search-placeholder": "Search engagements",
      "stakeholder": "stakeholder",
      "start-matching-confirmation": {
        "confirmation-label": "Start matching",
        "text": "New contacts will be invited to log in if they have not yet activated their accounts.",
        "title": "Start Coach Matching"
      },
      "stats": {
        "active-engagements": "Active Engagements",
        "coach-disconnected": "This coach was disconnected from your account. Please add a new coach.",
        "completed-engagements": "Completed Engagements",
        "draft-engagements": "Draft Engagements",
        "no-coach": "Coach not assigned",
        "no-engagement": "Engagement not found."
      },
      "status": {
        "active": "Active",
        "all": "All",
        "coach_selection": "Matching",
        "completed": "Completed",
        "draft": "Draft",
        "draft_coach_selection": "Matching draft",
        "inactive": "Inactive"
      },
      "table-header": {
        "active-engagements": "Active engagements",
        "amount-due": "Amount due",
        "amount-paid": "Amount paid",
        "coach": "Coach",
        "coach-company": "Coach Company",
        "coach-email": "Coach Email",
        "coach-first-name": "Coach Last Name",
        "coach-last-name": "Coach First Name",
        "coachee": "Coachee",
        "coachee-company": "Coachee Company",
        "coachee-email": "Coachee Email",
        "coachee-first-name": "Coachee Last Name",
        "coachee-last-name": "Coachee First Name",
        "coachee-phone": "Coachee Phone",
        "completed-engagements": "Completed engagements",
        "department": "Department",
        "endDate": "End Date",
        "engagement-template": "Program",
        "hours-completed": "Hours completed",
        "hours-remaining": "Hours remaining",
        "leader-level": "Leader Level",
        "location": "Location",
        "percent-complete": "Percent Complete",
        "progress": "Progress",
        "sessions-cancelled": "Sessions canceled",
        "sessions-completed": "Sessions completed",
        "sessions-remaining": "Sessions remaining",
        "startDate": "Start Date",
        "status": "Status",
        "sub-department": "Sub-department",
        "title": "Employee Title",
        "total-engagements": "Total engagements",
        "total-sessions": "Total sessions"
      },
      "title": "Engagements",
      "upload-engagements": "Upload Engagements",
      "view-tooltip": "View"
    },
    "form": {
      "and": "and",
      "answers": {
        "answer": "Answer",
        "confirm-delete": "Are you sure you want to delete this response?",
        "count": "{responses, plural, =0 {no responses} =1 {1 response} other {# responses}}",
        "delete": "Delete response",
        "next": "Next response",
        "no-response": "No one has submitted a response yet.",
        "no-responses": "No responses.",
        "number": "# of responses",
        "previous": "Previous response"
      },
      "back-to-submissions": "Back to submissions",
      "block-responses": {
        "blocked": "Disabled",
        "blocked-description": "This form is not accepting responses.",
        "dialog-title": "Enable/disable link",
        "public": "Enabled",
        "public-description": "This form is accepting responses."
      },
      "confidential-dialog": {
        "all": {
          "description": "All engagement participants can see this form.",
          "name": "Everyone"
        },
        "confidential": {
          "description": "Only account admins and form recipients can see confidential form results.",
          "name": "Confidential"
        },
        "title": "Who can see responses"
      },
      "delete": "Delete Form",
      "delete-confirm": "Are you sure you want to delete this form? If you do, any related submission data will be discarded.",
      "download-csv": "Download responses as csv file",
      "download-responses": "Download responses (.csv)",
      "empty-question-error": "You need to add the question's description.",
      "empty-section-error": "You need to add a title for the section.",
      "example": {
        "coach": "Example coach",
        "coachee": "Example coachee",
        "name": "Example"
      },
      "external-send-dialog": {
        "add-users-tooltip": "Add using list of users",
        "block-global-responses": "Enable responses from link",
        "copied": "Copied to clipboard",
        "email": "Email",
        "highlight": "click to highlight",
        "invalid-entries": "The following entries are not valid email addresses:",
        "invalid-ignored": "These addresses will be ignored.",
        "link": "Link",
        "message-label": "Message",
        "message-placeholder": "Enter your message here",
        "send-success": "Form sent successfully",
        "subject-label": "Subject",
        "subject-placeholder": "email subject",
        "title": "Send form",
        "to-helper": "You can enter multiple email addresses separated by commas, semicolons or new lines interchangeably.",
        "to-label": "To",
        "to-placeholder": "Name <address@example.com>; Another name <address2@example.com>",
        "valid-found": "Found <strong>{number}</strong> valid email {number, plural, one {address} other {addresses}}."
      },
      "individual": "Individual",
      "loading": "Loading form...",
      "manage-visibility": "Manage Visibility",
      "no-assignment": "This submission does not belong to any engagement.",
      "no-options-error": "You need to add at least one option.",
      "origin": "This task originated from the engagement between",
      "preview": "Preview Form",
      "preview-mode": "Preview mode",
      "previewing": "\"Completing a form is not available during a form preview. On the actual form submission, the\n recipient will be able to complete the form and the answers will be captured.\"",
      "questions": {
        "add-option-button": "Add option",
        "added-option-toast": "Option was added",
        "added-section-toast": "Section was added",
        "added-toast": "Question was added",
        "delete-button": "Delete",
        "delete-tooltip": "Delete",
        "deleted-option-toast": "Option was deleted",
        "deleted-section-toast": "Section was deleted",
        "deleted-toast": "Question was deleted",
        "description-placeholder": "Form description",
        "duplicate-button": "Duplicate",
        "duplicate-tooltip": "Duplicate",
        "floating-bar": {
          "add-number": "Add number question",
          "add-text": "Add text question",
          "dropdown": "Add dropdown question",
          "multi-choice": "Add multiple choice question",
          "section": "Add section",
          "single-choice": "Add single choice question"
        },
        "move-down-tooltip": "Move down",
        "move-up-tooltip": "Move up",
        "multi-choice": {
          "add-option": "Add option",
          "weight-placeholder": "Weight (optional)"
        },
        "new-question": "Untitled",
        "new-section": "Untitled",
        "number": {
          "answer-placeholder": "Numeric value"
        },
        "other-answer": "Other",
        "question-delete-prompt": "Are you sure you want to delete this question? If you do, any related submission data will be discarded.",
        "question-placeholder": "Question *",
        "required": "Required",
        "section-delete-prompt": "Are you sure you want to delete this section? If you do, any related questions and their submission data will be discarded.",
        "section-description-placeholder": "Section description",
        "section-title": "Section {n} of {total}",
        "section-title-placeholder": "Section title",
        "single-choice": {
          "add-option": "Add option",
          "add-other": "Add \"other\"",
          "new-option": "Option",
          "other": "Other...",
          "weight-placeholder": "Weight (optional)"
        },
        "text": {
          "answer-placeholder": "Answer"
        },
        "title-placeholder": "Form title",
        "undo": "Undo"
      },
      "recorded": "Your response has been recorded. Thank you!",
      "responses": "responses",
      "revert": "Revert changes",
      "save": "Save",
      "saved": "Form changes saved.",
      "saving": "Saving...",
      "submission": {
        "already-submitted": "You already submitted this form once.",
        "already-submitted-description": "Forms received cannot be edited or submitted again after they were already sent.",
        "coach": "Coach",
        "coachee": "Coachee",
        "example": "Example",
        "example-coach": "Example coach",
        "example-coachee": "Example coachee",
        "preview-mode": "Preview mode",
        "preview-warning": "Completing a form is not available during a form preview. On the actual form submission, the recipient will be able to complete the form and the answers will be captured.",
        "response-recorded": "Your response has been recorded.",
        "response-submited-on": "This form was submitted on <strong>{date}</strong>",
        "thank-you": "Thank you!"
      },
      "submission-summary": "Submission summary",
      "submitted": "This form was submitted on",
      "submitted-by": "Submitted by",
      "submitted-on": "Submitted on",
      "summary": "Summary",
      "tabs": {
        "answers": "Responses",
        "questions": "Questions"
      },
      "thank": "Thank you!",
      "waiting-responses": "Waiting for responses"
    },
    "global": {
      "auth": {
        "failed": "These credentials do not match our records.",
        "throttle": "Too many login attempts. Please try again in :seconds seconds."
      },
      "client": {
        "active_client": "active client",
        "active_clients": "active clients",
        "archive_client": "Archive Client",
        "archived_client": "Successfully archived client",
        "archived_clients": "Successfully archived :count clients",
        "client": "Client",
        "client_will_create": "client will be created",
        "clients": "Clients",
        "clients_will_create": "clients will be created",
        "create_new_client": "Create new client",
        "created_client": "Successfully created client",
        "created_clients": "Successfully created :count client(s)",
        "delete_client": "Delete Client",
        "deleted_client": "Successfully deleted client",
        "deleted_clients": "Successfully deleted :count clients",
        "edit_client": "Edit Client",
        "edit_client_details": "Edit client details",
        "export_clients": "Export Client Data",
        "import_clients": "Import Client Data",
        "updated_client": "Successfully updated client"
      },
      "general": {
        "256_encryption": "256-Bit Encryption",
        "JSON_file": "JSON File",
        "accept_debit_cards": "Accept Debit Cards",
        "account_fields": "Company Fields",
        "account_holder_name": "Account Holder Name",
        "account_holder_type": "Account Holder Type",
        "account_management": "Account Management",
        "account_name": "Account Name",
        "account_number": "Account Number",
        "account_number_mismatch": "The account numbers do not match.",
        "ach": "ACH",
        "ach_authorization": "I authorize :company to use my bank account for future payments and, if necessary, electronically credit my account to correct erroneous debits. I understand that I may cancel this authorization at any time by removing the payment method or by contacting :email.",
        "ach_authorization_required": "You must consent to ACH transactions.",
        "ach_disabled": "Another gateway is already configured for direct debit.",
        "ach_email_prompt": "Please enter your email address:",
        "ach_verification_delay_help": "You will be able to use the account after completing verification. Verification usually takes 1-2 business days.",
        "action": "Action",
        "action_required": "Action Required",
        "active": "Active",
        "activity": "Activity",
        "activity_1": ":user created client :client",
        "activity_10": ":contact entered payment :payment for :invoice",
        "activity_11": ":user updated payment :payment",
        "activity_12": ":user archived payment :payment",
        "activity_13": ":user deleted payment :payment",
        "activity_14": ":user entered :credit credit",
        "activity_15": ":user updated :credit credit",
        "activity_16": ":user archived :credit credit",
        "activity_17": ":user deleted :credit credit",
        "activity_18": ":user created quote :quote",
        "activity_19": ":user updated quote :quote",
        "activity_2": ":user archived client :client",
        "activity_20": ":user emailed quote :quote to :contact",
        "activity_21": ":contact viewed quote :quote",
        "activity_22": ":user archived quote :quote",
        "activity_23": ":user deleted quote :quote",
        "activity_24": ":user restored quote :quote",
        "activity_25": ":user restored invoice :invoice",
        "activity_26": ":user restored client :client",
        "activity_27": ":user restored payment :payment",
        "activity_28": ":user restored :credit credit",
        "activity_29": ":contact approved quote :quote",
        "activity_3": ":user deleted client :client",
        "activity_30": ":user created vendor :vendor",
        "activity_31": ":user archived vendor :vendor",
        "activity_32": ":user deleted vendor :vendor",
        "activity_33": ":user restored vendor :vendor",
        "activity_34": ":user created expense :expense",
        "activity_35": ":user archived expense :expense",
        "activity_36": ":user deleted expense :expense",
        "activity_37": ":user restored expense :expense",
        "activity_39": ":user canceled a :payment_amount payment :payment",
        "activity_4": ":user created invoice :invoice",
        "activity_40": ":user refunded :adjustment of a :payment_amount payment :payment",
        "activity_41": ":payment_amount payment (:payment) failed",
        "activity_42": ":user created task :task",
        "activity_43": ":user updated task :task",
        "activity_44": ":user archived task :task",
        "activity_45": ":user deleted task :task",
        "activity_46": ":user restored task :task",
        "activity_47": ":user updated expense :expense",
        "activity_5": ":user updated invoice :invoice",
        "activity_6": ":user emailed invoice :invoice to :contact",
        "activity_7": ":contact viewed invoice :invoice",
        "activity_8": ":user archived invoice :invoice",
        "activity_9": ":user deleted invoice :invoice",
        "add_account": "Add Account",
        "add_bank_account": "Add Bank Account",
        "add_company": "Add Company",
        "add_contact": "Add contact",
        "add_credit_card": "Add Credit Card",
        "add_gateway": "Add Gateway",
        "add_payment_method": "Add Payment Method",
        "add_paypal_account": "Add PayPal Account",
        "add_to_invoice": "Add to invoice :invoice",
        "add_users_not_supported": "Upgrade to the Enterprise plan to add additional users to your account.",
        "added_on": "Added :date",
        "additional_info": "Additional Info",
        "address": "Address",
        "address1": "Street",
        "address2": "Apt/Suite",
        "admin": "Admin",
        "administrator": "Administrator",
        "administrator_help": "Allow user to manage users, change settings and modify all records",
        "advanced_settings": "Advanced Settings",
        "after": "After",
        "age": "Age",
        "age_group_0": "0 - 30 Days",
        "age_group_120": "120+ Days",
        "age_group_30": "30 - 60 Days",
        "age_group_60": "60 - 90 Days",
        "age_group_90": "90 - 120 Days",
        "aging": "Aging",
        "agree_to_terms": "I agree to the Coaching.com :terms",
        "alipay": "Alipay",
        "all": "All",
        "all_pages": "All pages",
        "all_pages_footer": "Show Footer on",
        "all_pages_header": "Show Header on",
        "always": "Always",
        "amount-late": "Amount Late",
        "amount_due": "Amount due",
        "analytics_key": "Analytics Key",
        "analytics_key_help": "Track payments using :link",
        "android_app": "Android app",
        "application_settings": "Application Settings",
        "applied_discount": "The coupon has been applied, the plan price has been reduced by :discount%.",
        "applied_free_year": "The coupon has been applied, your account has been upgraded to pro for one year.",
        "apply_credit": "Apply Credit",
        "apply_license": "Apply License",
        "apply_taxes": "Apply taxes",
        "approve": "Approve",
        "april": "April",
        "archive": "Archive",
        "archive_account_gateway": "Archive Gateway",
        "archive_bank_account": "Archive Bank Account",
        "archive_expense": "Archive Expense",
        "archive_expense_category": "Archive Category",
        "archive_payment_term": "Archive Payment Term",
        "archive_project": "Archive Project",
        "archive_recurring_invoice": "Archive Recurring Invoice",
        "archive_task": "Archive Task",
        "archive_tax_rate": "Archive Tax Rate",
        "archive_token": "Archive Token",
        "archive_user": "Archive User",
        "archive_vendor": "Archive Vendor",
        "archived": "Archived",
        "archived_account_gateway": "Successfully archived gateway",
        "archived_bank_account": "Successfully archived bank account",
        "archived_expense": "Successfully archived expense",
        "archived_expense_categories": "Successfully archived :count expense category",
        "archived_expense_category": "Successfully archived expense category",
        "archived_expenses": "Successfully archived expenses",
        "archived_project": "Successfully archived project",
        "archived_projects": "Successfully archived :count projects",
        "archived_recurring_invoice": "Successfully archived recurring invoice",
        "archived_task": "Successfully archived task",
        "archived_tasks": "Successfully archived :count tasks",
        "archived_tax_rate": "Successfully archived the tax rate",
        "archived_token": "Successfully archived token",
        "archived_user": "Successfully archived user",
        "are_you_sure": "Are you sure?",
        "are_you_sure_refund": "Refund selected payments?",
        "assigned_when_sent": "Assigned when sent",
        "associated_accounts": "Successfully linked accounts",
        "august": "August",
        "authentication": "Authentication",
        "authorization": "Authorization",
        "auto_bill": "Auto Bill",
        "auto_bill_ach_date_help": "ACH will always auto bill on the due date.",
        "auto_bill_email_message": "This invoice will automatically be billed to the payment method on file on the due date.",
        "auto_bill_notification": "This invoice will automatically be billed to your :payment_method on file on :due_date.",
        "auto_bill_notification_placeholder": "This invoice will automatically be billed to your credit card on file on the due date.",
        "auto_bill_payment_method_bank_transfer": "bank account",
        "auto_bill_payment_method_credit_card": "credit card",
        "auto_bill_payment_method_paypal": "PayPal account",
        "auto_convert_quote": "Auto convert quote",
        "auto_convert_quote_help": "Automatically convert a quote to an invoice when approved by a client.",
        "auto_wrap": "Auto Line Wrap",
        "bank_account": "Bank Account",
        "bank_account_error": "Failed to retreive account details, please check your credentials.",
        "bank_account_verification_help": "We have made two deposits into your account with the description \"VERIFICATION\". These deposits will take 1-2 business days to appear on your statement. Please enter the amounts below.",
        "bank_account_verification_next_steps": "We have made two deposits into your account with the description \"VERIFICATION\". These deposits will take 1-2 business days to appear on your statement. Once you have the amounts, come back to this payment methods page and click \"Complete Verification\" next to the account.",
        "bank_accounts": "Credit Cards & Banks",
        "bank_accounts_help": "Connect a bank account to automatically import expenses and create vendors. Supports American Express and <a href=\":link\" target=\"_blank\">400+ US banks.</a>",
        "bank_id": "Bank",
        "bank_password_help": "Note: your password is transmitted securely and never stored on our servers.",
        "bank_password_warning": "Warning: your password may be transmitted in plain text, consider enabling HTTPS.",
        "bank_transfer": "Bank Transfer",
        "basic_settings": "Basic Settings",
        "bcc_email": "BCC Email",
        "bcc_email_help": "Privately include this address with client emails.",
        "before": "Before",
        "billable": "Billable",
        "billing_address": "Billing Address",
        "billing_method": "Billing Method",
        "bitcoin": "Bitcoin",
        "blank": "Blank",
        "body": "Body",
        "body_font_id": "Body Font",
        "braintree_enable_paypal": "Enable PayPal payments through BrainTree",
        "braintree_paypal_disabled_help": "The PayPal gateway is processing PayPal payments",
        "braintree_paypal_help": "You must also :link.",
        "braintree_paypal_help_link_text": "link PayPal to your BrainTree account",
        "break_duration": "Break",
        "button_confirmation_message": "Click to confirm your email address.",
        "buy_license": "Buy License",
        "buy_now_buttons": "Buy Now Buttons",
        "buy_now_buttons_disabled": "This feature requires that a product is created and a payment gateway is configured.",
        "buy_now_buttons_warning": "Note: the client and invoice are created even if the transaction isn't completed.",
        "calendar": "Calendar",
        "canada": "Canada",
        "cancel": "Cancel",
        "cancel_account_help": "Permanently delete the account along with all data and setting.",
        "cancel_plan_change": "Cancel Change",
        "card_ach": "ACH",
        "card_americanexpress": "American Express",
        "card_carteblanche": "Carte Blanche",
        "card_creditcardother": "Unknown",
        "card_diners": "Diners Club",
        "card_discover": "Discover",
        "card_expiration": "Exp:&nbsp:expires",
        "card_jcb": "JCB",
        "card_laser": "Laser",
        "card_maestro": "Maestro",
        "card_mastercard": "MasterCard",
        "card_solo": "Solo",
        "card_switch": "Switch",
        "card_unionpay": "UnionPay",
        "card_visacard": "Visa",
        "categories": "Categories",
        "category": "Category",
        "changes_take_effect_immediately": "Note: changes take effect immediately",
        "chart": "Chart",
        "chart_type": "Chart Type",
        "charts_and_reports": "Charts & Reports",
        "checkbox": "Checkbox",
        "choose": "Choose",
        "city": "City",
        "city_state_postal": "City/State/Postal",
        "click_here": "click here",
        "client_created_at": "Date Created",
        "client_file": "Client File",
        "client_id": "Client Id",
        "client_login": "Client Login",
        "client_name": "Client Name",
        "client_not_found": "I wasn't able to find the client",
        "client_number": "Client Number",
        "client_number_help": "Specify a prefix or use a custom pattern to dynamically set the client number.",
        "client_portal": "Client Portal",
        "client_session_expired": "Session Expired",
        "client_session_expired_message": "Your session has expired. Please click the link in your email again.",
        "client_variables": "Client Variables",
        "close": "Close",
        "color_font_help": "Note: the primary color and fonts are also used in the client portal and custom email designs.",
        "column": "Column",
        "columns": "Columns",
        "companies": "Companies",
        "company": "Company",
        "company_account": "Company Account",
        "company_details": "Company Details",
        "company_name": "Company name",
        "company_not_found": "I couldn't find the company I'm looking for on this list",
        "company_size": "Company Size",
        "company_variables": "Company Variables",
        "competency_library": "Theme Library",
        "complete_verification": "Complete Verification",
        "confirm": "Confirm",
        "confirm_account_number": "Confirm Account Number",
        "confirm_account_to_import": "Please confirm your account to import data.",
        "confirm_recurring_timing": "Note: emails are sent at the start of the hour.",
        "confirm_remove_payment_method": "Are you sure you want to remove this payment method?",
        "confirmation_header": "Account Confirmation",
        "confirmation_message": "Please access the link below to confirm your account.",
        "confirmation_subject": "Coaching.com Account Confirmation",
        "contact": "Contact",
        "contact_custom1": "Contact First Custom",
        "contact_custom2": "Contact Second Custom",
        "contact_email": "Contact Email",
        "contact_fields": "Contact Fields",
        "contact_first_name": "Contact First Name",
        "contact_information": "Contact Information",
        "contact_last_name": "Contact Last Name",
        "contact_name": "Contact Name",
        "contact_phone": "Contact Phone",
        "contact_us": "Contact Us",
        "contact_us_help": "If you're reporting an error please include any relevant logs from storage/logs/laravel-error.log",
        "contact_us_response": "Thank you for your message! We'll try to respond as soon as possible.",
        "contacts": "Contacts",
        "content": "Content",
        "convert_currency": "Convert currency",
        "converted": "Converted",
        "cost": "Cost",
        "counter": "Counter",
        "country": "Country",
        "country_Afghanistan": "Afghanistan",
        "country_Albania": "Albania",
        "country_Algeria": "Algeria",
        "country_American Samoa": "American Samoa",
        "country_Andorra": "Andorra",
        "country_Angola": "Angola",
        "country_Anguilla": "Anguilla",
        "country_Antarctica": "Antarctica",
        "country_Antigua and Barbuda": "Antigua and Barbuda",
        "country_Argentina": "Argentina",
        "country_Armenia": "Armenia",
        "country_Aruba": "Aruba",
        "country_Australia": "Australia",
        "country_Austria": "Austria",
        "country_Azerbaijan": "Azerbaijan",
        "country_Bahamas": "Bahamas",
        "country_Bahrain": "Bahrain",
        "country_Bangladesh": "Bangladesh",
        "country_Barbados": "Barbados",
        "country_Belarus": "Belarus",
        "country_Belgium": "Belgium",
        "country_Belize": "Belize",
        "country_Benin": "Benin",
        "country_Bermuda": "Bermuda",
        "country_Bhutan": "Bhutan",
        "country_Bolivia, Plurinational State of": "Bolivia, Plurinational State of",
        "country_Bonaire, Sint Eustatius and Saba": "Bonaire, Sint Eustatius and Saba",
        "country_Bosnia and Herzegovina": "Bosnia and Herzegovina",
        "country_Botswana": "Botswana",
        "country_Bouvet Island": "Bouvet Island",
        "country_Brazil": "Brazil",
        "country_British Indian Ocean Territory": "British Indian Ocean Territory",
        "country_Brunei Darussalam": "Brunei Darussalam",
        "country_Bulgaria": "Bulgaria",
        "country_Burkina Faso": "Burkina Faso",
        "country_Burundi": "Burundi",
        "country_Cambodia": "Cambodia",
        "country_Cameroon": "Cameroon",
        "country_Canada": "Canada",
        "country_Cape Verde": "Cape Verde",
        "country_Cayman Islands": "Cayman Islands",
        "country_Central African Republic": "Central African Republic",
        "country_Chad": "Chad",
        "country_Chile": "Chile",
        "country_China": "China",
        "country_Christmas Island": "Christmas Island",
        "country_Cocos (Keeling) Islands": "Cocos (Keeling) Islands",
        "country_Colombia": "Colombia",
        "country_Comoros": "Comoros",
        "country_Congo": "Congo",
        "country_Congo, the Democratic Republic of the": "Congo, the Democratic Republic of the",
        "country_Cook Islands": "Cook Islands",
        "country_Costa Rica": "Costa Rica",
        "country_Croatia": "Croatia",
        "country_Cuba": "Cuba",
        "country_Curaçao": "Curaçao",
        "country_Cyprus": "Cyprus",
        "country_Czech Republic": "Czech Republic",
        "country_Côte d'Ivoire": "Côte d'Ivoire",
        "country_Denmark": "Denmark",
        "country_Djibouti": "Djibouti",
        "country_Dominica": "Dominica",
        "country_Dominican Republic": "Dominican Republic",
        "country_Ecuador": "Ecuador",
        "country_Egypt": "Egypt",
        "country_El Salvador": "El Salvador",
        "country_Equatorial Guinea": "Equatorial Guinea",
        "country_Eritrea": "Eritrea",
        "country_Estonia": "Estonia",
        "country_Ethiopia": "Ethiopia",
        "country_Falkland Islands (Malvinas)": "Falkland Islands (Malvinas)",
        "country_Faroe Islands": "Faroe Islands",
        "country_Fiji": "Fiji",
        "country_Finland": "Finland",
        "country_France": "France",
        "country_French Guiana": "French Guiana",
        "country_French Polynesia": "French Polynesia",
        "country_French Southern Territories": "French Southern Territories",
        "country_Gabon": "Gabon",
        "country_Gambia": "Gambia",
        "country_Georgia": "Georgia",
        "country_Germany": "Germany",
        "country_Ghana": "Ghana",
        "country_Gibraltar": "Gibraltar",
        "country_Greece": "Greece",
        "country_Greenland": "Greenland",
        "country_Grenada": "Grenada",
        "country_Guadeloupe": "Guadeloupe",
        "country_Guam": "Guam",
        "country_Guatemala": "Guatemala",
        "country_Guernsey": "Guernsey",
        "country_Guinea": "Guinea",
        "country_Guinea-Bissau": "Guinea-Bissau",
        "country_Guyana": "Guyana",
        "country_Haiti": "Haiti",
        "country_Heard Island and McDonald Islands": "Heard Island and McDonald Islands",
        "country_Holy See (Vatican City State)": "Holy See (Vatican City State)",
        "country_Honduras": "Honduras",
        "country_Hong Kong": "Hong Kong",
        "country_Hungary": "Hungary",
        "country_Iceland": "Iceland",
        "country_India": "India",
        "country_Indonesia": "Indonesia",
        "country_Iran, Islamic Republic of": "Iran, Islamic Republic of",
        "country_Iraq": "Iraq",
        "country_Ireland": "Ireland",
        "country_Isle of Man": "Isle of Man",
        "country_Israel": "Israel",
        "country_Italy": "Italy",
        "country_Jamaica": "Jamaica",
        "country_Japan": "Japan",
        "country_Jersey": "Jersey",
        "country_Jordan": "Jordan",
        "country_Kazakhstan": "Kazakhstan",
        "country_Kenya": "Kenya",
        "country_Kiribati": "Kiribati",
        "country_Korea, Democratic People's Republic of": "Korea, Democratic People's Republic of",
        "country_Korea, Republic of": "Korea, Republic of",
        "country_Kuwait": "Kuwait",
        "country_Kyrgyzstan": "Kyrgyzstan",
        "country_Lao People's Democratic Republic": "Lao People's Democratic Republic",
        "country_Latvia": "Latvia",
        "country_Lebanon": "Lebanon",
        "country_Lesotho": "Lesotho",
        "country_Liberia": "Liberia",
        "country_Libya": "Libya",
        "country_Liechtenstein": "Liechtenstein",
        "country_Lithuania": "Lithuania",
        "country_Luxembourg": "Luxembourg",
        "country_Macao": "Macao",
        "country_Macedonia, the former Yugoslav Republic of": "Macedonia, the former Yugoslav Republic of",
        "country_Madagascar": "Madagascar",
        "country_Malawi": "Malawi",
        "country_Malaysia": "Malaysia",
        "country_Maldives": "Maldives",
        "country_Mali": "Mali",
        "country_Malta": "Malta",
        "country_Marshall Islands": "Marshall Islands",
        "country_Martinique": "Martinique",
        "country_Mauritania": "Mauritania",
        "country_Mauritius": "Mauritius",
        "country_Mayotte": "Mayotte",
        "country_Mexico": "Mexico",
        "country_Micronesia, Federated States of": "Micronesia, Federated States of",
        "country_Moldova, Republic of": "Moldova, Republic of",
        "country_Monaco": "Monaco",
        "country_Mongolia": "Mongolia",
        "country_Montenegro": "Montenegro",
        "country_Montserrat": "Montserrat",
        "country_Morocco": "Morocco",
        "country_Mozambique": "Mozambique",
        "country_Myanmar": "Myanmar",
        "country_Namibia": "Namibia",
        "country_Nauru": "Nauru",
        "country_Nepal": "Nepal",
        "country_Netherlands": "Netherlands",
        "country_New Caledonia": "New Caledonia",
        "country_New Zealand": "New Zealand",
        "country_Nicaragua": "Nicaragua",
        "country_Niger": "Niger",
        "country_Nigeria": "Nigeria",
        "country_Niue": "Niue",
        "country_Norfolk Island": "Norfolk Island",
        "country_Northern Mariana Islands": "Northern Mariana Islands",
        "country_Norway": "Norway",
        "country_Oman": "Oman",
        "country_Pakistan": "Pakistan",
        "country_Palau": "Palau",
        "country_Palestinian Territory, Occupied": "Palestinian Territory, Occupied",
        "country_Panama": "Panama",
        "country_Papua New Guinea": "Papua New Guinea",
        "country_Paraguay": "Paraguay",
        "country_Peru": "Peru",
        "country_Philippines": "Philippines",
        "country_Pitcairn": "Pitcairn",
        "country_Poland": "Poland",
        "country_Portugal": "Portugal",
        "country_Puerto Rico": "Puerto Rico",
        "country_Qatar": "Qatar",
        "country_Romania": "Romania",
        "country_Russian Federation": "Russian Federation",
        "country_Rwanda": "Rwanda",
        "country_Réunion": "Réunion",
        "country_Saint Barthélemy": "Saint Barthélemy",
        "country_Saint Helena, Ascension and Tristan da Cunha": "Saint Helena, Ascension and Tristan da Cunha",
        "country_Saint Kitts and Nevis": "Saint Kitts and Nevis",
        "country_Saint Lucia": "Saint Lucia",
        "country_Saint Martin (French part)": "Saint Martin (French part)",
        "country_Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
        "country_Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
        "country_Samoa": "Samoa",
        "country_San Marino": "San Marino",
        "country_Sao Tome and Principe": "Sao Tome and Principe",
        "country_Saudi Arabia": "Saudi Arabia",
        "country_Senegal": "Senegal",
        "country_Serbia": "Serbia",
        "country_Seychelles": "Seychelles",
        "country_Sierra Leone": "Sierra Leone",
        "country_Singapore": "Singapore",
        "country_Sint Maarten (Dutch part)": "Sint Maarten (Dutch part)",
        "country_Slovakia": "Slovakia",
        "country_Slovenia": "Slovenia",
        "country_Solomon Islands": "Solomon Islands",
        "country_Somalia": "Somalia",
        "country_South Africa": "South Africa",
        "country_South Georgia and the South Sandwich Islands": "South Georgia and the South Sandwich Islands",
        "country_South Sudan": "South Sudan",
        "country_Spain": "Spain",
        "country_Sri Lanka": "Sri Lanka",
        "country_Sudan": "Sudan",
        "country_Suriname": "Suriname",
        "country_Svalbard and Jan Mayen": "Svalbard and Jan Mayen",
        "country_Swaziland": "Swaziland",
        "country_Sweden": "Sweden",
        "country_Switzerland": "Switzerland",
        "country_Syrian Arab Republic": "Syrian Arab Republic",
        "country_Taiwan, Province of China": "Taiwan, Province of China",
        "country_Tajikistan": "Tajikistan",
        "country_Tanzania, United Republic of": "Tanzania, United Republic of",
        "country_Thailand": "Thailand",
        "country_Timor-Leste": "Timor-Leste",
        "country_Togo": "Togo",
        "country_Tokelau": "Tokelau",
        "country_Tonga": "Tonga",
        "country_Trinidad and Tobago": "Trinidad and Tobago",
        "country_Tunisia": "Tunisia",
        "country_Turkey": "Turkey",
        "country_Turkmenistan": "Turkmenistan",
        "country_Turks and Caicos Islands": "Turks and Caicos Islands",
        "country_Tuvalu": "Tuvalu",
        "country_Uganda": "Uganda",
        "country_Ukraine": "Ukraine",
        "country_United Arab Emirates": "United Arab Emirates",
        "country_United Kingdom": "United Kingdom",
        "country_United States": "United States",
        "country_United States Minor Outlying Islands": "United States Minor Outlying Islands",
        "country_Uruguay": "Uruguay",
        "country_Uzbekistan": "Uzbekistan",
        "country_Vanuatu": "Vanuatu",
        "country_Venezuela, Bolivarian Republic of": "Venezuela, Bolivarian Republic of",
        "country_Viet Nam": "Viet Nam",
        "country_Virgin Islands, British": "Virgin Islands, British",
        "country_Virgin Islands, U.S.": "Virgin Islands, U.S.",
        "country_Wallis and Futuna": "Wallis and Futuna",
        "country_Western Sahara": "Western Sahara",
        "country_Yemen": "Yemen",
        "country_Zambia": "Zambia",
        "country_Zimbabwe": "Zimbabwe",
        "country_id": "Country",
        "country_not_supported": "That country is not supported.",
        "country_Åland Islands": "Åland Islands",
        "create": "Create",
        "create_all_help": "Allow user to create and modify records",
        "create_client": "Create Client",
        "create_expense_category": "Create category",
        "create_invoice_for_sample": "Note: create your first invoice to see a preview here.",
        "create_new": "Create New",
        "create_new_invoice": "Create new invoice",
        "create_payment_term": "Create Payment Term",
        "create_project": "Create project",
        "create_task": "Create Task",
        "create_tax_rate": "Add Tax Rate",
        "create_vendor": "Create vendor",
        "created_at": "Date Created",
        "created_bank_account": "Successfully created bank account",
        "created_by": "Created by :name",
        "created_by_invoice": "Created by :invoice",
        "created_customer": "Successfully created customer",
        "created_customers": "Successfully created :count customers",
        "created_expense": "Successfully created expense",
        "created_expense_category": "Successfully created expense category",
        "created_gateway": "Successfully created gateway",
        "created_invoices": "Successfully created :count invoice(s)",
        "created_new_company": "Successfully created new company",
        "created_payment_emailed_client": "Successfully created payment and emailed client",
        "created_products": "Successfully created/updated :count product(s)",
        "created_project": "Successfully created project",
        "created_quotes": "Successfully created :count quotes(s)",
        "created_task": "Successfully created task",
        "created_tax_rate": "Successfully created tax rate",
        "created_wepay_confirmation_required": "Please check your email and confirm your email address with WePay.",
        "creator": "Created by",
        "credit_card": "Credit Card",
        "csv": "CSV",
        "csv_file": "CSV file",
        "currency_code": "Code",
        "currency_symbol": "Symbol",
        "current_user": "Current User",
        "custom": "Custom",
        "custom1": "First Custom",
        "custom2": "Second Custom",
        "custom_account_fields_helps": "Add a label and value to the company details section of the PDF.",
        "custom_client_fields_helps": "Add a field when creating a client and optionally display the label and value on the PDF.",
        "custom_contact_fields_help": "Add a field when creating a contact and optionally display the label and value on the PDF.",
        "custom_css": "Custom CSS",
        "custom_design1": "Custom Design 1",
        "custom_design2": "Custom Design 2",
        "custom_design3": "Custom Design 3",
        "custom_field": "Custom Field",
        "custom_fields": "Custom Fields",
        "custom_invoice_charges_helps": "Add a field when creating an invoice and include the charge in the invoice subtotals.",
        "custom_invoice_fields_helps": "Add a field when creating an invoice and optionally display the label and value on the PDF.",
        "custom_invoice_item_fields_help": "Add a field when creating an invoice item and display the label and value on the PDF.",
        "custom_invoice_link": "Custom Invoice Link",
        "custom_messages": "Custom Messages",
        "custom_product_fields_help": "Add a field when creating a product or invoice and display the label and value on the PDF.",
        "custom_range": "Custom Range",
        "custom_variables": "Custom Variables",
        "customer": "Customer",
        "customers": "Customers",
        "customize": "Customize",
        "customize_design": "Customize Design",
        "customize_help": "<p>We use <a href=\"http://pdfmake.org/\" target=\"_blank\">pdfmake</a> to define the invoice designs declaratively. The pdfmake <a href=\"http://pdfmake.org/playground.html\" target=\"_blank\">playground</a> provides a great way to see the library in action.</p> <p>You can access a child property using dot notation. For example to show the client name you could use <code>$client.name</code>.</p> <p>If you need help figuring something out post a question to our <a href=\"https://www.coachlogix.com/forums/forum/support/\" target=\"_blank\">support forum</a> with the design you're using.</p>",
        "customize_options": "Customize options",
        "dark": "Dark",
        "dark_mode": "Dark Mode",
        "dark_mode_help": "Use a dark background for the sidebars",
        "dashboard": "Dashboard",
        "data": "Data",
        "data_visualizations": "Data Visualizations",
        "datatable_info": "Showing {start} to {end} of {total} entries",
        "date": "Date",
        "date_and_time": "Date & Time",
        "date_created": "Date Created",
        "date_format": "Date Format",
        "date_range": "Date Range",
        "datetime_format": "Date/Time Format",
        "day": "Day",
        "day_of_month": ":ordinal day of month",
        "day_of_week_after": ":ordinal :day after",
        "days": "Days",
        "days_after": "days after the",
        "days_before": "days before the",
        "debit_cards": "Debit Cards",
        "debug": "Debug",
        "december": "December",
        "default_design": "Standard Design",
        "default_documents": "Default Documents",
        "default_email_footer": "Set default <b>email signature</b>",
        "default_invoice_footer": "Default Invoice Footer",
        "default_invoice_terms": "Default Invoice Terms",
        "default_messages": "Default Messages",
        "default_quote_terms": "Default Quote Terms",
        "default_tax_rate_id": "Default Tax Rate",
        "defaults": "Defaults",
        "delete": "Delete",
        "delete_company": "Delete Company",
        "delete_company_help": "Permanently delete the company along with all data and setting.",
        "delete_company_message": "Warning: This will permanently delete your company, there is no undo.",
        "delete_data": "Delete Data",
        "delete_expense": "Delete Expense",
        "delete_expense_category": "Delete category",
        "delete_gateway": "Delete Gateway",
        "delete_project": "Delete project",
        "delete_recurring_invoice": "Delete Recurring Invoice",
        "delete_task": "Delete Task",
        "delete_user": "Delete User",
        "delete_vendor": "Delete Vendor",
        "deleted": "Deleted",
        "deleted_account": "Successfully deleted company",
        "deleted_account_details": "Your account (:account) has been successfully deleted.",
        "deleted_company": "Successfully canceled account",
        "deleted_company_details": "Your company (:account) has been successfully deleted.",
        "deleted_expense": "Successfully deleted expense",
        "deleted_expense_category": "Successfully deleted category",
        "deleted_expenses": "Successfully deleted expenses",
        "deleted_gateway": "Successfully deleted gateway",
        "deleted_project": "Successfully deleted project",
        "deleted_projects": "Successfully deleted :count projects",
        "deleted_recurring_invoice": "Successfully deleted recurring invoice",
        "deleted_task": "Successfully deleted task",
        "deleted_tasks": "Successfully deleted :count tasks",
        "deleted_user": "Successfully deleted user",
        "description": "Description",
        "details": "Details",
        "disable": "Disable",
        "disable_recurring": "Disable Recurring",
        "disabled": "Disabled",
        "discard_changes": "Discard Changes",
        "discount_message": ":amount off expires :expires",
        "discount_sample": "The discount for a :amount invoice would be :total.",
        "document_date": "Document Date",
        "document_email_attachment": "Attach Documents",
        "document_size": "Size",
        "documentation": "Documentation",
        "documents": "Documents",
        "documents_from_expenses": "From Expenses:",
        "documents_header": "Documents:",
        "domain": "Domain",
        "domain_help": "Used in the client portal and when sending emails.",
        "domain_help_website": "Used when sending emails.",
        "done": "Done",
        "download": "Download",
        "download_documents": "Download Documents (:size)",
        "draft": "Draft",
        "dropzone_cancel_upload": "Cancel upload",
        "dropzone_cancel_upload_confirmation": "Are you sure you want to cancel this upload?",
        "dropzone_default_message": "Drop files or click to upload",
        "dropzone_fallback_message": "Your browser does not support drag'n'drop file uploads.",
        "dropzone_fallback_text": "Please use the fallback form below to upload your files like in the olden days.",
        "dropzone_invalid_file_type": "You can't upload files of this type.",
        "dropzone_remove_file": "Remove file",
        "due": "Due",
        "due_by": "Due by :date",
        "due_date": "Due Date",
        "duplicate_post": "Warning: the previous page was submitted twice. The second submission had been ignored.",
        "duration": "Duration",
        "dwolla": "Dwolla",
        "edit": "Edit",
        "edit_all_help": "Allow user to modify records they didn't create",
        "edit_bank_account": "Edit Bank Account",
        "edit_category": "Edit Category",
        "edit_credit": "Edit Credit",
        "edit_details": "Edit Details",
        "edit_expense": "Edit Expense",
        "edit_gateway": "Edit Gateway",
        "edit_payment_details": "Edit payment details",
        "edit_payment_term": "Edit Payment Term",
        "edit_payment_terms": "Edit Payment Term",
        "edit_project": "Edit Project",
        "edit_report": "Edit Report",
        "edit_task": "Edit Task",
        "edit_tax_rate": "Edit tax rate",
        "edit_user": "Edit User",
        "edit_vendor": "Edit Vendor",
        "email": "Email",
        "email_alias_message": "We require each company to have a unique email address.<br/>Consider using an alias. ie, email+label@example.com",
        "email_design": "Email Design",
        "email_design_help": "Make your emails look more professional with HTML layouts.",
        "email_design_id": "Email Style",
        "email_designs": "Email Designs",
        "email_documents_example_1": "Widgets Receipt.pdf",
        "email_documents_example_2": "Final Deliverable.zip",
        "email_documents_header": "Documents:",
        "email_error": "There was a problem sending the email",
        "email_error_inactive_client": "Emails can not be sent to inactive clients",
        "email_error_inactive_contact": "Emails can not be sent to inactive contacts",
        "email_error_inactive_invoice": "Emails can not be sent to inactive invoices",
        "email_error_invalid_contact_email": "Invalid contact email",
        "email_error_user_unconfirmed": "Please confirm your account to send emails",
        "email_error_user_unregistered": "Please register your account to send emails",
        "email_notifications": "Email Notifications",
        "email_notifications_desc": "Set your company's signature and social media for email notifications.",
        "email_notifications_toggle": "Enable or disable individual email notifications.",
        "email_payment": "Email Payment",
        "email_preferences": "Email Preferences",
        "email_receipt": "Email payment receipt to the client",
        "email_settings": "Email Settings",
        "email_taken": "The email address is already registered",
        "emailed_invoices": "Successfully emailed invoices",
        "emailed_payment": "Successfully emailed payment",
        "emailed_quotes": "Successfully emailed quotes",
        "empty": "Empty",
        "enable": "Enable",
        "enable_ach": "Accept US bank transfers",
        "enable_alipay": "Accept Alipay",
        "enable_buy_now_buttons_help": "Enable support for buy now buttons",
        "enable_chart": "Chart",
        "enable_client_portal": "Client Portal",
        "enable_client_portal_dashboard": "Dashboard",
        "enable_client_portal_dashboard_help": "Show/hide the dashboard page in the client portal.",
        "enable_client_portal_help": "Show/hide the client portal.",
        "enable_email_markup": "Enable Markup",
        "enable_email_markup_help": "Make it easier for your clients to pay you by adding schema.org markup to your emails.",
        "enable_https": "We strongly recommend using HTTPS to accept credit card details online.",
        "enable_max": "Enable max",
        "enable_min": "Enable min",
        "enable_portal_password": "Password Protect Invoices",
        "enable_portal_password_help": "Allows you to set a password for each contact. If a password is set, the contact will be required to enter a password before viewing invoices.",
        "enable_recurring": "Enable Recurring",
        "enable_report": "Report",
        "enable_second_tax_rate": "Enable specifying a <b>second tax rate</b>",
        "enable_sofort": "Accept EU bank transfers",
        "enable_with_stripe": "Enable | Requires Stripe",
        "enabled": "Enabled",
        "encryption": "Encryption",
        "end": "End",
        "end_date": "End Date",
        "end_time": "End Time",
        "engagement_template": "Program",
        "enter_company_name": "Please enter the name of the company.",
        "enter_expense": "Enter Expense",
        "enterprise_plan_features": "The Enterprise plan adds support for multiple users and file attachments, :link to see the full list of features.",
        "enterprise_plan_month_description": "One month enrollment in the Coaching.com Enterprise Plan.",
        "enterprise_plan_product": "Enterprise Plan",
        "enterprise_plan_year_description": "One year enrollment in the Coaching.com Enterprise Plan.",
        "entity_state": "State",
        "error_contact_text": "If you'd like help please email us at :mailaddress",
        "error_refresh_page": "An error occurred, please refresh the page and try again.",
        "error_title": "Something went wrong",
        "exchange_rate": "Exchange Rate",
        "exclusive": "Exclusive",
        "expense": "Expense",
        "expense_amount": "Expense Amount",
        "expense_balance": "Expense Balance",
        "expense_categories": "Expense Categories",
        "expense_date": "Expense Date",
        "expense_documents": "Attached Documents",
        "expense_error_invoiced": "Expense has already been invoiced",
        "expense_error_mismatch_currencies": "The client's currency does not match the expense currency.",
        "expense_error_multiple_clients": "The expenses can't belong to different clients",
        "expense_error_multiple_currencies": "The expenses can't have different currencies.",
        "expense_file": "Expense File",
        "expense_should_be_invoiced": "Should this expense be invoiced?",
        "expenses": "Expenses",
        "expired": "Expired",
        "expired_quotes": "Expired Quotes",
        "expires": "Expires",
        "export": "Export",
        "export_data": "Export Data",
        "export_help": "Use JSON if you plan to import the data into Coaching.com.<br/>The file includes clients, products, invoices, quotes and payments.",
        "facebook_and_twitter": "Facebook and Twitter",
        "facebook_and_twitter_help": "Follow our feeds to help support our project",
        "failed_remove_payment_method": "Failed to remove the payment method",
        "failed_to_import": "The following records failed to import, they either already exist or are missing required fields.",
        "february": "February",
        "fee": "Fee",
        "fees": "Fees",
        "fees_disabled": "Fees are disabled",
        "fees_disabled_for_gateway": "Fees are disabled for this gateway.",
        "fees_sample": "The fee for a :amount invoice would be :total.",
        "fees_surcharge_help": "Customize surcharge :link.",
        "fees_tax_help": "Enable line item taxes to set the fee tax rates.",
        "field_due_date": "due date",
        "field_invoice_date": "invoice date",
        "field_label": "Field Label",
        "field_value": "Field Value",
        "fields": "Fields",
        "file": "File",
        "filter": "Filter",
        "filter_sort": "Filter/Sort",
        "filters": "Filters",
        "financial_year_start": "First Month of the Year",
        "financial_year_start_help": "Used by <b>date range</b> selectors",
        "finish_setup": "Finish Setup",
        "first_name": "First Name",
        "first_page": "First page",
        "first_reminder": "First Reminder",
        "first_row_headers": "Use first row as headers",
        "font_size": "Font Size",
        "footer": "Footer",
        "forbidden": "Forbidden",
        "force_pdfjs": "Prevent Download",
        "form": "Form",
        "format": "Format",
        "format_export": "Exporting format",
        "forms": "Forms",
        "free": "Free",
        "freq_annually": "Annually",
        "freq_four_weeks": "Four weeks",
        "freq_inactive": "Inactive",
        "freq_monthly": "Monthly",
        "freq_six_months": "Six months",
        "freq_three_months": "Three months",
        "freq_two_months": "Two months",
        "freq_two_weeks": "Two weeks",
        "freq_weekly": "Weekly",
        "freq_yearly": "Annually",
        "friday": "Friday",
        "from": "From",
        "from_address": "From Address",
        "from_name": "From Name",
        "full_logo": "Full Logo",
        "full_name": "Full Name",
        "gateway": "Gateway",
        "gateway_config_error": "It may help to set new passwords or generate new API keys.",
        "gateway_exists": "This gateway already exists",
        "gateway_fee_change_warning": "If there are unpaid invoices with fees they need to be updated manually.",
        "gateway_fees": "Gateway Fees",
        "gateway_fees_disclaimer": "Warning: not all states/payment gateways allow adding fees, please review local laws/terms of service.",
        "gateway_fees_help": "Automatically add an online payment surcharge/discount.",
        "gateway_help_20": ":link to sign up for Sage Pay.",
        "gateway_help_21": ":link to sign up for Sage Pay.",
        "gateway_help_43": ":link to sign up for Dwolla",
        "gateways": "Payment Gateways",
        "general": "General",
        "general_settings": "General Settings",
        "generated_numbers": "Generated Numbers",
        "go_back": "Go Back",
        "goal_stakeholder": "Goal stakeholder",
        "gocardless_webhook_help_link_text": "add this URL as an endpoint in GoCardless",
        "google_analytics": "Google Analytics",
        "group_by": "Group by",
        "group_dates_by": "Group Dates By",
        "group_when_sorted": "Group When Sorted",
        "guest": "Guest",
        "header": "Header",
        "header_font_id": "Header Font",
        "header_footer": "Header/Footer",
        "help": "Help",
        "hide": "Hide",
        "history": "History",
        "hour": "Hour",
        "hours": "Hours",
        "https": "HTTPS",
        "i_agree": "I Agree To The Terms",
        "id_number": "ID Number",
        "iframe_url": "Website",
        "iframe_url_help1": "Copy the following code to a page on your site.",
        "iframe_url_help2": "You can test the feature by clicking 'View as recipient' for an invoice.",
        "iframe_url_help3": "Note: if you plan on accepting credit cards details we strongly recommend enabling HTTPS on your site.",
        "import": "Import",
        "import_complete": "Your import has successfully completed.",
        "import_data": "Import Data",
        "import_expenses": "Import Expenses",
        "import_export": "Import | Export",
        "import_failed": "Import Failed",
        "import_freshbooks": "Import From FreshBooks",
        "import_invoices": "Import Invoices",
        "import_ofx": "Import OFX",
        "import_products": "Import Products",
        "import_started": "Your import has started, we'll send you an email once it completes.",
        "import_warning_invalid_date": "Warning: The date format appears to be invalid.",
        "imported_expenses": "Successfully created :count_vendors vendor(s) and :count_expenses expense(s)",
        "imported_file": "Successfully imported file",
        "imported_settings": "Successfully imported settings",
        "in_total_revenue": "in total revenue",
        "inactive_logout": "You have been logged out due to inactivity",
        "include": "Include",
        "inclusive": "Inclusive",
        "independent-coach": "Independent Coach",
        "individual_account": "Individual Account",
        "industry": "Industry",
        "industry_Accounting_&_Legal": "Accounting & Legal",
        "industry_Advertising": "Advertising",
        "industry_Aerospace": "Aerospace",
        "industry_Agriculture": "Agriculture",
        "industry_Automotive": "Automotive",
        "industry_Banking_&_Finance": "Banking & Finance",
        "industry_Biotechnology": "Biotechnology",
        "industry_Broadcasting": "Broadcasting",
        "industry_Business Services": "Business Services",
        "industry_Commodities & Chemicals": "Commodities & Chemicals",
        "industry_Communications": "Communications",
        "industry_Computers & Hightech": "Computers & Hightech",
        "industry_Construction": "Construction",
        "industry_Defense": "Defense",
        "industry_Energy": "Energy",
        "industry_Entertainment": "Entertainment",
        "industry_Government": "Government",
        "industry_Healthcare & Life Sciences": "Healthcare & Life Sciences",
        "industry_Insurance": "Insurance",
        "industry_Manufacturing": "Manufacturing",
        "industry_Marketing": "Marketing",
        "industry_Media": "Media",
        "industry_Nonprofit & Higher Ed": "Nonprofit & Higher Ed",
        "industry_Other": "Other",
        "industry_Pharmaceuticals": "Pharmaceuticals",
        "industry_Photography": "Photography",
        "industry_Professional Services & Consulting": "Professional Services & Consulting",
        "industry_Real Estate": "Real Estate",
        "industry_Restaurant & Catering": "Restaurant & Catering",
        "industry_Retail & Wholesale": "Retail & Wholesale",
        "industry_Sports": "Sports",
        "industry_Transportation": "Transportation",
        "industry_Travel & Luxury": "Travel & Luxury",
        "industry_help": "Used to provide comparisons against the averages of companies of similar size and industry.",
        "info": "Info",
        "initial_email": "Initial Email",
        "integration_type": "Integration Type",
        "intent_not_found": "Sorry, I'm not sure what you're asking.",
        "intent_not_supported": "Sorry, I'm not able to do that.",
        "internal-coach": "Internal Coach",
        "invalid_account_number": "The account number is not valid.",
        "invalid_card_number": "The credit card number is not valid.",
        "invalid_credentials": "These credentials do not match our records",
        "invalid_csv_header": "Invalid CSV Header",
        "invalid_cvv": "The CVV is not valid.",
        "invalid_expiry": "The expiration date is not valid.",
        "invalid_file": "Invalid file type",
        "invalid_mail_config": "Unable to send email, please check that themail settings are correct.",
        "invalid_routing_number": "The routing number is not valid.",
        "invalid_white_label_license": "The white label license is not valid",
        "invitation_status_opened": "Openend",
        "invitation_status_sent": "sent",
        "invitation_status_viewed": "Viewed",
        "invoice_amount": "Invoice Amount",
        "invoice_charges": "Invoice Surcharges",
        "invoice_counter": "Invoice Counter",
        "invoice_currency": "Invoice Currency",
        "invoice_details": "Invoice Details",
        "invoice_documents": "Documents",
        "invoice_due_date": "Due Date",
        "invoice_embed_documents": "Embed Documents",
        "invoice_embed_documents_help": "Include attached images in the invoice.",
        "invoice_expense": "Invoice Expense",
        "invoice_fields_help": "Drag and drop fields to change their order and location",
        "invoice_file": "Invoice File",
        "invoice_footer_help": "Use $pageNumber and $pageCount to display the page information.",
        "invoice_for_client": "Invoice :invoice for :client",
        "invoice_from": "Invoices From:",
        "invoice_item_fields": "Invoice Item Fields",
        "invoice_link": "Invoice Link",
        "invoice_message_button": "To view your invoice for :amount, click the button below.",
        "invoice_name": "Invoice",
        "invoice_no": "Invoice No.",
        "invoice_not_emailed": "This invoice hasn't been emailed.",
        "invoice_not_found": "The requested invoice is not available",
        "invoice_number_help": "Specify a prefix or use a custom pattern to dynamically set the invoice number.",
        "invoice_number_padding": "Padding",
        "invoice_quote_number": "Invoice and Quote Numbers",
        "invoice_save_error": "There was an error saving your invoice",
        "invoice_sent": ":count invoice sent",
        "invoice_signature": "Signature",
        "invoice_to": "Invoice to",
        "invoice_variables": "Invoice Variables",
        "invoice_will_create": "invoice will be created",
        "invoiced_amount": "Invoiced Amount",
        "invoices_sent": ":count invoices sent",
        "invoices_will_create": "invoices will be created",
        "iphone_app": "iPhone app",
        "iphone_app_message": "Consider downloading our :link",
        "january": "January",
        "july": "July",
        "june": "June",
        "keyboard_shortcuts": "Keyboard Shortcuts",
        "knowledge_base": "Knowledge Base",
        "label": "Label",
        "label_and_taxes": "label and taxes",
        "landing_page": "Landing Page",
        "lang_Albanian": "Albanian",
        "lang_Brazilian Portuguese": "Brazilian Portuguese",
        "lang_Croatian": "Croatian",
        "lang_Czech": "Czech",
        "lang_Danish": "Danish",
        "lang_Dutch": "Dutch",
        "lang_English": "English",
        "lang_English - United Kingdom": "English - United Kingdom",
        "lang_Finnish": "Finnish",
        "lang_French": "French",
        "lang_French - Canada": "French - Canada",
        "lang_German": "German",
        "lang_Greek": "Greek",
        "lang_Italian": "Italian",
        "lang_Japanese": "Japanese",
        "lang_Lithuanian": "Lithuanian",
        "lang_Norwegian": "Norwegian",
        "lang_Polish": "Polish",
        "lang_Portuguese - Brazilian": "Portuguese - Brazilian",
        "lang_Portuguese - Portugal": "Portuguese - Portugal",
        "lang_Romanian": "Romanian",
        "lang_Slovenian": "Slovenian",
        "lang_Spanish": "Spanish",
        "lang_Spanish - Spain": "Spanish - Spain",
        "lang_Swedish": "Swedish",
        "lang_Turkish - Turkey": "Turkish - Turkey",
        "language": "Language",
        "last_30_days": "Last 30 Days",
        "last_7_days": "Last 7 Days",
        "last_day_of_month": "Last day of month",
        "last_invoice_sent": "Last invoice sent :date",
        "last_logged_in": "Last logged in",
        "last_login": "Last Login",
        "last_modified_at": "Last modified at",
        "last_month": "Last Month",
        "last_name": "Last Name",
        "last_page": "Last page",
        "last_sent": "Last Sent",
        "last_sent_on": "Sent Last: :date",
        "last_year": "Last Year",
        "learn_more": "Learn more",
        "less_fields": "Less Fields",
        "license_expiring": "Note: Your license will expire in :count days, :link to renew it.",
        "light": "Light",
        "limit": "Limit",
        "limit_import_rows": "Data needs to be imported in batches of :count rows or less",
        "limit_users": "Sorry, this will exceed the limit of :limit users",
        "limited_gateways": "Note: we support one credit card gateway per company.",
        "limits": "Limits",
        "limits_not_met": "This invoice does not meet the limits for that payment type.",
        "line_item": "Line Item",
        "link": "Link",
        "link_manually": "Link Manually",
        "link_with_plaid": "Link Account Instantly with Plaid",
        "links": "Links",
        "list_...": "List ...",
        "list_clients": "List Clients",
        "list_credits": "List Credits",
        "list_expense_categories": "List Expense Categories",
        "list_expenses": "List Expenses",
        "list_invoices": "List Invoices",
        "list_payments": "List Payments",
        "list_projects": "List Projects",
        "list_quotes": "List Quotes",
        "list_recurring_invoices": "List Recurring Invoices",
        "list_tasks": "List Tasks",
        "list_vendors": "List Vendors",
        "listening": "Listening...",
        "live_preview": "Live Preview",
        "live_preview_disabled": "Live preview has been disabled to support selected font",
        "load_design": "Load Design",
        "localization": "Localization",
        "location": "Location",
        "location_first_surcharge": "Enabled - First surcharge",
        "location_line_item": "Enabled - Line item",
        "location_second_surcharge": "Enabled - Second surcharge",
        "logged": "Logged",
        "logged_in": "Logged In",
        "login": "Login",
        "login_create_an_account": "Create an Account!",
        "login_or_existing": "Or login with an existing account.",
        "logo": "Logo",
        "logo_help": "Supported: JPEG, GIF and PNG",
        "logo_too_large": "Your logo is :size, for better PDF performance we suggest uploading an image file less than 200KB",
        "logo_warning_fileinfo": "Warning: To support gifs the fileinfo PHP extension needs to be enabled.",
        "logo_warning_invalid": "There was a problem reading the image file, please try a different format.",
        "logo_warning_too_large": "The image file is too large.",
        "logout": "Log Out",
        "logout_and_delete": "Log Out/Delete Account",
        "mailgun_domain": "Mailgun Domain",
        "mailgun_private_key": "Mailgun Private Key",
        "manage_account": "Manage Account",
        "manage_auto_bill": "Manage Auto-bill",
        "manage_companies": "Manage Companies",
        "manager": "Manager",
        "managers": "Managers",
        "manual": "Manual",
        "manual_entry": "Manual entry",
        "march": "March",
        "margins": "Margins",
        "mark_active": "Mark Active",
        "mark_paid": "Mark Paid",
        "mark_ready": "Mark Ready",
        "marked_sent_invoice": "Successfully marked invoice sent",
        "marked_sent_invoices": "Successfully marked invoices sent",
        "max": "Max",
        "max_limit": "Max: :max",
        "max_users_reached": "The maximum number of users has been reached.",
        "may": "May",
        "menu": "Menu",
        "message": "Message",
        "microphone_help": "Say \"new invoice for [client]\" or \"show me [client]'s archived payments\"",
        "military_time": "24 Hour Time",
        "min": "Min",
        "min_limit": "Min: :min",
        "min_to_max_users": ":min to :max users",
        "minute": "Minute",
        "minutes": "Minutes",
        "missing_account_holder_name": "Please enter the account holder's name.",
        "missing_account_holder_type": "Please select an individual or company account.",
        "missing_publishable_key": "Set your Stripe publishable key for an improved checkout process",
        "modules": "Modules",
        "monday": "Monday",
        "month": "Month",
        "month_year": "MONTH/YEAR",
        "more_actions": "More Actions",
        "more_designs_self_host_header": "Get 6 more invoice designs for just $:price",
        "more_fields": "More Fields",
        "more_options": "More options",
        "name": "Name",
        "navigation": "Navigation",
        "navigation_variables": "Navigation Variables",
        "never": "Never",
        "new_...": "New ...",
        "new_category": "New Category",
        "new_client": "New Client",
        "new_company": "New Company",
        "new_expense": "Enter Expense",
        "new_expense_category": "New Expense Category",
        "new_product": "New Product",
        "new_project": "New Project",
        "new_recurring_invoice": "New Recurring Invoice",
        "new_report": "New Report",
        "new_start_date": "New start date",
        "new_task": "New Task",
        "new_tax_rate": "New Tax Rate",
        "new_user": "New User",
        "new_vendor": "New Vendor",
        "next_client_number": "The next client number is :number.",
        "next_credit_number": "The next credit number is :number.",
        "next_due_on": "Due Next: :date",
        "next_invoice_number": "The next invoice number is :number.",
        "next_quote_number": "The next quote number is :number.",
        "next_reset": "Next Reset",
        "next_send_on": "Send Next: :date",
        "no": "No",
        "no-notifications": "No notifications",
        "no_client_selected": "Please select a client",
        "no_contact_selected": "Please select a contact",
        "no_description": "No Description",
        "no_fees": "No Fees",
        "no_items": "No items",
        "no_limit": "No Limits",
        "no_longer_running": "This invoice is not scheduled to run",
        "no_mapper": "No valid mapping for file",
        "no_payment_method_specified": "No payment method specified",
        "no_results": "No results were found.",
        "no_transaction_reference": "We did not recieve a payment transaction reference from the gateway.",
        "no_undo": "Warning: this can't be undone.",
        "not_a_member_yet": "Not a member yet?",
        "not_allowed": "Sorry, you don't have the needed permissions",
        "not_authorized": "You are not authorized",
        "not_specified": "Not specified",
        "notes": "Notes",
        "notes_auto_billed": "Auto-billed",
        "notes_reminder1": "First Reminder",
        "notes_reminder2": "Second Reminder",
        "notes_reminder3": "Third Reminder",
        "notification_invoice_bounced": "We were unable to deliver Invoice :invoice to :contact.",
        "notification_invoice_bounced_subject": "Unable to deliver Invoice :invoice",
        "notification_invoice_payment_failed": "A payment made by client :client towards Invoice :invoice failed. The payment has been marked as failed and :amount has been added to the client's balance.",
        "notification_invoice_payment_failed_subject": "Payment failed for Invoice :invoice",
        "notification_quote_bounced": "We were unable to deliver Quote :invoice to :contact.",
        "notification_quote_bounced_subject": "Unable to deliver Quote :invoice",
        "notifications": "Notifications",
        "november": "November",
        "now": "Now",
        "num_days": "Number of Days",
        "num_days_reminder": "Days after due date",
        "oauth_taken": "The account is already registered",
        "october": "October",
        "of": "of",
        "of-hours": "of hours",
        "off": "Off",
        "ofx_file": "OFX File",
        "ofx_help": "In most cases the default values should work, if you're unable to connect it may help to adjust the settings.",
        "ofx_parse_failed": "Failed to parse OFX file",
        "old_browser": "Please use a <a href=\":link\" target=\"_blank\">newer browser</a>",
        "on_due_date": "On due date",
        "on_send_date": "On send date",
        "oneclick_login": "One-Click Login",
        "oneclick_login_help": "Connect an account to login without a password",
        "open_balance": "Open Balance",
        "opt_in": "Opt-in",
        "opt_out": "Opt-out",
        "opted_in": "Opted in",
        "opted_out": "Opted out",
        "options": "Options",
        "or": "or",
        "order_overview": "Order overview",
        "organization": "Organization",
        "original_start_date": "Original start date",
        "other_providers": "Other Providers",
        "outstanding": "Outstanding",
        "overdue": "Overdue",
        "owner": "Owner",
        "padding_help": "The number of zero's to pad the number.",
        "page_expire": "This page will expire soon, :click_here to keep working",
        "page_size": "Page Size",
        "paid": "Paid",
        "partial_due": "Partial Due",
        "partial_value": "Must be greater than zero and less than the total",
        "participants": "Participants",
        "password": "Password",
        "pattern": "Pattern",
        "pattern_help_1": "Create custom numbers by specifying a pattern",
        "pattern_help_2": "Available variables:",
        "pattern_help_3": "For example, :example would be converted to :value",
        "pattern_help_title": "Pattern Help",
        "pay_with_card": "Credit Card",
        "pay_with_paypal": "PayPal",
        "payment": "Payment",
        "payment_error_code": "There was an error processing your payment [:code]. Please try again later.",
        "payment_file": "Payment File",
        "payment_message_button": "Thank you for your payment of :amount.",
        "payment_method_added": "Added payment method.",
        "payment_method_error": "There was an error adding your payment methd. Please try again later.",
        "payment_method_removed": "Removed payment method.",
        "payment_method_set_as_default": "Set Autobill payment method.",
        "payment_method_verified": "Verification completed successfully",
        "payment_methods": "Payment Methods",
        "payment_processed_through_wepay": "ACH payments will be processed using WePay.",
        "payment_settings": "Payment Settings",
        "payment_terms_help": "Sets the default <b>invoice due date</b>",
        "payment_terms_net": "Net",
        "payment_type": "Payment Type",
        "payment_type_ACH": "ACH",
        "payment_type_Alipay": "Alipay",
        "payment_type_American Express": "American Express",
        "payment_type_Apply Credit": "Apply Credit",
        "payment_type_Bank Transfer": "Bank Transfer",
        "payment_type_Carte Blanche": "Carte Blanche",
        "payment_type_Cash": "Cash",
        "payment_type_Check": "Check",
        "payment_type_Credit Card Other": "Credit Card Other",
        "payment_type_Debit": "Debit",
        "payment_type_Diners Card": "Diners Card",
        "payment_type_Discover Card": "Discover Card",
        "payment_type_EuroCard": "EuroCard",
        "payment_type_Google Wallet": "Google Wallet",
        "payment_type_JCB": "JCB",
        "payment_type_Laser": "Laser",
        "payment_type_Maestro": "Maestro",
        "payment_type_MasterCard": "MasterCard",
        "payment_type_Money Order": "Money Order",
        "payment_type_Nova": "Nova",
        "payment_type_PayPal": "PayPal",
        "payment_type_Sofort": "Sofort",
        "payment_type_Solo": "Solo",
        "payment_type_Swish": "Swish",
        "payment_type_Switch": "Switch",
        "payment_type_UnionPay": "UnionPay",
        "payment_type_Venmo": "Venmo",
        "payment_type_Visa Card": "Visa Card",
        "payment_type_direct_debit": "Direct Debit",
        "payment_type_dwolla": "Dwolla",
        "payment_type_help": "Sets the default <b>manual payment type</b>.",
        "payment_type_iZettle": "iZettle",
        "payment_type_on_file": ":type on file",
        "payment_type_stripe": "Stripe",
        "paypal": "PayPal",
        "pdf_settings": "PDF Settings",
        "pending": "Pending",
        "pending_change_to": "Changes To",
        "percent": "Percent",
        "permissions": "Permissions",
        "phantomjs_help": "In certain cases the app uses :link_phantom to generate the PDF, install :link_docs to generate it locally.",
        "phantomjs_local": "Using local PhantomJS",
        "phone": "Phone",
        "plaid": "Plaid",
        "plaid_environment_help": "When a Stripe test key is given, Plaid's development environement (tartan) will be used.",
        "plaid_linked_status": "Your bank account at :bank",
        "plaid_optional": "(optional)",
        "plain": "Plain",
        "plan": "Plan",
        "plan_change": "Change Plan",
        "plan_changes_to": ":plan on :date",
        "plan_credit_description": "Credit for unused time",
        "plan_credit_product": "Credit",
        "plan_enterprise": "Enterprise",
        "plan_expired": ":plan Plan Expired",
        "plan_expires": "Plan Expires",
        "plan_free": "Free",
        "plan_free_self_hosted": "Self Hosted (Free)",
        "plan_paid": "Term Started",
        "plan_pending_monthly": "Will switch to monthly on :date",
        "plan_price": "Plan Price",
        "plan_price_monthly": "$:price/Month",
        "plan_price_yearly": "$:price/Year",
        "plan_pro": "Pro",
        "plan_refunded": "A refund has been issued.",
        "plan_started": "Plan Started",
        "plan_status": "Plan Status",
        "plan_term": "Term",
        "plan_term_changes_to": ":plan (:term) on :date",
        "plan_term_month": "Month",
        "plan_term_monthly": "Monthly",
        "plan_term_year": "Year",
        "plan_term_yearly": "Yearly",
        "plan_trial": "Trial",
        "plan_upgrade": "Upgrade",
        "plan_white_label": "Self Hosted (White labeled)",
        "plans": "Plans",
        "postal_city_state": "Postal/City/State",
        "postal_code": "Postal Code",
        "postmark_error": "There was a problem sending the email through Postmark: :link",
        "powered_by": "Powered by",
        "prefix": "Prefix",
        "present": "Present",
        "preview": "Preview",
        "primary_color": "Primary Color",
        "primary_user": "Primary User",
        "privacy_policy": "Privacy Policy",
        "private_notes": "Private Notes",
        "pro": "Pro",
        "pro_plan_calendar": ":link to enable the calendar by joining the Pro Plan",
        "pro_plan_month_description": "One month enrollment in the Coaching.com Pro Plan.",
        "pro_plan_remove_logo": ":link to remove the Coaching.com logo by joining the Pro Plan",
        "pro_plan_remove_logo_link": "Click here",
        "pro_plan_reports": ":link to enable reports by joining the Pro Plan",
        "pro_plan_title": "NINJA PRO",
        "pro_plan_year_description": "One year enrollment in the Coaching.com Pro Plan.",
        "processed_updates": "Successfully completed update",
        "product_fields": "Product Fields",
        "product_file": "Product File",
        "product_key": "Product",
        "product_settings": "Product Settings",
        "product_will_create": "product will be created",
        "products_will_create": "products will be created",
        "profile": "Profile",
        "profit": "Profit",
        "profit_and_loss": "Profit and Loss",
        "project": "Project",
        "projects": "Projects",
        "provide_email": "Please provide a valid email address",
        "provide_name_or_email": "Please provide a name or email",
        "public_key": "Public Key",
        "public_notes": "Public Notes",
        "publishable_key": "Publishable Key",
        "purge_data": "Purge Data",
        "purge_data_help": "Permanently delete all data but keep the account and settings.",
        "purge_data_message": "Warning: This will permanently erase your data, there is no undo.",
        "purge_details": "The data in your company (:account) has been successfully purged.",
        "purge_successful": "Successfully purged company data",
        "qty": "Quantity",
        "quantity": "Quantity",
        "quote_counter": "Quote Counter",
        "quote_design": "Quote Design",
        "quote_due_date": "Valid Until",
        "quote_footer": "Quote Footer",
        "quote_is_approved": "The quote has been approved",
        "quote_issued_to": "Quote issued to",
        "quote_message_button": "To view your quote for :amount, click the button below.",
        "quote_no": "Quote No.",
        "quote_not_emailed": "This quote hasn't been emailed.",
        "quote_number_help": "Specify a prefix or use a custom pattern to dynamically set the quote number.",
        "quote_settings": "Quote Settings",
        "quote_terms": "Quote Terms",
        "quote_to": "Quote to",
        "raw": "Raw",
        "raw_html": "Raw HTML",
        "recent_payments": "Recent Payments",
        "recipients": "Recipients",
        "recommend_off": "We recommend <b>disabling</b> this setting.",
        "recommend_on": "We recommend <b>enabling</b> this setting.",
        "recurring": "Recurring",
        "recurring_due_dates": "Recurring Invoice Due Dates",
        "recurring_hour": "Recurring Hour",
        "recurring_invoice": "Recurring Invoice",
        "recurring_invoice_number": "Recurring Number",
        "recurring_invoice_number_prefix_help": "Speciy a prefix to be added to the invoice number for recurring invoices.",
        "recurring_prefix": "Recurring Prefix",
        "recurring_too_soon": "It's too soon to create the next recurring invoice, it's scheduled for :date",
        "redirect_url": "Redirect URL",
        "redirect_url_help": "Optionally specify a URL to redirect to after a payment is entered.",
        "referral_code": "Referral URL",
        "referral_code_help": "Earn money by sharing our app online",
        "referral_program": "Referral Program",
        "refresh": "Refresh",
        "refund": "Refund",
        "refund_body": "You have been processed a refund of :amount for invoice :invoice_number.",
        "refund_max": "Max:",
        "refund_payment": "Refund Payment",
        "refund_subject": "Refund Processed",
        "refunded_credit_payment": "Refunded credit payment",
        "refunded_payment": "Refunded Payment",
        "reminder_emails": "Reminder Emails",
        "reminder_subject": "Reminder: Invoice :invoice from :account",
        "remove": "Remove",
        "remove_contact": "Remove contact",
        "remove_payment_method": "Remove Payment Method",
        "renew_license": "Renew License",
        "renews": "Renews",
        "reply_to_email": "Reply-To Email",
        "reply_to_email_help": "Specify the reply-to address for client emails.",
        "report": "Report",
        "report_settings": "Report Settings",
        "reports": "Reports",
        "reports_help": "Shift + Click to sort by multple columns, Ctrl + Click to clear the grouping.",
        "require": "Require",
        "require_invoice_signature": "Invoice Signature",
        "require_invoice_signature_help": "Require client to provide their signature.",
        "require_quote_signature": "Quote Signature",
        "require_quote_signature_help": "Require client to provide their signature.",
        "resend_confirmation_email": "Resend Confirmation Email",
        "resend_invite": "Resend invitation",
        "reset": "Reset",
        "reset_all": "Reset All",
        "reset_counter": "Reset Counter",
        "reset_footer": "Reset footer",
        "reset_footer_help": "Reset to the default account footer",
        "reset_password_footer": "If you did not request this password reset please email our support: :email",
        "reset_terms": "Reset terms",
        "reset_terms_help": "Reset to the default account terms",
        "restore": "Restore",
        "restore_account_gateway": "Restore Gateway",
        "restore_client": "Restore Client",
        "restore_expense": "Restore Expense",
        "restore_expense_category": "Restore expense category",
        "restore_product": "Restore Product",
        "restore_project": "Restore project",
        "restore_recurring_invoice": "Restore Recurring Invoice",
        "restore_task": "Restore Task",
        "restore_user": "Restore User",
        "restore_vendor": "Restore Vendor",
        "restored_account_gateway": "Successfully restored gateway",
        "restored_client": "Successfully restored client",
        "restored_expense": "Successfully restored expense",
        "restored_expense_category": "Successfully restored expense category",
        "restored_project": "Successfully restored project",
        "restored_recurring_invoice": "Successfully restored recurring invoice",
        "restored_task": "Successfully restored task",
        "restored_user": "Successfully restored user",
        "restored_vendor": "Successfully restored vendor",
        "resume": "Resume",
        "resume_task": "Resume Task",
        "resumed_task": "Successfully resumed task",
        "return_to_app": "Return To App",
        "revenue": "Revenue",
        "routing_number": "Routing Number",
        "rows": "rows",
        "run": "Run",
        "running": "Running",
        "sample_commands": "Sample commands",
        "saturday": "Saturday",
        "save": "Save",
        "save_as_default": "Save as default",
        "save_as_default_footer": "Save as default footer",
        "save_draft": "Save Draft",
        "save_or_discard": "Save or discard your changes",
        "schedule": "Schedule",
        "search": "Search",
        "search_company_name": "Search for a company name",
        "search_hotkey": "shortcut is /",
        "second": "Second",
        "second_reminder": "Second Reminder",
        "secondary_color": "Secondary Color",
        "seconds": "Seconds",
        "secret": "Secret",
        "secret_key": "Secret Key",
        "secured_by_plaid": "Secured by Plaid",
        "security": "Security",
        "security_confirmation": "Your email address has been confirmed.",
        "see_options": "See options",
        "see_whats_new": "See what's new in v:version",
        "select": "Select",
        "select_file": "Please select a file",
        "select_label": "Select Label",
        "selected": "Selected",
        "send_automatically": "Send Automatically",
        "send_email": "Send email",
        "send_invite": "Send invitation",
        "send_portal_password": "Generate Password Automatically",
        "send_portal_password_help": "If no password is set, one will be generated and sent with the first invoice.",
        "send_test_email": "Send test email",
        "sent": "Sent",
        "sent_by": "Sent by :user",
        "sent_invite": "Successfully sent invitation",
        "sent_message": "Successfully sent message",
        "september": "September",
        "service": "Service",
        "session_expired": "Your session has expired.",
        "set_limits": "Set :gateway_type Limits",
        "set_limits_fees": "Set :gateway_type Limits/Fees",
        "set_now": "Set to now",
        "settings": "Settings",
        "setup_account": "Setup Account",
        "should_be_invoiced": "Should be invoiced",
        "show_accept_invoice_terms": "Invoice Terms Checkbox",
        "show_accept_invoice_terms_help": "Require client to confirm that they accept the invoice terms.",
        "show_accept_quote_terms": "Quote Terms Checkbox",
        "show_accept_quote_terms_help": "Require client to confirm that they accept the quote terms.",
        "show_address": "Show Address",
        "show_address_help": "Require client to provide their billing address",
        "show_all_options": "Show all options",
        "show_archived_deleted": "Show archived/deleted",
        "show_archived_users": "Show archived users",
        "show_currency_code": "Currency Code",
        "show_deleted_users": "Show deleted users",
        "show_line_item_tax": "Display <b>line item taxes inline</b>",
        "sign_here": "Please sign here:",
        "sign_up": "Sign Up",
        "sign_up_now": "Sign Up Now",
        "sign_up_using": "Sign up using",
        "sign_up_with_wepay": "Sign up with WePay",
        "signature": "Email Signature",
        "signed": "Signed",
        "site_updates": "Site Updates",
        "sofort": "Sofort",
        "sort_by": "Sort By",
        "source": "Source",
        "standard_fees_apply": "Fee: 2.9%/1.2% [Credit Card/Bank Transfer] + $0.30 per successful charge.",
        "start": "Start",
        "start_date": "Start date",
        "start_date_required": "The start date is required",
        "start_of_week": "First Day of the Week",
        "start_of_week_help": "Used by <b>date</b> selectors",
        "start_time": "Start Time",
        "started_task": "Successfully started task",
        "state": "State/Province",
        "statement": "Statement",
        "statement_date": "Statement Date",
        "statement_issued_to": "Statement issued to",
        "statement_to": "Statement to",
        "status": "Status",
        "status_all": "All",
        "status_approved": "Approved",
        "status_completed": "Completed",
        "status_draft": "Draft",
        "status_failed": "Failed",
        "status_paid": "Paid",
        "status_partial": "Partial",
        "status_partially_refunded": "Partially Refunded",
        "status_partially_refunded_amount": ":amount Refunded",
        "status_pending": "Pending",
        "status_refunded": "Refunded",
        "status_sent": "Sent",
        "status_unpaid": "Unpaid",
        "status_viewed": "Viewed",
        "status_voided": "Canceled",
        "stop": "Stop",
        "stop_task": "Stop Task",
        "stopped_task": "Successfully stopped task",
        "stripe_ach_help": "ACH support must also be enabled in :link.",
        "stripe_alipay_help": "These gateways also need to be activated in :link.",
        "stripe_webhook_help": "You must :link.",
        "stripe_webhook_help_link_text": "add this URL as an endpoint at Stripe",
        "styles": "Styles",
        "subdomain": "Subdomain",
        "subdomain_help": "Set the subdomain or display the invoice on your own website.",
        "subject": "Subject",
        "submit": "Submit",
        "success": "Success",
        "sunday": "Sunday",
        "support": "Support",
        "support_forum": "Support Forum",
        "surcharge": "Surcharge",
        "surcharge_label": "Surcharge Label",
        "switch": "Switch",
        "switch_to_primary": "Switch to your primary company (:name) to manage your plan.",
        "switch_to_wepay": "Switch to WePay",
        "system": "System",
        "system_settings": "System Settings",
        "task": "Task",
        "task_details": "Task Details",
        "task_error_invoiced": "Tasks have already been invoiced",
        "task_error_multiple_clients": "The tasks can't belong to different clients",
        "task_error_running": "Please stop running tasks first",
        "task_errors": "Please correct any overlapping times",
        "task_file": "Task File",
        "tasks": "Tasks",
        "tax_invoice": "Tax Invoice",
        "tax_name": "Tax Name",
        "tax_quote": "Tax Quote",
        "tax_rate": "Tax Rate",
        "tax_rate_type_help": "Inclusive tax rates adjust the line item cost when selected.<br/>Only exclusive tax rates can be used as a default.",
        "tax_settings": "Tax Settings",
        "template": "Template",
        "template_help_1": "Available variables:",
        "template_help_title": "Templates Help",
        "templates_and_reminders": "Templates & Reminders",
        "terms_of_service": "Terms of Service",
        "text": "Text",
        "third_reminder": "Third Reminder",
        "this_month": "This Month",
        "this_year": "This Year",
        "thursday": "Thursday",
        "time": "Time",
        "time_tracker": "Time Tracker",
        "time_tracker_login": "Time Tracker Login",
        "timer": "Timer",
        "times": "Times",
        "timesheets": "Timesheets",
        "timezone": "Timezone",
        "timezone_default": "Computer Timezone",
        "timezone_unset": "Please :link to set your timezone",
        "to": "To",
        "toggle_history": "Toggle History",
        "toggle_menu": "Toggle Menu",
        "toggle_navigation": "Toggle Navigation",
        "token_billing_1": "Disabled",
        "token_billing_2": "Opt-in - checkbox is shown but not selected",
        "token_billing_3": "Opt-out - checkbox is shown and selected",
        "token_billing_4": "Always",
        "token_billing_braintree_paypal": "Save payment details",
        "token_billing_checkbox": "Store credit card details",
        "token_billing_help": "Store payment details with WePay, Stripe, Braintree or GoCardless.",
        "token_billing_type_id": "Token Billing",
        "token_expired": "Validation token was expired. Please try again.",
        "total-owed-ytd": "Total Owed (YTD)",
        "total-paid-ytd": "Total Paid (YTD)",
        "total_invoiced": "Total Invoiced",
        "total_profit": "Total Profit",
        "total_revenue": "Total Revenue",
        "totals": "Totals",
        "translate_app": "Help improve our translations with :link",
        "trello_roadmap": "Trello Roadmap",
        "trial_call_to_action": "Start Free Trial",
        "trial_expired": ":plan Plan Trial Ended",
        "trial_footer": "Your free pro plan trial lasts :count more days, :link to upgrade now.",
        "trial_footer_last_day": "This is the last day of your free pro plan trial, :link to upgrade now.",
        "trial_message": "Your account will receive a free two week trial of our pro plan.",
        "trial_success": "Successfully enabled two week free pro plan trial",
        "tuesday": "Tuesday",
        "type": "Type",
        "unassigned": "Unassigned",
        "united_states": "United States",
        "unknown_bank": "Unknown Bank",
        "unlink": "Unlink",
        "unlink_account": "Unlink Account",
        "unlinked_account": "Successfully unlinked accounts",
        "unnamed_client": "Unnamed Client",
        "unpaid": "Unpaid",
        "unsaved_changes": "You have unsaved changes",
        "untitled": "Untitled",
        "untitled_account": "Untitled Company",
        "upcoming_quotes": "Upcoming Quotes",
        "update": "Update",
        "update_address": "Update Address",
        "update_address_help": "Update client's address with provided details",
        "update_credit": "Update Credit",
        "update_font_cache": "Please force refresh the page to update the font cache.",
        "updated_bank_account": "Successfully updated bank account",
        "updated_credit": "Successfully updated credit",
        "updated_expense": "Successfully updated expense",
        "updated_expense_category": "Successfully updated expense category",
        "updated_gateway": "Successfully updated gateway",
        "updated_plan": "Updated plan settings",
        "updated_project": "Successfully updated project",
        "updated_settings": "Successfully updated settings",
        "updated_task": "Successfully updated task",
        "updated_tax_rate": "Successfully updated tax rate",
        "updated_user": "Successfully updated user",
        "upgrade_call_to_action": "Upgrade Now!",
        "upgrade_for_permissions": "Upgrade to our Enterprise plan to enable permissions.",
        "upload": "Upload",
        "upload-csv": "Upload CSV File",
        "url": "URL",
        "use_another_provider": "Use another provider",
        "use_bank_on_file": "Use Bank on File",
        "use_card_on_file": "Use Card on File",
        "use_client_terms": "Use client terms",
        "use_for_auto_bill": "Use For Autobill",
        "used_for_auto_bill": "Autobill Payment Method",
        "user": "User",
        "user_create_all": "Create clients, invoices, etc.",
        "user_details": "User Details",
        "user_edit_all": "Edit all clients, invoices, etc.",
        "user_email_footer": "To adjust your email notification settings please visit :link",
        "user_guide": "User Guide",
        "user_management": "User Management",
        "user_view_all": "View all clients, invoices, etc.",
        "username": "Username",
        "users": "Users",
        "valid_thru": "Valid\nthru",
        "valid_until": "Valid Until",
        "validate": "Validate",
        "validate_bank_account": "Validate Bank Account",
        "value": "Value",
        "vendor": "Vendor",
        "vendor_contacts": "Vendor Contacts",
        "vendor_name": "Vendor",
        "vendors": "Vendors",
        "verification_amount1": "Amount 1",
        "verification_amount2": "Amount 2",
        "verification_failed": "Verification Failed",
        "verification_pending": "Verification Pending",
        "verify_email": "Please visit the link in the account confirmation email to verify your email address.",
        "view": "View",
        "view_all_help": "Allow user to view records they didn't create",
        "view_client": "View Client",
        "view_client_portal": "View client portal",
        "view_dashboard": "View Dashboard",
        "view_documentation": "View Documentation",
        "view_expense": "View expense # :expense",
        "view_expense_num": "Expense # :expense",
        "view_history": "View History",
        "view_in_gateway": "View in :gateway",
        "view_payment": "View Payment",
        "view_portal": "View Portal",
        "view_statement": "View Statement",
        "view_vendor": "View Vendor",
        "viewer": "Viewer",
        "viewers": "Viewers",
        "voice_commands": "Voice Commands",
        "voice_commands_feedback": "We're actively working to improve this feature, if there's a command you'd like us to support please email us at :email.",
        "wait_for_upload": "Please wait for the document upload to complete.",
        "warn_change_auto_bill": "Due to NACHA rules, changes to this invoice may prevent ACH auto bill.",
        "warn_start_date_changed": "The next invoice will be sent on the new start date.",
        "warning": "Warning",
        "webhook_url": "Webhook URL",
        "website": "Website",
        "website_help": "Display the invoice in an iFrame on your own website",
        "website_url": "Website URL",
        "wednesday": "Wednesday",
        "week": "Week",
        "wepay": "WePay",
        "wepay_account_description": "Payment gateway for Coaching.com",
        "wepay_company_name_help": "This will appear on client's credit card statements.",
        "wepay_description_help": "The purpose of this account.",
        "wepay_payment_tos_agree": "I agree to the WePay :terms and :privacy_policy.",
        "wepay_payment_tos_agree_required": "You must agree to the WePay Terms of Service and Privacy Policy.",
        "wepay_tos_agree": "I agree to the :link.",
        "wepay_tos_link_text": "WePay Terms of Service",
        "what_are_you_working_on": "What are you working on?",
        "white_label_button": "White Label",
        "white_label_custom_css": ":link for $:price to enable custom styling and help support our project.",
        "white_label_license_key": "License Key",
        "white_label_purchase_link": "Purchase a white label license",
        "white_label_text": "Purchase a ONE YEAR white label license for $:price to remove the Coaching.com branding from the invoice and client portal.",
        "work": "Work",
        "work_phone": "Phone",
        "working": "Working",
        "wrong_confirmation": "Incorrect confirmation code",
        "www": "www",
        "year": "Year",
        "yes": "Yes",
        "your_statement": "Your Statement",
        "zapier": "Zapier",
        "zero-dollars": "$0"
      },
      "header_titles": {
        "account_managers": "Account Managers",
        "admin_resources": "Engagement Files",
        "progress_summary": "Progress Summary",
        "summary_info": "Summary Information"
      },
      "invoice": {
        "add_to_invoice": "Add to invoice",
        "amount_due": "Amount due",
        "amount_paid": "Amount paid",
        "amount_received": "Amount received",
        "archive_recurring_invoice": "Archive recurring invoice",
        "archived_invoice": "Invoice archived successfully",
        "archived_invoices": ":count invoice(s) archived successfully",
        "archived_recurring_invoice": "Invoice archived successfully",
        "balance": "Balance",
        "cloned_invoice": "Invoice cloned successfully",
        "create_new_invoice": "Create new invoice",
        "created_by_invoice": "Created by :invoice",
        "created_invoice": "Invoice created successfully",
        "created_invoices": ":count invoice(s) created successfully",
        "custom_invoice_link": "Custom invoice link",
        "default_invoice_footer": "Default invoice footer",
        "default_invoice_terms": "Default invoice terms",
        "delete_recurring_invoice": "Delete recurring invoice",
        "deleted_invoice": "Invoice deleted successfully",
        "deleted_invoices": ":count invoices deleted successfully",
        "deleted_recurring_invoice": "Recurring invoice deleted successfully",
        "discount_amount": "Discount amount",
        "discount_percent": "Discount percent",
        "emailed_invoice": "Invoice emailed",
        "invoice_amount": "Invoice amount",
        "invoice_charges": "Invoice charges",
        "invoice_counter": "Invoice counter",
        "invoice_design": "Invoice design",
        "invoice_due_date": "Invoice due date",
        "invoice_error": "Please make sure to select a client and correct any errors",
        "invoice_fields": "Invoice fields",
        "invoice_footer": "Invoice footer",
        "invoice_history": "Invoice history",
        "invoice_issued_to": "Invoice issued to",
        "invoice_labels": "Invoice Labels",
        "invoice_message": "To view your invoice for :amount, click the button below.",
        "invoice_no": "Invoice no",
        "invoice_not_found": "Invoice not found",
        "invoice_number_counter": "Invoice number counter",
        "invoice_number_help": "Specify a prefix or use a custom pattern to dynamically set the invoice number.",
        "invoice_number_prefix": "Invoice number prefix",
        "invoice_options": "Invoice options",
        "invoice_quote_number": "Invoice quote number",
        "invoice_sent": "Invoice sent",
        "invoice_settings": "Invoice settings",
        "invoice_task": "Invoice task",
        "invoice_to": "Invoice to",
        "invoiced": "Invoiced",
        "invoices_sent": ":count invoice(s) sent",
        "last_invoice_sent": "Last invoice sent on :date",
        "new_recurring_invoice": "New recurring invoice",
        "next_invoice_number": "Next invoice number is :number.",
        "outstanding": "Outstanding",
        "paid": "Paid",
        "partial": "Partial",
        "partial_remaining": ":partial of :balance",
        "payment_terms_net": "Net",
        "recurring": "Recurring",
        "recurring_invoice": "Recurring invoice",
        "recurring_too_soon": "Il est trop tôt pour créer la prochaine facture récurrente, prévue pour le :date",
        "restore_recurring_invoice": "Restore recurring invoice",
        "restored_recurring_invoice": "Restored recurring invoice",
        "should_be_invoiced": "Should be invoiced",
        "total_invoiced": "Total invoiced",
        "updated_invoice": "Updated invoice",
        "vat_number": "VAT number",
        "vat_percent": "VAT percent (%)",
        "view_invoice": "View invoices"
      },
      "location_info": {
        "address": "Address",
        "address1": "Street",
        "address2": "Apt/Suite",
        "city": "City",
        "country": "Country",
        "postal_code": "Postal Code",
        "state": "State/Province"
      },
      "pagination": {
        "next": "Next &raquo;",
        "previous": "&laquo; Previous"
      },
      "passwords": {
        "password": "Passwords must be at least six characters and match the confirmation.",
        "reset": "Your password has been reset!",
        "sent": "We have emailed your password reset link!",
        "token": "This password reset token is invalid.",
        "user": "We can't find a user with that email address."
      },
      "quote": {
        "archive_quote": "Archiver ce devis",
        "archived_quote": "Devis archivé",
        "archived_quotes": ":count devis ont bien été archivés",
        "clone_quote": "Dupliquer ce devis",
        "cloned_quote": "Devis dupliqué",
        "confirm_email_quote": "Voulez-vous vraiment envoyer ce devis par courriel ?",
        "convert_to_invoice": "Convertir en facture",
        "converted_to_invoice": "Le devis a bien été converti en facture",
        "create_quote": "Créer un devis",
        "created_quote": "Devis créé",
        "default_quote_terms": "Conditions des devis par défaut",
        "delete_quote": "Supprimer ce devis",
        "deleted_quote": "Devis supprimé",
        "deleted_quotes": ":count devis ont bien été supprimés",
        "edit_quote": "Éditer ce devis",
        "email_quote": "Envoyer ce devis par courriel",
        "emailed_quote": "Devis envoyé par courriel",
        "expired_quotes": "Devis expirés",
        "new_quote": "Nouveau devis",
        "quote": "Devis",
        "quote_counter": "Compteur de devis",
        "quote_date": "Date du devis",
        "quote_due_date": "Date limite",
        "quote_email": "Email de devis",
        "quote_footer": "Pied de page des devis",
        "quote_history": "Historique des devis",
        "quote_is_approved": "Ce devis a été approuvé",
        "quote_link_message": "Pour visionner votre soumission, cliquez sur le lien ci-dessous:",
        "quote_message": "Pour visionner votre devis de :amount, cliquez sur le lien ci-dessous.",
        "quote_no": "Devis n°",
        "quote_number": "Devis numéro",
        "quote_number_counter": "Compteur du numéro de devis",
        "quote_number_help": "Spécifier un préfixe ou utiliser un modèle personnalisé pour la création du numéro de devis.",
        "quote_number_prefix": "Préfixe du numéro de devis",
        "quote_number_short": "Devis #",
        "quote_subject": "New quote :number from :account",
        "quote_terms": "Conditions des devis",
        "quote_total": "Quote total",
        "quotes": "Quotes",
        "save_quote": "Save quote",
        "upcoming_quotes": "Upcoming quotes",
        "updated_quote": "Updated quote",
        "view_quote": "View quote",
        "your_quote": "Your quote"
      },
      "site_objects": {
        "client": "Client",
        "competency_library": "Theme Library",
        "contacts": "Contacts",
        "engagement_template": "Program Template",
        "invoice": "Invoice",
        "organization": "Organization",
        "untitled_library": "Untitled theme library"
      },
      "statuses": {
        "active": "Active",
        "approved": "Approved",
        "assigned": "Assigned",
        "awaiting_approval": "Awaiting Approval",
        "cancelled": "Canceled",
        "completed": "Completed",
        "deleted": "Deleted",
        "draft": "Draft",
        "in_review": "In Review",
        "internal_record": "Internal Record",
        "invited": "Invite Sent",
        "paid": "Paid",
        "partial": "Partial",
        "pending": "Pending",
        "scheduled": "Scheduled",
        "sent": "Sent",
        "status": "Status",
        "unpaid": "Unpaid",
        "viewed": "Viewed"
      },
      "user_data": {
        "email": "Email",
        "first_name": "First Name",
        "last_name": "Last Name",
        "phone": "Phone",
        "work_email": "Email",
        "work_phone": "Phone"
      },
      "validation": {
        "accepted": "The :attribute must be accepted.",
        "active_url": "The :attribute is not a valid URL.",
        "after": "The :attribute must be a date after :date.",
        "after_or_equal": "The :attribute must be a date after or equal to :date.",
        "alpha": "The :attribute may only contain letters.",
        "alpha_dash": "The :attribute may only contain letters, numbers, and dashes.",
        "alpha_num": "The :attribute may only contain letters and numbers.",
        "array": "The :attribute must be an array.",
        "before": "The :attribute must be a date before :date.",
        "before_or_equal": "The :attribute must be a date before or equal to :date.",
        "between": {
          "array": "The :attribute must have between :min and :max items.",
          "file": "The :attribute must be between :min and :max kilobytes.",
          "numeric": "The :attribute must be between :min and :max.",
          "string": "The :attribute must be between :min and :max characters."
        },
        "boolean": "The :attribute field must be true or false.",
        "confirmed": "The :attribute confirmation does not match.",
        "date": "The :attribute is not a valid date.",
        "date_format": "The :attribute does not match the format :format.",
        "different": "The :attribute and :other must be different.",
        "digits": "The :attribute must be :digits digits.",
        "digits_between": "The :attribute must be between :min and :max digits.",
        "dimensions": "The :attribute has invalid image dimensions.",
        "distinct": "The :attribute field has a duplicate value.",
        "email": "The :attribute must be a valid email address.",
        "exists": "The selected :attribute is invalid.",
        "file": "The :attribute must be a file.",
        "filled": "The :attribute field must have a value.",
        "image": "The :attribute must be an image.",
        "in": "The selected :attribute is invalid.",
        "in_array": "The :attribute field does not exist in :other.",
        "integer": "The :attribute must be an integer.",
        "ip": "The :attribute must be a valid IP address.",
        "ipv4": "The :attribute must be a valid IPv4 address.",
        "ipv6": "The :attribute must be a valid IPv6 address.",
        "json": "The :attribute must be a valid JSON string.",
        "max": {
          "array": "The :attribute may not have more than :max items.",
          "file": "The :attribute may not be greater than :max kilobytes.",
          "numeric": "The :attribute may not be greater than :max.",
          "string": "The :attribute may not be greater than :max characters."
        },
        "mimes": "The :attribute must be a file of type: :values.",
        "mimetypes": "The :attribute must be a file of type: :values.",
        "min": {
          "array": "The :attribute must have at least :min items.",
          "file": "The :attribute must be at least :min kilobytes.",
          "numeric": "The :attribute must be at least :min.",
          "string": "The :attribute must be at least :min characters."
        },
        "not_in": "The selected :attribute is invalid.",
        "numeric": "The :attribute must be a number.",
        "present": "The :attribute field must be present.",
        "regex": "The :attribute format is invalid.",
        "required": "The :attribute field is required.",
        "required_if": "The :attribute field is required when :other is :value.",
        "required_unless": "The :attribute field is required unless :other is in :values.",
        "required_with": "The :attribute field is required when :values is present.",
        "required_with_all": "The :attribute field is required when :values is present.",
        "required_without": "The :attribute field is required when :values is not present.",
        "required_without_all": "The :attribute field is required when none of :values are present.",
        "same": "The :attribute and :other must match.",
        "size": {
          "array": "The :attribute must contain :size items.",
          "file": "The :attribute must be :size kilobytes.",
          "numeric": "The :attribute must be :size.",
          "string": "The :attribute must be :size characters."
        },
        "string": "The :attribute must be a string.",
        "timezone": "The :attribute must be a valid zone.",
        "unique": "The :attribute has already been taken.",
        "uploaded": "The :attribute failed to upload.",
        "url": "The :attribute format is invalid."
      },
      "vendor": {
        "archived_vendor": "Le fournisseur a été archivé",
        "archived_vendors": ":count fournisseurs archivés",
        "created_vendor": "Le fournisseur a été créé",
        "deleted_vendor": "Le fournisseur a été supprimé",
        "deleted_vendors": ":count fournisseurs supprimés",
        "updated_vendor": "Le fournisseur a été mis à jour",
        "vendor": "Vendor"
      }
    },
    "icon": {
      "add": "add",
      "chat": "sms",
      "company": "business",
      "complete": "done",
      "completed": "done",
      "dashboard": "network_check",
      "delete": "delete",
      "doc": "description",
      "download": "file_download",
      "edit": "edit",
      "engagement": "flag",
      "engagement-milestone": "flag",
      "engagement-session": "people",
      "feedback": "send",
      "file": "description",
      "form": "assignment_turned_in",
      "goal": "my_location",
      "image": "image",
      "info": "info",
      "interaction": "people",
      "invoice": "inbox",
      "late-cancel": "close",
      "library": "account_balance",
      "manage-people": "person_add",
      "milestone": "flag",
      "multiple-choice": "check_box",
      "next-steps": "check_box",
      "note": "assignment",
      "notifications": "notifications",
      "pdf": "description",
      "people": "person",
      "ppt": "personal_video",
      "preview": "open_in_new",
      "profile": "account_circle",
      "program": "format_list_bulleted",
      "rate": "rate_review",
      "report": "assessment",
      "request-schedule": "alarm",
      "reschedule": "update",
      "resources": "account_balance",
      "roadmap": "flag",
      "schedule": "date_range",
      "scheduled": "date_range",
      "send": "send",
      "send-request-to-schedule": "flash_on",
      "sent": "send",
      "settings": "settings",
      "share": "share",
      "single-choice": "radion_button_checked",
      "stripe": "local_atm",
      "support": "help",
      "survey": "assignment_turned_in",
      "task": "check_box",
      "text-field": "text-fields",
      "visibility": "visibility",
      "watch-later": "watch_later",
      "xls": "view_list"
    },
    "index": {
      "credit-card-expiring": {
        "button": "Update",
        "message": "Hi {user}, it looks like the payment associated with your account will expire on {date}. Please update the payment information to avoid account inactivation."
      },
      "deliquent-account": {
        "button": "Update",
        "message": "Hi {user}, it looks like there were some issues processing your payment. Your account is now delinquent. Please update your payment method by (2 weeks from the start of delinquency) to avoid account inactivation."
      },
      "frozen-account": {
        "button": "Update",
        "dialog": {
          "admin-message": "Your last payment is past due. There was a problem processing your card. Please update your payment method.",
          "others-message": "Your account is delinquent. Please ask an administrator to update the payment method.",
          "title": "Frozen account"
        },
        "message": "Hi {user}, it looks like there were some issues processing your payment. Your account is now delinquent; all active engagements have temporarily been made inactive. To revert this change please update your payment method."
      },
      "mobile": {
        "account": "Account",
        "messages": "Messages",
        "notifications": "Notifications"
      },
      "mobile-app": {
        "description": "<strong>Coaching.com</strong> provides an all-in-one platform for coaches and their clients to manage their coaching relationships. From videoconferencing and chat to invoicing and document sharing, this app makes it easy to get the most out of coaching relationships.",
        "go-to-app-store": "Go to app store"
      },
      "nav": {
        "chat": "Chat Support",
        "clients": "Clients",
        "coach-matching": "Coach matching",
        "coaches": "Coaches",
        "company-resources": "Resources",
        "dashboard": "Dashboard",
        "employees": "Employees",
        "engagements": "Engagements",
        "help": "Help",
        "invite-members": "Add employees",
        "invoices": "Invoices",
        "logout": "Logout",
        "my-profile": "My Profile",
        "notifications": "Notifications",
        "open-support": "Support Portal",
        "personal-settings": "My Settings",
        "profile": "Profile",
        "reports": "Reports",
        "resources": "Resources",
        "settings": "Company Settings",
        "support": "Support",
        "switch-account": "Switch Account",
        "switch-context": "Switch company context",
        "vendors": "Vendors"
      },
      "notifications": {
        "alerts": "Alerts",
        "invitations": "Invitations",
        "settings": "Notification settings"
      }
    },
    "invoice": {
      "add-expense": "Add expense",
      "add-payment": "Add payment",
      "add-session": "Add session",
      "add_documents_to_invoice": "Add documents to invoice",
      "add_new_session": "Add new session",
      "adjustment": "Adjustment",
      "already_paid": "This invoice has already been paid.",
      "amount": "Amount",
      "amount_paid": "Amount paid",
      "and_created_client": "and created client",
      "applied_payment": "Successfully applied payment",
      "archive_invoice": "Archive Invoice",
      "archived_credit": "Successfully archived credit",
      "archived_credits": "Successfully archived :count credits",
      "archived_invoice": "Successfully archived invoice",
      "archived_invoices": "Successfully archived :count invoices",
      "archived_payment": "Successfully archived payment",
      "archived_payments": "Successfully archived :count payments",
      "auto_bill_failed": "Auto-billing for invoice :invoice_number failed",
      "average_invoice": "Average Invoice",
      "balance": "Balance",
      "balance_due": "Balance Due",
      "bill-to": "Bill to",
      "billed": "Billed",
      "billed_client": "billed client",
      "billed_clients": "billed clients",
      "billing-method": {
        "billable_hours": "Billable hours",
        "unit_cost": "Unit cost"
      },
      "charge_late_fee": "Charge Late Fee",
      "charge_taxes": "Charge taxes",
      "choose_credit_card": "Choose a credit card",
      "clone_invoice": "Clone Invoice",
      "cloned_invoice": "Successfully cloned invoice",
      "collect-via-stripe": "Would you like to collect payment via Stripe?",
      "collect_via_stripe": "Would you like to collect payment via Stripe?",
      "confirm_delete_invoice": "Are you sure you want to delete this invoice?",
      "confirm_email_invoice": "Are you sure you want to email this invoice?",
      "confirm_send_invoice": "Are you sure you want to send this invoice to",
      "cost": "Cost",
      "create_credit_note": "Create Credit Note",
      "create_invoice": "Create Invoice",
      "created_credit": "Successfully created credit",
      "created_invoice": "Successfully created invoice",
      "created_payment": "Successfully created payment",
      "created_payment_and_credit": "Successfully created payment and credit",
      "created_payment_and_credit_emailed_client": "Successfully created payment and credit, and emailed client",
      "created_payments": "Successfully created :count payment(s)",
      "credit": "Credit",
      "credit_amount": "Credit Amount",
      "credit_balance": "Credit Balance",
      "credit_card": {
        "card_number": "Card Number",
        "cvv": "CVV",
        "expiration_month": "Expiration Month",
        "expiration_year": "Expiration Year",
        "update_credit_card": "Update Credit Card",
        "update_payment_details": "Update payment details",
        "updated_payment_details": "Successfully updated payment details"
      },
      "credit_created_by": "Credit created by payment :transaction_reference",
      "credit_date": "Credit Date",
      "credit_issued_to": "Credit issued to",
      "credit_note": "Credit Note",
      "credit_number": "Credit Number",
      "credit_number_help": "Specify a prefix or use a custom pattern to dynamically set the credit number for negative invoices.",
      "credit_to": "Credit to",
      "credit_total": "Credit Total",
      "credits": "Credits",
      "currencies": {
        "argentine_peso": "Argentine Peso",
        "aruban_florin": "Aruban Florin",
        "australian_dollar": "Australian Dollar",
        "bangladeshi_taka": "Bangladeshi Taka",
        "brazilian_real": "Brazilian Real",
        "british_pound": "British Pound",
        "bulgarian_lev": "Bulgarian Lev",
        "canadian_dollar": "Canadian Dollar",
        "chilean_peso": "Chilean Peso",
        "chinese_renminbi": "Chinese Renminbi",
        "colombian_peso": "Colombian Peso",
        "costa_rican_colon": "Costa Rican Colón",
        "croatian_kuna": "Croatian Kuna",
        "czech_koruna": "Czech Koruna",
        "danish_krone": "Danish Krone",
        "dominican_peso": "Dominican Peso",
        "east_caribbean_dollar": "East Caribbean Dollar",
        "egyptian_pound": "Egyptian Pound",
        "euro": "Euro",
        "ghanaian_cedi": "Ghanaian Cedi",
        "guatemalan_quetzal": "Guatemalan Quetzal",
        "hong_kong_dollar": "Hong Kong Dollar",
        "icelandic_krona": "Icelandic Króna",
        "indian_rupee": "Indian Rupee",
        "indonesian_rupiah": "Indonesian Rupiah",
        "israeli_shekel": "Israeli Shekel",
        "japanese_yen": "Japanese Yen",
        "jordanian_dinar": "Jordanian Dinar",
        "kenyan_shilling": "Kenyan Shilling",
        "macanese_pataca": "Macanese Pataca",
        "malaysian_ringgit": "Malaysian Ringgit",
        "maldivian_rufiyaa": "Maldivian Rufiyaa",
        "mexican_peso": "Mexican Peso",
        "mozambican_metical": "Mozambican Metical",
        "myanmar_kyat": "Myanmar Kyat",
        "namibian_dollar": "Namibian Dollar",
        "netherlands_antillean_guilder": "Netherlands Antillean Guilder",
        "new_zealand_dollar": "New Zealand Dollar",
        "nigerian_naira": "Nigerian Naira",
        "norske_kroner": "Norske Kroner",
        "omani_rial": "Omani Rial",
        "pakistani_rupee": "Pakistani Rupee",
        "papua_new_guinean_kina": "Papua New Guinean Kina",
        "peruvian_sol": "Peruvian Sol",
        "philippine_peso": "Philippine Peso",
        "polish_zloty": "Polish Zloty",
        "romanian_new_leu": "Romanian New Leu",
        "russian_ruble": "Russian Ruble",
        "rwandan_franc": "Rwandan Franc",
        "saudi_riyal": "Saudi Riyal",
        "singapore_dollar": "Singapore Dollar",
        "south_african_rand": "South African Rand",
        "sri_lankan_rupee": "Sri Lankan Rupee",
        "swedish_krona": "Swedish Krona",
        "swiss_franc": "Swiss Franc",
        "taiwan_new_dollar": "Taiwan New Dollar",
        "tanzanian_shilling": "Tanzanian Shilling",
        "thai_baht": "Thai Baht",
        "trinidad_and_tobago_dollar": "Trinidad and Tobago Dollar",
        "tunisian_dinar": "Tunisian Dinar",
        "turkish_lira": "Turkish Lira",
        "ukrainian_hryvnia": "Ukrainian Hryvnia",
        "united_arab_emirates_dirham": "United Arab Emirates Dirham",
        "uruguayan_peso": "Uruguayan Peso",
        "us_dollar": "US Dollar",
        "vietnamese_dong": "Vietnamese Dong",
        "west_african_franc": "West African Franc"
      },
      "currency": "Currency",
      "date": "Date",
      "delete": "Delete",
      "delete-invoice-confirmation": "Are you sure you want to delete this invoice?",
      "delete-payment-confirmation": "Are you sure you want to delete this payment?",
      "delete-selected": "Delete selected",
      "delete_invoice": "Delete Invoice",
      "delete_selected": "Delete selected",
      "deleted_credit": "Successfully deleted credit",
      "deleted_credits": "Successfully deleted :count credits",
      "deleted_invoice": "Successfully deleted invoice",
      "deleted_invoices": "Successfully deleted :count invoices",
      "deleted_payment": "Successfully deleted payment",
      "deleted_payments": "Successfully deleted :count payments",
      "direct_invoice": "Direct Invoice",
      "discount": "Discount",
      "discount_amount": "Amount",
      "discount_percent": "Percent",
      "download-pdf": "Download PDF",
      "download_invoice": "Download Invoice",
      "download_quote": "Download Quote",
      "downloaded_invoice": "An email will be sent with the invoice PDF",
      "downloaded_invoices": "An email will be sent with the invoice PDFs",
      "downloaded_quote": "An email will be sent with the quote PDF",
      "downloaded_quotes": "An email will be sent with the quote PDFs",
      "due-date": "Due date",
      "edit_invoice": "Edit Invoice",
      "edit_payment": "Edit Payment",
      "email_invoice": "Email Invoice",
      "email_paid": "Email me when an invoice is <b>paid</b>",
      "email_sent": "Email me when an invoice is <b>sent</b>",
      "email_viewed": "Email me when an invoice is <b>viewed</b>",
      "emailed_invoice": "Successfully emailed invoice",
      "enable_invoice_tax": "Enable specifying an <b>invoice tax</b>",
      "enable_line_item_tax": "Enable specifying <b>line item taxes</b>",
      "enter_credit": "Enter Credit",
      "enter_payment": "Enter Payment",
      "error": "An error ocurred. Please check for errors on invoice.",
      "expenses": {
        "clone_expense": "Clone Expense",
        "created_expenses": "Successfully created :count expense(s)",
        "duplicate_expense_warning": "Warning: This :link may be a duplicate",
        "expense_category": "Expense Category",
        "expense_link": "expense",
        "expense_will_create": "expense will be created",
        "expenses_will_create": "expenses will be created",
        "total_expenses": "Total Expenses"
      },
      "fee_help": "Gateway fees are the costs charged for access to the financial networks that handle the processing of online payments.",
      "frequency": "Frequency",
      "frequency_alt": "How Often",
      "gateway": "Gateway",
      "gateway_help_1": ":link to sign up for Authorize.net.",
      "gateway_help_17": ":link to get your PayPal API signature.",
      "gateway_help_2": ":link to sign up for Authorize.net.",
      "gateway_help_23": ":link to get your Stripe API keys.",
      "include_item_taxes_inline": "Include <b>line item taxes in line total</b>",
      "invoice-number": "Invoice Number",
      "invoice-number-short": "Invoice #",
      "invoice-to": "Invoice to",
      "invoice_date": "Invoice Date",
      "invoice_design": "Design",
      "invoice_error": "Please make sure to select a client and correct any errors",
      "invoice_fields": "Invoice Fields",
      "invoice_history": "Invoice History",
      "invoice_issued_to": "Invoice issued to",
      "invoice_link_message": "To view the invoice click the link below:",
      "invoice_number_counter": "Invoice Number Counter",
      "invoice_number_prefix": "Invoice Number Prefix",
      "invoice_options": "Invoice Options",
      "invoice_sent_error": "There was an error while sending the invoice.",
      "invoice_sent_successfully": "Invoice sent successfully.",
      "invoice_settings": "Invoice Settings",
      "invoice_terms": "Invoice Terms",
      "invoice_total": "Invoice Total",
      "invoices": "Invoices",
      "invoices_are_attached": "Your invoice PDFs are attached.",
      "issued-date": "Issued date",
      "item": "Item",
      "late_fee_added": "Late fee added on :date",
      "late_fee_amount": "Late Fee Amount",
      "late_fee_percent": "Late Fee Percent",
      "line-item": "Line item",
      "line_item": "Line item",
      "line_total": "Line Total",
      "list": {
        "amount": "Amount",
        "description": "Description",
        "number": "#",
        "taxable": "Taxable"
      },
      "mark-paid": "Mark paid",
      "mark-unpaid": "Mark unpaid",
      "mark_billable": "Mark billable",
      "mark_sent": "Mark Sent",
      "method": "Method",
      "minutes": "minutes",
      "muliplication_sign": "x",
      "new_credit": "Enter Credit",
      "new_invoice": "New Invoice",
      "new_payment": "Enter Payment",
      "no-items-in-invoice": "There are no items added to this invoice.",
      "no-payments": "No payments",
      "no_session": "No invoiceable sessions available.",
      "note_to_client": "Note to Client",
      "notes": "Notes",
      "notification_invoice_paid": "A payment of :amount was made by client :client towards Invoice :invoice.",
      "notification_invoice_paid_subject": "Invoice :invoice was paid by :client",
      "notification_invoice_sent": "The following client :client was emailed Invoice :invoice for :amount.",
      "notification_invoice_sent_subject": "Invoice :invoice was sent to :client",
      "notification_invoice_viewed": "The following client :client viewed Invoice :invoice for :amount.",
      "notification_invoice_viewed_subject": "Invoice :invoice was viewed by :client",
      "number": "Invoice {number}",
      "online_payments": "Online Payments",
      "paid_to_date": "Paid to Date",
      "partial": "Partial/Deposit",
      "partial_remaining": ":partial of :balance",
      "partials": {
        "asked-to-pay": "You were asked to pay",
        "has-sent-invoice": "has sent you an invoice due on"
      },
      "pay-now": "Pay Now",
      "pay-tooltip": "Pay",
      "pay_with_card": "Pay with card",
      "payment": "Payment",
      "payment-date": "Payment date",
      "payment_amount": "Payment Amount",
      "payment_created_successfully": "Payment created successfully.",
      "payment_date": "Payment Date",
      "payment_error": "There was an error processing your payment. Please try again later.",
      "payment_gateway": "Payment Gateway",
      "payment_method": "Payment method",
      "payment_terms": {
        "archived_payment_term": "Successfully archived payment term",
        "created_payment_term": "Successfully created payment term",
        "updated_payment_term": "Successfully updated payment term"
      },
      "payment_type": "Payment Type",
      "payment_types": {
        "bitcoin": "Bitcoin",
        "credit_card": "Credit Card",
        "paypal": "PayPal"
      },
      "payments": "Payments",
      "pdf_email_attachment": "Attach Invoice",
      "percent_sign": "%",
      "po-code": "PO Code",
      "po_number_short": "PO #",
      "products": {
        "add_item": "Add Item",
        "archive_product": "Archive Product",
        "archived_product": "Successfully archived product",
        "archived_products": "Successfully archived :count products",
        "create_product": "Add Product",
        "created_product": "Successfully created product",
        "delete_product": "Delete product",
        "deleted_product": "Successfully deleted product",
        "deleted_products": "Successfully deleted :count products",
        "edit_product": "Edit Product",
        "fill_products": "Auto-fill products",
        "fill_products_help": "Selecting a product will automatically <b>fill in the description and cost</b>",
        "item_cost": "Item Cost",
        "item_notes": "Item Notes",
        "item_product": "Item Product",
        "item_quantity": "Item Quantity",
        "item_tax1": "Item Tax1",
        "item_tax2": "Item Tax2",
        "item_tax_name": "Item Tax Name",
        "item_tax_rate": "Item Tax Rate",
        "list_products": "List Products",
        "product": "Product",
        "product_library": "Product Library",
        "product_notes": "Product Notes",
        "products": "Products",
        "restored_product": "Successfully restored product",
        "update_products": "Auto-update products",
        "update_products_help": "Updating an invoice will automatically <b>update the product library</b>",
        "updated_product": "Successfully updated product"
      },
      "quantity": "Quantity",
      "quotes": {
        "archive_quote": "Archive Quote",
        "archived_quote": "Successfully archived quote",
        "archived_quotes": "Successfully archived :count quotes",
        "clone_quote": "Clone Quote",
        "cloned_quote": "Successfully cloned quote",
        "confirm_email_quote": "Are you sure you want to email this quote?",
        "convert_to_invoice": "Convert to Invoice",
        "converted_to_invoice": "Successfully converted quote to invoice",
        "create_quote": "Create Quote",
        "created_quote": "Successfully created quote",
        "delete_quote": "Delete Quote",
        "deleted_quote": "Successfully deleted quote",
        "deleted_quotes": "Successfully deleted :count quotes",
        "edit_quote": "Edit Quote",
        "email_approved": "Email me when a quote is <b>approved</b>",
        "email_quote": "Email Quote",
        "emailed_quote": "Successfully emailed quote",
        "new_quote": "New Quote",
        "notification_quote_approved": "The following client :client approved Quote :invoice for :amount.",
        "notification_quote_approved_subject": "Quote :invoice was approved by :client",
        "notification_quote_sent": "The following client :client was emailed Quote :invoice for :amount.",
        "notification_quote_sent_subject": "Quote :invoice was sent to :client",
        "notification_quote_viewed": "The following client :client viewed Quote :invoice for :amount.",
        "notification_quote_viewed_subject": "Quote :invoice was viewed by :client",
        "quote": "Quote",
        "quote_date": "Quote Date",
        "quote_history": "Quote History",
        "quote_link_message": "To view your client quote click the link below:",
        "quote_message": "To view your quote for :amount, click the link below.",
        "quote_number": "Quote Number",
        "quote_number_counter": "Quote Number Counter",
        "quote_number_prefix": "Quote Number Prefix",
        "quote_number_short": "Quote #",
        "quote_subject": "New quote :number from :account",
        "quote_total": "Quote Total",
        "quotes": "Quotes",
        "restore_quote": "Restore Quote",
        "restored_quote": "Successfully restored quote",
        "save_quote": "Save Quote",
        "updated_quote": "Successfully updated quote",
        "view_quote": "View Quote",
        "your_quote": "Your Quote"
      },
      "rate": "Rate",
      "recurring": {
        "archive_recurring_expense": "Archive Recurring Expense",
        "archived_recurring_expense": "Successfully archived recurring expense",
        "confirm_recurring_email_invoice": "Are you sure you want this invoice emailed?",
        "created_recurring_expense": "Successfully created recurring expense",
        "delete_recurring_expense": "Delete Recurring Expense",
        "deleted_recurring_expense": "Successfully deleted project",
        "edit_recurring_expense": "Edit Recurring Expense",
        "list_recurring_expense": "List Recurring Expenses",
        "new_recurring_expense": "New Recurring Expense",
        "recurring_expense": "Recurring Expense",
        "recurring_expenses": "Recurring Expenses",
        "restore_recurring_expense": "Restore Recurring Expense",
        "restored_recurring_expense": "Successfully restored recurring expense",
        "updated_recurring_expense": "Successfully updated recurring expense",
        "view_recurring_expense": "View Recurring Expense"
      },
      "reset_counter_help": "Automatically reset the invoice and quote counters.",
      "restore_credit": "Restore Credit",
      "restore_invoice": "Restore Invoice",
      "restore_payment": "Restore Payment",
      "restored_credit": "Successfully restored credit",
      "restored_invoice": "Successfully restored invoice",
      "restored_payment": "Successfully restored payment",
      "save_as_default_terms": "Save as default terms",
      "save_for_later": "Save for later",
      "save_invoice": "Save Invoice",
      "secure_payment": "Secure Payment",
      "select_engagement": "Select an engagement",
      "select_session": "Select session",
      "select_tax": "Select tax",
      "send-again": "Send again",
      "send-invoice": "Send invoice",
      "send-invoice-confirmation": "Are you sure you want to send this invoice to <strong>{client}</strong>",
      "send_invoice": "Send invoice",
      "standing": "Standing",
      "stripe": "Stripe",
      "subtotal": "Subtotal",
      "success": "Invoice saved successfully.",
      "tax": "Tax",
      "tax-approach": "Tax approach",
      "tax1": "First Tax",
      "tax2": "Second Tax",
      "tax_rates": "Tax Rates",
      "taxable-amount": "Taxable amount",
      "taxes": "Taxes",
      "taxes-applied": "Taxes applied",
      "taxes-dialog": {
        "edit-title": "Edit tax",
        "new-title": "Add new tax"
      },
      "taxes_and_fees": "Taxes and fees",
      "terms": "Payment Terms",
      "title": "Invoice",
      "total": "Total",
      "transaction_reference": "Transaction Reference",
      "unit_cost": "Unit Cost",
      "untitled_session": "Untitled session",
      "upcoming_invoices": "Upcoming Invoices",
      "updated_invoice": "Successfully updated invoice",
      "updated_payment": "Successfully updated payment",
      "vat": "VAT",
      "vat-applied": "VAT applied",
      "vat_number": "VAT Number",
      "vendors": {
        "archived_vendor": "Successfully archived vendor",
        "archived_vendors": "Successfully archived :count vendors",
        "created_vendor": "Successfully created vendor",
        "deleted_vendor": "Successfully deleted vendor",
        "deleted_vendors": "Successfully deleted :count vendors",
        "updated_vendor": "Successfully updated vendor"
      },
      "view_invoice": "View Invoice",
      "your_credit": "Your Credit",
      "your_invoice": "Your Invoice"
    },
    "invoices": {
      "amount-outstanding": "Amount Outstanding",
      "amount-received": "Amount Received",
      "delete-invoice-confirmation": "Are you sure you want to remove this invoice?",
      "delete-invoice-title": "Delete invoice",
      "delete-tooltip": "Delete",
      "loading": "Loading invoices...",
      "no-invoices": "No invoices",
      "no-vat-message": "You need to configure your invoice settings before adding VAT.",
      "search": "Search Invoices",
      "table": {
        "headers": {
          "actions": "Actions",
          "amount": "Amount",
          "balance": "Balance",
          "client": "Client",
          "company": "Company",
          "created-date": "Created date",
          "due": "Due",
          "issued": "Issued",
          "number": "Invoice #",
          "paid": "Paid",
          "status": "Status",
          "total-paid": "Total paid",
          "vendor": "Vendor"
        }
      },
      "tabs": {
        "received": "Received",
        "sent": "Sent"
      },
      "title": "Invoices",
      "total-invoiced": "Total Invoiced",
      "total-invoices": "Total Invoices"
    },
    "marketplace": {
      "coach-list": {
        "add-filter": "Add filter",
        "apply": "Apply",
        "clear": "Clear",
        "empty-state": "We couldn't find any profiles that match your criteria.",
        "filtered-by": "Filter by",
        "filters": "Filters",
        "read-more": "read more",
        "search-placeholder": "Search for your coach...",
        "see-more": "see more",
        "view-profile": "view profile"
      },
      "find-coach": "Find a Coach",
      "get-started": "Get Started",
      "interview-already-scheduled": "You have already scheduled an interview with this coach.",
      "login": "Login",
      "my-marketplace": {
        "interviews": "Interviews",
        "orders": "Orders"
      },
      "not-allowed-error": "Only Independent Coachees can currently perform this action. If you would like to proceed Sign Up or Log In as an Independent Coachee.",
      "platform": "Platform",
      "pricing": "Pricing",
      "solutions": "Solutions"
    },
    "matching-setting": {
      "delete": "Delete matching setting",
      "general-info": "General Info",
      "interview-description-label": "Enter the description you would like coachees to see for the interview",
      "interview-details": "Interview Details",
      "interview-duration-label": "Duration",
      "interview-name-label": "Name for the interview",
      "max-coach-requests": "Is there a limit to the number of matching interviews coachees can schedule? (0 = Unlimited)",
      "max-coach-requests-title": "Maximum interview requests",
      "no-interview-allowed": "Should coachees be able to interview potential coaches?",
      "no-interview-allowed-title": "Allow matching interviews",
      "required-interview": "Should coachees be required to interview coaches before making a selection?",
      "required-interview-title": "Matching interview required",
      "start-engagement": "Should engagements start automatically after a coach has been selected?",
      "start-engagement-title": "Start engagements automatically",
      "welcome-message": "Welcome Message"
    },
    "messaging": {
      "channel-info": "Channel info",
      "channel-topic-dialog": {
        "input-label": "Channel topic",
        "title": "Channel topic"
      },
      "click-here": "Click here",
      "close": "Close window",
      "coaching-request-card": {
        "awaiting-admin-approval": "Awaiting admin approval",
        "cancel-confirmation-dialog": {
          "description": "Are you sure you want to cancel this interview?",
          "title": "Cancel interview"
        },
        "cancel-interview": "Cancel interview",
        "complete-interview": "Complete interview",
        "error": "This interview request couldn't be loaded. The engagement might have been deleted.",
        "interview-canceled": "This interview was canceled",
        "interview-declined": "Admin declined this interview",
        "loading": "Loading interview request...",
        "no-location": "No meeting location set.",
        "reschedule-interview": "Reschedule interview",
        "schedule-interview": "Schedule interview",
        "set-meeting-location": "Set meeting location",
        "title": "Interview Request"
      },
      "enter_message": "Enter your message",
      "group-channels": "Group Channels",
      "info": {
        "add-participant": "Add participants",
        "channel-topic": "Channel topic",
        "delete-group": "Delete group",
        "general": "General",
        "leave-group": "Leave group",
        "participants": "Participants"
      },
      "is_typing": "is typing...",
      "loading_messages": "Loading messages...",
      "messages": "Messages",
      "messages_with": "Messages with",
      "messaging_center": "Messaging center",
      "new_conversation": "New conversation",
      "new_message": "New message",
      "no-conversations": "No conversations",
      "no-group-channels": "No group channels were created yet.",
      "no-messages": "No messages",
      "no-personal-channels": "No personal channels were created yet.",
      "no_users": "No users were found.",
      "open": "Open messaging center",
      "open_messaging": "Open in messaging center",
      "personal-channels": "Personal Channels",
      "sent_on": "sent on",
      "title": "Messaging",
      "you": "You",
      "zombie": "This chat thread is disabled"
    },
    "messaging-types": {
      "accept_contract": {
        "name": ""
      },
      "archive_expense": {
        "name": ""
      },
      "archive_task": {
        "name": ""
      },
      "archive_vendor": {
        "name": ""
      },
      "assign_task": {
        "name": ""
      },
      "cancel_session": {
        "name": ""
      },
      "coach_suggestion": {
        "name": ""
      },
      "complete_task": {
        "name": ""
      },
      "confirm_session": {
        "name": ""
      },
      "create_client": {
        "name": ""
      },
      "create_contract": {
        "name": ""
      },
      "create_credit": {
        "name": ""
      },
      "create_engagement": {
        "name": ""
      },
      "create_expense": {
        "name": ""
      },
      "create_payment": {
        "name": ""
      },
      "create_task": {
        "name": ""
      },
      "create_vendor": {
        "name": ""
      },
      "delete_client": {
        "name": ""
      },
      "delete_credit": {
        "name": ""
      },
      "delete_engagement": {
        "name": ""
      },
      "delete_expense": {
        "name": ""
      },
      "delete_payment": {
        "name": ""
      },
      "delete_task": {
        "name": ""
      },
      "delete_vendor": {
        "name": ""
      },
      "engagement_file_uploaded": {
        "name": ""
      },
      "engagement_file_viewed": {
        "name": ""
      },
      "engagement_form_complete": {
        "name": ""
      },
      "engagement_form_received": {
        "name": ""
      },
      "failed_payment": {
        "name": ""
      },
      "invite_coach": {
        "name": ""
      },
      "invited_employee_account": {
        "name": ""
      },
      "publish_engagement": {
        "name": ""
      },
      "receive_invoice": {
        "name": ""
      },
      "refunded_payment": {
        "name": ""
      },
      "request_session": {
        "name": "Session Scheduling Request"
      },
      "reschedule_session": {
        "name": ""
      },
      "restore_expense": {
        "name": ""
      },
      "restore_payment": {
        "name": ""
      },
      "restore_task": {
        "name": ""
      },
      "restore_vendor": {
        "name": ""
      },
      "schedule_session": {
        "name": "Session Scheduled"
      },
      "session_reminder": {
        "name": "Reminder"
      },
      "update_contract": {
        "name": ""
      },
      "update_engagement": {
        "name": ""
      },
      "update_expense": {
        "name": ""
      },
      "update_invoice": {
        "name": ""
      },
      "update_task": {
        "name": ""
      },
      "voided_payment": {
        "name": ""
      }
    },
    "models": {
      "account": {
        "logo-updated": "Profile picture updated."
      },
      "billing-approach": {
        "name-label": "Name"
      },
      "education-degree": {
        "create-label": "Add education history",
        "degree-label": "Degree",
        "edit-label": "Edit education history",
        "graduation-date-label": "Graduation Date",
        "major-label": "Major",
        "school-label": "School name",
        "start-date-label": "Start Date"
      },
      "employee": {
        "employee-title-label": "Title",
        "internal-identification-number-label": "Work ID",
        "work-email-label": "Work Email",
        "work-phone-label": "Work Phone"
      },
      "model-deleted": "{model} deleted.",
      "model-saved": "{model} saved.",
      "names": {
        "account": "Account",
        "account-messaging": "Account messaging",
        "account-payment-gateway": "Account payment gateway",
        "account-setting": "Account setting",
        "account-stripe-card": "Account stripe card",
        "account-summary": "Account summary",
        "account-table-customization": "Table settings",
        "activity": "Activity",
        "admin-resource": "Admin resource",
        "adminable": "Adminable",
        "assignable": "Assignable",
        "assignment": "Assigment",
        "association": "Association",
        "availability-preference": "Availability preferences",
        "award": "Award",
        "base-model": "Base model",
        "base-program": "Base program",
        "base-resource": "Base resource",
        "billable-activity": "Billable activity",
        "billing-setting": "Billing setting",
        "business-unit": "Business unit",
        "chat-message": "Chat message",
        "client": "Client",
        "client-coach": "Client coach",
        "client-coach-share": "Client Coach share",
        "client-coachee": "Client coachee",
        "client-location": "Client location",
        "client-poc": "Client POC",
        "client-summary": "Client summary",
        "coach": "Coach",
        "coach-pool": "Coach pool",
        "coach-pool-coach": "Coach pool coach",
        "coaching-categorization": "Coaching categorization",
        "coaching-experience": "Coaching experience",
        "coaching-inquiry": "Coaching inquiry",
        "coaching-inquiry-message": "Coaching inquiry message",
        "coaching-request": "Coaching request",
        "coaching-request-message": "Coaching request message",
        "coaching-theme-info": "Coaching theme info",
        "comment": "Comment",
        "commentable": "Commentable",
        "company-location": "Company location",
        "competenciable": "Competenciable",
        "competency": "Competency",
        "competency-description": "Competency description",
        "competency-library": "Competency library",
        "contact": "Contact",
        "contract": "Contract",
        "contract-adjustment-request": "Contract adjustmnet request",
        "contract-adjustment-request-field": "Contract adjustment request field",
        "contract-template": "Contract template",
        "country": "Country",
        "cronofy-calendar": "Cronofy calendar",
        "cronofy-connected-account": "Cronofy connected account",
        "currency": "Currency",
        "custom-notifiable": "Custom notifiable",
        "custom-notification": "Custom notification",
        "department": "Department",
        "education-degree": "Education degree",
        "email-customization": "Email customization",
        "employee": "Employee",
        "employee-level": "Employee level",
        "employee-view": "Employee view",
        "engagement": "Engagement",
        "engagement-comment": "Engagement connect",
        "engagement-milestone": "Engagement milestone",
        "engagement-participant": "Engagement participant",
        "engagement-resource": "Engagement resource",
        "engagement-resource-group": "Engagement resource group",
        "engagement-session": "Session",
        "engagement-session-participant": "Engagement session participant",
        "engagement-summary": "Engagement summary",
        "engagement-template": "Engagement template",
        "expense": "Expense",
        "file": "File",
        "file-resource": "File resource",
        "form": "Form",
        "form-answer": "Form Answer",
        "form-question": "Form option",
        "form-question-option": "Form question option",
        "form-section": "Form section",
        "form-submission": "Form submission",
        "gateway-type": "Gabrary resourceteway Type",
        "goal": "Goal",
        "goal-approach": "Goal approach",
        "goalable": "Goalable",
        "inspirational-quote": "Inspirational quoute",
        "interaction": "Interaction",
        "invitation": "Invitation",
        "invoice": "Invoice",
        "invoice-design": "Invoice design",
        "invoice-item": "Invoice Item",
        "invoiceable": "Invoiceable",
        "language": "Language",
        "language-fluency": "Language fluency",
        "library-resource": "Library resource",
        "location": "Location",
        "me": "Me",
        "meeting-location": "Message location",
        "message": "Message",
        "message-recipient": "Message recipient",
        "message-thread": "Message thread",
        "messageable": "Messageable",
        "messaging-type": "Messaging type",
        "milestone": "Milestone",
        "note": "Note",
        "noteable": "Noteable",
        "notification": "Notification",
        "notification-reminder": "Notification reminder",
        "payment": "Payment",
        "payment-gateway": "Payment gateway",
        "payment-library": "Payment library",
        "payment-method": "Payment method",
        "payment-schedule": "Payment schedule",
        "payment-status": "Payment status",
        "payment-term": "Payment term",
        "payment-type": "Payment type",
        "pending-action": "Pending action",
        "permission": "Permission",
        "permission-role": "Permission role",
        "plan": "Plan",
        "plan-feature": "Plan feature",
        "profile": "Profile",
        "program-item": "Program item",
        "program-resource": "Program resource",
        "programmable": "Programmable",
        "resource": "Resource",
        "resource-group": "Resource group",
        "resource-group-item": "Resource group item",
        "resourceable": "Resourceable",
        "response": "Response",
        "review": "Review",
        "reviewable": "Reviewable",
        "role": "Role",
        "role-user": "Role user",
        "sessionable": "Sessionable",
        "shareable": "Shareable",
        "shared-item": "Shared Item",
        "site-feature": "Site feature",
        "specialty": "Speciality",
        "stakeholder": "Stakeholder",
        "stakeholderable": "Stakeholderable",
        "status": "Status",
        "stripe-card": "Stripe card",
        "stripe-connected-account": "Stripe connected account",
        "subscription": "Subscription",
        "survey-resource": "Survey resource",
        "task": "Task",
        "task-grouping": "Task grouping",
        "task-resource": "Task resource",
        "taskable": "Taskable",
        "tax-rate": "Tax rate",
        "timezone": "Timezone",
        "twilio-identity": "Twilio identity",
        "twilioable": "Twilioable",
        "user": "User",
        "user-affiliation": "User affiliation",
        "user-assessment": "User assessment",
        "user-certification": "User certification",
        "user-context": "User context",
        "user-language": "User language",
        "user-notification-setting": "User notification setting",
        "user-publication": "User publication",
        "user-work": "User work",
        "work-experience": "Work experience"
      },
      "resource": {
        "duplicating-resource": "Duplicating resource...",
        "resource-duplicated": "Resource duplicated"
      },
      "save-success": "Saved successfully.",
      "task": {
        "duplicating-session": "Duplicating session...",
        "duplicating-task": "Duplicating task...",
        "session-duplicated": "Session duplicated",
        "task-duplicated": "Task duplicated"
      },
      "user-language": {
        "create-label": "Add language",
        "edit-label": "Edit language",
        "fluency-label": "Fluency",
        "language-label": "Language"
      },
      "work-experience": {
        "create-label": "Add work experience",
        "description-label": "Description",
        "edit-label": "Edit work experience",
        "end-date-label": "End Date",
        "organization-label": "Organization",
        "role-label": "Role",
        "start-date-label": "Start Date"
      }
    },
    "not-found": {
      "description": "Sorry, but the page you are looking for has not been found. Try checking the URL for error, then hit the refresh button on your browser or try found something else in our app.",
      "goToLogin": "Go to application",
      "title": "Page Not Found"
    },
    "note": {
      "back": "Back to engagement notes",
      "delete": "Delete",
      "deleting": "Deleting",
      "editor-placeholder": "Take your notes here.",
      "save": "Save changes",
      "saved": "All changes saved",
      "saving": "Saving",
      "share": "Share",
      "title": "Editing note",
      "title-placeholder": "Untitled."
    },
    "notifications": {
      "form": {
        "title": "You've been sent a form to fill out."
      },
      "goal": {
        "completed": "Goal completed.",
        "created": "Goal created.",
        "deleted": "Goal deleted.",
        "status-updated": "Goal status updated.",
        "title": "You've been assigned a goal to complete.",
        "updated": "Goal updated."
      },
      "source": {
        "engagement": "Engagement"
      }
    },
    "onboarding": {
      "blog-posts": {
        "goal-setting": "The importance of goal setting and how it can help you",
        "google": "How Google is using coaching to build leaders instead of finding them",
        "read-more": "Read more",
        "right-arrow": "›",
        "roi": "Can ROI for coaching ever be proven?",
        "successful": "Most successful people have a coach... why shouldn't you?",
        "top-companies": "Coaching is not just for executives. Learn why top companies are offering coaching to all!"
      },
      "how": "How do I",
      "learn-more": "Learn More",
      "news": "\"Exciting news here at Coaching.com! Our latest release is now live and better than ever. We’re excited to finally\n share a completely redesigned Coaching.com experience. And we couldn’t be happier. Intuitive and easy to use,\n Coaching.com 5.0 brings you tons of new features and significantly improves the user experience for everyone.\"",
      "webinar": "Webinar Signup",
      "welcome": "Welcome to Coaching.com!"
    },
    "orders": {
      "cancel": "cancel",
      "coach": "Coach",
      "complete-purchase": "Complete purchase",
      "details": "Details",
      "empty-state": "No orders",
      "engagement-with": "Engagement with",
      "go-to-engagement": "Go to engagement",
      "hours": "Hours",
      "package-name": "Package name",
      "price": "Price",
      "purchase": "Purchase",
      "sessions": "Sessions",
      "status": "Status",
      "statuses": {
        "completed": "Completed",
        "failed": "Failed",
        "pending": "Pending",
        "processing": "Processing"
      },
      "title": "Orders",
      "wait-msg": "We’re setting up your coaching engagement. Might take a few seconds."
    },
    "outside": {
      "auth": {
        "issue": "It looks like there is an issue with your account on Coaching.com. Please contact support@coaching.com to resolve the issue.",
        "old": "It looks like you're trying to log in with an old or unsupported browser.",
        "sso": "It looks like you accessed Coaching.com before your profile was fully setup and activated. Please contact your account administrator for more information.",
        "try-again": "Please try again using the latest version of",
        "verify-email": {
          "message": "An email has been sent to you with a link to verify your account.",
          "resend": "Resend email",
          "resend-success": "Email resent.",
          "return-login": "Return to login page",
          "title": "Email verification"
        },
        "whoops": "Whoops..."
      },
      "author": "Marshall Goldsmith, Ph.D.",
      "browsers": {
        "chrome": "Chrome",
        "edge": "Edge",
        "firefox": "Firefox",
        "safari": "Safari"
      },
      "coach-request": {
        "declined": {
          "description": "You declined coaching with <strong>{coacheeName}</strong>",
          "modal": {
            "description": "You can optionally specify the reason for declining this request.",
            "reason-label": "Decline reason",
            "reason-placeholder": "Enter the reason for decline this request",
            "title": "Decline request"
          },
          "reason": "Reason:",
          "title": "Coaching Request"
        },
        "notified": "You will be notified when the engagement starts.",
        "pending": {
          "description": "<strong>{coacheeName}</strong> has requested coaching with you",
          "title": "Coaching Request"
        },
        "selected": {
          "description": "Coaching request with <strong>{coacheeName}</strong> was accepted",
          "title": "Coaching Request"
        }
      },
      "forgot": {
        "contact": "Contact <a href=\"mailto:support@coaching.com?subject=Password&#32;Reset\">support@coaching.com</a> for help.",
        "email-label": "Email",
        "login-link": "Back to login",
        "prompt": "Please enter the email associated with your account.",
        "reset-button": "Reset",
        "submissions": "Submissions",
        "submitted-by": "Submitted by {user}",
        "submitted-on": "Submitted on {date}",
        "success": "A reset password link has been sent to <strong>{email}</strong>. <br> Please contact <a href=\"mailto:support@coaching.com?subject=Password&#32;Reset\">support@coaching.com</a> if you do not receive the email.",
        "title": "Reset password",
        "wait": "Please wait"
      },
      "invite": {
        "create-password": "Create your password in order to start using your account.",
        "invited-by": "<strong>{senderName}</strong> invited you to create a Coaching.com account.",
        "set-password": "Set password"
      },
      "login": {
        "contact": "Contact <a href=\"mailto:support@coaching.com?subject=Login\">support@coaching.com</a> for help.",
        "dont-have-account": "Don't have an account?",
        "email-label": "Email",
        "forgot-link": "Forgot password?",
        "form-paragraph-1": "We just launched a <strong>new version of Coaching.com</strong> with improved security features.",
        "form-paragraph-2-1": "If you already have an account you'll need to ",
        "form-paragraph-2-2": " to log in",
        "form-paragraph-2-action": "reset your password",
        "form-paragraph-3": "Already reset your password or first time logging in to a brand new account?",
        "intro": "<p>We just launched a <strong>new version of CoachLogix</strong> with improved security features.</p><p>Because of this, you’ll need to reset your password to access your account. We remain committed to protecting your privacy and confidentiality and will continue working hard to provide you with best-in-class coaching management solutions.</p>",
        "login-button": "Log in",
        "login-signup-button": "Login / Signup",
        "password-label": "Password",
        "signup-button": "Sign up",
        "signup-label": "Sign up here",
        "signup-link": "Sign up",
        "social-privacy-notice": "For privacy reasons we are removing the ability to access your Coaching.com account using your social account ID’s, such as Facebook, LinkedIn, Google. If you have used one of these social logins please <a href=\"{url}\" class=\"cl-link\">click here</a> to update your account credentials.",
        "tagline": "Everything you need to manage your coaching practice in one place.",
        "terms-acknowledge": "I consent to the",
        "terms-acknowledge-login": "By logging in I acknowledge the",
        "title": "Welcome!",
        "wait": "Please wait"
      },
      "payment-thank-you": {
        "go-back-button": "Go to homepage",
        "payment-accepted": "Your payment has been accepted.",
        "thanks": "Thank you for your payment",
        "you-can-go-back": "You can now return to the Coaching.com app."
      },
      "quote": "“Coaching.com does a stellar job at providing the most advanced, cutting edge solutions for the coaching industry.”",
      "take-me-to-app": "Take me to the app",
      "title": "Bestselling Author and Leadership Coach"
    },
    "personal-settings": {
      "app-notifications": "App Notifications",
      "calendar": {
        "availability-warning": "When sessions are scheduled through Coaching.com, we will add the event to your target calendar(s) automatically.",
        "calendar-update": "Calendar updated.",
        "calendars": {
          "add-calendar-button": "Add calendar",
          "description": "Connect calendar(s) you would like Coaching.com to read \"busy\" times from.",
          "description-connected": "Congratulations! You have connected your calendar with Coaching.com.",
          "disconnect-calendar-button": "Disconnect calendar",
          "headline": "Connect Calendars",
          "headline-connected": "Calendar is connected",
          "prompt": "Select a provider to add your first account:"
        },
        "default-meeting-location": {
          "description": "Set a default meeting location for every scheduled coach matching interview and your scheduled coach sessions.",
          "headline": "Default matching interview location",
          "tooltip": "Set as the default matching interview location"
        },
        "default-session-location": {
          "description": "Set a default coaching session location for every scheduled coach sessions.",
          "headline": "Default coaching session location",
          "tooltip": "Set as the default coaching session location"
        },
        "hours": {
          "add-hours": "Add Hours",
          "cancellation-policy": "Session cancellation policy:",
          "description": "Want to allow your clients to schedule their own sessions? Set the standard operating hours you would like to share with clients and we'll handle showing your available time.",
          "duration": "Duration",
          "headline": "Standard Hours",
          "no-schedule-within": "No session scheduling within:",
          "session-padding": "Padding between sessions:"
        },
        "invalid-time": "Invalid time",
        "locations": {
          "description": "Manage your locations and set a default meeting location for every scheduled coach matching interview and your scheduled coach sessions.",
          "headline": "Default locations"
        },
        "no-calendars": "No calendars were added yet.",
        "providers": {
          "apple": "iCloud",
          "exchange": "Exchange",
          "google": "Google",
          "live_connect": "Outlook.com",
          "office365": "Office 365"
        },
        "set-as-default": "Set as default",
        "set-location-as": "Set location as",
        "subtitle": "Synchronize your calendars with your Coaching.com account.",
        "target-calendars": "Target calendars",
        "title": "Calendar settings",
        "update-toast-message": "Calendar settings updated."
      },
      "current-browser": "Enabling this setting will only affect the current browser.",
      "general": {
        "accepting-clients": "I'm available for new clients",
        "coach-capacity-helper": "Choose your engagement capacity or a maximum number of concurrent engagements. This is used to provide information during the engagement creation process.",
        "coach-capacity-not-set": "Coach engagement capacity not set.",
        "current-coach-capacity": "Coach engagement capacity set to {capacity}.",
        "im-a-coach": "I'm a coach",
        "request-external-engagement-approval": "Request my approval for new client engagements",
        "settings-saved": "Personal settings were saved.",
        "title": "General",
        "update-avatar": "Update photo",
        "updated-avatar": "Photo updated"
      },
      "global": "Global configuration",
      "notifications": {
        "subtitle": "Adjust your personal notification settings.",
        "table-header": {
          "email": "Email",
          "first-name": "First Name",
          "id": "ID",
          "last-name": "Last Name",
          "phone": "Phone",
          "status": "Status",
          "title": "Title"
        },
        "title": "Notifications",
        "userSettings": {
          "empty": "Loading Notification Types Configurations",
          "headline": "Notification Types",
          "subhead": "Choose which notifications you want to receive and by which method"
        }
      },
      "title": "Personal Settings",
      "toggle-notifications": "Enable or disable all email notifications."
    },
    "profile": {
      "add-new-section-title": "Add new profile section",
      "affiliations": "Affiliations",
      "assessments": "Assessments",
      "clients": "Clients",
      "credentials": "Credentials",
      "education-degree": "Education",
      "empty-state": {
        "affiliations": "You haven't added affiliations yet.",
        "experience": "You haven't added years of experience yet.",
        "general": "{name} is still building the profile.",
        "industries": "You haven't added industries yet.",
        "languages": "You haven't added languages yet.",
        "leader-levels": "You haven't added employee levels yet.",
        "modalities": "You haven't configured your modalities yet.",
        "specialties": "You haven't added specialties yet.",
        "summary": "You haven't added a profile summary yet.",
        "type-of-coach": "You haven't added coaching types yet.",
        "video": "You haven't added a video yet.",
        "work-experience": "You haven't added work experience yet."
      },
      "experience": "Experience",
      "hours-coached": "Hours Coached",
      "languages": "Languages",
      "leader-levels": "Target Audience",
      "modalities": "Modalities",
      "packages": {
        "purchase": "Purchase",
        "sessions": "{sessions, plural, one {session} other {sessions}}",
        "title": "Packages"
      },
      "publications": "Publications",
      "section": {
        "certifications": "Certifications",
        "coaching-experience": "Coaching Experience",
        "placeholder": "None added yet.",
        "user-publication": "Publications"
      },
      "specialties": "Specialties",
      "specialties-message": "Please select up to 5 specialties",
      "work-experience": "Work Experience",
      "years-of-experience": "Years of Experience"
    },
    "program": {
      "visibility": "Visibility"
    },
    "program-item": {
      "add": "Add program item",
      "admin-visibility": "Admin only",
      "cancel": "Cancel",
      "canceled": "Canceled on {date}",
      "completed": "Completed on {date}",
      "scheduled": "Scheduled for {date}",
      "sent": "Sent on {date}",
      "subtitle": "You can add sessions, resources, and tasks.",
      "title": "Start building your program"
    },
    "program-templates": {
      "add-item": "Add item",
      "delete": "Delete template",
      "general": {
        "info": "Changes made to a Program Template will only be reflected in newly created engagements.",
        "program-templates": "Program templates",
        "template-created": "Template created.",
        "template-updated": "Template updated."
      },
      "resources": {
        "permissions-notice": "Resources added to a program template and applied to an engagement will be visible and made accessible to all engagement stakeholders"
      },
      "settings": {
        "add-theme-library": "Add a theme library",
        "admin": "Admin",
        "apply-to-all": "This change will apply to all sessions, are you sure you want to proceed?",
        "created-by": "Created by",
        "delete-confirmation": "Are you sure you want to delete this template?",
        "delete-success": "Template deleted successfully",
        "delete-template": "Delete this template",
        "delete-warning": "Once you delete a template, it cannot be recovered.",
        "meeting-location-description": "Set a default meeting location for this engagement.",
        "meeting-location-title": "Default Meeting Location",
        "theme-library": "Theme Library",
        "theme-library-added": "Theme library added.",
        "untitled-template": "Untitled template",
        "updated-meeting-location": "Default meeting location updated."
      },
      "single-assignee-links": "Links can only have a single assignee.",
      "single-assignee-tasks": "Tasks can only have a single assignee.",
      "start-building": "Start building your program",
      "start-building-helper": "You can add sessions, resources, and tasks by selecting \"+ Add Item\" in the top left corner.",
      "start-building-here": "Start building your program here."
    },
    "reports": {
      "1month": "1 Month",
      "1year": "1 Year",
      "3months": "3 Months",
      "6months": "6 Months",
      "all": "All",
      "categories": {
        "activity": "Activities",
        "aging": "Aging",
        "client": "Clients",
        "engagement": "Engagements",
        "expense": "Expenses",
        "invoice": "Invoices",
        "payment": "Payments",
        "profit_and_loss": "Profit and loss",
        "task": "Tasks",
        "tax_rate": "Tax rates"
      },
      "icons": {
        "assignment": "check",
        "client": "work",
        "coach": "recent-actors",
        "coachee": "person",
        "engagement": "group",
        "engagement_session": "group",
        "form_submission": "class",
        "goal": "gps_fixed",
        "invoice": "receipt",
        "payment": "attach-money",
        "vendor": "business"
      },
      "load": "Load Report",
      "loading": "Loading reports...",
      "names": {
        "assignment": "Assignments/Tasks",
        "client": "Clients",
        "coach": "Coaches",
        "coachee": "Coachees",
        "engagement": "Engagements",
        "engagement_session": "Sessions",
        "form_submission": "Form Submissions",
        "goal": "Goals",
        "invoice": "Invoices",
        "payment": "Payments",
        "vendor": "Vendors"
      },
      "no-data": "No data available",
      "please": "Please select a report.",
      "table": {
        "headers": {
          "actions": "Actions",
          "category": "Category",
          "name": "Report",
          "type": "Type"
        }
      },
      "table-header": {
        "active_coachees": "Active Coachees",
        "active_coaches": "Active Coaches",
        "active_contracts": "Active Contracts",
        "active_engagements": "Active Engagements",
        "actual_duration": "Actual Duration",
        "amount_due": "Amount Due",
        "amount_invoiceable": "Total Invoiceable",
        "amount_invoiced": "Total Invoiced",
        "amount_outstanding": "Total Outstanding",
        "amount_paid": "Amount Paid",
        "amount_received": "Total Revenue",
        "answer": "Answer",
        "assigned_date": "Assigned Date",
        "assignee": "Assignee",
        "assignee_account_name": "Assignee Company",
        "assignee_company": "Assignee Company",
        "assignee_department": "Assignee Department",
        "assignee_email": "Assignee Email",
        "assignee_first_name": "Assignee First Name",
        "assignee_last_name": "Assignee Last Name",
        "assignee_location": "Assignee Location",
        "assignee_sub_department": "Assignee Sub-department",
        "average_coach_rating": "Average Coach Rating",
        "average_rating": "Average Rating",
        "billing_method": "Billing Method",
        "billing_unit": "Billing Unit",
        "client_account_name": "Client Company",
        "coach": "Coach",
        "coach_account_name": "Coach Company",
        "coach_city": "Coach City",
        "coach_company": "Coach Company",
        "coach_country": "Coach Country",
        "coach_department": "Coach Department",
        "coach_email": "Coach Email",
        "coach_first_name": "Coach First Name",
        "coach_included": "Coach Included",
        "coach_last_name": "Coach Last Name",
        "coach_location": "Coach Location",
        "coach_name": "Coach Name",
        "coach_phone": "Coach Phone",
        "coach_shares_pending": "Coach Shares",
        "coach_sub_department": "Coach Sub-department",
        "coach_type": "Coach Type",
        "coachee": "Coachee",
        "coachee_account_name": "Coachee Company",
        "coachee_company": "Coachee Company",
        "coachee_department": "Coachee Department",
        "coachee_email": "Coachee Email",
        "coachee_first_name": "Coachee First Name",
        "coachee_included": "Coachee Included",
        "coachee_last_name": "Coachee Last Name",
        "coachee_location": "Coachee Location",
        "coachee_name": "Coachee Name",
        "coachee_phone": "Coachee Phone",
        "coachee_sub_department": "Coachee Sub-department",
        "coachee_type": "Coachee Type",
        "competency_library": "Theme Library",
        "completed_date": "Completed Date",
        "created_at": "Date Created",
        "date_assigned": "Date Assigned",
        "date_completed": "Date Completed",
        "date_created": "Date Created",
        "date_last_updated": "Date Last Updated",
        "department": "Department",
        "due_date": "Due Date",
        "duration": "Scheduled Duration",
        "employee_level": "Employee Level",
        "employee_title": "Employee Title",
        "end_date": "End Date",
        "engagement": "Engagement",
        "engagement_id": "Engagement Id",
        "engagement_status": "Engagement Status",
        "engagement_template": "Program",
        "engagements_completed": "Completed Engagements",
        "first_rating": "First Rating",
        "form_name": "Form Name",
        "form_section": "Form Section",
        "goal_description": "Goal Description",
        "goal_name": "Goal Name",
        "goals_completed": "Goals Completed",
        "goals_created": "Goals Created",
        "goals_open": "Open Goals",
        "hours_cancelled": "Hours Cancelled",
        "hours_completed": "Hours Completed",
        "hours_remaining": "Hours Remaining",
        "hours_scheduled": "Hours Scheduled",
        "id": "Id",
        "improvement": "Improvement",
        "invites_pending": "Invites Pending",
        "late_cancelled": "Late Cancelled",
        "latest_rating": "Latest Rating",
        "lead_email": "Lead Email",
        "lead_first_name": "Lead First Name",
        "lead_last_name": "Lead Last Name",
        "lead_phone": "Lead Phone",
        "leader_level": "Leader Level",
        "location": "Location",
        "location_name": "Location Name",
        "meeting_location": "Meeting Location",
        "meeting_type": "Meeting Type",
        "most_recent_session": "Most Recent Session",
        "next_session": "Next Session",
        "percent_complete": "Percent Complete",
        "primary_contact_email": "Coachee Email",
        "primary_contact_first_name": "Contact Last Name",
        "primary_contact_last_name": "Contact First Name",
        "primary_contact_phone": "Coachee Phone",
        "program_name": "Program Name",
        "progress": "Progress",
        "question_number": "Question #",
        "question_text": "Question",
        "respondent_company": "Respondent Company",
        "respondent_email": "Respondent Email",
        "respondent_first_name": "Respondent First Name",
        "respondent_last_name": "Respondent Last Name",
        "respondent_name": "Respondent Name",
        "scheduled_date": "Scheduled Date",
        "session": "Session",
        "session_status": "Session Status",
        "sessions_cancelled": "Sessions Canceled",
        "sessions_completed": "Sessions Completed",
        "sessions_remaining": "Sessions Remaining",
        "sessions_scheduled": "Sessions Scheduled",
        "stakeholders": "Stakeholders",
        "start_date": "Start Date",
        "status": "Status",
        "sub_department": "Sub-department",
        "task_description": "Task Description",
        "task_name": "Task Name",
        "task_type": "Task Type",
        "themes": "Themes",
        "title": "Title",
        "total_attendees": "Total Attendees",
        "total_coach_reviews": "Coach Reviews",
        "total_contracts": "Total Contracts",
        "total_engagements": "Total Engagements",
        "total_hours": "Total Hours",
        "total_invoices": "Total Invoices",
        "total_sessions": "Total Sessions",
        "vendor_account_name": "Vendor Company",
        "weight": "Weight"
      },
      "title": "Reports",
      "ytd": "YTD"
    },
    "resources": {
      "add-file": "Add file",
      "articles-label": "Articles",
      "audio-label": "Audio",
      "back-to-all-resources": "Back",
      "create-link-dialog": {
        "description-label": "Description",
        "edit-title": "Edit link",
        "invalid-link": "Please provide a valid address.",
        "link-preview-title": "Link preview",
        "link-success": "Link created successfully.",
        "link-title": "Preview Link",
        "loading-preview": "Generating preview...",
        "name-label": "Name",
        "preview-error": "Could not generate link preview. Is the link pointing to a valid URL?",
        "title": "Create link",
        "url-label": "Link"
      },
      "delete-check": "Are you sure you want to delete this resource?",
      "document-dialog": {
        "create-note-title": "Create note",
        "create-title": "Create document",
        "edit-note-title": "Edit note",
        "edit-title": "Edit document"
      },
      "drag-out-helper": "Drop here to move the resource out of the current folder.",
      "empty-folder": "This folder is empty.",
      "file-error": "{ext} files are not supported. Please try another file.",
      "filter-types": {
        "ownership": "Ownership",
        "type": "Type"
      },
      "filters": {
        "all": "All types",
        "archive": "Compressed files",
        "audio": "Audio",
        "competency-library": "Theme libraries",
        "document": "Coaching.com Docs",
        "form": "Forms",
        "group": "Folders",
        "image": "Images",
        "link": "Link",
        "note": "Notes",
        "presentation": "Presentations",
        "spreadsheet": "Spreadsheets",
        "text": "Uploaded Docs",
        "video": "Video"
      },
      "folder-name": "Folder: {name}",
      "folder-saved": "Folder saved.",
      "folders": "Folders",
      "grid-view": "Grid View",
      "last-modified": "Last modified {date}",
      "list-view": "List View",
      "malicious-files-detected": "We detected one or more malicious files in your resources. These files have been deleted, below a list of the files:",
      "new-folder": "New folder",
      "no-match": "None of your resources or folders matched: \"{searchText}\".",
      "no-match-type": {
        "audio": "No audio files matched \"{searchText}\".",
        "competency-library": "No theme libraries matched \"{searchText}\".",
        "form": "No forms matched \"{searchText}\".",
        "group": "No folders matched \"{searchText}\".",
        "image": "No images matched \"{searchText}\".",
        "presentation": "No presentations matched \"{searchText}\".",
        "spreadsheet": "No spreadsheets matched \"{searchText}\".",
        "text": "No documents matched \"{searchText}\".",
        "video": "No video files matched \"{searchText}\"."
      },
      "notes": "Notes",
      "notes-empty": "No notes",
      "ownership-filters": {
        "all": "All",
        "mine": "Mine",
        "shared": "Shared"
      },
      "please-download": "This file format is not previewable. Please download it.",
      "recommended-label": "Recommended",
      "resource-shared": "Resource shared.",
      "search": "Search Resources",
      "see-all": "See all",
      "share-dialog": {
        "filters": {
          "all": "All",
          "coaching-pools": "Coaching pools",
          "engagement-editors": "Engagement editors",
          "external-coaches": "External coaches",
          "global-admin": "Global admins",
          "internal-coaches": "Internal coaches",
          "location-admin": "Location admins",
          "location-coaches": "Location coaches"
        }
      },
      "sharing-permission-saved": "Sharing permission saved",
      "show-shares": "Show shares",
      "success-messages": {
        "competency-library": "Competency library added successfully.",
        "document": "Document added successfully.",
        "file": "File added successfully.",
        "folder": "Folder added successfully.",
        "form": "Form added successfully.",
        "link": "Link added successfully.",
        "move-into": "Resource moved into folder successfully.",
        "move-out": "Resource moved out of folder successfully."
      },
      "title": "Resources",
      "try-another": "Try another search or click the",
      "try-filter": "icon to filter a resource by type.",
      "videos-label": "Videos",
      "visibility-warning": "Files uploaded to engagement resources are visible to all engagement participants."
    },
    "settings": {
      "basic-info": {
        "about": "About",
        "account-configuration": "Account configuration",
        "address": "Address",
        "address2": "Address 2",
        "adjust-text": "Adjust company settings and preferences for",
        "coaching": {
          "coach-capacity-updated": "Coach capacity succesfully updated!",
          "default-coach-capacity": "Default Coach Engagement Capacity",
          "not-set": "Not Set",
          "title": "Coach Settings"
        },
        "coaching-vendors": "Coaching vendors",
        "conf-description": "Choose which features you want available in your account.",
        "external-coaching": "Do you contract external coaches?",
        "facebook": "Company Facebook",
        "headline": "Company Info",
        "independent-coaches": "Independent coaches",
        "internal-coaching": "Do you employ internal coaches?",
        "language": "Language",
        "language-description": "Choose the default language for your employees. Individual users can change this default in their personal settings.",
        "language-title": "Default language",
        "linkedin": "Company LinkedIn",
        "name": "Name",
        "primary-contact": "Primary contact",
        "provide-coaching": "Do you sell coaching services?",
        "save": "Save",
        "saving": "Saving...",
        "settings-update": "Settings updated.",
        "signature": "Email Signature",
        "subhead": "Tell us some information about your account so we can customize Coaching.com to fit your needs.",
        "timezone-description": "Choose the default timezone for your employees. Individual users can change this default in their personal settings.",
        "timezone-title": "Default timezone",
        "title": "General",
        "twitter": "Company Twitter",
        "update-company-info": "Update your company information.",
        "website": "Company Website",
        "what-type-of-coaches": "What type of coaches do you contract?"
      },
      "billing": {
        "allow-expenses-options": {
          "approval_needed": "Yes, but approval is needed",
          "no": "No",
          "yes": "Yes"
        },
        "billing-approaches": {
          "fixed_contract_amount": "Fixed amount",
          "flat": "Per session",
          "hourly": "Per hour",
          "retainer": "Retainer"
        },
        "billing-approaches-amounts": {
          "fixed_contract_amount": "Total cost",
          "flat": "Amount per session",
          "hourly": "Amount per hour",
          "retainer": "Amount invoiced each billable period"
        },
        "contract-duration-interval": {
          "engagement_sessions": "Engagement sessions",
          "hours_billed": "Hours billed",
          "months": "Monthly",
          "sessions_completed": "Sessions",
          "weeks": "Weekly"
        },
        "contract-duration-type": {
          "days": "Days",
          "months": "Months",
          "weeks": "Weeks"
        },
        "contract-templates": {
          "create-dialog": {
            "title": "Create contract template"
          },
          "empty-state": "No contract templates.",
          "headline": "Contract templates",
          "subhead": "Create templates of your preferred contracts.",
          "terms": "Contract terms",
          "title": "Contract template"
        },
        "delete-contract-template-prompt": "Are you sure you want to delete this contract template?",
        "delete-contract-template-successful": "Contract template was deleted successfully.",
        "duration-type": {
          "each-month": "Same day each month",
          "hours_billed": "After {value, plural, =1 {one hour billed} other {# hours billed}}",
          "months": "Every {value, plural, =1 {month} other {# months}}",
          "sessions_completed": "After {value, plural, =1 {one session} other {# sessions}}",
          "weeks": "Every {value, plural, =1 {week} other {# weeks}}"
        },
        "endPercentage": "End",
        "firstQuarterPercentage": "First quarter",
        "firstThirdPercentage": "First third",
        "metrics": "Billing metrics",
        "middlePercentage": "Middle",
        "ordinal-numbers": "{number, selectordinal, one {#st} two {#nd} few {#rd} other {#th} =-1 {Last} }",
        "payment-schedule": "Payment schedule",
        "payment-schedules": {
          "create-dialog": {
            "title": "Create billing approach"
          },
          "empty-state": "No billing approaches.",
          "headline": "Payment schedule",
          "subhead": "These can be applied to contract templates to define a preferred contracts."
        },
        "periods": {
          "end": "End",
          "firstQuarter": "First quarter",
          "firstThird": "First third",
          "middle": "Middle",
          "secondThird": "Second third",
          "thirdQuarter": "Third quarter",
          "upfront": "Upfront"
        },
        "schedule-types": {
          "custom": "Create new",
          "duration": "After a time interval",
          "fixed_contract_amount_percentages": "Different percentages during the contract",
          "manual": "I will manually invoice the client",
          "session": "After each session"
        },
        "secondThirdPercentage": "Second third",
        "thirdQuarterPercentage": "Third quarter",
        "title": "Billing",
        "upfrontPercentage": "Upfront"
      },
      "branding": {
        "brand-colors-description": "Choose a primary and accent color that represent your brand.",
        "brand-colors-title": "Brand colors",
        "custom": "Custom",
        "email-notifications-description": "Add a signature and social media footer to email notifications generated from your Coaching.com account.",
        "email-notifications-title": "Email Notifications",
        "full-logo-description": "This logo will appear as a header on your forms, emails and other places. If you do not upload a wide logo, we will use your square logo.",
        "primary-color": "Primary color",
        "primary-color-helper": "A color that appears most frequently in your app.",
        "secondary-color": "Secondary color",
        "secondary-color-helper": "A color used to accent key parts of your UI.",
        "square-logo": "Square Logo",
        "square-logo-description": "Choose a logo that will be used to represent your company in Coaching.com.",
        "subhead": "Customize the look and feel of your Coaching.com account for employees and directly contracted coachees.",
        "themes": "Themes",
        "title": "Branding",
        "update-cover-picture": "Update cover picture",
        "update-full-logo": "Update Full logo",
        "update-logo": "Update logo",
        "white-sidenav": "Make sidenav white"
      },
      "calendar": {
        "title": "Calendar"
      },
      "coach-pools": {
        "add-dialog": {
          "created": "Matching setting created.",
          "title": "Add matching settings"
        },
        "default-setting": "Default matching setting",
        "default-setting-tooltip": "When you delete a matching setting, any engagements attached to it will be changed to this matching setting.",
        "delete-prompt": "If any engagements are attached to this matching setting, they will be changed to the <strong>default</strong> matching setting.<br>Are you sure you want to delete this matching setting?",
        "no-matching-settings": "No matching settings.",
        "no-program-templates": "Please create a program template (Settings > Program Templates)",
        "subtitle": "Customize your preferred settings for coach matching workflows.",
        "title": "Coach Matching Settings"
      },
      "company-members": {
        "add-employee": "Add employee",
        "create-workflow": {
          "company-location-label": "Company location",
          "department-label": "Department",
          "employee-level-label": "Employee level",
          "employee-title-label": "Employee title",
          "first-name-label": "First name",
          "internal-identification-label": "Internal identification",
          "last-name-label": "Last name",
          "role-label": "Role",
          "title": "Create employee",
          "work-email-label": "Work email",
          "work-phone-label": "Work phone"
        },
        "invite-user": "Invite user",
        "resend-invite": "Resend Invite",
        "role": {
          "company_admin": "Account Manager/Owner",
          "company_billing_manager": "Account Billing Manager",
          "company_client_manager": "Account Client Manager",
          "company_coach": "Internal Coach",
          "company_coach_manager": "Account Coach Manager",
          "company_department_manager": "Department Manager",
          "company_employee": "Employee",
          "company_employee_manager": "Account Employee Manager",
          "company_engagement_editor": "Account engagement editor",
          "company_engagement_manager": "Account Engagement Manager",
          "company_external_coach": "External Coach",
          "company_marketing_manager": "Account Marketing Manager"
        },
        "send-invite": "Send Invite",
        "subtitle": {
          "default": "Manage employees, coaches and admins for your company.",
          "independent_coach": "Add anyone who helps you manage your practice here."
        },
        "table-header": {
          "account-status": "Account Status",
          "avatar": "Avatar",
          "date-added": "Created At",
          "department": "Department",
          "email": "Email",
          "first-name": "First Name",
          "id": "ID",
          "is-admin": "Is Admin?",
          "is-coach": "Is Coach?",
          "last-login": "Last Login",
          "last-name": "Last Name",
          "leader-level": "Leader Level",
          "location": "Location",
          "phone": "Phone",
          "status": "Status",
          "sub-department": "Sub-Department",
          "title": "Title"
        },
        "title": {
          "default": "Employees",
          "independent_coach": "Admins"
        },
        "update-employee": "Update Employee Info",
        "update-employee-roles": "Update Employee Roles",
        "update-employee-roles-self": "Update your account roles",
        "update-employee-self": "Update your account info",
        "view-employee-summary": "View Employee Summary Page"
      },
      "contract-templates": {
        "add": "Add Contract Template",
        "subtitle": "Create templates of your preferred contracts.",
        "table": {
          "headers": {
            "amount": "Amount",
            "auto-send": "Auto-send",
            "billing-metric": "Billing Metric",
            "created-at": "Date Created",
            "created-by": "Created By",
            "interval": "Interval",
            "name": "Name",
            "stripe": "Stripe",
            "updated-at": "Last Updated"
          }
        },
        "title": "Contract Templates"
      },
      "data-table": {
        "created-by": "Created By",
        "date-created": "Date Created",
        "last-updated": "Last Updated",
        "name": "Name",
        "total-hours": "Total Hours",
        "total-sessions": "Total Sessions"
      },
      "invoicing": {
        "add-tax-dialog": {
          "creating": "Saving tax...",
          "edit-title": "Edit tax",
          "rate-helper": "Enter a number up to 100.00.",
          "save-for-later": "Save for future use",
          "success": "Tax saved successfully.",
          "title": "Create new tax"
        },
        "delete-tax-prompt": "Are you sure you want to delete this tax?",
        "delete-tax-successful": "Tax was deleted successfully.",
        "invoices": {
          "counter-helper": "Reset the invoice number counter.",
          "headline": "Invoice settings",
          "prefix-helper": "You can set a prefix that will be added to your invoice numbers.",
          "subhead": "Manage your general invoice settings."
        },
        "stripe": {
          "connect-button": "Connect",
          "description": " Connect your Stripe account to client payments easily and securely.",
          "headline": "Connect your stripe account",
          "powered-by-stripe": "Powered by stripe"
        },
        "stripe-connected": {
          "description": "Congratulations! You have connected your Stripe account and made paying and getting paid in Coaching.com a lot easier.",
          "disconnect-button": "Disconnect",
          "headline": "Your stripe account is connected"
        },
        "taxes": {
          "add-tax-button": "Create tax",
          "add-taxes": "Add taxes",
          "headline": "Tax approaches",
          "no-taxes": "You didn't add any taxes yet.",
          "subhead": "Define one or more tax approaches that can be applied to invoices.",
          "tax-rate": "Tax Rate (percent)"
        },
        "title": "Invoicing"
      },
      "invoicing-payments": {
        "account-payable": {
          "headline": "Accounts Payable",
          "info": "Choose where invoices from vendors are received."
        },
        "add-new-employee": "Add new employee",
        "default-currency": "Default currency",
        "default-currency-description": "Select the default currency for new contracts and invoices.",
        "primary-card": "Primary card",
        "save-successful": "Settings updated",
        "set-as-primary": "Set as primary",
        "subtitle": "Configure your invoice settings and payment options.",
        "title": "Invoicing & Payments"
      },
      "marketplace": {
        "calendar-sync": {
          "description": "Select a provider to add your first account.",
          "name": "Calendar Sync",
          "title": "Connect Calendars"
        },
        "claim-dialog": {
          "alert": "You are not able to change your URL once it is selected.",
          "confirm-button": "Claim URL",
          "selecting": "You are selecting",
          "title": "Confirm URL"
        },
        "claim-url": {
          "available": "Available",
          "claim-url": "Claim URL",
          "claimed": "Claimed",
          "description": "At Coaching.com we believe everyone should be empowered to stand out. Now you can with your own custom Coaching.com URL. Search below and claim yours!",
          "search-placeholder": "Your unique link",
          "title": "Choose Your URL",
          "unavailable": "Unavailable"
        },
        "coaching-info": "Your Coaching Info",
        "complete-first": "You must complete your profile before publishing.",
        "contact-info": {
          "description": "Your clients will need a way to contact you.",
          "helper": "First name, last name and email are required fields.",
          "name": "Contact Info",
          "title": "Complete Contact Info"
        },
        "description": "Complete these requirements to be able to publish your profile",
        "getting-started": "Getting Started",
        "interview": {
          "desc": "Description",
          "desc-val": "A 15 minute call to determine if there is a good fit for a coaching relationship",
          "disabled": "Disabled",
          "duration": "Duration",
          "duration-val": "15 min",
          "enabled": "Enabled",
          "interview-name": "Chemistry Call",
          "location": "Location",
          "location-val": "Coaching.com Video",
          "name": "Name",
          "tab-title": "Marketplace interview",
          "title": "Allow marketplace interview"
        },
        "packages": {
          "activate-success": "Package was successfully activated.",
          "create-new": "Create New",
          "deactivate-success": "Package was successfully deactivated.",
          "empty-message": "No packages added.",
          "package-dialog": {
            "create-package": "Create Package",
            "create-template-cta": "Can't find the template you're looking for? Create a new program template.",
            "create-template-cta-button": "Create new",
            "create-title": "Add New Package",
            "description-placeholder": "Provide an overview of the package. You can package your coaching programs based on duration, topic, audience, or any way you think provides prospective clients a clear sense of what they’re buying.",
            "edit-title": "Edit Package",
            "name-placeholder": "Be succinct yet descriptive",
            "price-notice": "Price clients will pay upfront for package",
            "program-template-info": "Program Template info"
          },
          "package-item": {
            "price": "Price",
            "total-hours": "Total Hours",
            "total-sessions": "Total Sessions"
          },
          "title": "Packages",
          "warning": "Note: Clients can't purchase your services until you have at least one active package and a published profile."
        },
        "profile-picture": {
          "name": "Profile Picture",
          "title": "Update Profile Picture"
        },
        "publish-profile": "Publish profile",
        "publish-success": "Your profile has been published!",
        "publish-success-dialog": {
          "title": "Congratulations, your profile is now public! The Find a Coach section of Coaching.com will be available soon.",
          "view-profile": "View Profile"
        },
        "published": "Your profile is currently visible in the marketplace.",
        "stripe": {
          "description-connected": "Your stripe account is connected.",
          "description-disconnected": "This is how you will get paid!",
          "name": "Connect to Stripe",
          "title": "Connect Stripe Account"
        },
        "subtitle": "Manage your Coaching.com marketplace settings.",
        "summary": {
          "minimum-characters": "Minimum {min} characters",
          "name": "Summary",
          "placeholder": "Use this space to describe yourself, your coaching experience, and the approach you take to work with clients. Be concise but also creative! This is how prospective clients can learn more about you and your coaching style.",
          "remaining-characters": "{value} {value, plural, one {character} other {characters}} needed",
          "title": "Complete Summary"
        },
        "terms-dialog": {
          "accept-label": "I confirm that the statements above are true",
          "comission-msg": "{commission}% commission fee will be applied to your packages.",
          "commission-reduce": "Reduce this fee down to as low as 15% ",
          "pro": "by updating your plan to pro.",
          "publish": "Publish profile",
          "terms": "By checking the box below you confirm that you: <ul class=\"cl-list-disc cl-list-inside cl-mt-1\">\n  <li>Have worked with at least 5 paying coaching clients in the past 10 years</li>\n  <li>Have at least 50 hours of paid coaching experience in the past 10 years</li>\n</ul>\n",
          "title": "Minimum Coaching Experience Requirement"
        },
        "title": "Marketplace",
        "unpublish-success": "Your profile has been unpublished.",
        "unpublished": "Your profile is not visible in the marketplace.",
        "url-claimed": "Your URL was claimed successfully."
      },
      "my-profile": {
        "title": "My Profile"
      },
      "notifications": {
        "title": "Notifications"
      },
      "org-structure": {
        "department": {
          "add": "Add department",
          "add-business-unit": "Add business unit",
          "business-unit": "Business Unit",
          "delete-confirm": "Are you sure you want to delete this department?",
          "department": "Department",
          "no-departments": "No departments",
          "subtitle": "Manage company departments that represent your org structure.",
          "title": "Departments"
        },
        "location": {
          "add": "Add location",
          "address": "Address",
          "address2": "Address 2",
          "admins-dialog": {
            "no-admins": "No admins",
            "see-profile": "See employee profile",
            "title": "{locationName} Admins"
          },
          "city": "City",
          "company-location": "Company location",
          "delete-confirm": "Are you sure you want to delete this location?",
          "phone": "Phone",
          "postal-code": "Postal Code",
          "state": "State/Province",
          "subtitle": "Manage company locations across different geographic areas.",
          "table": {
            "address": "Address:",
            "delete": "Delete",
            "edit": "Edit",
            "no-locations": "No locations",
            "phone": "Phone:",
            "see-admins": "See location admins"
          },
          "title": "Locations"
        },
        "location-updated": "Location updated",
        "subtitle": "Set up your company's organizational structure to provide account administrators with different levels of access and to generate reports based on your business locations, departments and business units.",
        "table": {
          "address": "Address",
          "city": "City",
          "country": "Country",
          "name": "Name",
          "phone": "Phone"
        },
        "title": "Org Structure"
      },
      "payments": {
        "add-card-dialog": {
          "creating": "Creating card...",
          "success": "Card created successfully.",
          "title": "Create new card"
        },
        "cards": {
          "add-card-button": "Create new card",
          "added-by": "Added by",
          "added-on": "added on",
          "headline": "Cards",
          "no-cards": "You didn't add any cards yet.",
          "subhead": "Manage your payment methods."
        },
        "delete-card-successful": "Card was deleted successfully.",
        "delete-prompt": "Are you sure you want to delete this card?",
        "delete-prompt-title": "Confirmation",
        "subtitle": "Manage your invoice payments and add credit cards.",
        "title": "Payments"
      },
      "program-templates": {
        "add": "Add program template",
        "clone": "Would you like to clone an existing template?",
        "subtitle": "Manage program templates that you can use across your engagements.",
        "title": "Program Templates"
      },
      "subscription": {
        "account-support-dialog": {
          "info": "Please contact your Coaching.com account manager or <a href=\"mailto:support@coaching.com\" class=\"cl-link\">support@coaching.com</a> to change your plan.",
          "title": "Manage custom plan"
        },
        "active-coaches-dialog": {
          "active-engagements": "{num} active engagements",
          "title": "Active coaches"
        },
        "additional": "additional seats.",
        "cancel-dialog": {
          "prompt": "Are you sure you want to cancel your plan?",
          "subscription-canceled": "Your plan has been canceled.",
          "title": "Cancel plan"
        },
        "each-seat": "Each additional seat costs",
        "for-the": "for the",
        "free-plan-upgrade-message": "You have reached the limit of active engagements in your free plan. Please purchase a subscription to start this engagement.",
        "payment-card": {
          "check": "Check or ACH Transfer",
          "helper-message": "This payment method will be charged for your plan on your next billing date.",
          "not-subscribed": "You're not subscribed to a paid plan.",
          "title": "Payment method",
          "update-button": "Update card"
        },
        "payment-dialog": {
          "title": "Change your payment method"
        },
        "per-coach": "per additional coach",
        "plan": "plan",
        "plan-card": {
          "active-plan-message": "Your current plan is <strong>{name} ({interval})</strong>.",
          "descriptions": {
            "business": "Includes one seat. Each seat supports one active coach and an additional 10 active engagements. Upgrade to Business Pro for unlimited engagements per seat.",
            "business_free": "You can manage one engagement for free. Select a plan to manage your entire practice with Coaching.com.",
            "business_plus": "Includes one seat. Each seat supports one active coach with unlimited engagements.",
            "enterprise": "",
            "enterprise_free": "",
            "enterprise_plus": "",
            "independent_coach": "You can manage up to 10 active engagements on this plan. Upgrade to Coach Pro to manage your entire practice with Coaching.com.",
            "independent_coach_free": "You can manage up to 3 active engagements for free. Select a plan to manage your entire practice with Coaching.com.",
            "independent_coach_plus": "You can manage unlimited engagements on this plan."
          },
          "expires-at": "Expires at",
          "first-payment": "First payment on",
          "manage-coaches-message": "Want to manage additional coaches? See our business plans.",
          "next-payment": "Next payment on",
          "payment-interval": "Payment interval",
          "payment-method": "Payment method",
          "title": "Current plan",
          "view-business-plans-button": "View business plans",
          "view-coach-plans-button": "View coach plans"
        },
        "plans": {
          "business": "Business Standard",
          "business_free": "Business Free",
          "business_plus": "Business Pro",
          "enterprise": "Enterprise",
          "enterprise_free": "Enterprise Free",
          "enterprise_plus": "Enterprise",
          "independent_coach": "Independent Coach Standard",
          "independent_coach_free": "Independent Coach Free",
          "independent_coach_plus": "Independent Coach Pro"
        },
        "plans-dialog": {
          "cancel-button": "Cancel",
          "current-plan-ribbon": "Current plan",
          "free-label": "Free",
          "subscribe-button": "Subscribe",
          "update-button": "Select",
          "yearly-plan-label": "Show annual plans"
        },
        "seats-dialog": {
          "annual": "per year",
          "info": "<strong>1 seat is included with your plan.</strong> Seats are only required for coaches with active engagements. How many additional seats does your business need?",
          "monthly": "per month",
          "per-seat-price": "Each additional seat costs {price} {interval} each.",
          "price-message": "We will charge you <strong>{price} {interval}</strong> for the <strong>{plan} plan</strong> with <strong>{numSeats} </strong> additional {numSeats, plural, one {seat} other {seats}}.",
          "questions": "Have questions? <a href=\"https://www.coachlogix.com/pricing\" target=\"_blank\">Check out the FAQ section of our pricing page.</a>",
          "seats": "Additional seats",
          "success-message": "Plan changes saved.",
          "title": "Manage seats",
          "yearly": "per year"
        },
        "subscribe-dialog": {
          "added-new-card": "Add credit or debit card",
          "added-on": "added on",
          "finish": "Finish",
          "payment": "Payment",
          "questions": "Have questions? <a href=\"https://www.coaching.com/pricing\" target=\"_blank\" rel=\"noopener noreferrer\">Check out the FAQ section of our pricing page.</a>",
          "subscribe-confirmation": "You're now on the {plan} {interval} plan.",
          "subscribing": "Subscribing...",
          "subscriptions": "Seats",
          "subscriptions-prompt": "<strong>1 seat is included with your plan.</strong> Seats are only required for coaches with active engagements. How many additional seats does your business need?"
        },
        "subscriptions-card": {
          "descriptions": {
            "business-pro": "{seats} {seats, plural, one {seat} other {seats}} supports {seats} {seats, plural, one {active coach} other {active coaches}} with unlimited engagements.",
            "business-standard": "{seats} {seats, plural, one {seat} other {seats}} supports {seats} {seats, plural, one {active coach} other {active coaches}} with up to {numEngagements} active engagements."
          },
          "one-free-message": "You have 1 free seat.",
          "seats-button": "Manage seats",
          "see-coaches": "Show coaches using seats",
          "title": "Seats"
        },
        "subscriptions-upgrade-message": "You have reached the limit of active engagements in your plan. Please purchase more subscriptions to start this engagement.",
        "title": {
          "plural": "Subscriptions",
          "singular": "Subscription"
        },
        "update-dialog": {
          "prompt": "Are you sure you want to switch to the <strong>{name} ({interval})</strong> plan?",
          "subscription-updated": "Subscription was updated successfully.",
          "title": "Update subscription"
        },
        "upgrade-message": "Upgrade your current plan, add and remove subscriptions and set your payment method.",
        "upgrade-messages": {
          "max_engagements_business": "You have reached the max number of active engagements in the Business Standard plan. Upgrade to Business Pro to start this engagement.",
          "max_engagements_business_free": "Select a plan to manage your entire practice with Coaching.com.",
          "max_engagements_independent_coach": "You have reached the max number of active engagements in the Coach Standard plan. Upgrade to Coach Pro to start this engagement.",
          "max_engagements_independent_coach_free": "You have reached the max number of active engagements in the free plan. Upgrade your plan to start this engagement.",
          "max_seats_business": "All seats are currently in use. Add another seat to start this engagement.",
          "max_seats_business_plus": "All seats are currently in use. Add another seat to start this engagement."
        },
        "upgrade-messages-support": {
          "max_engagements": "You have reached the max number of active engagements in your plan.",
          "max_seats": "All seats are currently in use."
        },
        "upgrade-now": "Please upgrade now to enable this feature in your account.",
        "will-charge": "We will charge you",
        "with": "with"
      },
      "templates": {
        "title": "Templates"
      },
      "title": "Company Settings"
    },
    "status": {
      "active": "Active",
      "approved": "Approved",
      "assigned": "Assigned",
      "awaiting_approval": "Awaiting Approval",
      "canceled": "Cancelled",
      "completed": "Completed",
      "deleted": "Deleted",
      "draft": "Draft",
      "in_review": "In Review",
      "internal_record": "Internal Record",
      "invited": "Invite Sent",
      "paid": "Paid",
      "pending": "Pending",
      "scheduled": "Scheduled",
      "sent": "Sent"
    },
    "ui": {
      "account-managers": {
        "account-manager-updated": "Admin contact updated.",
        "disconnect": "Disconnect",
        "edit-account-manager": "Edit admin contact",
        "email": "Email",
        "first-name": "First name",
        "last-name": "Last name",
        "phone": "Phone",
        "resend-invitation": "Resend invitation",
        "send-email": "Send email",
        "send-invitation": "Send invitation"
      },
      "activate": "Activate",
      "active": "Active",
      "add": "Add",
      "add_additional_message": "Add additional email message",
      "add_another_attachment": "Add another_attachment",
      "add_attachment": "Add attachment",
      "add_company": "Add company",
      "add_message_type": "Add message type",
      "add_new_role": "Add new role",
      "add_new_stakeholder": "Add new stakeholder",
      "add_notification": "Add notification",
      "add_role": "Add role",
      "add_stakeholder": "Add stakeholder",
      "add_title": "Add a title",
      "add_to_admin": "Add to Admin",
      "address1-label": "Address",
      "address2-label": "Address 2",
      "all": "All",
      "alt-phone": "Alt Phone",
      "approve": "Approve",
      "at-capacity": "At Capacity",
      "availability": {
        "available": "Accepting Clients",
        "unavailable": "At Capacity"
      },
      "availability-dialog": {
        "force-schedule-description": "Scheduling over busy time may cause conflicts.",
        "schedule": "Schedule",
        "schedule-title": "Scheduling confirmation",
        "schedule-warning": "Scheduling this session will send confirmation emails to all attendees.",
        "session-date": "<b>Date:</b> {date}",
        "session-duration": "<b>Duration:</b> {duration} minutes",
        "session-title": "<b>Title:</b> {title}",
        "title": "Select an available time slot",
        "today": "Today"
      },
      "back": "Back",
      "bold": "Bold",
      "bullet-list": "Bullet list",
      "calendar": {
        "busy": "Importing busy times",
        "two-way": "Full two-way sync"
      },
      "cancel": "Cancel",
      "canceled-on": "Canceled on",
      "card-number-mask": "XXXX XXXXX XXXX {cardLastFour}",
      "change": "Change",
      "city-label": "City",
      "clear": "Clear",
      "client_companies": "Client companies",
      "close": "Close",
      "coach-profile": {
        "assessments": {
          "assessments": "Assessments",
          "no-assessments-added": "No assessments added yet",
          "update-assessments": "Update assessments"
        },
        "availability": {
          "accepting": "Accepting Clients",
          "availability": "Coach availability",
          "available": "I am available for coaching",
          "capacity": "At Capacity",
          "edit": "Change availability",
          "unavailable": "I am not available for coaching"
        },
        "coaching-experience": {
          "coaching-experience": "Coaching experience",
          "create-new-industry": "Create new industry",
          "edit-experience": "Edit coaching experience",
          "experience-updated": "Experience updated",
          "how-many-years-coaching": "How many years have you been coaching?",
          "industries": "Industries",
          "industries-dialog-title": "Industries (Add up to 7 tags)",
          "leader-levels": "Target Audience",
          "no-industries-added": "No industries added yet.",
          "no-levels-added-yet": "No levels added yet.",
          "no-years-of-experience": "No years of experience added yet.",
          "update-coaching-experience": "Update coaching experience",
          "update-industries": "Update industries",
          "update-leader-levels": "Update leader-levels",
          "years-coaching": "Years coaching"
        },
        "contact": {
          "city": "City",
          "contact": "Contact",
          "country": "Country",
          "edit-info": "Edit contact information",
          "email": "Email",
          "info-updated": "Contact info updated.",
          "location": "Location",
          "no-info": "No contact information added yet",
          "phone": "Phone number",
          "title": "Title",
          "update": "Update coach info",
          "website": "Website"
        },
        "credentials": {
          "credentials": "Credentials",
          "no-credentials-added": "You haven't added any credentials.",
          "update-credentials": "Update credentials"
        },
        "education-degrees": {
          "add-education": "Add education",
          "clear-date": "Clear date",
          "degree": "Degree",
          "edit-education": "Edit education",
          "education": "Education",
          "end-date": "End date",
          "major": "Field of study",
          "no-education-added": "You haven't added your education degrees yet.",
          "school": "School",
          "start-date": "Start date"
        },
        "empty-states-no-data": "User has no data available yet.",
        "invalid-end-date": "Invalid end date",
        "invalid-start-date": "Invalid start date",
        "languages": {
          "add-language": "Add language",
          "elementary-proficiency": "Elementary",
          "fluency": "Fluency",
          "languages": "Languages",
          "limited-working-proficiency": "Beginner",
          "native-proficiency": "Expert",
          "no-language-added": "You haven't added a language yet.",
          "no-language-match": "No languages match.",
          "professional-proficiency": "Advanced",
          "professional-working-proficiency": "Intermediate",
          "select-a-language": "Select a language..."
        },
        "modalities": {
          "audio": "Phone / audio only",
          "in-person": "In person",
          "modalities": "Modalities",
          "modalities-updated": "Modalities were updated.",
          "no-modalities-added": "No modalities added.",
          "video": "Video conferencing"
        },
        "no-start-error": "The end date can't be specified without a start date.",
        "ongoing": "Is currently ongoing.",
        "present": "Present",
        "profile-update": "Profile info was updated.",
        "specialties": {
          "create-new-specialty": "Create new specialty",
          "no-added-specialties": "No specialties added yet.",
          "placeholder": "Select up to 5 tags",
          "specialties": "Specialities",
          "specialties-updated": "Specialities were updated.",
          "update-specialties": "Update specialties"
        },
        "start-end": "Start date must be before the end date.",
        "summary": {
          "no-added-summary": "No summary added yet.",
          "summary": "Summary",
          "summary-placeholder": "Type here something about you...",
          "summary-updated": "Summary was updated.",
          "update-summary": "Update summary"
        },
        "update-cover-photo": "Update Cover Photo",
        "user-affiliation": {
          "add-affiliation": "Add affiliation",
          "affiliations": "Affiliations",
          "description": "Description",
          "name": "Name",
          "no-affiliation": "No affiliations added yet",
          "year-joined": "Year joined"
        },
        "video": {
          "add-video": "Add video",
          "delete-video": "Delete video",
          "edit-video": "Edit video url",
          "no-video-added": "You haven't added a video yet. ",
          "paste-youtube-video": "Paste YouTube or Vimeo video link here",
          "video": "Video",
          "video-updated": "Video was updated."
        },
        "work-experience": {
          "add-work-experience": "Add work experience",
          "clear-date": "Clear date",
          "description": "Description",
          "end-date": "End date",
          "no-work-experience-added": "You haven't added your work experience yet.",
          "organization": "Organization",
          "role": "Role",
          "start-date": "Start date",
          "work-experience": "Work experience",
          "work-experience-updated": "Work experience was updated."
        }
      },
      "coach-profiles": {
        "coaching-types": {
          "coaching-types": "Coaching Types",
          "create-new-coaching-type": "Create new coaching type",
          "no-added-types": "No coach types added yet.",
          "placeholder": "Select up to 3 tags",
          "type-of-coach": "Type of coach",
          "update-coach-types": "Update coach types"
        },
        "employee-levels": {
          "create-new-employee-level": "Create new level",
          "employee-levels": "Target Audience",
          "placeholder": "Select target audiences"
        },
        "industries": {
          "create-new-industry": "Create new industry",
          "industries": "Industries",
          "placeholder": "Select up to 7 tags"
        }
      },
      "coaches_per_page": "Coaches per page",
      "company": "company",
      "complete": "Complete",
      "completed": "Completed",
      "completed-on": "Completed on",
      "confirm": "Confirm",
      "confirmation": "Confirmation",
      "confirmation-dialog": {
        "connect-confirmation": "Are you sure you want to connect?",
        "internal-invitation-confirmation": "Once connected you will both have access to any mutual engagement data.This includes shared program items, resources, notes, goals and participant information."
      },
      "contact_email": "Contact Email",
      "country-label": "Country",
      "create": "Create",
      "credit-card-number-label": "Credit Card Number",
      "credit-or-debit": "Credit or debit card",
      "currency": "Currency",
      "current-timezone-helper": "Your current timezone will be set according to the timezone of the device you're using. Current browser timezone is <strong>{name}</strong>.",
      "custom-notifications-dialog": {
        "enable-notification": {
          "file": {
            "description": "Allows you to customize the notification that is sent when this file is sent",
            "main": "Customize file received notification"
          },
          "form": {
            "description": "Allows you to customize the notification that is sent when this form is sent",
            "main": "Customize form received notification"
          },
          "session": {
            "description": "Allows you to customize the notification that is sent when this session is scheduled",
            "main": "Customize schedule notification"
          },
          "todo": {
            "description": "Allows you to customize the notification that is sent when this task is sent",
            "main": "Customize task received notification"
          }
        },
        "notification-updated": "Notification updated.",
        "title": "Notifications"
      },
      "cvc-label": "CVC",
      "date": "Date",
      "date-of-birth": "Date of birth",
      "deactivate": "Deactivate",
      "decline": "Decline",
      "delete": "Delete",
      "delete-confirmation": {
        "fail": "{modelName} failed to delete.",
        "successful": "{modelName} successfully deleted."
      },
      "delete-prompt": "Are you sure you want to delete?",
      "delivery_time": "Delivery time",
      "department": "Department",
      "description": "Description",
      "details": "Details",
      "direct_coachees": "Direct coachees",
      "discard": "Discard",
      "discard-prompt": "Discard changes?",
      "disconnect": "Disconnect",
      "dismiss": "Dismiss",
      "done": "Done",
      "download": "Download",
      "dummy-email": "Email@email.com",
      "duplicate": "Make a copy",
      "edit": "Edit",
      "email": "Email",
      "employee-roles-dialog": {
        "add-new-role": "Add new role",
        "add-role": "Add role",
        "does-provide-coaching": "Does this employee provide coaching?",
        "employee-updated": "Employee updated.",
        "remove-role": "Remove role",
        "user-role-updated": "User roles updated."
      },
      "employee_provide_coaching": "Does this employee provide coaching?",
      "employees": "Employees",
      "empty-group": "No resources available.",
      "engagement": "Engagement",
      "enter_poc": "Please enter the point of contact email.",
      "experience": {
        "0-5": "0-5 years",
        "10-20": "10-20 years",
        "20-30": "20-30 years",
        "30+": "over 30 years",
        "5-10": "5-10 years"
      },
      "expiration-label": "Expiration",
      "facebook": "Facebook",
      "features": "Features",
      "files": "Files",
      "filter": "Filter",
      "filters": {
        "assessments": "Assessments",
        "availability": "Availability",
        "city": "City",
        "country": "Country",
        "credentials": "Credentials",
        "experience": "Experience",
        "gender": "Gender",
        "industries": "Industries",
        "leader_levels": "Target Audience",
        "modalities": "Modalities",
        "specialties": "Specialties",
        "type": "Type"
      },
      "finish": "Finish",
      "first-name": "First name",
      "found_user": "We found the user on Coaching.com!",
      "free-plan": "Free Plan",
      "gender-label": "Gender",
      "genders": {
        "female": "Female",
        "male": "Male",
        "other": "Other / Non-binary"
      },
      "goal": {
        "add-task": "Add Task",
        "deleted": "Goal deleted.",
        "feedback": "Request feedback",
        "private": "Private",
        "public": "Public",
        "stakeholder-feedback": "The following stakeholders will receive a request to provide a goal rating and optional comment on this goal. They will be able to see the goal name and goal description only. No milestones will be shared.",
        "sub-task": {
          "add-description": "Add a description",
          "add-new-milestone": "Add new milestone (max 500 characters)",
          "add-title": "Add a title",
          "creating-new-milestone": "Creating new milestone...",
          "delete-milestone": "Delete milestone",
          "send-reminder": "Send reminder",
          "set-due-date": "Set due date"
        },
        "title": "Goal"
      },
      "grid_view": "Grid view",
      "groups": "Folders",
      "inactivate": "Inactivate",
      "inactive": "Inactive",
      "invitation": "Invitation",
      "invite": "Invite",
      "invite-dialog": {
        "about-this-coach": "About this coach",
        "add-independent-coach-error": "This user can't be added as an independent coach.",
        "already-connected-coach-confirmation": "Do you wish to proceed?",
        "already-connected-coach-message": "You're already connected to this coach. If you wish to contract <strong>{employee}</strong> through <strong>{client}</strong>, you'll first need to terminate your prior relationship with <strong>{employee}</strong>.",
        "already-connected-poc-error": "This user can't be added as another company's admin because he's already your employee.",
        "coach-added": "Coach added.",
        "coach-requested": "Request to share coach sent.",
        "coachee-added": "Coachee added.",
        "coachee-requested": "Request to share coachee sent.",
        "confirm-user-message": "Please confirm that this is the {userType} you're trying to connect to.",
        "contact-email": "Contact email",
        "enter-email": "Enter the email",
        "enter-usertype-name": "Enter the {userType}'s name",
        "errors": {
          "existing-client": "You're already connected to this account.",
          "existing-coach": "This coach was already added.",
          "existing-coachee": "This coachee was already added.",
          "existing-employee": "An employee already exists with that email for this account.",
          "existing-poc": "This point of contact was already added.",
          "external-coach": "This email is already in use. Please try again."
        },
        "invalid-coach": "This coach cannot be added as an internal coach.",
        "invalid-email": "Invalid email.",
        "invalid-poc": "This email does not match the company administrator.",
        "invite-text": "If you don’t send the invite now this user will be added as an internal record that you can track. You can invite them later at any point to enable them to access their account.",
        "invite-types": {
          "client-company": {
            "description": "An organization that purchases coaching services.",
            "title": "Client company"
          },
          "client-poc": {
            "description": "Add a new point of contact",
            "title": "Add PoC"
          },
          "directly-contracted-coachee": {
            "description": "An individual client who pays for their own coaching.",
            "title": "Individual client"
          },
          "employee": {
            "description": "An employee of your organization",
            "title": "Employee"
          },
          "external-participant": {
            "description": "A participant that does not belong to your organization",
            "title": "External participant"
          },
          "external-vendor": {
            "description": "An organization that provides coaching services.",
            "title": "External vendor"
          },
          "independent-coach": {
            "description": "An independent coach, contracted directly.",
            "title": "Independent coach"
          },
          "internal-coach": {
            "description": "An employee of your company who provides coaching services.",
            "title": "Internal coach"
          },
          "request-coach": {
            "description": "Request a coach from a vendor organization",
            "title": "Request vendor coach"
          },
          "request-coachee": {
            "description": "Request a coachee from a client organization",
            "title": "Request client coachee"
          },
          "sponsored-coachee": {
            "description": "A coachee that is sponsored by a client company.",
            "title": "Sponsored coachee"
          },
          "vendor-provided-coach": {
            "description": "A coach who works with a coaching vendor or group of coaches.",
            "title": "Vendor contracted coach"
          }
        },
        "max-chars": "Your search term should have at least {n} characters.",
        "poc-added": "Point of contact added.",
        "request-add-description": "You are about to add a new coach to your cadre who will be available for coaching at {client}.",
        "user-types": {
          "poc": "point of contact"
        },
        "usertype-name": "About this {userType}",
        "verifying": "Verifying..."
      },
      "invite-later": "You can invite later",
      "invite-now": "Send invite now",
      "invite-resent-success": "Invite resent successfully",
      "invite-sent-success": "Invite sent successfully",
      "italic": "Italic",
      "language": {
        "en-us": "English (US)",
        "fr-fr": "French (FR)",
        "label": "Language"
      },
      "last-name": "Last name",
      "less_details": "Less details",
      "linkedin": "LinkedIn",
      "list_view": "List view",
      "loading": "Loading...",
      "location": "Location",
      "manage": "Manage",
      "meeting-locations-dialog": {
        "add-location-address": "Add location address",
        "add-new": "Add new",
        "add-phone-number": "Add phone number",
        "add-video-url": "Add video url",
        "default-location-saved": "Default meeting location updated.",
        "delete-meeting-location": "Delete meeting location",
        "location-deleted": "Meeting location deleted.",
        "location-updated": "Location details updated.",
        "meeting-locations": "Meeting locations",
        "no-locations": "No locations found.",
        "save-for-later": "Save for later",
        "saved": "Saved",
        "untitled-meeting-location": "Untitled meeting location"
      },
      "minimize": "Minimize",
      "more-options": "More options",
      "more_details": "More details",
      "na": "N/A",
      "name": "Name",
      "name-on-card": "Name on card",
      "next": "Next",
      "no": "No",
      "none": "None",
      "not_assigned": "Not assigned",
      "note": "Note",
      "notifications": {
        "sent_when_scheduled": "This notification will be sent when the session is scheduled."
      },
      "numbered-list": "Numbered list",
      "off": "Off",
      "ok": "OK",
      "on": "On",
      "onboarding": {
        "callout": "Get Started",
        "clients-slide": {
          "headline": "Win more clients",
          "subhead": "Invite clients to engage and collaborate on their coaching journey through client portals."
        },
        "coachee-connected-slide": {
          "headline": "Stay connected to your coach",
          "subhead": "Communicate quickly and easily with shared notes and goals."
        },
        "coachee-development-slide": {
          "headline": "Take charge of your development",
          "subhead": "Work with your coach to set goals and track your progress."
        },
        "coachee-schedule-slide": {
          "headline": "Schedule your next session",
          "subhead": "See the times your coach is available to schedule sessions."
        },
        "coaches-slide": {
          "headline": "All your coaches in one place",
          "subhead": "Create engagements with internal and contracted coaches."
        },
        "dashboard-slide": {
          "headline": "Stay on top of things",
          "subhead": "Your dashboard lets you know what's happening and what to do next."
        },
        "enterprise-scale-slide": {
          "headline": "Develop talent at scale",
          "subhead": "Manage company-wide coaching with fewer resources."
        },
        "invoicing-slide": {
          "headline": "Easy invoicing and payments",
          "subhead": "Send invoices and make payments from your account."
        },
        "provider-scale-slide": {
          "headline": "Scale your coaching business.",
          "subhead": "Deliver more with fewer resources."
        },
        "reports-slide": {
          "headline": "One-click reporting",
          "subhead": "Keep stakeholders up to date with relevant reports."
        },
        "scheduling-slide": {
          "headline": "A better way to schedule",
          "subhead": "Calendar settings lets your clients know when you're available."
        },
        "stripe-invoicing-slide": {
          "headline": "Easy invoicing and payments",
          "subhead": "Send invoices and make payments with our Stripe payment integration."
        }
      },
      "or": "or",
      "page-size": "Page size",
      "phone": "Phone",
      "plaform-language-label": "Platform Language",
      "platform-language-label": "Platform Language",
      "poc_email": "Point of contact email",
      "postal-code-label": "Postal code",
      "previous": "Previous",
      "primary": "Primary",
      "purchase": "Purchase",
      "rate": "Rate",
      "rating": "out of 10",
      "reject": "Reject",
      "remove": "Remove",
      "remove-chip": "Press delete to remove this chip.",
      "remove_role": "Remove role",
      "rename": "Rename",
      "resend": "Resend",
      "resources": "Resources",
      "revert": "Revert",
      "review-dialog": {
        "leave-feedback": "Leave feedback"
      },
      "reviews": {
        "feedback": "Your feedback is important. Please provide feedback on {coacheeName}'s goal progress below.",
        "feedback-placeholder": "Feedback",
        "no-improvement": "No improvement",
        "no-need": "No need to improve further",
        "num": {
          "eight": "8",
          "five": "5",
          "four": "4",
          "nine": "9",
          "one": "1",
          "seven": "7",
          "six": "6",
          "ten": "10",
          "three": "3",
          "two": "2"
        },
        "provide-feedback": "Provide feedback",
        "rating": "Rating",
        "recorded": "Your review has been recorded.",
        "subtitle": "Help by giving some feedback about {coacheeName}'s progress.",
        "thanks": "Thank you!",
        "title": "{coacheeName} needs some feedback to develop: {reviewableName}"
      },
      "save": "Save",
      "saved": "Saved",
      "saving": "Saving...",
      "schedule": "Schedule",
      "scheduled-for": "Scheduled for",
      "search": "Search",
      "select": "Select",
      "select-all": "Select all",
      "select_end_date": "Select end date",
      "select_start_date": "Select start date",
      "send": "Send",
      "send-invite": "Send Invite",
      "send_invitation": "Send invitation",
      "send_invitation_optional": "Send invitation?",
      "send_invite_later": "You can add this person and invite later.",
      "sending": "Sending...",
      "sent": "Sent",
      "share": "Share",
      "share-dialog": {
        "add-new-share": "Share with others",
        "add-people-groups": "Add people or groups",
        "can-copy": "Can copy",
        "can-edit": "Can edit",
        "can-share": "Can share",
        "can-view": "Can view",
        "delete-tooltip": "Stop sharing",
        "edit-note": "Edit note",
        "empty-search": "No employees or groups match your search.",
        "groups": {
          "coaching-pools": {
            "description": "Only coaches that belong to coach pools",
            "name": "Coaching pools"
          },
          "engagement-editors": {
            "description": "Only employees that are engagement editors",
            "name": "Engagement editors"
          },
          "external-coaches": {
            "description": "Only coaches that do not belong to your account",
            "name": "External coaches"
          },
          "global-admin": {
            "description": "Only global admins of your account",
            "name": "Global admins"
          },
          "internal-coaches": {
            "description": "Only coaches that belong to your account",
            "name": "Internal coaches"
          },
          "location-admin": {
            "description": "Only admins that belong to your location",
            "name": "Location admins"
          },
          "location-coaches": {
            "description": "Only coaches that belong to your location",
            "name": "Location coaches"
          },
          "stakeholders": {
            "description": "Anyone with access to this engagement.",
            "name": "Everyone"
          }
        },
        "item-not-shared": "This item isn't shared with anyone yet.",
        "private-note": "Private note",
        "remove-share": "Remove share",
        "share-note": "Share note",
        "shared-on": "Shared on {date}",
        "shared-with": "Shared with",
        "sharing-confirmation": "If you do not want others to make changes or view results, make a duplicate and share the copy instead.",
        "sharing-warning": "The admins you are sharing this form with will be able make changes and view aggregate results."
      },
      "sharing-removed": "Sharing removed.",
      "sharing-with": "Sharing with",
      "skip": "Skip",
      "sms-preview": {
        "document": "You have a coaching document awaiting review.",
        "form": "You were sent a coaching form.",
        "new-scheduled": "You have a new coaching session scheduled at [time] on [short date format].",
        "new-session": "You have a new coaching session coming up at [time] on [short date format].",
        "rescheduled": "A coaching session was rescheduled for [time] on [short date format].",
        "schedule": "Schedule your next coaching session.",
        "task": "You have a coaching task due.",
        "task-login": "You have a coaching task due. Login to mark it complete.",
        "thank-attending": "Thank you for attending the coaching session on [short date format]."
      },
      "something-went-wrong": "Something went wrong",
      "stakeholder": "Stakeholder:",
      "state-label": "State",
      "status": "Status",
      "stop_sharing": "Stop sharing",
      "style": {
        "bold": "*bold*",
        "italics": "_italics_",
        "strike": "~strike~"
      },
      "summary-data": {
        "active-clients": "Total clients",
        "active-coaches": "Active coaches",
        "active-engagements": "Active engagements",
        "active-vendors": "Total vendors",
        "allocated-engagements": "Allocated engagements",
        "amount-due": "Amount due",
        "amount-outstanding": "Amount outstanding",
        "amount-owed": "Amount owed",
        "amount-paid": "Amount paid",
        "approved-coaches": "Approved coaches",
        "coach-approvals-pending": "Coach approvals pending",
        "completed-engagements": "Completed engagements",
        "draft-engagements": "Draft engagements",
        "hours-cancelled": "Hours canceled",
        "hours-completed": "Hours completed",
        "hours-remaining": "Hours remaining",
        "hours-scheduled": "Hours scheduled",
        "invoices-received": "Invoices received",
        "invoices-sent": "Invoices sent",
        "matching-engagements": "Matching engagements",
        "no-engagements-found": "No engagements found.",
        "paid": "Paid",
        "sessions-cancelled": "Sessions canceled",
        "sessions-completed": "Sessions completed",
        "sessions-remaining": "Sessions remaining",
        "sessions-scheduled": "Sessions scheduled",
        "total-amount": "Total amount",
        "total-clients": "Total clients",
        "total-coaches": "Total coaches",
        "total-engagements": "Total engagements",
        "total-invoiced": "Total invoiced",
        "total-invoices": "Total vendors",
        "total-outstanding": "Total outstanding",
        "total-paid": "Total paid",
        "total-revenue": "Total revenue",
        "total-sessions": "Total sessions",
        "total-vendors": "Total vendors"
      },
      "tabs": {
        "coach-profile": "Profile",
        "coachees": "Coachees",
        "coaches": "Coaches",
        "details": "Details",
        "engagements": "Engagements",
        "invoices": "Invoices",
        "people": "People",
        "profile": "Profile",
        "reviews": "Reviews",
        "summary": "Summary"
      },
      "timezone": "Timezone",
      "timezone-offset": "(GMT {offset})",
      "type": {
        "external": "External",
        "internal": "Internal"
      },
      "unavailable-for-new-clients": "Unavailable for New Clients",
      "upcoming-sessions": "Upcoming Sessions",
      "update": "Update",
      "upgrade": "Upgrade",
      "upload-dialog": {
        "data-title": "Data",
        "download-csv-template": "Download CSV Template",
        "drop-label": "Drop your CSV file here",
        "error-number": "Found {num} {num, plural, one {error} other {errors}} of this type",
        "error-types": {
          "alternatives.types": "Invalid number",
          "any.only": "Unexpected values",
          "any.required": "Empty values",
          "array.includesRequiredKnowns": "Missing columns",
          "array.min": "Empty file",
          "csv-error": "CSV error",
          "date.format": "Invalid date",
          "number.positive": "Invalid number",
          "string.email": "Invalid e-mails",
          "string.empty": "Empty values",
          "string.max": "Too many characters"
        },
        "errors": {
          "InvalidQuotes": "Trailing quote on quoted field is malformed.",
          "MissingQuotes": "A quoted field is unterminated.",
          "TooFewFields": "Row {row} has too few columns.",
          "TooManyFields": "Row {row} has too many columns.",
          "alternatives.types": "\"{value}\" must be a number.",
          "any.only": "Column {key} only possible values are {valids}.",
          "any.required": "Column {key} must not have empty values.",
          "array.includesRequiredKnowns": "Columns {knownMisses} are required but were not included in the CSV file.",
          "array.min": "File should have at least 1 row.",
          "date.format": "\"{value}\" must be in one of the following formats: {format}.",
          "number.positive": "\"{value}\" must be a positive number.",
          "string.email": "\"{value}\" is not a valid e-mail address.",
          "string.empty": "Column {key} must not have empty values.",
          "string.max": "Column {key} must not exceed {limit} characters."
        },
        "errors-label": "Errors",
        "errors-title": "Errors",
        "parsing-file": "Parsing file...",
        "success": "Successfully parsed {number} rows.",
        "try-again": "Try again",
        "try-another-file": "Try another file",
        "upload-failed": "File uploaded failed.",
        "upload-success": "File uploaded successfully.",
        "upload-types": {
          "client-company": {
            "description": "An organization that purchases coaching services.",
            "title": "Upload Client Companies"
          },
          "client-poc": {
            "description": "Add a new point of contact",
            "title": "Add PoC"
          },
          "create-engagements": {
            "description": "Download CSV to create new engagements",
            "title": "Create New Engagements"
          },
          "directly-contracted-coachee": {
            "description": "An individual client who pays for their own coaching.",
            "title": "Upload Individual Clients"
          },
          "employee": {
            "description": "An employee of your organization",
            "title": "Upload Employees"
          },
          "external-participant": {
            "description": "A participant that does not belong to your organization",
            "title": "External participant"
          },
          "external-vendor": {
            "description": "An organization that provides coaching services.",
            "title": "Upload Vendor Companies"
          },
          "independent-coach": {
            "description": "An independent coach, contracted directly.",
            "title": "Upload Independent Coaches"
          },
          "internal-coach": {
            "description": "An employee of your company who provides coaching services.",
            "title": "Internal coach"
          },
          "request-coach": {
            "description": "Request a coach from a vendor organization",
            "title": "Request vendor coach"
          },
          "request-coachee": {
            "description": "Request a coachee from a client organization",
            "title": "Request client coachee"
          },
          "sponsored-coachee": {
            "description": "A coachee that is sponsored by a client company.",
            "title": "Sponsored coachee"
          },
          "update-engagements": {
            "description": "Download CSV to update existing engagements",
            "title": "Update Engagements"
          },
          "vendor-provided-coach": {
            "description": "A coach who works with a coaching vendor or group of coaches.",
            "title": "Vendor contracted coach"
          }
        },
        "uploading-file": "Uploading file..."
      },
      "user-exists": "user exists",
      "vat-id-label": "VAT ID",
      "vendor_type": "Vendor Type",
      "version": "Coaching.com 5.0",
      "view": "View",
      "website": "Website",
      "yes": "Yes"
    },
    "v2": {
      "common": {
        "active": "Active",
        "address": "Address",
        "address-2": "Address 2",
        "cancel": "Cancel",
        "city": "City",
        "clear": "Clear",
        "coach_selection": "Coach Matching",
        "complete": "Complete",
        "completed": "Completed",
        "delete": "Delete",
        "description": "Description",
        "draft": "Draft",
        "draft_coach_selection": "Coach Matching Draft",
        "edit": "Edit",
        "email": "Email",
        "end-date": "End date",
        "facebook": "Facebook",
        "filter": "Filter",
        "first-name": "First name",
        "fluency": "Fluency",
        "inactive": "Inactive",
        "interview-session": "Interview Session",
        "language": "Language",
        "languages": "Languages",
        "last-name": "Last name",
        "linkedin": "LinkedIn",
        "location": "Location",
        "modalities": "Modalities",
        "name": "Name",
        "organization": "Organization",
        "phone": "Phone",
        "platform-langugage": "Platform Language",
        "postal-code": "Postal Code",
        "present": "Present",
        "quick-view": "Quick view",
        "rate": "Rate",
        "request-available-times": "Request Available Times",
        "role": "Role",
        "save": "Save",
        "schedule-interview": "Schedule Interview",
        "search": "Search",
        "search-placeholder": "Type to filter options",
        "select": "Select",
        "select-coach": "Select Coach",
        "select-country": "Select a country",
        "select-gender": "Gender",
        "selected": "Selected",
        "sort": "Sort",
        "start-date": "Start Date",
        "state": "State/Province",
        "summary": "Summary",
        "title": "Title",
        "twitter": "Twitter",
        "unavailable": "Unavailable",
        "upcoming-interviews": "Upcoming Interviews",
        "update": "Update",
        "viewing": "Viewing",
        "website": "Website",
        "year-joined": "Year joined",
        "years": "Years"
      },
      "employee": {
        "profile": {
          "add-industries": "Add industries",
          "add-language": "Add Language",
          "add-levels": "Add levels",
          "add-modalities": "Add modalities",
          "add-types": "Edit types",
          "affiliations": "Affiliations",
          "affiliations-updated": "Affiliations were updated.",
          "assessment-name": "Assessment name",
          "assessments": "Assessments",
          "assessments-updated": "Assessments updated.",
          "authors": "Authors",
          "certified-ass": "Certified Assessment",
          "client-number": "Completed engagements",
          "clients": "Clients",
          "coach-type": "Type of coach",
          "coached-hours": "Number of coached hours",
          "coaching-info": "Coaching Information",
          "coaching-information-updated": "Coaching information updated",
          "create-affiliation": "Create new affiliation",
          "create-assessment": "Create assessment",
          "create-credential": "Create credential",
          "create-education": "New degree",
          "create-publication": "Create publication",
          "create-work-experience": "Create work experience",
          "cred-id": "Credential ID No.",
          "credentials": "Credentials",
          "credentials-updated": "Credentials updated.",
          "custom-issuing-org": "Organization name",
          "date": "Date",
          "degree": "Degree",
          "description": "Description",
          "does-not-expire": "Does not expire",
          "edit-affiliation": "Edit affiliation",
          "edit-assessment": "Edit assessment",
          "edit-credential": "Edit credential",
          "edit-education": "Edit degree",
          "edit-language": "Edit Language",
          "edit-publication": "Edit publication",
          "edit-work-experience": "Edit work experience",
          "education": "Education",
          "education-updated": "Education updated",
          "expiration-date": "Expiration date",
          "graduation-date": "Graduation date",
          "hours": "Hours",
          "industries": "Industries",
          "issuing-date": "Issuing date",
          "issuing-org": "Issuing Organization",
          "languages-updated": "Languages were updated.",
          "leader-levels": "Target Audience",
          "link": "Link",
          "major": "Field of study",
          "modalities": "Modalities",
          "no-assessments": "You haven't added any assessments yet.",
          "no-credentials": "You haven't added any credentials yet.",
          "no-education": "You haven't added your education yet.",
          "no-publications": "You havent added any publications yet.",
          "publications": "Publications",
          "publications-updated": "Publications updated.",
          "publisher": "Publisher",
          "school": "School",
          "select-coaching-types": "Select coaching types",
          "select-industires": "Select industries",
          "select-levels": "Select levels",
          "select-modalities": "Select modalities",
          "select-specialties": "Select specialties",
          "specialties": "Specialties",
          "title": "Title",
          "unsaved-changes-dialog": {
            "leave": "Leave",
            "message": "You have unsaved changes. By selecting <strong>LEAVE</strong> you will discard any unsaved changes. Would you like to proceed?",
            "stay": "Stay",
            "title": "Unsaved Changes"
          },
          "work-experience": "Work experience",
          "work-experience-updated": "Work experience updated",
          "years": "Year(s)",
          "years-certified": "Years certified",
          "years-coaching": "Years coaching",
          "youtube-input-placeholder": "Enter YouTube or Vimeo video link here"
        }
      },
      "engagement": {
        "coach-name": "Coach",
        "completed": "Completed",
        "default-title": "Custom Program",
        "display-completed": "Display Completed",
        "empty-program": "Program is empty",
        "engagement-with": "Engagement with",
        "feedback-max-score": "out of 10",
        "goal": {
          "goals-title": "Goals",
          "reviews": {
            "review-change": "from first rating",
            "review-label": "Feedback for: "
          }
        },
        "goals-label": "Goals",
        "in-progress": "In Progress",
        "join-session": "Join session",
        "join-session-description": "Join the video call for this session.",
        "location-default": "Location not set",
        "matching-process": {
          "assesement": "Assessments",
          "assessments": "Assessments",
          "close-filters": "Close filters",
          "close-quick-view": "Close quick view",
          "coach-selection": "Coach Selection",
          "coach-selection-message": "Welcome to coaching! Congratulations in your choice to focus time and energy on your own growth. I am passion about supporting you to extend your reach and become even more than you thought possible.",
          "coach-type": "Coach Type",
          "coach-types": "Coach Types",
          "coaching-pools": "Coaching Pools",
          "coaching-types": "Coaching Types",
          "credentials": "Credentials",
          "employee-levels": "Employee Levels",
          "engagement-editors": "Engagement editors",
          "experience": "Experience",
          "external-coaches": "External Coaches",
          "gender": "Gender",
          "global-admin": "Global Admin",
          "industries": "Industries",
          "internal-coaches": "Internal Coaches",
          "languages": "Languages",
          "leader-levels": "Target Audience",
          "location": "Location",
          "location-admin": "Location Admin",
          "location-coaches": "Location Coaches",
          "modalities": "Modalities",
          "no-coaches": "No coaches",
          "no-completed-interviews": "No completed interviews",
          "no-upcoming-interviews": "No upcoming interviews",
          "specialties": "Specialties",
          "title": "Matching Process",
          "view-full-profile": "View full profile"
        },
        "message": "Message",
        "milestones": "Milestones",
        "next-scheduled-session": "Next Scheduled Session",
        "next-unscheduled-session": "Schedule your Next Session",
        "no-assigned-tasks": "No assigned tasks",
        "no-current-goals": "No current goals",
        "no-recent-feedback": "No recent feedback",
        "no-sessions-scheduled": "No sessions scheduled",
        "program": {
          "add-new": "Add new",
          "program-title": "Program"
        },
        "program-overview": "Program Overview",
        "schedule-session": "Schedule Session",
        "settings": {
          "completed": "Completed",
          "contract": {
            "no-contract": "No contract added"
          },
          "general": {
            "date-placeholder": "Select a date",
            "end-date": "End date",
            "start-date": "Start date",
            "status": "Status"
          },
          "remaining": "Remaining",
          "stakeholders": {
            "add-stakeholder": "Add stakeholder",
            "creator": "Creator",
            "editor": "Editor",
            "editors": "Editors",
            "help": "Add people that you want to View or Edit this Engagement here. Click to learn more.",
            "select-editor": "Select editor",
            "select-viewer": "Select viewer",
            "title": "Engagement Stakeholders",
            "viewer": "Viewer",
            "viewers": "Viewers"
          },
          "total": "total",
          "welcome-message": "Welcome Message",
          "welcome-message-title": "Welcome Message Title"
        },
        "sidenav": {
          "dashboard": "Dashboard",
          "goals": "Goals",
          "matching-process": "Matching process",
          "program": "Program",
          "resources": "Resources"
        },
        "start-session": "Start session",
        "start-session-description": "Start a video call for this session.",
        "tasks-label": "Tasks",
        "view-all": "VIEW ALL",
        "view-details": "VIEW DETAILS",
        "welcome": "Welcome to Coaching! Congratulations in your choice to focus time and energy on your own growth. I am passionate about supporting you to extend your reach and become even more than you thought possible Coaching is about you as a whole person: your values, goals, work, balance, fulfillment, and life purpose. We will design a plan that is specific to your needs and aspirations. Schedule your first session with your coach below to get started.",
        "welcome-title": "Welcome to Coaching.com"
      },
      "messaging": {
        "direct-messages": "Direct Messages",
        "groups": "Groups",
        "new-message": "New message",
        "no-channels": "Let’s get this convo started!",
        "title": "Messages"
      }
    },
    "vendors": {
      "add-coach": "Add a coach",
      "add-coach-or-vendor": "Add coach or vendor",
      "add-vendor": "Add vendor",
      "create-workflow": {
        "account-name-label": "Company name",
        "email-label": "Contact email",
        "first-name-label": "Contact first name",
        "last-name-label": "Contact last name",
        "title": "Create vendor"
      },
      "description": "Coaching companies and independent coaches you have contracted.",
      "loading": "Loading vendors...",
      "no-coachees": "No coachees",
      "no-coaches": "No coaches",
      "no-vendors": "No vendors",
      "search-placeholder": "Search vendors",
      "stats": {
        "active-coaches": "Active Coaches",
        "approved-coaches": "Approved Coaches",
        "pending-coach-approval": "Pending Coach Approval"
      },
      "table-header": {
        "name": "Name",
        "status": "Status"
      },
      "title": "Vendors",
      "type": {
        "all": "All",
        "independent_coach": "Independent coach",
        "provider": "Business"
      },
      "upload-vendors": "Upload vendors",
      "vendor-type": "Vendor type"
    },
    "video": {
      "audio-input-label": "Microphone",
      "audio-input-placeholder": "Please select an audio input",
      "audio-output-label": "Speaker",
      "audio-output-placeholder": "Please select an audio output",
      "audio-status-off": "Audio: OFF",
      "audio-status-on": "Audio: ON",
      "cant-join-message": "You can't join this video session. To join a video session the current time must be within scheduled session start/end times and the meeting location must be set to Coaching.com video.",
      "input-level": "Input level",
      "join-interview": "Join interview",
      "join-session": "Join session",
      "play-test": "Play test sound",
      "prompt-join": "{name}, ready to join the session?",
      "prompt-start": "{name}, ready to start the session?",
      "settings": "Settings",
      "start-interview": "Start interview",
      "start-session": "Start session",
      "stop-test": "Stop test sound",
      "tooltips": {
        "audio-disable": "Mute",
        "audio-enable": "Unmute",
        "collapse": "Hide controls",
        "expand": "Show controls",
        "leave": "Leave call",
        "screen-share-disable": "Stop sharing screen",
        "screen-share-enable": "Share screen",
        "settings-disable": "Hide settings",
        "settings-enable": "Show settings",
        "video-disable": "Disable video",
        "video-enable": "Enable video"
      },
      "video-input-label": "Video",
      "video-input-placeholder": "Please select a video input",
      "video-status-off": "Video: OFF",
      "video-status-on": "Video: ON",
      "waiting-for-coach": "Welcome to your session, it will begin when your coach arrives.",
      "waiting-for-others": "Waiting for other(s) to join..."
    }
  };
  _exports.default = _default;
});