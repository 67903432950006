define("coachlogix/abilities/coach-profile", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    account: Ember.inject.service(),
    canEdit: Ember.computed('model.id', 'account.activeUser.id', function () {
      return this.get('model.id') === this.get('account.activeUser.id');
    }),
    canShowCoachAvailability: Ember.computed('account.activeEmployee.isCoach', function () {
      return this.get('account.activeEmployee.isCoach');
    })
  });

  _exports.default = _default;
});