define("coachlogix/models/task", ["exports", "@ember-data/model", "ember-data-copyable", "ember-concurrency", "moment", "coachlogix/models/assignable", "coachlogix/models/base-model", "coachlogix/models/commentable", "coachlogix/models/competenciable", "coachlogix/models/custom-notifiable", "coachlogix/models/reviewable", "coachlogix/models/stakeholderable"], function (_exports, _model, _emberDataCopyable, _emberConcurrency, _moment, _assignable, _baseModel, _commentable, _competenciable, _customNotifiable, _reviewable, _stakeholderable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_assignable.default, _commentable.default, _emberDataCopyable.default, _reviewable.default, _stakeholderable.default, _competenciable.default, _customNotifiable.default, {
    intl: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    description: (0, _model.attr)('string'),
    publishedDate: (0, _model.attr)('datetime'),
    completedDate: (0, _model.attr)('datetime'),
    status: (0, _model.attr)('string'),
    // draft || published || completed
    targetDate: (0, _model.attr)('date'),
    taskType: (0, _model.attr)('string'),
    taskableType: (0, _model.attr)('string'),
    title: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    templateDefaultCoach: (0, _model.attr)('boolean'),
    templateDefaultCoachee: (0, _model.attr)('boolean'),
    engagement: (0, _model.belongsTo)('engagement', {
      async: true,
      inverse: null
    }),
    parent: (0, _model.belongsTo)('task', {
      async: true,
      inverse: 'subTasks'
    }),
    resource: (0, _model.belongsTo)('resource', {
      async: true,
      inverse: null
    }),
    taskable: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      inverse: null
    }),
    subTasks: (0, _model.hasMany)('task', {
      async: true,
      inverse: 'parent'
    }),
    programItem: (0, _model.belongsTo)('program-item', {
      async: true,
      inverse: null
    }),
    isCompleted: Ember.computed.equal('status', 'completed'),
    isActive: Ember.computed.equal('status', 'active'),
    isPublished: Ember.computed.equal('status', 'published'),
    canBeMovedOnFrom: Ember.computed.or('isCompleted', 'isActive'),
    isForm: Ember.computed.equal('taskType', 'form'),
    isFile: Ember.computed.equal('taskType', 'file'),
    isTodo: Ember.computed.equal('taskType', 'todo'),
    isLink: Ember.computed.equal('taskType', 'link'),
    isMilestone: Ember.computed.equal('taskType', 'milestone'),
    completedAssignments: Ember.computed.filterBy('assignments', 'status', 'completed'),
    lastReminder: Ember.computed.alias('assignments.firstObject.lastReminder'),
    lastReminderDate: Ember.computed('lastReminder', 'publishedDate', function () {
      const lastReminder = this.get('lastReminder');
      const publishedDate = this.get('publishedDate');
      return lastReminder ? lastReminder : (0, _moment.default)(publishedDate);
    }),
    displayTitle: Ember.computed('title', function () {
      if (this.get('title')) {
        return this.get('title');
      }

      return this.get('intl').t('engagement-task.default-name');
    }),
    notificationType: Ember.computed('taskType', function () {
      let taskType = this.get('taskType');

      if (taskType === 'form') {
        return 'engagement_form_received';
      } else if (taskType === 'file') {
        return 'engagement_program_file_received';
      } else if (taskType === 'todo' || taskType === 'milestone') {
        return 'engagement_task_sent';
      } else if (taskType === 'link') {
        return 'engagement_program_link_sent';
      }
    }),
    isFromTemplate: Ember.computed.equal('taskable.modelName', 'engagement-template'),
    updateTitle: (0, _emberConcurrency.task)(function* (title) {
      this.set('title', title);
      yield this.get('saveTask').perform();
    }),
    updateDescription: (0, _emberConcurrency.task)(function* (description) {
      this.set('description', description);
      yield this.get('saveTask').perform();
    }),
    publish: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('status', 'published');
        yield this.save();
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    setTaskDate: (0, _emberConcurrency.task)(function* (taskDate) {
      try {
        let toastMessage = '';

        if (this.get('isCompleted')) {
          this.set('completedDate', taskDate);
          toastMessage = this.get('intl').t('engagement-task.completed-date-set');
        } else {
          this.set('targetDate', taskDate);
          toastMessage = this.get('intl').t('engagement-task.target-date-set');
        }

        yield this.save();
        this.clToaster.success(toastMessage);
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    republish: (0, _emberConcurrency.task)(function* () {// return `helpers/resend-notification/?recipient_id=354&notifiable_type=employees&notifiable_id=386&type_key=invited_employee_account`
    }),
    deleteTask: (0, _emberConcurrency.task)(function* () {
      try {
        let taskable = yield this.get('taskable');
        yield this.destroyRecord();

        if (taskable) {
          let tasks = yield taskable.get('tasks');

          if (tasks) {
            tasks.removeObject(this);
          }
        }

        this.clToaster.success(this.intl.t('models.model-deleted', {
          model: this.modelName
        }));
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    completeTask: (0, _emberConcurrency.task)(function* (date) {
      let completedDate = date || new Date();

      try {
        this.set('status', 'completed');
        this.set('completedDate', completedDate);
        yield this.save();
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    duplicate: (0, _emberConcurrency.task)(function* () {
      this.clToaster.info(this.intl.t('models.task.duplicating-task'));

      try {
        // Fetch all the data.
        yield this.reload();
        let programItem = yield this.get('programItem');
        let program = yield programItem.get('program'); // Create duplicate.

        let duplicatedItem = this.store.createRecord('program-item', {
          clonedFrom: programItem
        });
        let lastPosition = programItem.get('programable.programItems').sortBy('position').get('lastObject.position') + 1;
        duplicatedItem.set('position', lastPosition);
        yield duplicatedItem.save(); // Add duplicated item to the local item list.

        program.get('programItems').pushObject(duplicatedItem);
        this.clToaster.success(this.intl.t('models.task.task-duplicated'));
        return duplicatedItem;
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});