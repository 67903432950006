define("coachlogix/pods/engagement/resources/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "abtqRoCT",
    "block": "{\"symbols\":[\"alert\"],\"statements\":[[6,[37,1],[[32,0,[\"model\",\"engagementTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cl-h-screen cl-w-full cl-flex cl-justify-center cl-items-center\"],[12],[2,\"\\n    \"],[8,\"cl-progress-circular\",[],[[\"@size\"],[48]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[30,[36,1],[[30,[36,0],[\"see header in engagement\",[32,0,[\"resourceable\"]]],null],\"cl-text-primary-contrast cl-mb-7\",\"cl-mb-3\"],null]],[12],[2,\"\\n    \"],[10,\"h1\"],[15,0,[31,[\"cl-title-1 \",[30,[36,1],[[30,[36,0],[\"see header in engagement\",[32,0,[\"resourceable\"]]],null],\"cl-text-primary-contrast\"],null]]]],[12],[1,[30,[36,2],[\"resources.title\"],null]],[13],[2,\"\\n\\n    \"],[8,\"cl-alert\",[[24,0,\"cl-mt-2 cl-rounded-sm\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"icon\"]],[],[[\"@icon\"],[\"group\"]],null],[2,\"\\n      \"],[8,[32,1,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[\"engagement.resources.permissions-notice\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"v2/resources/resources-view\",[],[[\"@resourceable\"],[[32,0,[\"resourceable\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"cannot\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/engagement/resources/template.hbs"
    }
  });

  _exports.default = _default;
});