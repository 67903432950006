define("coachlogix/pods/engagement/controller", ["exports", "ember-ajax/errors", "ember-concurrency-decorators"], function (_exports, _errors, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EngagementController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.reads('model.engagementTask.value'), _dec4 = Ember.computed.reads('model.engagementsTask.value'), (_class = (_temp = class EngagementController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "twilio", _descriptor2, this);

      _initializerDefineProperty(this, "engagement", _descriptor3, this);

      _initializerDefineProperty(this, "engagements", _descriptor4, this);
    }

    *updateStatus(status) {
      try {
        if (status === 'active') {
          let defaultCoachCapacity = this.engagement.get('ownerAccount.defaultCoachCapacity');
          let employeeCapacity = this.engagement.get('coach.coachCapacity');
          let activeEngagements = this.engagement.get('coach.activeEngagements');

          if (employeeCapacity) {
            if (activeEngagements > 0 && activeEngagements >= employeeCapacity) {
              this.set('coachIsAtCapacityStartConfirmation', true);
            } else {
              yield this.engagement.updateStatus.linked().perform(status);
            }
          } else if (defaultCoachCapacity) {
            if (activeEngagements > 0 && activeEngagements >= defaultCoachCapacity) {
              this.set('coachIsAtCapacityStartConfirmation', true);
            } else {
              yield this.engagement.updateStatus.linked().perform(status);
            }
          } else {
            yield this.engagement.updateStatus.linked().perform(status);
          }
        } else if (status === 'active_confirm') {
          yield this.engagement.updateStatus.linked().perform('active');
        } else {
          yield this.engagement.updateStatus.linked().perform(status);
        }
      } catch (e) {
        if ((0, _errors.isBadRequestError)(e)) {
          this.set('errors', e.payload.errors);
          this.set('upgrade', true);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "twilio", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "engagement", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "engagements", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateStatus", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateStatus"), _class.prototype)), _class));
  _exports.default = EngagementController;
});