define("coachlogix/models/company-location", ["exports", "@ember-data/model", "coachlogix/models/adminable", "coachlogix/models/base-model"], function (_exports, _model, _adminable, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_adminable.default, {
    name: (0, _model.attr)('string'),
    about: (0, _model.attr)('string'),
    logo: (0, _model.attr)('string'),
    headerImage: (0, _model.attr)('string'),
    primaryColor: (0, _model.attr)('string'),
    accentColor: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    address2: (0, _model.attr)('string'),
    postalCode: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    facebook: (0, _model.attr)('string'),
    linkedin: (0, _model.attr)('string'),
    website: (0, _model.attr)('string'),
    taxId: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    planEndsAt: (0, _model.attr)('date'),
    trialEndsAt: (0, _model.attr)('date'),
    account: (0, _model.belongsTo)('account', {
      inverse: 'companyLocations'
    }),
    employees: (0, _model.hasMany)('employee', {
      inverse: null
    }),
    primaryContact: (0, _model.belongsTo)('user', {
      inverse: null
    }),

    /** returns a promise
    adminUsers: function(){
      return this.get('role-user').then(function(admins){
        return admins.mapBy('user');
      });
    }.property('admins.@each.user'),
     */
    fullAddress: Ember.computed('address', 'address2', 'city', 'country', 'coutry', 'postalCode', 'state', function () {
      const address = this.get('address');
      const address2 = this.get('address2');
      const city = this.get('city');
      const state = this.get('state');
      const postalCode = this.get('postalCode');
      const coutry = this.get('coutry');
      let addressComponents = [];
      if (address) addressComponents.push(address);
      if (address2) addressComponents.push(address2);
      if (city) addressComponents.push(city);
      if (state) addressComponents.push(state);
      if (postalCode) addressComponents.push(postalCode);
      if (coutry) addressComponents.push(coutry);

      if (addressComponents.length === 0) {
        return this.get('intl').t('ui.na');
      }

      return addressComponents.join(', ');
    }),
    intl: Ember.inject.service()
  });

  _exports.default = _default;
});