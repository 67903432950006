define("coachlogix/pods/components/v2/messaging/channel-pane/messages/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aq4cOP5D",
    "block": "{\"symbols\":[\"@message\",\"@showTimestamp\",\"@isOwn\",\"&attrs\"],\"statements\":[[8,\"cl-card\",[[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-p-2 \",[30,[36,1],[[32,3],\"cl-bg-primary cl-text-primary-contrast\"],null],\" cl-max-w-24 \",[30,[36,2],[[32,2],\"cl-mb-1\"],null]]]],[17,4]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,3],[[32,1,[\"body\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cl-title-7 cl-mb-1 cl-mt-0.5 cl-mx-0.5\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isToday\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,1,[\"dateCreated\"]],\"LT\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,1,[\"dateCreated\"]],\"LLL\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"if\",\"unless\",\"markdown\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/messaging/channel-pane/messages/text/template.hbs"
    }
  });

  _exports.default = _default;
});