define("coachlogix/pods/components/v2/messaging/channel-pane/messages/system/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LBf9h15B",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"styleNamespace\"]],\" cl-w-full cl-text-center cl-body-2 cl-font-bold cl-px-1 cl-py-0.5 cl-uppercase cl-text-secondary cl-mb-1\"]]],[17,1],[12],[2,\"\\n  \"],[1,[35,0,[\"body\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"message\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/messaging/channel-pane/messages/system/template.hbs"
    }
  });

  _exports.default = _default;
});