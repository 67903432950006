define("coachlogix/pods/invoices/components/create-dialog/after-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zVzV+RRT",
    "block": "{\"symbols\":[\"&attrs\",\"@value\",\"@onToggle\",\"@disabled\"],\"statements\":[[11,\"div\"],[24,0,\"cl-px-3 cl-py-1\"],[17,1],[12],[2,\"\\n  \"],[8,\"cl-switch\",[],[[\"@value\",\"@onChange\",\"@disabled\"],[[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"engagements.create-dialog.only-complete-uninvoiced\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/invoices/components/create-dialog/after-options/template.hbs"
    }
  });

  _exports.default = _default;
});