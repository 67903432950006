define("coachlogix/models/document", ["exports", "@ember-data/model", "remove-markdown"], function (_exports, _model, _removeMarkdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    summary: (0, _model.attr)('string'),
    isNote: (0, _model.attr)('boolean'),
    documentContent: (0, _model.belongsTo)('document-content', {
      async: true,
      inverse: 'resource',
      cascadeDelete: true
    }),
    isDocument: Ember.computed.equal('resourceType', 'document'),
    plainTextSummary: Ember.computed('summary', function () {
      let summary = this.summary || '';
      return (0, _removeMarkdown.default)(summary.trim());
    })
  });

  _exports.default = _default;
});