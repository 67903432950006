define("coachlogix/pods/form/form-submission/question/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "faAmlZIg",
    "block": "{\"symbols\":[\"option\",\"&attrs\",\"@answer\",\"@disabled\",\"@viewMode\"],\"statements\":[[11,\"div\"],[24,0,\"cl-p-4\"],[17,2],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"cl-title-4 cl-mb-3\"],[12],[2,\"\\n\"],[2,\"    \"],[1,[32,3,[\"question\",\"questionText\"]]],[2,\"\\n\"],[6,[37,0],[[32,3,[\"question\",\"isRequired\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"cl-text-danger\"],[12],[2,\"*\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[8,\"cl-select\",[[24,0,\"cl-w-32 cl-text-lg\"]],[[\"@solid\",\"@placeholder\",\"@options\",\"@selected\",\"@onChange\",\"@disabled\",\"@editable\"],[true,[30,[36,1],[\"global.general.choose\"],null],[32,0,[\"allOptionValues\"]],[32,0,[\"value\"]],[32,0,[\"setValue\"]],[32,4],[30,[36,2],[[32,5]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"not\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/form/form-submission/question/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});