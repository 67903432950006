define("coachlogix/models/library-resource", ["exports", "coachlogix/models/base-resource"], function (_exports, _baseResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DS from 'ember-data';
  // const { hasMany } = DS;
  var _default = _baseResource.default.extend({
    isLibrary: true
    /* items: hasMany('libraryItemResource', {
      async: true,
      inverse: null
    }) */

  });

  _exports.default = _default;
});