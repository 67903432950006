define("coachlogix/models/survey-resource", ["exports", "@ember-data/model", "coachlogix/models/base-resource"], function (_exports, _model, _baseResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseResource.default.extend({
    name: (0, _model.attr)('string'),
    survey: (0, _model.belongsTo)('survey', {
      async: true,
      inverse: null
    }),
    isSurvey: true
  });

  _exports.default = _default;
});