define("coachlogix/pods/components/v2/engagement-template/engagement-task/detail-view/actions/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TaskDetailViewActions = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec(_class = (_class2 = (_temp = class TaskDetailViewActions extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    *duplicate() {
      let item = yield this.task.duplicate.perform();
      this.router.transitionTo('program-template.program.program-item', item.id);
    }

    *deleteTask() {
      let itemable = this.task;
      let programItem = yield itemable.get('programItem');
      let programable = yield programItem.get('programable');
      yield programable.deleteItemable.perform(itemable);
      this.router.transitionTo('program-template.program.index');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "duplicate", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "duplicate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteTask"), _class2.prototype)), _class2)) || _class);
  var _default = TaskDetailViewActions;
  _exports.default = _default;
});