define("coachlogix/helpers/currency-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    account: Ember.inject.service(),

    compute() {
      let account = this.get('account.activeAccount');
      let billingSettings = account.get('billingSettings');

      if (billingSettings && billingSettings.get('currency')) {
        return billingSettings.get('currency.symbol') || '$';
      }

      return '$';
    }

  });

  _exports.default = _default;
});