define("coachlogix/models/program-resource", ["exports", "@ember-data/model", "coachlogix/models/base-resource"], function (_exports, _model, _baseResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseResource.default.extend({
    isProgram: true,
    totalActiveAssociations: (0, _model.attr)('number'),
    totalHistoricAssociations: (0, _model.attr)('number'),
    name: (0, _model.attr)('string', {
      defaultValue: 'Untitled Roadmap Template'
    }),
    description: (0, _model.attr)('string', {
      defaultValue: ''
    })
    /* setting: belongsTo('programSetting', { async: true }),
     items: hasMany('program-item', {
      key: 'items',
      async: true,
      polymorphic: true,
      inverse: null
    }) */

  });

  _exports.default = _default;
});