define("coachlogix/pods/outside/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      // Default to en locale in outside routes
      _moment.default.locale('en');
    }

  });

  _exports.default = _default;
});