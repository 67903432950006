define("coachlogix/pods/components/v2/goal/detail-view/actions/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GoalDetailViewActions = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec(_class = (_class2 = (_temp = class GoalDetailViewActions extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clToaster", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "account", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);
    }

    *deleteGoal() {
      try {
        yield this.goal.delete.perform();
        this.router.transitionTo('engagement.goals');
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    async isUserCoacheOrCoachee() {
      let engagement = await this.goal.get('goalable');
      let currentEmployeeId = this.account.activeEmployee.id;
      let coachId = engagement.belongsTo('coach').id();
      let coacheeId = engagement.belongsTo('coachee').id();
      return [coachId, coacheeId].includes(currentEmployeeId);
    }

    *toggleIsPrivate(isPrivate) {
      let isUserCoacheOrCoachee = yield this.isUserCoacheOrCoachee(); // if active employee is coach or coachee, proceed
      // otherwise we need to show a warning, because private goals are only visible for
      // the coach and coachee

      if (isUserCoacheOrCoachee) {
        yield this.setGoalVisibility.perform(isPrivate);
      } else if (isPrivate) {
        this.set('showWarning', true);
      }
    }

    *setGoalVisibility(isPrivate, unloadGoal) {
      this.goal.set('isPrivate', isPrivate);

      try {
        yield this.goal.save();
        this.clToaster.success(this.intl.t('notifications.goal.updated'));

        if (unloadGoal) {
          let engagementId = this.goal.belongsTo('goalable').id();
          this.goal.unloadRecord();
          this.router.replaceWith('engagement.goals', engagementId);
        }
      } catch (e) {
        this.goal.set('isPrivate', !isPrivate);
        this.clToaster.error(e);
      } finally {
        this.set('confidentialDialog', false);
        this.set('showWarning', false);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "deleteGoal", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteGoal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleIsPrivate", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleIsPrivate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setGoalVisibility", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "setGoalVisibility"), _class2.prototype)), _class2)) || _class);
  var _default = GoalDetailViewActions;
  _exports.default = _default;
});