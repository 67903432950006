define("coachlogix/pods/account-setup/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountSetupController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('currentStep'), _dec6 = Ember.computed.reads('account.activeUser.firstName'), _dec7 = Ember.computed.reads('account.activeUser.lastName'), _dec8 = Ember.computed.reads('account.activeUser.title'), _dec9 = Ember.computed('firstName', 'lastName'), _dec10 = Ember.computed('accountType', 'companyName', 'internalCoaching', 'buysCoaching', 'providesCoaching'), _dec11 = Ember._action, (_class = (_temp = class AccountSetupController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "ajax", _descriptor4, this);

      _initializerDefineProperty(this, "firstName", _descriptor5, this);

      _initializerDefineProperty(this, "lastName", _descriptor6, this);

      _initializerDefineProperty(this, "title", _descriptor7, this);

      _defineProperty(this, "step", 1);
    }

    get totalSteps() {
      return Array.from(Array(3), (e, i) => i + 1);
    }

    get step1Valid() {
      return Ember.isPresent(this.firstName) && Ember.isPresent(this.lastName);
    }

    get step3Valid() {
      if (this.accountType === 'coach') {
        return Ember.isPresent(this.companyName);
      } else if (this.accountType === 'company') {
        return Ember.isPresent(this.companyName) && (this.internalCoaching || this.buysCoaching || this.providesCoaching);
      } else {
        return false;
      }
    }

    selectAccountType(accountType) {
      this.set('accountType', accountType);
      this.set('step', 3);
    }

    *createAccount() {
      let type = this.accountType;

      if (type === 'company') {
        type = 'business';
      } else if (type === 'coach') {
        type = 'independent_coach';
      }

      let account;

      if (this.account.activeAccount) {
        account = this.account.activeAccount;
      } else {
        account = this.store.createRecord('account');
      }

      account.setProperties({
        name: this.companyName,
        accountType: type,
        employeeTitle: this.title,
        status: 'active'
      });

      if (this.accountType === 'company') {
        account.setProperties({
          internalCoaching: this.internalCoaching,
          buysCoaching: this.buysCoaching,
          buysIndependentCoaching: this.buysCoaching,
          buysVendorCoaching: this.buysCoaching,
          providesCoaching: this.providesCoaching
        });
      }

      let user = this.account.activeUser;
      let firstName = this.firstName || '';
      let lastName = this.lastName || '';
      user.set('firstName', firstName.trim());
      user.set('lastName', lastName.trim());
      user.set('autoUpdateTimezone', true);

      try {
        yield user.save();
        yield account.save();
        yield this.account.loadInitialData.perform();
        this.transitionToRoute('dashboard');
      } catch (e) {
        this.clToaster.error('Something went wrong');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "totalSteps", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "totalSteps"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "step1Valid", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "step1Valid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "step3Valid", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "step3Valid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAccountType", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selectAccountType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createAccount", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createAccount"), _class.prototype)), _class));
  _exports.default = AccountSetupController;
});