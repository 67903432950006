define("coachlogix/pods/outside/invite/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      invitationId
    }) {
      return this.store.findRecord('invitation', invitationId);
    },

    afterModel(invitation) {
      this.transitionTo('dashboard');
      invitation.save();
    }

  });

  _exports.default = _default;
});