define("coachlogix/helpers/cl-ui-is-selected", ["exports", "@coachlogix/cl-ui/helpers/cl-ui-is-selected"], function (_exports, _clUiIsSelected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _clUiIsSelected.default;
    }
  });
  Object.defineProperty(_exports, "isSelected", {
    enumerable: true,
    get: function () {
      return _clUiIsSelected.isSelected;
    }
  });
});