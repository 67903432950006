define("coachlogix/models/employee-view", ["exports", "@ember-data/model", "ember-can/computed", "coachlogix/models/base-model", "coachlogix/models/commentable"], function (_exports, _model, _computed, _baseModel, _commentable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_commentable.default, {
    accountService: Ember.inject.service('account'),
    router: Ember.inject.service(),
    accountAbility: (0, _computed.ability)('account'),
    email: (0, _model.attr)('string'),
    fullName: (0, _model.attr)('string'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    avatar: (0, _model.attr)('string'),
    employeeTitle: (0, _model.attr)('string'),
    deskNumber: (0, _model.attr)('string'),
    internalIdentificationNumber: (0, _model.attr)('string'),
    workEmail: (0, _model.attr)('string'),
    workPhone: (0, _model.attr)('string'),
    account: (0, _model.attr)('string'),
    location: (0, _model.attr)('string'),
    department: (0, _model.attr)('string'),
    subDepartment: (0, _model.attr)('string'),
    employeeLevel: (0, _model.attr)('string'),
    isCoach: (0, _model.attr)('boolean'),
    status: (0, _model.attr)('string'),
    //'invited','active','internal_record')
    wasInvited: Ember.computed.equal('status', 'invited'),
    isInternalRecord: Ember.computed.equal('status', 'internal_record'),
    isConnected: Ember.computed.equal('status', 'active'),
    displayEmail: Ember.computed.or('workEmail', 'email'),
    displayPhone: Ember.computed.or('workPhone', 'phone')
  });

  _exports.default = _default;
});