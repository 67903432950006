define("coachlogix/models/notification-reminder", ["exports", "@ember-data/model", "ember-data-copyable", "coachlogix/models/base-model"], function (_exports, _model, _emberDataCopyable, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_emberDataCopyable.default, {
    timeUnit: (0, _model.attr)('string'),
    // minutes || hours || days || weeks
    timeValue: (0, _model.attr)('string'),
    timePeriod: (0, _model.attr)('string'),
    // before || after
    message: (0, _model.attr)('string'),
    subject: (0, _model.attr)('string'),
    targetDate: (0, _model.attr)('date'),
    customNotification: (0, _model.belongsTo)('custom-notification', {
      inverse: 'notificationReminders'
    })
  });

  _exports.default = _default;
});