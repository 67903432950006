define("coachlogix/pods/components/v2/client/actions/manage-visibility-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g/CzXXxX",
    "block": "{\"symbols\":[\"location\",\"@onClose\",\"@client\",\"&attrs\"],\"statements\":[[8,\"cl-selection-dialog\",[[24,0,\"cl-w-32\"],[17,4]],[[\"@title\",\"@multiple\",\"@onClose\",\"@options\",\"@selected\",\"@onChange\",\"@selectLabel\"],[[30,[36,0],[\"client.client-locations.title\"],null],true,[32,2],[32,0,[\"account\",\"activeAccount\",\"companyLocations\"]],[32,3,[\"clientLocations\"]],[30,[36,1],[[32,0,[\"saveLocations\"]]],null],[30,[36,0],[\"ui.save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[6,[37,3],[[30,[36,2],[[32,1,[\"fullAddress\"]],[30,[36,0],[\"ui.na\"],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"p\"],[12],[1,[32,1,[\"fullAddress\"]]],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"perform\",\"eq\",\"unless\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/client/actions/manage-visibility-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});