define("coachlogix/models/adminable", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    clToaster: Ember.inject.service(),
    roleUsers: (0, _model.hasMany)('role-user', {
      async: true,
      inverse: null
    }),
    createAdmin: (0, _emberConcurrency.task)(function* (user, role, account) {
      user = yield user;
      role = yield role;
      account = yield account;
      let admin = this.store.createRecord('role-user', {
        adminable: this,
        user,
        role,
        account
      });

      try {
        yield admin.save();
        this.get('roleUsers').pushObject(admin);
        this.clToaster.success(`${user.get('name')} added as ${role.get('display_name')}`);
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});