define("coachlogix/pods/video/video-ui/participant/component", ["exports", "@glimmer/component", "tracked-maps-and-sets", "tracked-toolbox", "twilio-video"], function (_exports, _component, _trackedMapsAndSets, _trackedToolbox, _twilioVideo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VideoUiParticipantComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class VideoUiParticipantComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "videoTracks", _descriptor, this);

      _initializerDefineProperty(this, "audioTracks", _descriptor2, this);
    }

    /**
     * returns the correct video track to display
     * - if there is a screnshare track and we're on the main area, return that track
     * - otherwise return the first available non-screen share video track
     * - otherwise return null
     */
    get videoTrack() {
      for (let track of this.videoTracks) {
        if (track.name === 'screenshare' && this.args.isMain) {
          return track;
        }
      }

      for (let track of this.videoTracks) {
        if (track.name !== 'screenshare') {
          return track;
        }
      }

      return null;
    }
    /**
     * return the first audio track if exists
     * (a participant shouldn't be able to have more than one anyway)
     */


    get audioTrack() {
      return this.audioTracks.size > 0 ? this.audioTracks.values().next().value : null;
    }

    setupParticipant([participant]) {
      if (this.participant === participant) {
        return;
      }

      if (this.participant) {
        this.teardownParticipant();
      }

      this.participant = participant;

      for (let publication of this.participant.tracks.values()) {
        this.onTrackPublished(publication);
      }

      this.participant.on('trackPublished', this.onTrackPublished);
      this.participant.on('trackUnpublished', this.onTrackUnpublished);
    }

    teardownParticipant() {
      this.participant.off('trackPublished', this.onTrackPublished);
      this.participant.off('trackUnpublished', this.onTrackUnpublished);

      for (let publication of this.participant.tracks.values()) {
        this.onTrackUnpublished(publication);
      }

      this.participant = null;
    }

    onTrackPublished(publication) {
      console.log('onTrackPublished', ...arguments);

      if (
      /*publication.isSubscribed && */
      publication.track) {
        this.onSubscribed(publication.track);
      }

      publication.on('subscribed', this.onSubscribed);
      publication.on('unsubscribed', this.onUnsubscribed);
    }

    onTrackUnpublished(publication) {
      console.log('onTrackUnpublished', ...arguments);

      if (
      /*publication.isSubscribed && */
      publication.track) {
        this.onUnsubscribed(publication.track);
      }

      publication.off('subscribed', this.onSubscribed);
      publication.off('unsubscribed', this.onUnsubscribed);
    }

    onSubscribed(track) {
      console.log('onSubscribed', ...arguments);

      if (track.kind === 'video') {
        this.videoTracks.add(track);
      } else if (track.kind === 'audio') {
        this.audioTracks.add(track);
      } // LocalTracks and RemoteTracks publications events behave a little differently.
      // When restarting local tracks, the stopped and started events are triggered,
      // so we need these events.


      if (track instanceof _twilioVideo.LocalVideoTrack || track instanceof _twilioVideo.LocalAudioTrack) {
        track.on('started', this.onTrackStarted);
        track.on('stopped', this.onUnsubscribed);
      }
    }

    onUnsubscribed(track) {
      console.log('onUnsubscribed', ...arguments);

      if (track.kind === 'video') {
        this.videoTracks.delete(track);
      } else if (track.kind === 'audio') {
        this.audioTracks.delete(track);
      }
    }

    onTrackStarted(track) {
      console.log('onTrackStarted', ...arguments);

      if (track.kind === 'video') {
        this.videoTracks.add(track);
      } else if (track.kind === 'audio') {
        this.audioTracks.add(track);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "videoTracks", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedMapsAndSets.TrackedSet();
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "audioTracks", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedMapsAndSets.TrackedSet();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "videoTrack", [_trackedToolbox.cached], Object.getOwnPropertyDescriptor(_class.prototype, "videoTrack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "audioTrack", [_trackedToolbox.cached], Object.getOwnPropertyDescriptor(_class.prototype, "audioTrack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupParticipant", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setupParticipant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardownParticipant", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "teardownParticipant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTrackPublished", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onTrackPublished"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTrackUnpublished", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onTrackUnpublished"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubscribed", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onSubscribed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUnsubscribed", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onUnsubscribed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTrackStarted", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onTrackStarted"), _class.prototype)), _class));
  _exports.default = VideoUiParticipantComponent;
});