define("coachlogix/pods/components/cl-employee-selection-dialog/capacity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dzcYvfTQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-flex cl-flex-1 cl-justify-center cl-align-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-w-10 cl-text-center\"],[12],[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cl-employee-selection-dialog/capacity/template.hbs"
    }
  });

  _exports.default = _default;
});