define("coachlogix/pods/messaging/channel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4k+pft+t",
    "block": "{\"symbols\":[],\"statements\":[[8,\"v2/messaging/channel-pane\",[[24,0,\"cl-flex-1 cl-bg-pale-grey-three\"]],[[\"@sid\",\"@showToolbar\"],[[32,0,[\"model\"]],true]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coachlogix/pods/messaging/channel/template.hbs"
    }
  });

  _exports.default = _default;
});