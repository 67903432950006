define("coachlogix/pods/components/v2/summary-cards/interviews/actions/time-suggestions-dialog/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TimeSuggestionsDialog = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = (_temp = class TimeSuggestionsDialog extends _component.default {
    // either contains a given coaching request or
    // a coaching request from a given coaching request *id*
    get declinedSuggestions() {
      return this.coachingRequest.timeSuggestions.filter(s => s.isDeclined).sort((a, b) => a.startTime - b.startTime);
    }

    get sentSuggestions() {
      return this.coachingRequest.timeSuggestions.filter(s => s.isSent);
    }

    get isInvalid() {
      return this.sentSuggestions.some(s => Ember.isEmpty(s.startTime) || this.wasPreviouslyDeclined(s.startTime));
    }

    wasPreviouslyDeclined(date) {
      date = (0, _moment.default)(date);
      return this.declinedSuggestions.some(s => date.isSame(s.startTime, 'minute'));
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "coachingRequest", _descriptor3, this);

      this.getCoachingRequest.perform();
    }

    *getCoachingRequest() {
      let coachingRequest;

      if (this.args.coachingRequest) {
        coachingRequest = this.args.coachingRequest;
      } else if (this.args.coachingRequestId) {
        coachingRequest = yield this.store.findRecord('coaching-request', this.args.coachingRequestId, {
          reload: true,
          include: 'time-suggestions'
        });
      }

      this.coachingRequest = coachingRequest;

      if (this.sentSuggestions.length === 0) {
        for (let i = 0; i < 3; i++) {
          let s = this.store.createRecord('time-suggestion', {
            status: 'sent'
          });
          this.coachingRequest.timeSuggestions.addObject(s);
        }
      }
    }

    willDestroy() {
      super.willDestroy(...arguments);
      let suggestions = this.coachingRequest.timeSuggestions.filter(s => s.isNew);

      for (let s of suggestions) {
        s.destroyRecord();
      }
    }

    *send() {
      try {
        let suggestions = this.coachingRequest.timeSuggestions.filter(s => s.isSent && s.isNew);
        yield (0, _emberConcurrency.all)(suggestions.map(s => s.save()));
        this.coachingRequest.status = 'interview_times_sent';
        yield this.coachingRequest.save();
        this.args.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "coachingRequest", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getCoachingRequest", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getCoachingRequest"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "send", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "send"), _class.prototype)), _class));
  _exports.default = TimeSuggestionsDialog;
});