define("coachlogix/pods/components/v2/engagement/create-dialog/select-contract-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SelectContractDialogComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.or('account.name', 'clientAccount.name'), _dec(_class = (_class2 = (_temp = class SelectContractDialogComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "accountName", _descriptor3, this);
    }

    init() {
      super.init(...arguments);
      this.set('contracts', this.getContracts.perform());
    }

    *getContracts() {
      let contracts;

      if (this.contracts) {
        contracts = this.contracts;
      } else {
        let filter = {
          assignable: true
        };

        if (this.account) {
          let account = yield this.account;
          filter.account_id = account.id;
        }

        if (this.clientAccount) {
          let clientAccount = yield this.clientAccount;
          filter.client_account_id = clientAccount.id;
        }

        contracts = yield this.store.query('contract', {
          filter
        });
      }

      contracts = contracts.toArray ? contracts.toArray() : contracts;
      return contracts.filter(c => Ember.isPresent(c.name));
    }

    *redirectToClient() {
      let client = yield this.client;
      let route = client.isClient ? 'client' : 'vendor';
      this.router.transitionTo(route, client.id, {
        queryParams: {
          addContract: true
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "accountName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "getContracts", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "getContracts"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "redirectToClient", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "redirectToClient"), _class2.prototype)), _class2)) || _class);
  var _default = SelectContractDialogComponent;
  _exports.default = _default;
});