define("coachlogix/models/user-certification", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    issuer: (0, _model.attr)('string'),
    yearAttained: (0, _model.attr)('string'),
    // certCopy: attr('string'),
    user: (0, _model.belongsTo)('user'),
    autocomplete: Ember.computed('autocompleteService.certifications', function () {
      let name = this.get('autocompleteService.certifications');
      return {
        name
      };
    }),
    autocompleteService: Ember.inject.service('autocomplete')
  });

  _exports.default = _default;
});