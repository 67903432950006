define("coachlogix/models/account-summary", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    totalContracts: (0, _model.attr)('number'),
    activeContracts: (0, _model.attr)('number'),
    totalClients: (0, _model.attr)('number'),
    totalVendors: (0, _model.attr)('number'),
    totalInvoiced: (0, _model.attr)('number'),
    invoicesSent: (0, _model.attr)('number'),
    invoicesReceived: (0, _model.attr)('number'),
    invoicesReceivedTotal: (0, _model.attr)('number'),
    amountPaid: (0, _model.attr)('number'),
    amountReceived: (0, _model.attr)('number'),
    amountDue: (0, _model.attr)('number'),
    amountOwed: (0, _model.attr)('number'),
    engagementsAllocated: (0, _model.attr)('number'),
    engagementsMatch: (0, _model.attr)('number'),
    engagementsDraft: (0, _model.attr)('number'),
    engagementsActive: (0, _model.attr)('number'),
    engagementsInactive: (0, _model.attr)('number'),
    engagementsCompleted: (0, _model.attr)('number'),
    engagementHoursScheduled: (0, _model.attr)('number'),
    engagementHoursCompleted: (0, _model.attr)('number'),
    engagementHoursCancelled: (0, _model.attr)('number'),
    engagementHoursRemaining: (0, _model.attr)('number'),
    sessionsScheduled: (0, _model.attr)('number'),
    sessionsCompleted: (0, _model.attr)('number'),
    sessionsCancelled: (0, _model.attr)('number'),
    sessionsRemaining: (0, _model.attr)('number'),
    tasksCreated: (0, _model.attr)('number'),
    tasksCompleted: (0, _model.attr)('number'),
    tasksOpen: (0, _model.attr)('number'),
    goalsCreated: (0, _model.attr)('number'),
    goalsCompleted: (0, _model.attr)('number'),
    goalsOpen: (0, _model.attr)('number'),
    totalReviews: (0, _model.attr)('number'),
    averageRating: (0, _model.attr)('number'),
    totalCoachReviews: (0, _model.attr)('number'),
    averageCoachRating: (0, _model.attr)('number'),
    invitesAccepted: (0, _model.attr)('number'),
    invitesPending: (0, _model.attr)('number'),
    coachSharesAccepted: (0, _model.attr)('number'),
    coachSharesPending: (0, _model.attr)('number'),
    coachingInquires: (0, _model.attr)('number'),
    internalCoaches: (0, _model.attr)('number'),
    externalCoaches: (0, _model.attr)('number'),
    totalCoaches: Ember.computed('internalCoaches', 'externalCoaches', function () {
      return this.get('internalCoaches') + this.get('externalCoaches');
    }),
    totalCoachees: (0, _model.attr)('number'),
    activeCoaches: (0, _model.attr)('number'),
    activeCoachees: (0, _model.attr)('number'),
    account: (0, _model.belongsTo)('account', {
      inverse: 'accountSummary'
    }),
    engagementsValues: Ember.computed.collect('engagementsActive', 'engagementsDraft', 'engagementsMatch', 'engagementsCompleted'),
    totalEngagements: Ember.computed.sum('engagementsValues'),
    hourValues: Ember.computed.collect('engagementHoursCompleted', 'engagementHoursScheduled'),
    totalHours: Ember.computed.sum('hourValues'),
    percentageHours: Ember.computed('engagementHoursCompleted', 'totalHours', function () {
      return Math.round(this.get('engagementHoursCompleted') / this.get('totalHours') * 100) || 0;
    }),
    sessionValues: Ember.computed.collect('sessionsCompleted', 'sessionsScheduled'),
    totalSessions: Ember.computed.sum('sessionValues'),
    percentageSessions: Ember.computed('sessionsCompleted', 'totalSessions', function () {
      return Math.round(this.get('sessionsCompleted') / this.get('totalSessions') * 100) || 0;
    }),
    interviewsScheduled: (0, _model.attr)('number'),
    interviewsUnscheduled: (0, _model.attr)('number'),
    receivedDueAmount: (0, _model.attr)('number'),
    receivedDueCount: (0, _model.attr)('number'),
    receivedPaidAmount: (0, _model.attr)('number'),
    receivedPaidCount: (0, _model.attr)('number'),
    receivedPastDueAmount: (0, _model.attr)('number'),
    receivedPastDueCount: (0, _model.attr)('number'),
    receivedTotalAmountValues: Ember.computed.collect('receivedDueAmount', 'receivedPaidAmount', 'receivedPastDueAmount'),
    receivedTotalAmount: Ember.computed.sum('receivedTotalAmountValues'),
    sentDraftAmount: (0, _model.attr)('number'),
    sentDraftCount: (0, _model.attr)('number'),
    sentDueAmount: (0, _model.attr)('number'),
    sentDueCount: (0, _model.attr)('number'),
    sentPaidAmount: (0, _model.attr)('number'),
    sentPaidCount: (0, _model.attr)('number'),
    sentPastDueAmount: (0, _model.attr)('number'),
    sentPastDueCount: (0, _model.attr)('number'),
    sentTotalAmountValues: Ember.computed.collect('sentDraftAmount', 'sentDueAmount', 'sentPaidAmount', 'sentPastDueAmount'),
    sentTotalAmount: Ember.computed.sum('sentTotalAmountValues')
  });

  _exports.default = _default;
});