define("coachlogix/services/coach-categorizations", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    isLoading: Ember.computed.reads('fetchCategorizations.isRunning'),
    // Completely free form
    affiliations: Ember.computed.filterBy('content', 'categorizationType', 'affiliations'),
    // Suggested content + custom entry
    assessments: Ember.computed.filterBy('content', 'categorizationType', 'assessments'),
    // Suggested content + custom entry
    credentials: Ember.computed.filterBy('content', 'categorizationType', 'credentials'),
    clientTypes: Ember.computed.filterBy('content', 'categorizationType', 'clientTypes'),
    // Suggested content only
    theoreticalModels: Ember.computed.filterBy('content', 'categorizationType', 'theoretical models'),
    coachingTypes: Ember.computed.filterBy('content', 'categorizationType', 'coaching types'),
    // Modalities
    modalities: Ember.computed.filterBy('content', 'categorizationType', 'modalities'),
    // Coaching Experience
    employeeLevels: Ember.computed.filterBy('content', 'categorizationType', 'employee levels'),
    businessFunctions: Ember.computed.filterBy('content', 'categorizationType', 'business function/departments'),
    // Only thing that is taggable, suggested content only
    specialties: Ember.computed.filterBy('content', 'categorizationType', 'specialties'),
    fetchAllCategorizations: (0, _emberConcurrency.task)(function* () {
      let categorizations = yield this.get('store').query('coaching-categorization', {
        sort: 'categorization_type,value'
      });
      this.set('content', Ember.ArrayProxy.create({
        content: Ember.A(categorizations)
      }));
      return categorizations;
    }).on('init'),
    fetchCategorizations: (0, _emberConcurrency.task)(function* (type) {
      let categorizations = [];

      if (this.get('content')) {
        categorizations = this.get('content');
      } else {
        categorizations = yield this.get('fetchAllCategorizations').perform();
      }

      return categorizations.filterBy('categorizationType', type);
    })
  });

  _exports.default = _default;
});