define("coachlogix/abilities/department", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roleIsAny = roleIsAny;
  _exports.default = void 0;

  function roleIsAny(value) {
    if (!Array.isArray(value)) {
      value = [value];
    }

    return this.get('roles').any(role => value.includes(role));
  }

  var _default = _emberCan.Ability.extend({
    account: Ember.inject.service(),
    canViewDashboard: false,
    canManageEngagements: false,
    canManageResources: false,
    canManageInvoices: false,
    canManagePeople: false,
    canManageCompanies: false,
    canManageReports: false,
    canManageSettings: false,
    canManageProgramTemplates: Ember.computed.alias('account.activeEmployee.role', function () {}),
    canManageOrgStructure: Ember.computed.reads('account.activeAccount.isEnterprise'),
    canAddInternalCoaches: Ember.computed.reads('account.activeAccount.internalCoaching'),
    canBuyCoaching: Ember.computed.reads('account.activeAccount.buysCoaching'),
    canSellCoaching: Ember.computed.reads('account.activeAccount.providesCoaching')
  });

  _exports.default = _default;
});