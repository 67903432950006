define("coachlogix/models/user-work", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    organization: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    beginDate: (0, _model.attr)('date', {
      defaultValue: new Date()
    }),
    endDate: (0, _model.attr)('date'),
    present: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});