define("coachlogix/pods/components/profile/general-info/language-edit-dialog/component", ["exports", "@glimmer/component", "ember-changeset", "ember-concurrency-decorators"], function (_exports, _component, _emberChangeset, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LanguageEditDialog = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, (_class = (_temp = class LanguageEditDialog extends _component.default {
    get isInvalid() {
      return Ember.isEmpty(this.changeset.language) || Ember.isEmpty(this.changeset.fluency);
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _defineProperty(this, "changeset", null);

      _defineProperty(this, "fluencies", this.store.peekAll('language-fluency'));

      _defineProperty(this, "languages", this.store.findAll('language'));

      _initializerDefineProperty(this, "isEditing", _descriptor4, this);

      this.store.push({
        data: [{
          id: 2,
          type: 'language-fluency',
          attributes: {
            name: this.intl.t('ui.coach-profile.languages.limited-working-proficiency')
          }
        }, {
          id: 1,
          type: 'language-fluency',
          attributes: {
            name: this.intl.t('ui.coach-profile.languages.elementary-proficiency')
          }
        }, {
          id: 3,
          type: 'language-fluency',
          attributes: {
            name: this.intl.t('ui.coach-profile.languages.professional-working-proficiency')
          }
        }, {
          id: 4,
          type: 'language-fluency',
          attributes: {
            name: this.intl.t('ui.coach-profile.languages.professional-proficiency')
          }
        }, {
          id: 5,
          type: 'language-fluency',
          attributes: {
            name: this.intl.t('ui.coach-profile.languages.native-proficiency')
          }
        }]
      });
      let profileLanguage = this.args.profileLanguage;

      if (!profileLanguage) {
        profileLanguage = this.store.createRecord('profile-language', {
          profile: this.args.profile
        });
      }

      this.changeset = new _emberChangeset.Changeset(profileLanguage);
    }

    willDestroy() {
      super.willDestroy(...arguments);

      if (this.changeset.data.isNew) {
        this.changeset.data.destroyRecord();
      }
    }

    *saveChanges() {
      try {
        yield this.changeset.save();
        this.clToaster.success(this.intl.t('v2.employee.profile.languages-updated'));
        this.args.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *delete() {
      try {
        yield this.args.profileLanguage.destroyRecord();
        this.clToaster.success(this.intl.t('v2.employee.profile.languages-updated'));
        this.args.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
        this.args.profileLanguage.rollbackAttributes();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isEditing", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  _exports.default = LanguageEditDialog;
});