define("coachlogix/models/report", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    reportType: (0, _model.attr)('string'),
    columns: (0, _model.attr)('array'),
    rows: (0, _model.attr)('array'),
    filters: (0, _model.attr)('array'),
    meta: (0, _model.attr)()
  });

  _exports.default = _default;
});