define("coachlogix/pods/components/v2/messaging/channel-topic-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zAT63++p",
    "block": "{\"symbols\":[\"dialog\",\"&attrs\",\"@onClose\"],\"statements\":[[8,\"cl-dialog\",[[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"cl-title-3 cl-flex-1\"],[12],[1,[30,[36,0],[\"messaging.channel-topic-dialog.title\"],null]],[13],[2,\"\\n    \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"select\",\"isRunning\"]]]],[[\"@iconButton\",\"@onClick\"],[true,[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"close\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-input\",[[16,\"disabled\",[32,0,[\"select\",\"isRunning\"]]]],[[\"@solid\",\"@label\",\"@value\",\"@onChange\"],[true,[30,[36,0],[\"messaging.channel-topic-dialog.input-label\"],null],[32,0,[\"value\"]],[30,[36,2],[[30,[36,1],[[32,0,[\"value\"]]],null]],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-button\",[[16,\"disabled\",[32,0,[\"select\",\"isRunning\"]]]],[[\"@raised\",\"@onClick\"],[true,[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"ui.cancel\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"cl-button\",[[16,\"disabled\",[30,[36,4],[[32,0,[\"select\",\"isRunning\"]],[30,[36,3],[[32,0,[\"value\"]]],null]],null]]],[[\"@raised\",\"@primary\",\"@onClick\"],[true,true,[30,[36,5],[[32,0,[\"select\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"ui.ok\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"fn\",\"is-empty\",\"or\",\"perform\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/messaging/channel-topic-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});