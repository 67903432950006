define("coachlogix/pods/engagement/matching-process/interviews/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MatchingProcessController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.reads('model.engagement.value'), _dec6 = Ember.computed.reads('model.coachingRequests.value'), _dec7 = Ember.computed('coachingRequests'), _dec8 = Ember.computed('coachingRequests'), (_class = (_temp = class MatchingProcessController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "twilio", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "engagement", _descriptor5, this);

      _initializerDefineProperty(this, "coachingRequests", _descriptor6, this);
    }

    get upcomingInterviews() {
      return this.coachingRequests.filter(c => {
        return c.isInterviewTimesRequested || c.isInterviewTimesSent || (0, _moment.default)(c.scheduledDate).isAfter((0, _moment.default)().subtract(1, 'd')) && !c.isInterviewCompleted && !c.isInterviewCanceled;
      });
    }

    get completedInterviews() {
      return this.coachingRequests.filter(c => c.isInterviewCompleted || c.isInterviewCanceled);
    }

    *selectCoach(coach) {
      try {
        let coachingRequests = yield coach.get('coachingRequests');
        let coachingRequest = coachingRequests.find(c => {
          return c.belongsTo('coachee').id() === this.engagement.belongsTo('coachee').id() && c.belongsTo('engagement').id() === this.engagement.get('id');
        });
        this.set('coachingRequest', coachingRequest);
        this.coachingRequest.set('status', 'coach_selected');
        yield this.coachingRequest.save(); // the request might be auto approved / auto started
        // when that is the case, we redirect the coachee to the engagement itself, not
        //

        if (this.coachingRequest.get('isCoachApproved')) {
          yield this.router.replaceWith('engagement', this.engagement.id);
          let currentRouteName = 'engagement';
          let currentRouteInstance = Ember.getOwner(this).lookup(`route:${currentRouteName}`);
          currentRouteInstance.refresh();
        } else {
          this.router.replaceWith('engagement.matching-process-selection', this.engagement.id, this.coachingRequest.id);
        }
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "twilio", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "engagement", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "coachingRequests", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "upcomingInterviews", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "upcomingInterviews"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "completedInterviews", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "completedInterviews"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCoach", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "selectCoach"), _class.prototype)), _class));
  _exports.default = MatchingProcessController;
});