define("coachlogix/pods/components/cronofy/availability-rules/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f9Nd7Ubk",
    "block": "{\"symbols\":[\"token\"],\"statements\":[[8,\"cronofy/cronofy-element\",[],[[\"@subs\"],[[32,0,[\"account\",\"activeUser\",\"cronofyConnectedAccount\",\"cronofyId\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[4,[38,0],[[32,0,[\"setupElement\"]],[32,1]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cronofy/availability-rules/template.hbs"
    }
  });

  _exports.default = _default;
});