define("coachlogix/models/permission-role", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    permissionId: (0, _model.attr)('string'),
    permission: (0, _model.belongsTo)('permission', {
      inverse: null
    }),
    role: (0, _model.belongsTo)('role', {
      inverse: null
    })
  });

  _exports.default = _default;
});