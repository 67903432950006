define("coachlogix/models/currency", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    symbol: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    precision: (0, _model.attr)('number')
  });

  _exports.default = _default;
});