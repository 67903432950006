define("coachlogix/pods/competency-library/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompetencyLibraryRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec4 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class CompetencyLibraryRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "topbar", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);
    }

    activate() {
      super.activate(...arguments);
      this.topbar.setTransparent();
    }

    model({
      competency_library_id
    }) {
      return {
        competencyLibrary: this.loadLibrary.perform(competency_library_id),
        competencies: this.loadCompetencies.perform()
      };
    }

    *loadLibrary(id) {
      return yield this.store.findRecord('resource', id, {
        include: 'competencies.competency-description',
        reload: true
      });
    }

    *loadCompetencies() {
      return yield this.store.findAll('competency', {
        include: 'competency-description',
        reload: true
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "topbar", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadLibrary", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadLibrary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadCompetencies", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "loadCompetencies"), _class.prototype)), _class));
  _exports.default = CompetencyLibraryRoute;
});