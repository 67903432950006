define("coachlogix/models/review", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    rating: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    review: (0, _model.attr)('string'),
    reviewable: (0, _model.belongsTo)('base-model', {
      async: true,
      polymorphic: true
    }),
    userContent: Ember.computed.alias('user.content'),
    goalId: Ember.computed.alias('reviewable.id'),
    goalTitle: Ember.computed.alias('reviewable.title'),
    user: (0, _model.belongsTo)('user', {
      async: true,
      inverse: null
    })
  });

  _exports.default = _default;
});