define("coachlogix/pods/components/v2/messaging/channel-topic-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ChannelTopicDialog = (_dec = (0, _component.tagName)(''), _dec(_class = (_class2 = (_temp = class ChannelTopicDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "value", '');
    }

    *select() {
      yield this.onSelection(this.value);

      if (this.onClose) {
        this.onClose();
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "select", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype)), _class2)) || _class);
  var _default = ChannelTopicDialog;
  _exports.default = _default;
});