define("coachlogix/models/contract-template", ["exports", "@ember-data/model", "coachlogix/models/base-model", "coachlogix/models/contract"], function (_exports, _model, _baseModel, _contract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "CONTRACT_TYPES", {
    enumerable: true,
    get: function () {
      return _contract.CONTRACT_TYPES;
    }
  });
  Object.defineProperty(_exports, "BILLING_APPROACHES", {
    enumerable: true,
    get: function () {
      return _contract.BILLING_APPROACHES;
    }
  });
  Object.defineProperty(_exports, "ALLOW_EXPENSES_OPTIONS", {
    enumerable: true,
    get: function () {
      return _contract.ALLOW_EXPENSES_OPTIONS;
    }
  });
  Object.defineProperty(_exports, "PAYMENT_INTERVAL_TYPES", {
    enumerable: true,
    get: function () {
      return _contract.PAYMENT_INTERVAL_TYPES;
    }
  });
  Object.defineProperty(_exports, "INVOICE_SCHEDULE_TYPES", {
    enumerable: true,
    get: function () {
      return _contract.INVOICE_SCHEDULE_TYPES;
    }
  });
  Object.defineProperty(_exports, "ALLOW_PAYMENT_SCHEDULES", {
    enumerable: true,
    get: function () {
      return _contract.ALLOW_PAYMENT_SCHEDULES;
    }
  });
  _exports.default = _exports.CONTRACT_DURATION_TYPES = _exports.PAYMENT_SCHEDULE_METRICS = void 0;
  const PAYMENT_SCHEDULE_METRICS = ['session_number', 'program_items_number', 'engagement_duration'];
  _exports.PAYMENT_SCHEDULE_METRICS = PAYMENT_SCHEDULE_METRICS;
  const CONTRACT_DURATION_TYPES = ['months', 'weeks', 'days'];
  _exports.CONTRACT_DURATION_TYPES = CONTRACT_DURATION_TYPES;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    contractAmount: (0, _model.attr)('number'),
    contractType: (0, _model.attr)('string', {
      defaultValue: 'ongoing'
    }),
    taxName1: (0, _model.attr)('string'),
    taxRate1: (0, _model.attr)('string'),
    taxName2: (0, _model.attr)('string'),
    taxRate2: (0, _model.attr)('string'),
    paymentTermsInfo: (0, _model.attr)('string'),
    allowExpenses: (0, _model.attr)('string'),
    applyVat: (0, _model.attr)('string'),
    dayOfWeek: (0, _model.attr)('number'),
    dayOfMonth: (0, _model.attr)('number'),
    billingApproach: (0, _model.attr)('string'),
    paymentScheduleMetric: (0, _model.attr)('string'),
    // if schedule type = payment_interval then get interval_type and value
    // if schedule type = payment_schedule then get the payment_schedule_id associated
    invoiceScheduleType: (0, _model.attr)('string'),
    paymentIntervalType: (0, _model.attr)('string'),
    paymentIntervalValue: (0, _model.attr)('number'),
    invoiceAutomatically: (0, _model.attr)('boolean'),
    payViaStripe: (0, _model.attr)('boolean'),
    contractDurationType: (0, _model.attr)('string'),
    contractDurationValue: (0, _model.attr)('number'),
    contractAmountCap: (0, _model.attr)('string'),
    hoursCap: (0, _model.attr)('string'),
    engagementsCap: (0, _model.attr)('string'),
    engagementSessionsCap: (0, _model.attr)('string'),
    expenseCap: (0, _model.attr)('number'),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    account: (0, _model.belongsTo)('account', {
      inverse: null
    }),
    currency: (0, _model.belongsTo)('currency', {
      inverse: null
    }),
    paymentSchedule: (0, _model.belongsTo)('payment-schedule', {
      inverse: null
    }),
    employeeLevel: (0, _model.belongsTo)('employee-level', {
      inverse: null
    })
  });

  _exports.default = _default;
});