define("coachlogix/models/competency", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    account: (0, _model.belongsTo)('account'),
    competencyDescription: (0, _model.belongsTo)('competency-description', {
      inverse: 'competency'
    }),
    isCoachlogix: Ember.computed('account.id', function () {
      return this.belongsTo('account').id() === '1';
    }),
    defaultedDescription: Ember.computed.or('competencyDescription.description', 'description'),
    totalCount: (0, _model.attr)('number'),
    averageFirstRating: (0, _model.attr)('number'),
    averageLatestRating: (0, _model.attr)('number'),
    averagePercentImprovement: (0, _model.attr)('number'),
    topLevel: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});