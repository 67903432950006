define("coachlogix/models/payment-gateway", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    provider: (0, _model.attr)('string'),
    visible: (0, _model.attr)('boolean'),
    sortOrder: (0, _model.attr)('boolean'),
    recommended: (0, _model.attr)('boolean'),
    siteUrl: (0, _model.attr)('string'),
    isOffsite: (0, _model.attr)('boolean'),
    isSecure: (0, _model.attr)('boolean'),
    paymentLibrary: (0, _model.belongsTo)('payment-library', {
      inverse: null
    })
  });

  _exports.default = _default;
});