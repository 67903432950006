define("coachlogix/pods/components/v2/employee/update-roles-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmployeeUpdateRolesDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.filterBy('roleUsersCopy', 'objectType', 'accounts'), _dec7 = Ember.computed.filterBy('roleUsersCopy', 'objectType', 'company-locations'), _dec8 = Ember.computed.filterBy('roleUsersCopy', 'objectType', 'departments'), _dec9 = Ember.computed.setDiff('employee.roleUsers', 'roleUsersCopy'), _dec10 = Ember.computed.setDiff('roleUsersCopy', 'employee.roleUsers'), _dec11 = Ember.computed('roleUsersCopy.@each.roleName', 'roleToAdd.objectType', 'locationToAdd', 'subDepartmentToAdd', 'departmentToAdd'), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec16 = Ember.computed.setDiff('originalSelectedCompanyLocations', 'selectedCompanyLocations'), _dec17 = Ember._action, _dec(_class = (_class2 = (_temp = class EmployeeUpdateRolesDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "accountRoles", _descriptor5, this);

      _initializerDefineProperty(this, "locationRoles", _descriptor6, this);

      _initializerDefineProperty(this, "departmentRoles", _descriptor7, this);

      _initializerDefineProperty(this, "rolesToDelete", _descriptor8, this);

      _initializerDefineProperty(this, "rolesToCreate", _descriptor9, this);

      _defineProperty(this, "roleToAdd", null);

      _initializerDefineProperty(this, "rolesToDelete", _descriptor10, this);
    }

    get canAddRoles() {
      let roleUsersCopy = this.get('roleUsersCopy') || [];
      let isAdmin = roleUsersCopy.isAny('roleName', 'account_global_admin');

      if (isAdmin) {
        return false;
      }

      if (this.roleToAdd) {
        let objectType = this.roleToAdd.get('objectType');

        if (objectType === 'company-locations') {
          return this.get('locationToAdd');
        } else if (objectType === 'departments') {
          return this.get('subDepartmentToAdd') || this.get('departmentToAdd');
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    addRole() {
      let roleToAdd = this.get('roleToAdd');
      let roleUser = this.get('store').createRecord('role-user', {
        user: this.get('employee.user'),
        role: roleToAdd
      });

      if (roleToAdd.get('objectType') === 'company-locations') {
        roleUser.set('adminable', this.get('locationToAdd'));
      } else if (roleToAdd.get('objectType') === 'departments') {
        let departmentToAdd = this.get('subDepartmentToAdd') || this.get('departmentToAdd');
        roleUser.set('adminable', departmentToAdd);
      } else {
        roleUser.set('adminable', this.get('employee.account'));
      }

      this.get('roleUsersCopy').addObject(roleUser);
      this.setProperties({
        roleToAdd: null,
        subDepartmentToAdd: null,
        departmentToAdd: null
      });
      this.get('saveRoles').perform();
    }

    removeRole(roleUser) {
      this.get('roleUsersCopy').removeObject(roleUser);

      if (roleUser.get('isNew')) {
        roleUser.destroyRecord();
      }

      this.get('saveRoles').perform();
    }

    toggleIsCoach(checkboxValue) {
      const employee = this.get('employee');
      employee.set('isCoach', checkboxValue);
      this.get('saveEmployee').perform();
    }

    *saveRoles() {
      try {
        const rolesToDelete = this.get('rolesToDelete');
        const rolesToCreate = this.get('rolesToCreate');
        yield (0, _emberConcurrency.all)(rolesToDelete.invoke('destroyRecord'));
        yield (0, _emberConcurrency.all)(rolesToCreate.invoke('save'));
        this.employee.set('roleUsers', this.get('roleUsersCopy'));
        yield this.employee.save();
        this.clToaster.success(this.intl.t('ui.employee-roles-dialog.user-role-updated'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *saveEmployee() {
      const user = yield this.get('account.activeUser');

      try {
        if (this.get('isSelf')) {
          user.set('isCoach', this.employee.get('isCoach'));
          yield user.save();
        }

        yield this.employee.save();
        this.clToaster.success(this.intl.t('ui.employee-roles-dialog.user-role-updated'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *loadRoles() {
      let roleUsers = yield this.employee.get('user.roleUsers');
      yield (0, _emberConcurrency.all)(roleUsers.mapBy('role'));
      let globalAdminRoleUser = roleUsers.find(ru => ru.roleName === 'account_global_admin');
      let companyLocationsRoleUsers = roleUsers.filter(ru => ru.roleName === 'location_global_admin');
      let companyLocations = yield (0, _emberConcurrency.all)(companyLocationsRoleUsers.mapBy('adminable'));
      this.set('globalAdminRoleUser', globalAdminRoleUser);
      this.set('isGlobalAdmin', Boolean(globalAdminRoleUser));
      this.set('companyLocationsRoleUsers', companyLocationsRoleUsers);
      this.set('selectedCompanyLocations', companyLocations.slice());
      this.set('isCoach', this.employee.isCoach);
      let roles = yield this.store.query('role', {
        filter: {
          account_type_customizable: true
        }
      });
      let globalAdminRole = roles.find(r => r.name === 'account_global_admin');
      let locationAdminRole = roles.find(r => r.name === 'location_global_admin');
      this.set('globalAdminRole', globalAdminRole);
      this.set('locationAdminRole', locationAdminRole);
    }

    toggleLocation(location) {
      if (this.selectedCompanyLocations.includes(location)) {
        this.selectedCompanyLocations.removeObject(location);
      } else {
        this.selectedCompanyLocations.addObject(location);
      }
    }

    *saveChanges() {
      // GLOBAL ADMIN CHANGES
      if (this.globalAdminRoleUser && !this.isGlobalAdmin) {
        // remove global admin role
        yield this.globalAdminRoleUser.destroyRecord();
      }

      if (!this.globalAdminRoleUser && this.isGlobalAdmin) {
        // add global admin role
        yield this.store.createRecord('role-user', {
          user: this.employee.get('user'),
          role: this.globalAdminRole,
          adminable: this.employee.get('account')
        }).save();
      } // LOCATION ADMIN CHANGES


      let toDelete = this.companyLocationsRoleUsers.filter(ru => {
        // role users which adminable is no longer present in the new locations list
        return !this.selectedCompanyLocations.find(l => ru.get('adminable.id') === l.id);
      });
      yield (0, _emberConcurrency.all)(toDelete.map(ru => ru.destroyRecord()));
      let toCreate = this.selectedCompanyLocations.filter(l => {
        // locations which aren't present in the original role users list
        return !this.companyLocationsRoleUsers.find(ru => ru.get('adminable.id') === l.id);
      });
      yield (0, _emberConcurrency.all)(toCreate.map(l => {
        return this.store.createRecord('role-user', {
          user: this.employee.get('user'),
          role: this.locationAdminRole,
          adminable: l
        }).save();
      }));

      if (this.employee.isCoach !== this.isCoach) {
        this.employee.set('isCoach', this.isCoach);
        yield this.employee.save();
      }

      this.onClose?.();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "accountRoles", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "locationRoles", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "departmentRoles", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "rolesToDelete", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "rolesToCreate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "canAddRoles", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "canAddRoles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addRole", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "addRole"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeRole", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "removeRole"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleIsCoach", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleIsCoach"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveRoles", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveRoles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveEmployee", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveEmployee"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadRoles", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "loadRoles"), _class2.prototype), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "rolesToDelete", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleLocation", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleLocation"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveChanges"), _class2.prototype)), _class2)) || _class);
  var _default = EmployeeUpdateRolesDialog;
  _exports.default = _default;
});