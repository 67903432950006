define("coachlogix/pods/components/v2/tasks-list/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TasksList = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('onlyTodos', 'taskable.{todos,tasks}'), _dec(_class = (_class2 = (_temp = class TasksList extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "taskText", '');
    }

    get tasks() {
      return this.onlyTodos ? this.taskable.get('todos') : this.taskable.get('tasks');
    }

    *createNewTask() {
      try {
        if (Ember.isBlank(this.taskText)) {
          return;
        }

        let taskable = yield this.taskable;

        if (this.onlyTodos) {
          yield taskable.createTask.perform({
            title: this.taskText,
            taskType: 'todo'
          });
        } else {
          yield taskable.createTask.perform({
            title: this.taskText,
            taskType: 'milestone',
            status: 'published'
          });
        }

        this.set('taskText', '');
      } catch (e) {// ignore
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "tasks", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "tasks"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createNewTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createNewTask"), _class2.prototype)), _class2)) || _class);
  var _default = TasksList;
  _exports.default = _default;
});