define("coachlogix/models/plan", ["exports", "@ember-data/model", "coachlogix/macros/equals-ignore-case", "coachlogix/models/base-model"], function (_exports, _model, _equalsIgnoreCase, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    stripePlanId: (0, _model.attr)('string'),
    seatPlanId: (0, _model.attr)('string'),
    stripeId: (0, _model.attr)('string'),
    seatPrice: (0, _model.attr)('number'),
    basePrice: (0, _model.attr)('number'),
    trialDays: (0, _model.attr)('number'),
    interval: (0, _model.attr)('string'),
    provider: (0, _model.attr)('string'),
    accountType: (0, _model.attr)('string'),
    maxCoaches: (0, _model.attr)('number'),
    maxLocations: (0, _model.attr)('number'),
    maxCollaborators: (0, _model.attr)('number'),
    maxContacts: (0, _model.attr)('number'),
    maxEngagements: (0, _model.attr)('number'),
    sortOrder: (0, _model.attr)('number'),
    isHidden: (0, _model.attr)('boolean'),
    planFeatures: (0, _model.hasMany)('plan-feature', {
      inverse: null
    }),
    isMonthly: (0, _equalsIgnoreCase.default)('interval', 'monthly'),
    isAnnual: (0, _equalsIgnoreCase.default)('interval', 'annual'),
    indexedFeatures: Ember.computed('features.[]', 'planFeatures', function () {
      let obj = {};
      this.get('planFeatures').forEach(f => {
        obj[f.get('siteFeature.name')] = f;
      });
      return obj;
    }),
    isBusiness: Ember.computed('accountType', function () {
      return this.get('accountType').includes('business');
    }),
    isIndependentCoach: Ember.computed('accountType', function () {
      return this.get('accountType').includes('independent_coach');
    }),
    isStandard: Ember.computed('name', function () {
      let name = this.get('name') || '';
      return name.toUpperCase().includes('STANDARD');
    }),
    isPro: Ember.computed('name', function () {
      let name = this.get('name') || '';
      return name.toUpperCase().includes('PRO');
    }),
    isFree: Ember.computed('interval', function () {
      let interval = this.get('interval') || '';
      return interval.toUpperCase().includes('FREE');
    })
  });

  _exports.default = _default;
});