define("coachlogix/pods/form/answers/submission/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormAnswersSubmissionRoute = (_dec = Ember.inject.service, (_class = (_temp = class FormAnswersSubmissionRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    model({
      form_submission_id
    }) {
      let loadSubmissionTask = this.loadSubmission.perform(form_submission_id);
      return {
        submission: loadSubmissionTask,
        engagement: this.loadEngagement.perform(loadSubmissionTask),
        ...this.modelFor('form')
      };
    }

    *loadSubmission(id) {
      let include = ['answers', 'resource.form-questions.form-section', 'resource.form-sections.form-questions', 'resource.form-questions.form-question-options', 'assignment.assignable.engagement.coach.user', 'assignment.assignable.engagement.coachee.user'].join(',');
      return yield this.store.findRecord('form-submission', id, {
        reload: true,
        include
      });
    }

    *loadEngagement(loadSubmissionTask) {
      try {
        let submission = yield loadSubmissionTask;
        let assignment = yield submission.get('assignment');
        let task = yield assignment.get('assignable');
        return yield task.get('engagement');
      } catch (e) {
        return null;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadSubmission", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadSubmission"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadEngagement", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadEngagement"), _class.prototype)), _class));
  _exports.default = FormAnswersSubmissionRoute;
});