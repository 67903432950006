define("coachlogix/initializers/ignore-rel-link-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IGNORED_WARNINGS = ['ds.store.push-link-for-sync-relationship'];
  var _default = {
    name: 'warnings-to-ignore',

    initialize() {
      Ember.Debug.registerWarnHandler(function (message, options, next) {
        if (!ignoreWarning(options)) {
          next(...arguments);
        }
      });
    }

  };
  _exports.default = _default;

  function ignoreWarning(options) {
    return options && options.id && IGNORED_WARNINGS.includes(options.id);
  }
});