define("coachlogix/pods/components/profile/affiliations/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProfileAffiliationsComponent extends _component.default {
    get userAffiliations() {
      return this.args.profile.get('userAffiliations').filter(e => !e.isNew);
    }

  }

  _exports.default = ProfileAffiliationsComponent;
});