define("coachlogix/models/assignment", ["exports", "@ember-data/model", "ember-concurrency", "qs-stringify", "coachlogix/models/base-model", "coachlogix/models/reviewable", "coachlogix/models/taskable"], function (_exports, _model, _emberConcurrency, _qsStringify, _baseModel, _reviewable, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend(_reviewable.default, _taskable.default, {
    ajax: Ember.inject.service(),
    actualDuration: (0, _model.attr)('number'),
    completionDate: (0, _model.attr)('datetime'),
    assignedDate: (0, _model.attr)('date'),
    dueDate: (0, _model.attr)('date'),
    isStarred: (0, _model.attr)('boolean'),
    startDate: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    // draft || assigned || completed
    lastReminder: (0, _model.attr)('date'),
    assignable: (0, _model.belongsTo)('base-model', {
      polymorphic: true,
      inverse: null
    }),
    assignee: (0, _model.belongsTo)('employee', {
      async: true,
      inverse: null
    }),
    formSubmissions: (0, _model.hasMany)('form-submission', {
      inverse: 'assignment'
    }),
    notifications: (0, _model.hasMany)('notification', {
      inverse: null
    }),
    resource: (0, _model.belongsTo)('resource', {
      inverse: null
    }),
    updateDueDate: (0, _emberConcurrency.task)(function* (dueDate) {
      this.set('dueDate', dueDate);
      yield this.save();
    }),
    isCompleted: Ember.computed.equal('status', 'completed'),
    toggleCompletion: (0, _emberConcurrency.task)(function* () {
      if (!this.get('isCompleted')) {
        this.set('completionDate', new Date());
        this.set('status', 'completed');
      } else {
        this.set('completionDate', null);
        this.set('status', 'assigned');
      }

      yield this.save();
      this.clToaster.success('Task status updated');
    }).drop(),
    requestFeedback: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.store.createRecord('task', {
          taskType: 'feedback_request',
          taskable: this
        }).save();
        this.clToaster.success('Request sent.');
      } catch (e) {
        this.clToaster.error(e);
      }
    }).drop(),
    resend: (0, _emberConcurrency.task)(function* (taskType) {
      try {
        let assignmentId = this.get('id');
        let recipientId = this.get('assignee.id');
        let assignable = yield this.get('assignable');
        let typeKey = assignable.get('notificationType');
        taskType = assignable.get('taskType');
        let queryString = (0, _qsStringify.default)({
          recipient_id: recipientId,
          notifiable_type: 'assignments',
          notifiable_id: assignmentId,
          type_key: typeKey
        });
        yield this.get('ajax').post(`/helpers/resend-notification?${queryString}`);

        if (taskType) {
          let taskTypeLabel = taskType.capitalize();
          this.clToaster.success(`${taskTypeLabel} sent again.`);
        } else {
          this.clToaster.success('Notification sent successfully.');
        }
      } catch (e) {
        this.clToaster.error('You can only send once every 24 hours');
      }
    })
  });

  _exports.default = _default;
});