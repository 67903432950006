define("coachlogix/models/billing-setting", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    vatNumber: (0, _model.attr)('string'),
    ein: (0, _model.attr)('string'),
    invoiceHeader: (0, _model.attr)('string'),
    invoiceFooter: (0, _model.attr)('string'),
    invoiceTerms: (0, _model.attr)('string'),
    invoiceNumberPrefix: (0, _model.attr)('string'),
    invoiceNumberCounter: (0, _model.attr)('string'),
    invoiceNumberPadding: (0, _model.attr)('string'),
    invoiceNumberPattern: (0, _model.attr)('string'),
    invoiceTaxes: (0, _model.attr)('string'),
    invoiceItemTaxes: (0, _model.attr)('string'),
    invoiceLabels: (0, _model.attr)('string'),
    invoiceFields: (0, _model.attr)('string'),
    invoiceEmbedDocuments: (0, _model.attr)('string'),
    documentEmailAttachment: (0, _model.attr)('string'),
    lateFee1Amount: (0, _model.attr)('string'),
    lateFee1Percent: (0, _model.attr)('string'),
    lateFee2Amount: (0, _model.attr)('string'),
    lateFee2Percent: (0, _model.attr)('string'),
    lateFee3Amount: (0, _model.attr)('string'),
    lateFee3Percent: (0, _model.attr)('string'),
    taxName1: (0, _model.attr)('string'),
    taxRate1: (0, _model.attr)('string'),
    taxName2: (0, _model.attr)('string'),
    taxRate2: (0, _model.attr)('string'),
    customInvoiceLabel1: (0, _model.attr)('string'),
    customInvoiceLabel2: (0, _model.attr)('string'),
    customInvoiceTaxes1: (0, _model.attr)('string'),
    customInvoiceTaxes2: (0, _model.attr)('string'),
    customInvoiceItemLabel1: (0, _model.attr)('string'),
    customInvoiceItemLabel2: (0, _model.attr)('string'),
    enableSecondTaxRate: (0, _model.attr)('string'),
    showItemTaxes: (0, _model.attr)('string'),
    autoBillOnDueDate: (0, _model.attr)('string'),
    startOfWeek: (0, _model.attr)('string'),
    recurringHour: (0, _model.attr)('string'),
    showAcceptInvoiceTerms: (0, _model.attr)('string'),
    showAcceptQuoteTerms: (0, _model.attr)('string'),
    quoteTerms: (0, _model.attr)('string'),
    quoteNumberPrefix: (0, _model.attr)('string'),
    quoteNumberCounter: (0, _model.attr)('string'),
    autoConvertQuote: (0, _model.attr)('string'),
    utf8Invoices: (0, _model.attr)('string'),
    autoWrap: (0, _model.attr)('string'),
    subdomain: (0, _model.attr)('string'),
    recurringInvoiceNumberPrefix: (0, _model.attr)('string'),
    shareCounter: (0, _model.attr)('string'),
    creditNumberCounter: (0, _model.attr)('string'),
    creditNumberPrefix: (0, _model.attr)('string'),
    creditNumberPattern: (0, _model.attr)('string'),
    customClientLabel1: (0, _model.attr)('string'),
    customClientLabel2: (0, _model.attr)('string'),
    vatPercent: (0, _model.attr)('number'),
    accountsPayableEmail: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    address2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    postalCode: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    billingContact: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    currency: (0, _model.belongsTo)('currency', {
      inverse: null
    }),
    invoiceDesign: (0, _model.belongsTo)('invoice-design', {
      inverse: null
    }),
    account: (0, _model.belongsTo)('account', {
      inverse: 'billingSettings'
    })
  });

  _exports.default = _default;
});