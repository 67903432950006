define("coachlogix/pods/video/video-track/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * This component's purpose is to renders a twilio VideoTrack
   * https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/VideoTrack.html
   */
  let VideoTrackComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = (_temp = class VideoTrackComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "videoTrack", null);
    }

    setupVideoElement(videoElement, [videoTrack]) {
      if (this.videoTrack === videoTrack) {
        return;
      }

      if (this.videoTrack) {
        this.destroyVideoElement();
      }

      (true && !(videoTrack) && Ember.assert('a @videoTrack argument must be passed in', videoTrack));
      videoTrack.attach(videoElement);
      this.videoTrack = videoTrack;
    }

    destroyVideoElement() {
      // no need to detach. ember will remove the element or reattach a new track
      // this was causing some bugs when switching video tracks
      // this.videoTrack.detach(videoElement);
      this.videoTrack = null;
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "setupVideoElement", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setupVideoElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyVideoElement", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "destroyVideoElement"), _class.prototype)), _class));
  _exports.default = VideoTrackComponent;
});