define("coachlogix/pods/components/v2/engagement/locations-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MeetingLocationsDialog = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.reads('location'), _dec5 = Ember.computed.reads('loadMeetingLocations.isRunning'), _dec6 = Ember.computed.reads('setSelected.isRunning'), _dec7 = Ember.computed.or('isFetchingLocations', 'isFetchingSelected'), _dec8 = (0, _emberConcurrencyDecorators.lastValue)('loadMeetingLocations'), _dec9 = Ember.computed('selectedTab'), _dec10 = Ember.computed('meetingLocationsValue.@each.isNew'), _dec11 = Ember.computed('meetingType', 'locationAdditionalInfo', 'videoAdditionalInfo', 'phoneAdditionalInfo'), _dec12 = Ember.computed('selectedLocation', 'creatingNewLocation', 'meetingType', 'additionalInfo'), _dec13 = Ember._action, _dec14 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec15 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec(_class = (_class2 = (_temp = class MeetingLocationsDialog extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "selectedTab", 'add-new');

      _defineProperty(this, "saveForLater", true);

      _initializerDefineProperty(this, "selectedLocation", _descriptor3, this);

      _initializerDefineProperty(this, "isFetchingLocations", _descriptor4, this);

      _initializerDefineProperty(this, "isFetchingSelected", _descriptor5, this);

      _initializerDefineProperty(this, "isLoading", _descriptor6, this);

      _initializerDefineProperty(this, "meetingLocationsValue", _descriptor7, this);
    }

    get creatingNewLocation() {
      return this.selectedTab === 'add-new';
    }

    get meetingLocations() {
      let meetingLocations = this.meetingLocationsValue || [];
      return meetingLocations.filter(l => !l.get('isNew'));
    }

    get additionalInfo() {
      if (this.meetingType === 'location') {
        return this.locationAdditionalInfo;
      }

      if (this.meetingType === 'video') {
        return this.videoAdditionalInfo;
      }

      if (this.meetingType === 'phone') {
        return this.phoneAdditionalInfo;
      }

      return null;
    }

    get isValid() {
      if (this.creatingNewLocation) {
        return this.meetingType && this.additionalInfo;
      }

      return true;
    }
    /**
     * Actions
     */


    clearLocation() {
      this.set('selectedLocation', null);
    }
    /**
     * Tasks
     */


    *loadMeetingLocations() {
      if (this.onlyAdd) {
        return;
      }

      let locations = yield this.store.query('meeting-location', {}); // If the user has saved locations, show that tab instead.

      if (locations.length > 0) {
        this.set('selectedTab', 'saved');
      }

      return locations;
    }

    *setSelected() {
      let location = yield this.selected;
      this.set('selectedLocation', location);
    }

    *createLocation() {
      if (this.creatingNewLocation) {
        let meetingLocation = this.store.createRecord('meeting-location', {
          meetingType: this.meetingType,
          additionalInfo: this.additionalInfo,
          oneTimeUse: !this.saveForLater
        });
        yield meetingLocation.save();
        this.onChange(meetingLocation);
      } else {
        this.onChange(this.selectedLocation);
      }

      this.onClose();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "selectedLocation", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isFetchingLocations", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isFetchingSelected", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isLoading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "meetingLocationsValue", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "creatingNewLocation", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "creatingNewLocation"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "meetingLocations", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "meetingLocations"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "additionalInfo", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "additionalInfo"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isValid", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "isValid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearLocation", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "clearLocation"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadMeetingLocations", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "loadMeetingLocations"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setSelected", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "setSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createLocation", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createLocation"), _class2.prototype)), _class2)) || _class);
  var _default = MeetingLocationsDialog;
  _exports.default = _default;
});