define("coachlogix/pods/program-template/program/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hbe41byR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-flex cl-flex-col cl-flex-1 cl-items-center cl-justify-center cl-space-y-1\"],[12],[2,\"\\n  \"],[8,\"cl-icon\",[],[[\"@icon\",\"@size\"],[\"description\",120]],null],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"cl-title-4\"],[12],[1,[30,[36,0],[\"program-templates.start-building\"],null]],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"cl-title-6 cl-m-0 cl-text-light-grey-blue\"],[12],[1,[30,[36,0],[\"program-templates.start-building-helper\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/program-template/program/index/template.hbs"
    }
  });

  _exports.default = _default;
});