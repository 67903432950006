define("coachlogix/pods/dashboard/card-scroller/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CardScrollerComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = (_class2 = (_temp = class CardScrollerComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "page", _descriptor, this);

      _initializerDefineProperty(this, "totalPages", _descriptor2, this);
    }

    registerWidgetsScroller(element) {
      this.widgetsScroller = element;
      this.updateTotalPages();
    }

    nextPage() {
      this.page++;
      this.updateTransform();
    }

    previousPage() {
      this.page--;
      this.updateTransform();
    }

    updateTransform() {
      this.updateTotalPages();
      let widgetsWidth = this.widgetsScroller.clientWidth - 20;
      let parentWidth = this.widgetsScroller.parentNode.clientWidth - 40; // minus padding

      let expectedPageWidth = this.widgetsScroller.parentNode.clientWidth * this.page;
      let fromEndWidth = widgetsWidth - parentWidth;
      let width = Math.min(expectedPageWidth, fromEndWidth);
      this.widgetsScroller.style.transform = `translateX(-${width}px)`;
    }

    updateTotalPages() {
      let widgetsWidth = this.widgetsScroller.clientWidth - 20;
      let parentWidth = this.widgetsScroller.parentNode.clientWidth - 40; // minus padding

      this.totalPages = Math.ceil(widgetsWidth / parentWidth) - 1;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "page", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "totalPages", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "registerWidgetsScroller", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "registerWidgetsScroller"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "nextPage", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "nextPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "previousPage", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "previousPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateTotalPages", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "updateTotalPages"), _class2.prototype)), _class2)) || _class);
  var _default = CardScrollerComponent;
  _exports.default = _default;
});