define("coachlogix/services/clock", ["exports", "coachlogix/config/environment", "ember-clock/services/clock"], function (_exports, _environment, _clock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isDisabled = _environment.default['ember-clock'] && _environment.default['ember-clock'].disabled;

  var _default = _clock.default.extend({
    disabled: isDisabled
  });

  _exports.default = _default;
});