define("coachlogix/pods/employee/profile-header/component", ["exports", "@glimmer/component", "ember-ajax/errors", "ember-concurrency-decorators"], function (_exports, _component, _errors, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmployeeProfileHeaderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember.computed.reads('args.engagement.readOnlyMatching'), _dec8 = Ember.computed.reads('args.engagement.matchingSettings.coachPoolsNoInterviewAllowed'), _dec9 = Ember.computed.reads('args.engagement.matchingSettings.coachPoolsRequiredInterview'), _dec10 = Ember.computed.reads('args.engagement.matchingSettings.coachPoolsStartEngagementAutomatically'), _dec11 = Ember.computed.reads('args.engagement.matchingSettings.coachPoolsInterviewLength'), _dec12 = Ember.computed.reads('args.engagement.matchingSettings.coachPoolsInterviewTitle'), _dec13 = Ember.computed.reads('args.engagement.matchingSettings.coachPoolsInterviewDescription'), _dec14 = Ember.computed.reads('args.user.availabilityPreferences.defaultMeetingLocation'), _dec15 = Ember.computed.reads('args.engagement.matchingSettings.coachPoolsInterviewTitle'), _dec16 = Ember._tracked, _dec17 = Ember.computed('args.{employee.coachingRequests.[],engagement.id}'), _dec18 = Ember.computed('isReadOnly', 'coachingRequest.{isInterviewApproved,isNew}', 'args.employee.user.isCronofyConnected', 'isInterviewAllowed'), _dec19 = Ember.computed('isReadOnly', 'isInterviewAllowed', 'coachingRequest', 'args.employee.user.isCronofyConnected'), _dec20 = Ember.computed('args.employee', 'coachingRequest.{isInterviewScheduled,isNew}', 'isInterviewAllowed', 'isReadOnly'), _dec21 = Ember.computed('isReadOnly', 'coachingRequest.{isInterviewCompleted,isNew}', 'args.engagement', 'isInterviewAllowed', 'isRequiredInterview'), _dec22 = Ember._action, (_class = (_temp = class EmployeeProfileHeaderComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "twilio", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "openInterviewScheduler", _descriptor6, this);

      _initializerDefineProperty(this, "isReadOnly", _descriptor7, this);

      _initializerDefineProperty(this, "isInterviewAllowed", _descriptor8, this);

      _initializerDefineProperty(this, "isRequiredInterview", _descriptor9, this);

      _initializerDefineProperty(this, "startEngagementAutomatically", _descriptor10, this);

      _initializerDefineProperty(this, "defaultInterviewDuration", _descriptor11, this);

      _initializerDefineProperty(this, "defaultInterviewTitle", _descriptor12, this);

      _initializerDefineProperty(this, "defaultInterviewDescription", _descriptor13, this);

      _initializerDefineProperty(this, "defaultInterviewLocation", _descriptor14, this);

      _initializerDefineProperty(this, "defaultInterviewTitle", _descriptor15, this);

      _initializerDefineProperty(this, "timeSuggestionsComment", _descriptor16, this);
    }

    get coachingRequest() {
      if (!this.args.engagement) {
        return false;
      }

      let coachingRequests = this.args.employee.coachingRequests;
      return coachingRequests.find(c => {
        return c.belongsTo('coachee').id() === this.args.engagement.belongsTo('coachee').id() && c.belongsTo('engagement').id() === this.args.engagement.id;
      });
    }

    get canScheduleInterview() {
      if (this.isReadOnly) {
        return false;
      }

      if (this.coachingRequest && !this.coachingRequest.isNew) {
        return this.coachingRequest.isInterviewApproved && this.args.employee.get('user.isCronofyConnected');
      } else {
        return this.isInterviewAllowed && this.args.employee.get('user.isCronofyConnected');
      }
    }

    get canRequestInterview() {
      if (this.isReadOnly) {
        return false;
      }

      return this.isInterviewAllowed && !this.coachingRequest && !this.args.employee.get('user.isCronofyConnected');
    }

    get canRescheduleInterview() {
      if (this.isReadOnly) {
        return false;
      }

      return this.coachingRequest && !this.coachingRequest.isNew && this.coachingRequest.isInterviewScheduled && this.isInterviewAllowed && this.args.employee.get('user.isCronofyConnected');
    }

    get canSelectCoach() {
      if (!this.args.engagement || this.isReadOnly) {
        return false;
      }

      if (this.coachingRequest && !this.coachingRequest.isNew) {
        return !this.isRequiredInterview || this.coachingRequest.isInterviewCompleted;
      } else {
        return !this.isInterviewAllowed || !this.isRequiredInterview;
      }
    }

    createRequestForInterview() {
      if (!this.coachingRequest) {
        this.store.createRecord('coaching-request', {
          coach: this.args.employee,
          coachee: this.args.engagement?.get('coachee'),
          engagement: this.args.engagement,
          status: 'interview_approved',
          duration: this.defaultInterviewDuration,
          meetingLocation: this.defaultInterviewLocation,
          interviewTitle: this.defaultInterviewTitle,
          description: this.defaultInterviewDescription
        });
      }

      this.openInterviewScheduler = true;
    }

    *selectCoach() {
      try {
        // the coaching request *might* not yet exist because there's the possibility
        // to directly request the coach without an interview (when required interview global setting is false)
        let coachingRequest = this.coachingRequest;

        if (!coachingRequest) {
          coachingRequest = this.store.createRecord('coaching-request', {
            coach: this.args.employee,
            coachee: this.args.engagement.get('coachee'),
            engagement: this.args.engagement
          });
        }

        coachingRequest.set('status', 'coach_selected');
        yield coachingRequest.save(); // the request might be auto approved / auto started
        // when that is the case, we redirect the coachee to the engagement itself, not
        //

        if (coachingRequest.get('isCoachApproved')) {
          yield this.router.replaceWith('engagement', this.args.engagement.id);
          let currentRouteName = 'engagement';
          let currentRouteInstance = Ember.getOwner(this).lookup(`route:${currentRouteName}`);
          currentRouteInstance.refresh();
        } else {
          this.router.replaceWith('engagement.matching-process-selection', this.args.engagement.id, coachingRequest.id);
        }
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *sendCoachingRequest() {
      let coachingRequest, channel;

      try {
        if (!this.twilio.client) {
          yield this.twilio.initTwilio.perform();
        }

        let engagement = this.args.engagement;
        let coach = this.args.employee;
        let coachee = yield this.args.engagement.get('coachee');
        let coachUser = yield coach.get('user');
        let coacheeUser = yield coachee.get('user');
        coachingRequest = this.store.createRecord('coaching-request', {
          coach,
          coachee,
          engagement,
          status: 'interview_times_requested',
          duration: this.defaultInterviewDuration,
          meetingLocation: this.defaultInterviewLocation,
          timeSuggestionsComment: this.timeSuggestionsComment,
          interviewTitle: this.defaultInterviewTitle,
          description: this.defaultInterviewDescription
        });
        channel = yield this.twilio.createPersonalChannel.perform(coachUser);
        coachingRequest.set('channelId', channel.sid);
        yield coachingRequest.save();
        let text = this.intl.t('coaches.request-interview-message', {
          coachName: coachUser.name,
          coacheeName: coacheeUser.name
        });
        yield channel.sendMessage(text, {
          type: 'coaching-request',
          coachingRequestId: coachingRequest.id
        });
        let dockChannels = this.twilio.dockChannels;
        dockChannels.addObject(channel);
        this.timeSuggestionsComment = '';
        this.clToaster.success('Request sent succesfully!');
      } catch (e) {
        if (coachingRequest) {
          coachingRequest.deleteRecord();
        }

        if (channel) {
          channel.delete();
        }

        if ((0, _errors.isBadRequestError)(e) || (0, _errors.isConflictError)(e)) {
          let errors = e.payload.errors || [];

          if (errors.length > 0) {
            let error = errors[0];
            this.clToaster.error(this.intl.t(`engagement-coaching-request.errors.${error.code}`));
          }
        } else {
          this.clToaster.error(e);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "twilio", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "openInterviewScheduler", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isInterviewAllowed", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isRequiredInterview", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "startEngagementAutomatically", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "defaultInterviewDuration", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "defaultInterviewTitle", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "defaultInterviewDescription", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "defaultInterviewLocation", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "defaultInterviewTitle", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "timeSuggestionsComment", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "coachingRequest", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "coachingRequest"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canScheduleInterview", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "canScheduleInterview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canRequestInterview", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "canRequestInterview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canRescheduleInterview", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "canRescheduleInterview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canSelectCoach", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "canSelectCoach"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createRequestForInterview", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "createRequestForInterview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCoach", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "selectCoach"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendCoachingRequest", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "sendCoachingRequest"), _class.prototype)), _class));
  _exports.default = EmployeeProfileHeaderComponent;
});