define("coachlogix/models/client-summary", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    totalContracts: (0, _model.attr)('number'),
    activeContracts: (0, _model.attr)('number'),
    totalInvoices: (0, _model.attr)('number'),
    amountInvoiced: (0, _model.attr)('number'),
    amountPaid: (0, _model.attr)('number'),
    amountOutstanding: (0, _model.attr)('number'),
    engagementsActive: (0, _model.attr)('number'),
    engagementsDraft: (0, _model.attr)('number'),
    engagementsCompleted: (0, _model.attr)('number'),
    engagementHoursScheduled: (0, _model.attr)('number'),
    engagementHoursCompleted: (0, _model.attr)('number'),
    engagementsTotal: (0, _model.attr)('number'),
    sessionsScheduled: (0, _model.attr)('number'),
    sessionsCompleted: (0, _model.attr)('number'),
    invitesAccepted: (0, _model.attr)('number'),
    invitesPending: (0, _model.attr)('number'),
    totalCoaches: (0, _model.attr)('number'),
    totalCoachees: (0, _model.attr)('number'),
    activeCoaches: (0, _model.attr)('number'),
    activeCoachees: (0, _model.attr)('number'),
    coachSharesAccepted: (0, _model.attr)('number'),
    coachSharesPending: (0, _model.attr)('number'),
    totalReviews: (0, _model.attr)('number'),
    averageRating: (0, _model.attr)('number'),
    totalCoachReviews: (0, _model.attr)('number'),
    averageCoachRating: (0, _model.attr)('number'),
    client: (0, _model.belongsTo)('client', {
      inverse: 'clientSummary'
    }),
    hasSummaryData: Ember.computed('totalInvoices', 'totalContracts', 'engagementsActive', 'engagementsCompleted', function () {
      return this.get('totalInvoices') > 0 || this.get('totalContracts') > 0 || this.get('engagementsActive') > 0 || this.get('engagementsCompleted') > 0;
    }),
    engagementsValues: Ember.computed.collect('engagementsActive', 'engagementsCompleted'),
    totalEngagements: Ember.computed.sum('engagementsValues'),
    hourValues: Ember.computed.collect('engagementHoursCompleted', 'engagementHoursScheduled'),
    totalHours: Ember.computed.sum('hourValues'),
    percentageHours: Ember.computed('engagementHoursCompleted', 'totalHours', function () {
      return Math.round(this.get('engagementHoursCompleted') / this.get('totalHours') * 100) || 0;
    }),
    sessionValues: Ember.computed.collect('sessionsCompleted', 'sessionsScheduled'),
    totalSessions: Ember.computed.sum('sessionValues'),
    percentageSessions: Ember.computed('sessionsCompleted', 'totalSessions', function () {
      return Math.round(this.get('sessionsCompleted') / this.get('totalSessions') * 100) || 0;
    })
  });

  _exports.default = _default;
});