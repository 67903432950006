define("coachlogix/pods/components/cronofy/availability-viewer/component", ["exports", "@ember-decorators/component", "cronofy-elements", "moment"], function (_exports, _component, _cronofyElements, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AvailabilityViewerComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec(_class = (_class2 = (_temp = class AvailabilityViewerComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "uniqueId", Ember.guidFor(this));

      _initializerDefineProperty(this, "account", _descriptor, this);
    }

    async setupElement(element, [token]) {
      element.id = this.uniqueId;
      let start = (0, _moment.default)(this.start);
      let today = (0, _moment.default)(); // cronofy doesn't let us ask for availability in the past
      // so if the given start date is in the past, we send the current time

      start = start.isBefore(today) ? today : start;
      start = start.clone().utc().milliseconds(0);
      let availabilityPreferences = await this.user.get('availabilityPreferences');

      if (availabilityPreferences.withinHours) {
        start = start.add(availabilityPreferences.withinHours, 'hours');
      }

      start = start.toISOString();
      /*
      let end = moment(start)
        .clone()
        .add(34, 'days')
        .utc()
        .milliseconds(0)
        .toISOString();
      */

      let end = (0, _moment.default)(this.end).clone().utc().milliseconds(0).toISOString();
      let cAccount = await this.user.get('cronofyConnectedAccount');
      let duration = this.duration || 60;
      let interval = this.interval || 30;
      let locale = this.account.activeUser.iso639Language;
      let tzid = this.account.activeUser.get('timezone.name');
      (0, _cronofyElements.AvailabilityViewer)({
        target_id: this.uniqueId,
        element_token: token,
        availability_query: {
          participants: [{
            required: 'all',
            members: [{
              sub: cAccount.cronofyId
            }]
          }],
          available_periods: [{
            start,
            end
          }],
          required_duration: {
            minutes: duration
          }
        },
        config: {
          slot_selection: this.canForceSchedule ? 'unrestricted' : 'available',
          mode: 'no_confirm',
          start_time: '07:00',
          end_time: '19:00',
          interval,
          bounds_control: true
        },
        locale,
        tzid,
        styles: {
          prefix: 'availability-viewer'
        },
        callback: ({
          notification
        }) => {
          if (notification.type === 'slot_selected' && this.onSlotSelected) {
            this.onSlotSelected({
              slotStart: (0, _moment.default)(notification.slot.start),
              slotEnd: (0, _moment.default)(notification.slot.end),
              isAvailable: this.canForceSchedule ? notification.slot.available : true
            });
          }
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "setupElement", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "setupElement"), _class2.prototype)), _class2)) || _class);
  var _default = AvailabilityViewerComponent;
  _exports.default = _default;
});