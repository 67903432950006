define("coachlogix/pods/video/audio-track/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bUItZRGB",
    "block": "{\"symbols\":[\"&attrs\",\"@audioTrack\"],\"statements\":[[11,\"audio\"],[17,1],[4,[38,0],[[32,0,[\"setupAudioElement\"]],[32,2]],null],[4,[38,1],[[32,0,[\"setupAudioElement\"]],[32,2]],null],[4,[38,1],[[32,0,[\"twilioVideo\",\"applyAudioOutputDevice\"]],[32,0,[\"twilioVideo\",\"selectedAudioOutput\"]]],null],[4,[38,2],[[32,0,[\"destroyAudioElement\"]]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/video/audio-track/template.hbs"
    }
  });

  _exports.default = _default;
});