define("coachlogix/pods/components/v2/stripe-card-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "11p27oM4",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[8,\"cl-input\",[[17,1]],[[\"@containerClass\",\"@label\"],[[32,0,[\"styleNamespace\"]],[30,[36,0],[\"ui.credit-or-debit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[24,0,\"cl-rounded-sm cl-border-solid cl-border-light-grey-blue cl-border\"],[4,[38,1],[[32,0,[\"mountStripeElement\"]]],null],[4,[38,2],[[32,0,[\"unmountStripeElement\"]]],null],[12],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"cl-text-danger cl-m-0 cl-mt-0.5\"],[12],[1,[32,0,[\"error\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"did-insert\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/stripe-card-input/template.hbs"
    }
  });

  _exports.default = _default;
});