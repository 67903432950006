define("coachlogix/pods/notifications/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NOTIFICATIONS_ROUTER = [{
    type: 'schedule_session',
    route: 'engagement.program.program-item',

    async params(notification) {
      let notifiable = await notification.get('notifiable');

      if (notifiable.get('modelName') == 'engagement-session') {
        return [notifiable.belongsTo('engagement').id(), notifiable.belongsTo('programItem').id()];
      }
    }

  }, {
    type: 'receive_invoice',

    route(isAuthenticated) {
      return isAuthenticated ? 'invoice' : 'outside.invoice-payment';
    },

    params(notification, isAuthenticated) {
      if (isAuthenticated) {
        return notification.belongsTo('notifiable').id();
      } else {
        return notification.get('token');
      }
    }

  }, {
    type: 'create_contract_invoice',
    route: 'invoice',

    params(notification) {
      return notification.belongsTo('notifiable').id();
    }

  }, {
    type: 'invoice_payment_documented',
    route: 'invoice.payments',

    async params(notification) {
      let notifiable = await notification.get('notifiable');
      return notifiable.belongsTo('invoice').id();
    }

  }, {
    type: 'complete_task',
    route: 'engagement.program.program-item',

    async params(notification) {
      let assignment = await notification.get('notifiable');
      let task = await assignment.get('assignable');

      if (task) {
        let programItemId = task.belongsTo('programItem').id();
        let engagementId = task.belongsTo('engagement').id();
        return [engagementId, programItemId];
      }
    }

  }, {
    type: 'sent_contract_invoice',
    route: 'invoice',

    params(notification) {
      return notification.belongsTo('notifiable').id();
    }

  }, {
    type: 'invite_coach',
    route: 'client',

    params(notification) {
      return notification.belongsTo('notifiable').id();
    }

  }, {
    type: 'shared_contract',

    async route() {
      return 'dashboard';
    },

    async queryParams(notification) {
      let contract = await notification.get('notifiable');
      return {
        approveContractId: contract.get('id')
      };
    }

  }, {
    type: 'accept_contract',

    async route(_, notification) {
      let contract = await notification.get('notifiable');
      let client = await contract.get('client');
      let recipient = await notification.get('receiver');
      let recipientAccountId = recipient.belongsTo('account').id();

      if (client.belongsTo('clientAccount').id() === recipientAccountId) {
        return 'vendor';
      } else {
        return 'client';
      }
    },

    async params(notification) {
      let contract = await notification.get('notifiable');
      let client = await contract.get('client');
      return client.get('id');
    },

    async queryParams(notification) {
      let contract = await notification.get('notifiable');
      return {
        contract: contract.get('id')
      };
    }

  }, {
    type: 'receive_stripe_invoice',

    route(isAuthenticated) {
      return isAuthenticated ? 'invoice' : 'outside.invoice-payment';
    },

    params(notification, isAuthenticated) {
      if (isAuthenticated) {
        return notification.belongsTo('notifiable').id();
      } else {
        return notification.get('token');
      }
    }

  }, {
    type: 'invited_*',
    needsTempToken: true,
    route: 'outside.invite',

    async params(notification) {
      return await notification.get('notifiable');
    }

  }, {
    type: 'invite_client_request',
    route: 'vendor',

    async params(notification) {
      return await notification.belongsTo('notifiable').id();
    }

  }, {
    type: 'invite_vendor_client',

    async route(_, notification) {
      let client = await notification.get('notifiable');
      let recipient = await notification.get('receiver');
      let recipientAccountId = recipient.belongsTo('account').id();

      if (client.belongsTo('clientAccount').id() === recipientAccountId) {
        return 'vendor';
      } else {
        return 'client';
      }
    },

    async params(notification) {
      let client = await notification.get('notifiable');
      return client.get('id');
    }

  }, {
    type: 'invite_accept_client',

    async route(_, notification) {
      let client = await notification.get('notifiable');
      let recipient = await notification.get('receiver');
      let recipientAccountId = recipient.belongsTo('account').id();

      if (client.belongsTo('clientAccount').id() === recipientAccountId) {
        return 'vendor';
      } else {
        return 'client';
      }
    },

    async params(notification) {
      let client = await notification.get('notifiable');
      return client.get('id');
    }

  }, {
    type: 'receive_stripe_invoice',

    route(isAuthenticated) {
      return isAuthenticated ? 'invoice' : 'outside.invoice-payment';
    },

    params(notification, isAuthenticated) {
      if (isAuthenticated) {
        return notification.belongsTo('notifiable').id();
      } else {
        return notification.get('token');
      }
    }

  }, {
    type: 'engagement_form_received',
    needsTempToken: true,

    route(_, notification) {
      let existingSubmission = notification.get('notifiable.formSubmissions.firstObject');
      let blockingStatuses = ['submitted', 'reviewed', 'archived'];

      if (existingSubmission && blockingStatuses.includes(existingSubmission.status)) {
        return 'outside.form.expired';
      }

      return 'outside.form.submit';
    },

    params(notification, _, route) {
      if (route !== 'dashboard') {
        return notification.belongsTo('notifiable').id();
      }
    }

  }, {
    type: 'external_form_complete',
    needsTempToken: true,
    route: 'form.answers.submission',

    async params(notification) {
      let assignment = await notification.get('notifiable');
      let [submissionsId] = assignment.hasMany('formSubmissions').ids();
      return [assignment.belongsTo('assignable').id(), submissionsId];
    }

  }, {
    type: 'external_form_received',
    needsTempToken: true,

    route(_, notification) {
      let existingSubmission = notification.get('notifiable.formSubmissions.firstObject');
      let blockingStatuses = ['submitted', 'reviewed', 'archived'];

      if (existingSubmission && blockingStatuses.includes(existingSubmission.status)) {
        return 'outside.form.expired';
      }

      return 'outside.form.submit';
    },

    params(notification) {
      return notification.belongsTo('notifiable').id();
    }

  }, {
    type: 'engagement_form_complete',
    route: 'engagement.program.program-item',

    async params(notification) {
      let assignment = await notification.get('notifiable');
      let task = await assignment.get('assignable');
      return [notification.belongsTo('targetable').id(), task.belongsTo('programItem').id()];
    }

  }, {
    type: 'engagement_resource_shared',
    route: 'engagement.resources',

    async params(notification) {
      return notification.get('targetable').get('id');
    }

  }, {
    type: 'coach_pool_engagement_paused',
    route: 'engagement',
    params: notification => notification.belongsTo('notifiable').id(),

    async queryParams() {
      return {
        upgrade: true
      };
    }

  }, {
    type: 'publish_engagement',
    route: 'engagement',
    params: notification => notification.belongsTo('notifiable').id()
  }, {
    type: 'create_engagement',
    route: 'engagement',
    params: notification => notification.belongsTo('notifiable').id()
  }, {
    type: 'engagement_note_shared',
    route: 'engagement.notes',

    async params(notification) {
      return notification.get('targetable').get('id');
    }

  }, {
    type: 'goal_assigned',
    route: 'engagement.goals.goal',

    async params(notification) {
      let goal = await notification.get('notifiable');
      return [goal.belongsTo('goalable').id(), goal.get('id')];
    }

  }, {
    type: 'goal_feedback_request',
    needsTempToken: true,
    route: 'outside.feedback',
    params: notification => notification.belongsTo('notifiable').id()
  }, {
    type: 'client_coach_shared',
    route: 'vendor.coaches',

    async params(notification) {
      let clientCoach = await notification.get('notifiable');
      return clientCoach.belongsTo('client').id();
    }

  }, {
    type: 'client_coach_request',
    route: 'client.coaches',

    async params(notification) {
      let clientCoach = await notification.get('notifiable');
      return clientCoach.belongsTo('client').id();
    }

  }, {
    type: 'client_coachee_request',
    route: 'vendor.coachees',

    async params(notification) {
      let clientCoachee = await notification.get('notifiable');
      return clientCoachee.belongsTo('client').id();
    }

  }, {
    type: 'client_coachee_approved',
    route: 'vendor.coachees',

    async params(notification) {
      let clientCoachee = await notification.get('notifiable');
      return clientCoachee.belongsTo('client').id();
    }

  }, {
    type: 'client_coach_approved',
    route: 'client.coaches',

    async params(notification) {
      let clientCoachee = await notification.get('notifiable');
      return clientCoachee.belongsTo('client').id();
    }

  }, {
    type: 'client_coachee_shared',
    route: 'client.coachees',

    async params(notification) {
      let clientCoachee = await notification.get('notifiable');
      return clientCoachee.belongsTo('client').id();
    }

  }, {
    type: 'unread_messages',
    route: 'messaging.channel',

    async params(notification) {
      let chatMessage = await notification.get('notifiable');
      return chatMessage.get('channelId');
    }

  }, {
    type: 'coach_pool_coach_interview_requested',
    route: 'engagement.matching-process',

    async params(notification) {
      return notification.get('targetable').get('id');
    }

  }, {
    type: 'coach_pool_coach_interview_approved',
    route: 'engagement.matching-process',

    async params(notification) {
      return notification.get('targetable').get('id');
    }

  }, {
    type: 'coach_pool_final_coach_selected',
    route: 'engagement.matching-process-coaches',

    async params(notification) {
      return notification.get('targetable').get('id');
    }

  }, {
    type: 'coach_interview_approved_coach',

    async route(_, notification) {
      let coachingRequest = await notification.get('notifiable');
      return coachingRequest.channelId ? 'messaging.channel' : 'messaging';
    },

    async params(notification) {
      let coachingRequest = await notification.get('notifiable');

      if (coachingRequest.channelId) {
        return coachingRequest.channelId;
      }
    }

  }, {
    type: 'coach_pool_interview_approved_no_cronofy',
    route: 'engagement.matching-process',

    async params(notification, _, __, services) {
      let coachingRequest = await notification.get('notifiable');
      let engagement = await notification.get('targetable');

      if (coachingRequest.channelId && services.twilio.client) {
        let channel = await services.twilio.client.getChannelBySid(coachingRequest.channelId);
        services.twilio.dockChannels.addObject(channel);
      }

      return engagement.id;
    }

  }, {
    type: 'coach_pool_matching_process_assigned',
    route: 'engagement.matching-process',

    async params(notification) {
      return notification.get('targetable').get('id');
    }

  }, {
    type: 'engagement_task_sent',
    route: 'engagement.program.program-item',

    async params(notification) {
      let assignment = await notification.get('notifiable');
      let task = await assignment.get('assignable');
      return [notification.belongsTo('targetable').id(), task.belongsTo('programItem').id()];
    }

  }, {
    type: 'engagement_program_file_received',
    route: 'engagement.program.program-item',

    async params(notification) {
      let assignment = await notification.get('notifiable');
      let task = await assignment.get('assignable');
      return [notification.belongsTo('targetable').id(), task.belongsTo('programItem').id()];
    }

  }, {
    type: 'engagement_program_link_sent',
    route: 'engagement.program.program-item',

    async params(notification) {
      let link = await notification.get('notifiable');
      let task = await link.get('assignable');
      return [notification.belongsTo('targetable').id(), task.belongsTo('programItem').id()];
    }

  }, {
    type: 'engagement_program_file_received',
    route: 'engagement.program.program-item',

    async params(notification) {
      let file = await notification.get('notifiable');
      let task = await file.get('assignable');
      return [notification.belongsTo('targetable').id(), task.belongsTo('programItem').id()];
    }

  }, {
    type: 'coach_pool_interview_rescheduled',

    async route(_, notification) {
      let coachingRequest = await notification.get('notifiable');
      let receiverId = notification.belongsTo('receiver').id();
      let coachUserId = coachingRequest.belongsTo('coachUser').id();
      let coacheeUserId = coachingRequest.belongsTo('coacheeUser').id();

      if (receiverId === coachUserId) {
        return 'messaging.channel';
      } else if (coacheeUserId === receiverId) {
        return 'engagement.matching-process';
      } else {
        return 'engagement.matching-process-coaches';
      }
    },

    async params(notification, _, route, services) {
      if (route !== 'messaging.channel') {
        return notification.get('targetable').get('id');
      }

      if (!services.twilio.client) {
        // Notification came from email. needs initialization
        await services.account.loadInitialData.perform();
        await services.twilio.initTwilio.perform();
      }

      let coachee = notification.get('notifiable.coacheeUser');
      let channel = await services.twilio.createPersonalChannel.perform(coachee);
      return channel.sid;
    }

  }, {
    type: 'coach_pool_interview_scheduled',

    async route(_, notification) {
      let coachingRequest = await notification.get('notifiable');
      let receiverId = notification.belongsTo('receiver').id();
      let coachUserId = coachingRequest.belongsTo('coachUser').id();
      let coacheeUserId = coachingRequest.belongsTo('coacheeUser').id();

      if (receiverId === coachUserId) {
        return coachingRequest.isMarketplace ? 'dashboard' : 'messaging.channel';
      } else if (receiverId === coacheeUserId) {
        return coachingRequest.isMarketplace ? 'my-marketplace.interviews' : 'engagement.matching-process.interviews';
      } else {
        return 'engagement.matching-process';
      }
    },

    async params(notification, _, route, services) {
      let coachingRequest = await notification.get('notifiable');

      if (coachingRequest.isMarketplace) {
        return;
      }

      if (route !== 'messaging.channel') {
        return notification.get('targetable').get('id');
      }

      if (!services.twilio.client) {
        // Notification came from email. needs initialization
        await services.account.loadInitialData.perform();
        await services.twilio.initTwilio.perform();
      }

      let coachee = notification.get('notifiable.coacheeUser');
      let channel = await services.twilio.createPersonalChannel.perform(coachee);
      return channel.sid;
    }

  }, {
    type: 'coach_pool_coach_approval_request',
    needsTempToken: true,
    route: 'outside.coach-request',

    async params(notification) {
      return notification.get('notifiable').get('id');
    }

  }, {
    type: 'coach_pool_coach_declined',
    route: 'engagement.matching-process',

    async params(notification) {
      return notification.get('targetable').get('id');
    }

  }, {
    type: 'coach_pool_matching_process_coach_added',
    route: 'engagement.matching-process',

    async params(notification) {
      return notification.get('targetable').get('id');
    }

  }, {
    type: 'coach_pool_coach_declined_admin',
    route: 'engagement.matching-process',

    async params(notification) {
      return notification.get('targetable').get('id');
    }

  }, {
    type: 'coach_pool_interview_times_requested',
    route: 'dashboard',

    async queryParams(notification) {
      return {
        coachingRequestId: notification.get('notifiable').get('id')
      };
    }

  }];

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    twilio: Ember.inject.service(),
    account: Ember.inject.service(),
    notifications: Ember.inject.service(),

    async model({
      notification_token
    }) {
      try {
        let r = await this.store.query('notification', {
          filter: {
            token: notification_token
          },
          include: 'notifiable,notifiable.form-submissions,notifiable.client,notifiable.assignable,notifiable.client.client-account,notifiable.client.account,targetable,receiver,receiver.account,tenant'
        });
        return r.get('firstObject');
      } catch (e) {
        if (e.status === 450 && e.payload.redirect_url) {
          window.location.href = `${e.payload.redirect_url}?redirectUrl=${window.location.href}`;
          this.transitionTo('login');
        } else {
          throw e;
        }
      }
    },

    afterModel(notification) {
      return this.get('routeNotification').perform(notification);
    },

    routeNotification: (0, _emberConcurrency.task)(function* (notification) {
      try {
        if (this.session.isAuthenticated) {
          yield this.account.loadInitialData.perform();
          let activeEmployeeTenant = this.account.activeEmployee.belongsTo('tenant').id();
          let notificationTenant = notification.belongsTo('tenant').id();

          if (activeEmployeeTenant !== notificationTenant) {
            this.notifications.set('switchTenant', notification);
            return;
          }
        }

        let type = notification.get('typeKey');
        let routeInfo = NOTIFICATIONS_ROUTER.find(r => {
          let re = new RegExp(`^${r.type}$`);
          return r.type && re.test(type);
        }); // redirect all non matched notifications to dashboard, for now
        // assert(`The notification type '${type}' could not be handled.`, routeInfo);

        if (routeInfo) {
          yield this.getTemporaryToken(routeInfo, notification);
          let route = routeInfo.route; // support routes as functions

          if (typeof route === 'function') {
            route = yield route(this.get('session.isAuthenticated'), notification);
          }

          let params = routeInfo.params ? yield routeInfo.params(notification, this.get('session.isAuthenticated'), route, {
            account: this.account,
            twilio: this.twilio
          }) : [];
          params = params === undefined ? [] : params; // treat undefined as no params

          params = Ember.isArray(params) ? params : [params]; // treat single value as an array with a single value

          let queryParams = routeInfo.queryParams ? yield routeInfo.queryParams(notification, this.get('session.isAuthenticated'), route) : {};
          notification.set('read', true);
          notification.save();
          this.transitionTo(route, ...params, {
            queryParams
          });
        } else {
          this.transitionTo('dashboard');
        }
      } catch (e) {
        this.transitionTo('not-found');
      }
    }),

    async getTemporaryToken({
      needsTempToken
    }, notification) {
      if (needsTempToken && !this.get('session.isAuthenticated')) {
        let {
          token
        } = await this.get('ajax').request('/logged-out/inactive-user-token', {
          data: {
            token: notification.get('token')
          }
        });
        this.get('ajax').set('tempToken', token);
      }
    }

  });

  _exports.default = _default;
});