define("coachlogix/pods/marketplace/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J+BRFgyt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"main-scroll\"],[15,0,[31,[\"cl-overflow-auto cl-w-full cl-flex cl-flex-col cl-relative cl-text-deep-blue \",[30,[36,1],[[30,[36,0],[\"marketplace.index\"],null],\"cl-bg-pale-grey-three\",\"cl-bg-transparent\"],null],\" cl-duration-500 cl-transition-colors cl-ease-out-exp\"]]],[12],[2,\"\\n  \"],[8,\"marketplace/header\",[[24,0,\"cl-container cl-mx-auto cl-z-20\"]],[[\"@dark\"],[[30,[36,0],[\"marketplace.index\"],null]]],null],[2,\"\\n\\n  \"],[10,\"main\"],[14,0,\"cl-flex-none cl-flex cl-z-10 cl-flex-col\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"marketplace/footer\",[[24,0,\"cl-container cl-mx-auto\"]],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"is-active\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/marketplace/template.hbs"
    }
  });

  _exports.default = _default;
});