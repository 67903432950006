define("coachlogix/pods/invoice/index/controller", ["exports", "ember-can/computed", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _computed, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InvoiceIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.reads('model.invoice.value'), _dec5 = (0, _computed.ability)('invoice', 'invoice'), _dec6 = Ember.computed.not('invoiceAbility.canEdit'), _dec7 = Ember.computed.or('save.isRunning', 'invoice.send.isRunning', 'invoice.updateProperty.isRunning'), _dec8 = Ember.computed.or('isWorking', 'cantEdit'), _dec9 = Ember.computed('invoice.invoiceItems.@each.{invoiceable,invoiceableType}'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember.computed.bool('invoice.vatPercent'), _dec15 = Ember._action, (_class = (_temp = class InvoiceIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "invoice", _descriptor4, this);

      _initializerDefineProperty(this, "invoiceAbility", _descriptor5, this);

      _initializerDefineProperty(this, "cantEdit", _descriptor6, this);

      _initializerDefineProperty(this, "isWorking", _descriptor7, this);

      _initializerDefineProperty(this, "shouldDisable", _descriptor8, this);

      _initializerDefineProperty(this, "hasVat", _descriptor9, this);
    }

    get selectedSessions() {
      return this.invoice.get('invoiceItems').filterBy('invoiceableType', 'engagement-sessions').mapBy('invoiceable.content');
    }

    setTaxable(item, value) {
      if (value) {
        let invoiceTaxName = this.invoice.taxName1;
        let invoiceTaxRate = this.invoice.taxRate1;
        item.set('taxName1', invoiceTaxName);
        item.set('taxRate1', invoiceTaxRate);
      } else {
        item.set('taxName1', null);
        item.set('taxRate1', null);
      }

      item.saveTask.perform();
    }

    addSession(sessions) {
      if (sessions) {
        sessions.forEach(session => {
          let invoiceItem = this.store.createRecord('invoice-item', {
            invoiceable: session,
            invoiceableType: 'engagement-sessions',
            invoice: this.invoice,
            units: 1,
            billingMethod: 'unit_cost',
            notes: `${session.title} - ${session.duration} minutes`
          });
          invoiceItem.save();
        });
      }
    }

    addExpense() {
      let invoiceItem = this.store.createRecord('invoice-item', {
        invoiceableType: 'expenses',
        units: 1,
        unitPrice: 0,
        billingMethod: 'unit_cost',
        invoice: this.invoice
      });
      invoiceItem.save();
    }

    async setTax({
      name,
      rate
    }) {
      this.invoice.set('taxName1', name);
      this.invoice.set('taxRate1', rate);
      let invoiceItems = this.invoice.get('invoiceItems');
      this.updateItemsTax(this.invoice, invoiceItems);
      await this.save.perform();
    }

    *save() {
      let invoice = this.get('invoice');

      try {
        let invoiceItems = yield this.invoice.get('invoiceItems');
        this.updateItemsTax(this.invoice, invoiceItems);
        yield (0, _emberConcurrency.all)(invoiceItems.invoke('save'));
        yield this.invoice.save();
        this.clToaster.success(this.intl.t('invoice.success'));
        return invoice;
      } catch (e) {
        this.clToaster.error(this.intl.t('invoice.error'));
      }
    }

    *saveItem(item) {
      if (this.cantEdit) return;
      yield item.save();
    }

    *saveInvoice() {
      if (this.cantEdit) return;
      yield this.invoice.save();
    }

    updateItemsTax(invoice, invoiceItems) {
      let invoiceTaxName = invoice.get('taxName1');
      let invoiceTaxRate = invoice.get('taxRate1');
      let taxableItems = invoiceItems.filterBy('isTaxable');
      taxableItems.setEach('taxName1', invoiceTaxName);
      taxableItems.setEach('taxRate1', invoiceTaxRate);
    }

    *addNewSession() {
      try {
        let invoiceItem = this.store.createRecord('invoice-item', {
          invoiceableType: 'expenses',
          isAdhocSession: true,
          invoice: this.invoice,
          units: 1,
          unitPrice: null,
          description: 'Engagement Session',
          notes: 'Engagement Session',
          billingMethod: 'unit_cost'
        });
        yield invoiceItem.save();
        this.invoice.get('invoiceItems').addObject(invoiceItem);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *deleteItem(item) {
      this.invoice.get('invoiceItems').removeObjects(item);
      yield item.destroyRecord();
    }

    applyVat(value) {
      if (value) {
        let vatPercent = this.invoice.get('account.billingSettings.vatPercent');

        if (vatPercent) {
          this.invoice.set('vatPercent', vatPercent);
        } else {
          this.clToaster.info(this.intl.t('invoices.no-vat-message'));
          return; // No vat can be applied, no need to save
        }
      } else {
        this.invoice.set('vatPercent', 0);
      }

      this.invoice.saveTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "invoice", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "invoiceAbility", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cantEdit", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isWorking", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "shouldDisable", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectedSessions", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "selectedSessions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTaxable", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setTaxable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addSession", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "addSession"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addExpense", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "addExpense"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTax", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setTax"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveItem", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveInvoice", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveInvoice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addNewSession", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "addNewSession"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteItem", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteItem"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hasVat", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "applyVat", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "applyVat"), _class.prototype)), _class));
  _exports.default = InvoiceIndexController;
});