define("coachlogix/models/message-thread", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    status: (0, _model.attr)('string'),
    subject: (0, _model.attr)('string'),
    channelId: (0, _model.attr)('string'),
    isPrivate: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    sender: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    messages: (0, _model.hasMany)('message', {
      inverse: null
    }),
    messageRecipient: (0, _model.hasMany)('message-recipient', {
      inverse: null
    })
  });

  _exports.default = _default;
});