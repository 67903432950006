define("coachlogix/pods/settings/invoicing-payments/taxes/component", ["exports", "@ember-decorators/component", "ember-changeset", "ember-concurrency-decorators"], function (_exports, _component, _emberChangeset, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let InvoicingPaymentsTaxesComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.reads('account.activeAccount'), _dec8 = Ember.computed('account.activeAccount.billingSettings', 'changeset', 'editState'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec(_class = (_class2 = (_temp = class InvoicingPaymentsTaxesComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "activeAccount", _descriptor6, this);

      _defineProperty(this, "editState", false);

      _defineProperty(this, "changeset", null);

      _defineProperty(this, "selectedTaxToEdit", null);

      _defineProperty(this, "editStateChanges", {
        taxes: null
      });
    }

    init() {
      super.init(...arguments);
    }

    get billingSettings() {
      return this.editState ? this.changeset : this.account.activeAccount.billingSettings;
    }

    async setEditState() {
      this.set('editStateChanges.taxes', this.taxes);
      let billingSettings = await this.account.activeAccount.get('billingSettings');
      this.set('changeset', new _emberChangeset.Changeset(billingSettings));
      this.set('editState', true);
    }

    cancelChanges() {
      this.changeset.rollback();

      for (let tax of this.taxes.toArray()) {
        if (tax.isNew) {
          tax.destroyRecord();
        } else {
          tax.rollbackAttributes();
        }
      }

      this.set('editState', false);
    }

    editTax(tax) {
      this.set('selectedTaxToEdit', tax);
    }

    onEditTaxClose() {
      this.set('editStateChanges.taxes', this.taxes);
      this.set('selectedTaxToEdit', null);
    }

    onEditTaxSaved(editedTax) {
      if (editedTax) {
        this.selectedTaxToEdit.name = editedTax.name;
        this.selectedTaxToEdit.rate = editedTax.ratePercentage / 100;
      }

      this.set('selectedTaxToEdit', null);
    }

    onNewTaxCreated(tax) {
      if (tax) {
        let newTax = this.store.createRecord('tax-rate', {
          name: tax.name,
          rate: tax.ratePercentage / 100
        });
        this.editStateChanges.taxes.pushObject(newTax);
      }

      this.set('showCreateTaxDialog', false);
    }

    onTaxDelete(tax) {
      tax.deleteRecord();
      this.set('selectedTaxToEdit', null);
    }

    *saveChanges() {
      try {
        let promises = this.taxes.map(tax => tax.save());
        yield Promise.all(promises);
        yield this.changeset.save();
        this.set('editState', false);
        const successMessage = 'Taxes updated';
        this.clToaster.success(successMessage);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *loadTaxes() {
      let taxes = yield this.store.findAll('tax-rate');
      this.set('taxes', taxes.toArray());
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "activeAccount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "billingSettings", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "billingSettings"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setEditState", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "setEditState"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancelChanges", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editTax", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "editTax"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onEditTaxClose", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "onEditTaxClose"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onEditTaxSaved", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "onEditTaxSaved"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onNewTaxCreated", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "onNewTaxCreated"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onTaxDelete", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "onTaxDelete"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveChanges", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "saveChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadTaxes", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "loadTaxes"), _class2.prototype)), _class2)) || _class);
  var _default = InvoicingPaymentsTaxesComponent;
  _exports.default = _default;
});