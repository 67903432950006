define("coachlogix/abilities/resourceable", ["exports", "ember-can", "coachlogix/abilities/engagement-permission-helpers"], function (_exports, _emberCan, _engagementPermissionHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_engagementPermissionHelpers.default, {
    account: Ember.inject.service(),
    engagement: Ember.computed.reads('model'),

    engagementStatusIsAny(...values) {
      return values.any(v => {
        return this.isOrContains(this.get('model.status'), v);
      });
    },

    canManageResources: Ember.computed('model.{status,modelName}', 'roles.[]', function () {
      if (this.get('model.modelName') === 'engagement-template') {
        return this.roleIsAny('account_global_admin', 'location_global_admin', 'admin', 'manager', 'coach', 'coachee');
      } else {
        return this.engagementStatusIsAny('draft', 'active', 'coach_selection', 'draft_coach_selection') && this.roleIsAny('account_global_admin', 'location_global_admin', 'admin', 'manager', 'coach', 'coachee');
      }
    }),
    canDeleteResource: Ember.computed('model.modelName', 'resource.owner.id', 'role', 'account.activeUser.id', function () {
      let userId = this.account.activeUser.id;
      let resourceOwnerId = this.resource.belongsTo('owner').id();
      let createdByActiveUser = resourceOwnerId === userId;

      if (this.get('model.modelName') === 'engagement-template' || this.get('model.modelName') === 'engagement-session') {
        return this.roleIsAny('account_global_admin', 'location_global_admin', 'admin', 'manager') || createdByActiveUser;
      } else {
        return this.roleIsAny('admin', 'manager') || createdByActiveUser;
      }
    })
  });

  _exports.default = _default;
});