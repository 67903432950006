define("coachlogix/pods/engagement/matching-process-coaches/coach-request-table-cell/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CoachRequestTableCell = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.reads('engagement.matchingSettings'), _dec(_class = (_class2 = (_temp = class CoachRequestTableCell extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "clToaster", _descriptor4, this);

      _initializerDefineProperty(this, "matchingSettings", _descriptor5, this);
    }

    init() {
      super.init(...arguments);
      let coachingRequests = this.coach.get('coachingRequests') || [];
      let matchedRequest = coachingRequests.find(c => {
        return this.engagement && c.get('coacheeUser.id') === this.engagement.get('coachee.user.id') && c.belongsTo('engagement').id() === this.engagement.get('id');
      });

      if (!matchedRequest) {
        matchedRequest = this.createCoachingRequest();
        matchedRequest.set('interviewTitle', this.matchingSettings.get('coachPoolsInterviewTitle'));
        matchedRequest.set('duration', this.matchingSettings.get('coachPoolsInterviewLength'));
        matchedRequest.set('description', this.matchingSettings.get('coachPoolsInterviewDescription'));
        let defaultMeetingLocation = matchedRequest.get('user.availabilityPreferences.defaultMeetingLocation');
        matchedRequest.set('meetingLocation', defaultMeetingLocation);
      }

      this.coachingRequest = matchedRequest;
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);

      if (this.coachingRequest && this.coachingRequest.isNew) {
        this.coachingRequest.destroyRecord();
      }
    }

    createCoachingRequest() {
      return this.store.createRecord('coaching-request', {
        coach: this.coach,
        coachee: this.engagement.get('coachee'),
        engagement: this.engagement,
        status: 'no_request'
      });
    }

    *declineCoach() {
      yield this.setRequestStatus.perform(this.coachingRequest, 'coach_declined');
    }

    *cancelCoachInterview() {
      yield this.setRequestStatus.perform(this.coachingRequest, 'interview_canceled');
    }

    *setRequestStatus(coachingRequest, status) {
      try {
        if (!coachingRequest) {
          throw new Error('Request not found.');
        }

        coachingRequest.set('status', status);

        if (status === 'coach_declined') {
          coachingRequest.set('declinedBy', this.account.activeEmployee);
        }

        yield coachingRequest.save();
        this.clToaster.success('Request status updated.');
      } catch (e) {
        this.clToaster.error('Error updating the request.');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "clToaster", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "matchingSettings", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "declineCoach", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "declineCoach"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancelCoachInterview", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelCoachInterview"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setRequestStatus", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "setRequestStatus"), _class2.prototype)), _class2)) || _class);
  var _default = CoachRequestTableCell;
  _exports.default = _default;
});