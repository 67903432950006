define("coachlogix/utils/resource-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resourceCategory = resourceCategory;
  _exports.resourceIcon = resourceIcon;
  _exports.resourceCategoryName = resourceCategoryName;
  _exports.RESOURCE_CATEGORIES = _exports.OTHER_RESOURCE_CATEGORIES = _exports.FILE_CATEGORIES = void 0;
  const FILE_CATEGORIES = [{
    name: 'image',
    icon: 'image',
    mimeTypes: ['image/gif', 'image/x-icon', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/webp', 'image/vnd.adobe.photoshop', 'application/x-photoshop', 'application/photoshop', 'application/psd', 'image/psd']
  }, {
    name: 'video',
    icon: 'movie',
    mimeTypes: ['video/mpeg', 'video/ogg', 'video/webm', 'video/3gpp', 'video/3gpp2', 'video/x-msvideo', 'video/x-m4v', 'video/mp4', 'video/quicktime']
  }, {
    name: 'audio',
    icon: 'audiotrack',
    mimeTypes: ['audio/aac', 'audio/midi', 'audio/ogg', 'audio/x-wav', 'audio/webm', 'audio/3gpp', 'audio/3gpp2', 'audio/mpeg', 'audio/mp3', 'audio/x-m4a']
  }, {
    name: 'spreadsheet',
    icon: 'view-list',
    mimeTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.spreadsheetml.template', 'application/vnd.ms-excel.sheet.macroEnabled.12', 'application/vnd.ms-excel.template.macroEnabled.12', 'application/vnd.ms-excel.addin.macroEnabled.12', 'application/vnd.ms-excel.sheet.binary.macroEnabled.12', 'application/vnd.oasis.opendocument.spreadsheet', 'application/x-iwork-numbers-sffnumbers', 'text/csv']
  }, {
    name: 'presentation',
    icon: 'call-to-action',
    mimeTypes: ['application/x-iwork-keynote-sffkey', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.presentationml.template', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/vnd.ms-powerpoint.addin.macroEnabled.12', 'application/vnd.ms-powerpoint.presentation.macroEnabled.12', 'application/vnd.ms-powerpoint.template.macroEnabled.12', 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12', 'application/vnd.oasis.opendocument.presentation']
  }, {
    name: 'text',
    icon: 'description',
    mimeTypes: ['text/plain', 'application/pdf', 'application/rtf', 'text/rtf', 'text/richtext', 'application/x-iwork-pages-sffpages', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.wordprocessingml.template', 'application/vnd.ms-word.document.macroEnabled.12', 'application/vnd.ms-word.template.macroEnabled.12']
  }, {
    name: 'archive',
    icon: 'widgets',
    mimeTypes: ['application/x-bzip', 'application/x-bzip2', 'application/x-rar-compressed', 'application/x-tar', 'application/zip', 'application/x-7z-compressed']
  }];
  _exports.FILE_CATEGORIES = FILE_CATEGORIES;
  const OTHER_RESOURCE_CATEGORIES = [{
    name: 'form',
    icon: 'assignment-turned-in',
    types: ['form']
  }, {
    name: 'group',
    icon: 'folder',
    types: ['group']
  }, {
    name: 'competency-library',
    icon: 'library-books',
    types: ['competency-library']
  }, {
    name: 'link',
    icon: 'link',
    types: ['link']
  }, {
    name: 'document',
    icon: 'description',
    types: ['document']
  }, {
    name: 'note',
    icon: 'note',
    types: ['note']
  }];
  _exports.OTHER_RESOURCE_CATEGORIES = OTHER_RESOURCE_CATEGORIES;
  const OTHER_CATEGORY = {
    name: 'other',
    icon: 'settings-applications'
  };
  const RESOURCE_CATEGORIES = FILE_CATEGORIES.concat(OTHER_RESOURCE_CATEGORIES);
  _exports.RESOURCE_CATEGORIES = RESOURCE_CATEGORIES;

  function resourceCategory(r) {
    let category;

    if (r && r.get('isFile')) {
      let mimeType = r.get('resourceInfo.mimetype');
      category = FILE_CATEGORIES.find(c => {
        return c.mimeTypes.includes(mimeType);
      });
    } else {
      let resourceType = r.get('modelName') === 'resource-group' ? 'group' : r.get('resourceType');
      resourceType = resourceType === 'document' && r.get('isNote') ? 'note' : resourceType;
      category = OTHER_RESOURCE_CATEGORIES.find(c => {
        return c.types.includes(resourceType);
      });
    }

    if (!category) {
      category = OTHER_CATEGORY;
    }

    return category;
  }

  function resourceIcon(r) {
    return resourceCategory(r).icon;
  }

  function resourceCategoryName(r) {
    return resourceCategory(r).name;
  }
});