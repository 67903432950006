define("coachlogix/pods/marketplace/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8OuLMesb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-container cl-mx-auto cl-h-full cl-px-2 cl-flex cl-justify-center cl-items-center cl-min-h-40\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"logo\"],[[\"height\",\"class\"],[\"80\",\"cl-animate-pulse\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/marketplace/loading/template.hbs"
    }
  });

  _exports.default = _default;
});