define("coachlogix/pods/personal-settings/calendar/controller", ["exports", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PersonalSettingsCalendarController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed.reads('model.availabilityPreferences.isRunning'), _dec8 = Ember.computed.reads('model.cronofyConnectedAccount.value'), _dec9 = Ember.computed.reads('model.availabilityPreferences.value'), _dec10 = Ember.computed.reads('model.meetingLocations.value'), _dec11 = Ember.computed('model.calendars.value', 'cronofyConnectedAccount.writeableCalendars.@each.calendarId'), _dec12 = Ember.computed('selectedCalendars.@each.provider_name', 'meetingLocations.@each.isGoogleMeet'), (_class = (_temp = class PersonalSettingsCalendarController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "calendarWasEdited", _descriptor4, this);

      _initializerDefineProperty(this, "showUnsavedDataConfirmation", _descriptor5, this);

      _initializerDefineProperty(this, "shouldHighlightRules", _descriptor6, this);

      _initializerDefineProperty(this, "loadingAvailabilityPreferences", _descriptor7, this);

      _initializerDefineProperty(this, "cronofyConnectedAccount", _descriptor8, this);

      _initializerDefineProperty(this, "availabilityPreferences", _descriptor9, this);

      _initializerDefineProperty(this, "meetingLocations", _descriptor10, this);
    }

    get selectedCalendars() {
      let writeableCalendars = this.cronofyConnectedAccount?.get('writeableCalendars') || [];
      let calendars = this.model.calendars.value;
      return writeableCalendars.map(wc => {
        return calendars?.find(c => c.calendar_id === wc.calendarId);
      });
    }

    get locations() {
      let hasGoogleCalendar = this.selectedCalendars ? this.selectedCalendars.find(calendar => calendar?.provider_name === 'google') : null;

      if (hasGoogleCalendar) {
        return this.meetingLocations;
      } else {
        return this.meetingLocations.toArray().filter(l => !l.isGoogleMeet);
      }
    }
    /**
     * TASKS
     */


    *saveAvailability() {
      try {
        let availabilityPreferences = yield this.get('availabilityPreferences');
        yield availabilityPreferences.save();
        let toastMessage = this.intl.t('personal-settings.calendar.update-toast-message');
        this.clToaster.success(toastMessage);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *deleteCalendars(notification, userinfo) {
      let profileId = notification.profile.profile_id;
      let calendars = yield this.cronofyConnectedAccount.get('writeableCalendars');
      let calendarsToDelete = calendars.filter(c => c.profileId === profileId);
      yield (0, _emberConcurrency.all)(calendarsToDelete.map(c => c.destroyRecord()));

      if (userinfo['cronofy.data'].profiles.length === 0) {
        let cronofyConnectedAccount = yield this.cronofyConnectedAccount;
        yield cronofyConnectedAccount.destroyRecord();
        this.set('model.cronofyConnectedAccount', null);
      }
    }

    *saveWriteableCalendars(calendars) {
      let writeableCalendars = yield this.cronofyConnectedAccount.get('writeableCalendars');

      for (let wc of writeableCalendars.toArray()) {
        let exists = calendars.some(c => wc.calendarId === c.calendar_id); // if new selection does not include an old calendar, we must delete it

        if (!exists) {
          yield wc.destroyRecord();
        }
      }

      for (let c of calendars) {
        let exists = writeableCalendars.toArray().some(wc => wc.calendarId === c.calendar_id); // if old selection does not include a new calendar, we must add it

        if (!exists) {
          let cronofyCalendar = this.store.createRecord('cronofy-calendar', {
            calendarId: c.calendar_id,
            calendarName: c.calendar_name,
            profileId: c.profile_id,
            profileName: c.profile_name,
            providerName: c.provider_name,
            cronofyConnectedAccount: this.cronofyConnectedAccount
          });
          yield cronofyCalendar.save();
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarWasEdited", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showUnsavedDataConfirmation", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "shouldHighlightRules", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadingAvailabilityPreferences", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "cronofyConnectedAccount", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "availabilityPreferences", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "meetingLocations", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectedCalendars", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selectedCalendars"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "locations", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "locations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAvailability", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveAvailability"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteCalendars", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteCalendars"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveWriteableCalendars", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveWriteableCalendars"), _class.prototype)), _class));
  var _default = PersonalSettingsCalendarController;
  _exports.default = _default;
});