define("coachlogix/router", ["exports", "coachlogix/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    // unauthenticated routes
    this.route('outside', {
      path: ''
    }, function () {
      this.route('login');
      this.route('authentication-error');
      this.route('payment-thank-you');
      this.route('email-verification');
      this.route('sso-unauthorized');
      this.route('form', function () {
        this.route('expired', {
          path: 'expired/:assignment_id'
        });
        this.route('submit', {
          path: 'submit/:assignment_id'
        });
        this.route('submit-link', {
          path: 'submit-link/:hash'
        });
        this.route('preview', {
          path: 'preview/:resource_id'
        });
        this.route('responses', {
          path: 'responses/:task_id'
        });
      });
      this.route('feedback', {
        path: 'feedback/:assignment_id'
      });
      this.route('invoice-payment', {
        path: 'invoice-payment/:notification_token'
      });
      this.route('invite', {
        path: 'invite/:invitation_id'
      });
      this.route('coach-request', {
        path: 'coach-request/:coaching_request_id'
      });
      this.route('marketplace', {
        path: '',
        resetNamespace: true
      }, function () {
        this.route('index', {
          path: 'marketplace'
        });
        this.route('profile', {
          path: 'p/:path'
        });
      });
    });
    this.route('account-setup'); // group all authenticated routes here

    this.route('index', {
      path: '/'
    }, function () {
      this.route('engagements', {
        resetNamespace: true
      });
      this.route('engagement', {
        path: 'engagements/:engagement_id',
        resetNamespace: true
      }, function () {
        this.route('program', function () {
          this.route('program-item', {
            path: '/:program_item_id'
          });
        });
        this.route('goals', function () {
          this.route('goal', {
            path: '/:goal_id'
          });
        });
        this.route('resources');
        this.route('settings');
        this.route('matching-process-coaches');
        this.route('matching-process', function () {
          this.route('shared-coaches');
          this.route('interviews');
        });
        this.route('matching-process-selection', {
          path: 'selection/:coaching_request_id'
        });
      });
      this.route('client', {
        path: 'clients/:client_id',
        resetNamespace: true
      }, function () {
        this.route('engagements');
        this.route('coaches');
        this.route('coachees');
      });
      this.route('clients', {
        resetNamespace: true
      });
      this.route('coaches', {
        resetNamespace: true
      }, function () {
        this.route('pools');
      });
      this.route('resources', {
        resetNamespace: true
      });
      this.route('form', {
        resetNamespace: true,
        path: 'form/:form_id'
      }, function () {
        this.route('answers', function () {
          this.route('index', {
            path: 'summary'
          });
          this.route('submission', {
            path: 'submission/:form_submission_id'
          });
        });
      });
      this.route('competency-library', {
        resetNamespace: true,
        path: 'theme-libraries/:competency_library_id'
      });
      this.route('dashboard', {
        resetNamespace: true
      });
      this.route('employee', {
        path: 'employee/:employee_id',
        resetNamespace: true
      }, function () {
        this.route('engagements');
        this.route('profile');
      });
      this.route('employees', {
        resetNamespace: true
      });
      this.route('engagement-coaching-request', {
        path: 'engagement-request/:coaching_request_id',
        resetNamespace: true
      });
      this.route('invoices', {
        resetNamespace: true
      }, function () {
        this.route('sent');
        this.route('received');
      });
      this.route('invoice', {
        path: 'invoices/:invoice_id',
        resetNamespace: true
      }, function () {
        this.route('payments');
      });
      this.route('my-marketplace', {
        resetNamespace: true
      }, function () {
        this.route('orders', function () {
          this.route('order', {
            path: '/:order_id'
          });
        });
        this.route('interviews');
      });
      this.route('personal-settings', {
        resetNamespace: true
      }, function () {
        this.route('general');
        this.route('calendar');
        this.route('notifications');
      });
      this.route('program-template', {
        path: 'program-template/:program_template_id',
        resetNamespace: true
      }, function () {
        this.route('program', function () {
          this.route('program-item', {
            path: '/:program_item_id'
          });
        });
        this.route('resources');
        this.route('settings');
      });
      this.route('matching-setting', {
        path: 'matching-setting/:matching_setting_id',
        resetNamespace: true
      });
      this.route('settings', {
        resetNamespace: true
      }, function () {
        this.route('basic-info');
        this.route('branding');
        this.route('coach-pools');
        this.route('company-members');
        this.route('invoicing-payments');
        this.route('org-structure');
        this.route('program-templates');
        this.route('subscription');
        this.route('marketplace');
      });
      this.route('vendor', {
        path: 'vendors/:vendor_id',
        resetNamespace: true
      }, function () {
        this.route('engagements');
        this.route('coaches');
        this.route('coachees');
        this.route('coach-profile');
      });
      this.route('vendors', {
        resetNamespace: true
      });
      this.route('messaging', {
        resetNamespace: true
      }, function () {
        this.route('channel', {
          path: '/:channel_id'
        });
      });
      this.route('reports', {
        resetNamespace: true
      }, function () {
        this.route('report', {
          path: '/:report_id'
        });
      });
    });
    this.route('notifications', {
      path: 'notifications/:notification_token',
      resetNamespace: true
    });
    this.route('video', {
      path: 'video/:session_id',
      resetNamespace: true
    });
    this.route('stripe-callback');
    this.route('cronofy-callback');
    this.route('logout');
    this.route('not-found', {
      path: '404'
    });
    this.route('default', {
      path: '/*path'
    });
  });
});