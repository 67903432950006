define("coachlogix/pods/components/v2/tasks-list/item/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TasksListItem = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed.equal('task.title', '&nbsp;'), _dec3 = Ember.computed.reads('task.assignments.firstObject'), _dec4 = Ember.computed('isFauxEmpty', 'task.title'), _dec5 = Ember._action, _dec(_class = (_class2 = (_temp = class TasksListItem extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isFauxEmpty", _descriptor, this);

      _initializerDefineProperty(this, "assignment", _descriptor2, this);
    }

    get title() {
      if (this.isFauxEmpty) {
        return '';
      }

      return this.task.title || '';
    }

    onChange(title) {
      if (title === '') {
        this.task.set('title', '&nbsp;');
      } else if (title.length <= 100) {
        // max chars allowed
        this.task.set('title', title);
      }

      this.task.debouncedSaveTask.perform();
    }
    /*
    click() {
      if (!this.get('disabled')) {
        next(() => {
          this.$('input').focus();
        });
      }
    }
    */


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isFauxEmpty", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "assignment", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "title", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "title"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onChange", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "onChange"), _class2.prototype)), _class2)) || _class);
  var _default = TasksListItem;
  _exports.default = _default;
});