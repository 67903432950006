define("coachlogix/utils/devices-utils", ["exports", "twilio-video"], function (_exports, _twilioVideo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDeviceSelectionOptions = getDeviceSelectionOptions;
  _exports.getDefaultDevices = getDefaultDevices;
  _exports.createScreenTrack = createScreenTrack;
  _exports.hasStorage = void 0;

  /**
   * Get the list of available media devices of the given kind.
   * @param {Array<MediaDeviceInfo>} deviceInfos
   * @param {string} kind - One of 'audioinput', 'audiooutput', 'videoinput'
   * @returns {Array<MediaDeviceInfo>} - Only those media devices of the given kind
   */
  function getDevicesOfKind(deviceInfos, kind) {
    return deviceInfos.filter(deviceInfo => deviceInfo.kind === kind);
  }
  /**
   * Ensure that media permissions are obtained.
   * @returns {Promise<void>}
   */


  async function ensureMediaPermissions() {
    let devices = await navigator.mediaDevices.enumerateDevices();
    let hasMediaPermissions = devices.every(device => device.deviceId && device.label);

    if (!hasMediaPermissions) {
      let mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      });
      mediaStream.getTracks().forEach(track => track.stop());
    }
  }
  /**
   * Get the list of available media devices.
   * @returns {Promise<DeviceSelectionOptions>}
   * @typedef {object} DeviceSelectionOptions
   * @property {Array<MediaDeviceInfo>} audioinput
   * @property {Array<MediaDeviceInfo>} audiooutput
   * @property {Array<MediaDeviceInfo>} videoinput
   */


  async function getDeviceSelectionOptions() {
    // before calling enumerateDevices, get media permissions (.getUserMedia)
    // w/o media permissions, browsers do not return device Ids and/or labels.
    await ensureMediaPermissions();
    let deviceInfos = await navigator.mediaDevices.enumerateDevices();
    let kinds = ['audioinput', 'audiooutput', 'videoinput'];
    return kinds.reduce((deviceSelectionOptions, kind) => {
      deviceSelectionOptions[kind] = getDevicesOfKind(deviceInfos, kind);
      return deviceSelectionOptions;
    }, {});
  }

  function getDeviceObjects(devices = []) {
    return devices.map(d => {
      return {
        value: d.deviceId,
        label: d.label || `Device [ id: ${d.deviceId.substr(0, 5)}... ]`
      };
    });
  }

  const hasStorage = function () {
    try {
      let test = 'test';
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (exception) {
      return false;
    }
  }();

  _exports.hasStorage = hasStorage;

  async function getDefaultDevices() {
    let allDevices = await getDeviceSelectionOptions();
    let result = {};

    for (let kind of ['audioinput', 'audiooutput', 'videoinput']) {
      let devices = getDeviceObjects(allDevices[kind]); // calculate default devices
      // if it exists on localstorage, then use that device id, if it exists
      // else, try to find a sensible default device
      //   - chrome and opera seem to use a device id of `default` for the default devices of the system -> try to use that
      //   - otherwise just use the first device available (index 0)

      let selectedDevice;
      let selectedDeviceId = hasStorage && localStorage.getItem(`cl-${kind}-device-id`);

      if (selectedDeviceId) {
        selectedDevice = devices.find(d => d.value === selectedDeviceId); // if the saved device id isn't valid anymore, then we might as well delete it from local storage

        if (!selectedDeviceId) {
          hasStorage && localStorage.removeItem(`cl-${kind}-device-id`);
        }
      }

      if (!selectedDevice && devices && devices.length) {
        selectedDevice = devices.find(d => d.value === 'default');
      }

      if (!selectedDevice && devices && devices.length) {
        selectedDevice = devices[0];
      }

      result[kind] = {
        devices,
        selectedDevice
      };
    }

    return result;
  }
  /**
   * Create a LocalVideoTrack for your screen. You can then share it
   * with other Participants in the Room.
   * @param {number} height - Desired vertical resolution in pixels
   * @param {number} width - Desired horizontal resolution in pixels
   * @returns {Promise<LocalVideoTrack>}
   */


  async function createScreenTrack(height, width) {
    if (typeof navigator === 'undefined' || !navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
      return new Error('getDisplayMedia is not supported');
    }

    let stream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        height,
        width
      }
    });
    return new _twilioVideo.LocalVideoTrack(stream.getVideoTracks()[0], {
      name: 'screenshare'
    });
  }
});