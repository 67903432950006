define("coachlogix/pods/engagement/program/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "htkwF0m9",
    "block": "{\"symbols\":[],\"statements\":[[8,\"cl-empty-state\",[[24,0,\"cl-h-full cl-flex cl-justify-center cl-items-center\"]],[[\"@text\"],[[30,[36,0],[\"v2.engagement.empty-program\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],[\"file\"],[[\"class\"],[\"cl-mb-2\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/engagement/program/index/template.hbs"
    }
  });

  _exports.default = _default;
});