define("coachlogix/pods/components/ui/stripe-card-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'md-input-container',
    classNameBindings: ['focused:md-focused', 'focused:md-input-focused', 'hasValue:md-input-has-value', 'error:md-input-invalid'],
    stripe: Ember.inject.service('stripe-old'),

    didInsertElement() {
      this._super(...arguments);

      let elements = this.get('stripe').stripe.elements(); // Create an instance of the card Element

      let card = elements.create('card', {
        style: {
          base: {
            iconColor: 'rgba(0, 0, 0, 0.38)',
            lineHeight: '26px',
            fontSize: '16px',
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
            '::placeholder': {
              color: 'rgba(0, 0, 0, 0.38)'
            }
          }
        }
      });

      if (typeof this.cardCreated === 'function') {
        this.cardCreated(card);
      } // Add an instance of the card Element into the `card-element` <div>


      card.mount(this.element.querySelector(`#input-${this.elementId}`));
      card.addEventListener('change', Ember.run.bind(this, this.onChange));
      card.addEventListener('focus', Ember.run.bind(this, this.onFocus));
      card.addEventListener('blur', Ember.run.bind(this, this.onBlur));
      this.cardElement = card;
    },

    willDestroyElement() {
      this._super(...arguments);

      this.cardElement.unmount();
    },

    onChange({
      complete,
      empty,
      error
    }) {
      if (typeof this.onComplete === 'function') {
        this.onComplete(complete);
      }

      this.set('hasValue', !empty);

      if (error) {
        this.set('error', error.message);
      } else {
        this.set('error', null);
      }
    },

    onFocus() {
      this.set('focused', true);
    },

    onBlur() {
      this.set('focused', false);
    }

  });

  _exports.default = _default;
});