define("coachlogix/pods/components/v2/summary-cards/employee-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fvUyo8UY",
    "block": "{\"symbols\":[\"list\",\"prop\",\"&attrs\"],\"statements\":[[6,[37,2],[[32,0,[\"values\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"cl-card\",[[24,0,\"cl-flex cl-flex-col cl-py-3\"],[17,3]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[14,0,\"cl-title-4 cl-px-3 cl-mb-2\"],[12],[2,\"Employee Information Card\"],[13],[2,\"\\n\\n    \"],[8,\"cl-list\",[],[[\"@bordered\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"values\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,1,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"cl-icon\",[],[[\"@icon\"],[[32,2,[\"icon\"]]]],null],[2,\"\\n          \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n            \"],[10,\"h7\"],[14,0,\"cl-title-7\"],[12],[1,[32,2,[\"label\"]]],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cl-body-1\"],[12],[1,[32,2,[\"value\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/summary-cards/employee-info/template.hbs"
    }
  });

  _exports.default = _default;
});