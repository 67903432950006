define("coachlogix/pods/components/profile/general-info/employee-levels-edit-dialog/component", ["exports", "@glimmer/component", "ember-changeset", "ember-concurrency-decorators"], function (_exports, _component, _emberChangeset, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MAX_COACHING_TYPES = 5;
  let EmployeeLevelsEditDialog = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember.computed.filterBy('changeset.coachingCategorizations', 'categorizationType', 'employee levels'), (_class = (_temp = class EmployeeLevelsEditDialog extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clToaster", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "coachCategorizations", _descriptor3, this);

      _initializerDefineProperty(this, "changeset", _descriptor4, this);

      _initializerDefineProperty(this, "employeeLevels", _descriptor5, this);
    }

    *getDefaultLevels() {
      let levels = yield this.coachCategorizations.fetchCategorizations.perform('employee levels');
      return levels.sort((a, b) => a.sortOrder - b.sortOrder);
    }

    *updateCoachingCategorization(name, records) {
      try {
        if (name === 'coachingTypes') {
          if (records.length > MAX_COACHING_TYPES) {
            this.clToaster.info(`Please select up to ${MAX_COACHING_TYPES} coaching types`);
            return;
          }
        }

        let filteredCoachingCategorizations = yield this[name];
        let coachingCategorizationsRel = yield this.changeset.get('coachingCategorizations');
        let coachingCategorizations = Ember.A(coachingCategorizationsRel.toArray());
        let toAdd = records.reject(item => {
          return filteredCoachingCategorizations.includes(item);
        }, filteredCoachingCategorizations);
        let toRemove = filteredCoachingCategorizations.reject(item => {
          return records.includes(item);
        }, records);
        coachingCategorizations.pushObjects(toAdd);
        coachingCategorizations.removeObjects(toRemove);
        this.changeset.set('coachingCategorizations', coachingCategorizations);
        yield this.changeset.save();
        this.clToaster.success(this.intl.t('ui.coach-profile.profile-update'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "coachCategorizations", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _emberChangeset.Changeset(this.args.profile);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getDefaultLevels", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getDefaultLevels"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "employeeLevels", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateCoachingCategorization", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateCoachingCategorization"), _class.prototype)), _class));
  _exports.default = EmployeeLevelsEditDialog;
});