define("coachlogix/pods/components/v2/contracts/select-contract-template-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lSEpeGmQ",
    "block": "{\"symbols\":[\"contractTemplate\",\"@onClose\",\"@onSelect\",\"@onContractCreate\",\"&attrs\"],\"statements\":[[8,\"cl-selection-dialog\",[[24,0,\"cl-w-32\"],[17,5]],[[\"@title\",\"@onClose\",\"@closeOnSelect\",\"@options\",\"@required\",\"@search\",\"@searchField\",\"@onChange\",\"@noOptionsText\",\"@auxButtonClick\",\"@auxButtonLabel\"],[[30,[36,0],[\"contracts.create-dialog.select-template\"],null],[32,2],false,[32,0,[\"contractTemplates\"]],true,true,[30,[36,1],[\"name\"],null],[32,3],[30,[36,0],[\"contracts.create-dialog.no-templates\"],null],[32,4],[30,[36,0],[\"contracts.create-dialog.create-new\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"array\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/contracts/select-contract-template-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});