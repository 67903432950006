define("coachlogix/pods/components/v2/engagement/create-dialog/select-coach-pool-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J5zm6Xs8",
    "block": "{\"symbols\":[\"coachPool\",\"@onClose\",\"@onCoachPoolSelect\",\"&attrs\"],\"statements\":[[8,\"cl-selection-dialog\",[[24,0,\"cl-w-32\"],[17,4]],[[\"@title\",\"@onClose\",\"@closeOnSelect\",\"@options\",\"@multiple\",\"@required\",\"@search\",\"@searchField\",\"@onChange\",\"@noOptionsText\"],[[30,[36,0],[\"engagements.create-dialog.select-coach-pools\"],null],[32,2],false,[32,0,[\"coachPools\"]],true,true,true,[30,[36,1],[\"name\",\"description\"],null],[32,3],[30,[36,0],[\"engagements.create-dialog.no-pools\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-6\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"array\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement/create-dialog/select-coach-pool-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});