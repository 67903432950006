define("coachlogix/pods/invoice/payments/create-payment-dialog/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CreatePaymentDialogComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = (_temp = class CreatePaymentDialogComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "selectedAmountOption", _descriptor4, this);

      _initializerDefineProperty(this, "amount", _descriptor5, this);

      _initializerDefineProperty(this, "paymentType", _descriptor6, this);

      _initializerDefineProperty(this, "paymentDate", _descriptor7, this);

      _defineProperty(this, "paymentTypes", this.store.findAll('payment-type', {
        reload: true
      }));

      this.amountOptions = [{
        id: 'full',
        label: `Remaining amount (${this.intl.formatNumber(this.args.invoice.balance, {
          currency: this.args.invoice.get('currency.code'),
          style: 'currency'
        })})`
      }, {
        id: 'other',
        label: 'Other'
      }];
      this.selectedAmountOption = this.amountOptions[0];
    }

    get amountErrors() {
      if (this.amount <= 0 || this.amount > this.args.invoice.balance) {
        return [`You can only pay up to the current invoice balance (${this.intl.formatNumber(this.args.invoice.balance, {
          currency: this.args.invoice.get('currency.code'),
          style: 'currency'
        })}).`];
      }

      return [];
    }

    get isValid() {
      if (this.selectedAmountOption.id === 'full') {
        return this.paymentType;
      } else if (this.selectedAmountOption.id === 'other') {
        return this.amount > 0 && this.amount <= this.args.invoice.balance && this.paymentType;
      }

      return false;
    }

    *createPayment() {
      let amount;

      if (this.selectedAmountOption.id === 'full') {
        amount = this.args.invoice.balance;
      } else if (this.selectedAmountOption.id === 'other') {
        amount = this.amount;
      }

      let payment = this.store.createRecord('payment', {
        paymentType: this.paymentType,
        paymentDate: this.paymentDate,
        amount,
        invoice: this.args.invoice
      });

      try {
        yield payment.save();
        yield this.args.invoice.reload();
        this.args.onClose?.();
        this.clToaster.success(this.intl.t('invoice.payment_created_successfully'));
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedAmountOption", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "amount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "paymentType", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "paymentDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "createPayment", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createPayment"), _class.prototype)), _class));
  _exports.default = CreatePaymentDialogComponent;
});