define("coachlogix/models/reviewable", ["exports", "@ember-data/model", "ember-concurrency"], function (_exports, _model, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    accountService: Ember.inject.service('account'),
    clToaster: Ember.inject.service(),
    reviews: (0, _model.hasMany)('review', {
      async: true,
      inverse: 'reviewable'
    }),
    createReview: (0, _emberConcurrency.task)(function* (attrs) {
      let review = this.store.createRecord('review', attrs);
      review.setProperties({
        reviewable: this,
        user: this.get('accountService.activeUser')
      });

      try {
        yield review.save();
        this.get('reviews').pushObject(review);
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});