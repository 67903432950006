define("coachlogix/models/profile", ["exports", "@ember-data/model", "ember-concurrency-decorators", "remove-markdown", "tracked-toolbox", "urijs", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrencyDecorators, _removeMarkdown, _trackedToolbox, _urijs, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const returnRelativeLink = function (link) {
    if (link && (0, _urijs.default)(link).protocol() === '' && !link.startsWith('//')) {
      return `//${link}`;
    }

    return link;
  };

  let Profile = (_dec = Ember.inject.service, _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('number'), _dec21 = (0, _model.attr)('number'), _dec22 = (0, _model.attr)('boolean', {
    allowNull: true
  }), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('number'), _dec25 = (0, _model.attr)('number'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('object'), _dec28 = (0, _model.belongsTo)('user', {
    inverse: 'profile',
    async: false
  }), _dec29 = (0, _model.hasMany)('profile-language', {
    inverse: 'profile',
    async: false
  }), _dec30 = (0, _model.hasMany)('work-experience', {
    inverse: 'profile',
    async: false
  }), _dec31 = (0, _model.hasMany)('coaching-experiences', {
    inverse: 'profile',
    async: false
  }), _dec32 = (0, _model.hasMany)('user-affiliation', {
    inverse: 'profile',
    async: false
  }), _dec33 = (0, _model.hasMany)('education-degree', {
    inverse: 'profile',
    async: false
  }), _dec34 = (0, _model.hasMany)('assessment', {
    inverse: 'profile',
    async: false
  }), _dec35 = (0, _model.hasMany)('credential', {
    inverse: 'profile',
    async: false
  }), _dec36 = (0, _model.hasMany)('publication', {
    inverse: 'profile',
    async: false
  }), _dec37 = (0, _model.hasMany)('coaching-categorizations', {
    inverse: null,
    async: false
  }), _dec38 = Ember.computed.filterBy('coachingCategorizations', 'categorizationType', 'coaching types'), _dec39 = Ember.computed.filterBy('coachingCategorizations', 'categorizationType', 'specialties'), _dec40 = Ember.computed.filterBy('coachingCategorizations', 'categorizationType', 'employee levels'), _dec41 = Ember.computed.filterBy('coachingCategorizations', 'categorizationType', 'modalities'), _dec42 = Ember.computed.filterBy('coachingCategorizations', 'categorizationType', 'theoretical models'), (_class = (_temp = class Profile extends _baseModel.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intercom", _descriptor, this);

      _initializerDefineProperty(this, "status", _descriptor2, this);

      _initializerDefineProperty(this, "url", _descriptor3, this);

      _initializerDefineProperty(this, "hashUrl", _descriptor4, this);

      _initializerDefineProperty(this, "summary", _descriptor5, this);

      _initializerDefineProperty(this, "coverPhoto", _descriptor6, this);

      _initializerDefineProperty(this, "address", _descriptor7, this);

      _initializerDefineProperty(this, "address2", _descriptor8, this);

      _initializerDefineProperty(this, "city", _descriptor9, this);

      _initializerDefineProperty(this, "country", _descriptor10, this);

      _initializerDefineProperty(this, "postalCode", _descriptor11, this);

      _initializerDefineProperty(this, "state", _descriptor12, this);

      _initializerDefineProperty(this, "gender", _descriptor13, this);

      _initializerDefineProperty(this, "facebook", _descriptor14, this);

      _initializerDefineProperty(this, "twitter", _descriptor15, this);

      _initializerDefineProperty(this, "linkedin", _descriptor16, this);

      _initializerDefineProperty(this, "website", _descriptor17, this);

      _initializerDefineProperty(this, "introVideo", _descriptor18, this);

      _initializerDefineProperty(this, "yearStarted", _descriptor19, this);

      _initializerDefineProperty(this, "numberOfClients", _descriptor20, this);

      _initializerDefineProperty(this, "coachedHours", _descriptor21, this);

      _initializerDefineProperty(this, "allowIntroductoryInterviews", _descriptor22, this);

      _initializerDefineProperty(this, "highestEducation", _descriptor23, this);

      _initializerDefineProperty(this, "minimumPackagePrice", _descriptor24, this);

      _initializerDefineProperty(this, "maximumPackagePrice", _descriptor25, this);

      _initializerDefineProperty(this, "currencyCode", _descriptor26, this);

      _initializerDefineProperty(this, "locationInfo", _descriptor27, this);

      _initializerDefineProperty(this, "owner", _descriptor28, this);

      _initializerDefineProperty(this, "languages", _descriptor29, this);

      _initializerDefineProperty(this, "workExperiences", _descriptor30, this);

      _initializerDefineProperty(this, "coachingExperiences", _descriptor31, this);

      _initializerDefineProperty(this, "userAffiliations", _descriptor32, this);

      _initializerDefineProperty(this, "educationDegrees", _descriptor33, this);

      _initializerDefineProperty(this, "assessments", _descriptor34, this);

      _initializerDefineProperty(this, "credentials", _descriptor35, this);

      _initializerDefineProperty(this, "publications", _descriptor36, this);

      _initializerDefineProperty(this, "coachingCategorizations", _descriptor37, this);

      _initializerDefineProperty(this, "coachingTypes", _descriptor38, this);

      _initializerDefineProperty(this, "specialties", _descriptor39, this);

      _initializerDefineProperty(this, "employeeLevels", _descriptor40, this);

      _initializerDefineProperty(this, "modalities", _descriptor41, this);

      _initializerDefineProperty(this, "theoreticalModels", _descriptor42, this);
    }

    get facebookLink() {
      return returnRelativeLink(this.facebook);
    }

    get twitterLink() {
      return returnRelativeLink(this.twitter);
    }

    get linkedinLink() {
      return returnRelativeLink(this.linkedin);
    }

    get websiteLink() {
      return returnRelativeLink(this.website);
    }

    get yearsCoaching() {
      if (this.yearStarted) {
        return new Date().getFullYear() - this.yearStarted;
      } else {
        return 0;
      }
    }

    get displayAddress() {
      let properties = ['address', 'address2', 'city', 'postalCode', 'state', 'country'];
      return properties.map(p => this[p]).filter(Boolean).join(', ');
    }

    get fullLocation() {
      let properties = ['city', 'state', 'country'];
      return properties.map(p => this[p]).filter(Boolean).join(', ');
    }

    get isClaimed() {
      return Ember.isPresent(this.url);
    }

    get currentUrl() {
      return Ember.isPresent(this.url) ? this.url : this.hashUrl;
    }

    get isPublished() {
      return this.status === 'published';
    }

    get isUnpublished() {
      return !this.isPublished;
    }

    get plainTextSummary() {
      let summary = this.summary || '';
      return (0, _removeMarkdown.default)(summary.trim());
    } // this is an auxiliary property to help
    // the frontend decide wether to show a placeholder or not


    get isProfileEmpty() {
      let properties = ['introVideo', 'employeeLevels', 'specialties', 'languages', 'educationDegrees', 'credentials', 'assessments', 'workExperiences', 'userAffiliations', 'modalities'];
      return properties.map(p => this[p]).every(v => Ember.isEmpty(v));
    }

    *publish() {
      try {
        this.set('status', 'published');
        yield this.save();
        this.intercom.set('user.profile_published', true);
        this.clToaster.success(this.intl.t('settings.marketplace.publish-success'));
      } catch (e) {
        this.set('status', 'unpublished');
        this.clToaster.error(e);
        throw e;
      }
    }

    *unpublish() {
      try {
        this.set('status', 'unpublished');
        yield this.save();
        this.intercom.set('user.profile_published', false);
        this.clToaster.success(this.intl.t('settings.marketplace.unpublish-success'));
      } catch (e) {
        this.set('status', 'published');
        this.clToaster.error(e);
        throw e;
      }
    }

    *claimURL(url) {
      try {
        this.set('url', url);
        yield this.save();
        this.clToaster.success(this.intl.t('settings.marketplace.url-claimed'));
      } catch (e) {
        this.set('url', null);
        this.clToaster.error(e);
        throw e;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intercom", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hashUrl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "summary", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "coverPhoto", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "address2", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "postalCode", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "gender", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "facebook", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "twitter", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "linkedin", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "website", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "introVideo", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "yearStarted", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "numberOfClients", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "coachedHours", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "allowIntroductoryInterviews", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "highestEducation", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "minimumPackagePrice", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "maximumPackagePrice", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "currencyCode", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "locationInfo", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "languages", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "workExperiences", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "coachingExperiences", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "userAffiliations", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "educationDegrees", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "assessments", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "credentials", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "publications", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "coachingCategorizations", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "coachingTypes", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "specialties", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "employeeLevels", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "modalities", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "theoreticalModels", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "plainTextSummary", [_trackedToolbox.cached], Object.getOwnPropertyDescriptor(_class.prototype, "plainTextSummary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unpublish", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "unpublish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "claimURL", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "claimURL"), _class.prototype)), _class));
  _exports.default = Profile;
});