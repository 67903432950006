define("coachlogix/models/resource-group", ["exports", "@ember-data/model", "ember-data-copyable", "ember-data-storefront/mixins/loadable-model", "ember-concurrency", "coachlogix/models/shareable"], function (_exports, _model, _emberDataCopyable, _loadableModel, _emberConcurrency, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-mixins
  var _default = _shareable.default.extend(_emberDataCopyable.default, _loadableModel.default, {
    name: (0, _model.attr)('string'),
    isEngagement: (0, _model.attr)('boolean'),
    isNote: (0, _model.attr)('boolean'),
    // resourceables
    engagements: (0, _model.hasMany)('engagement', {
      async: true,
      inverse: 'resourceGroups'
    }),
    engagementSessions: (0, _model.hasMany)('engagement-session', {
      async: true,
      inverse: 'resourceGroups'
    }),
    engagementTemplates: (0, _model.hasMany)('engagement-template', {
      async: true,
      inverse: 'resourceGroups'
    }),
    accounts: (0, _model.hasMany)('account', {
      async: true,
      inverse: 'resourceGroups'
    }),
    clonedFrom: (0, _model.belongsTo)('resource-group', {
      inverse: null
    }),
    resources: (0, _model.hasMany)('resource', {
      async: true,
      inverse: 'resourceGroups'
    }),
    user: (0, _model.belongsTo)('user', {
      async: true,
      inverse: 'resourceGroups'
    }),
    owner: (0, _model.belongsTo)('user', {
      async: true,
      inverse: 'resourceGroups'
    }),
    addResource: (0, _emberConcurrency.task)(function* (resources) {
      try {
        resources = Ember.isArray(resources) ? resources : [resources];
        this.get('resources').addObjects(resources);
        yield this.save();
        this.clToaster.success('Resource was added successfully.');
      } catch (e) {
        this.clToaster.error(e);
      }
    }),
    removeResource: (0, _emberConcurrency.task)(function* (resources) {
      try {
        resources = Ember.isArray(resources) ? resources : [resources];
        this.get('resources').removeObjects(resources);
        yield this.save();
        this.clToaster.success('Resource was removed successfully.');
      } catch (e) {
        this.clToaster.error(e);
      }
    })
  });

  _exports.default = _default;
});