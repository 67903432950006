define("coachlogix/pods/settings/marketplace/checklist/calendar-sync/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YgWkB9k1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-p-3 cl-flex cl-flex-col cl-space-y-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-flex-col cl-space-y-0.5\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-5\"],[12],[1,[30,[36,0],[\"settings.marketplace.calendar-sync.title\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[12],[1,[30,[36,0],[\"settings.marketplace.calendar-sync.description\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"cronofy/calendar-sync\",[],[[\"@singleCalendar\",\"@onProfileRevoke\"],[true,[30,[36,1],[[32,0,[\"deleteCalendars\"]]],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"perform\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/settings/marketplace/checklist/calendar-sync/template.hbs"
    }
  });

  _exports.default = _default;
});