define("coachlogix/models/shareable", ["exports", "@ember-data/model", "ember-concurrency-decorators", "coachlogix/models/base-model"], function (_exports, _model, _emberConcurrencyDecorators, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Shareable = (_dec = (0, _model.hasMany)('shared-item', {
    async: true,
    inverse: 'shareable'
  }), _dec2 = (0, _model.attr)('boolean'), _dec3 = (0, _model.attr)('boolean'), _dec4 = (0, _model.attr)('boolean'), _dec5 = (0, _model.attr)('boolean'), (_class = (_temp = class Shareable extends _baseModel.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sharedWith", _descriptor, this);

      _initializerDefineProperty(this, "canEdit", _descriptor2, this);

      _initializerDefineProperty(this, "canCopy", _descriptor3, this);

      _initializerDefineProperty(this, "canShare", _descriptor4, this);

      _initializerDefineProperty(this, "isShared", _descriptor5, this);
    }

    *shareWith(attrs, options) {
      let pushAccount = options && options.pushAccount;
      attrs.shareable = this;
      let sharedItem = this.store.createRecord('shared-item', attrs);

      try {
        yield sharedItem.save();

        if (pushAccount && attrs.user.get('employee')) {
          let employee = attrs.user.get('employee');
          sharedItem.set('user.employee', employee);
          sharedItem.set('user.employee.account', attrs.user.get('employee.account'));
        }

        this.get('sharedWith').pushObject(sharedItem);
      } catch (e) {
        yield sharedItem.destroyRecord();
        this.clToaster.error(e);
      }
    }

    *removeSharing(sharedItem) {
      try {
        yield sharedItem.destroyRecord();
        this.get('sharedWith').removeObject(sharedItem);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sharedWith", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "canEdit", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canCopy", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "canShare", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isShared", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "shareWith", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "shareWith"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSharing", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeSharing"), _class.prototype)), _class));
  _exports.default = Shareable;
});