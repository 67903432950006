define("coachlogix/pods/components/v2/engagement/engagement-session/detail-view/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SessionDetailView = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('session.{isScheduled,meetingLocation.additionalInfo}'), _dec5 = Ember.computed('account.activeUser.timezone.name'), _dec(_class = (_class2 = (_temp = class SessionDetailView extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _defineProperty(this, "displayCommentsSection", true);
    }

    get locationLabel() {
      let meetingLocation = this.get('session.meetingLocation');

      if (this.session.isScheduled && meetingLocation.get('isGoogleMeet') && Ember.isEmpty(meetingLocation.get('additionalInfo'))) {
        return this.intl.t('engagement.settings.meeting-locations.link_will_generate');
      }

      return meetingLocation.get('label');
    }

    get timezoneName() {
      const timezone = this.get('account.activeUser.timezone.name');

      if (timezone) {
        return _moment.default.tz(timezone).format('z');
      }

      return this.intl.t('global.general.timezone_default');
    }

    *addParticipant(participant) {
      this.session.get('participants').addObject(participant);
      yield this.session.save();
      this.set('addEmail', false);
      this.set('editParticipants', false);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "locationLabel", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "locationLabel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "timezoneName", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "timezoneName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addParticipant", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "addParticipant"), _class2.prototype)), _class2)) || _class);
  var _default = SessionDetailView;
  _exports.default = _default;
});