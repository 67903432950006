define("coachlogix/pods/components/cl-resource-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xgP7ySOZ",
    "block": "{\"symbols\":[\"@solid\",\"&attrs\"],\"statements\":[[8,\"cl-icon\",[[16,0,[31,[[32,0,[\"styleNamespace\"]],\" \",[32,0,[\"styleNamespace\"]],\"--\",[32,0,[\"category\",\"name\"]],\" \",[30,[36,0],[[32,1],\"solid\"],null]]]],[17,2]],[[\"@solid\",\"@icon\",\"@size\"],[[32,1],[32,0,[\"category\",\"icon\"]],[32,0,[\"size\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cl-resource-icon/template.hbs"
    }
  });

  _exports.default = _default;
});