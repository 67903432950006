define("coachlogix/pods/components/v2/notifications-dropdown/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NotificationsDropdownComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec4 = (0, _emberConcurrencyDecorators.task)({
    on: 'init'
  }), _dec(_class = (_class2 = (_temp = class NotificationsDropdownComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "selectedTab", 0);

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    // calculates which tab should be active by default
    // depending on the latest unread notification type
    // runs when component renders (on init)
    *setSelectedTab() {
      // await for all the notifications
      let notifications = yield this.notifications.all.last; // filter the unread notifications and sort by createdAt descending

      let unreadNotifications = notifications.filter(a => !a.read).sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        } else if (a.createdAt > b.createdAt) {
          return -1;
        }

        return 0;
      });

      if (unreadNotifications && unreadNotifications[0]) {
        // invitations tab is 1, other is 0. If notification
        // is an invitation we set the selected tab to 1
        let selectedTab = unreadNotifications[0].isInvitation ? 1 : 0;
        this.set('selectedTab', selectedTab);
      }
    }

    *markAllAsRead() {
      yield this.notifications.all.last;
      let unreadNotifications = yield (0, _emberConcurrency.all)(this.notifications.unread.mapBy('notification'));
      unreadNotifications.setEach('read', true);
      yield (0, _emberConcurrency.all)(unreadNotifications.invoke('save'));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "setSelectedTab", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "setSelectedTab"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "markAllAsRead", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "markAllAsRead"), _class2.prototype)), _class2)) || _class);
  var _default = NotificationsDropdownComponent;
  _exports.default = _default;
});