define("coachlogix/pods/dashboard/billable-activity/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let DashboardBillableActivityWidgetComponent = (_dec = (0, _component.tagName)(''), _dec(_class = class DashboardBillableActivityWidgetComponent extends Ember.Component {}) || _class);
  var _default = DashboardBillableActivityWidgetComponent;
  _exports.default = _default;
});