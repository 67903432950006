define("coachlogix/transforms/array", ["exports", "@ember-data/serializer/transform", "change-object-case"], function (_exports, _transform, _changeObjectCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _changeObjectCase.default.options = {
    recursive: true,
    arrayRecursive: true
  };

  var _default = _transform.default.extend({
    deserialize(value) {
      let array = [];

      if (value && typeof value === 'string') {
        try {
          array = JSON.parse(value);
        } catch (e) {
          return null;
        }
      } else {
        array = value || [];
      }

      return _changeObjectCase.default.camelArray(array);
    },

    serialize(value) {
      if (Ember.isArray(value)) {
        return Ember.A(_changeObjectCase.default.snakeArray(value));
      } else {
        return value;
      }
    }

  });

  _exports.default = _default;
});