define("coachlogix/pods/my-marketplace/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0aptm3J1",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[10,\"div\"],[14,0,\"cl-container cl-mx-auto cl-p-4 cl-flex cl-flex-col\"],[12],[2,\"\\n  \"],[8,\"cl-card\",[[24,0,\"cl-sticky cl-top-0 cl-z-20\"]],[[\"@bordered\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-tabs\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"tab\"]],[],[[\"@href\",\"@isActive\"],[[30,[36,0],[\"my-marketplace.orders\"],null],[30,[36,1],[\"my-marketplace.orders\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[\"marketplace.my-marketplace.orders\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,1,[\"tab\"]],[],[[\"@href\",\"@isActive\"],[[30,[36,0],[\"my-marketplace.interviews\"],null],[30,[36,1],[\"my-marketplace.interviews\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[\"marketplace.my-marketplace.interviews\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"href-to\",\"is-active\",\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/my-marketplace/template.hbs"
    }
  });

  _exports.default = _default;
});