define("coachlogix/transforms/json", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize(serialized) {
      if (Ember.isEmpty(serialized)) {
        return {};
      } else {
        return serialized;
      }
    },

    serialize(deserialized) {
      if (Ember.isEmpty(deserialized)) {
        return {};
      } else {
        return deserialized;
      }
    }

  });

  _exports.default = _default;
});