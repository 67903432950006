define("coachlogix/pods/my-marketplace/orders/order/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrderRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), _dec5 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class OrderRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "twilioSync", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _defineProperty(this, "document", null);
    }

    model({
      order_id,
      success,
      cancelled
    }) {
      return {
        orderTask: this.loadOrder.perform(order_id, success, cancelled)
      };
    }

    deactivate() {
      // close twilio document if existing
      this.document?.close();
      this.document = null;
    }

    *loadOrder(id, success, cancelled) {
      let order = yield this.store.findRecord('order', id, {
        include: 'package,package.owner',
        reload: true
      }); // only listen for changes when the order still isn't completed

      if (success && (order.isPending || order.isProcessing)) {
        order.status = 'processing';

        if (order.twilioSyncDocumentId) {
          this.listenToUpdates.perform(order);
        }
      } // if user cancelled payment, revert order status


      if (cancelled && order.isProcessing) {
        order.status = 'pending';
        yield order.save();
      }

      return order;
    }

    *listenToUpdates(order) {
      let client = yield this.twilioSync.initTwilio.perform();
      this.document = yield client.document(order.twilioSyncDocumentId);
      let isCompleted = this.onOrderCompleted(this.document.data, order); // the order still isn't completed
      // we need to listen for changes

      if (!isCompleted) {
        this.document.on('updated', event => this.onOrderCompleted(event.data, order));
      }
    } // tests if twilio sync document informs the order completion
    // if it does, sets the order to the completed status and transitions
    // to the engagement


    onOrderCompleted(document, order) {
      if (Ember.isPresent(document?.engagement_id)) {
        order.status = 'completed';
        this.router.transitionTo('engagement', document.engagement_id);
        return true;
      }

      return false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "twilioSync", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadOrder", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "loadOrder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listenToUpdates", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "listenToUpdates"), _class.prototype)), _class));
  _exports.default = OrderRoute;
});