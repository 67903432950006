define("coachlogix/pods/engagement/matching-process-coaches/controller", ["exports", "ember-ajax/errors", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _errors, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MatchingProcessCoaches = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.reads('model.engagementTask.value'), _dec6 = Ember.computed.reads('engagement.coachee'), _dec7 = Ember.computed.reads('engagement.coachPools.firstObject'), _dec8 = Ember.computed.reads('coachPool.coachPoolCoaches'), _dec9 = Ember.computed.reads('engagement.matchingSettings.coachPoolsStartEngagementAutomatically'), _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class MatchingProcessCoaches extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "engagement", _descriptor5, this);

      _initializerDefineProperty(this, "coachee", _descriptor6, this);

      _initializerDefineProperty(this, "coachPool", _descriptor7, this);

      _initializerDefineProperty(this, "coachPoolCoaches", _descriptor8, this);

      _initializerDefineProperty(this, "startAutomatically", _descriptor9, this);

      _defineProperty(this, "step", null);

      _defineProperty(this, "showAddCoachesDialog", false);
    }

    *loadData({
      sortData,
      filterData
    }) {
      yield (0, _emberConcurrency.timeout)(600);
      let engagement = yield this.model.engagementTask;
      let coachPools = yield engagement.get('coachPools');
      let coachPoolId = coachPools.firstObject.id;
      let params = {};

      if (sortData.length) {
        let sortName = sortData[0].prop;
        let sortPrefix = sortData[0].direction === 'desc' ? '-' : '';
        params.sort = `${sortPrefix}${sortName}`;
      }

      let filters = {};
      filters.coach_pool_id = coachPoolId;

      if (filterData.filter) {
        filters.search = filterData.filter;
      }

      params.filter = filters;
      params.include = ['employee.user.cronofy-connected-account', 'employee.account', 'employee.department', 'employee.client-coaches', 'employee.user.timezone', 'employee.user.availability-preferences', 'employee.coaching-requests', 'employee.coaching-requests.coach.user.cronofy-connected-account', 'employee.coaching-requests.coachee.user'].join(',');
      let coachPoolCoaches = yield this.store.query('coach-pool-coach', params);
      this.set('meta', coachPoolCoaches.meta);
      return coachPoolCoaches;
    }
    /**
     * ACTIONS
     */


    onMatchingProcessCompletedSaved() {
      this.send('onMatchingProcessCompleted');
    }

    matchingProcessSelected(selected) {
      this.set('showAddCoachesDialog', false);

      if (selected === 'coach') {
        this.set('step', 'selectingCoach');
        return;
      }

      if (selected === 'coach-pools') {
        this.set('step', 'selectingCoachPool');
        return;
      }
    }

    *coachPoolsSelected(selectedPools) {
      try {
        let coachPools = yield this.engagement.get('coachPools');
        selectedPools.forEach(pool => {
          let poolExists = coachPools.find(cp => cp.id === pool.id);

          if (!poolExists) {
            this.engagement.coachPools.pushObject(pool);
          }
        });
        let include = ['coach.user.cronofy-connected-account', 'coach.user.availability-preferences', 'coach.user.timezone', 'coachee.user', 'coach.client-coaches', 'coach.account', 'resources', 'resource-groups', 'engagement-summary', 'comments', 'engagement-template', 'client', 'contractor-client', 'coach-pools', 'coach-pools.coaches.account', 'coach-pools.coaches.client-coaches', 'coach-pools.coaches.user.availability-preferences', 'coach-pools.coach-pool-coaches', 'coach-pools.coach-pool-coaches.employee', 'coach-pools.coach-pool-coaches.employee.user', 'coach-pools.coaches.coaching-requests.coach.user', 'coach-pools.coaches.coaching-requests.coachee.user', 'coachee.user'].join(','); // we need to save the engagement with an includes parameter because
        // the backend is merging all coach pools into one
        // we can't just do engagement.reload() because it will be returned with
        // link relationships and/or missing relationships

        yield this.engagement.save({
          adapterOptions: {
            include
          }
        });
        let successMessage = this.intl.t('coach-pools.coaches-added');
        this.clToaster.success(successMessage);
        this.set('step', null);
        this.tableApi.reloadData();
      } catch (e) {
        this.clToaster.error(e);
        this.set('step', null);
      }
    }

    *addPoolCoachesTask(selectedCoaches) {
      try {
        let coachPool = yield this.coachPool;
        let coachPoolCoaches = yield coachPool.get('coachPoolCoaches');
        let cpcPromises = [];

        for (let coach of selectedCoaches) {
          let coachExists = coachPoolCoaches.find(cpc => cpc.belongsTo('employee').id() === coach.id); // prevent duplicates

          if (!coachExists) {
            let cpc = this.store.createRecord('coach-pool-coach', {
              employee: coach,
              coachPool
            });
            cpcPromises.push(cpc.save());
          }
        }

        let cpcs = yield (0, _emberConcurrency.all)(cpcPromises);
        coachPool.coachPoolCoaches.addObjects(cpcs);
        coachPool.coaches.addObjects(selectedCoaches.filter(sc => !coachPoolCoaches.find(cpc => cpc.belongsTo('employee').id() === sc.id))); // Only show toast if at least one coach was added.

        if (cpcs.length) {
          this.set('step', null);
          this.clToaster.success(this.intl.t('coach-pools.coaches-added'));
          this.tableApi.reloadData();
        } else {
          this.set('step', null);
        }
      } catch (e) {
        this.set('step', null);
        this.clToaster.error(e);
      }
    }
    /**
     * TASKS
     */


    *removeCPC(coachCPC) {
      try {
        yield coachCPC.destroyRecord();
        let successMessage = this.intl.t('coach-pools.coach-removed-from-matching');
        this.tableApi.reloadData();
        this.clToaster.success(successMessage);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *startEngagement(coach) {
      let account = yield this.engagement.get('ownerAccount');

      if (account.isFrozen) {
        this.set('showFrozenAccountDialog', true);
        return;
      }

      try {
        this.engagement.set('coach', coach);
        yield this.engagement.activate.linked().perform();
        this.router.replaceWith('engagement.program', this.engagement.id);
      } catch (e) {
        if ((0, _errors.isBadRequestError)(e)) {
          this.set('errors', e.payload.errors);
          this.set('upgrade', true);
        }
      }
    }

    *maybeSelectCoach(coachingRequest) {
      let account = yield this.engagement.get('ownerAccount');

      if (account.isFrozen) {
        this.set('showFrozenAccountDialog', true);
        return;
      }

      if (this.startAutomatically) {
        this.set('showStartConfirmation', coachingRequest);
      } else {
        yield this.selectCoach.perform(coachingRequest);
      }
    }

    *selectCoach(coachingRequest) {
      try {
        coachingRequest.set('status', 'coach_approved');
        yield coachingRequest.save();
        this.router.replaceWith('engagement', this.engagement.id);
        this.onMatchingProcessCompletedSaved();
      } catch (e) {
        if ((0, _errors.isBadRequestError)(e)) {
          this.set('errors', e.payload.errors);
          this.set('upgrade', true);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "engagement", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "coachee", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "coachPool", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "coachPoolCoaches", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "startAutomatically", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadData", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMatchingProcessCompletedSaved", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onMatchingProcessCompletedSaved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "matchingProcessSelected", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "matchingProcessSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "coachPoolsSelected", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "coachPoolsSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addPoolCoachesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "addPoolCoachesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeCPC", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeCPC"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startEngagement", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "startEngagement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "maybeSelectCoach", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "maybeSelectCoach"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCoach", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "selectCoach"), _class.prototype)), _class));
  _exports.default = MatchingProcessCoaches;
});