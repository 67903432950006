define("coachlogix/models/engagement-summary", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    amountPaid: (0, _model.attr)('number'),
    amountDue: (0, _model.attr)('number'),
    percentComplete: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    hoursCompleted: (0, _model.attr)('number'),
    hoursScheduled: (0, _model.attr)('number'),
    hoursRemaining: (0, _model.attr)('number'),
    hoursCancelled: (0, _model.attr)('number'),
    sessionsCompleted: (0, _model.attr)('number'),
    sessionsScheduled: (0, _model.attr)('number'),
    sessionsRemaining: (0, _model.attr)('number'),
    sessionsCancelled: (0, _model.attr)('number'),
    totalSessions: (0, _model.attr)('number'),
    tasksCompleted: (0, _model.attr)('number'),
    tasksOpen: (0, _model.attr)('number'),
    goalsCompleted: (0, _model.attr)('number'),
    goalsOpen: (0, _model.attr)('number'),
    totalReviews: (0, _model.attr)('number'),
    averageRating: (0, _model.attr)('number'),
    totalCoachReviews: (0, _model.attr)('number'),
    averageCoachRating: (0, _model.attr)('number'),
    mostRecentSession: (0, _model.attr)('date'),
    nextSession: (0, _model.attr)('date'),
    engagement: (0, _model.belongsTo)('engagement', {
      inverse: 'engagementSummary'
    }),
    hourValues: Ember.computed.collect('hoursCompleted', 'hoursRemaining'),
    totalHours: Ember.computed.sum('hourValues'),
    percentageHours: Ember.computed('hoursCompleted', 'totalHours', function () {
      return Math.round(this.get('hoursCompleted') / this.get('totalHours') * 100) || 0;
    }),
    sessionValues: Ember.computed.collect('sessionsCompleted', 'sessionsRemaining'),
    percentageSessions: Ember.computed('sessionsCompleted', 'totalSessions', function () {
      return Math.round(this.get('sessionsCompleted') / this.get('totalSessions') * 100) || 0;
    }),
    taskValues: Ember.computed.collect('tasksCompleted', 'tasksOpen'),
    totalTasks: Ember.computed.sum('taskValues'),
    percentageTasks: Ember.computed('tasksCompleted', 'totalTasks', function () {
      return Math.round(this.get('tasksCompleted') / this.get('totalTasks') * 100) || 0;
    }),
    goalValues: Ember.computed.collect('goalsCompleted', 'goalsOpen'),
    totalGoals: Ember.computed.sum('goalValues'),
    percentageGoals: Ember.computed('goalsCompleted', 'totalGoals', function () {
      return Math.round(this.get('goalsCompleted') / this.get('totalGoals') * 100) || 0;
    })
  });

  _exports.default = _default;
});