define("coachlogix/mixins/pagination", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_MS = 250;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    // the queryParams hash has a special meaning when
    // used in controllers. Here we're binding the query string
    // to a query param.
    queryParams: {
      'queryOptions.q': 'search'
    },
    search: null,
    // we need to use a computed to create an array instance on demand,
    // otherwise the same empty array instance is shared among all classes.
    // we could use init() as well
    model: Ember.computed(function () {
      return [];
    }),
    currentPage: 1,
    fetch: (0, _emberConcurrency.task)(function* () {
      let pageOptions = this.buildPageOptions();
      let queryOptions = this.buildQueryOptions();
      let records = yield this.get('store').query(this.get('modelName'), Ember.assign(pageOptions, queryOptions)); // increment the page counter

      this.incrementProperty('currentPage'); // update total pages with the metadata the server returns

      this.set('totalPages', records.meta.total);
      this.set('totalItems', records.meta.count); // push new records to the model

      this.get('model').pushObjects(records.toArray());
    }).drop(),

    // An overridable method that builds the correct
    // options hash for page related stuff
    buildPageOptions() {
      return {
        page: this.get('currentPage')
      };
    },

    // An overridable method that builds the correct
    // options hash for query related stuff
    // returns `queryOptions` by default
    buildQueryOptions() {
      return this.get('queryOptions') || {};
    },

    // true when there are no more records to load
    // because we already reached the last page
    noMoreRecords: Ember.computed('totalPages', 'currentPage', function () {
      return this.get('currentPage') >= this.get('totalPages');
    }),
    query: (0, _emberConcurrency.task)(function* (q) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      this.set('queryOptions.q', q.target.value);
      this.reset();
      this.get('fetch').perform();
    }).restartable(),

    reset() {
      this.set('currentPage', 1);
      this.set('totalPages', null);
      this.get('model').clear();
      this.get('fetch').cancelAll();
    }

  });

  _exports.default = _default;
});