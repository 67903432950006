define("coachlogix/models/stripe-card", ["exports", "@ember-data/model", "moment", "coachlogix/models/base-model"], function (_exports, _model, _moment, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    customerId: (0, _model.attr)('string'),
    cardId: (0, _model.attr)('string'),
    cardToken: (0, _model.attr)('string'),
    cardLastFour: (0, _model.attr)('string'),
    expMonth: (0, _model.attr)('string'),
    expYear: (0, _model.attr)('string'),
    cardBrand: (0, _model.attr)('string'),
    isDefault: (0, _model.attr)('boolean'),
    user: (0, _model.belongsTo)('user'),
    isExpiring: Ember.computed('expMonth', 'expYear', function () {
      let warningDate = (0, _moment.default)({
        year: parseInt(this.expYear),
        month: parseInt(this.expMonth) - 1
      });
      let expireDate = warningDate.clone().endOf('month');
      return (0, _moment.default)().isBetween(warningDate, expireDate);
    })
  });

  _exports.default = _default;
});