define("coachlogix/pods/reports/report/report-table/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ReportTableComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, (_class = (_temp = class ReportTableComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "columns", _descriptor2, this);

      _initializerDefineProperty(this, "selectedFilters", _descriptor3, this);

      _initializerDefineProperty(this, "availableFilters", _descriptor4, this);

      _initializerDefineProperty(this, "reportType", _descriptor5, this);

      _initializerDefineProperty(this, "cards", _descriptor6, this);

      _initializerDefineProperty(this, "totalRows", _descriptor7, this);

      let report = this.args.report;
      this.columns = report.columns;
      this.reportType = report.reportType;
      this.availableFilters = report.filters;
      this.totalRows = report.meta.page.total;
      this.cards = report.meta.graphs || [];
    }

    applyFilters() {
      this.tableApi.reloadData();
    }

    *loadData({
      paginationData,
      filterData,
      sortData
    }) {
      yield (0, _emberConcurrency.timeout)(600);
      let params = {};

      if (sortData.length) {
        let sortName = sortData[0].prop;
        let sortPrefix = sortData[0].direction === 'desc' ? '-' : '';
        params.sort = `${sortPrefix}${sortName}`;
      }

      if (filterData.filter) {
        params.filter = {};
        params.filter.search = filterData.filter;
      }

      params.page = {
        size: paginationData.pageSize,
        number: paginationData.pageNumber
      };

      if (this.selectedFilters) {
        params.filter = Ember.assign({}, params.filter, this.selectedFilters);
      }

      let report = yield this.store.findRecord('report', this.args.report.id, {
        reload: true,
        adapterOptions: {
          queryParams: params
        }
      });
      this.availableFilters = report.filters;
      this.reportType = report.reportType;
      this.totalRows = report.meta.page.total;
      return report.rows;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedFilters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.Object.create();
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "availableFilters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "reportType", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cards", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "totalRows", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "applyFilters", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "applyFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadData", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadData"), _class.prototype)), _class));
  _exports.default = ReportTableComponent;
});