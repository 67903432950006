define("coachlogix/pods/components/v2/resources/preview-file-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zOESMfhG",
    "block": "{\"symbols\":[\"dialog\",\"@resource\",\"&attrs\",\"@onClose\"],\"statements\":[[8,\"cl-dialog\",[[16,0,[32,0,[\"styleNamespace\"]]],[17,3]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"cl-title-3 cl-flex-1\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n\\n    \"],[8,\"cl-button\",[],[[\"@iconButton\",\"@onClick\"],[true,[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"close\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[[24,0,\"cl-flex\"]],[[\"@padding\"],[false]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,1],[[32,2,[\"isPreviewable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"filestack-preview\",[[24,0,\"preview-iframe-wrapper\"]],[[\"@handle\"],[[32,2,[\"resourceInfo\",\"handle\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-flex cl-flex-col cl-items-center cl-justify-center\"],[12],[2,\"\\n        \"],[8,\"cl-empty-state\",[[24,0,\"cl-mb-2\"]],[[\"@icon\",\"@size\",\"@text\"],[\"visibility_off\",108,[30,[36,0],[\"resources.please-download\"],null]]],null],[2,\"\\n        \"],[8,\"cl-button\",[[24,\"target\",\"_blank\"]],[[\"@primary\",\"@raised\",\"@href\"],[true,true,[31,[[32,2,[\"resourceInfo\",\"url\"]],\"?dl=true\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"file-download\"]],null],[2,\"\\n          \"],[1,[30,[36,0],[\"ui.download\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/resources/preview-file-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});