define("coachlogix/pods/components/ui/empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h8w/nWuf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"empty-state__icon\"],[12],[1,[30,[36,7],[[35,6]],[[\"size\"],[[35,5]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h1\"],[15,0,[30,[36,3],[[30,[36,1],[[35,2],[35,2],\"md-display-1\"],null],\" typography--contrast \",[30,[36,1],[[35,0],\"text-center\"],null]],null]],[12],[2,\"\\n      \"],[1,[34,4]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"center\",\"if\",\"textClass\",\"concat\",\"text\",\"iconSize\",\"icon\",\"paper-icon\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/ui/empty-state/template.hbs"
    }
  });

  _exports.default = _default;
});