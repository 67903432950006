define("coachlogix/pods/personal-settings/notifications/controller", ["exports", "ember-concurrency-decorators", "ember-group-by"], function (_exports, _emberConcurrencyDecorators, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PersonalSettingsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.reads('model.messagingTypes.value'), _dec7 = Ember.computed.reads('account.activeUser'), _dec8 = (0, _emberGroupBy.default)('model.messagingTypes.value', 'category'), _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class PersonalSettingsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "emberOauth2", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "clToaster", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "notifications", _descriptor5, this);

      _initializerDefineProperty(this, "messagingTypes", _descriptor6, this);

      _initializerDefineProperty(this, "activeUser", _descriptor7, this);

      _initializerDefineProperty(this, "groupedNotifications", _descriptor8, this);

      _defineProperty(this, "iconsMap", {
        accounts: 'account-circle',
        engagements: 'flag',
        invoices: 'attach-money',
        network: 'timeline',
        payments: 'credit-card',
        sessions: 'people',
        tasks: 'check-box'
      });
    }

    setUserProperty(prop, value) {
      this.set(`activeUser.${prop}`, value);
      this.save.perform();
    }

    setMessagingTypeProperty(messagingType, prop, value) {
      messagingType.set(prop, value);
      this.saveMessagingType.perform(messagingType);
    }

    *save() {
      try {
        yield this.activeUser.save();
      } catch (e) {
        this.clToaster.error(e);
      }

      if (this.get('onSave')) {
        this.get('onSave')();
      }
    }

    *saveMessagingType(messagingType) {
      return yield messagingType.save();
    }

    *toggleAppNotifications(value) {
      if (value) {
        try {
          yield this.notifications.request();
        } catch (e) {
          this.clToaster.warn('Permission for notifications was denied. Please grant permissions on your browser settings.');
        }
      } else {
        yield this.notifications.revoke();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "emberOauth2", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "messagingTypes", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "groupedNotifications", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setUserProperty", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setUserProperty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setMessagingTypeProperty", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setMessagingTypeProperty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveMessagingType", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveMessagingType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAppNotifications", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAppNotifications"), _class.prototype)), _class));
  _exports.default = PersonalSettingsController;
});