define("coachlogix/transforms/date", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateTransform extends _transform.default {
    deserialize(serialized) {
      if (serialized) {
        return (0, _moment.default)(serialized).toDate();
      } else {
        return null;
      }
    }

    serialize(date) {
      if (date) {
        return (0, _moment.default)(date).format('YYYY-MM-DD');
      } else {
        return null;
      }
    }

  }

  _exports.default = DateTransform;
});