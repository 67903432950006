define("coachlogix/pods/components/v2/messaging/user-selection-dialog/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let UserSelectionDialogComponent = (_dec = (0, _component.tagName)(''), _dec(_class = (_class2 = class UserSelectionDialogComponent extends Ember.Component {
    *onEmployeesSelection(employees) {
      if (this.onChange) {
        let users = yield (0, _emberConcurrency.all)(employees.mapBy('user'));
        this.onChange(users);
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "onEmployeesSelection", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "onEmployeesSelection"), _class2.prototype)), _class2)) || _class);
  var _default = UserSelectionDialogComponent;
  _exports.default = _default;
});