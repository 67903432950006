define("coachlogix/pods/components/v2/summary-cards/employee-info/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmployeeInfoComponent = (_dec = Ember.inject.service, (_class = (_temp = class EmployeeInfoComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "properties", [{
        name: 'employeeTitle',
        label: this.intl.t('employee.title'),
        icon: 'work'
      }, {
        name: 'user.email',
        label: this.intl.t('ui.email'),
        icon: 'mail'
      }, {
        name: 'internalIdentificationNumber',
        label: this.intl.t('employee.id'),
        icon: 'group'
      }, {
        name: 'employeeLevel.title',
        label: this.intl.t('employee.level'),
        icon: 'group'
      }, {
        name: 'companyLocation.name',
        label: this.intl.t('ui.location'),
        icon: 'location_on'
      }, {
        name: 'department.name',
        label: this.intl.t('ui.department'),
        icon: 'business'
      }, {
        name: 'subDepartment.name',
        label: this.intl.t('models.names.business-unit'),
        icon: 'business'
      }]);
    }

    get values() {
      return this.properties.map(p => {
        return { ...p,
          value: Ember.get(this.args.employee, p.name)
        };
      }).filter(p => Boolean(p.value));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EmployeeInfoComponent;
});