define("coachlogix/pods/components/ui/alert-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZLkSmrXp",
    "block": "{\"symbols\":[\"card\",\"&default\"],\"statements\":[[6,[37,13],null,[[\"class\"],[[30,[36,12],[[35,11],\" alert-card \",[30,[36,3],[[35,2],\" alert-card--warn \"],null],[30,[36,3],[[35,10],\"alert-card--error\"],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,9],[[30,[36,8],[[32,1,[\"content\"]],\"expected `card.content` to be a contextual component but found a string. Did you mean `(component card.content)`? ('coachlogix/pods/components/ui/alert-card/template.hbs' @ L10:C5) \"],null]],[[\"class\"],[\"layout-row layout-align-center-center\"]],[[\"default\"],[{\"statements\":[[6,[37,3],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,7],[[35,6]],[[\"size\",\"class\"],[[35,5],\"alert-card__icon\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"flex alert-card__text\"],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"class\",\"primary\",\"warn\",\"raised\",\"onClick\"],[\"alert-card__button\",[30,[36,3],[[35,2],false,true],null],[35,2],true,[35,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"buttonText\",\"onClick\",\"warn\",\"if\",\"paper-button\",\"iconSize\",\"icon\",\"paper-icon\",\"-assert-implicit-component-helper-argument\",\"component\",\"error\",\"class\",\"concat\",\"paper-card\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/ui/alert-card/template.hbs"
    }
  });

  _exports.default = _default;
});