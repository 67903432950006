define("coachlogix/pods/components/v2/engagement/engagement-coaching-request/master-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4F8yHUNa",
    "block": "{\"symbols\":[\"@item\",\"@href\",\"@isActive\",\"@onClick\",\"&attrs\"],\"statements\":[[8,\"cl-list/item\",[[17,5]],[[\"@dense\",\"@href\",\"@isActive\",\"@onClick\"],[true,[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,1,[\"itemable\",\"canBeMovedOnFrom\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[],[[\"@icon\",\"@solid\",\"@success\",\"@size\"],[\"check\",true,true,22]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"cl-icon\",[[24,0,\"cl-icon-solid--session\"]],[[\"@solid\",\"@icon\",\"@size\"],[true,\"people\",22]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"cl-title-6 cl-truncate\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"itemable\",\"interviewTitle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"itemable\",\"interviewTitle\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,1],[\"engagement-coaching-request.default-title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"cl-body-1 cl-truncate\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"itemable\",\"completedDate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[\"engagement-coaching-request.completed-on\"],[[\"date\"],[[30,[36,0],[[32,1,[\"itemable\",\"completedDate\"]],\"ll\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,1,[\"itemable\",\"scheduledDate\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[\"engagement-coaching-request.scheduled-for\"],[[\"date\"],[[30,[36,0],[[32,1,[\"itemable\",\"scheduledDate\"]],\"ll\"],null]]]]],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement/engagement-coaching-request/master-item/template.hbs"
    }
  });

  _exports.default = _default;
});