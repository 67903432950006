define("coachlogix/pods/form/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U6mLUmfG",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"model\",\"form\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-h-full cl-w-full cl-flex cl-justify-center cl-items-center\"],[12],[2,\"\\n    \"],[8,\"cl-progress-circular\",[],[[\"@size\"],[48]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-container-v2\"],[12],[2,\"\\n    \"],[8,\"form/form-editor\",[],[[\"@active\",\"@form\",\"@disabled\"],[[32,0,[\"active\"]],[32,0,[\"form\"]],[30,[36,1],[[30,[36,0],[\"edit resource\",[32,0,[\"form\"]]],null],[32,0,[\"shouldDisable\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"cannot\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/form/index/template.hbs"
    }
  });

  _exports.default = _default;
});