define("coachlogix/models/pending-action", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _baseModel.default.extend({
    created: (0, _model.attr)('date'),
    dateDisposed: (0, _model.attr)('date'),
    action: (0, _model.attr)('string'),
    notificationType: (0, _model.attr)('string'),
    updated: (0, _model.attr)('date')
    /*
    actionObject: belongsTo('model', {
      async: true,
      polymorphic: true
    }),
     allowedDispositions: hasMany('disposition', {
      async: true
    }),
     disposition: belongsTo('disposition', {
      async: true
    }),
     targetObject: belongsTo('model', {
      async: true,
      polymorphic: true
    }),
     intermediateObject: belongsTo('model', {
      async: true,
      polymorphic: true
    }),
     user: belongsTo('user', {
      async: true
    }),
     context: belongsTo('context', {
      async: true
    }),
     relatedNotification: belongsTo('notification', {
      async: true,
      inverse: null
    }) */

  });

  _exports.default = _default;
});