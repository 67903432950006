define("coachlogix/initializers/install-devtools", ["exports", "ember-chrome-devtools/initializers/install-devtools"], function (_exports, _installDevtools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _installDevtools.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _installDevtools.initialize;
    }
  });
});