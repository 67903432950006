define("coachlogix/models/role", ["exports", "@ember-data/model", "coachlogix/models/base-model"], function (_exports, _model, _baseModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    name: (0, _model.attr)('string'),
    displayName: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    objectType: (0, _model.attr)('string'),
    isHidden: (0, _model.attr)('boolean'),
    isDefault: (0, _model.attr)('boolean'),
    permissions: (0, _model.hasMany)('permission', {
      async: true
    })
  });

  _exports.default = _default;
});