define("coachlogix/translations/fr-fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "account-setup": {
      "account-step": {
        "account-type-warning": "Cette inscription est destinée aux entreprises et aux pratiques indépendantes. Si vous êtes un employé interne ou une partie invitée, ne continuez pas. Veuillez vous inscrire en utilisant le lien d'invitation envoyé par votre groupe d'invitation.",
        "coach-description": "Pour les coaches indépendants qui suivent une pratique privée",
        "coach-label": "Entraîneur",
        "company-description": "Pour la gestion de plusieurs coaches",
        "company-label": "Entreprise",
        "question": "Créez-vous ce compte ou gère-t-il le coaching?",
        "title": "Bienvenue à Coaching.com"
      },
      "buy-coaching": "We contract external coaches",
      "coach-description": "Entraîneur individuel qui offre des services d'apprentissage et de développement.",
      "coach-label": "Entraîneur professionnel",
      "coachlogix": "Coaching.com",
      "company-name-label": "Nom de la compagnie",
      "company-step": {
        "individual-company-question": "Quel est le nom de votre entreprise individuelle?",
        "org-question": "Quel est le nom de votre organisation?",
        "title": "Dis nous en plus"
      },
      "company-type-question": "Quelle meilleure description de votre entreprise?",
      "contract-coaches": "Avez-vous des contracteurs externes?",
      "email-label": "Email",
      "enable-features-contract-coaches": "Activez les fonctionnalités pour contracter des coachs externes et des sociétés d'encadrement, recevoir des factures et effectuer des paiements, et suivre les dépenses d'encadrement.",
      "enable-features-internal-coaching": "Activez les fonctionnalités pour gérer le coaching avec des coachs internes au sein de votre organisation.",
      "enable-features-sell-coaching": "Activer les fonctionnalités pour se connecter avec les clients, créer des factures et recevoir des paiements, et suivre les revenus de coaching.",
      "enterprise-description": "Société qui exécute des programmes internes d'apprentissage et de développement.",
      "enterprise-label": "Enterprise Compagnie",
      "first-name-label": "Prénom",
      "greeting": "salut",
      "internal-coaching": "Employez-vous des coachs internes?",
      "last-name-label": "Nom de famille",
      "legal": "En cliquant sur \"CONTINUER\", vous acceptez les conditions de service et la politique de confidentialité de Coaching.com.",
      "options-title": "Aidez-nous à adapter votre expérience. Sélectionnez ceux qui s'appliquent.",
      "provider-description": "Société avec plusieurs coaches qui offrent des services d'apprentissage et de développement.",
      "provider-label": "Fournisseur de coaching",
      "sell-coaching": "Vendez-vous des services de coaching?",
      "social-merge-step": {
        "continue-signup": "Continuer l'inscription",
        "create-account": "Si c'est votre première fois ici et que vous souhaitez configurer votre compte, veuillez cliquer sur le bouton «Continuer l'inscription».",
        "merge-email": "Si vous avez déjà utilisé un utilisateur Coaching.com et que vous avez précédemment utilisé un autre email pour vous connecter, veuillez l'entrer ci-dessous.",
        "title": "Nous n'avons pas trouvé d'utilisateur correspondant à l'information de votre profil social."
      },
      "step-one-label": "Step 1 of 2",
      "step-two-label": "Step 2 of 2",
      "user-info-step": {
        "title": "Nous sommes ravis que vous soyez ici! Dites-nous d'abord un peu de vous."
      },
      "verify-email-step": {
        "check-junk": "Si vous ne voyez pas l'email dans votre boîte de réception, assurez-vous de vérifier vos dossiers de courrier indésirable / spam.",
        "continue-merge": "Continuer",
        "title": "Nous vous avons envoyé un email avec un code de vérification que vous recevrez sous peu. Veuillez entrer le code ci-dessous."
      }
    },
    "application": {
      "context-switcher": {
        "confirm-button": "Commutateur",
        "error": "Une erreur s'est produite lors du changement de locataire",
        "prompt": "Cette notification vous oblige à passer à un autre locataire? Voulez-vous poursuivre?",
        "title": "Changer de locataire"
      }
    },
    "cl-calendar": {
      "today": "Aujourd'hui"
    },
    "cl-color-picker": {
      "cancel": "Annuler",
      "clear": "Effacé",
      "save": "Sauver"
    },
    "cl-comment-submit": {
      "comment": "Commentaire",
      "placeholder": "Écrire un commentaire",
      "submitting": "Soumission..."
    },
    "cl-comments": {
      "cancel": "Annuler",
      "collapse": "Effondrer",
      "expand": "Développer",
      "placeholder": "Modifier votre commentaire",
      "submit": "Soumettre",
      "submitting": "Soumission..."
    },
    "cl-confirmation-dialog": {
      "cancel": "Annuler",
      "default-title": "Êtes-vous sûr?",
      "ok": "Ok"
    },
    "cl-date-picker": {
      "clear": "Nettoyer"
    },
    "cl-dropzone": {
      "drop-files": "Déposez vos fichiers ici",
      "or": "ou",
      "select": "Sélectionnez un fichier"
    },
    "cl-infinite-pagination": {
      "empty": "Pas d'objet.",
      "loading": "Chargement..."
    },
    "cl-places-autocomplete": {
      "loading-message": "Chargement des emplacements ...",
      "search-message": "Tapez pour rechercher des emplacements"
    },
    "cl-selection-dialog": {
      "cancel": "Annuler",
      "default-title-multiple": "Sélectionnez plusieurs options",
      "default-title-single": "Sélectionner une option",
      "empty": "Pas d'objet.",
      "loading": "Chargement...",
      "select": "Sélectionner"
    },
    "cl-session-scheduler-dialog": {
      "attendees": "Participants",
      "cancel-button": "Annuler",
      "confirm-button": "Confirmer le calendrier",
      "details": "Détails",
      "hours": "heures",
      "minutes": "minutes",
      "schedule": "Programmer"
    },
    "cl-sidenav": {
      "close": "Proche",
      "open": "Ouvrir"
    },
    "cl-slot-picker": {
      "force-schedule-helper": "Cela remplacera les heures occupées sur les calendriers synchronisés et les paramètres de disponibilité standard.",
      "force-schedule-label": "Choisissez l'heure",
      "no-slots": "Aucun emplacement disponible."
    },
    "cl-table": {
      "back": "Retour",
      "columns": "Colonnes",
      "columns-dialog-title": "Personnaliser les colonnes",
      "create": "Créer un nouveau",
      "export": "Exporter",
      "export-csv": "CSV",
      "export-excel": "Excel",
      "export-pdf": "PDF",
      "next": "Prochain",
      "page-info": "({pageStart}-{pageEnd} sur {totalRows})",
      "reset-columns": "Réinitialiser les colonnes",
      "rows-per-page": "Lignes par page",
      "search": "Chercher",
      "update": "Mise à jour"
    },
    "cl-truncate": {
      "read-more": "Lire la suite",
      "show-less": "Montrer moins"
    },
    "client": {
      "account-contact": {
        "no-admin-match": "The email doesn't match any contact email for this client."
      },
      "add-admin-dialog": {
        "admin-added": "Admin a été ajouté.",
        "admin-already-added": "Cet administrateur a déjà été ajouté."
      },
      "client-action": {
        "approve-coach": "Approve coach",
        "approving-coach-title": "About this coach",
        "coach-name": "{coachType}'s name.",
        "enter-the-coach-name": "Enter the {coachType}'s name.",
        "independent-coach": "Independent coach",
        "independent-coach-description": "An independent coach, contracted directly.",
        "internal-coach": "Internal coach",
        "internal-coach-description": "An employee of your company who provides coaching services.",
        "send-invitation-description": "You can add this person and invite later.",
        "send-invitation-optional": "Send invitation?"
      },
      "client-contracts": {
        "active": "Active",
        "coaching-relationship": "{coach} coaching {coachee}",
        "contracts": "Client contracts",
        "no-contracts": "No contracts found.",
        "pending": "Pending",
        "vendor-contracts": "Vendor contracts"
      },
      "client-locations": {
        "assign-location": "Attribuer un emplacement",
        "description": {
          "client": "Restreignez l'accès administrateur aux données de ce client en attribuant des emplacements en fonction de votre structure organisationnelle.",
          "vendor": "Restreignez l'accès administrateur aux données de ce fournisseur en attribuant des emplacements en fonction de votre structure organisationnelle."
        },
        "edit-locations": "Modifier les emplacements",
        "locations-updated": "Emplacements des clients mis à jour",
        "no-locations-added": "Aucun emplacement n'a encore été ajouté",
        "no-locations-helper": {
          "client": "Les données client sont visibles pour tous les administrateurs de sites si aucun emplacement n'est sélectionné.",
          "vendor": "Les données du fournisseur sont visibles pour tous les administrateurs de sites si aucun emplacement n'est sélectionné."
        },
        "title": "Gérer la visibilité"
      }
    },
    "clients": {
      "about": "Sur",
      "add-client": "Ajouter un client",
      "client-type": "Type de client",
      "coaches": {
        "status": {
          "active": "Approuvé",
          "pending": "En attente d'approbation"
        }
      },
      "companies": {
        "add-dialog-title": "Ajouter une société cliente",
        "description": "Entreprises clientes avec lesquelles vous travaillez.",
        "empty-state": "Aucune entreprise client trouvée.",
        "name": "Entreprise",
        "title": "Entreprises clientes"
      },
      "create-workflow": {
        "account-name-label": "Nom de la entreprise",
        "email-label": "Email de contact",
        "first-name-label": "Prénom de contact",
        "last-name-label": "Nom de famille de contact",
        "title": "Créer un client"
      },
      "description": "Liste des clients avec lesquels vous travaillez.",
      "direct": {
        "add-dialog-title": "Ajouter un coachee direct",
        "description": "Les coachés qui ne sont pas sponsorisés par une entreprise cliente.",
        "empty-state": "Aucun coachee direct trouvé.",
        "name": "Client individuel",
        "title": "Coachés directs"
      },
      "glance": "En un coup d'oeil",
      "individual": "Individuel",
      "loading": "Chargement des clients...",
      "no-clients": "Aucun client",
      "no-coachees": "Pas de coachees",
      "no-coaches": "Pas de coaches",
      "search-placeholder": "Chercher des clients",
      "sessions": {
        "no-past": "Aucune session passée.",
        "no-upcoming": "Aucune session à venir",
        "past": "Passé",
        "upcoming": "Prochain",
        "upcoming-sessions": "Sessions à venir"
      },
      "stats": {
        "active-engagements": "Active Engagements",
        "completed-engagements": "Engagements Terminés",
        "total-engagements": "Total des Engagements"
      },
      "table-header": {
        "account-status": "Statut du compte",
        "active-engagements": "Engagements Actifs",
        "approval-status": "Statut Approuvé",
        "client-type": "Type de client",
        "completed-engagements": "Engagements Terminés",
        "connection-status": "Statut de Connexion",
        "contact-email": "Email du Contact",
        "contact-phone": "Numéro de Contact",
        "date-added": "Date Ajoutée",
        "draft-engagements": "Engagements Brouillons",
        "email": "Email",
        "name": "Nom",
        "phone": "Téléphone",
        "primary-contact": "Premier Contact",
        "status": "Statut",
        "user-status": "Statut de l'utilisateur"
      },
      "title": "Clients",
      "type": {
        "all": "Tout",
        "company": "Entreprise",
        "direct_coachee": "Individuel"
      }
    },
    "coach": {
      "about": "Sur",
      "city-label": "Ville",
      "contact-label": "Informations de contact",
      "disconnect": "Déconnecter",
      "education-degree-saved": "Diplôme d'études sauvé",
      "email-label": "Email",
      "engagement-log": "<strong>{coach}</strong> started an <a href=\"/engagements/{engagementId}\">engagement</a> with <a href=\"/employees/{coacheeId}\">{coachee}</a>.",
      "engagements": "Engagements",
      "engagements-tab": "Engagements",
      "internal-label": "Coach interne",
      "invite": "Inviter",
      "invoices": "Factures",
      "invoices-tab": "Factures",
      "new-engagement-button": "Nouvel engagement",
      "no-coaching-experience-added": "Vous n'avez pas encore ajouté d'expérience de coaching.",
      "no-external-profiles": "Pas de profils externes",
      "overview-label": "Aperçu",
      "phone-label": "Téléphone",
      "profiles": "Profils",
      "specialities-label": "Spécialités",
      "summary": "Résumé",
      "timeline-label": "Chronologie",
      "years": "Années"
    },
    "coach-pools": {
      "active-engagements": "Engagements actifs: {engagements}",
      "add-a-description": "ajouter une description",
      "add-coach-pool": {
        "description": "Ajouter des pools d'entraîneurs au processus de correspondance.",
        "title": "Ajouter un pool d'entraîneurs"
      },
      "add-coachees": "Ajouter des coachés",
      "add-coaches": "Ajouter des entraîneurs",
      "add-coaches-to-matching-process": "Ajouter des entraîneurs au processus de correspondance",
      "add-individual-coaches": {
        "description": "Sélectionnez des entraîneurs spécifiques à ajouter au processus de correspondance.",
        "title": "Ajouter des entraîneurs individuels"
      },
      "add-new-coach": "Ajouter un entraîneur",
      "add-new-coachee": "Ajouter un coaché",
      "add-new-share": "Ajouter un nouveau partage",
      "assign-a-coach": {
        "description": "Ignorez la correspondance et assignez un coach directement à partir de votre répertoire.",
        "title": "Assigner un coach"
      },
      "coach-pools": "Piscines d'entraîneur",
      "coach-removed": "L'entraîneur a été retiré de la piscine.",
      "coach-removed-from-matching": "Entraîneur retiré de la correspondance.",
      "coach-selection": "Sélection des entraîneurs",
      "coachee-removed": "Coaché ​​retiré de la piscine.",
      "coachees": "Les coachés",
      "coachees-added": "Les coachés ont été ajoutés avec succès.",
      "coaches": "Les entraîneurs",
      "coaches-added": "Entraîneurs ajoutés avec succès.",
      "confirm-coach-interview": "Cliquez sur le bouton \"Demander\" ci-dessous pour lancer une demande de <strong>{title}</strong> auprès de ce coach. Si vous avez des jours ou des heures préférés, veuillez en informer l'entraîneur en utilisant la zone de texte ci-dessous.",
      "create-pool": "Créer un pool",
      "delete-pool-warning": "Supprimer définitivement le pool d'entraîneurs. Cela affecte les jumelages d'entraîneurs en cours impliquant ce groupe. Vous ne pouvez pas annuler cette action.",
      "description": "La description",
      "error-saving-the-pool": "Erreur lors de l'enregistrement du pool.",
      "error-starting-matching-process": "Erreur lors du démarrage du processus de correspondance.",
      "individuals": "Personnes",
      "members-count": "{members} membres",
      "name": "prénom",
      "no-coachees-found": "Aucun coaché ​​trouvé",
      "no-coaches-found": "Aucun entraîneur trouvé",
      "no-coaches-selected": "Aucun entraîneur sélectionné.",
      "no-pools": "Aucune piscine n'a encore été créée.",
      "no-pools-found": "Aucune piscine trouvée",
      "pool-deleted": "Pool supprimé.",
      "pool-name": "Nom de la piscine",
      "pool-saved": "Piscine sauvegardée.",
      "pool-updated": "Piscine mise à jour.",
      "pools": "Les piscines",
      "remove-coachees": "Retirer les coachés",
      "remove-coachees-dialog-warning": "Cette action supprimera tous les coachés sélectionnés du pool. <strong>Es-tu sur de vouloir continuer?</strong>",
      "request": "Demander",
      "search-for-coach": "Recherche coach",
      "search-for-coachee": "Recherche coachee",
      "search-for-coaches": "Recherche d'entraîneurs",
      "search-for-contract": "Recherche de contrat",
      "search-for-pools": "Recherche de pools d'entraîneurs",
      "search-for-shares": "Rechercher des actions",
      "search-for-template": "Rechercher un modèle d'engagement",
      "select-all": "Tout sélectionner",
      "select-coachees": "Sélectionner des coachés",
      "select-coaches": "Sélectionnez les entraîneurs",
      "send-request": "Envoyer une demande",
      "settings": "Réglages",
      "share-matching-status": {
        "engagement-started-on": "L'engagement a commencé on {date}",
        "matching-completed-on": "Matching terminé en {date}",
        "matching-ended-on": "Le jumelage s'est terminé en {date}",
        "matching-started-on": "Le jumelage a commencé en {date}",
        "not-yet-started": "Matching pas encore commencé"
      },
      "shares": "Actions",
      "shares-removed": "Actions supprimées",
      "start-matching-process": "Démarrer le processus d'appariement",
      "start-matching-process-dialog-warning": "Cela lancera le processus de correspondance pour les <strong>{selectedCoachees}</strong> coachés. <br/> Êtes-vous sur de vouloir continuer?",
      "untitled-coach-pool": "Pool d'entraîneurs sans titre"
    },
    "coaches": {
      "active-coaches": "Entraîneurs Actifs",
      "add": "Ajouter",
      "add-coach": "Ajouter un entraîneur",
      "add-coach-or-vendor": "Ajouter un coach ou un vendeur",
      "add-coach-pool": "Ajouter un Groupe d'Entraîneurs",
      "add-to-pool": "Ajouter au groupement",
      "admin-approval": "Un administrateur sera notifié et demandé pour approbation.",
      "all-coaches": "Tous les Entraîneurs",
      "approve-selection": "Approuver la sélection",
      "approved-coaches": "Entraîneurs Approuvés",
      "assign-coach": "Attribuer un entraîneur",
      "automatic": "L'engagement commencera après la sélection.",
      "clear": "Nette",
      "client-disconnect": "Disconnected.",
      "coach-pool-created": "Le pool d'entraîneurs a été créé avec succès",
      "coach-pool-settings": "Paramètres du pool d'entraîneurs",
      "coach-pools": "Groupes d'Entraîneurs",
      "coaches": "Entraîneurs",
      "coaches-list": "Liste des entraîneurs",
      "coaching-vendors": "Fournisseurs d'Entraîneurs",
      "confirm-coach": "Êtes-vous sûr de vouloir choisir cet entraîneur? Cette action ne peut pas être annulée",
      "create-new-pool": "Créer un nouveau groupe",
      "create-pool": "Créer un groupe",
      "create-pool-button": "Créer Groupe",
      "create-workflow": {
        "account-name-label": "Nom de la entreprise",
        "coach-type-step": {
          "prompt": "Quel type d'coach ajoutez-vous?",
          "title": "Créer un fournisseur"
        },
        "create-pool-button": "Créer Groupe",
        "email-label": "Email de contact",
        "employee-invite-button": "Inviter un nouvel coach",
        "employee-invite-prompt": "Remplissez ce formulaire pour inviter un nouvel employé.",
        "employee-invite-title": "Ajouter quelqu'un de nouveau?",
        "employee-search-no-results": "Nous n'avons trouvé personne correspondant \"{search}\"",
        "employee-search-placeholder": "Entrez le nom ou l'adresse email",
        "external-coach-description": "Un coach qui est sous contrat pour fournir des services de coaching.",
        "external-coach-label": "Coach indépendant",
        "first-name-label": "Prénom de contact",
        "internal-coach-description": "Un coach qui est un employé de votre entreprise.",
        "internal-coach-label": "Les coaches internes",
        "last-name-label": "Nom de famille de contact"
      },
      "decline-selection": "Refuser la sélection",
      "decline-selection-warning": "Êtes-vous sûr de vouloir refuser cette demande de coaching?",
      "deselect-all": "Tout déselectionner",
      "edit-pool": "Modifier le groupe d'entraîneurs",
      "edit-pool-settings": "Réglages",
      "employee-removed": "Employé enlevé.",
      "error-removing-employee": "Problème de retrait d'un employé.",
      "error-updating-request": "Erreur lors de la mise à jour de la demande.",
      "existing-pool": "Groupe existant",
      "filter-labels": {
        "coaching-type": "Type de coaching",
        "credentials": "Lettres de créance",
        "experience": "Expérience",
        "gender": "Le sexe",
        "interview-status": "État de l'entretien",
        "interview-statuses": {
          "interview-approved": "Approuvé",
          "interview-completed": "Terminé",
          "interview-scheduled": "Prévu",
          "sent": "Demandé"
        },
        "leader-levels": "Niveaux de leader",
        "location": "Emplacement",
        "modalities": "Les modalités",
        "specialities": "Spécialités"
      },
      "filters": "Filtres",
      "individuals": "Personnes",
      "internal": "Interne",
      "invite-coaches": "Inviter des coaches",
      "loading": "Chargement...",
      "member": "Membre",
      "members": "Membres",
      "new-pool": "Nouveau groupe",
      "no-coach": "Pas d'entraîneurs.",
      "no-coach-pools": "Aucun pool de coach trouvé.",
      "no-coaches": "Aucun coach trouvé.",
      "pending-approval": "Validation En Attente",
      "phases": {
        "closing": "Phase de fermeture",
        "initial": "Phase initiale",
        "mid": "Phase intermédiaire"
      },
      "pools": "Le groupement",
      "remove-coach": "Supprimer l'entraîneur",
      "request-interview": "Demander un entretien",
      "request-interview-confirmation": "Êtes-vous sûr de vouloir demander une interview avec {coachName}?",
      "request-interview-message": "Bonjour {coachName},\n{coacheeName} a demandé une interview correspondante avec vous. Une fois que vous coordonnez une heure, planifiez-la ici.",
      "request-interview-message-html": "Bonjour {coachName},<br><br>\n<strong>{coacheeName}</strong> a demandé une interview correspondante avec vous. Une fois que vous coordonnez une heure, planifiez-la ici.",
      "request-interview-message-html-coachee": "Vous avez demandé une interview de mise en correspondance avec <strong>{coachName}</strong>.<br>\nUne fois que <strong>{coachName}</strong> a planifié une heure, elle apparaîtra ici.",
      "request-interview-message-html-other": "<strong>{coacheeName}</strong> a demandé une interview correspondante avec <strong>{coachName}</strong>.\nUne fois que <strong>{coachName}</strong> a planifié une heure, elle apparaîtra ici.",
      "reschedule-interview": "Reprogrammer l'entretien",
      "resend-request": "Renvoyer la demande",
      "schedule-interview": "Entretien de planification",
      "search-by-name-or-email": "Recherche par nom ou par email",
      "search-placeholder": "Rechercher les coaches",
      "select": "Sélectionner",
      "select-all": "Tout sélectionner",
      "select-coach": "Sélectionner un entraîneur",
      "show-messages": "Afficher les messages",
      "showing-page": "Affichage de {start} à {end} de {total} entrées",
      "showing-page-coachee": "({start} - {end} de {total})",
      "start-conversation": "Envoyer le message",
      "start-engagement": "Commencer l'engagement",
      "table-header": {
        "name": "Nom",
        "status": "Statut"
      },
      "tabs": {
        "internal": "Les coaches internes",
        "vendors": "Les vendeurs externes"
      },
      "title": "Entraîneur",
      "vendor-search-placeholder": "Rechercher vendeurs",
      "view-profile": "Voir le profil"
    },
    "coachlogix-terms": {
      "account-status": {
        "internal_record": "Registre interne"
      },
      "account-type": "Type de Compagnie",
      "account-types": {
        "business": "Compagnie",
        "enterprise": "Enterprise",
        "independent-coach": "Coach indépendant"
      },
      "client": "client",
      "coach": "Coach",
      "coach-interview": "Entretien avec l'entraîneur",
      "coachee": "Coachee",
      "coaching": "encadrement",
      "coachlogix": "Coaching.com",
      "contract": "Contrat",
      "engagement-session": "Session",
      "engagements": "Engagements",
      "independent-coach": "Coach indépendant",
      "internal-coach": "Coach interne",
      "internal-employee": "Employé interne",
      "session-date": "Date de la Session",
      "statuses": {
        "account": {
          "active": "actif",
          "inactive": "Inactif",
          "invited": "Invité",
          "pending": "Invité",
          "suspended": "actif",
          "trial": "actif",
          "unclaimed": "Inactif"
        },
        "client": {
          "active": "Actif",
          "approval_needed": "En Attente d'Approbation",
          "approved": "Connecté",
          "client_approval_needed": "Approbation du client requise",
          "coaches": {
            "active": "Actif",
            "coachees": {
              "total": "Total des coachés"
            },
            "inactive": "Inactif",
            "invited": "Invitation Envoyée",
            "total": "Total des entraîneurs"
          },
          "internal_record": "Enregistrement Interne",
          "invited": "Invitation Envoyée",
          "not_applicable": "n'est pas applicable",
          "pending": "Invitation Envoyée",
          "request_sent": "Demande envoyée"
        },
        "coaching-request": {
          "coach_approved": "Approuvé",
          "coach_declined": "Diminué",
          "coach_selected": "Choisi",
          "interview_approved": "Entretien approuvé",
          "interview_canceled": "Entretien annulé",
          "interview_completed": "Entretien terminé",
          "interview_declined": "Entretien refusé",
          "interview_scheduled": "Entretien prévu",
          "interview_times_requested": "Heures d'entrevue demandées",
          "interview_times_sent": "Heures d'interview envoyées",
          "no_request": "Pas encore demandé"
        },
        "contact": {
          "approved": "Approuvé",
          "client_approval_needed": "Approbation du client requise",
          "declined": "Diminué",
          "internal_record": "Enregistrement interne",
          "invited": "Invitation envoyée",
          "managed_by_vendor": "Géré par le vendeur",
          "not_applicable": "N'est pas applicable",
          "pending": "Demande envoyée",
          "request_sent": "Demande envoyée",
          "vendor_approval_needed": "Approbation du vendeur nécessaire",
          "your_approval_needed": "Votre approbation nécessaire"
        },
        "contract": {
          "active": "actif",
          "declined": "Diminué",
          "draft": "Brouillon",
          "sent": "Envoyé"
        },
        "employee": {
          "active": "Actif",
          "connected": "Actif",
          "disconnected": "Inactif",
          "internal_record": "Enregistrement Interne",
          "invited": "Invitation Envoyée",
          "owned": "Stakeholder",
          "pending": "Inactif",
          "suspended": "Active",
          "trial": "Inactive",
          "unclaimed": "Inactive"
        },
        "engagement": {
          "active": "Actif",
          "archived": "Supprimé",
          "coach_selection": "Correspondant à",
          "completed": "Terminé",
          "draft": "Brouillon",
          "draft_coach_selection": "Projet correspondant",
          "inactive": "Inactif"
        },
        "invoice": {
          "client_marked_paid": "Client marqué payé",
          "client_marked_partial_paid": "Client marqué partiellement payé",
          "draft": "Brouillon",
          "paid": "Payé",
          "partial_paid": "Partiellement payé",
          "past_due": "En retard",
          "sent": "Envoyé"
        },
        "matching-process": {
          "completed": "Matching terminé",
          "started": "Matching a commencé",
          "unstarted": "Correspondant non démarré"
        },
        "payment": {
          "completed": "Approuvé",
          "failed": "Diminué",
          "pending_approval": "En attente d'approbation",
          "vendor_declined": "Declined"
        },
        "user": {
          "offline": "Offline",
          "online": "Online"
        }
      },
      "statuses-helpers": {
        "coaching-request": {
          "coach_approved": "C'était l'entraîneur sélectionné pour cet engagement!",
          "coach_declined": "Cette sélection d'entraîneur a été refusée",
          "coach_selected": "En attente de l'approbation de la sélection des entraîneurs",
          "interview_approved": "Entretien peut maintenant être programmé",
          "interview_canceled": "Votre entretien prévu a été annulé",
          "interview_completed": "L'interview était terminée. Vous pouvez choisir cet entraîneur.",
          "interview_completed_admin": "L'interview s'est terminée le {completedDate}",
          "interview_declined": "La demande d'entrevue a été refusée",
          "interview_scheduled": "L'interview était prévu pour {scheduledDate}",
          "sent": "En attente de l'interview"
        }
      },
      "vendor": "vendeur"
    },
    "companies": {
      "create-dialog": {
        "cancel": "Annuler",
        "next": "Prochain",
        "step1": {
          "label": "Vous ajoutez une connexion interne ou externe?"
        },
        "title": "Créer"
      },
      "delete-tooltip": "Effacer",
      "loading": "Chargement...",
      "search": "Rechercher les Compagnie",
      "table": {
        "headers": {
          "actions": "Actes",
          "address": "Adresse",
          "name": "Nom"
        },
        "status": {
          "connected": "Connecté",
          "internalRecord": "Enregistrement interne",
          "invited": "Invité"
        }
      },
      "title": "Entreprises"
    },
    "company": {
      "coaches": "Entraîneurs",
      "index": {
        "about": "Sur",
        "details": "Détails",
        "no-about": "Aucune information sur nous n'a encore été fournie par le client.",
        "no-details": "Aucun détail n'a encore été fourni par le client."
      },
      "people": {
        "table": {
          "headers": {
            "actions": "Actes",
            "address": "Adresse",
            "name": "Nom"
          }
        }
      },
      "subtitle": "Entreprise",
      "tabs": {
        "coachees": "Coachees",
        "coaches": "Entraîneurs",
        "details": "Détails",
        "engagements": "Engagements",
        "invoices": "Factures",
        "people": "Gens",
        "profile": "Profil",
        "summary": "Résumé"
      },
      "vendors": "Vendeurs"
    },
    "company-resources": {
      "account": "Ressources de l'entreprise",
      "add": "Ajouter une ressource",
      "add-from": "Ajouter des ressources",
      "admin": "Ressources administratives",
      "copied": "Ressource copiée dans \"Mes ressources\"",
      "create-competency-library": "Créer une bibliothèque de thèmes",
      "create-document": "Créer un document",
      "create-folder": "Créer le dossier",
      "create-form": "Créer un formulaire",
      "create-link": "Créer un lien",
      "create-program-template": "Créer un modèle de programme",
      "delete-prompt": "Voulez-vous vraiment supprimer cette ressource?",
      "delete-prompt-warning": "En supprimant cette ressource, vous la supprimez également de tout engagement où cette ressource existe actuellement.",
      "delete-tooltip": "Supprimer",
      "file-created-toast": "Le fichier a été créé avec succès",
      "form-label": "Formulaire",
      "kb-label": "KB",
      "loading": "Chargement des ressources...",
      "me-label": "moi",
      "my": "Mes ressources",
      "shared": "Partagé avec moi",
      "table": {
        "headers": {
          "actions": "Actions",
          "fileSize": "Taille du fichier",
          "fileType": "Type de fichier",
          "lastModified": "Dernière modification",
          "name": "Nom",
          "owner": "Propriétaire"
        }
      },
      "title": "Resources",
      "upload-file": "Télécharger le fichier"
    },
    "competency-library": {
      "add-competency": "Ajouter des thème",
      "add-description": "Ajouter une description à cette bibliothèque de thèmes",
      "cant-edit-name": "Le nom ne peut pas être modifié car il s'agit d'une compétence globale de Coaching.com",
      "competency-removed": "Thème supprimé.",
      "competency-updated": "Thèmes mis à jour.",
      "create-new": "Créer un nouveau thème",
      "description": "La description",
      "edit-competency": "Editer le thème",
      "edit-competency-tooltip": "Editer le thème",
      "name": "prénom",
      "no-competencies": "Aucun thème ajouté pour le moment.",
      "no-competencies-found": "Aucun thème trouvé.",
      "remove-competency-tooltip": "Supprimer le thème",
      "reset-description": "Réinitialiser à la description par défaut",
      "search-competency": "Rechercher un thème",
      "theme-added-to-library": "Thème ajouté à la bibliothèque.",
      "theme-library-updated": "Bibliothèque de thèmes mise à jour."
    },
    "contact": {
      "dropdown-actions": {
        "disconnect": "Déconnecter",
        "disconnect-confirm": "Êtes-vous sûr de vouloir supprimer ce {contactType} de <strong>{accountName}</strong> ?",
        "stop-sharing": "Arrêtez le partage",
        "stop-sharing-confirm": "Êtes-vous sûr de vouloir arrêter de partager ce {contactType} avec <strong>{accountName}</strong> ?"
      },
      "info": "Informations de contact",
      "phone": "Téléphone",
      "phone-abbr": "P:"
    },
    "contracts": {
      "allow-expenses-options": {
        "approval_needed": "Oui, mais l'approbation est nécessaire",
        "no": "Non",
        "yes": "Oui"
      },
      "billing-approaches": {
        "fixed_contract_amount": "Montant fixé",
        "flat": "Par session",
        "hourly": "Par heure",
        "retainer": "Retenue"
      },
      "billing-approaches-amounts": {
        "fixed_contract_amount": "Coût total",
        "flat": "Montant par session",
        "hourly": "Montant par heure",
        "retainer": "Montant facturé à chaque période facturable"
      },
      "client": {
        "add-contract": "Ajouter un contrat"
      },
      "contract-duration-interval": {
        "engagement_sessions": "Séances d'engagement",
        "hours_billed": "Heures facturées",
        "months": "Mensuel",
        "sessions_completed": "Sessions",
        "weeks": "Hebdomadaire"
      },
      "contract-duration-type": {
        "days": "Journées",
        "months": "Mois",
        "sessions_completed": "Sessions terminées",
        "weeks": "Semaines"
      },
      "create-dialog": {
        "billing-metric-choice": "Quelle métrique de facturation souhaitez-vous utiliser?",
        "contract": {
          "name": "Comment s'appelle ce contrat?",
          "name-hint": "Donnez un nom à ce contrat.",
          "title": "Créer un contrat"
        },
        "contract-duration": "Souhaitez-vous que ce contrat se termine après une durée déterminée?",
        "contract-template": {
          "name": "Comment s'appelle ce modèle de contrat?",
          "name-hint": "Donnez un nom à ce modèle de contrat.",
          "title": "Créer un modèle de contrat"
        },
        "create-new": "Créer un nouveau contrat",
        "no-templates": "Aucun modèle de contrat n'a été trouvé.",
        "payment-schedule": "Souhaitez-vous que les factures soient générées automatiquement?",
        "select-template": "Choisir un modèle de contrat",
        "summary": {
          "billing-metric": "La métrique de facturation utilisée sera <strong>{billingMetric}</strong> .",
          "contract-duration": "Le contrat prendra fin après <strong>{contractDuration}</strong> .",
          "name": "Ce contrat s&#39;appellera <strong>{name}</strong> .",
          "payment-schedule": "Les paiements seront programmés en utilisant <strong>&quot;{paymentSchedule}&quot;</strong> ."
        }
      },
      "dialog": {
        "amount-placeholder": "Entrer le montant",
        "billing-metric": "Statistique de facturation",
        "billing-metric-placeholder": "Veuillez sélectionner une statistique de facturation",
        "generate-automatic-invoices": "Générer des factures automatiquement",
        "info-separator": {
          "contract": "Informations sur le contrat",
          "contract-template": "Informations sur le modèle de contrat"
        },
        "payment-schedule": "Calendrier de paiement",
        "read-only-title": "Contrat",
        "steps": "Étape {n} sur {total}",
        "success": {
          "contract": "Contrat enregistré avec succès.",
          "contract-template": "Le modèle de contrat a bien été enregistré."
        }
      },
      "duration-type": {
        "each-month": "Même jour chaque mois",
        "hours_billed": "Après {value, plural, =1 {one hour billed} other {# hours billed}}",
        "months": "Chaque {value, plural, =1 {month} other {# months}}",
        "sessions_completed": "Après {value, plural, =1 {one session} other {# sessions}}",
        "weeks": "Chaque {value, plural, =1 {week} other {# weeks}}"
      },
      "editor": {
        "after": "après",
        "approve-success": "Contrat approuvé avec succès.",
        "auto-gen-invoice": "Générer des factures automatiquement",
        "auto-invoicing-helper": "Si cette option n'est pas sélectionnée, vous serez averti lorsqu'il sera temps de créer la facture en fonction de votre calendrier de paiement.",
        "auto-invoicing-prompt": "Envoyer des factures automatiquement en fonction du calendrier de paiement ci-dessus",
        "billing-metric": "Métrique de facturation",
        "contract": {
          "title": "Modifier le contrat"
        },
        "contract-duration": "Je voudrais que ce contrat se termine après une durée déterminée",
        "contract-template": {
          "title": "Modifier le modèle de contrat"
        },
        "day_of_every": "jour de chaque",
        "decline-success": "Contrat refusé avec succès.",
        "delete-confirmation": "Ce contrat est actuellement utilisé. Le supprimer le retirera de tous les engagements. Êtes-vous sur de vouloir continuer?",
        "delete-success": "Contrat supprimé avec succès",
        "generate-invoices": "Générer des factures automatiquement",
        "hours": "heures)",
        "months": "mois)",
        "name": "prénom",
        "no-edit": "Vous n'avez pas les autorisations d'édition pour ce contrat.",
        "no-edit-allowed": "Vous n'avez pas les autorisations d'édition pour ce contrat.",
        "of_every": "de chaque",
        "on": "sur",
        "on_the": "sur le",
        "pay-via-stripe": "Collecter le paiement via Stripe",
        "payment-schedule": "Calendrier de paiement",
        "payment-schedule-input": {
          "after": "après",
          "day-every": "jour chaque",
          "every": "chaque",
          "hours": "heures)",
          "months": "mois)",
          "on": "sur",
          "on-the": "sur le",
          "sessions": "session (s)",
          "weeks": "semaines)"
        },
        "save-success": "Contrat enregistré avec succès.",
        "sent-success": "Contrat envoyé avec succès.",
        "sessions": "session (s)",
        "terms": "termes",
        "weeks": "semaines)"
      },
      "ordinal-numbers": "{number, selectordinal, one {#st} two {#nd} few {#rd} other {#th} =-1 {Last} }",
      "summary": {
        "amount": "Montant",
        "interval": "Intervalle",
        "invoice_automatically": "Les factures seront envoyées automatiquement.",
        "metric": "Métrique",
        "name": "Nom du contrat",
        "payment": "Un lien de paiement par carte de crédit sera inclus."
      }
    },
    "ctas": {
      "add": "Ajouter",
      "add-admin": "Ajouter un Administrateur",
      "add-editor": "Ajouter un éditeur",
      "add-manager": "Ajouter Directeur",
      "add-poc": "Ajouter Point de Contact",
      "add-resource": "Ajouter une Ressource",
      "add-vendor-contact": "Ajouter un Contact Fournisseur",
      "add-viewer": "Ajouter Spectateur",
      "assign-coach": "Attribuer Coach",
      "assign-coachee": "Attribuer Coachee",
      "confirm-delete-coachee": "Êtes-vous sûr de vouloir supprimer ce coaché?",
      "create": "Créer",
      "delete": "Effacer",
      "request-coach": {
        "add": "Ajouter un Coach",
        "request": "Demander un Coach"
      },
      "request-coachee": {
        "add": "Ajouter Coachee",
        "request": "Demander Coachee"
      },
      "share-coach": {
        "active": "Partager l''Coach",
        "inactive": "Ajouter un entraîneur",
        "internal_record": "Ajouter un Coach",
        "invited": "Partager l'Coach",
        "pending": "Ajouter un Coach"
      },
      "share-coachee": {
        "active": "Partagez Coachee",
        "inactive": "Ajouter un coaché",
        "internal_record": "Ajouter Coachee",
        "invited": "Partager Coachee",
        "pending": "Ajouter Coachee"
      },
      "submit": "Soumettre"
    },
    "custom-notifications": {
      "label": "{count, plural, =0 {Pas de notification personnalisée} other {Notification personnalisée activée} }"
    },
    "dashboard": {
      "activity-feed": {
        "count": "{numActivities, plural, =0 {no activities} =1 {one activity} other {# activities}}",
        "no-data-available": "Pas de données disponibles",
        "no-more-records": "Plus de records",
        "title": "Flux d'activité"
      },
      "billable-activity": {
        "assignments": "Affectation",
        "coached-by": "{coachee} coaché ​​par {coach}",
        "create-invoice": "Créer une facture",
        "create-new-invoice": "Créer une nouvelle facture",
        "description": "Créez et envoyez des factures pour des engagements avec une activité facturable.",
        "empty-state": "Aucune activité facturable.",
        "engagement-sessions": "Session",
        "errors": {
          "billable-invoiced": "Cette session a déjà été facturée."
        },
        "expenses": "Les dépenses",
        "review-invoice": "Révisez et envoyez.",
        "title": "Activité facturable",
        "view-invoice": "Voir la facture"
      },
      "compentencies": {
        "add-skills": "Ajouter des compétences",
        "find-coach": "Trouvez un coach qui vous convient",
        "first-engagement": "Créez votre premier engagement",
        "first-goal": "En savoir plus sur et définir votre premier objectif",
        "plan-guide": "Guide du plan",
        "replay": "Regarder Replay",
        "right-arrow": "›",
        "select-plan": "Sélectionnez le plan qui vous convient",
        "sign-up": "Inscrivez-vous à notre webinaire gratuit en direct",
        "skillsets": "Dites-nous quelles compétences vous souhaitez améliorer",
        "start-sso": "Commencez votre implémentation SSO",
        "tutorial": "Lire le tutoriel"
      },
      "interviews": {
        "actions": {
          "cancel": "Annuler l'entretien",
          "complete": "Entretien complet",
          "reschedule": "Reprogrammer l'entretien",
          "schedule": "Entretien de planification",
          "see-suggested-times": "Voir les horaires suggérés",
          "suggest-times": "Suggérer des horaires"
        },
        "completed-interview": "Entretien terminé avec {coacheeName}",
        "empty-state": "Aucune demande d'entrevue",
        "interview-scheduled": "Interview avec {coacheeName}",
        "interview-with": "Nouvelle demande d'interview de {coacheeName}",
        "no-scheduled": "{coacheeName} de {accountName} aimerait vous interviewer.",
        "requests": "Demandes",
        "scheduled": "Prévu",
        "statuses": {
          "cancelled": "Annulé",
          "completed": "Complété",
          "requested": "Demandé",
          "scheduled": "Programmé"
        },
        "times-dialog": {
          "coachee-comment": "Le coachee a ajouté un commentaire pour votre considération",
          "declined-suggestions": "Suggestions refusées",
          "declined-suggestions-helper": "Il y a <strong>{num}</strong> suggestions de temps précédemment refusées",
          "declined-time-error": "Ce temps a été précédemment suggéré. Veuillez en sélectionner un autre.",
          "interview-date": "Date de l'entrevue",
          "select-date": "Sélectionnez une date",
          "select-time": "Sélectionnez une heure",
          "suggestion-number": "Suggestion #{index}",
          "title": "Suggérer une heure d'entrevue"
        },
        "title": "Entretiens Correspondants"
      },
      "invoices": {
        "coach": "Entraîneur: {coachName}",
        "currency-sign": "$",
        "description": "Factures que vous avez reçues nécessitant un paiement ou une mise à jour du statut.",
        "empty-state": "Aucune facture récente.",
        "general-title": "Factures",
        "no-invoices": "Aucune facture.",
        "title": "Factures récentes"
      },
      "sessions": {
        "no-past": "Aucune session passée.",
        "no-upcoming": "Aucune session à venir.",
        "past": "Passé",
        "upcoming": "Prochain"
      },
      "sessions-module": {
        "empty-state": "Aucune session trouvée",
        "interview-with": "Entretien avec {coacheeName}",
        "no-location": "Aucun lieu de réunion défini.",
        "title": "Sessions planifiées"
      },
      "stats": "Statistiques",
      "tasks": {},
      "tasks-module": {
        "all": "Tout",
        "all-open": "Tous ouverts",
        "dismiss": "Rejeter",
        "feedback-request": "Votre avis est demandé.",
        "feedback-request-completed-on": "Commentaires envoyés le {date}.",
        "feedback-request-from": "Commentaires demandés de {username}.",
        "file-completed-on": "Tâche de fichier terminée le {date}.",
        "file-review-request": "Vous avez un fichier à réviser.",
        "file-review-request-from": "{username} a un fichier à réviser.",
        "fill-out-form": "Remplir le formulaire",
        "form-completed-on": "Formulaire soumis le {date}.",
        "form-fill-request": "Vous avez reçu un formulaire à remplir.",
        "form-fill-request-from": "{username} a un formulaire à remplir.",
        "mark-completed": "Mark terminé",
        "mine": "Mien",
        "open-file": "Fichier ouvert",
        "resend-form": "Renvoyer le formulaire",
        "showing": "Montrant:",
        "task-completed-on": "Tâche terminée le {date}.",
        "task-request": "Vous avez une tâche à accomplir.",
        "task-request-from": "{username} a une tâche à accomplir.",
        "title": "Tâches"
      },
      "title": "Tableau de bord",
      "welcome": "Bienvenue,",
      "widget": {
        "accounting-widget": "Comptabilité",
        "coaches": "Entraîneurs",
        "engagements": "Engagements",
        "feedback": "Commentaires des intervenants",
        "impact": "Top 5 Objectifs",
        "invoices": "Factures",
        "progress": "Progrès",
        "sessions": "Sessions planifiées"
      }
    },
    "domain": {
      "account": "",
      "account-billing-info": "",
      "account-customization": "",
      "account-payment-gateway": "",
      "account-stripe-card": "",
      "activity": "",
      "assignment": "",
      "association": "",
      "base-model": "",
      "base-program": "",
      "base-resource": "",
      "billing-approach": "Approche de facturation",
      "billing-settings": "",
      "business-unit": "",
      "chat": "Bavarder",
      "client": "",
      "coach": "",
      "coaching-experience": "",
      "coaching-inquiry": "",
      "coaching-inquiry-message": "",
      "company": "",
      "contract": "",
      "country": "",
      "credit": "",
      "cronofy-connected-account": "",
      "education-degree": "Éducation",
      "email-customization": "",
      "employee": "",
      "engagement": "Engagement",
      "engagement-coaching-request": "Entrevue",
      "engagement-comment": "",
      "engagement-milestone": "",
      "engagement-note": "Remarque",
      "engagement-resource": "",
      "engagement-review": "",
      "engagement-session": "Session",
      "engagement-session-participant": "",
      "engagement-task": "Tâche",
      "file": "",
      "file-resource": "",
      "form": "",
      "form-question": "",
      "gateway-type": "",
      "goal": "Goal",
      "goal-approach": "",
      "interaction": "",
      "invoice": "",
      "invoice-design": "",
      "invoice-item": "",
      "library-resource": "",
      "location": "",
      "me": "",
      "message": "",
      "message-history": "",
      "message-recipient": "",
      "milestone": "",
      "note": "",
      "notification": "",
      "organization": "",
      "payment": "",
      "payment-gateway": "",
      "payment-library": "",
      "payment-method": "",
      "payment-status": "",
      "payment-term": "",
      "pending-action": "",
      "permission": "",
      "permission-role": "",
      "plan": "",
      "pool": "",
      "profile": "",
      "program-item": "",
      "program-resource": "",
      "program-template": "Modèle de programme",
      "programmable": "",
      "reminder": "",
      "report": "",
      "resource": "",
      "response": "",
      "role": "",
      "role-user": "",
      "roles": {
        "account-employee": "Employé",
        "account-owner": "Propriétaire du compte",
        "coachlogix-billing": "Gestionnaire de facturation"
      },
      "specialty": "",
      "status": "",
      "stripe-card": "",
      "stripe-connected-account": "",
      "subscription": "",
      "survey-resource": "",
      "task": "",
      "task-assignment": "",
      "task-grouping": "",
      "task-resource": "",
      "task-review": "",
      "task-signoff": "",
      "task-type": "",
      "theme-library": "Bibliothèque thématique",
      "user": "",
      "user-affiliation": "",
      "user-assessment": "",
      "user-certification": "",
      "user-context": "",
      "user-language": "",
      "user-publication": "",
      "user-work": "",
      "vendor": "",
      "work-experience": "L'expérience professionnelle"
    },
    "employee": {
      "account-roles": "Rôles de compte",
      "at": "à",
      "employe-actions": {
        "remove-confirmation": "Êtes-vous sûr de vouloir supprimer cet employé?"
      },
      "global-admin": "Administrateur global",
      "global-admin-permissions": "A l'autorisation de gérer tous les engagements, les coachs, les clients, les employés, les données financières des comptes et les paramètres de l'entreprise.",
      "inactivate-dialog": {
        "employee-inactivated": "Employé inactivé avec succès.",
        "employee-inactivation-failed": "Impossible d'inactiver l'employé.",
        "inactivate-confirmation": "Êtes-vous sûr de vouloir désactiver cet employé?",
        "remove-engagements": "Supprimez toutes les données, y compris les engagements."
      },
      "info": "Informations sur les employés",
      "level": "Niveau d'employé",
      "location-admin": "Administrateur d'emplacement (nécessite au moins 1 emplacement)",
      "location-admin-permissions": "A l'autorisation de gérer les engagements, les coachs, les clients, les employés, les données financières du compte et les paramètres de l'entreprise spécifiques à l'emplacement Peut accéder aux données et rapports spécifiques à l'emplacement.",
      "name": "prénom",
      "not-provided": "Non fourni",
      "provides-coaching": "Fournit du coaching",
      "send": "Envoyer un message",
      "title": "Titre d'employé"
    },
    "employees": {
      "add-employee": "Ajouter un employé",
      "loading": "Chargement des employés ...",
      "select-employee-dialog": {
        "add-admin": "Ajouter un nouvel administrateur",
        "add-employee": "Ajouter un nouvel employé",
        "assign-later": "Attribuer plus tard",
        "no-admins-found": "Aucun administrateur n'a été trouvé.",
        "no-employees-found": "Aucun employé n'a été trouvé.",
        "search": "Chercher...",
        "select-admin": "Sélectionnez admin",
        "select-employee": "Sélectionnez un employé"
      },
      "upload-employees": "Télécharger des employés"
    },
    "engagement": {
      "active-alert": "Cet engagement est actuellement actif.",
      "add-attendee": "Ajouter un participant",
      "add-by-email": "Vous ne trouvez pas quelqu'un? Ajouter par email",
      "admin-tab": "Admin",
      "archive-prompt": "Êtes-vous sûr de vouloir archiver cet engagement?",
      "capacity-coaches-warning": "Les coachs qui ont atteint leur capacité d'engagement ne seront pas présentés au coaché.",
      "coach-assign-error": "An error has occured while assigning a coach.",
      "coach-assign-success": "Coach was assigned successfully.",
      "coach-capacity-met-warning": "This coach has reached their engagement capacity. Are you sure you want to set this engagement to active?",
      "coach-capacity-met-warning-title": "Remplacement de la capacité de l'autocar",
      "coach-hasnt-started-yet": "Votre coach n'a pas encore commencé vos fiançailles.",
      "coach-label": "Coach",
      "coachee-label": "Coaché",
      "coaches-added-to-matching": "Entraîneurs ajoutés à la correspondance.",
      "complete-engagement": "Complete engagement",
      "create-engagement": "Créer un engagement",
      "create-links": "Créez des liens et attribuez-les à la révision.",
      "create-tasks": "Créer et assigner des tâches.",
      "dashboard-tab": "Aperçu",
      "delete-prompt": "Voulez-vous vraiment supprimer cet engagement?",
      "download-summary": "Download summary",
      "draft-alert": {
        "button": "Commencez à engagement",
        "description": "Cet engagement est encore un brouillon. Le coaching débutera dès qu'il sera activé.",
        "title": "Commencez à engagement"
      },
      "empty-matching-not-started": "Impossible de commencer la correspondance sans au moins une option.",
      "engagement-settings-updated": "Engagement settings updated.",
      "find-people": "Trouver des personnes par nom ou par email",
      "goals": {
        "add": "Ajouter un objectif",
        "add-theme": "Ajouter thèmes",
        "add-theme-warn": "Veuillez ajouter une bibliothèque de thèmes avant d'ajouter des tags à cet objectif.",
        "cancel": "Annuler",
        "coach-details": "Détails de coach",
        "coach-privacy": "Cet objectif n'est visible que pour vous et votre coach.",
        "coachee-privacy": "Cet objectif n'est visible que pour vous et votre coaché.",
        "comment-count": "{count} de {total}",
        "complete-goal": "Objectif complet",
        "completed-on": "Complété le {date}",
        "confidential-goal-description": "Cet objectif est confidentiel. Seul l'entraîneur et le coaché ​​peuvent voir cet objectif.",
        "default-description": "Ajouter une description",
        "default-title": "But sans titre",
        "delete-goal": "Supprimer l'objectif",
        "due-date": "Date d'échéance:",
        "due-date-date": "Date d'échéance: {date}",
        "due-date-tooltip": "Date d'échéance cible",
        "edit-themes": "Modifier les thèmes",
        "fallback-title": "Objectifs sans titre",
        "feedback": "Commentaires",
        "feedback-table": {
          "change": "changement",
          "coach": "Entraîneur",
          "coachee": "Coaché",
          "final": "Final",
          "initial": "Initiale",
          "latest": "Dernier",
          "no-change": "Pas de changement",
          "percentage-decrease": "{percentage}% diminution",
          "percentage-increase": "{percentage}% d'augmentation"
        },
        "goal-description": "Description de l'objectif",
        "goal-name": "Nom de but",
        "goal-progress": "Achèvement du jalon",
        "manage-visibility": "Gérer la visibilité",
        "must-add-stakeholders": "Vous devez ajouter des parties prenantes avant de pouvoir demander des commentaires.",
        "no-due-date": "Aucune date d'échéance définie",
        "personal-privacy": "Cet objectif n'est visible que pour vous.",
        "private": "Privé",
        "progress": "Le progrès",
        "provide-feedback": "Fournir commentaires",
        "request-feedback": "Demande de commentaires",
        "save": "Sauvegarder",
        "stakeholders": "Les parties prenantes",
        "themes": "Des thèmes",
        "title": "Créer un but",
        "visibility-confidential": "Confidentiel",
        "visibility-confidential-description": "Seul l'entraîneur et le coaché ​​peuvent voir cet objectif.",
        "visibility-everyone": "Toutes les personnes",
        "visibility-everyone-description": "Tous les administrateurs et les participants à l'engagement peuvent voir cet objectif.",
        "visibility-warning": "Si vous définissez cet objectif de manière confidentielle, vous y perdrez l'accès, car vous n'êtes pas le coach ou le coaché de cet engagement. Êtes-vous sur de vouloir continuer?"
      },
      "goals-tab": "Objectifs",
      "inactive-alert": {
        "button": "Rencontrer l'engagement",
        "description": "Cet engagement est inactif. Vous pouvez soit redémarrer cet engagement, soit créer un nouveau.",
        "title": "Cet engagement est inactif"
      },
      "invite-coachee-dialog": {
        "description": "Les vidéoconférences doivent être jointes à partir de l'application mobile Coaching.com. <strong> {coachee} </strong> n'a pas encore activé son compte Coaching.com ni téléchargé l'application mobile. Souhaitez-vous envoyer à <strong> {coachee} </strong> une invitation à créer un compte et à télécharger l'application mobile?",
        "send-invite-confirmation": "Envoyer une invitation maintenant?",
        "send-invite-warning": "Le coaché ​​ne pourra pas accéder au lieu de réunion choisi. Nous vous recommandons de modifier l'emplacement de la réunion par défaut ou d'envoyer l'invitation.",
        "title": "Inviter un coaché"
      },
      "loading": "Chargement de l'engagement ...",
      "matching-process": {
        "account-name": "Compte",
        "coach-location": "L'emplacement de l'entraîneur",
        "coach-name": "Nom de l'entraîneur",
        "email": "Email",
        "group-channel-topic": "Engagement avec {coachName} et {coacheeName}",
        "inactive-coachees-warning": "Les coachés des enregistrements inactifs et internes seront invités si un processus de mise en correspondance est lancé.",
        "inactive-coaches-warning": "Les coaches inactifs et les records internes seront invités mais n'apparaîtront pas comme options pour le coaché ​​tant qu'ils ne seront pas actifs.",
        "interview-card": {
          "request-times": "Demander de nouveaux horaires",
          "suggested-times": "Horaires suggérés",
          "waiting": "En attente des horaires suggérés par <strong>{coachName}</strong>"
        },
        "request-dialog": {
          "comment-label": "Commentaire",
          "comment-placeholder": "Fournissez des détails sur votre horaire préféré (facultatif)"
        },
        "request-status": "État de la demande",
        "start-engagement-confirmation": "Les paramètres de correspondance des coachs pour cet engagement démarreront automatiquement cet engagement une fois qu'un coach aura été affecté. Voulez-vous poursuivre?",
        "status": "Statut"
      },
      "matching-process-tab": "Correspondant à",
      "matching-started": "Matching a commencé",
      "me": "moi",
      "no-engagements": "Aucun engagement n'a été trouvé.",
      "no-results": "Aucun résultat trouvé.",
      "notes": {
        "empty-note": "Note vide",
        "search-notes": "Notes de recherche"
      },
      "notes-tab": "Notes",
      "notifications": {
        "add-message": "Ajouter un message",
        "after-engagement": "après l'engagement est démarré.",
        "after-file": "après l'envoi du fichier.",
        "after-form": "après l'envoi du formulaire.",
        "after-previous-item": "après que l'élément de programme précédent est terminé.",
        "after-session": "avant la session.",
        "after-task": "après l'envoi de la tâche.",
        "after-todo": "après l'envoi de la tâche.",
        "customize-content": "Personnaliser le contenu",
        "customize-content-description": "Un email de confirmation de planification sera envoyé à tous les participants lorsque la session est planifiée. Cet email inclut automatiquement la date, l'heure et les participants de la session, ainsi qu'une pièce jointe ICS. Personnalisez le sujet et ajoutez un message unique ci-dessous.",
        "customize-content-task-description": "Vous pouvez envoyer des notifications de {taskType} manuellement ou planifier leur envoi automatique. Personnalisez le sujet et ajoutez un message unique ci-dessous.",
        "edit-subject": "Modifier le sujet",
        "enable-attachments": "Ajouter des pièces jointes",
        "enable-attachments-description": "Ajoutez des pièces jointes à envoyer dans les emails de notification et de rappel.",
        "enable-reminder": "Activer rappel",
        "enable-reminder-description": "Ajouter un rappel de session à envoyer avant la date prévue de la session.",
        "enable-task-reminder-description": "Ajoutez un rappel à {enableReminderAction}. Le rappel ne sera envoyé que si le destinataire n'a pas encore {enableReminderAction}.",
        "loading-preview": "Chargement de l'aperçu",
        "no-assignees-notice": "Ajoutez un cessionnaire avant d'activer cette fonctionnalité.",
        "notifications": "Notifications",
        "preview-email": "Aperçu de l'email",
        "send-automatically": "Envoyer automatiquement",
        "send-automatically-description": "Planifiez cette item pour qu'elle soit envoyée automatiquement.",
        "send-notification": "Envoyer une notification",
        "send-reminder": "Rappel sera envoyé"
      },
      "owner-company": "Company",
      "program": {
        "add-item": "Ajouter un item",
        "hide-completed": "Masquer Terminé",
        "no-program-items": "Aucun élément de programme.",
        "no-search-results": "Aucun résultat trouvé.",
        "placeholders": {
          "active": "Commencez à ajouter des éléments à ce programme dans la colonne de gauche.",
          "coachee": "Votre coach n&#39;a pas encore créé votre programme. <br> Reviens bientôt!",
          "draft": "Vous pouvez affecter un modèle de programme dans les paramètres d’engagement ou créer un nouveau programme en ajoutant des éléments dans la colonne de gauche.",
          "inactive": "Reprenez l'engagement pour commencer à ajouter des éléments à ce programme."
        },
        "program-items-reordered": "Les changements de programme ont été enregistrés.",
        "program-items-save-failed": "Les éléments de programme n'ont pas pu être enregistrés.",
        "show-completed": "Afficher Terminé"
      },
      "program-item": {
        "delete-dialog": "Effacer l'article?"
      },
      "program-items": {
        "coaching-session-label": "Séance de Coaching",
        "file-label": "Fichier",
        "form-label": "Forme",
        "session-label": "Session",
        "task-label": "Tâche"
      },
      "program-tab": "Programme",
      "program-tab-disabled": "Vous utilisez un modèle, veuillez sélectionner le coach pour modifier le modèle de programme.",
      "reports": {
        "engagement-summary": "Résumé de l'engagement",
        "goals-summary": "Résumé des objectifs",
        "notes-summary": "Résumé des notes"
      },
      "reports-tab": "Rapports",
      "resources": {
        "folders-label": "Dossiers",
        "note-card": {
          "untitled": "Sans titre",
          "updated-on": "Mis à jour le"
        },
        "permissions-notice": "<strong>Les ressources sont privées par défaut.</strong> Pour mettre une ressource à la disposition d'autres utilisateurs, vous devez la partager soit avec \"Tout le monde\", ce qui la mettrait à la disposition de toutes les parties prenantes de l'engagement, soit de certaines parties prenantes.",
        "resources-label": "Ressources"
      },
      "resources-tab": "Ressources",
      "return-to-engagements": "Retour aux engagements",
      "schedule-sessions": "Programmer des séances de coaching.",
      "send-forms": "Envoyer des formulaires et des questionnaires.",
      "settings": {
        "add-engagement-editor": "Ajouter un éditeur d'engagement",
        "add-engagement-viewer": "Ajouter un visualiseur d'engagement",
        "add-file": "Ajouter le fichier",
        "add-viewer": "Ajouter une visionneuse",
        "added": "Ajouté sur",
        "added-on": "Ajouté le {date}",
        "coach": "Coach",
        "coach-disclaimer": "Les coachs peuvent voir tous les onglets mais ne peuvent pas éditer le programme ou les paramètres.",
        "coachee": "Coaché",
        "coachee-disclaimer": "Les coachs ne peuvent voir que les onglets du programme, des notes, des objectifs et des ressources.",
        "coachee-disclaimer-more": "Les coachs peuvent uniquement voir les onglets, les notes, les objectifs et les ressources du programme.",
        "confirm-remove-contract": "Êtes-vous sûr de vouloir retirer ce contrat de cet engagement?",
        "contract": "Contrat",
        "contracts": {
          "client-contract": "Contrat",
          "contractor-contract": "Contrat d'entrepreneur",
          "title": "Contrats"
        },
        "create-invoice": "Créer une facture",
        "created": "Créé sur",
        "creator": "Créé par",
        "delete-engagement": "Supprimer cet engagement",
        "delete-engagement-confirm": "Une fois que vous supprimez un engagement, il ne peut pas être récupéré.",
        "description": "Description",
        "description-placeholder": "Décrivez cet engagement",
        "end": "Fin:",
        "end-unspecified": "End: Non spécifié.",
        "engagement-dates": "Dates d'engagement",
        "engagement-deleted": "Engagement supprimé.",
        "engagement-started": "Ne peut pas être ajouté après le début de l'engagement.",
        "engagement-status": "Statut d'engagement",
        "engagement-status-updated": "Engagement défini comme {status}.",
        "hours-info": "Heures d'information",
        "invoicing-description": "Ne peut pas être ajouté après le début de l'engagement",
        "invoicing-title": "Approche de Facturation Préférentielle",
        "issued-on": "- publié le",
        "last-modified-at": "Dernière modification à {date}",
        "manager-already-added": "L'éditeur a déjà été ajouté.",
        "manager-disclaimer": "peut modifier les paramètres du programme et de l'engagement",
        "managers": "Les Gestionnaires",
        "matching": {
          "confirmation-dialog": {
            "message": "L'activation de l'interaction avec le coaché est permanente, vous ne serez pas en mesure de revenir en «Affichage seul». Voulez vous procéder?",
            "title": "Activer l'interaction"
          },
          "title": "Matching",
          "view-only": "Correspondance en lecture seule",
          "view-only-desc": "Lorsqu'elle est sélectionnée, les coachés ne pourront voir que les profils des coachs. Aucune planification d'entrevue ou sélection d'entraîneur ne sera possible.",
          "view-only-invalid": "Vous ne pouvez pas faire cet engagement en lecture seule si la correspondance commence comme un engagement normal."
        },
        "matching-setting-label": "Paramètres correspondants",
        "meeting-location-description": "Définissez un lieu de réunion par défaut pour cet engagement.",
        "meeting-location-title": "Emplacement de réunion par défaut",
        "meeting-locations": {
          "google_meet": "Google Meet",
          "in_app_video": "Coaching.com Video",
          "link_will_generate": "Un lien Google Meet sera généré sous peu.",
          "location": "Emplacement",
          "phone": "Téléphone",
          "video": "Vidéo"
        },
        "no-undo": "Vous ne pouvez pas annuler cette action.",
        "no-viewers": "Aucun spectateur ajouté.",
        "not-selected": "Non séléctionné",
        "participants": "Participants",
        "percent-complete": "% des heures de session terminées",
        "percent-sessions": "% de sessions terminées",
        "remove-contract": "Retirer le contrat",
        "remove-editor": "Supprimer l'éditeur",
        "remove-manager": "Supprimer le gestionnaire",
        "remove-viewer": "Supprimer le spectateur",
        "sessions-info": "Informations sur les sessions",
        "settings-permissions-notice": "Seuls les administrateurs, les éditeurs et le coach peuvent voir les paramètres d'engagement.",
        "start": "Début:",
        "start-unspecified": "Début: non spécifié.",
        "template-change-disclaimer": "Ne peut pas être modifié après le début de l'engagement.",
        "template-disclaimer": "Ne peut être ajouté après le début de l'engagement.",
        "title": "Titre",
        "title-placeholder": "Saisissez le titre de votre engagement",
        "upload-file": "Téléverser un fichier",
        "viewer-already-added": "Viewer déjà ajouté.",
        "viewer-disclaimer": "pouvez afficher les onglets du programme, des notes, des objectifs et des ressources, mais pas les paramètres",
        "viewers": "Les Spectateurs"
      },
      "settings-tab": "Paramètres",
      "start-chat-coach": "Commencer à discuter avec l'entraîneur",
      "start-chat-coachee": "Commencez le chat avec coaché",
      "start-matching": "Démarrer la correspondance",
      "status": "Cet état d&#39;engagement est <strong>{status}</strong> .",
      "summary": {
        "goals-completed": "Objectifs atteints",
        "goals-info": "Informations sur les buts",
        "goals-remaining": "Buts restants",
        "percent-goals-complete": "% d'objectifs atteints",
        "percent-tasks-complete": "% de tâches accomplies",
        "task-info": "Informations sur les tâches",
        "tasks-completed": "Tâches accomplies",
        "tasks-remaining": "Tâches restantes",
        "total-goals": "Total de buts",
        "total-tasks": "Total des tâches"
      },
      "summary-tab": "Résumé",
      "title": "{coachee} coaché par {coach}",
      "upload-files": "Télécharger des fichiers et les assigner pour examen.",
      "viewing-as": "Regarder comme:",
      "visibility-updated": "Visibility updated."
    },
    "engagement-coach-pool": {
      "selection": {
        "cancel-dialog": {
          "description": "Êtes-vous sûr de vouloir annuler cette demande de coaching?",
          "title": "Annuler la demande"
        },
        "cancel-request": "Annuler la demande",
        "header-text": "Vous avez sélectionné <strong>{coachName}</strong> pour cet engagement.",
        "info": "Vous avez sélectionné <strong>{coachName}</strong> en tant qu'entraîneur pour cet engagement. Votre demande est actuellement en attente d'approbation. L'engagement commencera une fois que votre sélection d'entraîneur aura été approuvée.",
        "interview-completed": "Votre entretien s'est terminé le 21 mars {completedDate}",
        "selected": "En attente de l'approbation de la sélection des entraîneurs"
      }
    },
    "engagement-coaching-request": {
      "change-completed-date": "Changer la date de fin",
      "coaching-interview": "Entretien de coaching",
      "complete-interview": "Entretien complet",
      "completed-on": "Terminé le {date}",
      "default-title": "Entretien de correspondance",
      "details": "Détails de l'entrevue",
      "errors": {
        "max-interview-requests": "Vous avez atteint le nombre maximum de demandes d'interviews."
      },
      "location": "Emplacement",
      "request-sent": "Demande envoyée.",
      "scheduled-for": "Planifié pour {date}"
    },
    "engagement-note": {
      "add-note": "Ajouter une note",
      "delete-label": "Supprimer la note",
      "note-shared": "Note partagée.",
      "placeholder-content": {
        "about": "À propos des notes",
        "attach": "Les notes privées peuvent être attachées aux sessions ou utilisées pour la journalisation.",
        "create": "Toute personne ayant accès à cet engagement peut créer des notes privées.",
        "download": "Vous pouvez télécharger vos notes privées individuellement ou télécharger le tout dans un seul fichier",
        "private": "Les notes sont privées et ne peuvent être visualisées par personne d'autre.",
        "sharing": "Les notes privées ne peuvent pas être partagées. Toutefois, vous pouvez créer un document et le partager avec d'autres personnes sous l'onglet Ressources de mission."
      },
      "shared-by": "Partagé par {user}",
      "sharing-removed": "{user} a été supprimé de cette note.",
      "view-only": "(voir seulement)"
    },
    "engagement-session": {
      "action-items": "Éléments d'action",
      "add-attendees-before-completing": "Ajoutez des participants avant de terminer.",
      "add-attendees-before-scheduling": "Ajoutez des participants avant la planification.",
      "add-description": "Ajouter une description",
      "add-note": "Ajouter une note de session",
      "add-participant": "Ajouter un participant",
      "attendee": "participant",
      "attendee-added": "Participant ajouté.",
      "attendees": "Participants",
      "cancel-button": "Annuler",
      "cancelation-date-description": "Date d'annulation",
      "cancelation-date-label": "Date d'annulation",
      "cancelation-reason-description": "Raison de l'annulation (optional)",
      "cancelation-reason-label": "Raison",
      "change-date-button": "Date de modification",
      "change-session-date": "Changer la date de la session",
      "coachee-can-schedule": "Vous pouvez planifier cette session maintenant.",
      "coachee-schedule-warning": "Vous ne pouvez planifier des sessions que si votre coach dispose d'une disponibilité partagée.",
      "comments": "Commentaires",
      "complete-interview-label": "Entretien complet",
      "complete-label": "Session complète",
      "complete-session-button": "Complète",
      "completed-on": "Complété le {date}",
      "confirm-delete": "Êtes-vous sûr de vouloir supprimer cette session?",
      "default-title": "Session sans titre",
      "delete-label": "Supprimer la session",
      "delete-todo": "Effacer todo",
      "duplicate-label": "Session en double",
      "duration": "Durée",
      "duration-default": "15 minutes",
      "duration-minutes": "Durée (minutes)",
      "duration-required": "La durée est requise.",
      "duration-updated": "Durée mise à jour.",
      "edit-cancelation": "Modifier l'annulation",
      "edit-scheduled-confirmation": "Modifier la confirmation de planification",
      "enable-reminder": "Activer rappel",
      "fullscreen": "Ouvrir en plein écran",
      "google-meet-unscheduled": "Le lien vidéo de Google Meet sera disponible une fois la session programmée.",
      "late-cancel-description": "Cette session apparaîtra comme une activité facturable sur les factures et les rapports.",
      "late-cancel-label": "Annulation tardive",
      "late-canceled": "Cette session a été annulée en retard sur {date}",
      "late-cancelled-on": "Retard annulé le {date}",
      "location": "Emplacement",
      "manage-attendees-label": "Gérer les participants",
      "marked-as-late-cancellation": "Session marquée comme annulation tardive.",
      "meeting-location-updated": "Lieu de réunion mis à jour.",
      "minutes-label": "minute",
      "named-attendee-added": "{attendee} ajouté.",
      "no-coach-availability": "Ce coach n'a pas encore partagé sa disponibilité. Vous pouvez planifier cette session, mais cela peut entraîner des conflits de planification pour le coach.",
      "no-coach-availability-interview": "Ce coach n'a pas encore partagé sa disponibilité. Vous pouvez planifier cet entretien, mais cela peut entraîner des conflits d'horaire pour le coach.",
      "no-description": "Aucune description ajoutée",
      "no-participants": "Aucune participants ajoutée",
      "no-past": "Vous ne pouvez pas planifier une date antérieure.",
      "participants-needed": "Vous devez ajouter au moins un participant.",
      "person-already-added": "Cette personne a déjà été ajoutée.",
      "reschedule": "Replanifier",
      "reschedule-label": "Replanifier",
      "schedule-label": "Horaire de session",
      "scheduled-for": "Planifié pour {date}",
      "send-reschedule-request": "Envoyer une demande de report",
      "send-schedule-request": "Envoyer une demande d'horaire",
      "session-date": "Date de session",
      "session-details": "Détails de la session",
      "session-duplicated": "Session dupliquée",
      "session-rescheduled": "Session reprogrammée.",
      "session-saved": "Session enregistrée.",
      "session-scheduled": "Session planifié.",
      "session-updated": "Session mise à jour..",
      "set-meeting-location": "Définir le lieu de la réunion",
      "start-time": "Heure de début",
      "time-unit-error": "Erreur lors de la définition de l'unité de temps.",
      "video-attendees": "Seules les parties prenantes de l'engagement qui ont des comptes actifs pourront accéder au lien vidéo Coaching.com."
    },
    "engagement-task": {
      "add-action-item": "Ajouter un nouvel élément d'action",
      "add-assignee": "Ajouter un cessionnaire",
      "add-description": "Ajouter une description",
      "add-recipient": "Ajouter un destinataire",
      "assignee": "Cessionnaire",
      "assignee-already-added": "Cette personne a déjà été ajoutée.",
      "assignee-needed": "Vous devez assigner au moins une personne.",
      "assignees": "Cessionnaires",
      "can-send-once-every-24-hours": "Vous ne pouvez l'envoyer qu'une fois toutes les 24 heures.",
      "change-date": "Changer date",
      "coachee-warning": {
        "file": "Vous ne pouvez pas voir ce fichier ou le marquer comme terminé tant qu'il ne vous est pas envoyé.",
        "form": "Vous ne pouvez pas voir ce formulaire ou y répondre tant qu'il ne vous a pas été envoyé.",
        "link": "Vous ne pouvez pas voir ce lien ou le marquer comme complet tant qu'il ne vous est pas envoyé.",
        "milestone": "Vous ne pouvez pas marquer cette tâche comme terminée tant qu'elle ne vous a pas été envoyée.",
        "todo": "Vous ne pouvez pas marquer cette tâche comme terminée tant qu'elle ne vous a pas été envoyée."
      },
      "comment": "Commentaire",
      "comments": "Commentaires",
      "complete-dialog": {
        "body": "Êtes-vous sûr de vouloir effectuer cette tâche?",
        "title": "Tâche complète"
      },
      "complete-label": "Tâche complète",
      "completed-date-set": "La date d'achèvement a été fixée.",
      "completed-on": "Achevé en",
      "confidential-form-description": "Ce formulaire est confidentiel. Seuls les administrateurs de compte et les destinataires de formulaire peuvent voir les réponses.",
      "confirm-delete": "Êtes-vous sûr de vouloir supprimer cette tâche?",
      "default-name": "Tâche sans titre",
      "delete-label": "Supprimer la tâche",
      "download-resource": "Download resource",
      "duplicate-label": "Tâche en double",
      "file-saved": "Fichier enregistrée.",
      "form-saved": "Formulaire enregistrée.",
      "fullscreen": "Ouvrir le plein écran",
      "manage-assignees-label": "Gérer les destinataires",
      "mark-link-complete-warning": "Vous ne pouvez pas voir le contenu de cette tâche ou la marquer comme terminée tant qu'elle ne vous est pas envoyée.",
      "no-assignees": "Aucun participant ajouté.",
      "no-description": "Aucune description ajoutée",
      "no-responses-collected": "Ce formulaire n'a pas encore de réponse.",
      "one-response-collected": "1 réponse recueillie.",
      "own-answer": "Vous avez répondu à ce formulaire.",
      "preview": "Aperçu du formulaire",
      "preview-own-response": "Voir la réponse",
      "preview-responses": "Afficher les réponses",
      "recipient": "Destinataire",
      "recipient-added": "Recipient ajoutée.",
      "recipient-added-form": "Form sent to recipient.",
      "recipients": "Destinataires",
      "resend": "Renvoyer",
      "resend-error": "Vous devez ajouter au moins un destinataire",
      "resend-file": "Renvoyer le fichier",
      "resend-form": "Renvoyer le formulaire",
      "resend-notifications": "Renvoyer les notifications aux personnes assignées.",
      "resend-task": "Envoyer une tâche encore",
      "resend-todo": "Envoyer une tâche encore",
      "respond": "Répondre",
      "responses-collected": "réponses collectées.",
      "send-automatically-confirmation": "Cette tâche est configurée pour envoyer une notification automatiquement, mais vous essayez de supprimer tous ses destinataires. Souhaitez-vous continuer et désactiver l'envoi automatique?",
      "send-file": "Send file",
      "send-form": "Send form",
      "send-milestone": "Envoyer tâche",
      "send-task": "Envoyer tâche",
      "send-task-again": "{taskType} envoyé encore.",
      "send-todo": "Envoyer tâche",
      "sent": "Envoyé",
      "sent-on": "Envoyé sur {date}.",
      "set-target-date": "Fixer la date cible",
      "target-date-set": "La date cible a été fixée.",
      "task-completed-date": "Date de fin de la tâche",
      "task-resent": "Renvoi de la notification",
      "task-saved": "Tâche enregistrée.",
      "task-sent": "Tâche envoyée",
      "todo-assignee-added": "Assignee ajoutée.",
      "updated-task": "Tâche mise à jour",
      "user-added": "{user} ajoutée.",
      "write-comment": "Écrire un commentaire"
    },
    "engagements": {
      "actions": {
        "complete": "Engagement complet",
        "delete": "Supprimer l'engagement",
        "resume": "Reprendre l'engagement",
        "start": "Commencer l'engagement",
        "start-matching": "Commencer à faire correspondre"
      },
      "add-participant-by-email": "Vous ne trouvez pas quelqu'un? Ajouter par email",
      "column-selection": {
        "cancel": "Annuler",
        "save": "Sauvegarder",
        "title": "Personnaliser les colonnes"
      },
      "create-dialog": {
        "already-matching": "<strong> {coacheeName} </strong> participe actuellement au jumelage de coachs. Allez à leur engagement pour sélectionner leur entraîneur, planifier une interview correspondante, etc.",
        "assign-later": "Attribuer plus tard",
        "choose-coach": "Choisissez l'entraîneur dans votre répertoire d'entraîneurs.",
        "create-coach": "Créer un nouvel coach",
        "create-coach-description": "Créez un nouveau coach pour cet engagement",
        "create-coachee": "Créer un nouveau coachee",
        "create-contract": "Créer un contrat maintenant",
        "create-engagement": "Créer un engagement",
        "for": "pour",
        "go-to-engagement": "Aller à l'engagement",
        "no-coachees": "Aucun coaché ​​n'a été trouvé.",
        "no-coaches": "Aucun entraîneur n'a été trouvé.",
        "no-contracts": "Aucun contrat n'a été trouvé.",
        "no-pools": "Aucun pool d'entraîneurs n'a été trouvé.",
        "no-templates": "Aucun modèle n'a été trouvé.",
        "only-complete-uninvoiced": "Afficher uniquement les engagements avec des sessions terminées et non facturées",
        "select-coach": "Sélectionner un entraîneur",
        "select-coach-pools": "Sélectionner des pools d'entraîneurs",
        "select-coachee": "Sélectionnez coaché",
        "select-coaches": "Sélectionnez les entraîneurs",
        "select-contract": "Sélectionnez le contrat",
        "select-template": "Choisir un modèle d'engagement",
        "selecting": "Sélectionner un entraîneur",
        "selecting-options": "Proposer des coachs spécifiques",
        "selecting-pool": "Partager les pools d'entraîneurs",
        "share-options": "Fournir des suggestions spécifiques pour le coaché ​​à choisir.",
        "share-pools": "Laissez le coaché ​​choisir son entraîneur parmi les pools d'entraîneurs.",
        "vendor-coaches-warning": "Les entraîneurs vendeurs ne peuvent avoir des engagements qu'avec des entraîneurs internes"
      },
      "create-workflow": {
        "coachee-step-title": "Selectionner le coaché",
        "company-filter-title": "Entreprises",
        "no-templates-found": "Aucun modèle n'a été trouvé.",
        "search-coachees-placeholder": "Chercher de les coachees",
        "search-templates-placeholder": "Chercher de les modèles",
        "step-one-label": "Étape 1 de 1",
        "step-two-label": "Étape 2 de 2",
        "template-step-title": "Selectionner le modèle"
      },
      "delete-confirmation": {
        "text": "Vous êtes sur le point de supprimer cet engagement. Cette action ne peut pas être annulée.",
        "title": "Supprimer la confirmation"
      },
      "delete-tooltip": "Supprimer",
      "engagement-status": "Statut d'engagement",
      "invite-coachee": {
        "description": "Souhaitez-vous envoyer une invitation à {coachee} pour activer son compte?",
        "is-inactive": "est Inactif"
      },
      "loading": "Chargement...",
      "na": "N/A",
      "no-coach-assigned": "Aucun entraîneur n'a été affecté.",
      "progress": {
        "coach": "Entraîneur:",
        "completed": "Terminé",
        "no-completed": "Aucun engagement terminé.",
        "no-started": "Aucun engagement commencé.",
        "soon": "Finir bientôt",
        "started": "Commencé"
      },
      "search-placeholder": "Chercher",
      "stakeholder": "intervenant",
      "start-matching-confirmation": {
        "confirmation-label": "Commencer à faire correspondre",
        "text": "Les nouveaux contacts seront invités à se connecter s'ils n'ont pas encore activé leur compte.",
        "title": "Commencer le jumelage"
      },
      "stats": {
        "active-engagements": "Engagements Actifs",
        "coach-disconnected": "Ce coach a été déconnecté de votre compte. S'il vous plaît ajouter un nouvel entraîneur.",
        "completed-engagements": "Engagements Terminés",
        "draft-engagements": "Projets d'engagement",
        "no-coach": "Coach non assigné.",
        "no-engagement": "Engagement non trouvé."
      },
      "status": {
        "active": "Actif",
        "all": "Tout",
        "coach_selection": "Correspondant à",
        "completed": "Complété",
        "draft": "Brouillon",
        "draft_coach_selection": "Projet correspondant",
        "inactive": "Inactif"
      },
      "table-header": {
        "active-engagements": "Engagements actifs",
        "amount-due": "Montant dû",
        "amount-paid": "Le montant payé",
        "coach": "Coach",
        "coach-company": "Compagnie d'autocars",
        "coach-email": "Coach Email",
        "coach-first-name": "Coach Prénom",
        "coach-last-name": "Coach Nom de famille",
        "coachee": "Coaché",
        "coachee-company": "Compagnie d'coaché",
        "coachee-email": "Coaché Email",
        "coachee-first-name": "Coaché Prénom",
        "coachee-last-name": "Coaché Nom de famille",
        "coachee-phone": "Coaché Phone",
        "completed-engagements": "Engagements Terminés",
        "department": "Département",
        "endDate": "Date de fin",
        "engagement-template": "Programme",
        "hours-completed": "Heures complétées",
        "hours-remaining": "Hours restantes",
        "leader-level": "Niveau d'employé",
        "location": "Emplacement",
        "percent-complete": "Pourcentage Terminé",
        "progress": "Le progrès",
        "sessions-cancelled": "Sessions annulées",
        "sessions-completed": "Sessions terminées",
        "sessions-remaining": "Sessions restantes",
        "startDate": "Date de début",
        "status": "Statut",
        "sub-department": "Sub-department",
        "title": "Position",
        "total-engagements": "Total de engagements",
        "total-sessions": "Nombre total de sessions"
      },
      "title": "Engagements",
      "view-tooltip": "Afficher"
    },
    "form": {
      "and": "et",
      "answers": {
        "answer": "Répondre",
        "confirm-delete": "Êtes-vous sûr de vouloir supprimer cette réponse?",
        "count": "{responses, plural, =0 {no responses} =1 {1 response} other {# responses}}",
        "delete": "Supprimer la réponse",
        "next": "Réponse suivante",
        "no-response": "Personne n'a encore envoyé de réponse.",
        "no-responses": "Pas de réponse.",
        "number": "# de réponses",
        "previous": "Réponse précédente"
      },
      "back-to-submissions": "Retour aux soumissions",
      "block-responses": {
        "blocked": "Désactivé",
        "blocked-description": "Ce formulaire n'accepte pas les réponses.",
        "dialog-title": "Activer/désactiver le lien",
        "public": "Activée",
        "public-description": "Ce formulaire accepte les réponses."
      },
      "confidential-dialog": {
        "all": {
          "description": "Tous les participants à l'engagement peuvent voir ce formulaire.",
          "name": "Toutes les personnes"
        },
        "confidential": {
          "description": "Seuls les administrateurs de compte et les destinataires de formulaire peuvent voir les résultats de formulaire confidentiels.",
          "name": "Confidentiel"
        },
        "title": "Qui peut voir les réponses"
      },
      "delete": "Supprimer le Formulaire",
      "delete-confirm": "Êtes-vous sûr de vouloir supprimer ce formulaire? Si vous le faites, toutes les données de soumission associées seront ignorées.",
      "download-csv": "Télécharger les réponses sous forme de fichier csv",
      "download-responses": "Télécharger les réponses (.csv)",
      "empty-question-error": "Vous devez ajouter la description de la question.",
      "empty-section-error": "Vous devez ajouter un titre à la section.",
      "example": {
        "coach": "Exemple de coach",
        "coachee": "Exemple coaché",
        "name": "Exemple"
      },
      "external-send-dialog": {
        "add-users-tooltip": "Ajouter à l'aide de la liste d'utilisateurs",
        "block-global-responses": "Bloquer les réponses du lien",
        "copied": "Copié dans le presse-papier",
        "email": "Email",
        "highlight": "cliquez pour mettre en évidence",
        "invalid-entries": "Les entrées suivantes ne sont pas des adresses email valides:",
        "invalid-ignored": "Ces adresses seront ignorées.",
        "link": "Lien",
        "message-label": "Message",
        "message-placeholder": "Entrez votre message ici",
        "send-success": "Formulaire envoyé avec succès",
        "subject-label": "Objet",
        "subject-placeholder": "Email objet",
        "title": "Envoyer le formulaire",
        "to-helper": "Vous pouvez entrer plusieurs adresses email séparées par des virgules, des points-virgules ou de nouvelles lignes de manière interchangeable.",
        "to-label": "À",
        "to-placeholder": "Nom <address@example.com>; Un autre nom <address2@example.com>",
        "valid-found": "{number} {number, plural, one {addresse} other {addresses}} email valides trouvées."
      },
      "individual": "Individuel",
      "loading": "Chargement",
      "manage-visibility": "Gérer Visibilité",
      "no-assignment": "Cette soumission n'appartient à aucun engagement.",
      "no-options-error": "Vous devez ajouter au moins une option.",
      "origin": "Cette tâche découle de l'engagement entre",
      "preview": "Aperçu du Formulaire",
      "preview-mode": "Mode de prévisualisation",
      "previewing": "\"Remplir un formulaire n'est pas disponible lors de la prévisualisation. Sur l'envoi du formulaire, le\n le destinataire sera en mesure de remplir le formulaire et les réponses seront saisies. \"",
      "questions": {
        "add-option-button": "Ajouter une option",
        "added-option-toast": "L'option a été ajoutée",
        "added-section-toast": "La section a été ajoutée",
        "added-toast": "La question a été ajoutée",
        "delete-button": "Supprimer",
        "delete-tooltip": "Supprimer",
        "deleted-option-toast": "L'option a été supprimée",
        "deleted-section-toast": "La section a été supprimée",
        "deleted-toast": "La question a été supprimée",
        "description-placeholder": "Description du formulaire",
        "duplicate-button": "Dupliquer",
        "duplicate-tooltip": "Dupliquer",
        "floating-bar": {
          "add-number": "Ajouter un numéro",
          "add-text": "Ajouter une question sur le texte",
          "dropdown": "Ajouter une liste déroulante",
          "multi-choice": "Ajouter une question à choix multiple",
          "section": "Ajouter une section",
          "single-choice": "Ajouter une question de choix unique"
        },
        "move-down-tooltip": "Descendre",
        "move-up-tooltip": "Monter",
        "multi-choice": {
          "add-option": "Ajouter une option",
          "weight-placeholder": "Poids"
        },
        "new-question": "Sans titre",
        "new-section": "Sans titre",
        "number": {
          "answer-placeholder": "Valeur numérique"
        },
        "other-answer": "Autre",
        "question-delete-prompt": "Êtes-vous sûr de vouloir supprimer cette question? Si vous le faites, toutes les données de soumission associées seront supprimées.",
        "question-placeholder": "Question *",
        "required": "Obligatoires",
        "section-delete-prompt": "Êtes-vous sûr de vouloir supprimer cette section? Si vous le faites, toutes les questions connexes et leurs données de soumission seront supprimées.",
        "section-description-placeholder": "Description de la section",
        "section-title": "Section de {n}",
        "section-title-placeholder": "Titre de section",
        "single-choice": {
          "add-option": "Ajouter une option",
          "add-other": "Ajouter \"autre\"",
          "new-option": "Option",
          "other": "Autre...",
          "weight-placeholder": "Poids"
        },
        "text": {
          "answer-placeholder": "Répondre"
        },
        "title-placeholder": "Titre du formulaire",
        "undo": "Annuler"
      },
      "recorded": "Votre réponse a été enregistrée. Je vous remercie!",
      "responses": "réponses",
      "revert": "Revenir",
      "save": "Sauvegarder",
      "saved": "Les modifications de formulaire ont été enregistrées.",
      "saving": "Économie...",
      "submission": {
        "already-submitted": "Vous avez déjà soumis ce formulaire une fois.",
        "already-submitted-description": "Les formulaires reçus ne peuvent être ni modifiés ni soumis à nouveau après avoir été envoyés.",
        "coach": "Entraîneur",
        "coachee": "Coaché",
        "example": "Exemple",
        "example-coach": "Exemple de coach",
        "example-coachee": "Exemple coaché",
        "preview-mode": "Mode de prévisualisation",
        "preview-warning": "Remplir un formulaire n'est pas disponible pendant un aperçu du formulaire. Lors de la soumission du formulaire, le destinataire pourra le compléter et les réponses seront saisies.",
        "response-recorded": "Votre réponse a été enregistrée.",
        "response-submited-on": "Ce formulaire a été envoyé le <strong>{date}</strong> &#39;",
        "thank-you": "Je vous remercie!"
      },
      "submission-summary": "Résumé de la soumission",
      "submitted": "Ce formulaire a été soumis le",
      "submitted-by": "Proposé par",
      "submitted-on": "Soumis le",
      "summary": "Résumé",
      "tabs": {
        "answers": "Réponses",
        "questions": "Des questions"
      },
      "thank": "Je vous remercie!",
      "waiting-responses": "En attente de réponses"
    },
    "global": {
      "auth": {
        "failed": "Ces informations d'identification ne correspondent pas à nos enregistrements.",
        "throttle": "Trop de tentatives de connexion. Veuillez réessayer dans :secondes secondes."
      },
      "client": {
        "active_client": "client actif",
        "active_clients": "Clients actifs",
        "archive_client": "Archiver le Client",
        "archived_client": "Client archivé avec succès",
        "archived_clients": "Archivé avec succès :count les clients",
        "client": "Client",
        "client_will_create": "le client sera créé",
        "clients": "Clients",
        "clients_will_create": "les clients seront créés",
        "create_new_client": "Créer un nouveau client",
        "created_client": "Client créé avec succès",
        "created_clients": "Créé avec succès :count client (s)",
        "delete_client": "Supprimer le Client",
        "deleted_client": "Client supprimé avec succès",
        "deleted_clients": "Supprimé avec succès :count les clients",
        "edit_client": "Modifier le client",
        "edit_client_details": "Modifier les détails du client",
        "export_clients": "Exporter les données client",
        "import_clients": "Importer des données client",
        "updated_client": "Client mis à jour avec succès"
      },
      "general": {
        "256_encryption": "Cryptage 256 bits",
        "JSON_file": "Fichier JSON",
        "accept_debit_cards": "Accepter les cartes de débit",
        "account_fields": "Champs pour entreprise",
        "account_holder_name": "Nom du titulaire du compte",
        "account_holder_type": "Veuillez sélectionner le type de compte",
        "account_management": "Gestion des comptes",
        "account_name": "Nom du compte",
        "account_number": "N° de compte",
        "account_number_mismatch": "Les numéros du compte bancaire ne correspondent pas.",
        "ach": "ACH",
        "ach_authorization": "J'autorise :company à utiliser mes données bancaires pour de futurs paiements, et si besoin de créditer mon compte par voie électronique afin de corriger d'éventuelles erreurs de débits. Je comprends que je suis en mesure d'annuler cette autorisation à tout moment en supprimant mon moyen de paiement ou en contactant :email.",
        "ach_authorization_required": "Vous devez consentir aux transactions ACH.",
        "ach_disabled": "Une autre passerelle est déjà configurée pour le prélèvement automatique.",
        "ach_email_prompt": "Veuillez entrer votre adresse courriel:",
        "ach_verification_delay_help": "Vous serez en mesure d'utiliser le compte après avoir terminé la vérification. Celle-ci demande habituellement 1-2 jours ouvrables.",
        "action": "Action",
        "action_required": "Action requise",
        "active": "Actif",
        "activity": "Activité",
        "activity_1": ": client créé par l'utilisateur: client",
        "activity_10": ": contact entré paiement: paiement pour: facture",
        "activity_11": ": paiement mis à jour par l'utilisateur: paiement",
        "activity_12": ": paiement archivé par l'utilisateur: paiement",
        "activity_13": ": utilisateur supprimé paiement: paiement",
        "activity_14": ": utilisateur entré: crédit crédit",
        "activity_15": ": utilisateur mis à jour: crédit",
        "activity_16": ": utilisateur archivé: crédit",
        "activity_17": ": utilisateur supprimé: crédit",
        "activity_18": ": citation créée par l'utilisateur: citation",
        "activity_19": ": devis mis à jour par l'utilisateur: devis",
        "activity_2": ": client archivé par l'utilisateur: client",
        "activity_20": ": devis envoyé par email à l'utilisateur: devis à: contact",
        "activity_21": ": contact vu devis: devis",
        "activity_22": ": citation archivée par l'utilisateur: citation",
        "activity_23": ": citation supprimée par l'utilisateur: citation",
        "activity_24": ": devis restauré par l'utilisateur: devis",
        "activity_25": ": facture restaurée par l'utilisateur: facture",
        "activity_26": ": utilisateur restauré client: client",
        "activity_27": ": utilisateur restauré paiement: paiement",
        "activity_28": ": utilisateur restauré: crédit",
        "activity_29": ": devis approuvé par contact: devis",
        "activity_3": ": utilisateur supprimé client: client",
        "activity_30": ": fournisseur créé par l'utilisateur: fournisseur",
        "activity_31": ": utilisateur archivé vendeur: fournisseur",
        "activity_32": ": utilisateur supprimé fournisseur: fournisseur",
        "activity_33": ": utilisateur restauré vendeur: vendeur",
        "activity_34": ": dépense créée par l'utilisateur: dépense",
        "activity_35": ": dépense archivée par l'utilisateur: dépense",
        "activity_36": ": dépense supprimée par l'utilisateur: dépense",
        "activity_37": ": dépense restaurée par l'utilisateur: dépense",
        "activity_39": ":user canceled a :payment_amount payment :payment",
        "activity_4": ": facture créée par l'utilisateur: facture",
        "activity_40": ":user refunded :adjustment of a :payment_amount payment :payment",
        "activity_41": "Le paiement de :payment_amount a échoué (:payment)",
        "activity_42": ": tâche créée par l'utilisateur: tâche",
        "activity_43": ": tâche mise à jour par l'utilisateur: tâche",
        "activity_44": ": tâche archivée par l'utilisateur: tâche",
        "activity_45": ": tâche supprimée par l'utilisateur: tâche",
        "activity_46": ": tâche restaurée par l'utilisateur: tâche",
        "activity_47": ": dépenses mises à jour par l'utilisateur: dépenses",
        "activity_5": ": facture mise à jour par l'utilisateur: facture",
        "activity_6": ": facture électronique de l’utilisateur: facture à: contact",
        "activity_7": ": contact consulté facture: facture",
        "activity_8": ": facture archivée de l'utilisateur: facture",
        "activity_9": ": facture effacée par l'utilisateur: facture",
        "add_account": "Ajouter un compte",
        "add_bank_account": "Ajouter un compte bancaire",
        "add_company": "Ajouter compte",
        "add_contact": "Ajouter un contact",
        "add_credit_card": "Ajouter une carte de crédit",
        "add_gateway": "Ajouter une passerelle",
        "add_payment_method": "Ajouter une méthode de paiement",
        "add_paypal_account": "Ajouter un compte PayPal",
        "add_to_invoice": "Ajouter à la facture: facture",
        "add_users_not_supported": "Passez au Plan Enterprise pour ajouter des utilisateurs supplémentaires à votre compte.",
        "added_on": "Ajouté le :date",
        "additional_info": "Informations complémentaires",
        "address": "Adresse",
        "address1": "Rue",
        "address2": "Appt/Bâtiment",
        "admin": "Admin",
        "administrator": "Administrateur",
        "administrator_help": "Permettre à l'utilisateur de gérer les utilisateurs, modifier les paramètres et de modifier tous les enregistrements",
        "advanced_settings": "Paramètres avancés",
        "after": "Après",
        "age": "Ancienneté",
        "age_group_0": "0 - 30 jours",
        "age_group_120": "120+ jours",
        "age_group_30": "30 -60 jours",
        "age_group_60": "60 - 90 jours",
        "age_group_90": "90 - 120 jours",
        "aging": "âgés",
        "agree_to_terms": "J'accepte les conditions d'utilisation d'Coaching.com :terms",
        "alipay": "Alipay",
        "all": "Tous",
        "all_pages": "toutes les pages",
        "all_pages_footer": "Show footer on",
        "all_pages_header": "Show header on",
        "always": "Toujours",
        "amount-late": "Montant en retard",
        "amount_due": "Montant dû",
        "analytics_key": "Analytics Key",
        "analytics_key_help": "Suivez les paiements en utilisant :link",
        "android_app": "App Android",
        "application_settings": "Paramètres de l'application",
        "applied_discount": "The coupon has been applied, the plan price has been reduced by :discount%.",
        "applied_free_year": "The coupon has been applied, your account has been upgraded to pro for one year.",
        "apply_credit": "Appliquer crédit",
        "apply_license": "Activer la licence",
        "apply_taxes": "Appliquer les taxes",
        "approve": "Accepter",
        "april": "Avril",
        "archive": "Archiver",
        "archive_account_gateway": "Passerelle d'archivage",
        "archive_bank_account": "Archiver le compte bancaire",
        "archive_expense": "Archiver le dépense",
        "archive_expense_category": "Archiver la catégorie",
        "archive_payment_term": "Archiver la condition de paiement",
        "archive_project": "Archiver le projet",
        "archive_recurring_invoice": "Archiver la facture récurrente",
        "archive_task": "Tâche d'archivage",
        "archive_tax_rate": "Archiver le taux de taxe",
        "archive_token": "Jeton d'archive",
        "archive_user": "Archiver utilisateur",
        "archive_vendor": "Archiver ce fournisseur",
        "archived": "Archivé",
        "archived_account_gateway": "Passerelle archivée avec succès",
        "archived_bank_account": "Compte bancaire archivé",
        "archived_expense": "Archivage de la dépense avec succès",
        "archived_expense_categories": ":count catégorie(s) de dépense archivée(s)",
        "archived_expense_category": "La catégorie de dépense a été archivée",
        "archived_expenses": "Archivage des dépenses avec succès",
        "archived_project": "Le projet a été archivé",
        "archived_projects": ":count projet(s) a (ont) été archivé(s)",
        "archived_recurring_invoice": "Facture récurrente archivée avec succès",
        "archived_task": "Tâche archivée avec succès",
        "archived_tasks": ":count tâches archivées avec succès",
        "archived_tax_rate": "Taux de taxe archivé avec succès",
        "archived_token": "Jeton archivé avec succès",
        "archived_user": "Utilisateur archivé avec succès",
        "are_you_sure": "Voulez-vous vraiment effectuer cette action ?",
        "are_you_sure_refund": "Rembourser les paiements sélectionnés?",
        "assigned_when_sent": "Affecté lors de l'envoi",
        "associated_accounts": "Comptes liés avec succès.",
        "august": "Août",
        "authentication": "Authentification",
        "authorization": "Autorisation",
        "auto_bill": "Facturation automatique",
        "auto_bill_ach_date_help": "ACH facturera toujours automatiquement à la date d'échéance",
        "auto_bill_email_message": "Cette facture sera automatiquement facturée à votre méthode de paiement inscrite au dossier à la date d'échéance.",
        "auto_bill_notification": "Cette facture sera automatiquement facturée à votre :payment_method au dossier le :due_date.",
        "auto_bill_notification_placeholder": "Cette facture sera automatiquement facturée à votre carte de crédit inscrite au dossier à la date d'échéance.",
        "auto_bill_payment_method_bank_transfer": "Compte bancaire",
        "auto_bill_payment_method_credit_card": "carte de crédit",
        "auto_bill_payment_method_paypal": "compte PayPal",
        "auto_convert_quote": "Convertir automatiquement les devis",
        "auto_convert_quote_help": "Convertissez automatiquement un devis en facture dès qu'il est approuvé par le client.",
        "auto_wrap": "Enrubannage automatique",
        "bank_account": "Compte Bancaire",
        "bank_account_error": "Echec de récupération des détails du compte, merci de vérifier vos identifiants",
        "bank_account_verification_help": "Nous avons fait deux dépôts dans votre compte avec la description \"VERIFICATION\". Ces dépôts prendront 1-2 jours ouvrables pour apparaître sur votre relevé. Veuillez entrer les montants ci-dessous.",
        "bank_account_verification_next_steps": "Nous avons fait deux dépôts dans votre compte avec la description \"VERIFICATION\". Ces dépôts prendront 1-2 jours ouvrables pour apparaître sur votre relevé. Lorsque les montant apparaîtront sur votre relevé, veuillez revenir sur cette page et cliquez sur \"Compléter la vérification\" à côté du compte.",
        "bank_accounts": "Comptes bancaires",
        "bank_accounts_help": "Liez un compte bancaire pour importer automatiquement les dépenses et créer les fournisseurs. Supporte American Express et <a href=\":link\" target=\"_blank\">400+ banques US.</a>",
        "bank_id": "banque",
        "bank_password_help": "Note : votre mot de passe est transmis de manière sécurisée et jamais stocké sur nos serveurs",
        "bank_password_warning": "Attention: votre mot de passe peut être transmis en clair, pensez à activer HTTPS.",
        "bank_transfer": "Virement bancaire",
        "basic_settings": "Paramètres généraux",
        "bcc_email": "Courriel CCI",
        "bcc_email_help": "Inclut de façon privée cette adresse avec les courriels du client.",
        "before": "Avant",
        "billable": "Facturable",
        "billing_address": "Adresse de facturation",
        "billing_method": "Méthode de facturation",
        "bitcoin": "Bitcoin",
        "blank": "Vide",
        "body": "Corps",
        "body_font_id": "Police du corps",
        "braintree_enable_paypal": "Activer les paiements PayPal via BrainTree",
        "braintree_paypal_disabled_help": "La passerelle PayPal traite les paiements PayPal",
        "braintree_paypal_help": "Vous devez aussi :link.",
        "braintree_paypal_help_link_text": "lier PayPal à votre compte BrainTree",
        "break_duration": "Pause",
        "button_confirmation_message": "Cliquez pour confirmer votre adresse email.",
        "buy_license": "Acheter une licence",
        "buy_now_buttons": "Boutons Achetez maintenant",
        "buy_now_buttons_disabled": "Cette fonctionnalité requiert qu'un produit soit créé et qu'une passerelle de paiement soit configurée.",
        "buy_now_buttons_warning": "Remarque: le client et la facture sont créés même si la transaction n'est pas terminée.",
        "calendar": "Calendrier",
        "canada": "Canada",
        "cancel": "Annuler",
        "cancel_account_help": "Supprime le compte ainsi que les données, les comptes et les réglages.",
        "cancel_plan_change": "Annuler la modification",
        "card_ach": "ACH",
        "card_americanexpress": "American Express",
        "card_carteblanche": "Carte Blanche",
        "card_creditcardother": "Inconnu",
        "card_diners": "Diners Club",
        "card_discover": "Discover",
        "card_expiration": "Exp:&nbsp:expires",
        "card_jcb": "JCB",
        "card_laser": "Laser",
        "card_maestro": "Maestro",
        "card_mastercard": "MasterCard",
        "card_solo": "Solo",
        "card_switch": "Switch",
        "card_unionpay": "UnionPay",
        "card_visacard": "Visa",
        "categories": "Catégories",
        "category": "Catégorie",
        "changes_take_effect_immediately": "Note: Les changements s'appliquent immédiatement",
        "chart": "Graphique",
        "chart_type": "Type de graphique",
        "charts_and_reports": "Graphiques & rapports",
        "checkbox": "Case à cocher",
        "choose": "Choisir",
        "city": "Ville",
        "city_state_postal": "Ville/ Province (Département)/ CP",
        "click_here": "cliquer ici",
        "client_created_at": "Date de création",
        "client_file": "Fichier de clients",
        "client_id": "ID du client",
        "client_login": "Connexion client",
        "client_name": "Nom du client",
        "client_not_found": "Je n'ai pas pu trouver le client",
        "client_number": "Numéro de client",
        "client_number_help": "Spécifiez un préfixe ou utilisez un modèle personnalisé pour la création du numéro de client.",
        "client_portal": "Portail client",
        "client_session_expired": " Session expirée.",
        "client_session_expired_message": "Votre session a expiré. Veuillez cliquer sur le lien dans votre courriel.",
        "client_variables": "Variables du client",
        "close": "Fermer",
        "color_font_help": "Note : la couleur et la police primaires sont également utilisées dans le portail client et le design des emails.",
        "column": "Colonne",
        "columns": "Colonnes",
        "companies": "Entreprises",
        "company": "Entreprise",
        "company_account": "Compte entreprise",
        "company_details": "Informations sur l’entreprise",
        "company_name": "Nom de l'entreprise",
        "company_not_found": "Je n'ai pas trouvé l'entreprise que je cherche sur cette liste",
        "company_size": "Taille de l'entreprise",
        "company_variables": "Variables de la compagnie",
        "competency_library": "Bibliothèque thématique",
        "complete_verification": "Compléter la vérification",
        "confirm": "Confirmer",
        "confirm_account_number": "Confirmez votre numéro de compte bancaire",
        "confirm_account_to_import": "Confirmer votre compte pour l'importation des données.",
        "confirm_recurring_timing": "Note : les courriels sont envoyés au début de l'heure.",
        "confirm_remove_payment_method": "Souhaitez-vous vraiment retirer cette méthode de paiement?",
        "confirmation_header": "Validation du compte",
        "confirmation_message": "Veuillez cliquer sur le lien ci-après pour valider votre compte.",
        "confirmation_subject": "Validation du compte Coaching.com",
        "contact": "Contact",
        "contact_custom1": "Contact First Custom",
        "contact_custom2": "Contact Second Custom",
        "contact_email": "Email du contact",
        "contact_fields": "Champs de contact",
        "contact_first_name": "Contact First Name",
        "contact_information": "Information de contact",
        "contact_last_name": "Contact Last Name",
        "contact_name": "Nom du contact",
        "contact_phone": "Téléphone du contact",
        "contact_us": "Nous joindre",
        "contact_us_help": "If you're reporting an error please include any relevant logs from storage/logs/laravel-error.log",
        "contact_us_response": "Merci pour votre message! Nous essaierons de répondre dès que possible. ",
        "contacts": "Informations de contact",
        "content": "Contenu",
        "convert_currency": "Convertir la devise",
        "converted": "Converti",
        "cost": "Coût",
        "counter": "Compteur",
        "country": "Pays",
        "country_Afghanistan": "Afghanistan",
        "country_Albania": "Albanie",
        "country_Algeria": "Algérie",
        "country_American Samoa": "Samoa américaines",
        "country_Andorra": "Andorra",
        "country_Angola": "Angola",
        "country_Anguilla": "Anguilla",
        "country_Antarctica": "Antartique",
        "country_Antigua and Barbuda": "Antigua-et-Barbuda",
        "country_Argentina": "Argentine",
        "country_Armenia": "Arménie",
        "country_Aruba": "Aruba",
        "country_Australia": "Australie",
        "country_Austria": "Autriche",
        "country_Azerbaijan": "Azerbaijan",
        "country_Bahamas": "Bahamas",
        "country_Bahrain": "Bahrain",
        "country_Bangladesh": "Bangladesh",
        "country_Barbados": "Barbades",
        "country_Belarus": "Biélorussie",
        "country_Belgium": "Belgique",
        "country_Belize": "Béize",
        "country_Benin": "Bénin",
        "country_Bermuda": "Bermudes",
        "country_Bhutan": "Bhoutan",
        "country_Bolivia, Plurinational State of": "Bolivie",
        "country_Bonaire, Sint Eustatius and Saba": "Pays-Bas caribéens",
        "country_Bosnia and Herzegovina": "Bosnie-Herzégovine",
        "country_Botswana": "Botswana",
        "country_Bouvet Island": "Île Bouvet",
        "country_Brazil": "Brésil",
        "country_British Indian Ocean Territory": "Territoire britannique de l'océan Indien",
        "country_Brunei Darussalam": "Brunei",
        "country_Bulgaria": "Bulgarie",
        "country_Burkina Faso": "Burkina Faso",
        "country_Burundi": "Burundi",
        "country_Cambodia": "Cambodge",
        "country_Cameroon": "Cameroun",
        "country_Canada": "Canada",
        "country_Cape Verde": "Cap-Vert",
        "country_Cayman Islands": "Iles Cayman",
        "country_Central African Republic": "République Centrafricaine",
        "country_Chad": "Tchad",
        "country_Chile": "Chili",
        "country_China": "Chine",
        "country_Christmas Island": "Île Christmas",
        "country_Cocos (Keeling) Islands": "Îles Cocos (Keeling)",
        "country_Colombia": "Colombie",
        "country_Comoros": "Comores",
        "country_Congo": "Congo",
        "country_Congo, the Democratic Republic of the": "République démocratique du Congo",
        "country_Cook Islands": "Îles Cook",
        "country_Costa Rica": "Costa Rica",
        "country_Croatia": "Coatie",
        "country_Cuba": "Cuba",
        "country_Curaçao": "Curaçao",
        "country_Cyprus": "Chypre",
        "country_Czech Republic": "République tchèque",
        "country_Côte d'Ivoire": "Côte d'Ivoire",
        "country_Denmark": "Danemark",
        "country_Djibouti": "Djibouti",
        "country_Dominica": "Dominique",
        "country_Dominican Republic": "République dominicaine",
        "country_Ecuador": "Équateur",
        "country_Egypt": "Egypte",
        "country_El Salvador": "Salvador",
        "country_Equatorial Guinea": "Guinée équatoriale",
        "country_Eritrea": "Érythrée",
        "country_Estonia": "Estonie",
        "country_Ethiopia": "Ethiopie",
        "country_Falkland Islands (Malvinas)": "Îles Malouines",
        "country_Faroe Islands": "Îles Féroé",
        "country_Fiji": "Fidji",
        "country_Finland": "Finlande",
        "country_France": "France",
        "country_French Guiana": "Guyane française",
        "country_French Polynesia": "Polynésie française",
        "country_French Southern Territories": "Terres australes et antarctiques françaises",
        "country_Gabon": "Gabon",
        "country_Gambia": "Gambie",
        "country_Georgia": "Géorgie",
        "country_Germany": "Allemagne",
        "country_Ghana": "Ghana",
        "country_Gibraltar": "Gibraltar",
        "country_Greece": "Grèce",
        "country_Greenland": "Groenland",
        "country_Grenada": "Grenade",
        "country_Guadeloupe": "Guadeloupe",
        "country_Guam": "Guam",
        "country_Guatemala": "Guatemala",
        "country_Guernsey": "Guernsey",
        "country_Guinea": "Guinée",
        "country_Guinea-Bissau": "Guinée-Bissau",
        "country_Guyana": "Guyana",
        "country_Haiti": "Haiti",
        "country_Heard Island and McDonald Islands": "Îles Heard-et-MacDonald",
        "country_Holy See (Vatican City State)": "Vatican",
        "country_Honduras": "Honduras",
        "country_Hong Kong": "Hong Kong",
        "country_Hungary": "Hongrie",
        "country_Iceland": "Islande",
        "country_India": "Inde",
        "country_Indonesia": "Indonésie",
        "country_Iran, Islamic Republic of": "Iran",
        "country_Iraq": "Irak",
        "country_Ireland": "Irlande",
        "country_Isle of Man": "Île de Man",
        "country_Israel": "Israel",
        "country_Italy": "Italie",
        "country_Jamaica": "Jamaique",
        "country_Japan": "Japon",
        "country_Jersey": "Jersey",
        "country_Jordan": "Jordanie",
        "country_Kazakhstan": "Kazakhstan",
        "country_Kenya": "Kenya",
        "country_Kiribati": "Kiribati",
        "country_Korea, Democratic People's Republic of": "Corée du Nord",
        "country_Korea, Republic of": "Corée du Sud",
        "country_Kuwait": "Koweit",
        "country_Kyrgyzstan": "Kirghizistan",
        "country_Lao People's Democratic Republic": "Laos",
        "country_Latvia": "Lettonie",
        "country_Lebanon": "Liban",
        "country_Lesotho": "Lesotho",
        "country_Liberia": "Liberia",
        "country_Libya": "Libye",
        "country_Liechtenstein": "Liechtenstein",
        "country_Lithuania": "Lithuanie",
        "country_Luxembourg": "Luxembourg",
        "country_Macao": "Macao",
        "country_Macedonia, the former Yugoslav Republic of": "Macédoine",
        "country_Madagascar": "Madagascar",
        "country_Malawi": "Malawi",
        "country_Malaysia": "Malaisie",
        "country_Maldives": "Maldives",
        "country_Mali": "Mali",
        "country_Malta": "Malte",
        "country_Marshall Islands": "Îles Marshall",
        "country_Martinique": "Martinique",
        "country_Mauritania": "Mauritanie",
        "country_Mauritius": "Maurice",
        "country_Mayotte": "Mayotte",
        "country_Mexico": "Mexique",
        "country_Micronesia, Federated States of": "Micronésie",
        "country_Moldova, Republic of": "Moldavie",
        "country_Monaco": "Monaco",
        "country_Mongolia": "Mongolie",
        "country_Montenegro": "Monténégro",
        "country_Montserrat": "Montserrat",
        "country_Morocco": "Maroc",
        "country_Mozambique": "Mozambique",
        "country_Myanmar": "Birmanie",
        "country_Namibia": "Namibie",
        "country_Nauru": "Nauru",
        "country_Nepal": "Népal",
        "country_Netherlands": "Pays-Bas",
        "country_New Caledonia": "Nouvelle-Calédonie",
        "country_New Zealand": "Nouvelle-Zélande",
        "country_Nicaragua": "Nicaragua",
        "country_Niger": "Niger",
        "country_Nigeria": "Nigeria",
        "country_Niue": "Niue",
        "country_Norfolk Island": "Île Norfolk",
        "country_Northern Mariana Islands": "Îles Mariannes du Nord",
        "country_Norway": "Norvège",
        "country_Oman": "Oman",
        "country_Pakistan": "Pakistan",
        "country_Palau": "Palau",
        "country_Palestinian Territory, Occupied": "Territoires palestiniens occupés",
        "country_Panama": "Panama",
        "country_Papua New Guinea": "Papouasie-Nouvelle-Guinée",
        "country_Paraguay": "Paraguay",
        "country_Peru": "Pérou",
        "country_Philippines": "Philippines",
        "country_Pitcairn": "Îles Pitcairn",
        "country_Poland": "Pologne",
        "country_Portugal": "Portugal",
        "country_Puerto Rico": "Puerto Rico",
        "country_Qatar": "Qatar",
        "country_Romania": "Roumanie",
        "country_Russian Federation": "Fédération Russe",
        "country_Rwanda": "Rwanda",
        "country_Réunion": "La Réunion",
        "country_Saint Barthélemy": "Saint Barthélemy",
        "country_Saint Helena, Ascension and Tristan da Cunha": "Sainte-Hélène, Ascension et Tristan da Cunha",
        "country_Saint Kitts and Nevis": "Saint-Kitts-et-Nevis",
        "country_Saint Lucia": "Sainte-Lucie",
        "country_Saint Martin (French part)": "Saint-Martin (partie française)",
        "country_Saint Pierre and Miquelon": "Saint Pierre et Miquelon",
        "country_Saint Vincent and the Grenadines": "Saint-Vincent-et-les-Grenadines",
        "country_Samoa": "Samoa",
        "country_San Marino": "Saint-Marin",
        "country_Sao Tome and Principe": "Sao Tomé-et-Principe",
        "country_Saudi Arabia": "Arabie Saoudite",
        "country_Senegal": "Sénégal",
        "country_Serbia": "Serbie",
        "country_Seychelles": "Seychelles",
        "country_Sierra Leone": "Sierra Leone",
        "country_Singapore": "Singapore",
        "country_Sint Maarten (Dutch part)": "Saint-Martin (partie Pays-Bas)",
        "country_Slovakia": "Slovaquie",
        "country_Slovenia": "Slovénie",
        "country_Solomon Islands": "Iles Salomon",
        "country_Somalia": "Somalie",
        "country_South Africa": "Afrique du Sud",
        "country_South Georgia and the South Sandwich Islands": "Géorgie du Sud-et-les Îles Sandwich du Sud",
        "country_South Sudan": "Soudan du Sud",
        "country_Spain": "Espagne",
        "country_Sri Lanka": "Sri Lanka",
        "country_Sudan": "Soudan",
        "country_Suriname": "Suriname",
        "country_Svalbard and Jan Mayen": "Svalbard et Jan Mayen",
        "country_Swaziland": "Swaziland",
        "country_Sweden": "Suède",
        "country_Switzerland": "Suisse",
        "country_Syrian Arab Republic": "Syrie",
        "country_Taiwan, Province of China": "Taîwan",
        "country_Tajikistan": "Tadjikistan",
        "country_Tanzania, United Republic of": "Tanzanie",
        "country_Thailand": "Thaïlande",
        "country_Timor-Leste": "Timor oriental",
        "country_Togo": "Togo",
        "country_Tokelau": "Tokelau",
        "country_Tonga": "Tonga",
        "country_Trinidad and Tobago": "Trinité-et-Tobago",
        "country_Tunisia": "Tunisie",
        "country_Turkey": "Turquie",
        "country_Turkmenistan": "Turkménistan",
        "country_Turks and Caicos Islands": "Îles Turques-et-Caïques",
        "country_Tuvalu": "Tuvalu",
        "country_Uganda": "Ouganda",
        "country_Ukraine": "Ukraine",
        "country_United Arab Emirates": "Émirats arabes unis",
        "country_United Kingdom": "Royaume-Uni",
        "country_United States": "États-Unis",
        "country_United States Minor Outlying Islands": "Îles mineures éloignées des États-Unis",
        "country_Uruguay": "Uruguay",
        "country_Uzbekistan": "Ouzbékistan",
        "country_Vanuatu": "Vanuatu",
        "country_Venezuela, Bolivarian Republic of": "Vénézuela",
        "country_Viet Nam": "Viêt Nam",
        "country_Virgin Islands, British": "Îles Vierges britanniques",
        "country_Virgin Islands, U.S.": "Îles Vierges des États-Unis",
        "country_Wallis and Futuna": "Wallis et Futuna",
        "country_Western Sahara": "Sahara occidental",
        "country_Yemen": "Yémen",
        "country_Zambia": "Zambie",
        "country_Zimbabwe": "Zimbabwe",
        "country_id": "Pays",
        "country_not_supported": "Ce pays n'est pas géré par notre système",
        "country_Åland Islands": "Åland",
        "create": "Créer",
        "create_all_help": "Autoriser l'utilisateur à créer et éditer tous les enregistrements",
        "create_client": "Créer un client",
        "create_expense_category": "Créer une catégorie",
        "create_invoice_for_sample": "Note : créez votre première facture pour voir la prévisualisation ici.",
        "create_new": "Créer",
        "create_new_invoice": "Créer une nouvelle facture",
        "create_payment_term": "Créer une condition de paiement",
        "create_project": "Créer un projet",
        "create_task": "Créer une tâche",
        "create_tax_rate": "Ajouter un taux de taxe.",
        "create_vendor": "Créer un fournisseur",
        "created_at": "Date Created",
        "created_bank_account": "Compte bancaire créé",
        "created_by": "Créé par :name",
        "created_by_invoice": "Créé par: facture",
        "created_customer": "Successfully created customer",
        "created_customers": "Successfully created :count customers",
        "created_expense": "Création de la dépense avec succès",
        "created_expense_category": "La catégorie de dépense a été créée",
        "created_gateway": "Passerelle créée avec succès",
        "created_invoices": "Création réussie: comptez les factures",
        "created_new_company": "La nouvelle entreprise a été créé",
        "created_payment_emailed_client": "Paiement crée avec succès et envoyé au client",
        "created_products": "Successfully created/updated :count product(s)",
        "created_project": "Le projet a été créé",
        "created_quotes": ":count offre(s) ont été créée(s)",
        "created_task": "Tâche créée avec succès",
        "created_tax_rate": "Taux de taxe crée avec succès",
        "created_wepay_confirmation_required": "Veuillez vérifier votre courriel et confirmer votre adresse courriel avec WePay.",
        "creator": "Créé par",
        "credit_card": "Carte de Crédit",
        "csv": "CSV",
        "csv_file": "Sélectionner un fichier CSV",
        "currency_code": "Code",
        "currency_symbol": "Symbole",
        "current_user": "Utilisateur actuel",
        "custom": "Personnalisé",
        "custom1": "First Custom",
        "custom2": "Second Custom",
        "custom_account_fields_helps": "Ajouter un label et une valeur aux détails de la société dur le PDF.",
        "custom_client_fields_helps": "Add a field when creating a client and optionally display the label and value on the PDF.",
        "custom_contact_fields_help": "Add a field when creating a contact and optionally display the label and value on the PDF.",
        "custom_css": "CSS personnalisé",
        "custom_design1": "Modèle personnalisé 1",
        "custom_design2": "Modèle personnalisé 2",
        "custom_design3": "Modèle personnalisé 3",
        "custom_field": "Champ personnalisé",
        "custom_fields": "Champs personnalisés",
        "custom_invoice_charges_helps": "Ajouter une entrée de texte à la page de création/édition de devis et inclure le supplément au sous-toal de la facture.",
        "custom_invoice_fields_helps": "Ajoutez un champ lors de la création d'une facture et affichez éventuellement l'étiquette et la valeur sur le PDF.",
        "custom_invoice_item_fields_help": "Ajouter un champs lors de la création d'un article de facture et afficher le label et la valeur sur le PDF.",
        "custom_invoice_link": "Lien de facture personnalisé",
        "custom_messages": "Messages personnalisés",
        "custom_product_fields_help": "Ajoute un champ lors de la création d'un produit ou d'une facture et affiche l'étiquette et la valeur sur le PDF.",
        "custom_range": "Intervalle personnalisé",
        "custom_variables": "Variables personnalisées",
        "customer": "Customer",
        "customers": "Customers",
        "customize": "Personnaliser",
        "customize_design": "Design personnalisé",
        "customize_help": "<p> Nous utilisons <a href=\"http://pdfmake.org/\" target=\"_blank\">pdfmake</a> pour définir les modèles de facture de manière déclarative. Le terrain de <a href=\"http://pdfmake.org/playground.html\" target=\"_blank\">jeu</a> pdfmake est un excellent moyen de voir la bibliothèque en action. </p><p> Vous pouvez accéder à une propriété enfant à l&#39;aide de la notation par points. Par exemple, pour afficher le nom du client, vous pouvez utiliser <code>$client.name</code> . </p><p> Si vous avez besoin d&#39;aide pour trouver une solution, posez une question à notre <a href=\"https://www.coachlogix.com/forums/forum/support/\" target=\"_blank\">forum d&#39;assistance</a> avec le modèle que vous utilisez. </p>",
        "customize_options": "Personnaliser les options",
        "dark": "Sombre",
        "dark_mode": "Mode sombre",
        "dark_mode_help": "Utilisez un fond sombre pour les barres latérales",
        "dashboard": "Tableau de bord",
        "data": "Données",
        "data_visualizations": "Visualisation des données",
        "datatable_info": "Affichage {start} sur {end} de {total} entrées",
        "date": "Date",
        "date_and_time": "Date & heure",
        "date_created": "Date de création",
        "date_format": "Format de la date",
        "date_range": "Intervalle de dates",
        "datetime_format": "Format Date/Heure",
        "day": "Jour",
        "day_of_month": ":ordinal jour du mois",
        "day_of_week_after": ":ordinal :day after",
        "days": "Jours",
        "days_after": "jours après le",
        "days_before": "jours avant le",
        "debit_cards": "Cartes de Débit",
        "debug": "Débogage",
        "december": "Décembre",
        "default_design": "Standard Design",
        "default_documents": "Default Documents",
        "default_email_footer": "Définir comme la signature de courriel par défaut",
        "default_invoice_footer": "Pied de page de la facture par défaut",
        "default_invoice_terms": "Termes de facturation par défaut",
        "default_messages": "Messages par défaut",
        "default_quote_terms": "Termes de devis par défaut",
        "default_tax_rate_id": "Taux de taxe par défaut",
        "defaults": "Valeurs par défaut",
        "delete": "Supprimer",
        "delete_company": "Delete Company",
        "delete_company_help": "Permanently delete the company along with all data and setting.",
        "delete_company_message": "Warning: This will permanently delete your company, there is no undo.",
        "delete_data": "Effacer les données",
        "delete_expense": "supprimer la dépense",
        "delete_expense_category": "Supprimer la catégorie",
        "delete_gateway": "Supprimer la passerelle",
        "delete_project": "Supprimer le projet",
        "delete_recurring_invoice": "Supprimer la facture récurrente",
        "delete_task": "Supprimer la tâche",
        "delete_user": "Supprimer l'utilisateur",
        "delete_vendor": "Supprimer ce fournisseur",
        "deleted": "Supprimé",
        "deleted_account": "Successfully deleted company",
        "deleted_account_details": "Your account (:account) has been successfully deleted.",
        "deleted_company": "Successfully canceled account",
        "deleted_company_details": "Your company (:account) has been successfully deleted.",
        "deleted_expense": "Effacement de la dépense avec succès",
        "deleted_expense_category": "La catégorie a été supprimée",
        "deleted_expenses": "Effacement des dépenses avec succès",
        "deleted_gateway": "Passerelle supprimée avec succès",
        "deleted_project": "Le projet a été supprimé",
        "deleted_projects": ":count projet(s) a (ont) été supprimé(s)",
        "deleted_recurring_invoice": "Facture récurrente supprimée avec succès",
        "deleted_task": "Tâche supprimée avec succès",
        "deleted_tasks": ":count tâches supprimées avec succès",
        "deleted_user": "Utilisateur supprimé",
        "description": "Description",
        "details": "Détails",
        "disable": "Désactiver",
        "disable_recurring": "Désactiver la récurrence",
        "disabled": "Désactivé",
        "discard_changes": "Annuler les modifications",
        "discount_message": "L'offre de :amount expire le :expires",
        "discount_sample": "La réduction pour une facture de :amount serait de :total.",
        "document_date": "Date de Document",
        "document_email_attachment": "Attacher les Documents",
        "document_size": "Taille",
        "documentation": "Documentation",
        "documents": "Documents",
        "documents_from_expenses": "Des dépenses :",
        "documents_header": "Documents :",
        "domain": "Domaine",
        "domain_help": "Utilisé dans le portail du client et lors de l'envoi de courriels",
        "domain_help_website": "Utilisé lors de l'envoi de courriels",
        "done": "Valider",
        "download": "Télécharger",
        "download_documents": "Télécharger les Documents (:size)",
        "draft": "Brouillon",
        "dropzone_cancel_upload": "Annuler l'envoi",
        "dropzone_cancel_upload_confirmation": "Etes-vous sûr de vouloir annuler cet envoi ?",
        "dropzone_default_message": "Glisser le fichier ou cliquer pour envoyer",
        "dropzone_fallback_message": "Votre navigateur ne supporte pas le drag'n'drop de fichier pour envoyer.",
        "dropzone_fallback_text": "Please use the fallback form below to upload your files like in the olden days.",
        "dropzone_invalid_file_type": "Vous ne pouvez pas envoyer de fichiers de ce type.",
        "dropzone_remove_file": "Supprimer le fichier",
        "due": "Dû",
        "due_by": "A échéanche du :date",
        "due_date": "Date d'échéance",
        "duplicate_post": "Attention: la page précédente a été soumise deux fois. La deuxième soumission avait été ignorée.",
        "duration": "Durée",
        "dwolla": "Dwolla",
        "edit": "modifier",
        "edit_all_help": "Autoriser l'utilisateur à modifier les enregistrement qu'il n'a pas créé",
        "edit_bank_account": "Éditer le compte bancaire",
        "edit_category": "Éditer la catégorie",
        "edit_credit": "Éditer le crédit",
        "edit_details": "Modifier",
        "edit_expense": "Éditer la dépensee",
        "edit_gateway": "Modifier la passerelle",
        "edit_payment_details": "Modifier les détails du paiement",
        "edit_payment_term": "Éditer la condition de paiement",
        "edit_payment_terms": "Éditer condition de paiement",
        "edit_project": "Editer le projet",
        "edit_report": "Editer le rapport",
        "edit_task": "Modifier la tâche",
        "edit_tax_rate": "Éditer le taux de taxe",
        "edit_user": "Éditer l'utilisateur",
        "edit_vendor": "Éditer le fournisseur",
        "email": "Courriel",
        "email_alias_message": "Chaque société doit avoir une adresse email unique.<br/>Envisagez d'utiliser un alias. ie, email+label@example.com",
        "email_design": "Email Design",
        "email_design_help": "Rendez vos courriels plus professionnels avec des mises en page en HTML.",
        "email_design_id": "Style de mail",
        "email_designs": "Email Designs",
        "email_documents_example_1": "Widgets Receipt.pdf",
        "email_documents_example_2": "Final Deliverable.zip",
        "email_documents_header": "Documents :",
        "email_error": "Il y a eu un problème en envoyant le courriel",
        "email_error_inactive_client": "Les mails ne peuvent être envoyés aux clients inactifs",
        "email_error_inactive_contact": "Les mails ne peuvent être envoyés aux contacts inactifs",
        "email_error_inactive_invoice": "Les mails ne peuvent être envoyés aux factures inactives",
        "email_error_invalid_contact_email": "Adresse mail du contact invalide",
        "email_error_user_unconfirmed": "Veuillez confirmer votre compte afin de permettre l'envoi de mail",
        "email_error_user_unregistered": "Veuillez vous inscrire afin d'envoyer des mails",
        "email_notifications": "Notifications par email",
        "email_notifications_desc": "Définissez la signature de votre entreprise et les médias sociaux pour les notifications par courrier électronique.",
        "email_notifications_toggle": "Activer ou désactiver les notifications individuelles par courrier électronique.",
        "email_payment": "Envoyer le paiement par email",
        "email_preferences": "Préférences de mail",
        "email_receipt": "Envoyer le reçu par courriel au client",
        "email_settings": "Paramètres mail",
        "email_taken": "L'adresse email est déjà enregistrée",
        "emailed_invoices": "Les factures ont été envoyées par courriel",
        "emailed_payment": "Paiement envoyé avec succès par email",
        "emailed_quotes": "Les offres ont été envoyées par courriel",
        "empty": "Vide",
        "enable": "Activé",
        "enable_ach": "Accept US bank transfers",
        "enable_alipay": "Accept Alipay",
        "enable_buy_now_buttons_help": "Activer les boutons Achetez maintenant",
        "enable_chart": "Graphiques",
        "enable_client_portal": "Tableau de bord",
        "enable_client_portal_dashboard": "Dashboard",
        "enable_client_portal_dashboard_help": "Voir/cacher la page de dashboard dans le portail client.",
        "enable_client_portal_help": "Afficher / masquer le tableau de bord sur le portail client.",
        "enable_email_markup": "Activer le balisage",
        "enable_email_markup_help": "Aidez vos clients à vous payer en ajoutant le balisage schema.org à vos courriels",
        "enable_https": "Nous vous recommandons fortement d'activer le HTTPS si vous acceptez les paiements en ligne.",
        "enable_max": "Activer max",
        "enable_min": "Activer min",
        "enable_portal_password": "Protéger les factures avec un mot de passe",
        "enable_portal_password_help": "Autoriser la création d'un mot de passe pour chaque contact. Si un mot de passe est créé, le contact devra rentrer un mot de passe avant de voir les factures.",
        "enable_recurring": "Activer la récurrence",
        "enable_report": "Rapport",
        "enable_second_tax_rate": "Activer la spécification d'un <b>second taux de taxe</b>",
        "enable_sofort": "Accept EU bank transfers",
        "enable_with_stripe": "Activer | Stripe est requis",
        "enabled": "Activé",
        "encryption": "Encryption",
        "end": "Fin",
        "end_date": "Date de fin",
        "end_time": "Heure de fin",
        "engagement_template": "Programme",
        "enter_company_name": "Veuillez saisir le nom de l'entreprise.",
        "enter_expense": "Nouvelle dépense",
        "enterprise_plan_features": "Le plan entreprise ajoute le support pour de multiples utilisateurs ainsi que l'ajout de pièces jointes, :link pour voir la liste complète des fonctionnalités.",
        "enterprise_plan_month_description": "Engagement d'un mois dans le Plan Coaching.com Entreprise.",
        "enterprise_plan_product": "Plan Entreprise",
        "enterprise_plan_year_description": "Engagement d'un an dans le Plan Coaching.com Entreprise.",
        "entity_state": "État",
        "error_contact_text": "Si vous avez besoin d'aide, veuillez nous contacter à :mailaddress",
        "error_refresh_page": "Un erreur est survenue, merci de rafraichir la page et essayer à nouveau",
        "error_title": "Il y a eu une erreur",
        "exchange_rate": "Taux de change",
        "exclusive": "Exclusif",
        "expense": "Dépense",
        "expense_amount": "Montant de la dépense",
        "expense_balance": "Balance de la dépnse",
        "expense_categories": "catégories de dépense",
        "expense_date": "Date de la dépense",
        "expense_documents": "Documents attachés",
        "expense_error_invoiced": "La dépense à déjà été facturée",
        "expense_error_mismatch_currencies": "La devise du clients n'est pas la même que celle de la dépense.",
        "expense_error_multiple_clients": "La dépense ne peut pas être attribuée à plusieurs clients",
        "expense_error_multiple_currencies": "Les dépenses ne peuvent avoir plusieurs devises.",
        "expense_file": "Fichier de dépense",
        "expense_should_be_invoiced": "Cette dépense doit elle être facturée ?",
        "expenses": "Dépenses",
        "expired": "Expiré",
        "expired_quotes": "Citations expirées",
        "expires": "Expire",
        "export": "Exporter",
        "export_data": "Exporter données",
        "export_help": "Utilisez JSON si vous prévoyez d'importer des données dans Coaching.com.<br/>Le fichier inclut les clients, les produits, les factures, les offres et les paiements.",
        "facebook_and_twitter": "Facebook et Twitter",
        "facebook_and_twitter_help": "Suivez-nous pour nous soutenir notre projet",
        "failed_remove_payment_method": "La suppression de la méthode de paiement a échoué",
        "failed_to_import": "L'import des enregistrements suivants à échoué, ils sont soit existants soit il manque des champs requis.",
        "february": "Février",
        "fee": "Frais",
        "fees": "Frais",
        "fees_disabled": "Les frais sont désactivés",
        "fees_disabled_for_gateway": "Les frais sont désactivés pour cette passerelle.",
        "fees_sample": "Le frais pour une facture de :amount serait de :total.",
        "fees_surcharge_help": "Personnaliser la majoration :link.",
        "fees_tax_help": "Activer les taxes par article pour définir les taux de taxes.",
        "field_due_date": "date d'échéance",
        "field_invoice_date": "Date de la facture",
        "field_label": "Nom du champ",
        "field_value": "Valeur du champ",
        "fields": "Champs",
        "file": "Fichier",
        "filter": "Filtrer",
        "filter_sort": "Filtrer / Trier",
        "filters": "Filtres",
        "financial_year_start": "Premier mois de l'année",
        "financial_year_start_help": "Utilisés par des sélecteurs de <b>plages de date</b>",
        "finish_setup": "Terminer la configuration",
        "first_name": "Prénom",
        "first_page": "première page",
        "first_reminder": "Premier rappel",
        "first_row_headers": "Utiliser la première ligne comme en-tête",
        "font_size": "Taille de police",
        "footer": "Pied de page",
        "forbidden": "Interdit",
        "force_pdfjs": "Empêcher le téléchargement",
        "form": "Formulaire",
        "format": "Format",
        "format_export": "Exporting format",
        "forms": "Formes",
        "free": "Gratuit",
        "freq_annually": "Annuelle",
        "freq_four_weeks": "quatre semaines",
        "freq_inactive": "Inactive",
        "freq_monthly": "Mensuelle",
        "freq_six_months": "Semestrielle",
        "freq_three_months": "Trimestrielle",
        "freq_two_months": "Deux mois",
        "freq_two_weeks": "deux semaines",
        "freq_weekly": "Hebdomadaire",
        "freq_yearly": "Annuellement",
        "friday": "Vendredi",
        "from": "De",
        "from_address": "Adresse expéditeur",
        "from_name": "Nom expéditeur",
        "full_logo": "Logo complet",
        "full_name": "Nom complet",
        "gateway": "Passerelle",
        "gateway_config_error": "Cela pourrait aider de définir de nouveau mots de passe ou générer de nouvelles clés API.",
        "gateway_exists": "La passerelle existe déjà",
        "gateway_fee_change_warning": "S'il existe des factures impayées avec des frais, elles doivent être mises à jour manuellement.",
        "gateway_fees": "Frais de la passerelle",
        "gateway_fees_disclaimer": "Attention: tous les pays/passerelles de paiement n'autorisent pas l'ajout de frais. Consultez les conditions d'utilisation de votre passerelle de paiement.",
        "gateway_fees_help": "Ajoute automatiquement une surcharge/remise de paiement en ligne.",
        "gateway_help_20": ":link pour vous inscrire à Sage Pay.",
        "gateway_help_21": ":link pour vous inscrire à Sage Pay.",
        "gateway_help_43": ": lien pour vous inscrire à Dwolla",
        "gateways": "Passerelles de paiement",
        "general": "Généraux",
        "general_settings": "Réglages généraux",
        "generated_numbers": "Numéros générés",
        "go_back": "Retour",
        "goal_stakeholder": "Stakeholder de buts",
        "gocardless_webhook_help_link_text": "ajouter cette URL en tant que point final dans GoCardless",
        "google_analytics": "Google Analytics",
        "group_by": "Grouper par",
        "group_dates_by": "Regrouper les dates par",
        "group_when_sorted": "Regrouper quand trié",
        "guest": "Invité",
        "header": "En-tête",
        "header_font_id": "Police de l'en-tête",
        "header_footer": "En-tête/Pied de page",
        "help": "Aide",
        "hide": "Cacher",
        "history": "Historique",
        "hour": "Heure",
        "hours": "Heures",
        "https": "HTTPS",
        "i_agree": "J'accepte les conditions ci dessus",
        "id_number": "Numéro ID",
        "iframe_url": "Site Internet",
        "iframe_url_help1": "Copiez le code suivant sur une page de votre site.",
        "iframe_url_help2": "Vous pouvez tester la fonctionnalité en cliquant sur \"Afficher en tant que destinataire\" pour une facture.",
        "iframe_url_help3": "Note : si vous prévoyez d'accepter les cartes de crédit, nous vous recommandons d'activer HTTPS sur votre site.",
        "import": "Importer",
        "import_complete": "L'importation s'est réalisée avec succès.",
        "import_data": "Importer des données",
        "import_expenses": "Importer les dépenses",
        "import_export": "Importer/Exporter",
        "import_failed": "Import Failed",
        "import_freshbooks": "Importer depuis FreshBooks",
        "import_invoices": "Importer des factures",
        "import_ofx": "Importer OFX",
        "import_products": "Importer des produits",
        "import_started": "L'importation est en cours. Vous recevrez un courriel lorsqu'elle sera terminée.",
        "import_warning_invalid_date": "Warning: The date format appears to be invalid.",
        "imported_expenses": "Successfully created :count_vendors vendor(s) and :count_expenses expense(s)",
        "imported_file": "Fichier importé",
        "imported_settings": "Paramètres importés avec succès",
        "in_total_revenue": "de bénéfice total",
        "inactive_logout": "Vous avez été déconnecté en raison de l'inactivité.",
        "include": "Inclure",
        "inclusive": "Inclusif",
        "independent-coach": "Coach Indépendant",
        "individual_account": "Compte particulier",
        "industry": "Secteur",
        "industry_Accounting_&_Legal": "Comptabilité et juridique",
        "industry_Advertising": "Publicité",
        "industry_Aerospace": "Aérospatial",
        "industry_Agriculture": "Agriculture",
        "industry_Automotive": "Automobile",
        "industry_Banking_&_Finance": "Banque et Finance",
        "industry_Biotechnology": "Biotechnologie",
        "industry_Broadcasting": "Médiadiffusion",
        "industry_Business Services": "Services aux entreprises",
        "industry_Commodities & Chemicals": "Produits chimiques",
        "industry_Communications": "Communications",
        "industry_Computers & Hightech": "Informatique & Hightech",
        "industry_Construction": "Construction",
        "industry_Defense": "Défense",
        "industry_Energy": "Energie",
        "industry_Entertainment": "Divertissement",
        "industry_Government": "Gouvernement",
        "industry_Healthcare & Life Sciences": "Santé et sciences de la vie",
        "industry_Insurance": "Assurances",
        "industry_Manufacturing": "Production",
        "industry_Marketing": "Marketing",
        "industry_Media": "Médias",
        "industry_Nonprofit & Higher Ed": "Organisme sans but lucratif & Enseignement supérieur",
        "industry_Other": "Autres",
        "industry_Pharmaceuticals": "Pharmaceutique",
        "industry_Photography": "Photographie",
        "industry_Professional Services & Consulting": "Services Professionnels et Conseil",
        "industry_Real Estate": "Immobilier",
        "industry_Restaurant & Catering": "Restaurant & Traiteur",
        "industry_Retail & Wholesale": "Détail & Grossiste",
        "industry_Sports": "Sports",
        "industry_Transportation": "Transport",
        "industry_Travel & Luxury": "Voyage & Luxe",
        "industry_help": "Utilisé dans le but de fournir des statistiques la taille et le secteur de l'entreprise.",
        "info": "Info",
        "initial_email": "Courriel initial",
        "integration_type": "Integration Type",
        "intent_not_found": "Désolé, je ne comprends pas bien ce que vous souhaitez.",
        "intent_not_supported": "Désolé, je ne peux pas faire cela.",
        "internal-coach": "Coach interne",
        "invalid_account_number": "Le numéro de compte bancaire est invalide",
        "invalid_card_number": "Le numéro de carte bancaire est invalide.",
        "invalid_credentials": "Ces informations d'identification ne correspondent pas à nos enregistrements",
        "invalid_csv_header": "En-tête du fichier CSV invalide",
        "invalid_cvv": "Le code de sécurité est incorrect.",
        "invalid_expiry": "La date d'expiration est invalide.",
        "invalid_file": "Type de fichier invalide",
        "invalid_mail_config": "Impossible d'envoyer le mail, veuillez vérifier que les paramètres de messagerie sont corrects.",
        "invalid_routing_number": "Le B.I.C. est invalide",
        "invalid_white_label_license": "La licence en marque blanche n'est pas valide",
        "invitation_status_opened": "Ouvert",
        "invitation_status_sent": "sent",
        "invitation_status_viewed": "Consulté",
        "invoice_amount": "Montant de la facture",
        "invoice_charges": "Frais de facturation",
        "invoice_counter": "Compteur de factures",
        "invoice_currency": "Devise de la facture",
        "invoice_details": "Détails de la facture",
        "invoice_documents": "Documents",
        "invoice_due_date": "Date d'échéance",
        "invoice_embed_documents": "Documents intégrés",
        "invoice_embed_documents_help": "Inclure l'image attachée dans la facture.",
        "invoice_expense": "Facturer la dépense",
        "invoice_fields_help": "Cliquez et déplacez les champs pour modifier leur ordre et emplacement",
        "invoice_file": "Fichier de factures",
        "invoice_footer_help": "Utilisez $pageNumber et $pageCount pour afficher les informations de la page.",
        "invoice_for_client": "Facture :invoice pour :client",
        "invoice_from": "Factures de:",
        "invoice_item_fields": "Invoice Item Fields",
        "invoice_link": "Lien de facture",
        "invoice_message_button": "Pour visionner votre facture de :amount, cliquer sur le lien ci-dessous",
        "invoice_name": "Facture",
        "invoice_no": "Numéro de facture",
        "invoice_not_emailed": "Cette facture n'a pas encore était envoyée par courriel.",
        "invoice_not_found": "La facture demandée n'est pas disponible",
        "invoice_number_help": "Spécifiez un préfixe ou utilisez un modèle personnalisé pour définir dynamiquement le numéro de facture.",
        "invoice_number_padding": "Remplissage",
        "invoice_quote_number": "Numéros de facture et de devis",
        "invoice_save_error": "Il y a eu une erreur lors de la sauvegarde de votre facture",
        "invoice_sent": ": compte facture envoyé",
        "invoice_signature": "Signatue",
        "invoice_to": "Facture à",
        "invoice_variables": "Variables de facture",
        "invoice_will_create": "la facture sera créée",
        "invoiced_amount": "Montant de la facture",
        "invoices_sent": ": compter les factures envoyées",
        "invoices_will_create": "les factures seront créées",
        "iphone_app": "App iPhone",
        "iphone_app_message": "Avez-vous penser télécharger notre :link",
        "january": "Janvier",
        "july": "Juillet",
        "june": "Juin",
        "keyboard_shortcuts": "Raccourcis clavier",
        "knowledge_base": "Base de connaissances",
        "label": "Label",
        "label_and_taxes": "Libellé et taxes",
        "landing_page": "Page d'accueil",
        "lang_Albanian": "Albanais",
        "lang_Brazilian Portuguese": "Portuguais - Brésil",
        "lang_Croatian": "Croate",
        "lang_Czech": "Tchèque",
        "lang_Danish": "Danois",
        "lang_Dutch": "Néerlandais",
        "lang_English": "Anglais",
        "lang_English - United Kingdom": "Anglais - Royaume Uni",
        "lang_Finnish": "Finnish",
        "lang_French": "Français",
        "lang_French - Canada": "Français - Canada",
        "lang_German": "Allemand",
        "lang_Greek": "Grec",
        "lang_Italian": "Italien",
        "lang_Japanese": "Japonais",
        "lang_Lithuanian": "Lituanien",
        "lang_Norwegian": "Norvégien",
        "lang_Polish": "Polonais",
        "lang_Portuguese - Brazilian": "Portugais - Brésil",
        "lang_Portuguese - Portugal": "Portugais - Portugal",
        "lang_Romanian": "Romanian",
        "lang_Slovenian": "Slovène",
        "lang_Spanish": "Espagnol",
        "lang_Spanish - Spain": "Espagnol - Espagne",
        "lang_Swedish": "Suédois",
        "lang_Turkish - Turkey": "Turkish - Turkey",
        "language": "Langage",
        "last_30_days": "30 derniers jours",
        "last_7_days": "7 derniers jours",
        "last_day_of_month": "Dernier jour du mois",
        "last_invoice_sent": "Dernière facture envoyée: date",
        "last_logged_in": "Dernière connexion",
        "last_login": "Dernière connexion",
        "last_modified_at": "Dernière modification à",
        "last_month": "Mois dernier",
        "last_name": "Nom",
        "last_page": "dernière page",
        "last_sent": "Dernier envoi",
        "last_sent_on": "Dernier envoi le :date",
        "last_year": "Dernière anée",
        "learn_more": "En savoir +",
        "less_fields": "Moins de champs",
        "license_expiring": "Note: Votre licence va expirer dans :count jours, :link pour la renouveler.",
        "light": "Clair",
        "limit": "Limite",
        "limit_import_rows": "Les données nécessitent d'être importées en lots de :count rangées ou moins.",
        "limit_users": "Désolé, ceci excédera la limite de :limit utilisateurs",
        "limited_gateways": "Note : Nous supportons une passerelle de carte de crédit par entreprise",
        "limits": "Limites",
        "limits_not_met": "Cette facture ne correspond pas aux limites définies pour ce type de paiement.",
        "line_item": "Ligne d'article",
        "link": "Lien",
        "link_manually": "Lier manuellement",
        "link_with_plaid": "Lier le compte instantanément avec Plaid",
        "list_...": "Lister ...",
        "list_clients": "Liste des clients",
        "list_credits": "List Credits",
        "list_expense_categories": "Liste des catégories de dépense",
        "list_expenses": "Liste des dépenses",
        "list_invoices": "Liste des factures",
        "list_payments": "Liste des paiements",
        "list_projects": "Lister les projets",
        "list_quotes": "Liste des devis",
        "list_recurring_invoices": "Liste des factures récurrentes",
        "list_tasks": "Liste des tâches",
        "list_vendors": "List Vendors",
        "listening": "A l'écoute...",
        "live_preview": "Aperçu",
        "live_preview_disabled": "Live preview has been disabled to support selected font",
        "load_design": "Chargé un modèle",
        "localization": "Localisation",
        "location": "Localisation",
        "location_first_surcharge": "Activé - Première majoration",
        "location_line_item": "Activer - Ligne d'article",
        "location_second_surcharge": "Activé - Seconde majoration",
        "logged": "Connecté",
        "logged_in": "Connecté",
        "login": "Connexion",
        "login_create_an_account": "Créez un compte!",
        "login_or_existing": "Ou connectez-vous avec un compte existant.",
        "logo": "Logo",
        "logo_help": "Formats supportés: JPEG, GIF et PNG",
        "logo_too_large": "Votre logo est: taille, pour de meilleures performances PDF, nous vous suggérons de télécharger un fichier image de moins de 200 Ko",
        "logo_warning_fileinfo": "Attention : Pour supporter les gifs, l'extension PHP fileinfo doit être activée.",
        "logo_warning_invalid": "il y a eu un problème lors de la lecture du fichier image, merci d'essayer un autre format.",
        "logo_warning_too_large": "Le fichier image est trop grand",
        "logout": "Se déconnecter",
        "logout_and_delete": "Déconnexion/Suppression du compte",
        "mailgun_domain": "Domaine Mailgun",
        "mailgun_private_key": "Mailgun Private Key",
        "manage_account": "Gérer votre compte",
        "manage_auto_bill": "Gérer les factures automatiques",
        "manage_companies": "Gérer les sociétés",
        "manager": "Gestionnaire",
        "managers": "Les Gestionnaires",
        "manual": "Manuel",
        "manual_entry": "Saisie manuelle",
        "march": "Mars",
        "margins": "Marges",
        "mark_active": "Marquer comme actif",
        "mark_paid": "Marquer comme payé",
        "mark_ready": "Marquer comme prêt",
        "marked_sent_invoice": "La facture marquée a été envoyée",
        "marked_sent_invoices": "Les factures marquées ont été envoyées",
        "max": "Max",
        "max_limit": "Max: :max",
        "max_users_reached": "Le nombre maximum d'utilisateurs a été atteint",
        "may": "Mai",
        "menu": "Menu",
        "message": "Message",
        "microphone_help": "Dire \"nouvelle facture pour [client]\" ou \"montre-moi les paiements archivés pour [client]\"",
        "military_time": "24H",
        "min": "Min",
        "min_limit": "Min: :min",
        "min_to_max_users": ":min à :max utilisateurs",
        "minute": "Minute",
        "minutes": "Minutes",
        "missing_account_holder_name": "Merci de saisir un nom de titulaire de compte",
        "missing_account_holder_type": "Merci de saisir un compte particulier ou entreprise",
        "missing_publishable_key": "Saisissez votre clé publique Stripe pour un processus de commande amélioré",
        "modules": "Modules",
        "monday": "Lundi",
        "month": "Mois",
        "month_year": "MOIS/ANNEE",
        "more_actions": "Plus d'actions",
        "more_designs_self_host_header": "Obtenez 6 modèles de factures additionnels pour seulement $:price",
        "more_fields": "Plus de champs",
        "more_options": "Plus d'options",
        "name": "Nom",
        "navigation": "Navigation",
        "navigation_variables": "Variables de navigation",
        "never": "Jamais",
        "new_...": "Nouveau ...",
        "new_category": "Nouvelle catégorie",
        "new_client": "Nouveau client",
        "new_company": "Nouveau compte",
        "new_expense": "Saisir une dépense",
        "new_expense_category": "Nouvelle catégorie de dépense",
        "new_product": "Nouvel article",
        "new_project": "Nouveau projet",
        "new_recurring_invoice": "Nouvelle facture récurrente",
        "new_report": "Nouveau rapport",
        "new_start_date": "Nouvelle date de départ",
        "new_task": "Nouvelle tâche",
        "new_tax_rate": "Nouveau taux de taxe",
        "new_user": "Nouvel utilisateur",
        "new_vendor": "Nouveau fournisseur",
        "next_client_number": "Le prochain numéro de client est :number",
        "next_credit_number": "The next credit number is :number.",
        "next_due_on": "Prochaine échéance: :date",
        "next_invoice_number": "Le prochain numéro de facture est: numéro.",
        "next_quote_number": "Le prochain numéro de devis est :number.",
        "next_reset": "Prochaine remise à zéro",
        "next_send_on": "Envoi suivant: :date",
        "no": "Non",
        "no-notifications": "Aucune notification",
        "no_client_selected": "Veuillez sélectionner un client",
        "no_contact_selected": "Veuillez sélectionner un contact",
        "no_description": "Pas de description",
        "no_fees": "Aucun frais",
        "no_items": "Aucun élément",
        "no_limit": "Aucune limite",
        "no_longer_running": "Cette facture n'est pas planifiée pour s'exécuter",
        "no_mapper": "Mappage invalide pour ce fichier",
        "no_payment_method_specified": "Aucune méthode de paiement spécifiée",
        "no_results": "Aucun resultat n'a été trouvé.",
        "no_transaction_reference": "Nous n'avons pas reçu de référence de transaction de paiement de la passerelle.",
        "no_undo": "Avertissement: Ceci ne peut pas être annulé.",
        "not_a_member_yet": "Pas encore membre?",
        "not_allowed": "Désolé, vous n'avez pas les permissions requises",
        "not_authorized": "Vous n'êtes pas autorisé(e)",
        "not_specified": "Non précisé",
        "notes": "Notes",
        "notes_auto_billed": "Auto-facturation",
        "notes_reminder1": "Premier rappel",
        "notes_reminder2": "Deuxième rappel",
        "notes_reminder3": "Troisième rappel",
        "notification_invoice_bounced": "Nous n'avons pas pu livrer Facture: facture à: contact.",
        "notification_invoice_bounced_subject": "Impossible de livrer la facture: facture",
        "notification_invoice_payment_failed": "Un paiement fait par le client :client pour la facture :invoice à échoué.  Le paiement a été marqué comme échoué et :amount a été ajouté au solde du client.",
        "notification_invoice_payment_failed_subject": "Le paiement a échoué pour la facture :invoice",
        "notification_quote_bounced": "Nous n'avons pas pu livrer Quote: facture à: contact.",
        "notification_quote_bounced_subject": "Impossible de livrer Quote: facture",
        "notifications": "Notifications",
        "november": "Novembre",
        "now": "Maintenant",
        "num_days": "Nombre de jours",
        "num_days_reminder": "Jours après la date d'échéance",
        "oauth_taken": "Le compte est déjà enregistré",
        "october": "Octobre",
        "of": "de",
        "of-hours": "d'heures",
        "off": "Fermé",
        "ofx_file": "Fichier OFX",
        "ofx_help": "In most cases the default values should work, if you're unable to connect it may help to adjust the settings.",
        "ofx_parse_failed": "Le traitement du fichier OFX a échoué",
        "old_browser": "Merci d'utiliser un <a href=\":link\" target=\"_blank\">navigateur plus récent</a>",
        "on_due_date": "À la date d'échéance",
        "on_send_date": "À la date d'envoi",
        "oneclick_login": "Connexion en un clic",
        "oneclick_login_help": "Connectez un compte pour vous connecter sans mot de passe",
        "open_balance": "Open Balance",
        "opt_in": "Activer",
        "opt_out": "Désactiver",
        "opted_in": "Activé",
        "opted_out": "Désactivé",
        "options": "Options",
        "or": "ou",
        "order_overview": "Aperçu de la commande",
        "organization": "Entreprise",
        "original_start_date": "Première date de départ",
        "other_providers": "Autres fournisseurs",
        "outstanding": "Exceptionnel",
        "overdue": "Impayé",
        "owner": "Propriétaire",
        "padding_help": "The number of zero's to pad the number.",
        "page_expire": "Cette page va bientôt expirer, :click_here pour continuer à travailler",
        "page_size": "Taille de Page",
        "paid": "Payé",
        "partial_due": "Solde partiel",
        "partial_value": "Doit être supérieur à zéro et inférieur au total",
        "participants": "Participants",
        "password": "Mot de passe",
        "pattern": "Modèle",
        "pattern_help_1": "Créer des numéros personnalisés en spécifiant un motif",
        "pattern_help_2": "Variables disponibles:",
        "pattern_help_3": "Par exemple: exemple serait converti en: valeur",
        "pattern_help_title": "Aide de modèle",
        "pay_with_card": "Carte de crédit",
        "pay_with_paypal": "Pay Pal",
        "payment": "Paiement",
        "payment_error_code": "Il y a eu une erreur lors du traitement de paiement [:code]. Veuillez réessayer plus tard.",
        "payment_file": "Fichier de paiement",
        "payment_message_button": "Merci pour votre paiement de :amount.",
        "payment_method_added": "Ajouter un moyen de paiement",
        "payment_method_error": "Une erreur s'est produite en ajoutant votre méthode de paiement. Veuillez réessayer plus tard.",
        "payment_method_removed": "Supprimer le moyen de paiement",
        "payment_method_set_as_default": "Définir une éthode de paiement automatique de factures",
        "payment_method_verified": "Vérification réussie",
        "payment_methods": "Moyen de paiement",
        "payment_processed_through_wepay": "Les paiements ACH seront traités avec WePay.",
        "payment_settings": "Paramètres de paiement",
        "payment_terms_help": "Définit la <b>date d&#39;échéance de la facture</b> par défaut",
        "payment_terms_net": "Net",
        "payment_type": "Type de paiement",
        "payment_type_ACH": "ACH",
        "payment_type_Alipay": "Alipay",
        "payment_type_American Express": "American Express",
        "payment_type_Apply Credit": "Appliquer le crédit",
        "payment_type_Bank Transfer": "Transfert bancaire",
        "payment_type_Carte Blanche": "Carte Blanche",
        "payment_type_Cash": "Comptant",
        "payment_type_Check": "Chèque",
        "payment_type_Credit Card Other": "Autre carte de crédit",
        "payment_type_Debit": "Débit",
        "payment_type_Diners Card": "Diners Card",
        "payment_type_Discover Card": "Discover Card",
        "payment_type_EuroCard": "EuroCard",
        "payment_type_Google Wallet": "Google Wallet",
        "payment_type_JCB": "JCB",
        "payment_type_Laser": "Laser",
        "payment_type_Maestro": "Maestro",
        "payment_type_MasterCard": "MasterCard",
        "payment_type_Money Order": "Money Order",
        "payment_type_Nova": "Nova",
        "payment_type_PayPal": "PayPal",
        "payment_type_Sofort": "Sofort",
        "payment_type_Solo": "Solo",
        "payment_type_Swish": "Swish",
        "payment_type_Switch": "Switch",
        "payment_type_UnionPay": "UnionPay",
        "payment_type_Venmo": "Venmo",
        "payment_type_Visa Card": "Carte Visa",
        "payment_type_direct_debit": "Prélèvement",
        "payment_type_dwolla": "Dwolla",
        "payment_type_help": "Définit le <b>type de paiement manuel<b/> par défaut.",
        "payment_type_iZettle": "iZettle",
        "payment_type_on_file": ":type enregistré",
        "payment_type_stripe": "Stripe",
        "paypal": "PayPal",
        "pdf_settings": "Réglages PDF",
        "pending": "En attente",
        "pending_change_to": "Changer vers",
        "percent": "Pourcent",
        "permissions": "Permissions",
        "phantomjs_help": "Dans certains cas, l'application utilise :link_phantom pour générer le PDF. Installez :link_docs pour le générer localement.",
        "phantomjs_local": "Utilise PhantomJS local",
        "phone": "Téléphone",
        "plaid": "Plaid",
        "plaid_environment_help": "Lorsque une clé de test Stripe est fournie, l’environnement de test Plaid (tartan) sera utilisé.",
        "plaid_linked_status": "Votre compte bancaire à :bank'",
        "plaid_optional": "(facultatif)",
        "plain": "Brut",
        "plan": "Plan",
        "plan_change": "Change Plan",
        "plan_changes_to": ":plan au :date",
        "plan_credit_description": "Crédit pour temps inutilisé",
        "plan_credit_product": "Crédit",
        "plan_enterprise": "Entreprise",
        "plan_expired": ":plan Plan Expiré",
        "plan_expires": "Fin du Plan",
        "plan_free": "Gratuit",
        "plan_free_self_hosted": "Auto hébergé (Gratuit)",
        "plan_paid": "Term Started",
        "plan_pending_monthly": "Basculera en mensuel le :date",
        "plan_price": "Prix du Plan",
        "plan_price_monthly": "$:price/Mois",
        "plan_price_yearly": "$:price/An",
        "plan_pro": "Pro",
        "plan_refunded": "Un remboursement a été émis.",
        "plan_started": "Début du Plan",
        "plan_status": "Status du Plan",
        "plan_term": "Terme",
        "plan_term_changes_to": ":plan (:term) le :date",
        "plan_term_month": "Mois",
        "plan_term_monthly": "Mensuel",
        "plan_term_year": "An",
        "plan_term_yearly": "Annuel",
        "plan_trial": "Essai",
        "plan_upgrade": "Upgrade",
        "plan_white_label": "Auto hébergé (Marque blanche)",
        "plans": "Des plans",
        "postal_city_state": "Ville/Province (Département)/Code postal",
        "postal_code": "Code postal",
        "postmark_error": "Il y a eu un problème en envoyant le courriel par Postmark: :link",
        "powered_by": "Propulsé par",
        "prefix": "Préfixe",
        "present": "Présent",
        "preview": "Prévisualisation",
        "primary_color": "Couleur principale",
        "primary_user": "Utilisateur principal",
        "privacy_policy": "Politique de confidentialité",
        "private_notes": "Note personnelle",
        "pro": "Pro",
        "pro_plan_calendar": ": lien pour activer le calendrier en adhérant au Plan Pro",
        "pro_plan_month_description": "Engagement d'un mois dans le Plan Coaching.com Pro.",
        "pro_plan_remove_logo": ":link pour supprimer le logo Coaching.com en souscrivant au Plan Pro",
        "pro_plan_remove_logo_link": "Cliquez ici",
        "pro_plan_reports": ":link pour activer les rapports en joignant le Plan Pro",
        "pro_plan_title": "NINJA PRO",
        "pro_plan_year_description": "Engagement d'un an dans le Plan Coaching.com Pro.",
        "processed_updates": "Mise à jour effectuée avec succès",
        "product_fields": "Champs de produit",
        "product_file": "Fichier de produit",
        "product_key": "Produit",
        "product_settings": "Paramètres du produit",
        "product_will_create": "Le produit sera créé",
        "products_will_create": "produits seront créés",
        "profile": "Profil",
        "profit": "Profit",
        "profit_and_loss": "Profits et Pertes",
        "project": "Projet",
        "projects": "Projets",
        "provide_email": "Veuillez renseigner une adresse courriel valide",
        "provide_name_or_email": "S'il vous plaît fournir un nom ou un email",
        "public_key": "Clé publique",
        "public_notes": "Note publique",
        "publishable_key": "Clé publique",
        "purge_data": "Purger les données",
        "purge_data_help": "Permanently delete all data but keep the account and settings.",
        "purge_data_message": "Attention : Cette action va supprimer vos données et est irréversible",
        "purge_details": "The data in your company (:account) has been successfully purged.",
        "purge_successful": "Successfully purged company data",
        "qty": "Quantité",
        "quantity": "Quantité",
        "quote_counter": "Compteur de devis",
        "quote_design": "Mise en page des Devis",
        "quote_due_date": "Valable jusque",
        "quote_footer": "Citation pied de page",
        "quote_is_approved": "Le devis a été approuvé",
        "quote_issued_to": "Devis à l'attention de",
        "quote_message_button": "Pour visionner votre devis de :amount,  cliquer sur le lien ci-dessous",
        "quote_no": "Numéro de citation",
        "quote_not_emailed": "Cette Offre n'a pas encore était envoyée par courriel.",
        "quote_number_help": "Spécifiez un préfixe ou utilisez un modèle personnalisé pour définir dynamiquement le numéro de devis.",
        "quote_settings": "Paramètres des devis",
        "quote_terms": "Termes de devis",
        "quote_to": "Offre pour",
        "raw": "Brut",
        "raw_html": "HTML brut",
        "recent_payments": "Paiements récents",
        "recipients": "Destinataires",
        "recommend_off": "Nous recommandons de<b>désactiver</b> ce réglage.",
        "recommend_on": "Nous recommandons d'<b>activer</b> ce réglage.",
        "recurring": "Récurrent",
        "recurring_due_dates": "Recurring Invoice Due Dates",
        "recurring_hour": "Heure récurrente",
        "recurring_invoice": "Facture récurrente",
        "recurring_invoice_number": "Numéro récurrent",
        "recurring_invoice_number_prefix_help": "Speciy a prefix to be added to the invoice number for recurring invoices.",
        "recurring_prefix": "Recurring Prefix",
        "recurring_too_soon": "Il est trop tôt pour créer la prochaine facture récurrente, c'est prévu: date",
        "redirect_url": "URL de redirection",
        "redirect_url_help": "Indiquez si vous le souhaitez une URL à laquelle vous vouler rediriger après l'entrée d'un paiement.",
        "referral_code": "Code de Parrainage",
        "referral_code_help": "Gagnez de l'argent en partagent notre outil en ligne",
        "referral_program": "Programme de parrainage",
        "refresh": "Rafraîchir",
        "refund": "Remboursement",
        "refund_body": "You have been processed a refund of :amount for invoice :invoice_number.",
        "refund_max": "max:",
        "refund_payment": "Remboursement du paiement",
        "refund_subject": "Refund Processed",
        "refunded_credit_payment": "Paiement de crédit remboursé",
        "refunded_payment": "Remboursement",
        "reminder_emails": "Emails de rappel",
        "reminder_subject": "Rappel: Facture :invoice de :account",
        "remove": "Supprimer",
        "remove_contact": "Supprimer ce contact",
        "remove_payment_method": "Supprimer le moyen de paiement",
        "renew_license": "Renouveler la licence",
        "renews": "Renouvellement",
        "reply_to_email": "Adresse de réponse",
        "reply_to_email_help": "Spécifier une adresse courriel de réponse",
        "report": "Rapport",
        "report_settings": "Report Settings",
        "reports": "Rapports",
        "reports_help": "Shift + Click pour trier plusieurs colonnes, Ctrl + Click pour annuler la sélection",
        "require": "Obligatoire",
        "require_invoice_signature": "Signature de facture",
        "require_invoice_signature_help": "Exiger que le client signe",
        "require_quote_signature": "Signature de l'offre",
        "require_quote_signature_help": "Exiger que le client signe",
        "resend_confirmation_email": "Renvoyer le courriel de confirmation",
        "resend_invite": "Renvoyer une invitation",
        "reset": "Remettre à zéro",
        "reset_all": "Réinitialiser",
        "reset_counter": "Remettre le compteur à zéro",
        "reset_footer": "Réinitialiser le pied de page",
        "reset_footer_help": "Réinitialiser le pied de page du compte par défaut",
        "reset_password_footer": "Si vous n'avez pas effectué de demande de réinitalisation de mot de passe veuillez contacter notre support : :email",
        "reset_terms": "Réinitialiser les termes",
        "reset_terms_help": "Réinitialiser les conditions du compte par défaut",
        "restore": "Restaurer",
        "restore_account_gateway": "Rétablir la passerelle de paiement",
        "restore_client": "Restaurer le client",
        "restore_expense": "Restorer la dépense",
        "restore_expense_category": "Rétablir la catégorie de dépense",
        "restore_product": "Rétablir le produit",
        "restore_project": "Rétablir le projet",
        "restore_recurring_invoice": "Restaurer la facture récurrente",
        "restore_task": "Tâche de restauration",
        "restore_user": "Restaurer l'utilisateur",
        "restore_vendor": "Restaurer le fournisseur",
        "restored_account_gateway": "La passerelle de paiement a été  rétablie",
        "restored_client": "Client restauré avec succès",
        "restored_expense": "Dépense restaurée",
        "restored_expense_category": "La catégorie de dépense a été rétablie",
        "restored_project": "Le projet a été rétabli",
        "restored_recurring_invoice": "Facture récurrente restaurée avec succès",
        "restored_task": "Tâche restaurée avec succès",
        "restored_user": "Utilisateur restauré avec succès",
        "restored_vendor": "Fournisseur restauré",
        "resume": "Reprendre",
        "resume_task": "Relancer la tâche",
        "resumed_task": "Tâche relancée avec succès",
        "return_to_app": "Return To App",
        "revenue": "Revenu",
        "routing_number": "B.I.C.",
        "rows": "lignes",
        "run": "Lancer",
        "running": "En cours",
        "sample_commands": "Exemples de commandes",
        "saturday": "Samedi",
        "save": "Sauvegarder",
        "save_as_default": "Sauvegarder par défaut",
        "save_as_default_footer": "Enregistrer comme pied de page par défaut",
        "save_draft": "Sauvegarder le brouillon",
        "save_or_discard": "Enregistrer ou ignorer vos modifications",
        "schedule": "Planification",
        "search": "Rechercher",
        "search_company_name": "Rechercher un nom de société",
        "search_hotkey": "la racine est /",
        "second": "Seconde",
        "second_reminder": "Second rappel",
        "secondary_color": "Couleur secondaire",
        "seconds": "Secondes",
        "secret": "Clé secrète",
        "secret_key": "Clé secrète",
        "secured_by_plaid": "Sécurisé par Plaid",
        "security": "Sécurité",
        "security_confirmation": "Votre adresse courriel a été confirmée.",
        "see_options": "Voir les options",
        "see_whats_new": "Voir les nouveautés dans la version v:version",
        "select": "Sélectionner",
        "select_file": "Veuillez sélectionner un fichier",
        "select_label": "Select Label",
        "selected": "Sélectionnés",
        "send_automatically": "Envoyer automatiquement",
        "send_email": "Envoyer courriel",
        "send_invite": "Envoyer une invitation",
        "send_portal_password": "Générer un mot de passe automatiquement",
        "send_portal_password_help": "Si aucun mot de passe n'est créé, un sera généré et envoyé avec la première facture.",
        "send_test_email": "Envoyer un email de test",
        "sent": "Sent",
        "sent_by": "Envoyé par :user",
        "sent_invite": "Invitation envoyée",
        "sent_message": "Message envoyé avec succès",
        "september": "Septembre",
        "service": "Service",
        "session_expired": "Votre session a expiré.",
        "set_limits": "Définir les limites de :gateway_type",
        "set_limits_fees": "Définir les limites/frais de :gateway_type",
        "set_now": "Définir maintenant",
        "settings": "Paramètres",
        "setup_account": "Paraméter le compte",
        "should_be_invoiced": "Devrait être facturé",
        "show_accept_invoice_terms": "Case à cocher pour les conditions de facturation",
        "show_accept_invoice_terms_help": "Exiger que le client confirme qu'il accepte les conditions de facturation",
        "show_accept_quote_terms": "Case à cocher pour les conditions d'offre",
        "show_accept_quote_terms_help": "Exiger que le client confirme qu'il accepte les conditions de l'offre",
        "show_address": "Afficher l'adresse",
        "show_address_help": "Demander au client de fournir son adresse de facturation",
        "show_all_options": "Afficher toutes les options",
        "show_archived_deleted": "Afficher archivés/supprimés",
        "show_archived_users": "Afficher les utilisateurs archivés",
        "show_currency_code": "Code de la devise",
        "show_deleted_users": "Voir les utilisateurs supprimés",
        "show_line_item_tax": "Display <b>line item taxes</b> inline",
        "sign_here": "Veuillez signer ici:",
        "sign_up": "S’enregistrer",
        "sign_up_now": "Inscrivez-vous maintenant",
        "sign_up_using": "Inscrivez-vous en utilisant",
        "sign_up_with_wepay": "S'enregistrer avec WePay",
        "signature": "Signature email",
        "signed": "Signé",
        "site_updates": "Mises à jour du site",
        "sofort": "Sofort",
        "sort_by": "Trier par",
        "source": "Source",
        "standard_fees_apply": "Taux : 2.9%/1.2% [Carte de Crédit/Transfert Bancaire] + $0.30 par paiement réussit.",
        "start": "Début",
        "start_date": "Date de début",
        "start_date_required": "La date de début est requise",
        "start_of_week": "Premier jour de la semaine",
        "start_of_week_help": "Utilisés par des sélecteurs de <b>date</b>",
        "start_time": "Heure de début",
        "started_task": "Tâche lancée avec succès",
        "state": "Région/Département",
        "statement": "Relevé",
        "statement_date": "Date du relevé",
        "statement_issued_to": "Relevé délivré à",
        "statement_to": "Relevé à",
        "status": "Statut",
        "status_all": "All",
        "status_approved": "Approuvé",
        "status_completed": "Validé",
        "status_draft": "Brouillon",
        "status_failed": "Échoué",
        "status_paid": "Payé",
        "status_partial": "Partiel",
        "status_partially_refunded": "Remboursement partiel",
        "status_partially_refunded_amount": ":amount remboursé",
        "status_pending": "en attente",
        "status_refunded": "remboursé",
        "status_sent": "Envoyée",
        "status_unpaid": "Unpaid",
        "status_viewed": "Vue",
        "status_voided": "annulé",
        "stop": "Fin",
        "stop_task": "Arrêter la tâche",
        "stopped_task": "Tâche stoppée avec succès",
        "stripe_ach_help": "ACH support must also be enabled in :link.",
        "stripe_alipay_help": "These gateways also need to be activated in :link.",
        "stripe_webhook_help": "Vous devez :link.",
        "stripe_webhook_help_link_text": "ajouter cette URL comme un terminal avec Stripe",
        "styles": "Styles",
        "subdomain": "Sous-domaine",
        "subdomain_help": "Définissez un sous-domaine ou affichez la facture sur votre propre site web.",
        "subject": "Sujet",
        "submit": "Envoyer",
        "success": "Succès",
        "sunday": "Dimanche",
        "support": "Support",
        "support_forum": "Forum de support",
        "surcharge": "Majoration",
        "surcharge_label": "Majoration",
        "switch": "Changer",
        "switch_to_primary": "Veuillez basculer vers votre entreprise initiale (:name) pour gérer votre plan d'abonnement.",
        "switch_to_wepay": "Changer pour WePay",
        "system": "Système",
        "system_settings": "Paramètres système",
        "task": "Tâche",
        "task_details": "Détails de la tâche",
        "task_error_invoiced": "Les tâches ont déjà été facturées",
        "task_error_multiple_clients": "Les tâches ne peuvent pas appartenir à différents clients",
        "task_error_running": "Arrêtez d'abord les tâches en cours",
        "task_errors": "Merci de corriger les horaires conflictuels",
        "task_file": "Fichier de tâches",
        "tasks": "les tâches",
        "tax_invoice": "Taxe de facture",
        "tax_name": "Nom de la taxe",
        "tax_quote": "Taxe applicable à l'offre",
        "tax_rate": "Taux de taxe",
        "tax_rate_type_help": "Inclusive tax rates adjust the line item cost when selected.<br/>Only exclusive tax rates can be used as a default.",
        "tax_settings": "Réglages des taxes",
        "template": "Modèle",
        "template_help_1": "Variable disponibles :",
        "template_help_title": "Aide Modèles",
        "templates_and_reminders": "Templates & Rappels",
        "terms_of_service": "Conditions d'utilisation",
        "text": "Texte",
        "third_reminder": "Troisième rappel",
        "this_month": "Mois en cours",
        "this_year": "Année encours",
        "thursday": "Jeudi",
        "time": "Temps",
        "time_tracker": "Suivi du temps",
        "time_tracker_login": "Connexion Time Tracker",
        "timer": "Compteur",
        "times": "Horaires",
        "timesheets": "Feuilles de temps",
        "timezone": "Fuseau horaire",
        "timezone_default": "Fuseau horaire de l'ordinateur",
        "timezone_unset": "Merci de :link pour définir votre fuseau horaire",
        "to": "À",
        "toggle_history": "Basculer l'historique",
        "toggle_menu": "Basculer la navigation",
        "toggle_navigation": "Basculer la navigation",
        "token_billing_1": "désactivé",
        "token_billing_2": "Opt-in - la case à cocher est affichée mais pas sélectionnée",
        "token_billing_3": "Désinscription - la case à cocher est affichée et sélectionnée",
        "token_billing_4": "Toujours",
        "token_billing_braintree_paypal": "Sauvegarder les détails du paiement",
        "token_billing_checkbox": "Stocker les détails de la carte de crédit",
        "token_billing_help": "Enregistrez les détails de paiement avec WePay, Stripe, Braintree ou GoCardless.",
        "token_billing_type_id": "Facturation par jeton",
        "token_expired": "Le jeton de validation a expiré. Veuillez réessayer.",
        "total-owed-ytd": "Total dû (cumul)",
        "total-paid-ytd": "Total payé (cumul annuel)",
        "total_invoiced": "Total facturé",
        "total_profit": "Total des profits",
        "total_revenue": "Revenu total",
        "totals": "Totals",
        "translate_app": "Aidez-nous à améliorer nos traductions avec :link",
        "trello_roadmap": "Trello Roadmap",
        "trial_call_to_action": "Commencer l'essai gratuit",
        "trial_expired": ":plan Essai du Plan terminé",
        "trial_footer": "Il vous reste :count jours d'essai sur notre offre Pro, :link modifiez votre abonnement maintenant.",
        "trial_footer_last_day": "C'est votre dernier jour d'essai sur notre offre Pro, :link modifiez votre abonnement maintenant.",
        "trial_message": "Votre compte va être crédité d'un essai gratuit de 2 semaines de notre Plan pro.",
        "trial_success": "Crédit d'un essai gratuit de 2 semaines de notre Plan pro avec succès",
        "tuesday": "Mardi",
        "type": "Type",
        "unassigned": "Non assigné",
        "united_states": "États-Unis",
        "unknown_bank": "Banque inconnue",
        "unlink": "Dissocier",
        "unlink_account": "Dissocier le compte",
        "unlinked_account": "Comptes dissociés avec succès.",
        "unnamed_client": "Client sans nom",
        "unpaid": "Non payé",
        "unsaved_changes": "Vous avez des modifications non enregistrées",
        "untitled": "Sans titre",
        "untitled_account": "Société sans nom",
        "upcoming_quotes": "Citations à venir",
        "update": "Mettre à jour",
        "update_address": "Mettre à jour l'adresse",
        "update_address_help": "Mettre à jour l'adresse du client avec les détails fournis",
        "update_credit": "Mettre à jour un crédit",
        "update_font_cache": "Veuillez actualiser la page pour mettre à jour le cache de la police de caractères",
        "updated_bank_account": "Compte bancaire mis à jour",
        "updated_credit": "Le crédit a été mis à jour",
        "updated_expense": "Mise à jour de la dépense avec succès",
        "updated_expense_category": "La catégorie de dépense a été mise à jour",
        "updated_gateway": "Passerelle mise à jour avec succès",
        "updated_plan": "Updated plan settings",
        "updated_project": "Le projet a été mis à  jour",
        "updated_settings": "Paramètres mis à jour avec succès",
        "updated_task": "Tâche mise à jour avec succès",
        "updated_tax_rate": "Taux de taxe mis à jour avec succès",
        "updated_user": "Utilisateur mis à jour",
        "upgrade_call_to_action": "Mettre à jour maintenant !",
        "upgrade_for_permissions": "Adhérez à notre Plan entreprise pour activer les permissions.",
        "upload": "Envoyer",
        "url": "URL",
        "use_another_provider": "Utiliser un autre fournisseur",
        "use_bank_on_file": "Utiliser la banque inscrite au dossier",
        "use_card_on_file": "Utiliser la carte sur fichier",
        "use_client_terms": "Utiliser les conditions de paiement du client",
        "use_for_auto_bill": "Utiliser pour les factures automatiques",
        "used_for_auto_bill": "Méthode de paiement automatique de factures",
        "user": "Utilisateur",
        "user_create_all": "Créer des clients, des factures, etc.",
        "user_details": "Utilisateur",
        "user_edit_all": "Modifier tous les clients, les factures, etc.",
        "user_email_footer": "Pour modifier vos paramètres de notification par courriel, veuillez visiter :link",
        "user_guide": "Guide de l'utilisateur",
        "user_management": "Gestion des utilisateurs",
        "user_view_all": "Voir tous les clients, les factures, etc.",
        "username": "Nom d'utilisateur",
        "users": "Utilisateurs",
        "valid_thru": "Valide\nthru",
        "valid_until": "Valide jusqu'au",
        "validate": "Valider",
        "validate_bank_account": "Valider le compte bancaire",
        "value": "Valeur",
        "vendor": "Fournisseur",
        "vendor_contacts": "Fournisseur",
        "vendor_name": "Contacts du fournisseur",
        "vendors": "Fournisseurs",
        "verification_amount1": "Montant 1",
        "verification_amount2": "Montant 2",
        "verification_failed": "Vérification échouée",
        "verification_pending": "En attente de vérification",
        "verify_email": "Cliquez sur le lien dans le mail de confirmation de compte pour valider votre adresse email.",
        "view": "Voir",
        "view_all_help": "Autoriser l'utilisateur à voir les enregistrement qu'il n'a pas créé",
        "view_client": "Voir le client",
        "view_client_portal": "Afficher le portail client",
        "view_dashboard": "Afficher le tabeau de bord",
        "view_documentation": "Voir documentation",
        "view_expense": "Voir la dépense # :expense",
        "view_expense_num": "Dépense # :expense",
        "view_history": "Consulter l'historique",
        "view_in_gateway": "Vue dans: passerelle",
        "view_payment": "Voir le paiement",
        "view_portal": "Voir le portail",
        "view_statement": "Voir le relevé",
        "view_vendor": "Voir Vendeur",
        "viewer": "Spectateur",
        "viewers": "Les Spectateurs",
        "voice_commands": "Commandes vocales",
        "voice_commands_feedback": "Nous travaillons activement à l'amélioration de cette fonctionnalité. Si vous souhaitez l'ajout d'une commande sépcifique, veuillez nous contacter par courriel à :email.",
        "wait_for_upload": "Veuillez patienter pendant le chargement  du fichier",
        "warn_change_auto_bill": "Selon les règles de NACHA, les changements à cette facture pourrait prévenir l'autofacturation de ACH.",
        "warn_start_date_changed": "La prochaine facture sera envoyée à la nouvelle date spécifiée.",
        "warning": "Avertissement",
        "webhook_url": "URL Webhook",
        "website": "Site web",
        "website_help": "Affichez la facture dans un iFrame sur votre propre site Web.",
        "website_url": "URL du site web",
        "wednesday": "Mercredi",
        "week": "Semaine",
        "wepay": "WePay",
        "wepay_account_description": "Passerelle de paiement pour Coaching.com",
        "wepay_company_name_help": "Ceci apparaîtra sur le relevé de carte de crédit du client",
        "wepay_description_help": "Le but de ce compte.",
        "wepay_payment_tos_agree": "J'accepte les :terms et la :privacy_policy de WePay",
        "wepay_payment_tos_agree_required": "Vous devez accepter les conditions d'utilisation et la politique de confidentialité de WePay",
        "wepay_tos_agree": "J'accepte les :link.",
        "wepay_tos_link_text": "Conditions d'utilisation de WePay",
        "what_are_you_working_on": "Sur quoi travailles-tu?",
        "white_label_button": "Marque blanche",
        "white_label_custom_css": ":link for $:price to enable custom styling and help support our project.",
        "white_label_license_key": "Clé de la licence",
        "white_label_purchase_link": "Achetez une licence en marque blanche",
        "white_label_text": "Achetez une licence en marque blanche d'UN AN au coût de $:price pour retirer la marque de Coaching.com des factures et du portail client.",
        "work": "Travail",
        "work_phone": "Téléphone",
        "working": "En cours",
        "wrong_confirmation": "Code de confirmation incorrect",
        "www": "www",
        "year": "Année",
        "yes": "Oui",
        "your_statement": "Votre relevé",
        "zapier": "Zapier",
        "zero-dollars": "0 $"
      },
      "header_titles": {
        "account_managers": "Gestionnaires de Compte",
        "admin_resources": "Fichiers d'engagement",
        "progress_summary": "Résumé des progrès",
        "summary_info": "Informations résumées"
      },
      "invoice": {
        "add_to_invoice": "Ajouter à la facture",
        "amount_due": "Montant dû",
        "amount_paid": "Montant payé",
        "amount_received": "Montant reçu",
        "archive_recurring_invoice": "Archiver facture récurrente",
        "archived_invoice": "Facture archivée avec succès",
        "archived_invoices": ":count factures archivées avec succès",
        "archived_recurring_invoice": "Facture récurrente archivée avec succès",
        "balance": "Montant",
        "cloned_invoice": "Facture dupliquée avec succès",
        "create_new_invoice": "Créer une nouvelle facture",
        "created_by_invoice": "Créé par :invoice",
        "created_invoice": "Facture créée avec succès",
        "created_invoices": ":count factures(s) créées avec succès",
        "custom_invoice_link": "Personnaliser le lien de la facture",
        "default_invoice_footer": "Définir par défaut",
        "default_invoice_terms": "Définir comme les conditions par défaut",
        "delete_recurring_invoice": "Supprimer archive récurrente",
        "deleted_invoice": "Facture supprimée avec succès",
        "deleted_invoices": ":count factures supprimées avec succès",
        "deleted_recurring_invoice": "Facture récurrente supprimée avec succès",
        "discount_amount": "Montant",
        "discount_percent": "Pourcent",
        "emailed_invoice": "Facture envoyée par courriel avec succès",
        "invoice_amount": "Montant de la facture",
        "invoice_charges": "Majoration de facture",
        "invoice_counter": "Compteur de factures",
        "invoice_design": "Modèle de facture",
        "invoice_due_date": "Date limite",
        "invoice_error": "Veuillez vous assurer de sélectionner un client et de corriger les erreurs",
        "invoice_fields": "Champs de facture",
        "invoice_footer": "Pied de facture",
        "invoice_history": "Historique des factures",
        "invoice_issued_to": "Facture destinée à",
        "invoice_labels": "Champs facture",
        "invoice_message": "Pour voir votre facture de :amount, Cliquez sur le lien ci-après.",
        "invoice_no": "Facture n°",
        "invoice_not_found": "La facture demandée n'est pas disponible",
        "invoice_number_counter": "Compteur du numéro de facture",
        "invoice_number_help": "Spécifier un préfixe ou utiliser un modèle personnalisé pour la création du numéro de facture.",
        "invoice_number_prefix": "Préfixe du numéro de facture",
        "invoice_options": "Options de facturation",
        "invoice_quote_number": "Numéro des devis & factures",
        "invoice_sent": ":count facture envoyée",
        "invoice_settings": "Paramètres des factures",
        "invoice_task": "Facturer la tâche",
        "invoice_to": "Facture pour",
        "invoiced": "Facturé",
        "invoices_sent": ":count factures envoyées",
        "last_invoice_sent": "Dernière facture envoyée le :date",
        "new_recurring_invoice": "Nouvelle facture récurrente",
        "next_invoice_number": "Le prochain numéro de facture est :number.",
        "outstanding": "Impayé",
        "paid": "Payé",
        "partial": "Partiel/dépôt",
        "partial_remaining": ":partial de :balance",
        "payment_terms_net": "Net",
        "recurring": "Récurrent",
        "recurring_invoice": "Facture récurrente",
        "recurring_too_soon": "Il est trop tôt pour créer la prochaine facture récurrente, prévue pour le :date",
        "restore_recurring_invoice": "Restaurer facture récurrence",
        "restored_recurring_invoice": "Facture récurrente restaurée avec succès",
        "should_be_invoiced": "Devrait être facturé",
        "total_invoiced": "Total facturé",
        "updated_invoice": "Facture modifiée avec succès",
        "vat_number": "Numéro de TVA",
        "vat_percent": "Pourcentage de TVA (%)",
        "view_invoice": "Voir la facture"
      },
      "location_info": {
        "address": "Adresse",
        "address1": "Rue",
        "address2": "Apt/Suite",
        "city": "Ville",
        "country": "Pays",
        "postal_code": "Code Postal",
        "state": "State/Province"
      },
      "pagination": {
        "next": "Suivant &raquo;",
        "previous": "&laquo; Précédent"
      },
      "passwords": {
        "password": "Les mots de passe doivent avoir au moins six caractères et doivent être identiques.",
        "reset": "Votre mot de passe a été réinitialisé!",
        "sent": "Rappel du mot de passe envoyé !",
        "token": "Ce jeton de réinitialisation du mot de passe n'est pas valide.",
        "user": "Nous ne pouvons trouver cet utilisateur avec cette adresse email."
      },
      "quote": {
        "archive_quote": "Archiver ce devis",
        "archived_quote": "Devis archivé",
        "archived_quotes": ":count devis ont bien été archivés",
        "clone_quote": "Dupliquer ce devis",
        "cloned_quote": "Devis dupliqué",
        "confirm_email_quote": "Voulez-vous vraiment envoyer ce devis par courriel ?",
        "convert_to_invoice": "Convertir en facture",
        "converted_to_invoice": "Le devis a bien été converti en facture",
        "create_quote": "Créer un devis",
        "created_quote": "Devis créé",
        "default_quote_terms": "Conditions des devis par défaut",
        "delete_quote": "Supprimer ce devis",
        "deleted_quote": "Devis supprimé",
        "deleted_quotes": ":count devis ont bien été supprimés",
        "edit_quote": "Éditer ce devis",
        "email_quote": "Envoyer ce devis par courriel",
        "emailed_quote": "Devis envoyé par courriel",
        "expired_quotes": "Devis expirés",
        "new_quote": "Nouveau devis",
        "quote": "Devis",
        "quote_counter": "Compteur de devis",
        "quote_date": "Date du devis",
        "quote_due_date": "Date limite",
        "quote_email": "Email de devis",
        "quote_footer": "Pied de page des devis",
        "quote_history": "Historique des devis",
        "quote_is_approved": "Ce devis a été approuvé",
        "quote_link_message": "Pour visionner votre soumission, cliquez sur le lien ci-dessous:",
        "quote_message": "Pour visionner votre devis de :amount, cliquez sur le lien ci-dessous.",
        "quote_no": "Devis n°",
        "quote_number": "Devis numéro",
        "quote_number_counter": "Compteur du numéro de devis",
        "quote_number_help": "Spécifier un préfixe ou utiliser un modèle personnalisé pour la création du numéro de devis.",
        "quote_number_prefix": "Préfixe du numéro de devis",
        "quote_number_short": "Devis #",
        "quote_subject": "New quote :number from :account",
        "quote_terms": "Conditions des devis",
        "quote_total": "Montant du devis",
        "quotes": "Devis",
        "save_quote": "Enregistrer ce devis",
        "upcoming_quotes": "Devis à venir",
        "updated_quote": "Devis mis à jour",
        "view_quote": "Voir le devis",
        "your_quote": "Votre devis"
      },
      "site_objects": {
        "client": "Client",
        "competency_library": "Bibliothèque Thématique",
        "contacts": "Contacts",
        "engagement_template": "Programme",
        "invoice": "Facture d'achat",
        "organization": "Organisation",
        "untitled_library": "Bibliothèque de thèmes sans titre"
      },
      "statuses": {
        "active": "Actif",
        "approved": "Approuvé",
        "assigned": "Assigné",
        "awaiting_approval": "En Attente d'Approbation",
        "cancelled": "Quitté",
        "completed": "Complété",
        "deleted": "Supprimé",
        "draft": "Brouillon",
        "in_review": "En Revue",
        "internal_record": "Enregistrement Interne",
        "invited": "Invitation Envoyée",
        "paid": "Payé",
        "partial": "Partiel",
        "pending": "En Attente",
        "scheduled": "Plannifié",
        "sent": "Envoyée",
        "status": "Statut",
        "unpaid": "Unpaid",
        "viewed": "Vue"
      },
      "user_data": {
        "email": "Email",
        "first_name": "Prénom",
        "last_name": "Nom de famille",
        "phone": "Téléphone",
        "work_email": "Email",
        "work_phone": "Téléphone"
      },
      "validation": {
        "accepted": "Le champ :attribute doit être accepté.",
        "active_url": "Le champ :attribute n'est pas une URL valide.",
        "after": "Le champ :attribute doit être une date postérieure au :date.",
        "after_or_equal": "La :attribute doit être une date après ou égale au :date.",
        "alpha": "Le champ :attribute doit seulement contenir des lettres.",
        "alpha_dash": "Le champ :attribute doit seulement contenir des lettres, des chiffres et des tirets.",
        "alpha_num": "Le champ :attribute doit seulement contenir des chiffres et des lettres.",
        "array": "Le champ :attribute doit être un tableau.",
        "before": "Le champ :attribute doit être une date antérieure au :date.",
        "before_or_equal": "La :attribute doit être antérieure ou égale àu :date.",
        "between": {
          "array": "Le champ :attribute doit avoir entre :min et :max éléments.",
          "file": "Le fichier :attribute doit avoir une taille entre :min et :max kilobytes.",
          "numeric": "La valeur de :attribute doit être comprise entre :min et :max.",
          "string": "Le texte :attribute doit avoir entre :min et :max caractères."
        },
        "boolean": "Le champ :attribute doit être vrai ou faux.",
        "confirmed": "Le champ de confirmation :attribute ne correspond pas.",
        "date": "Le champ :attribute n'est pas une date valide.",
        "date_format": "Le champ :attribute ne correspond pas au format :format.",
        "different": "Les champs :attribute et :other doivent être différents.",
        "digits": "Le champ :attribute doit avoir :digits chiffres.",
        "digits_between": "Le champ :attribute doit avoir entre :min and :max chiffres.",
        "dimensions": "L':attribute a des dimensions d'image incorrectes.",
        "distinct": "Le champ de :attribute a une valeur en double.",
        "email": "Le champ :attribute doit être une adresse email valide.",
        "exists": "Le champ :attribute sélectionné est invalide.",
        "file": "Le champ :attribute doit être un fichier.",
        "filled": "Le champ de :attribute doit avoir une valeur.",
        "image": "Le champ :attribute doit être une image.",
        "in": "Le champ :attribute est invalide.",
        "in_array": "Le champ :attribute n'existe pas dans :other.",
        "integer": "Le champ :attribute doit être un entier.",
        "ip": "Le champ :attribute doit être une adresse IP valide.",
        "ipv4": "Le champ :attribute doit être une adresse IPv4 valide.",
        "ipv6": "Le champ :attribute doit être une adresse IPv6 valide.",
        "json": "Le champ :attribute doit être une chaîne JSON valide.",
        "max": {
          "array": "Le champ :attribute ne peut avoir plus de :max éléments.",
          "file": "Le fichier :attribute ne peut être plus gros que :max kilobytes.",
          "numeric": "La valeur de :attribute ne peut être supérieure à :max.",
          "string": "Le texte de :attribute ne peut contenir plus de :max caractères."
        },
        "mimes": "Le champ :attribute doit être un fichier de type : :values.",
        "mimetypes": "Le champ :attribute doit être un fichier de type: :values.",
        "min": {
          "array": "Le champ :attribute doit avoir au moins :min éléments.",
          "file": "Le fichier :attribute doit être plus que gros que :min kilobytes.",
          "numeric": "La valeur de :attribute doit être supérieure à :min.",
          "string": "Le texte :attribute doit contenir au moins :min caractères."
        },
        "not_in": "Le champ :attribute sélectionné n'est pas valide.",
        "numeric": "Le champ :attribute doit contenir un nombre.",
        "present": "Le champ :attribute doit être présent.",
        "regex": "Le format du champ :attribute est invalide.",
        "required": "Le champ :attribute est obligatoire.",
        "required_if": "Le champ :attribute est obligatoire quand la valeur de :other est :value.",
        "required_unless": "Le champ :attribute est requis sauf si :other est dans :values.",
        "required_with": "Le champ :attribute est obligatoire quand :values est présent.",
        "required_with_all": "Le champ :attribute est obligatoire quand :values est présent.",
        "required_without": "Le champ :attribute est obligatoire quand :values n'est pas présent.",
        "required_without_all": "Le champ :attribute est requis quand aucun de :values n'est présent.",
        "same": "Les champs :attribute et :other doivent être identiques.",
        "size": {
          "array": "Le champ :attribute doit contenir :size éléments.",
          "file": "La taille du fichier de :attribute doit être de :size kilobytes.",
          "numeric": "La valeur de :attribute doit être :size.",
          "string": "Le texte de :attribute doit contenir :size caractères."
        },
        "string": "Le champ :attribute doit être une chaîne.",
        "timezone": "Le champ :attribute doit être une zone valide.",
        "unique": "La valeur du champ :attribute est déjà utilisée.",
        "uploaded": "Le champ :attribute n'a pas pu être téléchargé.",
        "url": "Le format de l'URL de :attribute n'est pas valide."
      },
      "vendor": {
        "archived_vendor": "Le fournisseur a été archivé",
        "archived_vendors": ":count fournisseurs archivés",
        "created_vendor": "Le fournisseur a été créé",
        "deleted_vendor": "Le fournisseur a été supprimé",
        "deleted_vendors": ":count fournisseurs supprimés",
        "updated_vendor": "Le fournisseur a été mis à jour",
        "vendor": "Le fournisseur"
      }
    },
    "icon": {
      "add": "add",
      "chat": "sms",
      "company": "business",
      "complete": "done",
      "completed": "done",
      "dashboard": "network_check",
      "delete": "delete",
      "doc": "description",
      "download": "file_download",
      "edit": "edit",
      "engagement": "flag",
      "engagement-milestone": "flag",
      "engagement-session": "people",
      "feedback": "send",
      "file": "description",
      "form": "assignment_turned_in",
      "goal": "my_location",
      "image": "image",
      "info": "info",
      "interaction": "people",
      "invoice": "inbox",
      "late-cancel": "close",
      "library": "account_balance",
      "manage-people": "person_add",
      "milestone": "flag",
      "multiple-choice": "check_box",
      "next-steps": "check_box",
      "note": "assignment",
      "notifications": "notifications",
      "pdf": "description",
      "people": "person",
      "ppt": "personal_video",
      "preview": "open_in_new",
      "profile": "account_circle",
      "program": "format_list_bulleted",
      "rate": "rate_review",
      "report": "assessment",
      "request-schedule": "alarm",
      "reschedule": "update",
      "resources": "account_balance",
      "roadmap": "flag",
      "schedule": "date_range",
      "scheduled": "date_range",
      "send": "send",
      "send-request-to-schedule": "flash_on",
      "sent": "send",
      "settings": "settings",
      "share": "share",
      "single-choice": "radion_button_checked",
      "stripe": "local_atm",
      "support": "help",
      "survey": "assignment_turned_in",
      "task": "check_box",
      "text-field": "text-fields",
      "visibility": "visibility",
      "watch-later": "watch_later",
      "xls": "view_list"
    },
    "index": {
      "credit-card-expiring": {
        "button": "Mise à jour",
        "message": "Bonjour {user}, il semble que le paiement associé à votre compte expirera le {date}. Veuillez mettre à jour les informations de paiement pour éviter l'inactivation du compte."
      },
      "deliquent-account": {
        "button": "Mise à jour",
        "message": "Bonjour {user}, il semble qu'il y ait eu des problèmes lors du traitement de votre paiement. Votre compte est désormais en souffrance. Veuillez mettre à jour votre mode de paiement avant (2 semaines à compter du début de la délinquance) pour éviter l'inactivation du compte."
      },
      "frozen-account": {
        "button": "Mise à jour",
        "dialog": {
          "admin-message": "Votre dernier paiement est en souffrance. Un problème est survenu lors du traitement de votre carte. Veuillez mettre à jour votre méthode de paiement.",
          "others-message": "Votre compte est en souffrance. Veuillez demander à un administrateur de mettre à jour le mode de paiement.",
          "title": "Compte gelé"
        },
        "message": "Bonjour {user}, il semble qu'il y ait eu des problèmes lors du traitement de votre paiement. Votre compte est maintenant en souffrance; tous les engagements actifs ont été temporairement rendus inactifs. Pour annuler cette modification, veuillez mettre à jour votre mode de paiement."
      },
      "mobile": {
        "account": "Compte",
        "messages": "Messages",
        "notifications": "Notifications"
      },
      "mobile-app": {
        "description": "<strong>Coaching.com</strong> fournit une plate-forme tout-en-un permettant aux coachs et à leurs clients de gérer leurs relations de coaching. De la visioconférence et du chat à la facturation et au partage de documents, cette application permet de tirer le meilleur parti des relations de coaching.",
        "go-to-app-store": "Accédez à l'App Store"
      },
      "nav": {
        "chat": "Chat en Direct",
        "clients": "Clients",
        "coach-matching": "Matching d'entraîneur",
        "coaches": "Entraîneurs",
        "company-resources": "Ressources",
        "dashboard": "Tableau de suivi",
        "employees": "Employés",
        "engagements": "Engagements",
        "help": "Aidez-moi",
        "invite-members": "Inviter un utilisateur",
        "invoices": "Factures",
        "logout": "Deconnecter",
        "my-profile": "Mon Profil",
        "notifications": "Les notifications",
        "open-support": "Options de Support",
        "personal-settings": "Mon Paramètres",
        "profile": "Profil",
        "reports": "Rapports",
        "resources": "Ressources",
        "settings": "Paramètres d'entreprise",
        "support": "Soutien",
        "switch-account": "Changer de compte",
        "switch-context": "Changer de contexte d'entreprise",
        "vendors": "Vendeurs"
      }
    },
    "invoice": {
      "add-expense": "Ajouter une dépense",
      "add-payment": "Ajouter un paiement",
      "add-session": "Ajouter une session",
      "add_documents_to_invoice": "Ajouter des documents à la facture",
      "add_new_session": "Ajouter une nouvelle session",
      "adjustment": "Réglements",
      "already_paid": "Cette facture a déjà été payée.",
      "amount": "Montant",
      "amount_paid": "Le montant payé",
      "and_created_client": "et client créé",
      "applied_payment": "Paiement appliqué avec succès",
      "archive_invoice": "Archiver la facture",
      "archived_credit": "Crédit archivé avec succès",
      "archived_credits": ":count crédits archivés avec succès",
      "archived_invoice": "Facture archivée avec succès",
      "archived_invoices": ":count factures archivées avec succès",
      "archived_payment": "Paiement archivé avec succès",
      "archived_payments": ":count paiements archivés avec succès",
      "auto_bill_failed": "Auto-billing for invoice :invoice_number failed",
      "average_invoice": "Facture moyenne",
      "balance": "Montant",
      "balance_due": "Montant dû",
      "bill-to": "Facturer",
      "billed": "Billed",
      "billed_client": "billed client",
      "billed_clients": "billed clients",
      "billing-method": {
        "billable_hours": "Billable hours",
        "unit_cost": "Unit cost"
      },
      "charge_late_fee": "Charge Late Fee",
      "charge_taxes": "Charge taxes",
      "choose_credit_card": "Choisissez une carte de crédit",
      "clone_invoice": "Dupliquer la facture",
      "cloned_invoice": "Successfully cloned invoice",
      "collect-via-stripe": "Souhaitez-vous percevoir un paiement par bande?",
      "collect_via_stripe": "Souhaitez-vous percevoir un paiement par bande?",
      "confirm_delete_invoice": "Êtes-vous sûr de vouloir supprimer cette facture?",
      "confirm_email_invoice": "Are you sure you want to email this invoice?",
      "confirm_send_invoice": "Êtes-vous sûr de vouloir envoyer cette facture à",
      "cost": "Coût",
      "create_credit_note": "Create Credit Note",
      "create_invoice": "Créer une facture",
      "created_credit": "Successfully created credit",
      "created_invoice": "Successfully created invoice",
      "created_payment": "Successfully created payment",
      "created_payment_and_credit": "Successfully created payment and credit",
      "created_payment_and_credit_emailed_client": "Successfully created payment and credit, and emailed client",
      "created_payments": "Successfully created :count payment(s)",
      "credit": "Credit",
      "credit_amount": "Montant du crédit",
      "credit_balance": "Solde du crédit",
      "credit_card": {
        "card_number": "Card Number",
        "cvv": "CVV",
        "expiration_month": "Expiration Month",
        "expiration_year": "Expiration Year",
        "update_credit_card": "Update Credit Card",
        "update_payment_details": "Update payment details",
        "updated_payment_details": "Successfully updated payment details"
      },
      "credit_created_by": "Credit created by payment :transaction_reference",
      "credit_date": "Date de crédit",
      "credit_issued_to": "Credit issued to",
      "credit_note": "Credit Note",
      "credit_number": "Credit Number",
      "credit_number_help": "Specify a prefix or use a custom pattern to dynamically set the credit number for negative invoices.",
      "credit_to": "Credit to",
      "credit_total": "Credit Total",
      "credits": "Crédits",
      "currencies": {
        "argentine_peso": "Argentine Peso",
        "aruban_florin": "Aruban Florin",
        "australian_dollar": "Australian Dollar",
        "bangladeshi_taka": "Bangladeshi Taka",
        "brazilian_real": "Brazilian Real",
        "british_pound": "British Pound",
        "bulgarian_lev": "Bulgarian Lev",
        "canadian_dollar": "Canadian Dollar",
        "chilean_peso": "Chilean Peso",
        "chinese_renminbi": "Chinese Renminbi",
        "colombian_peso": "Colombian Peso",
        "costa_rican_colon": "Costa Rican Colón",
        "croatian_kuna": "Croatian Kuna",
        "czech_koruna": "Czech Koruna",
        "danish_krone": "Danish Krone",
        "dominican_peso": "Dominican Peso",
        "east_caribbean_dollar": "East Caribbean Dollar",
        "egyptian_pound": "Egyptian Pound",
        "euro": "Euro",
        "ghanaian_cedi": "Ghanaian Cedi",
        "guatemalan_quetzal": "Guatemalan Quetzal",
        "hong_kong_dollar": "Hong Kong Dollar",
        "icelandic_krona": "Icelandic Króna",
        "indian_rupee": "Indian Rupee",
        "indonesian_rupiah": "Indonesian Rupiah",
        "israeli_shekel": "Israeli Shekel",
        "japanese_yen": "Japanese Yen",
        "jordanian_dinar": "Jordanian Dinar",
        "kenyan_shilling": "Kenyan Shilling",
        "macanese_pataca": "Macanese Pataca",
        "malaysian_ringgit": "Malaysian Ringgit",
        "maldivian_rufiyaa": "Maldivian Rufiyaa",
        "mexican_peso": "Mexican Peso",
        "mozambican_metical": "Mozambican Metical",
        "myanmar_kyat": "Myanmar Kyat",
        "namibian_dollar": "Namibian Dollar",
        "netherlands_antillean_guilder": "Netherlands Antillean Guilder",
        "new_zealand_dollar": "New Zealand Dollar",
        "nigerian_naira": "Nigerian Naira",
        "norske_kroner": "Norske Kroner",
        "omani_rial": "Omani Rial",
        "pakistani_rupee": "Pakistani Rupee",
        "papua_new_guinean_kina": "Papua New Guinean Kina",
        "peruvian_sol": "Peruvian Sol",
        "philippine_peso": "Philippine Peso",
        "polish_zloty": "Polish Zloty",
        "romanian_new_leu": "Romanian New Leu",
        "russian_ruble": "Russian Ruble",
        "rwandan_franc": "Rwandan Franc",
        "saudi_riyal": "Saudi Riyal",
        "singapore_dollar": "Singapore Dollar",
        "south_african_rand": "South African Rand",
        "sri_lankan_rupee": "Sri Lankan Rupee",
        "swedish_krona": "Swedish Krona",
        "swiss_franc": "Swiss Franc",
        "taiwan_new_dollar": "Taiwan New Dollar",
        "tanzanian_shilling": "Tanzanian Shilling",
        "thai_baht": "Thai Baht",
        "trinidad_and_tobago_dollar": "Trinidad and Tobago Dollar",
        "tunisian_dinar": "Tunisian Dinar",
        "turkish_lira": "Turkish Lira",
        "ukrainian_hryvnia": "Ukrainian Hryvnia",
        "united_arab_emirates_dirham": "United Arab Emirates Dirham",
        "uruguayan_peso": "Uruguayan Peso",
        "us_dollar": "US Dollar",
        "vietnamese_dong": "Vietnamese Dong",
        "west_african_franc": "West African Franc"
      },
      "currency": "Devise",
      "date": "Date",
      "delete": "Effacer",
      "delete-invoice-confirmation": "Êtes-vous sûr de vouloir supprimer cette facture?",
      "delete-payment-confirmation": "Êtes-vous sûr de vouloir supprimer ce paiement?",
      "delete-selected": "Supprimer sélectionnée",
      "delete_invoice": "Supprimer la facture",
      "delete_selected": "Supprimer sélectionnée",
      "deleted_credit": "Successfully deleted credit",
      "deleted_credits": "Successfully deleted :count credits",
      "deleted_invoice": "Successfully deleted invoice",
      "deleted_invoices": "Successfully deleted :count invoices",
      "deleted_payment": "Successfully deleted payment",
      "deleted_payments": "Successfully deleted :count payments",
      "direct_invoice": "Facture directe",
      "discount": "Remise",
      "discount_amount": "Amount",
      "discount_percent": "Percent",
      "download-pdf": "Télécharger le PDF",
      "download_invoice": "Download Invoice",
      "download_quote": "Download Quote",
      "downloaded_invoice": "An email will be sent with the invoice PDF",
      "downloaded_invoices": "An email will be sent with the invoice PDFs",
      "downloaded_quote": "An email will be sent with the quote PDF",
      "downloaded_quotes": "An email will be sent with the quote PDFs",
      "due-date": "Échéance",
      "edit_invoice": "Éditer la facture",
      "edit_payment": "Edit Payment",
      "email_invoice": "Envoyer la facture par courriel",
      "email_paid": "m'envoyer un courriel quand une facture est <b>payée</b>",
      "email_sent": "m'envoyer un courriel quand une facture est <b>envoyée</b>",
      "email_viewed": "m'envoyer un courriel quand une facture est <b>vue</b>",
      "emailed_invoice": "Successfully emailed invoice",
      "enable_invoice_tax": "Spécifier une <b>taxe pour la facture</b>",
      "enable_line_item_tax": "Spécifier une <b>taxe pour chaque ligne</b>",
      "enter_credit": "Saisissez un crédit",
      "enter_payment": "Saisissez un paiement",
      "error": "An error ocurred. Please check for errors on invoice.",
      "expenses": {
        "clone_expense": "Clone Expense",
        "created_expenses": "Successfully created :count expense(s)",
        "duplicate_expense_warning": "Warning: This :link may be a duplicate",
        "expense_category": "Expense Category",
        "expense_link": "expense",
        "expense_will_create": "expense will be created",
        "expenses_will_create": "expenses will be created",
        "total_expenses": "Total Expenses"
      },
      "fee_help": "Gateway fees are the costs charged for access to the financial networks that handle the processing of online payments.",
      "frequency": "Fréquence",
      "frequency_alt": "À quelle fréquence",
      "gateway": "Fournisseur",
      "gateway_help_1": ":link to sign up for Authorize.net.",
      "gateway_help_17": ":link to get your PayPal API signature.",
      "gateway_help_2": ":link to sign up for Authorize.net.",
      "gateway_help_23": ":link to get your Stripe API keys.",
      "include_item_taxes_inline": "Include <b>line item taxes in line total</b>",
      "invoice-number": "Numéro de facture",
      "invoice-number-short": "Facture d'achat #",
      "invoice-to": "Facture à",
      "invoice_date": "Date de facture",
      "invoice_design": "Design",
      "invoice_error": "Please make sure to select a client and correct any errors",
      "invoice_fields": "Invoice Fields",
      "invoice_history": "Invoice History",
      "invoice_issued_to": "Invoice issued to",
      "invoice_link_message": "To view the invoice click the link below:",
      "invoice_number_counter": "Invoice Number Counter",
      "invoice_number_prefix": "Invoice Number Prefix",
      "invoice_options": "Invoice Options",
      "invoice_sent_error": "Une erreur s'est produite lors de l'envoi de la facture.",
      "invoice_sent_successfully": "Facture envoyée avec succès.",
      "invoice_settings": "Invoice Settings",
      "invoice_terms": "Conditions de facturation",
      "invoice_total": "Montant total",
      "invoices": "Factures",
      "invoices_are_attached": "Your invoice PDFs are attached.",
      "issued-date": "Date de publication",
      "item": "Item",
      "late_fee_added": "Late fee added on :date",
      "late_fee_amount": "Late Fee Amount",
      "late_fee_percent": "Late Fee Percent",
      "line-item": "Élément de campagne",
      "line_item": "Élément de campagne",
      "line_total": "Total",
      "list": {
        "amount": "Amount",
        "description": "Description",
        "number": "#",
        "taxable": "Taxable"
      },
      "mark-paid": "Mark payé",
      "mark-unpaid": "Mark impayé",
      "mark_billable": "Mark billable",
      "mark_sent": "Mark Sent",
      "method": "Method",
      "minutes": "minutes",
      "muliplication_sign": "X",
      "new_credit": "Entrer un crédit",
      "new_invoice": "Nouvelle facture",
      "new_payment": "Entrer un paiement",
      "no-items-in-invoice": "Aucun article n'a été ajouté à cette facture.",
      "no-payments": "Aucun paiement",
      "no_session": "Pas de sessions facturables disponibles.",
      "note_to_client": "Note to Client",
      "notes": "Notes",
      "notification_invoice_paid": "A payment of :amount was made by client :client towards Invoice :invoice.",
      "notification_invoice_paid_subject": "Invoice :invoice was paid by :client",
      "notification_invoice_sent": "The following client :client was emailed Invoice :invoice for :amount.",
      "notification_invoice_sent_subject": "Invoice :invoice was sent to :client",
      "notification_invoice_viewed": "The following client :client viewed Invoice :invoice for :amount.",
      "notification_invoice_viewed_subject": "Invoice :invoice was viewed by :client",
      "number": "Facture #{number}",
      "online_payments": "Online Payments",
      "paid_to_date": "Payé à ce jour",
      "partial": "Partial/Deposit",
      "partial_remaining": ":partial of :balance",
      "partials": {
        "asked-to-pay": "On vous a demandé de payer",
        "has-sent-invoice": "vous a envoyé une facture due le"
      },
      "pay-now": "Payer maintenant",
      "pay-tooltip": "Payeur",
      "pay_with_card": "Payer avec la carte",
      "payment": "Paiement",
      "payment_amount": "Montant du paiement",
      "payment_created_successfully": "Paiement créé avec succès.",
      "payment_date": "Date du paiement",
      "payment_error": "There was an error processing your payment. Please try again later.",
      "payment_gateway": "Passerelle de paiement",
      "payment_method": "Mode de paiement",
      "payment_terms": {
        "archived_payment_term": "Successfully archived payment term",
        "created_payment_term": "Successfully created payment term",
        "updated_payment_term": "Successfully updated payment term"
      },
      "payment_type": "Type de paiement",
      "payment_types": {
        "bitcoin": "Bitcoin",
        "credit_card": "Credit Card",
        "paypal": "PayPal"
      },
      "payments": "Paiements",
      "pdf_email_attachment": "Joindre la facture",
      "percent_sign": "%",
      "po-code": "Code de commande",
      "po_number_short": "Bon de commande #",
      "products": {
        "add_item": "Add Item",
        "archive_product": "Archive Product",
        "archived_product": "Successfully archived product",
        "archived_products": "Successfully archived :count products",
        "create_product": "Add Product",
        "created_product": "Successfully created product",
        "delete_product": "Delete product",
        "deleted_product": "Successfully deleted product",
        "deleted_products": "Successfully deleted :count products",
        "edit_product": "Edit Product",
        "fill_products": "Auto-fill products",
        "fill_products_help": "Selecting a product will automatically <b>fill in the description and cost</b>",
        "item_cost": "Item Cost",
        "item_notes": "Item Notes",
        "item_product": "Item Product",
        "item_quantity": "Item Quantity",
        "item_tax1": "Item Tax1",
        "item_tax2": "Item Tax2",
        "item_tax_name": "Item Tax Name",
        "item_tax_rate": "Item Tax Rate",
        "list_products": "List Products",
        "product": "Product",
        "product_library": "Product Library",
        "product_notes": "Product Notes",
        "products": "Products",
        "restored_product": "Successfully restored product",
        "update_products": "Auto-update products",
        "update_products_help": "Updating an invoice will automatically <b>update the product library</b>",
        "updated_product": "Successfully updated product"
      },
      "quantity": "Quantity",
      "quotes": {
        "archive_quote": "Archive Quote",
        "archived_quote": "Successfully archived quote",
        "archived_quotes": "Successfully archived :count quotes",
        "clone_quote": "Clone Quote",
        "cloned_quote": "Successfully cloned quote",
        "confirm_email_quote": "Are you sure you want to email this quote?",
        "convert_to_invoice": "Convert to Invoice",
        "converted_to_invoice": "Successfully converted quote to invoice",
        "create_quote": "Create Quote",
        "created_quote": "Successfully created quote",
        "delete_quote": "Delete Quote",
        "deleted_quote": "Successfully deleted quote",
        "deleted_quotes": "Successfully deleted :count quotes",
        "edit_quote": "Edit Quote",
        "email_approved": "Email me when a quote is <b>approved</b>",
        "email_quote": "Email Quote",
        "emailed_quote": "Successfully emailed quote",
        "new_quote": "New Quote",
        "notification_quote_approved": "The following client :client approved Quote :invoice for :amount.",
        "notification_quote_approved_subject": "Quote :invoice was approved by :client",
        "notification_quote_sent": "The following client :client was emailed Quote :invoice for :amount.",
        "notification_quote_sent_subject": "Quote :invoice was sent to :client",
        "notification_quote_viewed": "The following client :client viewed Quote :invoice for :amount.",
        "notification_quote_viewed_subject": "Quote :invoice was viewed by :client",
        "quote": "Quote",
        "quote_date": "Quote Date",
        "quote_history": "Quote History",
        "quote_link_message": "To view your client quote click the link below:",
        "quote_message": "To view your quote for :amount, click the link below.",
        "quote_number": "Quote Number",
        "quote_number_counter": "Quote Number Counter",
        "quote_number_prefix": "Quote Number Prefix",
        "quote_number_short": "Quote #",
        "quote_subject": "New quote :number from :account",
        "quote_total": "Quote Total",
        "quotes": "Quotes",
        "restore_quote": "Restore Quote",
        "restored_quote": "Successfully restored quote",
        "save_quote": "Save Quote",
        "updated_quote": "Successfully updated quote",
        "view_quote": "View Quote",
        "your_quote": "Your Quote"
      },
      "rate": "Taux",
      "recurring": {
        "archive_recurring_expense": "Archive Recurring Expense",
        "archived_recurring_expense": "Successfully archived recurring expense",
        "confirm_recurring_email_invoice": "Are you sure you want this invoice emailed?",
        "created_recurring_expense": "Successfully created recurring expense",
        "delete_recurring_expense": "Delete Recurring Expense",
        "deleted_recurring_expense": "Successfully deleted project",
        "edit_recurring_expense": "Edit Recurring Expense",
        "list_recurring_expense": "List Recurring Expenses",
        "new_recurring_expense": "New Recurring Expense",
        "recurring_expense": "Recurring Expense",
        "recurring_expenses": "Recurring Expenses",
        "restore_recurring_expense": "Restore Recurring Expense",
        "restored_recurring_expense": "Successfully restored recurring expense",
        "updated_recurring_expense": "Successfully updated recurring expense",
        "view_recurring_expense": "View Recurring Expense"
      },
      "reset_counter_help": "Automatically reset the invoice and quote counters.",
      "restore_credit": "Restore Credit",
      "restore_invoice": "Restore Invoice",
      "restore_payment": "Restore Payment",
      "restored_credit": "Successfully restored credit",
      "restored_invoice": "Successfully restored invoice",
      "restored_payment": "Successfully restored payment",
      "save_as_default_terms": "Save as default terms",
      "save_for_later": "Garder pour plus tard",
      "save_invoice": "Sauvegarder la facture",
      "secure_payment": "Secure Payment",
      "select_engagement": "Sélectionnez un engagement",
      "select_session": "Sélectionnez une session",
      "select_tax": "Sélectionner une taxe",
      "send-again": "Envoyer à nouveau",
      "send-invoice": "Envoyer une facture",
      "send-invoice-confirmation": "Êtes-vous sûr de vouloir envoyer cette facture à <strong>{client}</strong>",
      "send_invoice": "Envoyer une facture",
      "standing": "Standing",
      "stripe": "Bande",
      "subtotal": "Subtotal",
      "success": "Invoice saved successfully.",
      "tax": "Taxe",
      "tax-approach": "Approche fiscale",
      "tax1": "First Tax",
      "tax2": "Second Tax",
      "tax_rates": "Taux de taxe",
      "taxable-amount": "Taxable amount",
      "taxes": "Taxes",
      "taxes-applied": "Taxes applied",
      "taxes_and_fees": "Taxes and fees",
      "terms": "Conditions",
      "title": "Facture",
      "total": "Total",
      "transaction_reference": "Référence de la transaction",
      "unit_cost": "Unit Cost",
      "untitled_session": "Session sans titre",
      "upcoming_invoices": "Factures à venir",
      "updated_invoice": "Successfully updated invoice",
      "updated_payment": "Successfully updated payment",
      "vat": "VAT",
      "vat-applied": "TVA appliquée",
      "vat_number": "VAT Number",
      "vendors": {
        "archived_vendor": "Successfully archived vendor",
        "archived_vendors": "Successfully archived :count vendors",
        "created_vendor": "Successfully created vendor",
        "deleted_vendor": "Successfully deleted vendor",
        "deleted_vendors": "Successfully deleted :count vendors",
        "updated_vendor": "Successfully updated vendor"
      },
      "view_invoice": "View Invoice",
      "your_credit": "Your Credit",
      "your_invoice": "Votre facture"
    },
    "invoices": {
      "amount-outstanding": "Montant En Circulation",
      "amount-received": "Montant Reçu",
      "delete-invoice-confirmation": "Êtes-vous sûr de vouloir supprimer cette facture?",
      "delete-invoice-title": "Supprimer la facture",
      "delete-tooltip": "Supprimer",
      "loading": "Chargement...",
      "no-invoices": "Pas de factures",
      "no-vat-message": "Vous devez configurer les paramètres de votre facture avant d’ajouter la TVA.",
      "search": "Rechercher",
      "table": {
        "headers": {
          "actions": "Actions",
          "amount": "Montant",
          "balance": "Équilibre",
          "client": "Client",
          "company": "Entreprise",
          "created-date": "Date de création",
          "due": "Échéance",
          "issued": "Publié",
          "number": "Facture #",
          "paid": "Payé",
          "status": "Statut",
          "total-paid": "Total payé",
          "vendor": "Vendeur"
        }
      },
      "tabs": {
        "received": "Reçu",
        "sent": "Envoyé"
      },
      "title": "Factures",
      "total-invoiced": "Total Facturé",
      "total-invoices": "Total Des Factures"
    },
    "marketplace": {
      "coach-list": {
        "add-filter": "Ajouter un filtre",
        "apply": "Appliquer",
        "clear": "Claire",
        "empty-state": "Nous n'avons trouvé aucun profil correspondant à vos critères.",
        "filtered-by": "Filtrer par",
        "filters": "Filtres",
        "my-marketplace": {
          "interviews": "Entrevues",
          "orders": "Ordres"
        },
        "read-more": "lire le suite",
        "search-placeholder": "Recherchez votre coach...",
        "see-more": "en savoir plus",
        "view-profile": "voir le profil"
      },
      "find-coach": "Trouver un coach",
      "get-started": "Commencer",
      "interview-already-scheduled": "Vous avez déjà programmé un entretien avec ce coach.",
      "login": "S'identifier",
      "not-allowed-error": "Pour le moment, seuls les coachés indépendants peuvent utiliser notre marché. Pour interagir avec le marché, veuillez vous déconnecter de tout compte Coaching.com affilié à une autre organisation.",
      "platform": "Plate-forme",
      "pricing": "Tarification",
      "solutions": "Solutions"
    },
    "matching-setting": {
      "delete": "Supprimer le paramètre correspondant",
      "general-info": "Informations générales",
      "interview-description-label": "Saisissez la description que vous souhaitez que les coachés voient pour l'entrevue",
      "interview-details": "Détails de l'entrevue",
      "interview-duration-label": "Durée",
      "interview-name-label": "Nom de l'entrevue",
      "max-coach-requests": "Y a-t-il une limite au nombre d'entretiens correspondants que les coachés peuvent planifier? (0 = illimité)",
      "max-coach-requests-title": "Nombre maximal de demandes d'entretien",
      "no-interview-allowed": "Les coachés doivent-ils pouvoir interviewer des coachs potentiels?",
      "no-interview-allowed-title": "Autoriser les entretiens correspondants",
      "required-interview": "Les coachés devraient-ils être tenus d'interroger les coachs avant de faire une sélection?",
      "required-interview-title": "Entretien correspondant requis",
      "start-engagement": "Les engagements doivent-ils commencer automatiquement après la sélection d'un coach?",
      "start-engagement-title": "Démarrer automatiquement les engagements",
      "welcome-message": "Message de bienvenue"
    },
    "messaging": {
      "channel-info": "Infos sur la chaîne",
      "channel-topic-dialog": {
        "input-label": "Sujet de la chaîne",
        "title": "Sujet de la chaîne"
      },
      "click-here": "Cliquez ici",
      "close": "Fermer la fenêtre",
      "coaching-request-card": {
        "awaiting-admin-approval": "En attente de l'approbation de l'administrateur",
        "cancel-confirmation-dialog": {
          "description": "Êtes-vous sûr de vouloir annuler cette interview?",
          "title": "Annuler l'entretien"
        },
        "cancel-interview": "Annuler l'entretien",
        "complete-interview": "Entretien complet",
        "error": "Cette demande d'interview n'a pas pu être chargée. L'engagement a peut-être été supprimé.",
        "interview-canceled": "Cette interview a été annulée",
        "interview-declined": "Admin a refusé cette interview",
        "loading": "Chargement de la demande d'interview...",
        "no-location": "Aucun lieu de réunion défini.",
        "reschedule-interview": "Reprogrammer l'entretien",
        "schedule-interview": "Entretien de planification",
        "set-meeting-location": "Définir le lieu de la réunion",
        "title": "Demande d'entrevueq"
      },
      "enter_message": "entrez votre message",
      "group-channels": "Canaux de groupe",
      "info": {
        "add-participant": "Ajouter des participants",
        "channel-topic": "Sujet de la chaîne",
        "delete-group": "Supprimer le groupe",
        "general": "Générale",
        "leave-group": "Quitter le groupe",
        "participants": "Participants"
      },
      "is_typing": "est en train d'écrire...",
      "loading_messages": "Chargement des messages ...",
      "messages": "messages",
      "messages_with": "Messages avec",
      "messaging_center": "Centre de messagerie",
      "new_conversation": "Nouvelle conversation",
      "new_message": "Nouveau message",
      "no-conversations": "Pas de conversations",
      "no-group-channels": "Aucun canal de groupe n'a encore été créé.",
      "no-messages": "Pas de messages.",
      "no-personal-channels": "Aucun canal personnel n'a encore été créé.",
      "no_users": "Aucun utilisateur n'a été trouvé.",
      "open": "Centre de messagerie ouvert",
      "open_messaging": "Ouvrir dans le centre de messagerie",
      "personal-channels": "Canaux personnels",
      "sent_on": "envoyé sur",
      "title": "Messagerie",
      "you": "Vous",
      "zombie": "Ce fil de discussion est désactivé"
    },
    "messaging-types": {
      "accept_contract": {
        "name": ""
      },
      "archive_expense": {
        "name": ""
      },
      "archive_task": {
        "name": ""
      },
      "archive_vendor": {
        "name": ""
      },
      "assign_task": {
        "name": ""
      },
      "cancel_session": {
        "name": ""
      },
      "coach_suggestion": {
        "name": ""
      },
      "complete_task": {
        "name": ""
      },
      "confirm_session": {
        "name": ""
      },
      "create_client": {
        "name": ""
      },
      "create_contract": {
        "name": ""
      },
      "create_credit": {
        "name": ""
      },
      "create_engagement": {
        "name": ""
      },
      "create_expense": {
        "name": ""
      },
      "create_payment": {
        "name": ""
      },
      "create_task": {
        "name": ""
      },
      "create_vendor": {
        "name": ""
      },
      "delete_client": {
        "name": ""
      },
      "delete_credit": {
        "name": ""
      },
      "delete_engagement": {
        "name": ""
      },
      "delete_expense": {
        "name": ""
      },
      "delete_payment": {
        "name": ""
      },
      "delete_task": {
        "name": ""
      },
      "delete_vendor": {
        "name": ""
      },
      "engagement_file_uploaded": {
        "name": ""
      },
      "engagement_file_viewed": {
        "name": ""
      },
      "engagement_form_complete": {
        "name": ""
      },
      "engagement_form_received": {
        "name": ""
      },
      "failed_payment": {
        "name": ""
      },
      "invite_coach": {
        "name": ""
      },
      "invited_employee_account": {
        "name": ""
      },
      "publish_engagement": {
        "name": ""
      },
      "receive_invoice": {
        "name": ""
      },
      "refunded_payment": {
        "name": ""
      },
      "request_session": {
        "name": "Session Scheduling Request"
      },
      "reschedule_session": {
        "name": ""
      },
      "restore_expense": {
        "name": ""
      },
      "restore_payment": {
        "name": ""
      },
      "restore_task": {
        "name": ""
      },
      "restore_vendor": {
        "name": ""
      },
      "schedule_session": {
        "name": "Session planifiée"
      },
      "session_reminder": {
        "name": "Rappel"
      },
      "update_contract": {
        "name": ""
      },
      "update_engagement": {
        "name": ""
      },
      "update_expense": {
        "name": ""
      },
      "update_invoice": {
        "name": ""
      },
      "update_task": {
        "name": ""
      },
      "voided_payment": {
        "name": ""
      }
    },
    "models": {
      "account": {
        "logo-updated": "Photo de profil mise à jour."
      },
      "billing-approach": {
        "name-label": "Nom"
      },
      "education-degree": {
        "create-label": "Ajouter l'historique de l'éducation",
        "degree-label": "Degré",
        "edit-label": "Modifier l'historique de l'éducation",
        "graduation-date-label": "Date d'obtention du diplôme",
        "major-label": "Majeur",
        "school-label": "Nom de l'école",
        "start-date-label": "Date de début"
      },
      "employee": {
        "employee-title-label": "Titre",
        "internal-identification-number-label": "ID de travail",
        "work-email-label": "Email",
        "work-phone-label": "Téléphone"
      },
      "model-deleted": "{model} supprimé.",
      "model-saved": "{model} enregistré.",
      "names": {
        "account": "Compte",
        "account-messaging": "Messagerie du compte",
        "account-payment-gateway": "Passerelle de paiement de compte",
        "account-setting": "Paramètre du compte",
        "account-stripe-card": "Carte de compte",
        "account-summary": "Relevé de compte",
        "account-table-customization": "Paramètres de la table",
        "activity": "Activité",
        "admin-resource": "Ressource administrateur",
        "adminable": "Adminable",
        "assignable": "Assignable",
        "assignment": "Cession",
        "association": "Association",
        "availability-preference": "Préférences de disponibilité",
        "award": "Prix",
        "base-model": "Modèle de base",
        "base-program": "Programme de base",
        "base-resource": "Ressource de base",
        "billable-activity": "Activité facturable",
        "billing-setting": "Réglage de la facturation",
        "business-unit": "Unité d'affaires",
        "chat-message": "Message de chat",
        "client": "Client",
        "client-coach": "Coach client",
        "client-coach-share": "Part client coach",
        "client-coachee": "Client coaché",
        "client-location": "Emplacement du client",
        "client-poc": "POC client",
        "client-summary": "Résumé client",
        "coach": "Entraîneur",
        "coach-pool": "Piscine pour entraîneurs",
        "coach-pool-coach": "Entraîneur piscine",
        "coaching-categorization": "Catégorisation du coaching",
        "coaching-experience": "Expérience de coaching",
        "coaching-inquiry": "Enquête de coaching",
        "coaching-inquiry-message": "Message de demande de coaching",
        "coaching-request": "Demande de coaching",
        "coaching-request-message": "Message de demande de coaching",
        "coaching-theme-info": "Informations sur le thème du coaching",
        "comment": "Commentaire",
        "commentable": "Commentable",
        "company-location": "Emplacement de la société",
        "competenciable": "Compétenciable",
        "competency": "Compétence",
        "competency-description": "Description de la compétence",
        "competency-library": "Bibliothèque de compétences",
        "contact": "Contact",
        "contract": "Contrat",
        "contract-adjustment-request": "Demande de contrat contractmnet",
        "contract-adjustment-request-field": "Champ de demande d'ajustement de contrat",
        "contract-template": "Modèle de contrat",
        "country": "Pays",
        "cronofy-calendar": "Calendrier Cronofy",
        "cronofy-connected-account": "Compte connecté Cronofy",
        "currency": "Devise",
        "custom-notifiable": "À déclaration obligatoire",
        "custom-notification": "Notification personnalisée",
        "department": "département",
        "education-degree": "Niveau d'étude",
        "email-customization": "Personnalisation du courrier électronique",
        "employee": "Employé",
        "employee-level": "Niveau d'employé",
        "employee-view": "Vue des employés",
        "engagement": "Engagement",
        "engagement-comment": "Engagement connecter",
        "engagement-milestone": "Jalon d'engagement",
        "engagement-participant": "Participant engagement",
        "engagement-resource": "Ressource d'engagement",
        "engagement-resource-group": "Groupe de ressources d'engagement",
        "engagement-session": "Session",
        "engagement-session-participant": "Participant à la séance d'engagement",
        "engagement-summary": "Résumé de l'engagement",
        "engagement-template": "Modèle d'engagement",
        "expense": "Frais",
        "file": "Fichier",
        "file-resource": "Ressource de fichier",
        "form": "Forme",
        "form-answer": "Réponse du formulaire",
        "form-question": "Option de formulaire",
        "form-question-option": "Formulaire d'option de question",
        "form-section": "Section de formulaire",
        "form-submission": "Soumission de formulaire",
        "gateway-type": "Type de ressource gabrary",
        "goal": "Objectif",
        "goal-approach": "Approche objectif",
        "goalable": "Objectif",
        "inspirational-quote": "Itinéraire inspirant",
        "interaction": "Interaction",
        "invitation": "Invitation",
        "invoice": "Facture d'achat",
        "invoice-design": "Conception de la facture",
        "invoice-item": "Poste de facture",
        "invoiceable": "Facturable",
        "language": "La langue",
        "language-fluency": "La maîtrise de la langue",
        "library-resource": "Ressource de la bibliothèque",
        "location": "Emplacement",
        "me": "Moi",
        "meeting-location": "Lieu du message",
        "message": "Message",
        "message-recipient": "Destinataire du message",
        "message-thread": "Fil de message",
        "messageable": "Messageable",
        "messaging-type": "Type de messagerie",
        "milestone": "Étape importante",
        "note": "Remarque",
        "noteable": "Remarquable",
        "notification": "Notification",
        "notification-reminder": "Rappel de notification",
        "payment": "Paiement",
        "payment-gateway": "Passerelle de paiement",
        "payment-library": "Bibliothèque de paiement",
        "payment-method": "Mode de paiement",
        "payment-schedule": "Calendrier de paiement",
        "payment-status": "Statut de paiement",
        "payment-term": "Paiement terme",
        "payment-type": "Type de paiement",
        "pending-action": "Action en attente",
        "permission": "Autorisation",
        "permission-role": "Rôle de permission",
        "plan": "Plan",
        "plan-feature": "Fonction de plan",
        "profile": "Profil",
        "program-item": "Élément du programme",
        "program-resource": "Ressource du programme",
        "programmable": "Programmable",
        "resource": "Ressource",
        "resource-group": "Groupe de ressources",
        "resource-group-item": "Élément de groupe de ressources",
        "resourceable": "Ressourcable",
        "response": "Réponse",
        "review": "La revue",
        "reviewable": "Révisable",
        "role": "Rôle",
        "role-user": "Rôle utilisateur",
        "sessionable": "En session",
        "shareable": "Partageable",
        "shared-item": "Article partagé",
        "site-feature": "Caractéristique du site",
        "specialty": "Spécialité",
        "stakeholder": "Intervenant",
        "stakeholderable": "Stakeholderable",
        "status": "Statut",
        "stripe-card": "Carte à rayures",
        "stripe-connected-account": "Stripe compte connecté",
        "subscription": "Abonnement",
        "survey-resource": "Ressource d'enquête",
        "task": "Tâche",
        "task-grouping": "Regroupement des tâches",
        "task-resource": "Ressource de tâche",
        "taskable": "Taskable",
        "tax-rate": "Taux d'imposition",
        "timezone": "Fuseau horaire",
        "twilio-identity": "Identité Twilio",
        "twilioable": "Twilioable",
        "user": "Utilisateur",
        "user-affiliation": "Affiliation utilisateur",
        "user-assessment": "Évaluation de l'utilisateur",
        "user-certification": "Certification d'utilisateur",
        "user-context": "Contexte utilisateur",
        "user-language": "Langue de l'utilisateur",
        "user-notification-setting": "Paramètre de notification utilisateur",
        "user-publication": "Publication de l'utilisateur",
        "user-work": "Travail de l'utilisateur",
        "work-experience": "L'expérience professionnelle"
      },
      "resource": {
        "duplicating-resource": "Ressource en double...",
        "resource-duplicated": "Ressources dupliquées"
      },
      "save-success": "Enregistré avec succès.",
      "task": {
        "duplicating-session": "Session de duplication...",
        "duplicating-task": "Tâche de duplication...",
        "session-duplicated": "Session dupliquée",
        "task-duplicated": "Tâche dupliquée"
      },
      "user-language": {
        "create-label": "Ajouter une langue",
        "edit-label": "Modifier la langue",
        "fluency-label": "Aisance",
        "language-label": "Langue"
      },
      "work-experience": {
        "create-label": "Ajouter une expérience de travail",
        "description-label": "La description",
        "edit-label": "Modifier l'expérience de travail",
        "end-date-label": "Date de fin",
        "organization-label": "Organisation",
        "role-label": "Rôle",
        "start-date-label": "Date de début"
      }
    },
    "not-found": {
      "description": "Désolé, mais la page que vous recherchez a été trouvée. Essayez de vérifier l'URL pour l'erreur, puis appuyez sur le bouton Actualiser sur votre navigateur ou essayez de trouver quelque chose d'autre dans notre application.",
      "goToLogin": "Accéder à la connexion",
      "title": "404"
    },
    "note": {
      "back": "Retour aux notes d'engagement",
      "delete": "Supprimer",
      "deleting": "Supprimer",
      "editor-placeholder": "Prenez vos notes ici.",
      "save": "Sauvegarder les modifications",
      "saved": "Tous les changements enregistrés",
      "saving": "Sauvegarder",
      "share": "Partager",
      "title": "Note d'édition",
      "title-placeholder": "Sans titre."
    },
    "notifications": {
      "form": {
        "title": "Vous avez envoyé un formulaire à remplir."
      },
      "goal": {
        "completed": "Objectif atteint.",
        "created": "Objectif créé.",
        "deleted": "Objectif supprimé.",
        "status-updated": "État de l'objectif mis à jour.",
        "title": "Vous avez un objectif à atteindre.",
        "updated": "Objectif mis à jour."
      },
      "source": {
        "engagement": "Engagement"
      }
    },
    "onboarding": {
      "blog-posts": {
        "goal-setting": "L'importance de l'établissement d'objectifs et comment cela peut vous aider",
        "google": "Comment Google utilise le coaching pour former des leaders au lieu de les trouver",
        "read-more": "Lire la suite",
        "right-arrow": "›",
        "roi": "Le retour sur investissement d'un coaching peut-il être prouvé?",
        "successful": "La plupart des gens qui réussissent ont un coach ... pourquoi pas vous?",
        "top-companies": "Le coaching n'est pas réservé aux cadres. Découvrez pourquoi les meilleures entreprises offrent du coaching à tous!"
      },
      "how": "Comment je",
      "learn-more": "Apprendre encore plus",
      "news": "\"Une nouvelle passionnante chez Coaching.com! Notre dernière version est maintenant en ligne et meilleure que jamais.\n partagez une expérience Coaching.com entièrement repensée. Et nous ne pourrions pas être plus heureux. Intuitif et facile à utiliser,\n Coaching.com 5.0 vous apporte des tonnes de nouvelles fonctionnalités et améliore considérablement l'expérience utilisateur pour tout le monde. \"",
      "webinar": "Inscription au webinaire",
      "welcome": "Bienvenue sur Coaching.com!"
    },
    "orders": {
      "cancel": "Annuler",
      "coach": "Entraîneur",
      "complete-purchase": "Finaliser l'achat",
      "details": "Détails",
      "empty-state": "Aucune commande",
      "engagement-with": "Engagement avec",
      "go-to-engagement": "Aller à l'engagement",
      "hours": "Heures",
      "package-name": "Nom du paquet",
      "price": "Prix",
      "purchase": "Achat",
      "sessions": "Sessions",
      "status": "Statut",
      "statuses": {
        "completed": "Terminé",
        "failed": "Échoué",
        "pending": "En attente",
        "processing": "En traitement"
      },
      "title": "Commandes",
      "wait-msg": "Nous mettons en place votre engagement de coaching. Cela peut prendre quelques secondes."
    },
    "outside": {
      "auth": {
        "issue": "Il semble y avoir un problème avec votre compte sur Coaching.com. Veuillez contacter support@coaching.com pour résoudre le problème.",
        "old": "On dirait que vous essayez de vous connecter avec un navigateur ancien ou non pris en charge.",
        "sso": "Il semble que vous ayez accédé à Coaching.com avant que votre profil soit entièrement configuré et activé. Veuillez contacter votre administrateur de compte pour plus d'informations.",
        "try-again": "Veuillez réessayer en utilisant la dernière version de",
        "verify-email": {
          "message": "Un email vous a été envoyé avec un lien pour vérifier votre compte.",
          "resend": "Ré-envoyer l'email",
          "resend-success": "Email renvoyé.",
          "return-login": "Revenir à la page de connexion",
          "title": "Vérification de l'email"
        },
        "whoops": "Oups ..."
      },
      "author": "Marshall Goldsmith, Ph.D.",
      "browsers": {
        "chrome": "Chrome",
        "edge": "Bord",
        "firefox": "Firefox",
        "safari": "Safari"
      },
      "forgot": {
        "contact": "Contact <a href=\"mailto:support@coaching.com?subject=Password&#32;Reset\">support@coaching.com</a> pour aider.",
        "email-label": "Email",
        "login-link": "Back to login",
        "prompt": "Merci de saisir l'adresse email associée à votre compte",
        "reset-button": "Réinitialiser",
        "submissions": "Soumissions",
        "submitted-by": "Soumis par {user}",
        "submitted-on": "Soumis le {date}",
        "success": "Un lien de réinitialisation a été envoyé à <strong>{email}</strong>. <br> S'il vous plaît contactez <a href=\"mailto:support@coaching.com?subject=Password&#32;Reset\">support@coaching.com</a> si vous ne recevez pas le courrier électronique.",
        "title": "Réinitialiser le mot de passe",
        "wait": "S'il vous plaît, attendez"
      },
      "invite": {
        "create-password": "Créez votre mot de passe pour commencer à utiliser votre compte.",
        "invited-by": "<strong>{senderName}</strong> vous a invité à créer un compte Coaching.com.",
        "set-password": "Définir le mot de passe"
      },
      "login": {
        "contact": "Contact <a href=\"mailto:support@coaching.com?subject=Login\">support@coaching.com</a> pour aider.",
        "dont-have-account": "Vous n'avez pas de compte?",
        "email-label": "Email",
        "forgot-link": "Mot de passe oublié?",
        "form-paragraph-1": "Nous venons de lancer une <strong> nouvelle version de Coaching.com </ strong> avec des fonctionnalités de sécurité améliorées.",
        "form-paragraph-2-1": "Si vous avez déjà un compte, vous devez ",
        "form-paragraph-2-2": " pour vous connecter",
        "form-paragraph-2-action": "réinitialiser votre mot de passe",
        "form-paragraph-3": "Vous avez déjà réinitialisé votre mot de passe ou vous êtes-vous connecté pour la première fois à un nouveau compte?",
        "intro": "<p> Nous venons de lancer une <strong>nouvelle version de Coaching.com</strong> avec des fonctionnalités de sécurité améliorées. </p><p> Pour cette raison, vous devrez réinitialiser votre mot de passe pour accéder à votre compte. Nous restons engagés à protéger votre vie privée et votre confidentialité et nous continuerons à travailler dur pour vous fournir les meilleures solutions de gestion de coaching. </p>",
        "login-button": "S'identifier",
        "login-signup-button": "S'identifier / Registre",
        "password-label": "Mot de passe",
        "signup-button": "Registre",
        "signup-label": "Inscrivez-vous ici",
        "signup-link": "Registre",
        "tagline": "Tout ce dont vous avez besoin pour gérer votre pratique de coaching au même endroit.",
        "terms-acknowledge": "J'accepte le",
        "terms-acknowledge-login": "En me connectant, je reconnais le",
        "title": "S'identifier",
        "wait": "S'il vous plaît, attendez"
      },
      "payment-thank-you": {
        "go-back-button": "Aller à la page d'accueil",
        "payment-accepted": "Votre paiement a été accepté.",
        "thanks": "Merci pour votre paiement",
        "you-can-go-back": "Vous pouvez maintenant revenir à l'application Coaching.com."
      },
      "quote": "“Coaching.com fait un travail incontournable pour fournir les solutions les plus avancées et les plus innovantes pour l'industrie du coaching.”",
      "take-me-to-app": "Emmenez-moi à l'application",
      "title": "Meilleur auteur et coach de leadership"
    },
    "personal-settings": {
      "app-notifications": "Notifications d'application",
      "calendar": {
        "availability-warning": "Lorsque des sessions sont programmées via Coaching.com, nous ajouterons automatiquement l'événement à vos calendriers cibles.",
        "calendar-update": "Calendrier mis à jour.",
        "calendars": {
          "add-calendar-button": "Ajouter un calendrier",
          "description": "Connectez le ou les calendriers à partir desquels Coaching.com doit lire les heures \"occupées\".",
          "description-connected": "Toutes nos félicitations! Vous avez connecté votre calendrier avec Coaching.com.",
          "disconnect-calendar-button": "Déconnecter le calendrier",
          "headline": "Connecter les calendriers",
          "headline-connected": "Le calendrier est connecté",
          "prompt": "Sélectionnez un fournisseur pour ajouter votre premier compte:"
        },
        "default-meeting-location": {
          "description": "Définissez un lieu de réunion par défaut pour chaque entretien de correspondance d'entraîneur programmé et vos sessions de coaching planifiées.",
          "headline": "Emplacement d'interview correspondant par défaut",
          "tooltip": "Ceci est le lieu de l'interview de correspondance par défaut"
        },
        "default-session-location": {
          "description": "Définissez un emplacement de session de coaching par défaut pour chaque session de coaching planifiée.",
          "headline": "Emplacement de la session de coaching par défaut",
          "tooltip": "Ceci est le lieu de session de coaching par défaut"
        },
        "hours": {
          "add-hours": "Ajouter des heures",
          "cancellation-policy": "Politique d'annulation de session:",
          "description": "Voulez-vous permettre à vos clients de programmer leurs propres sessions? Définissez les heures de fonctionnement standard que vous souhaitez partager avec les clients et nous gérerons votre temps disponible.",
          "duration": "Durée",
          "headline": "Heures standard",
          "no-schedule-within": "Aucune planification de session dans:",
          "session-padding": "Rembourrage entre les sessions:"
        },
        "invalid-time": "Temps invalide",
        "locations": {
          "description": "Gérez vos emplacements et définissez un emplacement de réunion par défaut pour chaque entretien de correspondance d'entraîneur programmé et vos sessions de coaching planifiées.",
          "headline": "Emplacements par défaut"
        },
        "no-calendars": "Aucun calendrier n'a encore été ajouté.",
        "set-as-default": "Définir par défaut",
        "set-location-as": "Définir l'emplacement comme",
        "subtitle": "Synchronisez vos agendas avec votre compte Coaching.com.",
        "title": "Paramètres de calendrier",
        "update-toast-message": "Paramètres du calendrier mis à jour."
      },
      "current-browser": "L'activation de ce paramètre n'affectera que le navigateur actuel.",
      "general": {
        "accepting-clients": "Je suis disponible pour les nouveaux clients",
        "coach-capacity-helper": "Choisissez votre capacité d'engagement ou un nombre maximum d'engagements simultanés. Ceci est utilisé pour fournir des informations pendant le processus de création de l'engagement.",
        "coach-capacity-not-set": "Capacité d'engagement de l'entraîneur non définie.",
        "current-coach-capacity": "Capacité d'engagement des entraîneurs fixée à {capacity}.",
        "im-a-coach": "Je suis un entraîneur",
        "request-external-engagement-approval": "Demander mon approbation pour de nouveaux mandats clients",
        "settings-saved": "Les paramètres personnels ont été enregistrés.",
        "title": "Général",
        "update-avatar": "Mettre à jour la photo",
        "updated-avatar": "Photo mise à jour"
      },
      "global": "Configuration globale",
      "notifications": {
        "subtitle": "Ajustez vos paramètres de notification personnels.",
        "table-header": {
          "email": "Email",
          "first-name": "Prénom",
          "id": "ID",
          "last-name": "Nom de famille",
          "phone": "Téléphone",
          "status": "Statut",
          "title": "Titre"
        },
        "title": "Notifications",
        "userSettings": {
          "empty": "Chargement des types de notification Configurations",
          "headline": "Types de notification",
          "subhead": "Choisissez les notifications que vous souhaitez recevoir et par quelle méthode"
        }
      },
      "title": "Paramètres Personnels",
      "toggle-notifications": "Activer ou désactiver toutes les notifications par email."
    },
    "profile": {
      "add-new-section-title": "Ajouter une nouvelle section de profil",
      "affiliations": "Affiliations",
      "assessments": "Évaluations",
      "clients": "Clients",
      "credentials": "Identifiants",
      "education-degree": "Éducation",
      "empty-state": {
        "affiliations": "Vous n'avez pas encore ajouté d'affiliation.",
        "experience": "Vous n'avez pas encore ajouté d'années d'expérience.",
        "industries": "Vous n'avez pas encore ajouté d'industries.",
        "languages": "Vous n'avez pas encore ajouté de langues.",
        "leader-levels": "Vous n'avez pas encore ajouté de niveaux d'employés.",
        "modalities": "Vous n'avez pas encore configuré vos modalités.",
        "specialties": "Vous n'avez pas encore ajouté de spécialités.",
        "summary": "Vous n'avez pas encore ajouté de résumé de profil.",
        "type-of-coach": "Vous n'avez pas encore ajouté de types de coaching.",
        "video": "Vous n'avez pas encore ajouté de vidéo.",
        "work-experience": "Vous n'avez pas encore ajouté d'expérience professionnelle."
      },
      "experience": "Expérience",
      "hours-coached": "Heures de coaching",
      "leader-levels": "Niveaux de leader",
      "modalities": "Modalités",
      "packages": {
        "purchase": "Acheter",
        "sessions": "{sessions, plural, one {session} other {sessions}}",
        "title": "Paquets"
      },
      "publications": "Publications",
      "section": {
        "certifications": "Certifications",
        "coaching-experience": "Expérience de coaching",
        "placeholder": "Aucun n'a encore été ajouté.",
        "user-publication": "Publications"
      },
      "specialties": "Spécialités",
      "specialties-message": "Veuillez sélectionner jusqu'à 5 spécialités",
      "user-language": "Langues",
      "work-experience": "L'expérience professionnelle",
      "years-of-experience": "Années d'expérience"
    },
    "program": {
      "visibility": "Visibilité"
    },
    "program-item": {
      "add": "Ajouter un élément de programme",
      "admin-visibility": "Admin uniquement",
      "cancel": "Annuler",
      "canceled": "Annulé le {date}",
      "completed": "Complété sur {date}",
      "scheduled": "Plannifié sur {date}",
      "sent": "Envoyé le {date}",
      "subtitle": "Vous pouvez ajouter des sessions, des ressources et des tâches.",
      "title": "Commencez à construire votre programme"
    },
    "program-templates": {
      "add-item": "Ajouter un item",
      "delete": "Supprimer le modèle",
      "general": {
        "info": "Les modifications apportées à un modèle de programme ne seront reflétées que dans les engagements nouvellement créés.",
        "program-templates": "Modèles de programme",
        "resources": {
          "permissions-notice": "Les ressources ajoutées à un modèle de programme et appliquées à une mission seront visibles et rendues accessibles à toutes les parties prenantes de la mission"
        },
        "template-created": "Modèle créé.",
        "template-updated": "Modèle mis à jour."
      },
      "settings": {
        "add-theme-library": "Ajouter une bibliothèque de thèmes",
        "admin": "Admin",
        "apply-to-all": "Cette modification s'appliquera à toutes les sessions. Êtes-vous sûr de vouloir continuer?",
        "created-by": "Créé par",
        "delete-confirmation": "Êtes-vous sûr de vouloir supprimer ce modèle?",
        "delete-success": "Modèle supprimé avec succès.",
        "delete-template": "Supprimer ce modèle",
        "delete-warning": "Une fois que vous supprimez un modèle, il ne peut plus être récupéré.",
        "meeting-location-description": "Définissez un emplacement de réunion par défaut pour cet engagement.",
        "meeting-location-title": "Lieu de réunion par défaut",
        "theme-library": "Bibliothèque thématique",
        "theme-library-added": "Bibliothèque de thèmes ajoutée.",
        "untitled-template": "Modèle sans titre",
        "updated-meeting-location": "Lieu de réunion par défaut mis à jour."
      },
      "single-assignee-links": "Les liens ne peuvent avoir qu'un seul destinataire.",
      "single-assignee-tasks": "Les tâches ne peuvent avoir qu'un seul destinataire.",
      "start-building": "Commencez à créer votre programme",
      "start-building-helper": "Vous pouvez ajouter des sessions, des ressources et des tâches en sélectionnant \"+ Ajouter un élément\" dans le coin supérieur gauche.",
      "start-building-here": "Commencez à créer votre programme ici."
    },
    "reports": {
      "1month": "1 Mois",
      "1year": "1 An",
      "3months": "3 Mois",
      "6months": "6 Mois",
      "all": "Tout",
      "categories": {
        "activity": "Activités",
        "aging": "Vieillissement",
        "client": "Clients",
        "engagement": "Engagements",
        "expense": "Dépenses",
        "invoice": "Factures",
        "payment": "Paiements",
        "profit_and_loss": "Profit et perte",
        "task": "les tâches",
        "tax_rate": "Les taux d'imposition"
      },
      "icons": {
        "assignment": "check",
        "client": "work",
        "coach": "recent-actors",
        "coachee": "person",
        "engagement": "group",
        "engagement_session": "group",
        "form_submission": "class",
        "goal": "gps_fixed",
        "invoice": "receipt",
        "payment": "attach-money",
        "vendor": "business"
      },
      "load": "Charger Rapport",
      "loading": "Chargement...",
      "names": {
        "assignment": "Assignations/Tâches",
        "client": "Clients",
        "coach": "Coaches",
        "coachee": "Coachees",
        "engagement": "Engagements",
        "engagement_session": "Sessions",
        "form_submission": "Soumissions de formulaire",
        "goal": "Buts",
        "invoice": "Factures",
        "payment": "Paiements",
        "vendor": "Vendeurs"
      },
      "no-data": "Pas de données disponibles",
      "please": "Sélectionnez un rapport.",
      "table": {
        "headers": {
          "actions": "Actions",
          "category": "Catégorie",
          "name": "Rapport",
          "type": "Type"
        }
      },
      "table-header": {
        "active_coachees": "Coachees actifs",
        "active_coaches": "Entraîneurs actifs",
        "active_contracts": "Contrats Actifs",
        "active_engagements": "Engagements Actifs",
        "actual_duration": "Durée réelle",
        "amount_due": "Montant Dû",
        "amount_invoiceable": "Total Invoiceable",
        "amount_invoiced": "Total Facturé",
        "amount_outstanding": "Total Dû",
        "amount_paid": "Total Payé",
        "amount_received": "Revenu Total",
        "answer": "Répondre",
        "assigned_date": "Date assignée",
        "assignee": "Cessionnaire",
        "assignee_account_name": "Compagnie d'cessionnaire",
        "assignee_company": "Compagnie d'cessionnaire",
        "assignee_department": "Cessionnaire Département",
        "assignee_email": "Cessionnaire Email",
        "assignee_first_name": "Cessionnaire Prénom",
        "assignee_last_name": "Cessionnaire Nom de famille",
        "assignee_location": "Cessionnaire Emplacement",
        "assignee_sub_department": "Cessionnaire Sous-département",
        "average_coach_rating": "Note moyenne de l'coach",
        "average_rating": "Note moyenne",
        "billing_method": "Méthode de facturation",
        "billing_unit": "Unité de facturation",
        "client_account_name": "Société Cliente",
        "coach": "Coach",
        "coach_account_name": "Compagnie de l'coach",
        "coach_city": "Ville de l'entraîneur",
        "coach_company": "Compagnie de l'coach",
        "coach_country": "Pays de l'entraîneur",
        "coach_department": "Coaché Département",
        "coach_email": "Coach Email",
        "coach_first_name": "Coach Prénom",
        "coach_included": "Coach Inclus",
        "coach_last_name": "Coach Nom de famille",
        "coach_location": "Coach Emplacement",
        "coach_name": "Nom de l'entraîneur",
        "coach_phone": "Coach Phone",
        "coach_shares_pending": "Actions Coach",
        "coach_sub_department": "Coach Sous-département",
        "coach_type": "Coach Type",
        "coachee": "Coaché",
        "coachee_account_name": "Compagnie d'coaché",
        "coachee_company": "Compagnie d'coaché",
        "coachee_department": "Coaché Département",
        "coachee_email": "Coaché Email",
        "coachee_first_name": "Coaché Prénom",
        "coachee_included": "Coaché Inclus",
        "coachee_last_name": "Coaché Nom de famille",
        "coachee_location": "Coaché Emplacement",
        "coachee_name": "Nom du coaché",
        "coachee_phone": "Coaché Phone",
        "coachee_sub_department": "Coaché Sous-département",
        "coachee_type": "Coaché Type",
        "competency_library": "Bibliothèque thématique",
        "completed_date": "Date d'achèvement",
        "created_at": "Date Créer",
        "date_assigned": "Date Assignée",
        "date_completed": "Date Complet",
        "date_created": "Date Créée",
        "date_last_updated": "Date de dernière mise à jour",
        "department": "Département",
        "due_date": "Date d'échéance",
        "duration": "Durée",
        "employee_level": "Niveau d'employé",
        "employee_title": "Position",
        "end_date": "Date de Fin",
        "engagement": "Engagement",
        "engagement_id": "Id d'engagement",
        "engagement_status": "Statut d'engagement",
        "engagement_template": "Programme",
        "engagements_completed": "Completed Engagements",
        "first_rating": "Première cote",
        "form_name": "Nom de forme",
        "form_section": "Section de formulaire",
        "goal_description": "Description de l'objectif",
        "goal_name": "Nom de l'objectif",
        "goals_completed": "Buts Terminés",
        "goals_created": "Buts Créés",
        "goals_open": "Objectifs Ouverts",
        "hours_cancelled": "Heures annulées",
        "hours_completed": "Heures Complétées",
        "hours_remaining": "Hours Restantes",
        "hours_scheduled": "Heures Prévues",
        "id": "Id",
        "improvement": "Amélioration",
        "invites_pending": "Invites en attente",
        "late_cancelled": "Retard annulé",
        "latest_rating": "Dernier classement",
        "lead_email": "Premier Email",
        "lead_first_name": "Premier Prénom",
        "lead_last_name": "Premier Nom de famille",
        "lead_phone": "Premier Phone",
        "leader_level": "Niveau d'employé",
        "location": "Emplacement",
        "location_name": "Nom de la Localisation",
        "meeting_location": "Lieu de Réunion",
        "meeting_type": "Type de Réunion",
        "most_recent_session": "Session la plus récente",
        "next_session": "Prochaine session",
        "percent_complete": "Le Progrès",
        "primary_contact_email": "Contact Email",
        "primary_contact_first_name": "Contact Prénom",
        "primary_contact_last_name": "Contact Nom de famille",
        "primary_contact_phone": "Contact Téléphone",
        "program_name": "Nom de Programme",
        "progress": "Le Progrès",
        "question_number": "Question #",
        "question_text": "Question",
        "respondent_company": "Intimé d'autocars",
        "respondent_email": "Intimé Email",
        "respondent_first_name": "Intimé Prénom",
        "respondent_last_name": "Intimé Nom de famille",
        "respondent_name": "Nom du répondant",
        "scheduled_date": "Date prévue",
        "session": "Session",
        "session_status": "Statut de la session",
        "sessions_cancelled": "Sessions Annulées",
        "sessions_completed": "Sessions Terminées",
        "sessions_remaining": "Sessions Restantes",
        "sessions_scheduled": "Sessions Prévues",
        "stakeholders": "Les parties prenantes",
        "start_date": "Date de Début",
        "status": "Statut",
        "sub_department": "Sous-département",
        "task_description": "Description de la Tâche",
        "task_name": "Nom de la Tâche",
        "task_type": "Type de Tâche",
        "themes": "Des thèmes",
        "title": "Titre",
        "total_attendees": "Nombre Total de Participants",
        "total_coach_reviews": "Avis des Coaches",
        "total_contracts": "Total des Contrats",
        "total_engagements": "Total de Engagements",
        "total_hours": "Total Heures",
        "total_invoices": "Total des Factures",
        "total_sessions": "Total de Sessions",
        "vendor_account_name": "Société du Vendeur",
        "weight": "Poids"
      },
      "title": "Rapports",
      "ytd": "cumul à ce jour"
    },
    "resources": {
      "add-file": "Ajouter le fichier",
      "articles-label": "Articles",
      "audio-label": "L'audio",
      "back-to-all-resources": "Retour",
      "create-link-dialog": {
        "description-label": "Description",
        "edit-link": "Modifier le lien",
        "invalid-link": "Veuillez fournir une adresse valide.",
        "link-preview-title": "Aperçu du lien",
        "link-success": "Lien créé avec succès.",
        "link-title": "Lien d'aperçu",
        "loading-preview": "Générer un aperçu...",
        "name-label": "Nom",
        "preview-error": "Impossible de générer l'aperçu du lien. Le lien pointe-t-il sur une URL valide?",
        "title": "Créer un lien",
        "url-label": "Entrez le lien"
      },
      "delete-check": "Êtes-vous sûr de vouloir supprimer cette ressource?",
      "drag-out-helper": "Déposez ici pour déplacer la ressource pour déplacer la ressource hors du dossier actuel.",
      "empty-folder": "Ce dossier est vide.",
      "file-error": "Les fichiers {ext} ne sont pas pris en charge. S'il vous plaît essayer un autre fichier.",
      "filters": {
        "all": "Tout",
        "archive": "Fichiers compressés",
        "audio": "Audio",
        "competency-library": "Bibliothèques thématiques",
        "document": "Coaching.com Docs",
        "form": "Formes",
        "group": "Dossiers",
        "image": "Images",
        "link": "Lien",
        "note": "Remarques",
        "presentation": "Présentations",
        "spreadsheet": "Feuilles de calcul",
        "text": "Docs téléchargés",
        "video": "Vidéo"
      },
      "folder-name": "Dossier: {name}",
      "folder-saved": "Dossier enregistré.",
      "folders": "Dossiers",
      "grid-view": "Grille Voir",
      "last-modified": "Dernière modification {date}",
      "list-view": "Vue de Liste",
      "malicious-files-detected": "Nous avons détecté un ou plusieurs fichiers malveillants dans vos ressources. Ces fichiers ont été supprimés, sous une liste de fichiers:",
      "new-folder": "Nouveau dossier",
      "no-match": "Aucune de vos ressources ou dossiers ne correspondait: \"{searchText}\".",
      "no-match-type": {
        "audio": "Aucun fichier audio ne correspond à \"{searchText}\".",
        "competency-library": "Aucune bibliothèque de thèmes ne correspond à \"{searchText}\".",
        "form": "Aucun formulaire ne correspond à \"{searchText}\".",
        "group": "Aucun dossier ne correspond à \"{searchText}\".",
        "image": "Aucune image ne correspond à \"{searchText}\".",
        "presentation": "Aucune présentation ne correspond à \"{searchText}\".",
        "spreadsheet": "Aucune feuille de calcul ne correspond à \"{searchText}\".",
        "text": "Aucun document ne correspond à \"{searchText}\".",
        "video": "Aucun fichier vidéo ne correspond à \"{searchText}\"."
      },
      "notes": "Remarques",
      "notes-empty": "Pas de notes",
      "please-download": "Ce format de fichier n'est pas visualisable. Veuillez le télécharger.",
      "recommended-label": "Conseillé",
      "resource-shared": "Ressource partagée.",
      "search": "Ressources de recherche",
      "see-all": "Voir tout",
      "sharing-permission-saved": "Autorisation de partage enregistrée",
      "show-shares": "Afficher les actions",
      "success-messages": {
        "competency-library": "Bibliothèque de compétences ajoutée avec succès.",
        "document": "Document ajouté avec succès.",
        "file": "Fichier ajouté avec succès.",
        "folder": "Dossier ajouté avec succès.",
        "form": "Formulaire ajouté avec succès.",
        "link": "Lien ajouté avec succès.",
        "move-into": "La ressource a été déplacée dans le dossier avec succès.",
        "move-out": "La ressource a été déplacée du dossier avec succès."
      },
      "title": "Ressources",
      "try-another": "Essayez une autre recherche ou cliquez sur le",
      "try-filter": "icône pour filtrer une ressource par type.",
      "types": {
        "admin-resources": "Ressources administratives",
        "my-resources": "Mes ressources",
        "shared-resources": "Ressources partagées"
      },
      "videos-label": "Vidéos",
      "visibility-warning": "Les fichiers téléchargés sur les ressources de mission sont visibles par tous les participants."
    },
    "settings": {
      "basic-info": {
        "about": "Sur",
        "account-configuration": "Configuration du compte",
        "address": "Adresse",
        "address2": "Appt/Bâtiment",
        "adjust-text": "Ajustez les paramètres et les préférences du compte pour.",
        "coaching": {
          "coach-capacity-updated": "Capacité de l'entraîneur mise à jour avec succès!",
          "default-coach-capacity": "Capacité d'engagement du coach par défaut",
          "not-set": "Pas encore défini",
          "title": "Paramètres de l'entraîneur"
        },
        "coaching-vendors": "Coaching des vendeurs",
        "conf-description": "Choisissez les fonctionnalités que vous voulez dans votre compte.",
        "external-coaching": "Avez-vous des contracteurs externes?",
        "facebook": "Facebook",
        "headline": "Information Simple",
        "independent-coaches": "Entraîneurs indépendants",
        "internal-coaching": "Employez-vous des coachs internes?",
        "language": "Langue",
        "language-description": "Choisissez la langue par défaut pour vos employés. Les utilisateurs individuels peuvent modifier cette valeur par défaut dans leurs paramètres personnels.",
        "language-title": "Langage par défaut",
        "linkedin": "LinkedIn",
        "name": "Nom",
        "primary-contact": "Contact principal",
        "provide-coaching": "Vendez-vous des services de coaching?",
        "save": "Enregistrer",
        "saving": "Enregistrement...",
        "settings-update": "Paramètres mis à jour.",
        "signature": "Signature électronique",
        "subhead": "Dites-nous des informations sur votre compte afin que nous puissions personnaliser Coaching.com en fonction de vos besoins.",
        "timezone-description": "Choisissez la fuseau horaire par défaut pour vos employés. Les utilisateurs individuels peuvent modifier cette valeur par défaut dans leurs paramètres personnels.",
        "timezone-title": "Fuseau horaire par défaut",
        "title": "Général",
        "twitter": "Twitter",
        "update-company-info": "Mettez à jour les informations sur votre entreprise.",
        "website": "Site",
        "what-type-of-coaches": "Quel type d'entraîneurs contractez-vous?"
      },
      "billing": {
        "allow-expenses-options": {
          "approval_needed": "Oui, mais l'approbation est nécessaire",
          "no": "Non",
          "yes": "Oui"
        },
        "billing-approaches": {
          "fixed_contract_amount": "Montant fixé",
          "flat": "Par session",
          "hourly": "Par heure",
          "retainer": "Retenue"
        },
        "billing-approaches-amounts": {
          "fixed_contract_amount": "Coût total",
          "flat": "Montant par session",
          "hourly": "Montant par heure",
          "retainer": "Montant facturé à chaque période facturable"
        },
        "contract-duration-interval": {
          "engagement_sessions": "Séances d'engagement",
          "hours_billed": "Heures facturées",
          "months": "Mensuel",
          "sessions_completed": "Sessions",
          "weeks": "Hebdomadaire"
        },
        "contract-duration-type": {
          "days": "Journées",
          "months": "Mois",
          "weeks": "Semaines"
        },
        "contract-templates": {
          "create-dialog": {
            "title": "Créer un modèle de contrat"
          },
          "empty-state": "Aucun modèle de contrat.",
          "headline": "Modèles de contrat",
          "subhead": "Ceux-ci peuvent être appliqués aux modèles de contrat pour définir un calendrier de facturation préféré.",
          "terms": "Termes de contrat",
          "title": "Modèle de contrat"
        },
        "delete-contract-template-prompt": "Êtes-vous sûr de vouloir supprimer ce modèle de contrat?",
        "delete-contract-template-successful": "Le modèle de contrat a été supprimé avec succès.",
        "duration-type": {
          "each-month": "Même jour chaque mois",
          "hours_billed": "Après {value, plural, =1 {heure facturée} other {# heures facturées}}",
          "months": "Chaque {value, plural, =1 {mois} other {# mois}}",
          "sessions_completed": "Après {value, plural, =1 {session} other {# sessions}}",
          "weeks": "Chaque {value, plural, =1 {la semaine} other {# semaines}}"
        },
        "endPercentage": "Fin",
        "firstQuarterPercentage": "Premier quart",
        "firstThirdPercentage": "Premier tiers",
        "metrics": "Statistiques de facturation",
        "middlePercentage": "Milieu",
        "ordinal-numbers": "{number, selectordinal, one {#st} two {#nd} few {#rd} other {#th} =-1 {Last} }",
        "payment-schedule": "Calendrier de paiement",
        "payment-schedules": {
          "create-dialog": {
            "title": "Créer un approache de facturation"
          },
          "empty-state": "Aucun approaches de facturation.",
          "headline": "Approches de facturation",
          "subhead": "Ceux-ci peuvent être appliqués aux modèles de contrat pour définir un calendrier de facturation préféré."
        },
        "periods": {
          "end": "Fin",
          "firstQuarter": "Premier quart",
          "firstThird": "Premier tiers",
          "middle": "Milieu",
          "secondThird": "Deuxième tiers",
          "thirdQuarter": "Troisième quart",
          "upfront": "À l'avant"
        },
        "schedule-types": {
          "custom": "Créer un nouveau",
          "duration": "Après un intervalle de temps",
          "fixed_contract_amount_percentages": "Différents pourcentages pendant le contrat",
          "manual": "Je vais facturer manuellement le client",
          "session": "Après chaque session"
        },
        "secondThirdPercentage": "Deuxième tiers",
        "thirdQuarterPercentage": "Troisième quart",
        "title": "Accords de Facturation",
        "upfrontPercentage": "À l'avant"
      },
      "branding": {
        "brand-colors-description": "Choisissez une couleur primaire et une couleur d’accentuation qui représentent votre marque.",
        "brand-colors-title": "Couleurs de la marque",
        "custom": "Personnalisé",
        "email-notifications-description": "Ajoutez une signature et un pied de page de réseau social aux notifications par courrier électronique générées à partir de votre compte Coaching.com.",
        "email-notifications-title": "Notifications par email",
        "full-logo-description": "Ce logo apparaîtra comme en-tête sur vos formulaires, emails et autres lieux. Si vous ne téléchargez pas un logo large, nous utiliserons votre logo carré.",
        "primary-color": "Couleur primaire",
        "primary-color-helper": "Une couleur qui apparaît le plus souvent dans votre application.",
        "secondary-color": "Couleur secondaire",
        "secondary-color-helper": "Une couleur utilisée pour accentuer les parties clés de votre interface utilisateur.",
        "square-logo": "Logo carré",
        "square-logo-description": "Choisissez un logo qui sera utilisé pour représenter votre entreprise dans Coaching.com.",
        "subhead": "Personnalisez l'apparence de votre compte Coaching.com pour les employés et les personnes directement embauchées.",
        "themes": "Thèmes",
        "title": "Image de marque",
        "update-cover-picture": "Mise à jour de la photo",
        "update-full-logo": "Mettre à jour le logo complet",
        "update-logo": "Mise à jour",
        "white-sidenav": "Rendre sidenav blanc"
      },
      "calendar": {
        "title": "Calendrier"
      },
      "coach-pools": {
        "add-dialog": {
          "created": "Le paramètre correspondant a été créé.",
          "title": "Ajouter des paramètres correspondants"
        },
        "default-setting": "Paramètre de correspondance par défaut",
        "default-setting-tooltip": "Lorsque vous supprimez un paramètre de correspondance, tous les engagements qui y sont associés sont remplacés par ce paramètre de correspondance.",
        "delete-prompt": "Si des engagements sont associés à ce paramètre de correspondance, ils seront remplacés par le paramètre de correspondance <strong>par défaut</strong>.<br>Voulez-vous vraiment supprimer ce paramètre de correspondance?",
        "no-matching-settings": "Aucun paramètre correspondant.",
        "no-program-templates": "Veuillez créer un modèle de programme (Paramètres> Modèles de programme)",
        "subtitle": "Administre votre Groupe d'Entraîneurs",
        "title": "Groupe d'Entraîneurs"
      },
      "company-members": {
        "add-employee": "Ajouter un employé",
        "create-workflow": {
          "company-location-label": "Emplacement de la société",
          "department-label": "Département",
          "employee-level-label": "Niveau d'employé",
          "employee-title-label": "Position de l'employé",
          "first-name-label": "Prénom",
          "internal-identification-label": "ID de l'employé",
          "last-name-label": "Nom de famille",
          "role-label": "Rôle",
          "title": "Créer un employé",
          "work-email-label": "Email de travail",
          "work-phone-label": "Téléphone de travail"
        },
        "invite-user": "Inviter un utilisateur",
        "resend-invite": "Renvoyer Inviter",
        "role": {
          "company_admin": "Propriétaire du Compte/Gestionnaire",
          "company_billing_manager": "Gestionnaire de Facturation",
          "company_client_manager": "Gestionnaire des Clientèles",
          "company_coach": "Entraîneur",
          "company_coach_manager": "Gestionnaire des Entraîneurs",
          "company_department_manager": "Directeur des Départements",
          "company_employee": "Employé",
          "company_employee_manager": "Gestionnaire des Employés",
          "company_engagement_editor": "Editeur d'engagement de compte",
          "company_engagement_manager": "Directeur des Engagements",
          "company_external_coach": "Entraîneur Externe",
          "company_marketing_manager": "Gestionnaire du Marketing"
        },
        "send-invite": "Envoyer une invitation",
        "subtitle": {
          "default": "Gérer les employés, les entraîneurs et les administrateurs pour votre entreprise.",
          "independent_coach": "Ajoutez toute personne qui vous aide à gérer votre pratique ici."
        },
        "table-header": {
          "account-status": "Statut du compte",
          "avatar": "Avatar",
          "date-added": "Créé à",
          "department": "département",
          "email": "Email",
          "first-name": "Prénom",
          "id": "ID",
          "is-admin": "Est administrateur?",
          "is-coach": "Est l'entraîneur?",
          "last-login": "Dernière connexion",
          "last-name": "Nom de famille",
          "leader-level": "Niveau de leader",
          "location": "Emplacement",
          "phone": "Téléphone",
          "status": "Statut",
          "sub-department": "Sous-département",
          "title": "Position"
        },
        "title": {
          "default": "Employés",
          "independent_coach": "Administrateurs"
        },
        "update-employee": "Mettre à jour les informations sur les employés",
        "update-employee-roles": "Mettre à jour les rôles des employés",
        "update-employee-roles-self": "Mettez à jour vos rôles de compte",
        "update-employee-self": "Mettre à jour les informations de votre compte",
        "view-employee-summary": "Voir la page de résumé des employés"
      },
      "contract-templates": {
        "add": "Ajouter un modèle de contrat",
        "subtitle": "Créez des modèles de vos calendriers de facturation préférés.",
        "table": {
          "headers": {
            "amount": "Montant",
            "auto-send": "Envoi automatique",
            "billing-metric": "Métrique de facturation",
            "created-at": "Date de création",
            "created-by": "Créé par",
            "interval": "Intervalle",
            "name": "Nom",
            "stripe": "Stripe",
            "updated-at": "Dernière mise à jour"
          }
        },
        "title": "Modèles de contrat"
      },
      "data-table": {
        "created-by": "Créé par",
        "date-created": "date créée",
        "last-updated": "Dernière mise à jour",
        "name": "prénom",
        "total-hours": "Heures totales",
        "total-sessions": "Total des sessions"
      },
      "invoicing": {
        "add-tax-dialog": {
          "creating": "Économie d'impôt...",
          "edit-title": "Modifier la taxe",
          "rate-helper": "Veuillez spécifier le taux en pourcentage (de 0 à 100).",
          "save-for-later": "Garder pour plus tard",
          "success": "Impôt enregistré avec succès.",
          "title": "Créer une nouvelle taxe"
        },
        "delete-tax-prompt": "Êtes-vous sûr de vouloir supprimer cette taxe?",
        "delete-tax-successful": "L'impôt a été supprimé avec succès.",
        "invoices": {
          "counter-helper": "Réinitialisez le compteur du numéro de facture.",
          "headline": "Réglages généraux",
          "prefix-helper": "Vous pouvez définir un préfixe qui sera ajouté à vos numéros de facture.",
          "subhead": "Personnaliser certaines options concernant la facturation ci-dessous"
        },
        "stripe": {
          "connect-button": "Relier",
          "description": "Connectez votre compte Stripe pour accepter et effectuer des paiements en quelques minutes. Configurez votre compte Stripe pour collecter facilement et en toute sécurité les informations de paiement de vos clients.",
          "headline": "Connectez votre compte Stripe",
          "powered-by-stripe": "Powered by stripe"
        },
        "stripe-connected": {
          "description": "Toutes nos félicitations! Vous avez connecté votre compte Stripe et simplifié votre paiement et votre paiement dans Coaching.com.",
          "disconnect-button": "Déconnecter",
          "headline": "Votre compte Stripe est connecté"
        },
        "taxes": {
          "add-tax-button": "Créer une taxe",
          "add-taxes": "Ajouter des taxes",
          "headline": "Approches fiscales",
          "no-taxes": "Vous n'avez pas encore ajouté de taxes.",
          "subhead": "Définir une ou plusieurs approches fiscales pouvant être appliquées lors de la facturation de vos clients",
          "tax-rate": "Taux d'imposition (pourcentage)"
        },
        "title": "Facturation"
      },
      "invoicing-payments": {
        "account-payable": {
          "headline": "Comptes à payer",
          "info": "Choisissez où les factures des fournisseurs sont reçues."
        },
        "add-new-employee": "Ajouter un nouvel employé",
        "default-currency": "Devise par défaut",
        "default-currency-description": "Sélectionnez la devise par défaut pour les nouveaux contrats et les factures.",
        "primary-card": "Carte principale",
        "save-successful": "Paramètres mis à jour",
        "set-as-primary": "Définir comme principale",
        "subtitle": "Configurez les paramètres de facturation et les options de paiement.",
        "title": "Facturation et Paiements"
      },
      "marketplace": {
        "calendar-sync": {
          "description": "Sélectionnez un fournisseur pour ajouter votre premier compte.",
          "name": "Synchronisation du calendrier",
          "title": "Connecter des calendriers"
        },
        "claim-dialog": {
          "alert": "Vous ne pouvez pas modifier votre URL une fois qu'elle est sélectionnée.",
          "confirm-button": "Réclamer l'URL",
          "selecting": "Vous sélectionnez",
          "title": "Confirmer l'URL"
        },
        "claim-url": {
          "available": "Disponible",
          "claim-url": "Réclamer l'URL",
          "claimed": "Revendiqué",
          "description": "Chez Coaching.com, nous pensons que tout le monde devrait être habilité à se démarquer. Vous pouvez désormais le faire avec votre propre URL personnalisée Coaching.com. Recherchez ci-dessous et revendiquez le vôtre!",
          "search-placeholder": "Votre lien unique",
          "title": "Choisissez votre URL",
          "unavailable": "Indisponible"
        },
        "coaching-info": "Vos informations de coaching",
        "complete-first": "Vous devez compléter votre profil avant de publier.",
        "contact-info": {
          "description": "Vos clients auront besoin d'un moyen de vous contacter.",
          "helper": "Le prénom, le nom et l'adresse email sont des champs obligatoires.",
          "name": "Informations de contact",
          "title": "Coordonnées complètes"
        },
        "description": "Remplissez ces conditions pour pouvoir publier votre profil",
        "getting-started": "Commencer",
        "interview": {
          "desc": "La description",
          "desc-val": "Un appel de 15 minutes pour déterminer s'il y a un bon ajustement pour une relation de coaching",
          "disabled": "Désactivé",
          "duration": "Durée",
          "duration-val": "15 min",
          "enabled": "Activé",
          "interview-name": "Appel de chimie",
          "location": "Emplacement",
          "location-val": "Coaching.com Video",
          "name": "Nom",
          "tab-title": "Entretien avec Marketplace",
          "title": "Autoriser l'entrevue sur la place de marché"
        },
        "packages": {
          "activate-success": "Le package a été activé avec succès.",
          "create-new": "Créer un nouveau",
          "deactivate-success": "Le package a été désactivé avec succès.",
          "empty-message": "Aucun paquet ajouté.",
          "package-dialog": {
            "create-package": "Créer un package",
            "create-template-cta": "Vous ne trouvez pas le modèle que vous recherchez? Créez un nouveau modèle de programme.",
            "create-template-cta-button": "Créer un nouveau",
            "create-title": "Ajouter un nouveau package",
            "description-placeholder": "Fournissez un aperçu du package. Vous pouvez regrouper vos programmes de coaching en fonction de la durée, du sujet, du public ou de tout autre moyen qui, selon vous, donne aux clients potentiels une idée claire de ce qu’ils achètent.",
            "edit-title": "Modifier le package",
            "name-placeholder": "Soyez succinct mais descriptif",
            "price-notice": "Prix que les clients paieront d'avance pour le forfait",
            "program-template-info": "Informations sur le modèle de programme"
          },
          "package-item": {
            "price": "Prix",
            "total-hours": "Heures totales",
            "total-sessions": "Total des sessions"
          },
          "title": "Paquets",
          "warning": "Remarque: les clients ne peuvent pas acheter vos services tant que vous n'avez pas au moins un package actif et un profil de publication."
        },
        "profile-picture": {
          "name": "Image de profil",
          "title": "Mettre à jour l'image de profil"
        },
        "publish-profile": "Publier le profil",
        "publish-success": "Votre profil a été publié!",
        "publish-success-dialog": {
          "title": "Félicitations, votre profil est désormais public! La section Trouver un coach de Coaching.com sera disponible d'ici le 1er février.",
          "view-profile": "Voir le profil"
        },
        "published": "Votre profil est actuellement visible sur la marketplace.",
        "stripe": {
          "description-connected": "Votre compte Stripe est connecté.",
          "description-disconnected": "C'est ainsi que vous serez payé!",
          "name": "Connectez-vous à Stripe",
          "title": "Connectez votre compte Stripe"
        },
        "subtitle": "Gérez les paramètres de votre marketplace Coaching.com.",
        "summary": {
          "minimum-characters": "Minimum {min} caractères",
          "name": "Sommaire",
          "placeholder": "Utilisez cet espace pour vous décrire, décrire votre expérience de coaching et l'approche que vous adoptez pour travailler avec les clients. Soyez concis mais aussi créatif! C'est ainsi que les clients potentiels peuvent en savoir plus sur vous et votre style de coaching.",
          "remaining-characters": "{value} {value, plural, one {caractère} other {caractères}} nécessaires",
          "title": "Résumé complet"
        },
        "terms-dialog": {
          "accept-label": "Je confirme que les affirmations ci-dessus sont vraies",
          "comission-msg": "Des frais de commission de {commission}% seront appliqués à vos forfaits.",
          "commission-reduce": "Réduisez ces frais à 15%",
          "pro": "en mettant à jour votre plan en pro.",
          "publish": "Publier le profil",
          "terms": "En cochant la case ci-dessous, vous confirmez que vous: <ul class=\"cl-list-disc cl-list-inside cl-mt-1\">\n  <li>Avoir travaillé avec au moins 5 clients de coaching payants au cours des 10 dernières années</li>\n  <li>Avoir au moins 50 heures d'expérience en coaching rémunéré au cours des 10 dernières années</li>\n</ul>\n",
          "title": "Exigence minimale en matière d'expérience en coaching"
        },
        "title": "Marketplace",
        "unpublish-success": "Votre profil n'a pas été publié.",
        "unpublished": "Votre profil n'est pas visible sur le marché.",
        "url-claimed": "Votre URL a été revendiquée avec succès."
      },
      "my-profile": {
        "title": "Mon Profil"
      },
      "notifications": {
        "title": "Notifications"
      },
      "org-structure": {
        "department": {
          "add": "Ajouter un département",
          "add-business-unit": "Ajouter une unité commerciale",
          "business-unit": "Unité d'affaires",
          "delete-confirm": "Êtes-vous sûr de vouloir supprimer ce département?",
          "department": "Département",
          "no-departments": "Aucun département",
          "subtitle": "Gérer les départements de l'entreprise qui représentent votre structure organisationnelle.",
          "title": "Départements"
        },
        "location": {
          "add": "Ajouter un emplacement",
          "address": "Adresse",
          "address2": "Adresse 2",
          "city": "Ville",
          "company-location": "Emplacement de la société",
          "delete-confirm": "Êtes-vous sûr de vouloir supprimer cet emplacement?",
          "phone": "Téléphone",
          "postal-code": "Code Postal",
          "state": "Etat/Province",
          "subtitle": "Gérer les emplacements de l'entreprise dans différentes zones géographiques.",
          "table": {
            "address": "Adresse:",
            "delete": "Effacer",
            "edit": "Modifier",
            "no-locations": "Aucun Endroit",
            "phone": "Téléphone:"
          },
          "title": "Emplacements"
        },
        "location-updated": "Lieu mis à jour",
        "subtitle": "Configurez la structure organisationnelle de votre entreprise afin de fournir aux administrateurs de comptes différents niveaux d'accès et de générer des rapports en fonction de vos sites, départements et unités commerciales.",
        "table": {
          "address": "Adresse",
          "city": "Ville",
          "country": "Pays",
          "name": "Nom",
          "phone": "Téléphone"
        },
        "title": "Structure Organisationnelle"
      },
      "payments": {
        "add-card-dialog": {
          "creating": "Création de carte...",
          "success": "Carte créée avec succès",
          "title": "Créer une nouvelle carte"
        },
        "cards": {
          "add-card-button": "Créer une nouvelle carte",
          "added-by": "Ajouté par",
          "added-on": "ajouté le",
          "headline": "Cartes",
          "no-cards": "Tu n'as pas encore ajouté de cartes.",
          "subhead": "Connectez vos cartes de crédit/débit à votre compte Coaching.com."
        },
        "delete-card-successful": "La carte a été supprimée avec succès.",
        "delete-prompt": "Êtes-vous sûr de vouloir supprimer cette carte?",
        "delete-prompt-title": "Confirmation",
        "subtitle": "Gérez vos paiements de factures et ajoutez des cartes de crédit.",
        "title": "Facturation"
      },
      "program-templates": {
        "add": "Ajouter modèle de programme",
        "clone": "Voulez-vous cloner un modèle existant?",
        "subtitle": "Gérez les modèles de programme que vous pouvez utiliser dans vos engagements.",
        "title": "Modèles de Programme"
      },
      "subscription": {
        "account-support-dialog": {
          "info": "Veuillez contacter votre responsable de compte Coaching.com ou <a href=\"mailto:support@coaching.com\" class=\"cl-link\">support@coaching.com</a> pour modifier votre forfait.",
          "title": "Gérer un plan personnalisé"
        },
        "active-coaches-dialog": {
          "active-engagements": "{num} engagements actifs",
          "title": "Entraîneurs actifs"
        },
        "additional": "sièges supplémentaires.",
        "cancel-dialog": {
          "prompt": "Êtes-vous sûr de vouloir annuler votre plan?",
          "subscription-canceled": "Votre plan a été annulé.",
          "title": "Annuler le plan"
        },
        "each-seat": "Chaque siège supplémentaire coûte",
        "for-the": "pour le",
        "free-plan-upgrade-message": "Vous avez atteint la limite des engagements actifs dans votre plan gratuit. Veuillez acheter un abonnement pour commencer cet engagement.",
        "payment-card": {
          "check": "Chèque ou transfert ACH",
          "helper-message": "C'est la méthode de paiement que nous utiliserons pour imputer votre plan. Vous pouvez appuyer sur <strong>\"Mettre à jour\"</ strong> ci-dessous pour le modifier.",
          "not-subscribed": "Vous n'êtes pas abonné à un plan payant.",
          "title": "Méthode de paiement actuelle",
          "update-button": "Mettre à jour"
        },
        "payment-dialog": {
          "title": "Changer votre mode de paiement"
        },
        "per-coach": "par entraîneur supplémentaire",
        "plan": "plan",
        "plan-card": {
          "active-plan-message": "Votre plan actuel est <strong>{name} ({interval})</strong>.",
          "descriptions": {
            "business": "Comprend un siège. Chaque siège reçoit un entraîneur actif et 10 engagements actifs supplémentaires. Passez à Business Pro pour des engagements illimités par siège.",
            "business_free": "Vous pouvez gérer un engagement gratuitement. Sélectionnez un plan pour gérer l'ensemble de votre pratique avec Coaching.com.",
            "business_plus": "Comprend un siège. Chaque siège prend en charge un entraîneur actif avec des engagements illimités.",
            "enterprise": "",
            "enterprise_free": "",
            "enterprise_plus": "",
            "independent_coach": "Vous pouvez gérer jusqu'à 10 engagements actifs sur ce plan. Passez à Coach Pro pour gérer l'ensemble de votre pratique avec Coaching.com.",
            "independent_coach_free": "Vous pouvez gérer jusqu'à 3 engagements actifs gratuitement. Sélectionnez un plan pour gérer l'ensemble de votre pratique avec Coaching.com.",
            "independent_coach_plus": "Vous pouvez gérer des engagements illimités sur ce plan."
          },
          "expires-at": "Expire à",
          "first-payment": "Premier paiement le",
          "manage-coaches-message": "Voulez-vous gérer des entraîneurs supplémentaires? Voir nos plans d'affaires.",
          "next-payment": "Prochain paiement le",
          "payment-interval": "Intervalle de paiement",
          "payment-method": "Mode de paiement",
          "title": "Plan actuel",
          "view-business-plans-button": "Voir les plans d'affaires",
          "view-coach-plans-button": "Voir les plans de l'entraîneur"
        },
        "plans": {
          "business": "Business Standard",
          "business_free": "Business Free",
          "business_plus": "Business Pro",
          "enterprise": "Enterprise",
          "enterprise_free": "Enterprise Free",
          "enterprise_plus": "Enterprise",
          "independent_coach": "Independent Coach Standard",
          "independent_coach_free": "Independent Coach Free",
          "independent_coach_plus": "Independent Coach Pro"
        },
        "plans-dialog": {
          "cancel-button": "Annuler",
          "current-plan-ribbon": "Plan actuel",
          "free-label": "Gratuit",
          "subscribe-button": "Souscrire",
          "update-button": "Mettre à jour",
          "yearly-plan-label": "Afficher les plans annuels"
        },
        "seats-dialog": {
          "annual": "par an",
          "info": "<strong>1 place est incluse dans votre forfait.</strong> Les sièges ne sont obligatoires que pour les entraîneurs ayant des engagements actifs. De combien de sièges supplémentaires votre entreprise a-t-elle besoin?",
          "monthly": "par mois",
          "per-seat-price": "Chaque siège supplémentaire coûte {price} {interval} chacun.",
          "price-message": "Votre plan <strong>{plan}</strong> et <strong>{numSeats} {numSeats, plural, one {siège} other {sièges}} supplémentaires</strong> coûteront <strong>{price} {interval}</strong>.",
          "questions": "Avoir des questions? <a href=\"https://www.coaching.com/pricing\" target=\"_blank\" rel=\"noopener noreferrer\">Consultez la section FAQ de notre page de tarification.</a>",
          "seats": "Sièges supplémentaires",
          "success-message": "Planifiez les modifications enregistrées.",
          "title": "Gérer les sièges",
          "yearly": "par an"
        },
        "subscribe-dialog": {
          "added-new-card": "Ajouter une carte de crédit ou de débit",
          "added-on": "ajouté sur",
          "finish": "Terminer",
          "payment": "Paiement",
          "questions": "Avoir des questions? <a href=\"https://www.coaching.com/pricing\" target=\"_blank\">Consultez la section FAQ de notre page de tarification.</a>",
          "subscribe-confirmation": "Vous êtes maintenant sur le plan {plan} {interval}.",
          "subscribing": "S'abonner...",
          "subscriptions": "Des places",
          "subscriptions-prompt": "<strong>1 siège est inclus avec votre plan.</strong> Les sièges ne sont requis que pour les entraîneurs ayant des engagements actifs. De combien de sièges supplémentaires votre entreprise a-t-elle besoin?"
        },
        "subscriptions-card": {
          "descriptions": {
            "business-pro": "{seats} {seats, plural, one {siège} other {sièges}} soutient {seats} {seats, plural, one {entraîneur actif} other {entraîneurs actifs}} avec des engagements illimités.",
            "business-standard": "{seats} {seats, plural, one {siège} other {sièges}} prend en charge {seats} {seats, plural, one {entraîneur actif} other {entraîneurs actifs}} avec jusqu'à {numEngagements} engagements actifs."
          },
          "one-free-message": "Vous avez 1 abonnement gratuit.",
          "seats-button": "Gérer les sièges",
          "see-coaches": "Montrer les entraîneurs utilisant des sièges",
          "title": "Abonnements"
        },
        "subscriptions-upgrade-message": "Vous avez atteint la limite des engagements actifs dans votre plan. Veuillez acheter plus d'abonnements pour commencer cet engagement.",
        "title": {
          "plural": "Abonnements",
          "singular": "Abonnement"
        },
        "update-dialog": {
          "prompt": "Êtes-vous sûr de vouloir passer au plan <strong>{name} ({interval})</strong>?",
          "subscription-updated": "L'abonnement a été mis à jour avec succès.",
          "title": "Mise à jour"
        },
        "upgrade-message": "Mettez à jour votre forfait actuel, ajoutez et supprimez des abonnements et définissez votre mode de paiement.",
        "upgrade-messages": {
          "max_engagements_business": "Vous avez atteint le nombre maximal d'engagements actifs dans le plan Business Standard. Passez à Business Pro pour commencer cet engagement.",
          "max_engagements_business_free": "Sélectionnez un plan pour gérer l'ensemble de votre pratique avec Coaching.com.",
          "max_engagements_independent_coach": "Vous avez atteint le nombre maximal d'engagements actifs dans le plan Coach Standard. Passez à Coach Pro pour commencer cet engagement.",
          "max_engagements_independent_coach_free": "Vous avez atteint le nombre maximum d'engagements actifs dans le plan libre. Améliorez votre plan pour commencer cet engagement.",
          "max_seats_business": "Tous les sièges sont actuellement utilisés. Ajoutez un autre siège pour commencer cet engagement.",
          "max_seats_business_plus": "Tous les sièges sont actuellement utilisés. Ajoutez un autre siège pour commencer cet engagement."
        },
        "upgrade-messages-support": {
          "max_engagements": "Vous avez atteint le nombre maximum d'engagements actifs dans votre plan.",
          "max_seats": "Tous les sièges sont actuellement utilisés."
        },
        "upgrade-now": "Veuillez mettre à jour maintenant pour activer cette fonctionnalité dans votre compte.",
        "will-charge": "Nous allons vous facturer",
        "with": "avec"
      },
      "templates": {
        "title": "Modèles"
      },
      "title": "Paramètres"
    },
    "status": {
      "active": "Actif",
      "approved": "Approuvé",
      "assigned": "Assigné",
      "awaiting_approval": "En Attente d'Approbation",
      "canceled": "Quitté",
      "completed": "Complété",
      "deleted": "Supprimé",
      "draft": "Brouillon",
      "in_review": "En Revue",
      "internal_record": "Enregistrement Interne",
      "invited": "Invitation Envoyée",
      "paid": "Payé",
      "pending": "En Attente",
      "scheduled": "Plannifié",
      "sent": "Envoyé"
    },
    "ui": {
      "account-managers": {
        "account-manager-updated": "Gestionnaire de compte mis à jour.",
        "disconnect": "Déconnecter",
        "edit-account-manager": "Modifier le gestionnaire de compte",
        "email": "Email",
        "first-name": "Prénom",
        "last-name": "Nom de famille",
        "phone": "Téléphone",
        "resend-invitation": "Renvoyer l'invitation",
        "send-email": "Envoyer un email",
        "send-invitation": "Envoyer une invitation"
      },
      "activate": "Activer",
      "active": "actif",
      "add": "Ajouter",
      "add_additional_message": "Ajouter un email supplémentaire",
      "add_another_attachment": "Ajouter une autre pièce jointe",
      "add_attachment": "Ajouter une pièce jointe",
      "add_company": "Ajouter une entreprise",
      "add_message_type": "Ajouter un type de message",
      "add_new_role": "Ajouter un nouveau rôle",
      "add_new_stakeholder": "Ajouter une nouvelle partie prenante",
      "add_notification": "Ajouter une notification",
      "add_role": "Ajouter rôle",
      "add_stakeholder": "Ajouter un intervenant",
      "add_title": "Ajouter un titre",
      "add_to_admin": "Ajouter à l'Admin",
      "address1-label": "Adresse",
      "address2-label": "Appt/Bâtiment",
      "all": "Tout",
      "approve": "Approuver",
      "at-capacity": "À capacité",
      "availability": {
        "available": "Accepter les clients",
        "unavailable": "À capacité"
      },
      "availability-dialog": {
        "force-schedule-description": "La planification pendant les heures chargées peut entraîner des conflits.",
        "schedule": "Programme",
        "schedule-title": "Confirmation de planification",
        "schedule-warning": "La planification de cette session enverra des courriels de confirmation à tous les participants.",
        "session-date": "<b>Date prévue:</b> {date}",
        "session-duration": "<b>Durée:</b> {duration} minutes",
        "session-title": "<b>Titre:</b> {title}",
        "title": "Sélectionnez un créneau horaire disponible",
        "today": "Aujourd'hui"
      },
      "back": "Arrière",
      "bold": "Gras",
      "bullet-list": "Liste des balles",
      "calendar": {
        "busy": "Importer les heures de pointe",
        "two-way": "Synchronisation bidirectionnelle complète"
      },
      "cancel": "Annuler",
      "canceled-on": "Annulé le",
      "card-number-mask": "XXXX XXXXX XXXX {cardLastFour}",
      "change": "Changement",
      "city-label": "Ville",
      "clear": "Effacer",
      "client_companies": "Entreprises clientes",
      "close": "Fermer",
      "coach-profile": {
        "assessments": {
          "assessments": "Évaluations",
          "no-assessments-added": "Aucune évaluation ajoutée pour le moment",
          "update-assessments": "Mettre à jour les évaluations"
        },
        "availability": {
          "accepting": "Accepter les clients",
          "availability": "Disponibilité de l'entraîneur",
          "available": "Je suis disponible pour le coaching",
          "capacity": "À capacité",
          "edit": "Changer la disponibilité",
          "unavailable": "Je ne suis pas disponible pour le coaching"
        },
        "coaching-experience": {
          "coaching-experience": "Expérience de coaching",
          "create-new-industry": "Créer une nouvelle industrie",
          "edit-experience": "Modifier l'expérience de coaching",
          "experience-updated": "Expérience mise à jour",
          "how-many-years-coaching": "Depuis combien d'années entraînez-vous?",
          "industries": "les industries",
          "industries-dialog-title": "Industries (Ajoutez jusqu'à 7 tags)",
          "leader-levels": "Niveaux de leader",
          "no-industries-added": "Aucune industrie ajoutée pour le moment.",
          "no-levels-added-yet": "Aucun niveau ajouté pour le moment.",
          "no-years-of-experience": "Aucune année d'expérience ajoutée pour le moment.",
          "update-coaching-experience": "Mise à jour de l'expérience de coaching",
          "update-industries": "Mise à jour des industries",
          "update-leader-levels": "Mettre à jour les niveaux de leader",
          "years-coaching": "Années de coaching"
        },
        "contact": {
          "city": "Ville",
          "contact": "Contact",
          "country": "Pays",
          "edit-info": "Modifier les informations de contact",
          "email": "Email",
          "info-updated": "Informations de contact mises à jour.",
          "location": "Emplacement",
          "no-info": "Aucune information de contact ajoutée pour le moment",
          "phone": "Numéro de téléphone",
          "title": "Titre",
          "update": "Mettre à jour les informations de l'entraîneur",
          "website": "Site Internet"
        },
        "credentials": {
          "credentials": "Lettres de créance",
          "no-credentials-added": "Vous n'avez ajouté aucune information d'identification.",
          "update-credentials": "Mettre à jour les informations d'identification"
        },
        "education-degrees": {
          "add-education": "Ajouter l'éducation",
          "clear-date": "Effacer la date",
          "degree": "Diplôme",
          "edit-education": "Editer l'éducation",
          "education": "Éducation",
          "end-date": "Date de fin",
          "major": "Majeur",
          "no-education-added": "Vous n'avez pas encore ajouté vos diplômes d'études.",
          "school": "École",
          "start-date": "Date de début"
        },
        "empty-states-no-data": "L'utilisateur n'a pas encore de données disponibles.",
        "invalid-end-date": "Date de fin invalide",
        "invalid-start-date": "Date de début invalide",
        "languages": {
          "add-language": "Ajouter une langue",
          "elementary-proficiency": "Élémentaire",
          "fluency": "Aisance",
          "languages": "Les langues",
          "limited-working-proficiency": "Débutant",
          "native-proficiency": "Expert",
          "no-language-added": "Vous n'avez pas encore ajouté de langue.",
          "no-language-match": "Aucune langue ne correspond.",
          "professional-proficiency": "Avancée",
          "professional-working-proficiency": "Intermédiaire",
          "select-a-language": "Sélectionnez une langue..."
        },
        "modalities": {
          "audio": "Téléphone / audio seulement",
          "in-person": "En personne",
          "modalities": "Les modalités",
          "modalities-updated": "Modalités mises à jour.",
          "no-modalities-added": "Aucune modalité ajoutée.",
          "video": "Vidéo conférence"
        },
        "no-start-error": "La date de fin ne peut être spécifiée sans une date de début.",
        "ongoing": "Est actuellement en cours.",
        "present": "Présent",
        "profile-update": "Les informations de profil ont été mises à jour.",
        "specialties": {
          "create-new-specialty": "Créer une nouvelle spécialité",
          "no-added-specialties": "Aucune spécialité ajoutée pour le moment.",
          "placeholder": "Sélectionnez jusqu'à 5 tags",
          "specialties": "Spécialités",
          "specialties-updated": "Les spécialités ont été mises à jour.",
          "update-specialties": "Mettre à jour les spécialités"
        },
        "start-end": "La date de début doit être antérieure à la date de fin.",
        "summary": {
          "no-added-summary": "Aucun résumé ajouté pour le moment.",
          "summary": "Résumé",
          "summary-placeholder": "Tapez ici quelque chose à propos de vous ...",
          "summary-updated": "Le résumé a été mis à jour.",
          "update-summary": "Résumé de la mise à jour"
        },
        "update-cover-photo": "Mettre à jour la couverture photo",
        "user-affiliation": {
          "add-affiliation": "Ajouter une affiliation",
          "affiliations": "Les affiliations",
          "description": "La description",
          "name": "prénom",
          "no-affiliation": "Aucune affiliation ajoutée pour l'instant",
          "year-joined": "Année d'adhésion"
        },
        "video": {
          "add-video": "Ajouter une vidéo",
          "delete-video": "Supprimer la vidéo",
          "edit-video": "Modifier l'URL de la vidéo",
          "no-video-added": "Vous n'avez pas encore ajouté de vidéo.",
          "paste-youtube-video": "Coller le lien vidéo YouTube ici",
          "video": "Vidéo",
          "video-updated": "La vidéo a été mise à jour."
        },
        "work-experience": {
          "add-work-experience": "Ajouter une expérience de travail",
          "clear-date": "Effacer la date",
          "description": "La description",
          "end-date": "Date de fin",
          "no-work-experience-added": "Vous n'avez pas encore ajouté votre expérience professionnelle.",
          "organization": "Organisation",
          "role": "Rôle",
          "start-date": "Date de début",
          "work-experience": "L'expérience professionnelle",
          "work-experience-updated": "L'expérience de travail a été mise à jour."
        }
      },
      "coach-profiles": {
        "coaching-types": {
          "coaching-types": "Types de coaching",
          "create-new-coaching-type": "Créer un nouveau type de coaching",
          "no-added-types": "Aucun type d'entraîneur ajouté pour le moment.",
          "placeholder": "Sélectionnez jusqu'à 3 tags",
          "type-of-coach": "Type d'entraîneur",
          "update-coach-types": "Mettre à jour les types de coach"
        },
        "employee-levels": {
          "create-new-employee-level": "Créer un nouveau niveau",
          "employee-levels": "Niveaux de leader",
          "placeholder": "Sélectionnez les niveaux de leader"
        },
        "industries": {
          "create-new-industry": "Créer une nouvelle industrie",
          "industries": "les industries",
          "placeholder": "Sélectionnez jusqu'à 7 tags"
        }
      },
      "coaches_per_page": "Entraîneurs par page",
      "company": "entreprise",
      "complete": "Achevée",
      "completed": "Terminé",
      "completed-on": "Terminé le",
      "confirm": "Confirm",
      "confirmation": "Confirmation",
      "confirmation-dialog": {
        "connect-confirmation": "Voulez-vous vraiment vous connecter ?",
        "internal-invitation-confirmation": "Une fois connecté, vous aurez tous deux accès à toutes les données d'engagement mutuel.Cela comprend les éléments de programme partagés, les ressources, les notes, les objectifs et les informations sur les participants'"
      },
      "contact_email": "Email du contact",
      "country-label": "Pays",
      "create": "Créez",
      "credit-card-number-label": "Credit Card Number",
      "credit-or-debit": "Crédit ou Débit",
      "currency": "Devise",
      "current-timezone-helper": "Votre fuseau horaire actuel sera défini en fonction du fuseau horaire de l'appareil que vous utilisez. Le fuseau horaire actuel du navigateur est <strong>{name}</strong>.",
      "custom-notifications-dialog": {
        "enable-notification": {
          "file": {
            "description": "Vous permet de personnaliser la notification envoyée lors de l'envoi de ce fichier",
            "main": "Personnaliser la notification de fichier reçu"
          },
          "form": {
            "description": "Vous permet de personnaliser la notification envoyée lorsque ce formulaire est envoyé",
            "main": "Personnaliser le formulaire reçu notification"
          },
          "session": {
            "description": "Vous permet de personnaliser la notification envoyée lors de la planification de cette session",
            "main": "Personnaliser la notification de planification"
          },
          "todo": {
            "description": "Vous permet de personnaliser la notification envoyée lors de l'envoi de cette tâche",
            "main": "Personnaliser la notification de tâche reçue"
          }
        },
        "notification-updated": "Notification mise à jour.",
        "title": "Les notifications"
      },
      "cvc-label": "CVC",
      "date": "Date",
      "deactivate": "Désactiver",
      "decline": "Déclin",
      "delete": "Supprimer",
      "delete-confirmation": {
        "fail": "{modelName} n'a pas pu être supprimé.",
        "successful": "{modelName} supprimé avec succès."
      },
      "delete-prompt": "Êtes-vous sûr de vouloir supprimer?",
      "delivery_time": "Heure de livraison",
      "department": "Département",
      "description": "La description",
      "details": "Détails",
      "direct_coachees": "Coachés directs",
      "discard": "Jeter",
      "discard-prompt": "Annuler les modifications?",
      "disconnect": "Déconnecter",
      "dismiss": "Rejeter",
      "done": "Done",
      "download": "Télécharger",
      "dummy-email": "Email@email.com",
      "duplicate": "Faire une copie",
      "edit": "Modifier",
      "email": "Email",
      "employee-roles-dialog": {
        "add-new-role": "Ajouter un nouveau rôle",
        "add-role": "Ajouter un rôle",
        "does-provide-coaching": "Cet employé offre-t-il un coaching?",
        "employee-updated": "Employé mis à jour.",
        "remove-role": "Supprimer le rôle",
        "user-role-updated": "Rôles d'utilisateur mis à jour."
      },
      "employee_provide_coaching": "Est-ce que cet employé fournit du coaching?",
      "employees": "Des Employés",
      "empty-group": "Ce dossier est vide.",
      "engagement": "Engagement",
      "enter_poc": "Veuillez entrer le courriel du point de contact.",
      "experience": {
        "0-5": "0-5 ans",
        "10-20": "10-20 ans",
        "20-30": "20-30 ans",
        "30+": "plus de 30 ans",
        "5-10": "5-10 ans"
      },
      "expiration-label": "Expiration",
      "facebook": "Facebook",
      "features": "Caractéristiques",
      "files": "Des dossiers",
      "filter": "Filtrer",
      "finish": "Terminer",
      "first-name": "Prénom",
      "found_user": "Nous avons trouvé l'utilisateur sur Coaching.com!",
      "free-plan": "Plan Libre",
      "gender-label": "Le Genre",
      "genders": {
        "female": "Femelle",
        "male": "Mâle",
        "other": "Autre / Non-binaire"
      },
      "goal": {
        "add-task": "Ajouter une tâche",
        "deleted": "Objectif supprimé.",
        "feedback": "Demander des commentaires",
        "private": "Privé",
        "public": "Publique",
        "stakeholder-feedback": "\"Les parties prenantes suivantes recevront une demande de classement d'objectif et\n commentaire facultatif sur cet objectif. Ils pourront voir le nom et l'objectif de l'objectif\n description seulement. Aucun jalon ne sera partagé. \"",
        "sub-task": {
          "add-description": "ajouter une description",
          "add-new-milestone": "Ajouter un nouveau jalon (maximum 500 caractères)",
          "add-title": "Ajouter un titre",
          "creating-new-milestone": "Créer un nouveau jalon...",
          "delete-milestone": "Supprimer un jalon",
          "send-reminder": "Envoyer un rappel",
          "set-due-date": "Fixer la date d'échéance"
        },
        "title": "Objectif"
      },
      "grid_view": "Vue de la grille",
      "groups": "Dossiers",
      "inactivate": "Désactiver",
      "inactive": "Inactif",
      "invitation": "Invitation",
      "invite": "Inviter",
      "invite-dialog": {
        "about-this-coach": "A propos de ce coach",
        "already-connected-coach-confirmation": "Voulez-vous continuer?",
        "already-connected-coach-message": "Vous êtes déjà connecté à ce coach. Si vous souhaitez passer le contrat <strong>{employee}</strong> par le biais de <strong>{client}</strong> , vous devez d&#39;abord mettre fin à votre relation antérieure avec <strong>{employee}</strong> .",
        "already-connected-poc-error": "Cet utilisateur ne peut pas être ajouté en tant qu'administrateur d'une autre société car il est déjà votre employé.",
        "coach-added": "Coach ajouté.",
        "coach-requested": "Demande de partage de l'entraîneur envoyée.",
        "coachee-added": "Coachee ajouté.",
        "coachee-requested": "Demande de partage de coaché ​​envoyée.",
        "confirm-user-message": "Veuillez confirmer qu'il s'agit du {userType} auquel vous essayez de vous connecter.",
        "contact-email": "Email du contact",
        "enter-email": "Entrez l'email",
        "enter-usertype-name": "Entrez le nom de {userType}",
        "errors": {
          "existing-client": "Vous êtes déjà connecté à ce compte.",
          "existing-coach": "Ce coach a déjà été ajouté.",
          "existing-coachee": "Ce coaché a déjà été ajouté.",
          "existing-employee": "Un employé existe déjà avec cet email pour ce compte.",
          "existing-poc": "Ce point de contact a déjà été ajouté.",
          "external-coach": "Cet email est déjà utilisée. Veuillez réessayer."
        },
        "invalid-coach": "Cet entraîneur ne peut pas être ajouté comme entraîneur interne.",
        "invalid-email": "Email invalide.",
        "invalid-poc": "Cet email ne correspond pas à l'administrateur de l'entreprise.",
        "invite-text": "Si vous n’envoyez pas l’invitation maintenant, cet utilisateur sera ajouté en tant qu’enregistrement interne que vous pourrez suivre. Vous pouvez les inviter plus tard à tout moment pour leur permettre d'accéder à leur compte.",
        "invite-types": {
          "client-company": {
            "description": "Une organisation qui achète des services de coaching.",
            "title": "Société cliente"
          },
          "client-poc": {
            "description": "Ajouter un nouveau point de contact",
            "title": "Ajouter PoC"
          },
          "directly-contracted-coachee": {
            "description": "Un client individuel qui paie pour son propre coaching.",
            "title": "Client individuel"
          },
          "employee": {
            "description": "Un employé de votre organisation",
            "title": "Employé"
          },
          "external-participant": {
            "description": "Un participant qui n'appartient pas à votre organisation",
            "title": "Participant externe"
          },
          "external-vendor": {
            "description": "Une organisation qui fournit des services de coaching.",
            "title": "Fournisseur externe"
          },
          "independent-coach": {
            "description": "Un coach indépendant, contracté directement.",
            "title": "Coach indépendant"
          },
          "internal-coach": {
            "description": "Un employé de votre entreprise qui fournit des services de coaching.",
            "title": "Coach interne"
          },
          "request-coach": {
            "description": "Demander un coach auprès d'un fournisseur",
            "title": "Demander un coach au vendeur"
          },
          "request-coachee": {
            "description": "Demander un coaché ​​à une organisation cliente",
            "title": "Demander un client coaché"
          },
          "sponsored-coachee": {
            "description": "Un coaché ​​parrainé par une entreprise cliente.",
            "title": "Coaché ​​parrainé"
          },
          "vendor-provided-coach": {
            "description": "Un coach qui travaille avec un vendeur de coaching ou un groupe de coaches.",
            "title": "Coach contracté par le vendeur"
          }
        },
        "max-chars": "Votre terme de recherche doit comporter au moins {n} caractères.",
        "poc-added": "Point de contact ajouté.",
        "request-add-description": "Vous êtes sur le point d'ajouter un nouvel entraîneur à votre cadre qui sera disponible pour un entraînement à {client}.",
        "user-types": {
          "poc": "point de contact"
        },
        "usertype-name": "À propos de ça {userType}",
        "verifying": "Vérification ..."
      },
      "invite-later": "Vous pouvez inviter plus tard",
      "invite-now": "Envoyer une invitation maintenant",
      "invite-resent-success": "Inviter renvoyé avec succès",
      "invite-sent-success": "Invitation envoyée avec succès",
      "italic": "Italique",
      "language": {
        "en-us": "Anglais (EU)",
        "fr-fr": "Français (FR)",
        "label": "Langage"
      },
      "last-name": "Nom",
      "less_details": "Moins de détails",
      "linkedin": "LinkedIn",
      "list_view": "Vue liste",
      "loading": "Loading...",
      "location": "Emplacement",
      "manage": "Gérer",
      "meeting-locations-dialog": {
        "add-location-address": "Ajouter une adresse de lieu",
        "add-new": "Ajouter nouveau",
        "add-phone-number": "Ajouter un numéro de téléphone",
        "add-video-url": "Ajouter l'URL de la vidéo",
        "default-location-saved": "Lieu de réunion par défaut mis à jour.",
        "delete-meeting-location": "Supprimer le lieu de la réunion",
        "location-deleted": "Lieu de réunion supprimé.",
        "location-updated": "Détails de l'emplacement mis à jour.",
        "meeting-locations": "Lieu de réunion",
        "no-locations": "Aucun lieu trouvé.",
        "save-for-later": "Garder pour plus tard",
        "saved": "Enregistré",
        "untitled-meeting-location": "Lieu de réunion sans titre"
      },
      "minimize": "Minimiser",
      "more-options": "Plus d'options",
      "more_details": "Plus de détails",
      "na": "N / A",
      "name": "Name",
      "name-on-card": "Name on card",
      "next": "Prochain",
      "no": "No",
      "none": "Aucun",
      "not_assigned": "Non attribué",
      "note": "Remarque",
      "notifications": {
        "sent_when_scheduled": "This notification will be sent when the session is scheduled."
      },
      "numbered-list": "Liste numérotée",
      "off": "Off",
      "ok": "OK",
      "on": "On",
      "onboarding": {
        "callout": "Commencer",
        "clients-slide": {
          "headline": "Gagnez plus de clients",
          "subhead": "Invitez les clients à s'engager et à collaborer dans leur parcours d'accompagnement à travers les portails clients."
        },
        "coachee-connected-slide": {
          "headline": "Restez connecté à votre coach",
          "subhead": "Communiquez rapidement et facilement avec des notes et des objectifs partagés"
        },
        "coachee-development-slide": {
          "headline": "Prenez en charge votre développement",
          "subhead": "Travaillez avec votre coach pour fixer des objectifs et suivre vos progrès."
        },
        "coachee-schedule-slide": {
          "headline": "Planifiez votre prochaine session",
          "subhead": "Voir les heures où votre coach est disponible pour planifier des sessions"
        },
        "coaches-slide": {
          "headline": "Tous vos coaches en un seul endroit",
          "subhead": "Créer des engagements avec des coaches internes et sous contrat.."
        },
        "dashboard-slide": {
          "headline": "Restez au top des choses",
          "subhead": "Votre tableau de bord vous permet de savoir ce qui se passe et ce qu'il faut faire ensuite."
        },
        "enterprise-scale-slide": {
          "headline": "Développer le talent à l'échelle",
          "subhead": "Gérer le coaching à l'échelle de l'entreprise avec moins de ressources."
        },
        "invoicing-slide": {
          "headline": "Facilité de facturation et de paiement",
          "subhead": "Envoyez des factures et effectuez des paiements depuis votre compte"
        },
        "provider-scale-slide": {
          "headline": "Mettre à l'échelle votre entreprise de coaching",
          "subhead": "Livrer plus avec moins de ressources"
        },
        "reports-slide": {
          "headline": "Rapports en un clic",
          "subhead": "Tenez les parties prenantes au courant des rapports pertinents."
        },
        "scheduling-slide": {
          "headline": "Une meilleure façon de planifier",
          "subhead": "Las paramètres de calendrier permet à vos clients de savoir quand vous êtes disponible."
        },
        "stripe-invoicing-slide": {
          "headline": "Facilité de facturation et de paiement",
          "subhead": "Envoyer des factures et effectuer des paiements avec notre intégration de paiement Stripe"
        }
      },
      "or": "ou",
      "page-size": "Taille de la page",
      "phone": "Téléphone",
      "plaform-language-label": "Langage",
      "platform-language-label": "Langage",
      "poc_email": "Email du point de contact",
      "postal-code-label": "Code postal",
      "previous": "Précédent",
      "primary": "Primaire",
      "purchase": "Achat",
      "rate": "Taux",
      "rating": "sur 10",
      "reject": "Rejeter",
      "remove": "Supprimer",
      "remove-chip": "Appuyez sur Supprimer pour supprimer cette puce.",
      "remove_role": "Supprimer le rôle",
      "rename": "Rename",
      "resend": "Renvoyer",
      "resources": "Ressources",
      "revert": "Revenir",
      "review-dialog": {
        "leave-feedback": "Laisser les commentaires"
      },
      "reviews": {
        "feedback": "Vos commentaires sont importants. Veuillez donner votre avis sur la progression de l'objectif de {coacheeName} ci-dessous.",
        "feedback-placeholder": "Réponse",
        "no-improvement": "Pas d'amélioration",
        "no-need": "Pas besoin d'améliorer encore",
        "num": {
          "eight": "8",
          "five": "5",
          "four": "4",
          "nine": "9",
          "one": "1",
          "seven": "7",
          "six": "6",
          "ten": "dix",
          "three": "3",
          "two": "2"
        },
        "provide-feedback": "Fournir une réponse",
        "rating": "Évaluation",
        "recorded": "Votre avis a été enregistré.",
        "subtitle": "Aidez-nous en donnant des commentaires sur les progrès de {coacheeName}.",
        "thanks": "Je vous remercie!",
        "title": "{coacheeName} a besoin de quelques commentaires pour développer: {reviewableName}"
      },
      "save": "Sauvegarder",
      "saved": "Enregistré",
      "saving": "Économie...",
      "schedule": "Programme",
      "scheduled-for": "Planifié pour",
      "search": "Chercher",
      "select": "Sélectionner",
      "select-all": "Tout sélectionner",
      "select_end_date": "Sélectionnez la date de fin",
      "select_start_date": "Sélectionnez la date de début",
      "send": "Envoyer",
      "send-invite": "Envoyer une invitation",
      "send_invitation": "Envoyer une invitation",
      "send_invitation_optional": "Envoyer une invitation? (optionnel)",
      "send_invite_later": "Vous pouvez ajouter cette personne et inviter plus tard.",
      "sending": "Envoi",
      "sent": "Envoyé",
      "share": "Partager",
      "share-dialog": {
        "add-new-share": "Partager avec d'autres",
        "add-people-groups": "Ajouter des personnes ou des groupes",
        "can-copy": "Peut copier",
        "can-edit": "Peut éditer",
        "can-share": "Peut partager",
        "can-view": "Peut voir",
        "delete-tooltip": "Stop sharing",
        "edit-note": "Note éditée",
        "empty-search": "Aucun employé ou groupe ne correspond à votre recherche.",
        "groups": {
          "coaching-pools": {
            "description": "Uniquement les entraîneurs qui appartiennent aux pools d'entraîneurs",
            "name": "Piscines de coaching"
          },
          "engagement-editors": {
            "description": "Seuls les employés qui sont des éditeurs d'engagement",
            "name": "Éditeurs d'engagement"
          },
          "external-coaches": {
            "description": "Uniquement les coachs qui n'appartiennent pas à votre compte",
            "name": "Coachs externes"
          },
          "global-admin": {
            "description": "Seuls les administrateurs mondiaux de votre compte",
            "name": "Administrateurs mondiaux"
          },
          "internal-coaches": {
            "description": "Seuls les coachs appartenant à votre compte",
            "name": "Coachs internes"
          },
          "location-admin": {
            "description": "Seuls les administrateurs appartenant à votre emplacement",
            "name": "Administrateurs d'emplacement"
          },
          "location-coaches": {
            "description": "Seuls les entraîneurs qui appartiennent à votre emplacement",
            "name": "Location coachs"
          },
          "stakeholders": {
            "description": "Toute personne ayant accès à cet engagement.",
            "name": "Toutes les personnes"
          }
        },
        "item-not-shared": "Cet article n'a pas encore été partagé avec quiconque.",
        "private-note": "Note privée",
        "remove-share": "Supprimer le partage",
        "share-note": "Partager la note",
        "shared-on": "Partagé sur {date}",
        "shared-with": "Partagé avec",
        "sharing-confirmation": "Si vous ne souhaitez pas que d'autres personnes apportent des modifications ou affichent les résultats, créez une copie et partagez la copie.",
        "sharing-warning": "Les administrateurs avec lesquels vous partagez ce formulaire pourront apporter des modifications et afficher les résultats globaux."
      },
      "sharing-removed": "Partage supprimé.",
      "sharing-with": "Partager avec",
      "skip": "Sauter",
      "sms-preview": {
        "document": "Vous avez un document de coaching en attente de révision.",
        "form": "Vous avez reçu un formulaire de coaching.",
        "new-scheduled": "Vous avez une nouvelle session de coaching planifiée à [heure] le [format de date courte].",
        "new-session": "Vous avez une nouvelle session de coaching à venir à [heure] le [format de date courte].",
        "rescheduled": "Une session de coaching a été reportée à [heure] le [format de date courte].",
        "schedule": "Planifiez votre prochaine session de coaching.",
        "task": "Vous avez une tâche de coaching due.",
        "task-login": "Vous avez une tâche de coaching due. Connectez-vous pour le marquer comme complet.",
        "thank-attending": "Merci d’assister à la session de coaching le [format de date courte]."
      },
      "something-went-wrong": "Quelque chose a mal tourné",
      "stakeholder": "Partie prenante:",
      "state-label": "Région/Département",
      "status": "Etat",
      "stop_sharing": "Arrêtez le partage",
      "style": {
        "bold": "*audacieux*",
        "italics": "_italique_",
        "strike": "~ grève ~"
      },
      "summary-data": {
        "active-clients": "Clients Actifs",
        "active-coaches": "Coaches Actifs",
        "active-engagements": "Engagements Actifs",
        "active-vendors": "Vendeurs Actifs",
        "allocated-engagements": "Engagements alloués",
        "amount-due": "Montant Dû",
        "amount-outstanding": "Montant Owed",
        "amount-owed": "Montant Owed",
        "amount-paid": "Total Payé",
        "approved-coaches": "Entraîneurs Approuvés",
        "coach-approvals-pending": "Approbations en Attente",
        "completed-engagements": "Engagements Terminées",
        "draft-engagements": "Engagements de projet",
        "hours-cancelled": "Heures Annulées",
        "hours-completed": "Heures Terminées",
        "hours-remaining": "Heures Restantes",
        "hours-scheduled": "Heures Planifiées",
        "invoices-received": "Invoices Reçues",
        "invoices-sent": "Factures envoyées",
        "matching-engagements": "Engagements correspondants",
        "no-engagements-found": "Aucun engagement trouvé.",
        "paid": "Payé",
        "sessions-cancelled": "Sessions Annulées",
        "sessions-completed": "Sessions Terminées",
        "sessions-remaining": "Sessions Restantes",
        "sessions-scheduled": "Sessions Planifiées",
        "total-amount": "Total de Montant",
        "total-clients": "Total des Clients",
        "total-coaches": "Total Coaches",
        "total-engagements": "Total des Engagements",
        "total-invoiced": "Total des Fournisseurs",
        "total-invoices": "Total des Fournisseurs",
        "total-outstanding": "Total Dû",
        "total-paid": "Total payé",
        "total-revenue": "Revenu Total",
        "total-sessions": "Total des Sessions",
        "total-vendors": "Total des Vendeurs"
      },
      "tabs": {
        "coach-profile": "Profile",
        "coachees": "Coachees",
        "coaches": "Entraîneurs",
        "details": "Détails",
        "engagements": "Engagements",
        "invoices": "Factures",
        "people": "Gens",
        "profile": "Profile",
        "reviews": "Avis",
        "summary": "Résumé"
      },
      "timezone": "Fuseau horaire",
      "timezone-offset": "(GMT {offset})",
      "unavailable-for-new-clients": "Indisponible pour les nouveaux clients",
      "upcoming-sessions": "Prochaines Sessions",
      "update": "Modifier",
      "upgrade": "Améliorer",
      "upload-dialog": {
        "data-title": "Data",
        "download-csv-template": "Télécharger le modèle CSV",
        "drop-label": "Déposez votre fichier CSV ici",
        "error-number": "{num} {num, plural, one {erreur} other {erreurs}} de ce type trouvées",
        "error-types": {
          "alternatives.types": "Numéro invalide",
          "any.only": "Des valeurs inattendues",
          "any.required": "Valeurs vides",
          "array.includesRequiredKnowns": "Colonnes manquantes",
          "array.min": "Fichier vide",
          "csv-error": "Erreur CSV",
          "date.format": "Date invalide",
          "number.positive": "Numéro invalide",
          "string.email": "E-mails non valides",
          "string.empty": "Valeurs vides",
          "string.max": "Trop de caractères"
        },
        "errors": {
          "InvalidQuotes": "Le devis de fin du champ cité est mal formé.",
          "MissingQuotes": "Un champ cité n'est pas terminé.",
          "TooFewFields": "La ligne {row} contient trop peu de colonnes.",
          "TooManyFields": "La ligne {row} contient trop de colonnes.",
          "alternatives.types": "\"{value}\" doit être un nombre.",
          "any.only": "Colonne {key} seules les valeurs possibles sont {valids}.",
          "any.required": "La colonne {key} ne doit pas avoir de valeurs vides.",
          "array.includesRequiredKnowns": "Les colonnes {knownMisses} sont obligatoires mais n'ont pas été incluses dans le fichier CSV.",
          "array.min": "Le fichier doit avoir au moins 1 ligne.",
          "date.format": "\"{value}\" doit être dans l'un des formats suivants: {format}.",
          "number.positive": "\"{value}\" doit être un nombre positif.",
          "string.email": "{value} n'est pas une adresse e-mail valide.",
          "string.empty": "La colonne {key} ne doit pas avoir de valeurs vides.",
          "string.max": "La colonne {key} ne doit pas dépasser {limit} caractères."
        },
        "errors-label": "Les erreurs",
        "errors-title": "Erreurs",
        "parsing-file": "Analyse du fichier...",
        "success": "{number} lignes analysées avec succès.",
        "try-again": "Réessayer",
        "upload-failed": "Échec du téléchargement du fichier.",
        "upload-success": "Fichier téléchargé avec succès.",
        "upload-types": {
          "client-company": {
            "description": "Une organisation qui achète des services de coaching.",
            "title": "Télécharger les entreprises clientes"
          },
          "client-poc": {
            "description": "Ajouter un nouveau point de contact",
            "title": "Ajouter PoC"
          },
          "create-engagements": {
            "description": "Téléchargez CSV pour créer de nouveaux engagements",
            "title": "Créer de nouveaux engagements"
          },
          "directly-contracted-coachee": {
            "description": "Un client individuel qui paie pour son propre coaching.",
            "title": "Télécharger des clients individuels"
          },
          "employee": {
            "description": "Un employé de votre organisation",
            "title": "Télécharger des employés"
          },
          "external-participant": {
            "description": "Un participant qui n'appartient pas à votre organisation",
            "title": "Participant externe"
          },
          "external-vendor": {
            "description": "Une organisation qui fournit des services de coaching.",
            "title": "Télécharger les entreprises du fournisseur"
          },
          "independent-coach": {
            "description": "Un coach indépendant, contracté directement.",
            "title": "Télécharger des coachs indépendants"
          },
          "internal-coach": {
            "description": "Un employé de votre entreprise qui fournit des services de coaching.",
            "title": "Coach interne"
          },
          "request-coach": {
            "description": "Demander un coach auprès d'un fournisseur",
            "title": "Demander un coach au vendeur"
          },
          "request-coachee": {
            "description": "Demander un coaché ​​à une organisation cliente",
            "title": "Demander un client coaché"
          },
          "sponsored-coachee": {
            "description": "Un coaché ​​parrainé par une entreprise cliente.",
            "title": "Coaché ​​parrainé"
          },
          "update-engagements": {
            "description": "Téléchargez CSV pour mettre à jour les engagements existants",
            "title": "Mettre à jour les engagements"
          },
          "vendor-provided-coach": {
            "description": "Un coach qui travaille avec un vendeur de coaching ou un groupe de coaches.",
            "title": "Coach contracté par le vendeur"
          }
        },
        "uploading-file": "Téléchargement du fichier..."
      },
      "user-exists": "l'utilisateur existe",
      "vat-id-label": "VAT ID",
      "vendor_type": "Type de fournisseur",
      "version": "Coaching.com 5.0",
      "view": "Vue",
      "website": "Site Internet",
      "yes": "Oui"
    },
    "v2": {
      "common": {
        "address-2": "L' allocution 2",
        "cancel": "Annuler",
        "city": "Ville",
        "clear": "Nette",
        "coach_selection": "Correspondance des entraîneurs",
        "complete": "Achevé",
        "description": "La description",
        "draft_coach_selection": "Repêchage de l'entraîneur",
        "edit": "Éditer",
        "email": "email",
        "end-date": "Date de fin",
        "facebook": "Facebook",
        "filter": "Filtre",
        "first-name": "Prénom",
        "fluency": "Aisance",
        "interview-session": "Séance d'entretien",
        "language": "Langue",
        "languages": "Langages",
        "last-name": "Nom de famille",
        "linkedin": "LinkedIn",
        "location": "Emplacement",
        "modalities": "Modalités",
        "name": "Nom",
        "organization": "Organisation",
        "phone": "Téléphone",
        "postal-code": "Code postal",
        "present": "Présent",
        "quick-view": "Aperçu rapide",
        "rate": "Le tarif",
        "request-available-times": "Demander les horaires disponibles",
        "role": "Rôle",
        "save": "Sauvegarder",
        "schedule-interview": "Planifier une entrevue",
        "search": "Chercher",
        "search-placeholder": "Type pour filtrer les options",
        "select": "Sélectionner",
        "select-coach": "Demande coach",
        "select-country": "Choisissez le pays",
        "selected": "Selected",
        "sort": "Trier",
        "start-date": "Date de début",
        "state": "L' État",
        "summary": "Sommaire",
        "title": "Titre",
        "twitter": "Twitter",
        "upcoming-interviews": "Entrevues à venir",
        "update": "Réactualiser",
        "viewing": "Visualise",
        "website": "Site Internet",
        "year-joined": "Année d'adhésion",
        "years": "Années"
      },
      "employee": {
        "profile": {
          "add-industries": "Ajouter des industries",
          "add-language": "Ajouter une langue",
          "add-levels": "Ajouter des niveaux",
          "add-modalities": "Ajouter des modalités",
          "add-types": "Ajouter des types",
          "affiliations": "Affiliations",
          "affiliations-updated": "Affiliations mises à jour",
          "assessments": "Évaluations",
          "assessments-updated": "Évaluations mises à jour.",
          "authors": "Auteurs",
          "client-number": "Nombre d'engagements terminés",
          "clients": "Clients",
          "coach-type": "Type d'entraîneur",
          "coached-hours": "Nombre total d'heures encadrées",
          "coaching-info": "Informations sur le coaching",
          "coaching-information-updated": "Informations sur le coaching mises à jour",
          "create-affiliation": "Créer une affiliation",
          "create-assessment": "Créer une évaluation",
          "create-credential": "Modifier les informations d'identification",
          "create-education": "Nouveau diplôme",
          "create-publication": "Créer une publication",
          "create-work-experience": "Créer une expérience de travail",
          "cred-id": "N ° d'identification du diplôme",
          "credentials": "Identifiants",
          "credentials-updated": "Identifiants mis à jour.",
          "custom-issuing-org": "Nom de l'organisation",
          "date": "Date",
          "degree": "Diplôme",
          "description": "La description",
          "does-not-expire": "N'expire pas",
          "edit-affiliation": "Modifier l'affiliation",
          "edit-assessment": "Modifier l'évaluation",
          "edit-credential": "Edit credential",
          "edit-education": "Modifier le diplôme",
          "edit-language": "Modifier la langue",
          "edit-publication": "Modifier la publication",
          "edit-work-experience": "Modifier l'expérience de travail",
          "education": "Éducation",
          "education-updated": "Éducation mise à jour",
          "expiration-date": "Date d'expiration",
          "graduation-date": "Date d'obtention du diplôme",
          "hours": "Heures",
          "industries": "Les industries",
          "issuing-date": "Date d'émission",
          "issuing-org": "Organisation émettrice",
          "languages-updated": "Langues mises à jour",
          "leader-levels": "Niveaux de leader",
          "link": "URL du lien",
          "major": "Domaine d'étude",
          "modalities": "Modalités",
          "no-assessments": "Vous n'avez encore ajouté aucune évaluation.",
          "no-credentials": "Vous n'avez pas encore ajouté d'informations d'identification.",
          "no-education": "Vous n'avez pas encore ajouté votre formation.",
          "no-publications": "Vous n'avez pas encore ajouté de publications.",
          "publications": "Publications",
          "publications-updated": "Publications mises à jour.",
          "publisher": "Éditeur",
          "school": "École",
          "select-coaching-types": "Sélectionnez les types de coaching",
          "select-industires": "Sélectionnez les industries",
          "select-levels": "Sélectionnez les niveaux",
          "select-modalities": "Sélectionnez les modalités",
          "select-specialties": "Sélectionnez des spécialités",
          "specialties": "Spécialités",
          "title": "Titre",
          "unsaved-changes-dialog": {
            "leave": "Partir",
            "message": "Vous avez des changements non enregistrés. En sélectionnant <strong>PARTIR</strong>, vous supprimerez toutes les modifications non enregistrées. Voulez vous procéder?",
            "stay": "Rester",
            "title": "Modifications non enregistrées"
          },
          "work-experience": "L'expérience professionnelle",
          "work-experience-updated": "Expérience de travail mise à jour",
          "years": "Années",
          "years-certified": "Années certifiées",
          "years-coaching": "Années de coaching",
          "youtube-input-placeholder": "Entrez le lien vidéo YouTube ici"
        }
      },
      "engagement": {
        "matching-process": {
          "assessments": "Évaluations",
          "close-filters": "Fermer les filtres",
          "close-quick-view": "Fermer la vue rapide",
          "coach-selection": "Sélection des entraîneurs",
          "coach-selection-message": "Bienvenue au coaching! Félicitations pour votre choix de consacrer du temps et de l'énergie à votre propre croissance. je suis passion de vous soutenir pour étendre votre portée et devenir encore plus que vous ne le pensiez possible.",
          "coach-type": "Type d'entraîneur",
          "coaching-types": "Types de coaching",
          "credentials": "Certifications",
          "employee-levels": "Niveaux d'employés",
          "experience": "Expérience",
          "gender": "Le sexe",
          "industries": "les industries",
          "languages": "Langages",
          "leader-levels": "Niveaux de sortants",
          "location": "Emplacement",
          "modalities": "Modalités",
          "no-coaches": "Aucun entraîneur",
          "no-completed-interviews": "Pas d'entretiens terminés",
          "no-upcoming-interviews": "Pas d'interview à venir",
          "specialties": "Spécialités",
          "title": "Processus d'appariement",
          "view-full-profile": "Voir le profil complet"
        }
      }
    },
    "vendors": {
      "add-coach": "Ajouter un coach",
      "add-coach-or-vendor": "Ajouter un coach ou un vendeur",
      "create-workflow": {
        "account-name-label": "Nom de la compagnie",
        "email-label": "Email du contact",
        "first-name-label": "Prénom du contact",
        "last-name-label": "Nom du contact",
        "title": "Créer un fournisseur"
      },
      "description": "Entreprises de coaching et coaches indépendants que vous avez engagés.",
      "loading": "Chargement des fournisseurs...",
      "no-coachees": "Pas de coachees",
      "no-coaches": "Pas de coaches",
      "no-vendors": "Aucun vendeur",
      "search-placeholder": "Recherche de fournisseurs",
      "stats": {
        "active-coaches": "Entraîneurs actifs",
        "approved-coaches": "Entraîneurs approuvés",
        "pending-coach-approval": "En attente de l'approbation de l'entraîneur"
      },
      "table-header": {
        "name": "Nom",
        "status": "Statut"
      },
      "title": "Furnisseurs",
      "type": {
        "all": "Tout",
        "company": "Affaires",
        "independent": "Coach indépendant"
      },
      "vendor-type": "Type de vendeur"
    },
    "video": {
      "audio-input-label": "Microphone",
      "audio-input-placeholder": "Veuillez sélectionner une entrée audio",
      "audio-output-label": "haut-parleur",
      "audio-output-placeholder": "Veuillez sélectionner une sortie audio",
      "audio-status-off": "Audio: OFF",
      "audio-status-on": "Audio: ON",
      "cant-join-message": "Vous ne pouvez pas rejoindre cette session vidéo. Pour rejoindre une session vidéo, l'heure actuelle doit être comprise dans les heures de début / fin de session planifiées et le lieu de la réunion doit être défini sur vidéo intégrée à l'application.",
      "input-level": "Niveau d'entrée",
      "join-interview": "Rejoindre l'entrevue",
      "join-session": "Rejoindre une session",
      "play-test": "Lecture du son de test",
      "prompt-join": "{name}, prêt à rejoindre la session?",
      "prompt-start": "{name}, prêt à démarrer la session?",
      "settings": "Paramètres",
      "start-interview": "Commencer l'entretien",
      "start-session": "Démarrer la session",
      "stop-test": "Arrêter le son de test",
      "tooltips": {
        "audio-disable": "Désactiver le son",
        "audio-enable": "Activer le son",
        "collapse": "Masquer les contrôles",
        "expand": "Afficher les contrôles",
        "leave": "Laisser l'appel",
        "screen-share-disable": "Arrêter de partager l'écran",
        "screen-share-enable": "Partager l'écran",
        "settings-disable": "Masquer les paramètres",
        "settings-enable": "Afficher les paramètres",
        "video-disable": "Désactiver la vidéo",
        "video-enable": "Activer la vidéo"
      },
      "video-input-label": "Vidéo",
      "video-input-placeholder": "Veuillez sélectionner une entrée vidéo",
      "video-status-off": "Vidéo: OFF",
      "video-status-on": "Vidéo: ON",
      "waiting-for-coach": "Bienvenue à votre session, elle commencera à l'arrivée de votre coach.",
      "waiting-for-others": "En attente de la participation d'autres personnes..."
    }
  };
  _exports.default = _default;
});