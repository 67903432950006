define("coachlogix/pods/components/settings/subscription/plans-dialog/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FEATURE_NAMES = ['Active Engagements', 'Account Branding', 'Branded Email Templates', 'Calendar Sync & Scheduling', 'Client Portals', 'Custom Forms/Surveys', 'Goal Planning & Feedback', 'Notes & Resource Management', 'Program Templates', 'Reporting & Analytics'];

  var _default = Ember.Component.extend({
    account: Ember.inject.service(),
    store: Ember.inject.service(),
    clToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',
    siteFeatures: FEATURE_NAMES,
    activeSubscription: Ember.computed.reads('account.activeAccount.subscription'),
    activePlan: Ember.computed.reads('account.activeAccount.subscription.plan'),
    freePlan: Ember.computed.reads('account.activeAccount.freePlan'),
    accountType: Ember.computed.reads('account.activeAccount.accountType'),
    shouldHidePlans: Ember.computed.or('showSubscribeDialog', 'showUpdateDialog', 'showCancelDialog'),
    showYearly: Ember.computed.reads('activePlan.isAnnual'),
    visibleInterval: Ember.computed('showYearly', function () {
      return this.get('showYearly') ? 'Annual' : 'Monthly';
    }),
    visiblePlans: Ember.computed('accountType', 'plans.lastSuccessful.value.@each.interval', 'visibleInterval', function () {
      return this.get('plans.lastSuccessful.value').filter(p => {
        let planAccountType = p.get('accountType');

        if (planAccountType === 'business_plus' || planAccountType === 'business_free') {
          planAccountType = 'business';
        } else if (planAccountType === 'independent_coach_plus' || planAccountType === 'independent_coach_free') {
          planAccountType = 'independent_coach';
        } // only if plan is of the same account type


        if (planAccountType === this.get('accountType')) {
          // only if the plan's interval is of the same selected interval OR free
          if (p.get('interval') === this.get('visibleInterval') || p.get('isFree')) {
            // only show stripe plans except in that case that it is free
            if (p.get('provider') === 'stripe' || p.get('isFree')) {
              return true;
            }
          }
        }

        return false;
      }).sortBy('sortOrder');
    }),
    actions: {
      openUpdateDialog(plan) {
        this.set('selectedPlan', plan);
        this.set('showUpdateDialog', true);
      },

      openSubscribeDialog(plan) {
        this.set('selectedPlan', plan);
        this.set('showSubscribeDialog', true);
      },

      openCancelDialog() {
        this.set('showCancelDialog', true);
      }

    },
    plans: (0, _emberConcurrency.task)(function* () {
      return yield this.get('store').findAll('plan', {
        include: 'plan-features,plan-features.site-feature',
        reload: true
      });
    }).on('init'),
    cancelActiveSubscription: (0, _emberConcurrency.task)(function* () {
      let subscription = yield this.get('account.activeAccount.subscription');
      yield subscription.destroyRecord();
      this.get('account.activeAccount').reload();
      this.get('clToaster').success(this.get('intl').t('settings.subscription.cancel-dialog.subscription-canceled'));

      if (typeof this.onClose === 'function') {
        this.onClose();
      }
    }),
    updateSubscription: (0, _emberConcurrency.task)(function* () {
      let subscription = yield this.get('account.activeAccount.subscription');
      let selectedPlan = this.get('selectedPlan');

      if (selectedPlan.get('isFree')) {
        let account = this.get('account.activeAccount');
        account.set('currentBillingPlan', selectedPlan.get('accountType'));

        if (selectedPlan.isBusiness) {
          account.set('accountType', 'business');
        } else if (selectedPlan.isIndependentCoach) {
          account.set('accountType', 'independent_coach');
        }

        yield account.save();
      } else {
        subscription.set('plan', selectedPlan);
        yield subscription.save();
      }

      this.get('account.activeAccount').reload();
      this.get('clToaster').success(this.get('intl').t('settings.subscription.update-dialog.subscription-updated'));

      if (typeof this.onClose === 'function') {
        this.onClose();
      }
    })
  });

  _exports.default = _default;
});