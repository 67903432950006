define("coachlogix/serializers/application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApplicationSerializer extends _jsonApi.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        createdAt: {
          serialize: false
        },
        updatedAt: {
          serialize: false
        },
        meta: {
          serialize: false
        }
      });
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'findRecord' && payload.meta) {
        payload.data.attributes.meta = payload.meta;
      }

      if (requestType === 'findAll' && payload.meta && payload.data?.length) {
        payload.data[0].attributes.meta = payload.meta;
      }

      return super.normalizeResponse(...arguments);
    }

  }

  _exports.default = ApplicationSerializer;
});