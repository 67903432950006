define("coachlogix/pods/components/v2/form-controls/gender-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Md+HPJgX",
    "block": "{\"symbols\":[\"gender\",\"@disabled\",\"@selected\",\"@onChange\"],\"statements\":[[8,\"cl-select\",[],[[\"@solid\",\"@disabled\",\"@label\",\"@placeholder\",\"@options\",\"@selected\",\"@onChange\"],[true,[32,2],[30,[36,0],[\"v2.common.select-gender\"],null],[30,[36,0],[\"v2.common.select-gender\"],null],[30,[36,1],[\"male\",\"female\",\"other\"],null],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,0],[[30,[36,2],[\"ui.genders.\",[32,1]],null]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"concat\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/form-controls/gender-select/template.hbs"
    }
  });

  _exports.default = _default;
});