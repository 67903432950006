define("coachlogix/pods/components/v2/contracts/contract-dialog/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment", "tracked-toolbox", "coachlogix/models/contract-template"], function (_exports, _component, _emberConcurrencyDecorators, _moment, _trackedToolbox, _contractTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContractDialogComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = (0, _trackedToolbox.localCopy)('args.contract.name'), _dec8 = (0, _trackedToolbox.localCopy)('args.contract.description'), _dec9 = (0, _trackedToolbox.localCopy)('args.contract.billingApproach'), _dec10 = (0, _trackedToolbox.localCopy)('args.contract.contractAmount'), _dec11 = Ember._tracked, _dec12 = (0, _trackedToolbox.localCopy)('args.contract.paymentSchedule.content'), _dec13 = (0, _trackedToolbox.localCopy)('args.contract.paymentIntervalType'), _dec14 = (0, _trackedToolbox.localCopy)('args.contract.invoiceAutomatically', false), _dec15 = (0, _trackedToolbox.localCopy)('args.contract.payViaStripe', false), _dec16 = (0, _trackedToolbox.localCopy)('args.contract.dayOfWeek', function () {
    return this.daysOfWeek[0];
  }), _dec17 = (0, _trackedToolbox.localCopy)('args.contract.dayOfMonth', function () {
    return this.daysOfMonth[0];
  }), _dec18 = (0, _trackedToolbox.localCopy)('args.contract.paymentIntervalValue', 1), _dec19 = (0, _emberConcurrencyDecorators.lastValue)('getPaymentSchedules'), _dec20 = Ember.computed.sort('paymentSchedules', 'paymentSchedulesSorting'), _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._tracked, (_class = (_temp = class ContractDialogComponent extends _component.default {
    // Step 1
    // Step 2
    // step 3
    // only initialize to true when a contract is passsed in and there is an invoiceScheduleType
    *getPaymentSchedules() {
      let paymentSchedules = yield this.store.findAll('payment-schedule', {
        reload: true
      });
      let paymentSchedulesNames = this.paymentSchedulesNames;
      return paymentSchedules.filter(p => {
        let isNotNew = !p.isNew;
        let isAllowed = paymentSchedulesNames.includes(p.name);
        return isNotNew && isAllowed;
      });
    }

    get paymentIntervalTypes() {
      if (this.billingApproach === 'flat') {
        return ['weeks', 'months', 'sessions_completed'];
      } else if (this.billingApproach === 'hourly') {
        return ['weeks', 'months', 'hours_billed'];
      } else if (this.billingApproach === 'retainer') {
        return ['weeks', 'months'];
      }

      return [];
    }

    get daysOfWeek() {
      return _moment.default.weekdays(false).map((name, value) => {
        return {
          name,
          value
        };
      });
    }

    get daysOfMonth() {
      let numDays = 28;
      let days = [];

      for (let i = 1; i <= numDays; i++) {
        days.push(i);
      }

      days.push(-1);
      return days;
    }

    get invoiceScheduleType() {
      // we can infer the initial schedule type based on the billing approach
      if (this.billingApproach) {
        if (this.billingApproach === 'fixed_contract_amount') {
          return 'payment_schedule';
        } else {
          return 'payment_interval';
        }
      }

      return null;
    } // Step 4


    get amountSummary() {
      if (this.billingApproach) {
        let text = this.intl.t(`contracts.billing-approaches.${this.billingApproach}`);

        if (this.amount) {
          let formattedAmount = this.account.formatCurrency(this.amount);
          return `${formattedAmount} ${text}`;
        } else {
          return text;
        }
      }

      return '';
    }

    get paymentScheduleSummary() {
      if (this.addPaymentSchedule && this.invoiceScheduleType === 'payment_interval' && this.paymentIntervalType && this.paymentIntervalValue) {
        return this.intl.t(`contracts.duration-type.${this.paymentIntervalType}`, {
          value: this.paymentIntervalValue
        });
      } else if (this.addPaymentSchedule && this.invoiceScheduleType === 'payment_schedule' && this.paymentSchedule) {
        return this.paymentSchedule.percentagesDescription;
      } else if (this.addPaymentSchedule === false) {
        return this.intl.t('ui.no');
      }

      return '';
    }

    get modelName() {
      return this.args.contract?.modelName || this.args.modelName || 'contract';
    }

    *save() {
      try {
        let contractModel;

        if (this.args.contract) {
          contractModel = this.args.contract;
        } else {
          contractModel = this.store.createRecord(this.modelName);
        }

        contractModel.setProperties({
          name: this.name,
          description: this.description,
          billingApproach: this.billingApproach,
          contractAmount: this.amount
        });

        if (this.addPaymentSchedule) {
          contractModel.setProperties({
            invoiceScheduleType: this.invoiceScheduleType,
            invoiceAutomatically: this.invoiceAutomatically,
            payViaStripe: this.payViaStripe
          });

          if (this.invoiceScheduleType === 'payment_interval') {
            contractModel.setProperties({
              paymentIntervalType: this.paymentIntervalType,
              paymentIntervalValue: this.paymentIntervalValue
            });

            if (this.paymentIntervalType === 'months') {
              contractModel.set('dayOfMonth', this.dayOfMonth);
            } else if (this.paymentIntervalType === 'weeks') {
              contractModel.set('dayOfWeek', this.dayOfWeek.value);
            }
          } else if (this.invoiceScheduleType === 'payment_schedule') {
            contractModel.set('paymentSchedule', this.paymentSchedule);
          }
        }

        if (this.args.client) {
          contractModel.set('client', this.args.client);
        }

        if (contractModel.get('client.isIndependentCoachee')) {
          contractModel.setProperties({
            status: 'active'
          });
        }

        let user = this.account.activeUser;
        let account = this.account.activeAccount;
        contractModel.setProperties({
          user,
          account
        });
        yield contractModel.save();
        this.clToaster.success(this.intl.t(`contracts.dialog.success.${this.modelName}`));
        this.args.onCreated?.(contractModel);
        this.args.onClose?.(true);
      } catch (e) {
        this.clToaster.error(e);
      }
    } // Common


    get stepProgress() {
      return this.step / this.totalSteps * 100;
    }

    get isOnFirstStep() {
      return this.step === 1;
    }

    get isOnLastStep() {
      return this.step === this.totalSteps;
    }

    get canAdvance() {
      if (this.step === 1) {
        return Ember.isPresent(this.name);
      } else if (this.step === 2) {
        return this.billingApproach && this.amount && parseInt(this.amount) > 0;
      } else if (this.step === 3) {
        // no payment schedule is valid
        if (!this.addPaymentSchedule) {
          return true;
        } // if schedule type is "payment_schedule" then it needs to have a paymentSchedule


        if (this.invoiceScheduleType === 'payment_schedule' && Ember.isPresent(this.paymentSchedule)) {
          return true;
        } // if payment_interval, then having a paymentIntervalType and paymentIntervalValue is required


        if (this.invoiceScheduleType === 'payment_interval') {
          if (Ember.isPresent(this.paymentIntervalType) && Ember.isPresent(this.paymentIntervalValue)) {
            // also, if paymentIntervalType is months, then a dayOfMonth is mandatory
            if (this.paymentIntervalType === 'months' && Ember.isPresent(this.dayOfMonth)) {
              return true; // if paymentIntervalType is weeks, then a dayOfWeek is mandatory
            } else if (this.paymentIntervalType === 'weeks' && Ember.isPresent(this.dayOfWeek.value)) {
              return true;
            } else {
              // the other cases are covered by the parent test
              return true;
            }
          }
        }
      }

      return false;
    }

    get canEdit() {
      if (this.args.viewOnly) {
        return false;
      }

      if (this.args.contract) {
        return this.can.abilityFor('contract', this.args.contract).canEdit;
      } else {
        return true;
      }
    }

    get isRunning() {
      return this.save.isRunning || this.send.isRunning || this.delete.isRunning || this.approve.isRunning || this.decline.isRunning;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "clToaster", _descriptor4, this);

      _initializerDefineProperty(this, "can", _descriptor5, this);

      _initializerDefineProperty(this, "step", _descriptor6, this);

      _defineProperty(this, "totalSteps", 4);

      _initializerDefineProperty(this, "name", _descriptor7, this);

      _initializerDefineProperty(this, "description", _descriptor8, this);

      _defineProperty(this, "billingApproaches", _contractTemplate.BILLING_APPROACHES);

      _defineProperty(this, "paymentSchedulesNames", _contractTemplate.ALLOW_PAYMENT_SCHEDULES);

      _initializerDefineProperty(this, "billingApproach", _descriptor9, this);

      _initializerDefineProperty(this, "amount", _descriptor10, this);

      _initializerDefineProperty(this, "addPaymentSchedule", _descriptor11, this);

      _initializerDefineProperty(this, "paymentSchedule", _descriptor12, this);

      _initializerDefineProperty(this, "paymentIntervalType", _descriptor13, this);

      _initializerDefineProperty(this, "invoiceAutomatically", _descriptor14, this);

      _initializerDefineProperty(this, "payViaStripe", _descriptor15, this);

      _initializerDefineProperty(this, "dayOfWeek", _descriptor16, this);

      _initializerDefineProperty(this, "dayOfMonth", _descriptor17, this);

      _initializerDefineProperty(this, "paymentIntervalValue", _descriptor18, this);

      _initializerDefineProperty(this, "paymentSchedules", _descriptor19, this);

      _defineProperty(this, "paymentSchedulesSorting", ['upfront:desc', 'firstQuarter:desc', 'firstThird:desc', 'middle:desc', 'secondThird:desc', 'thirdQuarter:desc', 'end:desc']);

      _initializerDefineProperty(this, "sortedPaymentSchedules", _descriptor20, this);

      _initializerDefineProperty(this, "showDeleteConfirmation", _descriptor21, this);

      this.getPaymentSchedules.perform();
    }

    nextStep() {
      this.step++;
    }

    previousStep() {
      this.step--;
    }

    goToStep(n) {
      this.step = n;
    }

    *send() {
      try {
        let contract = this.args.contract;
        contract.set('status', 'sent');
        yield contract.save();
        this.clToaster.success(this.intl.t('contracts.editor.sent-success'));
        this.args.onClose?.(true);
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *deleteWithConfirmation() {
      if (this.modelName === 'contract' && this.args.contract) {
        let contract = this.args.contract;

        if (Ember.isEmpty(contract.hasMany('engagements').ids())) {
          yield this.delete.perform();
        } else {
          this.showDeleteConfirmation = true;
        }
      } else {
        yield this.delete.perform();
      }

      this.args.onClose?.();
    }

    *delete() {
      try {
        let contract = this.args.contract;
        yield contract.destroyRecord();
        this.clToaster.success(this.intl.t('contracts.editor.delete-success'));
        this.args.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *approve() {
      try {
        let contract = this.args.contract;
        contract.set('status', 'active');
        yield contract.save();
        this.clToaster.success(this.intl.t('contracts.editor.approve-success'));
        this.args.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

    *decline() {
      try {
        let contract = this.args.contract;
        contract.set('status', 'declined');
        yield contract.save();
        this.clToaster.success(this.intl.t('contracts.editor.decline-success'));
        this.args.onClose?.();
      } catch (e) {
        this.clToaster.error(e);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "can", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "step", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.contract ? 4 : 1;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "billingApproach", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "amount", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "addPaymentSchedule", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.contract && Ember.isPresent(this.args.contract.invoiceScheduleType);
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "paymentSchedule", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "paymentIntervalType", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "invoiceAutomatically", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "payViaStripe", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "dayOfWeek", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "dayOfMonth", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "paymentIntervalValue", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "paymentSchedules", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "sortedPaymentSchedules", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getPaymentSchedules", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getPaymentSchedules"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextStep", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "nextStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previousStep", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "previousStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToStep", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "goToStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "send", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "send"), _class.prototype), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "showDeleteConfirmation", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteWithConfirmation", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteWithConfirmation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approve", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "approve"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decline", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "decline"), _class.prototype)), _class));
  _exports.default = ContractDialogComponent;
});