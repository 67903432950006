define("coachlogix/pods/settings/marketplace/checklist/profile-picture/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xc3pjs9B",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[10,\"div\"],[14,0,\"cl-h-full cl-p-3 cl-flex cl-flex-col cl-space-y-3 cl-items-center cl-justify-center\"],[12],[2,\"\\n\\n  \"],[8,\"v2/avatar-for\",[],[[\"@model\",\"@size\"],[[32,1],86]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-flex-col cl-space-y-0.5 cl-text-center\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"cl-title-5\"],[12],[1,[30,[36,4],[\"settings.marketplace.profile-picture.title\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"cl-button\",[[24,0,\"cl-mt-3\"]],[[\"@primary\",\"@onClick\",\"@raised\"],[true,[30,[36,2],[[30,[36,1],[[32,0,[\"showAvatarDialog\"]]],null],true],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-icon\",[[24,0,\"cl-mr-1\"]],[[\"@icon\"],[\"camera-alt\"]],null],[2,\"\\n    \"],[1,[30,[36,4],[\"personal-settings.general.update-avatar\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"showAvatarDialog\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui/file-picker\",[],[[\"@onUploadDone\",\"@onClose\",\"@accept\"],[[30,[36,0],[[32,0,[\"selectAvatar\"]]],null],[30,[36,2],[[30,[36,1],[[32,0,[\"showAvatarDialog\"]]],null],false],null],[30,[36,3],[\"image/jpg\",\"image/jpeg\",\"image/gif\",\"image/tiff\",\"image/png\",\"image/svg+xml\",\"image/bmp\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"mut\",\"fn\",\"array\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/settings/marketplace/checklist/profile-picture/template.hbs"
    }
  });

  _exports.default = _default;
});