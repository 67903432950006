define("coachlogix/pods/engagement/program/controller", ["exports", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EngagementProgramController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.reads('model.engagementTask.value'), _dec5 = Ember.computed.filterBy('model.programItemsTask.value', 'isValidItem', true), _dec6 = Ember.computed.filterBy('items', 'isPublishedOrScheduled'), _dec7 = Ember.computed.sort('sentOrScheduledItems', 'sentSort'), _dec8 = Ember.computed.filterBy('items', 'canBeMovedOnFrom', true), _dec9 = Ember.computed.sort('completedItems', 'completedSort'), _dec10 = Ember.computed('items.@each.{canBeMovedOnFrom,isPublished,isScheduled}'), _dec11 = Ember.computed.sort('pendingItems', 'pendingItemsSort'), _dec12 = Ember.computed.or('pendingItemsSorted.length', 'completedItemsSorted.length', 'sentItemsSorted.length'), _dec13 = Ember.computed.not('isNotEmpty'), _dec14 = Ember._action, (_class = (_temp = class EngagementProgramController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "clToaster", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _defineProperty(this, "showCompleted", true);

      _initializerDefineProperty(this, "engagement", _descriptor4, this);

      _initializerDefineProperty(this, "items", _descriptor5, this);

      _initializerDefineProperty(this, "sentOrScheduledItems", _descriptor6, this);

      _defineProperty(this, "sentSort", ['publishedOrScheduledDate:asc']);

      _initializerDefineProperty(this, "sentItemsSorted", _descriptor7, this);

      _initializerDefineProperty(this, "completedItems", _descriptor8, this);

      _defineProperty(this, "completedSort", ['completedDate:asc']);

      _initializerDefineProperty(this, "completedItemsSorted", _descriptor9, this);

      _defineProperty(this, "pendingItemsSort", ['position:asc']);

      _initializerDefineProperty(this, "pendingItemsSorted", _descriptor10, this);

      _initializerDefineProperty(this, "isNotEmpty", _descriptor11, this);

      _initializerDefineProperty(this, "isEmpty", _descriptor12, this);
    }

    // Pending items. These are items that aren't completed, published or scheduled. They appear last
    // in the list and are sorted by position
    get pendingItems() {
      return this.items.filter(p => {
        return !p.get('item.canBeMovedOnFrom') && !p.get('item.isPublished') && !p.get('item.isScheduled');
      });
    }

    transitionToItem(item) {
      this.router.transitionTo('engagement.program.program-item', item.id);
    }

    *reorderItems(reorderedItems) {
      try {
        // an array of all of the item positions values (actual)
        let pos = this.items.mapBy('position'); // we want to know how many items we're reordering, and then get the last position value before that

        let reorderOffset = this.items.length - reorderedItems.length - 1;
        reorderedItems.forEach((item, index) => {
          // we set the position to increment from the last item that isn't being reordered
          // unless it is a draft or new engagement, then we just go by the index
          if (pos[reorderOffset]) {
            item.set('position', pos[reorderOffset] + (index + 1));
          } else {
            item.set('position', index + 1);
          }
        });
        let updatedItems = reorderedItems.filter(i => {
          // only update item if it isn't new
          // and if position was changed
          return !i.get('isNew') && 'position' in i.changedAttributes();
        });

        if (updatedItems.length) {
          yield (0, _emberConcurrency.all)(updatedItems.invoke('save'));
          this.clToaster.success(this.intl.t('engagement.program.program-items-reordered'));
        }
      } catch (e) {
        this.clToaster.error(this.intl.t('engagement.program.program-items-save-failed'));
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clToaster", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "engagement", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sentOrScheduledItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sentItemsSorted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "completedItems", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "completedItemsSorted", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pendingItems", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "pendingItems"), _class.prototype), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pendingItemsSorted", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isNotEmpty", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToItem", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reorderItems", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "reorderItems"), _class.prototype)), _class));
  _exports.default = EngagementProgramController;
});