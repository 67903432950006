define("coachlogix/pods/components/v2/goal/feedback-cards/component", ["exports", "@ember-decorators/component", "ember-group-by"], function (_exports, _component, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ClFeedbackCards = (_dec = (0, _component.tagName)(''), _dec2 = (0, _emberGroupBy.default)('reviews', 'user.id'), _dec3 = Ember.computed('reviews.@each.{rating,user,createdAt}', 'reviewsPerUser.[]'), _dec(_class = (_class2 = (_temp = class ClFeedbackCards extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "reviewsPerUser", _descriptor, this);
    }

    get ratings() {
      return this.reviewsPerUser.map(({
        items
      }) => {
        let initial, final, change, realChange, user, increased, decreased, reviews;
        reviews = items.sortBy('createdAt').reverse();

        if (items.length > 0) {
          initial = reviews.objectAt(reviews.length - 1).rating;
          user = items.objectAt(0).get('user');
        }

        if (items.length > 1) {
          final = reviews.objectAt(0).rating;
        }

        if (initial && final) {
          realChange = (final - initial) * 10;
          change = Math.round(Math.abs(realChange));
          increased = realChange > 0;
          decreased = realChange < 0;
        }

        return {
          user,
          initial,
          final,
          change,
          realChange,
          increased,
          decreased,
          reviews,
          reviewIndex: 1
        };
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "reviewsPerUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "ratings", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "ratings"), _class2.prototype)), _class2)) || _class);
  var _default = ClFeedbackCards;
  _exports.default = _default;
});