define("coachlogix/abilities/resource", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourceAbility = (_dec = Ember.inject.service, _dec2 = Ember.computed.reads('model.isFile'), _dec3 = Ember.computed('account.activeUser.id', 'model.{canCopy,owner.id}'), _dec4 = Ember.computed('account.activeUser.id', 'model.{canShare,owner.id}'), _dec5 = Ember.computed('account.activeUser.id', 'model.{canEdit,owner.id}'), _dec6 = Ember.computed('account.activeUser.id', 'model.owner.id'), _dec7 = Ember.computed.or('canDownload', 'canCopy', 'canShare', 'canEdit', 'canDelete'), (_class = (_temp = class ResourceAbility extends _emberCan.Ability {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "account", _descriptor, this);

      _initializerDefineProperty(this, "canDownload", _descriptor2, this);

      _initializerDefineProperty(this, "canShowMenu", _descriptor3, this);
    }

    get canCopy() {
      return this.account.activeUser.id === this.model.belongsTo('owner').id() || this.model.canCopy;
    }

    get canShare() {
      return this.account.activeUser.id === this.model.belongsTo('owner').id() || this.model.canShare;
    }

    get canEdit() {
      return this.account.activeUser.id === this.model.belongsTo('owner').id() || this.model.canEdit;
    }

    get canDelete() {
      return this.account.activeUser.id === this.model.belongsTo('owner').id();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "account", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "canDownload", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canCopy", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "canCopy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canShare", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "canShare"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canEdit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "canEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canDelete", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "canDelete"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canShowMenu", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ResourceAbility;
});