define("coachlogix/pods/components/cl-chip-filters/string/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2m0p2DsV",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"cl-flex cl-flex-col cl-space-y-2 cl-p-3\"],[17,1],[12],[2,\"\\n  \"],[8,\"cl-input\",[[24,\"placeholder\",\"Enter your text query\"]],[[\"@value\",\"@onChange\"],[[32,0,[\"value\"]],[30,[36,2],[[30,[36,1],[[30,[36,0],[[32,0,[\"value\"]]],null]],null],[32,0,[\"handleApply\"]]],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"queue\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/cl-chip-filters/string/template.hbs"
    }
  });

  _exports.default = _default;
});