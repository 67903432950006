define("coachlogix/pods/vendor/coach-profile/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CoachProfileRoute = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class CoachProfileRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    model() {
      return {
        coachTask: this.loadCoach.perform()
      };
    }

    *loadCoach() {
      let vendor = yield this.modelFor('vendor').vendorTask;
      let coachId = vendor.belongsTo('lead').id();
      let include = ['profile', 'profile.coaching-categorizations', 'profile.education-degrees', 'profile.credentials.issuing-org', 'profile.assessments.certified-assessment', 'profile.publications', 'profile.user-affiliations', 'profile.work-experiences', 'profile.languages.language', 'profile.languages.fluency', 'account'].join(',');
      let user = yield this.store.findRecord('user', coachId, {
        include,
        reload: true
      });
      let profile = yield user.get('profile');

      if (!profile && user) {
        profile = this.store.createRecord('profile', {
          user
        });
        user.set('profile', profile);
      }

      return user;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadCoach", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "loadCoach"), _class.prototype)), _class));
  _exports.default = CoachProfileRoute;
});