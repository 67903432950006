define("coachlogix/pods/coaches/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BdwSg0Fx",
    "block": "{\"symbols\":[\"tabs\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,1],[\"page-title\"],null]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"cl-title-1\"],[12],[1,[30,[36,0],[\"coaches.coaches\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"cl-container cl-py-4 cl-px-4 cl-mx-auto cl-flex cl-flex-col\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-items-center cl-py-3\"],[14,1,\"coaches-search-bar\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cl-flex cl-items-center cl-space-x-1\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-inline-flex\"],[12],[2,\"\\n      \"],[8,\"cl-tabs\",[[24,0,\"cl-whitespace-nowrap\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"tab\"]],[],[[\"@href\",\"@isActive\"],[[30,[36,4],[\"coaches.index\"],null],[30,[36,5],[\"coaches.index\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"coaches.coaches-list\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,1,[\"tab\"]],[],[[\"@href\",\"@isActive\"],[[30,[36,4],[\"coaches.pools\"],null],[30,[36,5],[\"coaches.pools\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"coaches.coach-pools\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"cl-flex cl-items-center cl-space-x-1\"],[14,1,\"coaches-tool-bar\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"get-element-by-id\",\"-in-el-null\",\"in-element\",\"href-to\",\"is-active\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/coaches/template.hbs"
    }
  });

  _exports.default = _default;
});