define("coachlogix/pods/components/v2/messaging/channel-pane/group-avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WNFi4CMa",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,0],[[32,0,[\"getUser\",\"isRunning\"]]],null],[32,0,[\"user\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"cl-flex cl-flex-col cl-items-center cl-justify-start cl-flex-none\"],[17,1],[12],[2,\"\\n    \"],[8,\"cl-user-avatar\",[[24,0,\"cl-mt-0.5\"]],[[\"@size\",\"@firstName\",\"@lastName\",\"@email\",\"@imageUrl\"],[32,[32,0,[\"user\",\"attributes\",\"first_name\"]],[32,0,[\"user\",\"attributes\",\"last_name\"]],[32,0,[\"user\",\"attributes\",\"email\"]],[32,0,[\"user\",\"attributes\",\"avatar\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/messaging/channel-pane/group-avatar/template.hbs"
    }
  });

  _exports.default = _default;
});