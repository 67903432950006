define("coachlogix/pods/messaging/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K0swy6dO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cl-flex cl-flex-col cl-items-center cl-justify-center cl-flex-1\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"messaging\"],[[\"class\"],[\"cl-mb-1\"]]]],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"cl-m-0 cl-mb-1 cl-title-6 cl-text-light-grey-blue\"],[12],[1,[30,[36,1],[\"v2.messaging.no-channels\"],null]],[13],[2,\"\\n\\n  \"],[8,\"cl-button\",[],[[\"@primary\",\"@raised\",\"@onClick\"],[true,true,[30,[36,3],[[30,[36,2],[[32,0,[\"messaging\",\"showUsersDialog\"]]],null],true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"cl-icon\",[],[[\"@icon\"],[\"add\"]],null],[2,\"\\n    \"],[1,[30,[36,1],[\"v2.messaging.new-message\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"t\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/messaging/index/template.hbs"
    }
  });

  _exports.default = _default;
});