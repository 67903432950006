define("coachlogix/pods/engagement/program/index/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ProgramIndexRoute = (_class = class ProgramIndexRoute extends Ember.Route {
    redirect(model) {
      this.redirectToProgramItem.perform(model.programItemsTask);
    }

    *redirectToProgramItem(task) {
      let programItems = yield task;

      if (programItems.get('length') > 0) {
        programItems = programItems.toArray().sort((a, b) => {
          let aCompleted = a.get('item.canBeMovedOnFrom');
          let bCompleted = b.get('item.canBeMovedOnFrom');

          if (!aCompleted && !bCompleted) {
            // neither is completed, sort by position
            return a.get('position') - b.get('position');
          } else if (aCompleted && !bCompleted) {
            // a is completed/sent and b isn't, a goes first
            return 1;
          } else if (!aCompleted && bCompleted) {
            // a isn't completed/sent and b is, b goes first
            return -1;
          } else if (aCompleted && bCompleted) {
            // both are completed, sort by publishedOrScheduledDate
            return a.get('publishedOrScheduledDate') - b.get('publishedOrScheduledDate');
          }
        });
        let firstItem = programItems.find(item => {
          // right now program items with the itemable_type 'coaching-request' actually return null for modelName - so we just want the first item that has a modelName
          return item.get('item.modelName');
        });

        if (firstItem) {
          this.replaceWith('engagement.program.program-item', firstItem.get('id'));
        }
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "redirectToProgramItem", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToProgramItem"), _class.prototype)), _class);
  _exports.default = ProgramIndexRoute;
});