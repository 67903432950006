define("coachlogix/pods/components/v2/engagement/create-dialog/select-coachee-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D8D2r5T3",
    "block": "{\"symbols\":[\"@coacheesForClient\",\"@coacheesForVendor\",\"@onSelect\",\"@onClose\",\"@onToggle\"],\"statements\":[[8,\"cl-employee-selection-dialog\",[[24,0,\"cl-w-32\"]],[[\"@required\",\"@title\",\"@noOptionsText\",\"@coacheesForClient\",\"@coacheesForVendor\",\"@coachable\",\"@onChange\",\"@onClose\",\"@closeOnSelect\",\"@auxButtonClick\",\"@auxButtonLabel\",\"@warnMessage\"],[true,[30,[36,0],[\"engagements.create-dialog.select-coachee\"],null],[30,[36,0],[\"engagements.create-dialog.no-coachees\"],null],[32,1],[32,2],[30,[36,2],[[30,[36,1],[[32,1],[32,2]],null]],null],[32,3],[32,4],false,[32,5],[30,[36,0],[\"engagements.create-dialog.create-coachee\"],null],[30,[36,3],[[32,0,[\"account\",\"activeAccount\",\"isIndependentCoach\"]],[30,[36,0],[\"engagement.matching-process.inactive-coachees-warning\"],null]],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"or\",\"not\",\"unless\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/components/v2/engagement/create-dialog/select-coachee-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});