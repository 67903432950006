define("coachlogix/pods/engagement/program/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EngagementProgramRoute = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrencyDecorators.task)({
    cancelOn: 'deactivate'
  }), (_class = (_temp = class EngagementProgramRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    model() {
      let engagementTask = this.modelFor('engagement').engagementTask;
      return {
        programItemsTask: this.loadProgramItems.perform(engagementTask),
        engagementTask
      };
    }

    *loadProgramItems(engagementTask) {
      let engagement = yield engagementTask;
      this.maybeRedirect(engagement);
      engagement = yield this.store.findRecord('engagement', engagement.id, {
        reload: true,
        include: 'program-items.itemable'
      });
      return yield engagement.get('programItems');
    }

    maybeRedirect(engagement) {
      if (!engagement.belongsTo('coach').id()) {
        if (engagement.isMatching || engagement.isDraftMatching) {
          this.transitionTo('engagement.matching-process-coaches', engagement.id);
        } else {
          this.transitionTo('engagement', engagement.id);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadProgramItems", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "loadProgramItems"), _class.prototype)), _class));
  _exports.default = EngagementProgramRoute;
});