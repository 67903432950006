define("coachlogix/pods/settings/basic-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3VHdlKRV",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,1],[\"page-title\"],null]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cl-mt-1\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"cl-title-1\"],[12],[1,[30,[36,0],[\"settings.basic-info.title\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cl-body-2\"],[12],[1,[30,[36,0],[\"settings.basic-info.adjust-text\"],null]],[2,\" \"],[10,\"strong\"],[12],[1,[32,0,[\"account\",\"activeAccount\",\"name\"]]],[13],[2,\".\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"cl-container cl-p-4 cl-flex cl-space-x-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-space-y-4\"],[12],[2,\"\\n    \"],[8,\"settings/basic-info/company-info-card\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"cl-flex-1 cl-space-y-4\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[\"change account info in account\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"settings/basic-info/account-config-card\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"settings/basic-info/language-card\",[],[[],[]],null],[2,\"\\n\\n    \"],[8,\"settings/basic-info/coaching-capacity-card\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"get-element-by-id\",\"-in-el-null\",\"in-element\",\"can\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/settings/basic-info/template.hbs"
    }
  });

  _exports.default = _default;
});