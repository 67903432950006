define("coachlogix/validations/assessment", ["exports", "ember-changeset-validations/validators", "coachlogix/validators/presence-if"], function (_exports, _validators, _presenceIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    certifiedAssessment: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }),
    title: (0, _presenceIf.default)({
      presence: true,
      ignoreBlank: true,
      message: "Assessment name can't be blank"
    }, a => a.certifiedAssessment?.isCustom),
    yearsCertified: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }), (0, _validators.validateNumber)({
      allowBlank: true,
      integer: true,
      positive: true
    })]
  };
  _exports.default = _default;
});