define("coachlogix/pods/components/v2/invite-dialog/common/user-details/component", ["exports", "ember-can/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: Ember.inject.service(),
    tagName: '',
    store: Ember.inject.service(),
    employeeLevels: Ember.computed(function () {
      return this.store.findAll('employee-level');
    }),
    companyLocations: Ember.computed('account.activeAccount.companyLocations.[]', function () {
      return this.account.activeAccount.get('companyLocations');
    }),
    departments: Ember.computed(function () {
      // filter only top level departments
      return this.store.findAll('department', {
        reload: true
      }).then(departments => {
        return departments.filter(d => d.belongsTo('parent').id() === null);
      });
    }),
    subDepartments: Ember.computed('employee.department.subDepartments.[]', function () {
      return this.employee.get('department.subDepartments');
    }),
    userType: 'user',
    showSendInvitation: true,
    accountAbility: (0, _computed.ability)('account'),
    canAddAdmin: Ember.computed.reads('accountAbility.canCreateEmployee'),
    canAddLocationDepartment: Ember.computed.reads('accountAbility.canViewOrgStructure'),
    isEmployeeValid: Ember.computed('employee.{firstName,lastName,user}', function () {
      let employee = this.get('employee');
      return Ember.get(employee, 'user') || Ember.get(employee, 'firstName') && Ember.get(employee, 'lastName');
    }),
    showEmployeeDetails: Ember.computed(function () {
      let account = this.get('account');
      let activeAccount = account.get('activeAccount');
      let accountType = activeAccount.get('accountType');
      return accountType === 'business' || accountType === 'enterprise';
    }),
    showIsCoach: Ember.computed(function () {
      let account = this.get('account');
      let activeAccount = account.get('activeAccount');
      let accountType = activeAccount.get('accountType');
      return accountType === 'business' || accountType === 'enterprise';
    }),
    showLocation: Ember.computed(function () {
      let account = this.get('account');
      let activeAccount = account.get('activeAccount');
      let accountType = activeAccount.get('accountType');
      return accountType === 'enterprise';
    }),
    showMoreDetails: Ember.computed(function () {
      let account = this.get('account');
      let activeAccount = account.get('activeAccount');
      let accountType = activeAccount.get('accountType');
      return accountType === 'member' || accountType === 'independent_coach';
    }),
    clientCoaches: Ember.computed.reads('client.clientCoaches'),
    clientCoachesEmails: Ember.computed.mapBy('clientCoaches', 'employee.email'),
    canSendInvitation: Ember.computed('showSendInvitation', 'invalidPoc', function () {
      return this.showSendInvitation && !this.invalidPoc;
    })
  });

  _exports.default = _default;
});