define("coachlogix/services/stripe", ["exports", "ember-concurrency-decorators", "coachlogix/config/environment"], function (_exports, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let StripeService = (_class = class StripeService extends Ember.Service {
    *initStripe() {
      if (this.stripe) {
        return this.stripe;
      }

      (true && !(Ember.isPresent(_environment.default.stripe) && Ember.isPresent(_environment.default.stripe.publishableKey)) && Ember.assert('A "key" property must be specified in a config["stripe"] object on environemnt', Ember.isPresent(_environment.default.stripe) && Ember.isPresent(_environment.default.stripe.publishableKey)));
      let {
        loadStripe
      } = yield emberAutoImportDynamic("@stripe/stripe-js");
      this.stripe = yield loadStripe(_environment.default.stripe.publishableKey);
    }

    *openCheckout(session_id) {
      yield this.initStripe.perform();
      yield this.stripe.redirectToCheckout({
        sessionId: session_id
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "initStripe", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "initStripe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openCheckout", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "openCheckout"), _class.prototype)), _class);
  _exports.default = StripeService;
});