define("coachlogix/models/twilioable", ["exports", "@ember-data/model", "ember-data-copyable"], function (_exports, _model, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_emberDataCopyable.default, {
    accountService: Ember.inject.service('account'),
    twilioIdentity: (0, _model.hasMany)('tasks', {
      async: true,
      inverse: null
    })
  });

  _exports.default = _default;
});