define("coachlogix/pods/program-template/resources/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oOf9S5F2",
    "block": "{\"symbols\":[\"alert\"],\"statements\":[[6,[37,1],[[32,0,[\"model\",\"engagementTemplateTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cl-h-screen cl-w-full cl-flex cl-justify-center cl-items-center\"],[12],[2,\"\\n    \"],[8,\"cl-progress-circular\",[],[[\"@size\"],[48]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"cl-title-1 cl-mb-2\"],[12],[1,[30,[36,0],[\"resources.title\"],null]],[13],[2,\"\\n    \"],[8,\"cl-alert\",[[24,0,\"cl-mb-4 cl-rounded-sm cl-p-1\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"icon\"]],[],[[\"@icon\"],[\"group\"]],null],[2,\"\\n      \"],[8,[32,1,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"program-templates.resources.permissions-notice\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"v2/resources/resources-view\",[],[[\"@resourceable\"],[[32,0,[\"resourceable\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coachlogix/pods/program-template/resources/template.hbs"
    }
  });

  _exports.default = _default;
});