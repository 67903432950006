define("coachlogix/pods/dashboard/tasks/component", ["exports", "@ember-decorators/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const AVAILABLE_FILTER_TYPES = [{
    name: 'form',
    label: 'Form',
    icon: 'assignment_turned_in'
  }, {
    name: 'file',
    label: 'File',
    icon: 'insert_drive_file'
  }, {
    name: 'todo',
    label: 'Task',
    icon: 'offline_pin'
  }, {
    name: 'link',
    label: 'Link',
    icon: 'link'
  }];
  let DashboardTasksWidgetComponent = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = (_temp = class DashboardTasksWidgetComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "account", _descriptor2, this);

      _defineProperty(this, "taskTypes", AVAILABLE_FILTER_TYPES);

      _defineProperty(this, "typeFilters", ['form', 'file', 'todo', 'link']);

      _defineProperty(this, "statusFilters", ['assigned', 'completed']);

      _defineProperty(this, "assigneeFilter", 'myself');
    }

    toggleTypeFilter(value, event = null) {
      event?.stopPropagation?.();

      if (this.typeFilters.includes(value)) {
        this.typeFilters.removeObject(value);
      } else {
        this.typeFilters.addObject(value);
      }

      this.paginationApi.reset();
    }

    toggleStatusFilter(value, event) {
      event?.stopPropagation?.();

      if (this.statusFilters.includes(value)) {
        this.statusFilters.removeObject(value);
      } else {
        this.statusFilters.addObject(value);
      }

      this.paginationApi.reset();
    }

    setAssigneeFilter(value) {
      this.set('assigneeFilter', value);
      this.paginationApi.reset();
    }

    *loadData({
      pagination: {
        pageSize,
        pageNumber
      }
    }) {
      let include = ['assignable', 'assignee.user', 'notifications', 'resource'].join(',');
      let filter = {
        dashboard: 1
      };

      if (this.typeFilters && this.typeFilters.length) {
        filter.task_types = this.typeFilters;
      }

      if (this.assigneeFilter) {
        filter.assignee = this.assigneeFilter;
      }

      if (this.statusFilters && this.statusFilters.length) {
        filter.status = this.statusFilters;
      }

      let page = {
        size: pageSize,
        number: pageNumber
      };
      let assignments = yield this.store.query('assignment', {
        include,
        page,
        filter
      });
      return assignments.toArray();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "account", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleTypeFilter", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleTypeFilter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleStatusFilter", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleStatusFilter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setAssigneeFilter", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "setAssigneeFilter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadData", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "loadData"), _class2.prototype)), _class2)) || _class);
  var _default = DashboardTasksWidgetComponent;
  _exports.default = _default;
});