define("coachlogix/pods/outside/form/submit/route", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormSubmitRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class FormSubmitRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "clTheme", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "native", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    async beforeModel() {
      // try to load a token from the mobile app
      try {
        let token = await this.native.getToken();
        this.ajax.set('tempToken', token);
      } catch (e) {
        // ignore error
        return;
      }
    }

    model({
      assignment_id
    }) {
      return {
        submission: this.createSubmission.perform(assignment_id)
      };
    }

    *createSubmission(assignmentId) {
      let include = ['assignee.user', 'form-submissions', 'assignable.resource.form-questions.form-question-options', 'assignable.resource.form-sections', 'assignable.account', 'assignable.form-questions.form-question-options', 'assignable.form-sections', 'assignable.engagement.owner-account', 'assignable.engagement.coachee.user', 'assignable.engagement.coach.user'].join(',');
      let assignment = yield this.store.findRecord('assignment', assignmentId, {
        include,
        reload: true
      });
      let formSubmissions = yield assignment.get('formSubmissions');
      let assignee = yield assignment.get('assignee');
      let user = assignee ? yield assignee.get('user') : null;
      let assignable = yield assignment.get('assignable');
      let engagement = yield assignable.get('engagement');
      let account;

      if (engagement) {
        account = yield engagement.get('ownerAccount');
      } else {
        account = yield assignable.get('account');
      }

      if (account) {
        this.clTheme.setTheme({
          primary: account.primaryColor,
          secondary: account.accentColor
        });
      }

      if (formSubmissions.get('length')) {
        return formSubmissions.get('firstObject');
      } else {
        let assignable = yield assignment.get('assignable');
        let form;

        if (assignable && assignable.modelName === 'resource') {
          form = assignable;
        } else {
          form = yield assignable.get('resource');
        }

        yield form.get('formQuestions');
        return this.store.createRecord('form-submission', {
          resource: form,
          assignment,
          user
        });
      }
    }

    onFormSaved(submissionId) {
      // inform mobile app that the submission has finished
      this.native.submissionFinished(submissionId);
    }

    onFormError(e) {
      // inform mobile app that the submission has errored
      this.native.submissionError(e.toString());
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clTheme", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "native", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createSubmission", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createSubmission"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFormSaved", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onFormSaved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFormError", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onFormError"), _class.prototype)), _class));
  _exports.default = FormSubmitRoute;
});