define("coachlogix/pods/dashboard/stakeholder-feedback/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let DashboardStakeholderFeedbackComponent = (_dec = (0, _component.tagName)(''), _dec(_class = class DashboardStakeholderFeedbackComponent extends Ember.Component {}) || _class);
  var _default = DashboardStakeholderFeedbackComponent;
  _exports.default = _default;
});